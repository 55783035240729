import { useCallback, useEffect, useState } from 'react'
import { getAllRecords } from '../../../endpoints/admin'
import { useDispatch } from 'react-redux'
import { addFiles } from '../../../actions/files'
import useFilesQueryParams from './useFilesQueryParams'
import useFoldersBreadcrumb from '../../Files/hooks/useFoldersBreadcrumb'

function useGetAllRecords(fetchOnRender = true) {
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const storeFiles = useCallback(data => dispatch(addFiles(data)), [dispatch])
  const { page, per_page, keyword, folder_id } = useFilesQueryParams()
  const { handleBreadcrumb } = useFoldersBreadcrumb()

  const fetchRecords = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getAllRecords({ keyword, page, per_page, folder_id })
      handleBreadcrumb(data)
      storeFiles(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }, [keyword, page, storeFiles, per_page, folder_id, handleBreadcrumb])

  useEffect(() => {
    if (fetchOnRender) fetchRecords()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page, per_page, folder_id, fetchOnRender])

  return { loading, fetchRecords }
}

export default useGetAllRecords
