import React, { memo, useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  input: {
    width: 40,
    height: 20,
    margin: 0,
    padding: 0,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['4'],
      theme.palette.colors.neutral['9']
    ),
    border: 'none',
    borderRadius: 0,
    backgroundColor: 'transparent'
  },
  editBox: {
    padding: '2px 8px',
    border: `1px solid ${theme.palette.colors.neutral['5']}`,
    marginLeft: 8,
    whiteSpace: 'nowrap',
    position: 'relative',
    fontSize: 13,
    lineHeight: '20px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['4'],
      theme.palette.colors.neutral['9']
    ),
    cursor: 'pointer',
    borderRadius: 8
  }
}))

const EditableText = props => {
  const classes = useStyles()
  const {
    text,
    title,
    label = null,
    editable = true,
    children = null,
    onChange = () => null
  } = props
  const [clicked, setClicked] = useState(false)
  const [editedText, setText] = useState(text)

  useEffect(() => {
    setText(text)
  }, [text])

  const _toggleClick = useCallback(() => {
    if (editable) setClicked(oldVal => !oldVal)
  }, [editable])

  const _handleEditedTextChanged = useCallback(evt => {
    const time = Number(evt.target.value)
    if (Number.isInteger(time)) {
      setText(time)
    }
  }, [])

  const _handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        _toggleClick()
        onChange(editedText, label)
      }
    },
    [_toggleClick, editedText, label, onChange]
  )

  return (
    <div className={classes.editBox}>
      {clicked ? (
        <input
          type="text"
          value={editedText}
          readOnly={false}
          onBlur={_toggleClick}
          autoFocus={true}
          className={classes.input}
          onFocus={e => e.target.select()}
          onChange={_handleEditedTextChanged}
          onKeyDown={_handleKeyDown}
        />
      ) : (
        <span title={title} onClick={_toggleClick}>
          {text} {label} {children}
        </span>
      )}
    </div>
  )
}

export default memo(EditableText)
