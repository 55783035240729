import React, { memo, useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import { CircularProgress, Input } from '@material-ui/core'
import Section from './components'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 2,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: 200,
    paddingTop: 30,
    flexGrow: 1
  },

  search: {
    top: 0,
    left: 0,
    zIndex: 2,
    width: '90%',
    display: 'flex',
    position: 'fixed',
    padding: '8px 16px',
    backgroundColor: theme.palette.colors.customColor('white', '#424242')
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
    width: '100%',
    border: 'none',
    display: 'inline',
    lineHeight: '18px',
    backgroundColor: 'inherit',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[5],
      theme.palette.colors.neutral[9]
    )
  },
  header: {
    minHeight: 40,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      'white'
    )
  },
  icon: {
    height: 24,
    width: 24,
    marginRight: 8,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[5],
      'white'
    ),
    '&:hover': {
      background: 'none',
      color: theme.palette.colors.customColor(
        theme.palette.colors.neutral[5],
        'white'
      )
    }
  },
  modelsRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  model: {
    flexGrow: 1,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    width: '100%',
    display: 'flex',
    cursor: 'pointer',
    position: 'relative',
    textTransform: 'none',
    justifyContent: 'left',
    padding: '8px 16px 8px 16px',
    '&:hover': {
      color: 'inherit'
    }
  },
  hidden: {
    display: 'none'
  }
}))

const AIModelsMenu = ({ loading, error, handleSelect, items: models }) => {
  const classes = useStyles()
  const [search, setSearchValue] = useState('')
  const [isShowOrgModels, setShowOrgModels] = useState(true)
  const [isShowPublicModels, setShowPublicModels] = useState(false)

  const handleSearchChanged = useCallback(e => {
    setSearchValue(e?.target.value)
  }, [])

  const Content = memo(() => {
    if (loading) return <CircularProgress size={24} />

    return (
      <>
        <Section
          title={'Public models'}
          models={models?.public}
          isOpen={isShowPublicModels}
          handleSelect={handleSelect}
          setOpen={setShowPublicModels}
          loading={loading}
          classes={classes}
          search={search}
        />
        <Section
          title={'Organisation models'}
          models={models?.organization}
          handleSelect={handleSelect}
          isOpen={isShowOrgModels}
          setOpen={setShowOrgModels}
          defaultShow={true}
          loading={loading}
          classes={classes}
          search={search}
        />
        <Typography className={cn(classes.header, classes.headerText)}>
          Default Model (Stark Lab)
        </Typography>
      </>
    )
  })

  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <SearchIcon className={classes.icon} />
        <Input
          disableUnderline
          className={classes.input}
          placeholder={'Search'}
          value={search}
          onChange={handleSearchChanged}
        />
      </div>
      <Content />
    </div>
  )
}

export default memo(AIModelsMenu)
