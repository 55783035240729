import React from 'react'
import { FilterIcon } from '../../../Icons'
import ActionButton from '../../../ActionButton'
import Grid from '@material-ui/core/Grid'
import CustomCheckBox from '../../../FormComponents/CustomCheckbox/CustomCheckbox'
import CustomModal from '../../../CustomModal'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  checkbox: {
    marginBottom: 19
  }
}))

const ColumnFiltersModal = ({
  isOpened,
  toggleModal,
  handleApply,
  allColumns,
  handleToggleCheckbox
}) => {
  const classes = useStyles()

  if (!allColumns || allColumns.length === 0) {
    return null
  }

  return (
    <CustomModal
      open={isOpened}
      title="Column Filters"
      onClose={toggleModal}
      maxWidth={600}
      headerIcon={<FilterIcon />}
      renderActionButton={<ActionButton label="Apply" onClick={handleApply} />}
    >
      <Grid container>
        {allColumns.map((item, i) => {
          return (
            <Grid item xs={6} key={i}>
              <CustomCheckBox
                size={16}
                classNameRoot={classes.checkbox}
                onChange={() => handleToggleCheckbox(item.id)}
                checked={item.active}
                label={item.label}
              />
            </Grid>
          )
        })}
      </Grid>
    </CustomModal>
  )
}

export default ColumnFiltersModal
