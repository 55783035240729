// react
import React from 'react'
import ReactDOM from 'react-dom'

// redux
import { Provider } from 'react-redux'

// styling
import './index.css'
import './styles.css'
import './localStyles.css'
import '../node_modules/react-vis/dist/style.css'

import * as serviceWorker from './serviceWorker'

import { setApiConnection } from './hither/createHitherJob'

import apiConnection from './utils/ApiConnection'
import configureStore from './store'
import { AppConnected } from './components/App'

setApiConnection(apiConnection)

const { store } = configureStore()

// Render the app
ReactDOM.render(
  <Provider store={store}>
    <AppConnected />
  </Provider>,
  document.getElementById('root')
)

serviceWorker.unregister()
