import { useRef } from 'react'
import Plotly from 'plotly.js-gl2d-dist-min'
import { pixYToCoords } from '../../../PlotJS/helpers'

const axisStyle = {
  showline: false,
  showgrid: false,
  zeroline: false,
  showticklabels: false
}

const layout = (W, H, darkMode, args = {}) => {
  return {
    paper_bgcolor: darkMode ? '#000' : '#fff',
    plot_bgcolor: darkMode ? '#000' : '#fff',
    showlegend: false,
    height: H,
    width: W,
    xaxis: {
      ...axisStyle,
      ...args.xaxis
    },
    yaxis: {
      ...axisStyle,
      ...args.yaxis
    },

    dragmode: 'pan',
    autosize: false,
    hovermode: false,
    margin: {
      autoexpand: false,
      l: 0,
      r: 0,
      t: 0
    }
  }
}

const baseGlLine = {
  type: 'scattergl',
  mode: 'lines',
  showlegend: false
}

const getAnnotationOffset =
  waveformGeometry =>
  (x, offset = -0.9) =>
    waveformGeometry ? x + offset : offset

export const usePlot = (W, H, darkMode, layoutArgs) => {
  const ann = useRef([])
  const { axisArgs } = layoutArgs

  return {
    render: (elem, data) => {
      Plotly.react(
        elem,
        data,
        {
          ...layout(W, H, darkMode, axisArgs)
        },
        {
          scrollZoom: true,
          displayModeBar: false,
          plotGlPixelRatio: 1,
          showAxisDragHandles: false,
          responsive: true,
          showTips: false,
          fillFrame: true,
          autosizable: true,
          doubleClick: false
        }
      )
    },
    restyle: (elem, { darkMode, ...rest }) => {
      const color = darkMode ? '#fff' : '#000'
      const backgorund = darkMode ? '#000' : '#fff'
      if (elem) {
        ann.current = ann.current.map(x => ({
          ...x,
          font: { ...x.font, color }
        }))

        Plotly.relayout(elem, {
          paper_bgcolor: backgorund,
          plot_bgcolor: backgorund,
          annotations: ann.current,
          ...rest
        })
      }
    },
    update: (elem, data) => {
      Plotly.addTraces(elem, data ?? [])
    },
    updateAttribute: (elem, data, indices) => {
      const opt = {}
      if (data.y) {
        opt.y = data.y
      }
      if (data.x) {
        opt.x = data.x
      }
      if (elem) {
        Plotly.update(elem, opt, {}, indices)
      }
    },
    removeTrace: (elem, data) => {
      if (!elem) return

      Plotly.deleteTraces(elem, data)
    },
    registerEvent: (elem, event, cb) => {
      if (elem) {
        elem.on(event, cb)
      }
    },
    updateAnnotations: (elem, data, waveformGeometry) => {
      ann.current = data.map(([ch, pos]) => {
        const [x, y] = pos

        return buildAnnotation(
          ch,
          getAnnotationOffset(waveformGeometry)(x),
          y,
          darkMode
        )
      })

      Plotly.relayout(elem, {
        annotations: ann.current
      })
    },
    getAnnotations: () => ann.current
  }
}

function buildAnnotation(channelLabel, offsetX = 0, lastPoint = 0, darkMode) {
  return {
    text: `${channelLabel}`,
    align: 'left',
    showarrow: false,
    x: offsetX,
    y: lastPoint + 0.5,
    font: {
      color: darkMode ? '#fff' : 'black',
      size: 12
    }
  }
}

export function orderByGroup(group) {
  return group * 20
}

//used only on data without geometry
export const calcXOffset = k => k * 2.5

export const calcYOffset = (channel, n = 0) =>
  typeof channel === 'string' ? Number(channel) * n : channel * n

export function buildWaveforms({
  unit,
  xData,
  yData,
  offsetX = 0,
  offsetY = 0,
  H,
  deph = 1,
  style = { color: 'grey', width: 1 }
}) {
  if (!yData) return {}

  const output = {
    originalX: xData,
    originalY: yData,
    x: xData.map(v => v + offsetX),
    y: yData
      .map(v => v * deph)
      .map(v => pixYToCoords(v, H))
      .map(v => v + offsetY),
    'data-id': unit,
    offsetX,
    offsetY,
    line: style,
    updateX: offset => {
      output.x = output.originalX.map(v => v + offset)
      output.offsetX = offset

      return output
    },
    ...baseGlLine
  }

  return output
}

export const buildMeanWaveforms = ({
  channel,
  H,
  style = { color: 'grey', width: 2 },
  offsetX = 0,
  offsetY = 0,
  unit,
  xData,
  yData,
  trace_length,
  deph = 1
}) => {
  if (!xData)
    return {
      x: [],
      y: [],
      text: '',
      'data-id': unit,
      ...baseGlLine
    }

  const reverse = arr => arr.map(n => n * -1)

  const output = {
    originalX: xData,
    originalY: reverse(yData),
    x: xData.map(v => v + offsetX),
    y: reverse(yData)
      .map(v => v * deph)
      .map(v => pixYToCoords(v, H))
      .map(v => v + offsetY),
    text: channel,
    'data-id': unit,
    offsetX,
    offsetY,
    line: style,
    trace_length,
    updateX: offset => {
      output.x = output.originalX.map(v => v + offset)
      output.offsetX = offset

      return output.x
    },
    updateY: ({ newDeph, offset }) => {
      if (offset) {
        output.offsetY = offset
      }

      output.y = output.originalY
        .map(v => v * newDeph)
        .map(v => pixYToCoords(v, H))
        .map(v => v + (offset ?? output.offsetY))

      return output.y
    },
    ...baseGlLine
  }

  return output
}
