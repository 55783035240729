import { makeStyles } from '@material-ui/core/styles'
import useSortingsView from '../../../NewRecordingView/hooks/useSortingsView'
import React, { useCallback } from 'react'
import CustomDrawer from '../../../CustomDrawer'
import CustomTable from '../../../CustomTable'
import DrawerHeader from '../DrawerHeader'
import EmptySortings from '../EmptySortings'
import useToggleModal from '../../../../hooks/useToggleModal'
import { MODALS_ID, QPARAMS } from '../../../../utils/consts'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  drawer: {
    background: theme.palette.colors.backgrounds.contrast
  },
  modalRoot: {
    padding: 24
  }
}))

const SpikesortingsDrawer = ({ isLimited }) => {
  const classes = useStyles()
  const { SPIKESORTING_DRAWER, NEW_SPIKESORTING_MODAL } = MODALS_ID
  const { toggle: toggleDrawer, isOpened } = useToggleModal(SPIKESORTING_DRAWER)
  const { toggle: toggleSortingModal } = useToggleModal(NEW_SPIKESORTING_MODAL)

  const {
    columns,
    rows,
    pages,
    loading: loadingTable,
    loadingSortings,
    handleClickRow,
    isSortings,
    renderActionButton
  } = useSortingsView({ isLimited })
  const loading = loadingTable || loadingSortings

  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )

  const isSelected = useCallback(
    id => id === selectedSorting?.id,
    [selectedSorting]
  )

  const onClickRow = useCallback(
    id => {
      handleClickRow(id)
      toggleDrawer()
    },
    [handleClickRow, toggleDrawer]
  )

  return (
    <CustomDrawer
      open={isOpened}
      onClose={toggleDrawer}
      maxWidth="80%"
      anchor="left"
      customPaperClass={classes.drawer}
      classNames={classes.modalRoot}
    >
      <DrawerHeader
        isLimited={isLimited}
        title="Spikesorting"
        labelBtn="New Spikesorting"
        onClick={toggleSortingModal}
      />
      {!isSortings && !loading ? (
        <EmptySortings onClick={toggleSortingModal} isLimited={isLimited} />
      ) : (
        <CustomTable
          rows={rows}
          pages={pages}
          rowHeight={65}
          cellWidth={120}
          withPagination
          isSticky
          isSelected={isSelected}
          loading={loading}
          columns={columns}
          onClickRow={onClickRow}
          renderActionButton={!isLimited ? renderActionButton : null}
          customQparams={{
            page: QPARAMS.SORTINGS_PAGE
          }}
        />
      )}
    </CustomDrawer>
  )
}

export default SpikesortingsDrawer
