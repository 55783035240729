import { useCallback, useEffect, useState } from 'react'
import { getAIModels } from '../../../endpoints/admin'
import { useDispatch } from 'react-redux'
import useFilesQueryParams from '../../FilesTable/hooks/useFilesQueryParams'
import { addModels } from '../../../actions/aiModels'
import { AI_MODELS_FILTERS } from '../../../utils/consts'

export const existingTableParams = {
  page: 'ExistingAIPage',
  per_page: 'ExistingAIPerPage'
}

function useGetAIModels(fetchOnRender = true) {
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const {
    page,
    per_page,
    keyword,
    filter: queryfilter
  } = useFilesQueryParams(existingTableParams)
  const storeFiles = useCallback(data => dispatch(addModels(data)), [dispatch])

  const fetchRecords = useCallback(
    async (filter = AI_MODELS_FILTERS.ALL) => {
      const currentFilter = queryfilter ?? filter
      try {
        setLoading(true)
        const params = {
          page,
          per_page,
          keyword,
          filter: currentFilter
        }
        const data = await getAIModels(params)
        storeFiles({ ...data, filter: currentFilter })
        setLoading(false)
      } catch (err) {
        storeFiles({ filter: currentFilter })
        setLoading(false)
      }
    },
    [page, queryfilter, per_page, keyword, storeFiles]
  )

  useEffect(() => {
    if (fetchOnRender) fetchRecords()
  }, [page, per_page, keyword, queryfilter, fetchOnRender, fetchRecords])

  return { loading, fetchRecords }
}

export default useGetAIModels
