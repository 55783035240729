import React, { memo } from 'react'
import { IconButton, makeStyles, Typography } from '@material-ui/core'
import CurationAIIcon from '../../../../../../Icons/curationAIIcon'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import CloseIcon from '@material-ui/icons/Close'
import Zoom from '@material-ui/core/Zoom'

const useStyles = makeStyles(theme => ({
  curationAILabel: {
    fontSize: 18,
    fontWeight: 600,
    marginLeft: 8,
    lineHeight: '27px',
    color: theme.palette.colors.mainColor
  },
  popper: {
    zIndex: 1
  },
  tooltip: {
    width: 295,
    height: 129,
    padding: 16,
    borderRadius: 8,
    border: `1px solid ${theme.palette.colors.lighterGreen}`,
    lineHeight: '18px',
    fontWeight: 600,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.colors.mainColor,
    background: theme.palette.colors.backgrounds.main,
    '&::before': {
      border: `1px solid ${theme.palette.colors.lighterGreen}`
    }
  },
  arrow: {
    fontSize: 22,
    background: theme.palette.colors.backgrounds.main,
    '&::before': {
      background: theme.palette.colors.backgrounds.main,
      border: `1px solid ${theme.palette.colors.lighterGreen}`
    }
  },
  header: {
    alignItems: 'center',
    paddingBottom: 8,
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  bodyText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '18px',
    color: theme.palette.colors.neutral.contrast,
    marginTop: 8
  },
  curationAIIcon: {
    width: 24,
    height: 24
  },
  closeIcon: {
    width: 24,
    height: 24,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['5'],
      theme.palette.colors.darkMain
    ),
    '&:hover': {
      color: theme.palette.colors.customColor(
        theme.palette.colors.neutral['5'],
        theme.palette.colors.darkMain
      )
    }
  },
  logoContainer: {
    alignItems: 'center',
    display: 'inline-flex'
  }
}))

const TourTooltip = ({ children, showTourTooltip, handleClose }) => {
  const classes = useStyles({ showTourTooltip })

  return (
    <Tooltip
      arrow
      interactive
      TransitionComponent={Zoom}
      title={<TooltipBody classes={classes} handleClose={handleClose} />}
      open={showTourTooltip}
      classes={{
        popper: classes.popper,
        arrow: classes.arrow,
        tooltip: classes.tooltip
      }}
    >
      {children}
    </Tooltip>
  )
}

const TooltipBody = memo(({ classes, handleClose }) => (
  <Grid container>
    <Grid container className={classes.header}>
      <Grid item className={classes.logoContainer}>
        <CurationAIIcon className={classes.curationAIIcon} />
        <Typography className={classes.curationAILabel}>AI curation</Typography>
      </Grid>
      <IconButton className={classes.closeIcon}>
        <CloseIcon onClick={handleClose} />
      </IconButton>
    </Grid>
    <Typography className={classes.bodyText}>
      Check out AI recommended curation steps from the new ClusterView and
      SimilarityView widgets!
    </Typography>
  </Grid>
))

export default memo(TourTooltip)
