import DataViewDarkIllustration from './DataViewDarkIllustration'
import DataViewIllustration from './DataViewIllustration'
import { makeStyles, Typography } from '@material-ui/core'
import ClusterViewDarkIllustration from './ClusterViewDarkIllustration'
import ClusterViewIllustration from './ClusterViewIllustration'
import SimilarityViewDarkillustration from './SimilarityViewDarkillustration'
import SimilarityViewIllustration from './SimilarityViewIllustration'
import React from 'react'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  link: {
    fontWeight: 700,
    display: 'inline',
    color: theme.palette.colors.darkMain,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}))

function Steps() {
  const classes = useStyles()
  const link = 'https://doi.org/10.5281/zenodo.7303380'
  const darkMode = useSelector(state => state.darkMode)

  return [
    {
      title: 'AI curation is in Beta! 🎉',
      illustration: darkMode ? (
        <DataViewDarkIllustration />
      ) : (
        <DataViewIllustration />
      ),
      description: (
        <>
          AI provides recommendations to curate a spikesorted dataset based on
          models trained on human curation techniques. Based on recent work from
          the Stark lab. Spivak Lidor, Remenick Tali, & Stark Eran. (2022).
          Automated spike sorting curation. Zenodo.
          <Typography
            onClick={() => window.open(link)}
            className={classes.link}
          >
            {' '}
            {link}
          </Typography>
        </>
      )
    },
    {
      title: 'Save your time! ⌛',
      illustration: darkMode ? (
        <ClusterViewDarkIllustration />
      ) : (
        <ClusterViewIllustration />
      ),
      description: (
        <>
          Focus on analysing data, not the tools: units that should be relabeled
          or merged are automatically highlighted, drastically reducing manual
          curation effort.
        </>
      )
    },
    {
      title: 'Accuracy and consistency 🚀',
      illustration: darkMode ? (
        <SimilarityViewDarkillustration />
      ) : (
        <SimilarityViewIllustration />
      ),
      description: (
        <>
          The accuracy of the AI algorithm can increase over subsequent training
          sessions. Once trained, the same curation techniques can be easily
          applied by other users, including novice spikesorters!
        </>
      )
    }
  ]
}

export default Steps
