// This is an open 2-way connection with server (websocket)
import {
  REPORT_INITIAL_LOAD_COMPLETE,
  SET_SERVER_INFO,
  SET_WEBSOCKET_STATUS
} from '../../actions'
import {
  handleHitherJobCreated,
  handleHitherJobCreationError,
  handleHitherJobError,
  handleHitherJobFinished,
  sleepMsec
} from '../../hither/createHitherJob'
import configureStore from '../../store'

const { store } = configureStore()

class ApiConnection {
  constructor() {
    const url =
      process.env.REACT_APP_API_URL_LOCALLY ||
      (process.env.REACT_APP_API_URL
        ? `wss://${process.env.REACT_APP_API_URL.slice(8)}/websocket`
        : `ws://${window.location.hostname}:15308`)

    this._ws = new WebSocket(url)
    console.log(this._ws)
    this._ws.addEventListener('open', () => {
      this._connected = true
      const qm = this._queuedMessages
      this._queuedMessages = []
      for (let m of qm) {
        this.sendMessage(m)
      }
      this._onConnectCallbacks.forEach(cb => cb())
      store.dispatch({
        type: SET_WEBSOCKET_STATUS,
        websocketStatus: 'connected'
      })
    })
    this._ws.addEventListener('message', evt => {
      const x = JSON.parse(evt.data)
      // console.info('INCOMING MESSAGE', x)
      this._onMessageCallbacks.forEach(cb => cb(x))
    })
    this._ws.addEventListener('close', args => {
      console.warn('Websocket disconnected.')
      console.log(args)
      this._connected = false
      this._isDisconnected = true
      store.dispatch({
        type: SET_WEBSOCKET_STATUS,
        websocketStatus: 'disconnected'
      })
    })

    this._ws.addEventListener('error', args => {
      console.log('Error')
      console.log(args)
    })

    this._onMessageCallbacks = []
    this._onConnectCallbacks = []
    this._connected = false
    this._isDisconnected = false
    this._queuedMessages = []
    this._start()
  }
  onMessage(cb) {
    this._onMessageCallbacks.push(cb)
  }
  onConnect(cb) {
    this._onConnectCallbacks.push(cb)
    if (this._connected) {
      cb()
    }
  }
  isDisconnected() {
    return this._isDisconnected
  }
  sendMessage(msg) {
    if (!this._connected) {
      this._queuedMessages.push(msg)
      return
    }
    // console.info('OUTGOING MESSAGE', msg)
    this._ws.send(JSON.stringify(msg))
  }
  async _start() {
    while (true) {
      await sleepMsec(17000)
      this.sendMessage({ type: 'keepAlive' })
    }
  }
}

class SkipApiConnection {
  onConnect() {}
  onMessage() {}
  sendMessage() {}
}

const apiConnection = new SkipApiConnection()
apiConnection.onConnect(() => {
  console.info('Connected to API server')
})
apiConnection.onMessage(msg => {
  // console.log(msg.type)
  const type0 = msg.type
  if (type0 === 'reportServerInfo') {
    const { nodeId, defaultFeedId } = msg.serverInfo
    store.dispatch({
      type: SET_SERVER_INFO,
      nodeId,
      defaultFeedId
    })
  } else if (type0 === 'action') {
    let action = msg.action
    if ('persistKey' in action) {
      // just to be safe
      delete action['persistKey']
    }
    store.dispatch(action)
  } else if (type0 === 'reportInitialLoadComplete') {
    store.dispatch({
      type: REPORT_INITIAL_LOAD_COMPLETE
    })
  } else if (type0 === 'hitherJobFinished') {
    handleHitherJobFinished(msg)
  } else if (type0 === 'hitherJobError') {
    handleHitherJobError(msg)
  } else if (type0 === 'hitherJobCreated') {
    handleHitherJobCreated(msg)
  } else if (type0 === 'hitherJobCreationError') {
    handleHitherJobCreationError(msg)
  } else {
    console.warn(`Unregognized message type from server: ${type0}`)
  }
})

export default apiConnection
