import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { toggleCurationAI } from '../../../redux/actions'
import { NOTIFICATION } from '../../../../../../../utils/consts'
import useNotification from '../../../../../../SnackbarManager/hooks/useNotification'
import { last } from 'lodash/fp'
import useGetRecommendations from './useGetRecommendations'
import { startTour } from '../../../../../../../actions/tours'

export default function useCurationAI(props) {
  const { isInit } = props || {}
  const { recordingId } = useParams()
  const [showTourTooltip, setOpen] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const addNotification = useNotification()

  const spikeSortings =
    useSelector(state => state.recordingStore.sortings) || []
  const { selectedSorting, selectedCuration } = useSelector(
    state => state.recordingStore
  )

  const pathname = location.pathname.split('/')[1]
  const isRecordingPage = pathname === 'recording'

  const currentUser = useSelector(state => state.login?.currentUser) || {}
  const onboardings = currentUser?.user?.onboardings || []
  const { status: recordingTourStatus } =
    onboardings?.find(el => el?.step === 'recording') || {}
  const { status: curationAITourStatus } =
    onboardings?.find(el => el?.step === 'curation_AI') || {}

  const { id: selectedSortingId } = selectedSorting || {}
  const { id: selectedCurationId } = selectedCuration || {}
  const ID = [selectedSortingId, selectedCurationId].join('-')

  const setCurationAI = useCallback(
    option => {
      if (selectedCurationId && selectedSortingId) {
        localStorage.setItem(
          `curationAI`,
          JSON.stringify({ ID, isCurationAI: option })
        )
        dispatch(toggleCurationAI(option))
      }
    },
    [ID, dispatch]
  )

  const { loading, fetchRecommendations } = useGetRecommendations()

  const isCurationAI = useSelector(
    state => state.widgetsCache.curationAI.isCurationAI
  )
  const { recommendations } = useSelector(
    state => state.widgetsCache.curationAI
  )

  const storageKey = `ss-${recordingId}`
  const localSpikeSortings = JSON.parse(localStorage.getItem(storageKey)) || []

  const handleToggle = useCallback(option => setOpen(option), [setOpen])

  const handleSet = useCallback(() => {
    localStorage.setItem(
      storageKey,
      JSON.stringify([...localSpikeSortings, ID])
    )
  }, [ID, storageKey])

  const checkIsRequiredViewsVisible = useCallback(() => {
    const widgets =
      JSON.parse(localStorage.getItem('widgets-layout-DS-2')) ?? []
    const isSimilarityViewVisible = widgets.some(
      widget => last(widget).widgetId === 'similarity_view'
    )
    const isClusterViewVisible = widgets.some(
      widget => last(widget).widgetId === 'cluster_view'
    )
    return { isSimilarityViewVisible, isClusterViewVisible }
  }, [])

  const showErrorNotification = useCallback(
    msg => {
      setCurationAI(false)
      addNotification({
        type: NOTIFICATION.ERROR,
        title: msg
      })
    },
    [addNotification, setCurationAI]
  )

  const showWidgetErrorNotification = useCallback(
    widget =>
      showErrorNotification(
        `Please select ${widget} view widget from the widgets menu before starting the AI curation`
      ),
    [showErrorNotification]
  )

  const onCurationAI = useCallback(async () => {
    if (!selectedSortingId) {
      return showErrorNotification(
        'Recommendations not available yet, please run spike sorting first.'
      )
    }
    if (!selectedSorting || selectedSorting.output !== 'Completed') {
      return showErrorNotification(
        'Recommendations not available yet, please wait the spike sorting to finish'
      )
    }
    if (!selectedCurationId)
      return showErrorNotification(
        'Please select curation from the curation list before starting the AI curation'
      )

    const { isSimilarityViewVisible, isClusterViewVisible } =
      checkIsRequiredViewsVisible()
    if (!isClusterViewVisible) return showWidgetErrorNotification('Cluster')
    if (!isSimilarityViewVisible)
      return showWidgetErrorNotification('Similarity')

    if (recommendations.has(ID)) return setCurationAI(true)
    return await fetchRecommendations({ onSuccess: () => setCurationAI(true) })
  }, [
    selectedSortingId,
    selectedSorting?.output,
    selectedCurationId,
    showErrorNotification,
    checkIsRequiredViewsVisible,
    showWidgetErrorNotification,
    recommendations,
    ID,
    setCurationAI,
    fetchRecommendations
  ])

  const toggle = useCallback(async () => {
    handleToggle(false)
    if (isCurationAI) return setCurationAI(false)
    await onCurationAI()
  }, [onCurationAI, handleToggle, isCurationAI, setCurationAI])

  useEffect(() => {
    if (
      !isInit &&
      isRecordingPage &&
      recordingTourStatus &&
      selectedSortingId &&
      selectedCurationId &&
      !localSpikeSortings.includes(ID) &&
      selectedSorting.output === 'Completed'
    ) {
      setTimeout(() => dispatch(startTour()), 1000)
      handleSet()
    }
  }, [
    ID,
    handleSet,
    spikeSortings?.length,
    selectedSorting?.output,
    handleToggle,
    recordingId,
    recordingTourStatus,
    curationAITourStatus,
    dispatch
  ])

  useEffect(() => {
    const sessionCurAI = JSON.parse(localStorage.getItem('curationAI'))
    const { ID: sessionCurAIID, isCurationAI } = sessionCurAI || {}
    if (ID === sessionCurAIID && isCurationAI) onCurationAI()
    if (ID !== sessionCurAIID) setCurationAI(false)
    return () => {
      setCurationAI(false)
    }
  }, [ID])

  return {
    ID,
    loading,
    isCurationAI,
    showTourTooltip,
    toggle,
    handleToggle,
    setCurationAI,
    onCurationAI,
    handleSet
  }
}
