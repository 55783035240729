import { useFetchResult } from '../../../../../hooks/useFetchResult'
import { runLog } from '../../../../../endpoints/admin'
import { useCallback, useEffect } from 'react'
import { to } from '../../../../../utils/utils'

const useLogs = (id, fetchOn) => {
  const { state, fetchData, onSuccess, onError, onInit, onReset } =
    useFetchResult(runLog, { id })
  const { data: result, loading } = state || {}
  const { data, totak_chunks, chunk_number, ...res } = result || {}
  const hasMore = chunk_number + 1 < totak_chunks

  useEffect(() => {
    fetchOn && fetchData()
    // eslint-disable-next-line
  }, [fetchOn])

  const handleLoadMore = useCallback(async () => {
    if (loading || !hasMore) return
    try {
      onInit()
      const params = {
        id,
        ...res,
        totak_chunks,
        chunk_number: chunk_number + 1
      }
      const { err, data: nextChunk } = await to(runLog, params)
      if (err) {
        onError(err)
      } else {
        if (data)
          onSuccess({
            ...nextChunk,
            data: data + nextChunk?.data
          })
      }
    } catch (e) {
      onError(e)
    }
  }, [
    chunk_number,
    data,
    hasMore,
    id,
    loading,
    onError,
    onInit,
    onSuccess,
    res,
    totak_chunks
  ])

  return {
    state,
    fetchData,
    loadMore: handleLoadMore,
    hasMore,
    resetData: onReset
  }
}

export default useLogs
