import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import useFilesQueryParams from '../../../../FilesTable/hooks/useFilesQueryParams'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { MODALS_ID, NOTIFICATION } from '../../../../../utils/consts'
import useToggleModal from '../../../../../hooks/useToggleModal'
import { useSelector } from 'react-redux'
import { getModelDatasets } from '../../../../../endpoints/admin'
import useColumnFilters from '../../../../Files/hooks/useColumnFilters'
import { initialColumns } from './initialColumns'
import { getUploadDate } from '../../../../../utils/utils'
import useDelete from '../../../hooks/useDelete'
import useIsAuthor from '../../../../../common/hooks/useIsAuthor'

function useInfoPanel() {
  const infoTableParams = {
    page: 'infoTablePage',
    per_page: 'infoTablePerPage'
  }

  const addNotification = useNotification()
  const { toggleDeleteModal } = useDelete()
  const { page, per_page } = useFilesQueryParams(infoTableParams)

  const [data, setData] = useState(null)
  const [isEdit, setEdit] = useState(false)
  const [loading, setLoading] = useState(null)
  const [isExpanded, setExpanded] = useState(false)

  const customStorageName = 'ModelDatasetColumns'
  const modalID = MODALS_ID.MODEL_INFO_PANEL
  const { toggle: toggleDrawer, isOpened, modalState } = useToggleModal(modalID)
  const { id } = modalState?.args || {}
  const { records } = useSelector(state => state.aiModels)
  const selected = records?.find(item => item.id === id)
  const { user_id } = selected || {}
  const { isAuthorOrAdmin: isAuthorized } = useIsAuthor(user_id)

  const { pages, records: datasets } = data || {}

  const { filteredColumns: columns } = useColumnFilters({
    customStorageName,
    customInitialColumns: initialColumns
  })

  const rows = useMemo(
    () =>
      datasets?.length > 0 &&
      datasets?.map(item => {
        const {
          id,
          name,
          user_id,
          user_name,
          created_at,
          upload_date,
          duration,
          isolated_units,
          author,
          curation_id = ' ',
          tags,
          spikesorting_name = ' '
        } = item
        const authorpassed = { id: user_id, name: author?.username ?? user_name }

        return {
          id,
          curation_id,
          name,
          spikesorting_name,
          author: authorpassed,
          approvalStatus: true,
          duration,
          tags,
          isolated_units,
          uploaded_date: getUploadDate(upload_date || created_at)
        }
      }),
    [datasets]
  )

  const toggleExpanded = useCallback(() => setExpanded(prev => !prev), [])
  const toggleEdit = useCallback(() => setEdit(prev => !prev), [])

  const onDelete = useCallback(() => {
    toggleDrawer()
    toggleDeleteModal({ ids: [id] })
  }, [id, toggleDeleteModal, toggleDrawer])

  const getDatasets = useCallback(async () => {
    try {
      setLoading(true)
      const params = { id, page, per_page }
      const data = await getModelDatasets(params)
      setData(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [id, page, per_page])

  useEffect(() => {
    if (isOpened) getDatasets()
  }, [getDatasets, isOpened])

  return {
    rows,
    pages,
    columns,
    loading,
    selected,
    isAuthorized,
    isEdit,
    isOpened,
    isExpanded,
    onDelete,
    toggleEdit,
    toggleDrawer,
    toggleExpanded
  }
}

export default useInfoPanel
