import { Component } from 'react'

export class TimeSeriesData extends Component {
  constructor(props) {
    super(props)

    let defaultState = {
      panels: [],
      groups: 1,
      zoomLevel: 0,
      currentWindowTimeRange: undefined,
      // timeRange: undefined, // determined below
      currentTime: null,
      selectedPaths: new Set(),
      downsample: {
        enable: false,
        value: 250
      }
    }

    // const numChannels = props.num_channels
    // const numTimepoints = this.props.num_timepoints

    // determine initial time range
    // let mb_per_timepoint = (numChannels * 2) / 1e6
    // let max_mb_to_load = 1
    // let max_timepoints = Math.min(30000, max_mb_to_load / mb_per_timepoint)
    // let t1 = Math.floor(numTimepoints / 2 - max_timepoints / 2)
    // let t2 = Math.floor(numTimepoints / 2 + max_timepoints / 2)
    // if (t1 < 0) t1 = 0
    // if (t2 > numTimepoints - 1) t2 = numTimepoints - 1
    //defaultState.timeRange = [t1, t2]

    this.state = defaultState
    this._downsampleFactor = 1
    this.y_scale_factor = 1
    this._repainter = null
  }
}
