/*
What does this middleware do?

It allows us to dispatch actions to manipulate the redux state
in the usual way, but if the action has a persistKey field,
then this middleware redirects the action to the server for
persistence. Once the server has applied the action to the live
feed, the new messages on the feed will propagate back to the
client side (there is a feed listener) and at that time the action
will actually be played out on the redux state (because the
persistKey will have been stripped).
*/
import { applyMiddleware, createStore } from 'redux'
import { createEpicMiddleware } from 'redux-observable'
import rootReducer from './reducers'
import thunk from 'redux-thunk'
import { persistStateMiddleware, resetStore } from './middlewares'
import rootEpic from './epics'

import history from 'history/browser'

const buildQuery = () => {
  const location = history.location
  const query = new URLSearchParams(location.search)

  const getByName = name => query.get(name)

  const setQueryParams = props => {
    const q = new URLSearchParams()
    if (Array.isArray(props)) {
      props.forEach(({ key, value }) => value && q.set(key, value))
    } else {
      const { key, value } = props
      if (!value) return ''
      q.set(key, value)
    }
    const string = q.toString()
    return string ? `?${string}` : ''
  }

  return { getByName, query, setQueryParams }
}

const epicMiddleware = createEpicMiddleware({
  dependencies: { queryParams: buildQuery() }
})

// Create the store and apply middleware
// persistStateMiddleware is described above
// thunk allows asynchronous actions

export const saveState = (state, config = {}) => {
  const { whitelist } = config || {}
  try {
    const whitelisted = Object.entries(state).filter(([k]) =>
      whitelist?.includes(k)
    )
    const entries = Object.fromEntries(whitelisted)
    const res = whitelisted?.length ? entries : state

    const serializedState = JSON.stringify(res)
    localStorage.setItem('store', serializedState)
  } catch {
    // ignore write errors
    console.log('ERROR: saveState')
  }
}

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('store')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const whitelist = ['biochipData', 'timeSeriesLeftPanel', 'recordingStore']

export default () => {
  let store = createStore(
    rootReducer,
    applyMiddleware(persistStateMiddleware, resetStore, thunk, epicMiddleware)
  )

  store.subscribe(() => {
    if (window.opener) return null
    saveState(store.getState(), {
      // WHITELISTED REDUCERS TO BE SAVED IN LOCALSTORAGE
      whitelist
    })
  })

  epicMiddleware.run(rootEpic)

  return { store }
}
