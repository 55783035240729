import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import EmptyFolder from '../../../Icons/EmptyFolder'
import ActionButton from '../../../ActionButton'
import React from 'react'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1
  },
  title: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: 20,
    margin: 24,
    lineHeight: '24px',
    color: theme.palette.colors.neutral.contrast
  },
  button: {
    width: 'auto',
    height: 48
  }
}))

const EmptySortings = ({
  onClick = () => null,
  isLimited = false,
  topic = 'spikesorting',
  labelBtn = 'New spikesorting'
}) => {
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <EmptyFolder />
      <Typography className={classes.title}>
        This dataset doesn’t have any {topic} yet
      </Typography>
      <ActionButton
        label={labelBtn}
        className={classes.button}
        onClick={onClick}
        disabled={isLimited}
      />
    </Grid>
  )
}

export default EmptySortings
