import { useCallback, useMemo } from 'react'
import useEditRecord from '../../../hooks/useEditRecord'
import { object, number, string } from 'yup'
import {
  MAX_120_CHARS,
  MODALS_ID,
  REQUIRE_NUMBER,
  REQUIRED
} from '../../../../../utils/consts'
import { useFormik } from 'formik'
import useRecordingInfo from '../../../hooks/useRecordingInfo'
import useToggleModal from '../../../../../hooks/useToggleModal'
import { useSelector } from 'react-redux'

function useEditData() {
  const { toggle: toggleModal, isOpened } = useToggleModal(
    MODALS_ID.EDIT_DATA_MODAL
  )
  const { onEdit } = useEditRecord()
  const { recording } = useRecordingInfo()
  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )

  const {
    id: curationId,
    uncurated_units,
    good_units,
    noise_units,
    multi_units
  } = useSelector(state => state.recordingStore.selectedCuration) || {}

  const current_single_units =
    uncurated_units + good_units + noise_units + multi_units || 0

  const {
    sample_rate,
    duration,
    number_channels,
    probe_id,
    prb_file,
    dtype,
    id
  } = recording || {}

  const { algo, id: sortingId, isolated_single_units } = selectedSorting || {}

  const handleSave = useCallback(
    async values => {
      const data = { id, ...values }
      onEdit({
        data,
        onSuccess: (updateStore, res) => {
          const { duration, probe_id } = res || {}
          updateStore({
            recording: {
              ...recording,
              ...data,
              probe_id,
              prb_file: data.prb_file.name,
              duration
            }
          })
          toggleModal()
        }
      })
    },
    [id, onEdit, recording, toggleModal]
  )

  const initialValues = {
    dtype: dtype || '',
    sample_rate: sample_rate || '',
    number_channels: number_channels || '',
    prb_file: prb_file && probe_id ? { name: prb_file, id: probe_id } : ''
  }

  const validation = object({
    sample_rate: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    number_channels: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    prb_file: object({
      name: string()
    }),
    dtype: string().max(120, MAX_120_CHARS)
  })

  const formik = useFormik({
    initialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: validation,
    onSubmit: values => handleSave(values)
  })

  const datas = useMemo(
    () => [
      {
        title: 'Sorting ID',
        content: sortingId
      },
      {
        title: 'Curation ID',
        content: curationId
      },
      {
        title: 'Probe Design',
        content: prb_file
      },
      {
        title: 'Sorting Method',
        content: algo
      },
      {
        title: 'Sample Rate',
        content: sample_rate
      },
      {
        title: 'Duration (sec.)',
        content: duration
      },
      {
        title: 'Channel Count',
        content: number_channels
      },
      {
        title: 'Isolated single units',
        content: isolated_single_units
      },
      {
        title: 'Current single units',
        content: current_single_units
      },
      {
        title: 'Probe ID',
        content: probe_id
      }
    ],
    [
      sortingId,
      curationId,
      prb_file,
      algo,
      sample_rate,
      duration,
      number_channels,
      isolated_single_units,
      current_single_units,
      probe_id
    ]
  )

  return {
    isOpened,
    datas,
    toggleModal,
    formik
  }
}

export default useEditData
