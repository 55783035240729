import { MARGIN_X, MARGIN_Y } from '../consts'
export { factoryColumnBuckets } from './plotHelpers'

export const normalizeXGeometry = x => x / 15

export const updateAxesRange = arr => {
  const minX = Math.min(...arr.map(v => v[0]))
  const maxX = Math.max(...arr.map(v => v[0]))

  const minY = Math.min(...arr.map(v => v[1]))
  const maxY = Math.max(...arr.map(v => v[1]))

  return {
    xaxis: {
      range: [minX - MARGIN_X, maxX + MARGIN_X]
    },
    yaxis: {
      range: [minY - MARGIN_Y, maxY + MARGIN_Y]
    }
  }
}

function mySlice(arr, n = 0, from = 0, to = Infinity) {
  if (!n) {
    return arr.slice(from, Math.min(Math.abs(to), arr.length))
  }

  if (n < 0) {
    //take from back n elements
    return [...arr].reverse().slice(Math.abs(n), Math.min(to, arr.length))
  } else {
    return [...arr].slice(n, Math.min(to, arr.length))
  }
}

export function trimTraces(data, start = 5, end = -25) {
  return mySlice(mySlice(data, start), end)
}

export function onUpdateDeph(gd, newDeph) {
  const indices = gd.data.map((_, i) => i)
  const data = gd.data
    .filter(x => x.updateY)
    .reduce((acc, v) => {
      return [...acc, v.updateY({ newDeph })]
    }, [])

  return { indices, data, newDeph }
}
