const getEnv = key => process.env[key]

let apiUrl = getEnv('REACT_APP_NEW_API_URL')
// if (window.location.hostname.startsWith('staging-app-1')) {
//   apiUrl = 'https://staging-api-1.diagnosticbiochips.com'
// } else if (window.location.hostname.startsWith('staging-app-2')) {
//   apiUrl = 'https://staging-api-2.diagnosticbiochips.com'
// } else if (window.location.hostname.startsWith('staging-app-3')) {
//   apiUrl = 'https://staging-api-3.diagnosticbiochips.com'
// }

const config = {
  apiUrl
}

export default config
