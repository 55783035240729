import { useDispatch, useSelector } from 'react-redux'
import { ACTIONS } from 'react-joyride'
import { MODALS_ID } from '../../../utils/consts'
import useToggleModal from '../../../hooks/useToggleModal'
import { nextOrPrevTour } from '../../../actions/tours'
import useCurationAI from '../components/WidgetsLayout/widgets/DataView/hooks/useCurationAI'
import { useTheme } from '@material-ui/core'
import { useCallback, useEffect, useRef, useState } from 'react'
import useClusterView from '../components/WidgetsLayout/widgets/ClusterView/hooks/useClusterView'
import useSimilarityView from '../components/WidgetsLayout/widgets/SimilarityView/hooks/useSimilarityView'
export default function useToursController(data) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const realRowColor = useRef(null)
  const currentRow = useRef(null)
  const [pendingView, setPendingView] = useState(null)
  const isDarkTheme = theme.palette.type === 'dark'

  const nextOrPrev = (index, cdt) => dispatch(nextOrPrevTour(index, cdt))

  const { SPIKESORTING_DRAWER } = MODALS_ID

  const { ID, onCurationAI, isCurationAI } = useCurationAI({ isInit: true })
  const { isOpened: isSortingsModalOpened, toggle: toggleSortings } =
    useToggleModal(SPIKESORTING_DRAWER)
  const currentUser = useSelector(state => state.login?.currentUser) || {}
  const { state: clusterState } = useClusterView({ isInit: true })
  const { state: similarityState, storedData: storedSimilarityData } =
    useSimilarityView({ isInit: true })
  const { loading: isLoadingClusterView, error: isErrorClusterView } =
    clusterState || {}
  const { loading: isLoadingSimilarityView, error: isErrorSimilarityView } =
    similarityState || {}
  const loading = isLoadingClusterView || isLoadingSimilarityView
  const error = isErrorClusterView || isErrorSimilarityView

  const prepSampleSuggestion = useCallback(
    (view, base, viewVisibilityRow, firstItem, scrollIntoView) => {
      currentRow.current = { firstItem, viewVisibilityRow }
      if (firstItem) firstItem.click()
      scrollIntoView && firstItem.scrollIntoView()
      base.style.pointerEvents = 'none'
      viewVisibilityRow.style.visibility = 'visible'
      viewVisibilityRow.style.top = firstItem.getBoundingClientRect().top + 'px'
      const defaultColor = isDarkTheme ? theme.palette.colors.black2 : 'white'
      const currentColor = firstItem.style.backgroundColor || defaultColor
      const viewId = view + ID
      if (!realRowColor.current || !realRowColor.current[viewId])
        realRowColor.current = {
          ...realRowColor.current,
          [viewId]: currentColor
        }
      firstItem.style.backgroundColor = isDarkTheme ? '#192d40' : '#d8ebff'
    },
    [ID, isDarkTheme]
  )

  const runPendingAction = useCallback(() => {
    const { widgetName, childNum = 1, scrollIntoView } = pendingView
    const base = document.querySelector(`#${widgetName}-base`)
    const viewVisibilityRow = document.querySelector(
      `#${widgetName}-base-merge-visibility-Row`
    )
    const firstItem = document.querySelector(
      `#${widgetName}-base .ReactVirtualized__Table__row:nth-child(${childNum})`
    )

    if (!base) return

    if (firstItem && isCurationAI) {
      prepSampleSuggestion(
        widgetName,
        base,
        viewVisibilityRow,
        firstItem,
        scrollIntoView
      )
      pendingView.callback()
      setPendingView(null)
    }
  }, [isCurationAI, pendingView, prepSampleSuggestion])

  useEffect(() => {
    if (!loading && !error && pendingView) runPendingAction()
  }, [loading, runPendingAction, pendingView, storedSimilarityData, error])

  const nextTour = data => {
    const onboardings = currentUser?.user?.onboardings || []
    const { status } = onboardings?.find(el => el?.step === 'recording') || {}

    const { action, index } = data
    const isPrev = action === ACTIONS.PREV

    const cleanView = view => {
      if (currentRow.current) {
        const firstItem = currentRow.current.firstItem
        const viewVisibilityRow = currentRow.current.viewVisibilityRow
        const base = document.querySelector(`#${view}-base`)

        if (!base) return
        const viewId = view + ID
        firstItem.style.backgroundColor = realRowColor.current[viewId]
        base.style.pointerEvents = 'all'
        viewVisibilityRow.style.visibility = 'hidden'
      }
    }

    if (status) {
      // This block runs only if it is curation AI onboarding
      if (index === 0 && !isPrev) {
        onCurationAI()

        return setPendingView({
          widgetName: 'cluster-view',
          childNum: 1,
          scrollIntoView: true,
          callback: () => nextOrPrev(index, isPrev)
        })
      }

      if (index === 1 && isPrev) {
        cleanView('cluster-view')
      }

      if (index === 1 && !isPrev) {
        const firstItem = document.querySelector(
          '#similarity-view-base .ReactVirtualized__Table__row:nth-child(2)'
        )
        if (firstItem) firstItem.click()
        cleanView('cluster-view')
        return setPendingView({
          widgetName: 'similarity-view',
          childNum: 2,
          scrollIntoView: false,
          callback: () => nextOrPrev(index, isPrev)
        })
      }

      if (index === 2 && isPrev) {
        cleanView('similarity-view')
        return setPendingView({
          widgetName: 'cluster-view',
          childNum: 1,
          scrollIntoView: true,
          callback: () => nextOrPrev(index, isPrev)
        })
      }

      if ((index === 3 && !isPrev) || (index === 2 && !isPrev))
        cleanView('similarity-view', 2)

      return nextOrPrev(index, isPrev)
    }

    if ((index === 0 && !isPrev) || (index === 1 && isPrev)) {
      const widgetMenuButton = document.querySelector(
        '#widgets-menu>button:nth-child(1)'
      )
      if (widgetMenuButton) widgetMenuButton.click()
    }
    if ((index === 1 && !isPrev) || (index === 2 && isPrev)) {
      const widgetMenuButton = document.querySelector(
        '#widgets-menu>button:nth-child(1)'
      )
      if (widgetMenuButton) widgetMenuButton.click()

      const actionsMenuButton = document.querySelector(
        '#actions-menu>button:nth-child(1)'
      )
      if (actionsMenuButton) actionsMenuButton.click()
    }
    if ((index === 2 && !isPrev) || (index === 3 && isPrev)) {
      toggleSortings()
      const actionsMenuButton = document.querySelector(
        '#actions-menu>button:nth-child(1)'
      )
      if (actionsMenuButton) actionsMenuButton.click()
      if (index === 2) return setTimeout(() => nextOrPrev(index, isPrev), 1000)
      else nextOrPrev(index, isPrev)
    }

    if (index === 4 && !isPrev) {
      const tableRow = document.querySelector('.actions-table-row')
      if (tableRow) tableRow.style.opacity = 1
      const pTag = document.querySelector('.actions-table-row+p')
      if (pTag) pTag.style.opacity = 0
    }
    if ((index === 6 && !isPrev) || (index === 5 && isPrev)) {
      const tableRow = document.querySelector('.actions-table-row')
      if (tableRow) tableRow.style.opacity = ''
      const pTag = document.querySelector('.actions-table-row+p')
      if (pTag) pTag.style.opacity = 1
    }

    if ((index === 5 && !isPrev) || (index === 6 && isPrev)) {
      if (isSortingsModalOpened) toggleSortings()
    }

    if (index === 6 && !isPrev) {
      onCurationAI()
      return setPendingView({
        index,
        widgetName: 'cluster-view',
        childNum: 1,
        scrollIntoView: true,
        callback: () => nextOrPrev(index, isPrev)
      })
    }

    if (index === 7 && isPrev) {
      cleanView('cluster-view')
    }

    if (index === 7 && !isPrev) {
      cleanView('cluster-view')
      return setPendingView({
        index,
        widgetName: 'similarity-view',
        childNum: 2,
        scrollIntoView: false,
        callback: () => nextOrPrev(index, isPrev)
      })
    }

    if (index === 8 && isPrev) {
      cleanView('similarity-view')
      return setPendingView({
        index,
        widgetName: 'cluster-view',
        childNum: 1,
        scrollIntoView: true,
        callback: () => nextOrPrev(index, isPrev)
      })
    }

    if ((index === 9 && !isPrev) || (index === 8 && !isPrev))
      cleanView('similarity-view', 2)

    nextOrPrev(index, isPrev)
  }

  return { nextTour }
}
