import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BrainIcon from '../Icons/BrainIcon'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'inherit',
    background: theme.palette.colors.backgrounds.main
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingLeft: 40
  },
  mainTitle: {
    fontSize: 69,
    fontWeight: 400,
    lineHeight: '76px',
    color: theme.palette.colors.neutral.black
  },
  title: {
    fontWeight: 400,
    fontSize: 41,
    color: theme.palette.colors.neutral.black,
    margin: '8px 0'
  },
  text: {
    color: theme.palette.colors.neutral.contrast,
    fontSize: 23
  },
  brainIcon: {
    marginRight: '-50px'
  }
}))

function ErrorPageLayout({ title, subTitle, content, children }) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.root}>
        <Typography className={classes.mainTitle}>{title}</Typography>
        <Typography className={classes.title}>{subTitle}</Typography>
        <Typography className={classes.text}>{content}</Typography>

        {children}
      </Box>
      <Box className={classes.brainIcon}>
        <BrainIcon />
      </Box>
    </Box>
  )
}

export default ErrorPageLayout
