import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { MODAL_TYPE, MODALS_ID } from '../../../../../../utils/consts'
import useToggleModal from '../../../../../../hooks/useToggleModal'
import CustomModal from '../../../../../CustomModal'
import { Error } from '@material-ui/icons'
import ActionButton from '../../../../../ActionButton'

const useStyles = makeStyles(theme => ({
  message: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.colors.neutral.black
  }
}))

const ConfirmActionsModal = () => {
  const classes = useStyles()
  const modalID = MODALS_ID.SPIKESORTING_ACTION_CONFIRM_MODAL
  const { toggle, isOpened, modalState } = useToggleModal(modalID)
  const { onConfirm, type } = modalState?.args || {}
  const title = type === 'stop' ? 'Stop sorting' : 'Delete sorting'

  return (
    <CustomModal
      open={isOpened}
      title={title}
      type={MODAL_TYPE.ERROR}
      onClose={toggle}
      maxWidth={600}
      headerIcon={<Error />}
      renderActionButton={
        <ActionButton
          label={title}
          onClick={() => {
            toggle()
            return onConfirm()
          }}
        />
      }
    >
      <Typography className={classes.message}>
        Are you sure you want to {type} this item ?
      </Typography>
    </CustomModal>
  )
}

export default ConfirmActionsModal
