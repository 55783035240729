import React from 'react'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import useStyles from './style'
import HeadTable from './HeadTable'
import BodyColumn from './BodyColumn'
import cn from 'classnames'
import FilesPagination from '../FilesTable/components/FilesPagination'
import CustomCheckBox from '../FormComponents/CustomCheckbox/CustomCheckbox'
import BlurLoader from '../BlurLoader'
import TableShadows from '../FilesTable/components/TableShadows'
import useTableShadowsHandler from '../FilesTable/components/TableShadows/hooks/useTableShadowsHandler'
import EmptyFilesTable from '../FilesTable/components/EmptyFilesTable/EmptyFilesTable'
import { isEmpty } from 'lodash/fp'
import { InsertDriveFile } from '@material-ui/icons'

const CustomTable = ({
  pages,
  columns,
  rows = [],
  rowHeight = 50,
  cellWidth = 200,
  customQparams = {},
  loading = false,
  onClickRow = () => null,
  className = null,
  isSticky = false,
  statusMinWidth = null,
  withBorders = true,
  withPagination = false,
  withCheckbox = false,
  withRecordType = false,
  children,
  isSelected = () => false,
  withPerPageSelector = false,
  savePage = false,
  renderActionButton = null
}) => {
  const classes = useStyles({ height: rowHeight, withPagination, withBorders })
  const { onScroll, isRightShadow, isLeftShadow } = useTableShadowsHandler()

  const isCheckbox = withCheckbox && onClickRow

  if (!loading && isEmpty(rows)) {
    return (
      <div className={classes.emptyRoot}>
        <EmptyFilesTable />
      </div>
    )
  }

  return (
    <div className={cn(classes.root, className)}>
      {children}
      {isSticky && (
        <TableShadows
          classes={classes}
          isLeftShadow={isLeftShadow}
          isRightShadow={isRightShadow}
        />
      )}
      <div className={classes.tableRoot} onScroll={onScroll}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <HeadTable
            isSticky={isSticky}
            withCheckbox={withCheckbox}
            withRecordType={withRecordType}
            classes={classes}
            columns={columns}
            cellWidth={cellWidth}
          />
          <TableBody>
            {loading && <BlurLoader />}
            {rows &&
              rows.map((item, i) => {
                const { id, initial, ...rest } = item || {}
                const selected = isSelected(id)
                return (
                  <TableRow
                    key={i}
                    id={`table-row${i}`}
                    className={cn(classes.row, {
                      [classes.initial]: initial
                    })}
                    selected={selected}
                    onClick={() => onClickRow(id)}
                    classes={{
                      selected: classes.activeRow
                    }}
                  >
                    {isCheckbox && (
                      <TableCell
                        className={cn({
                          [classes.sticky]: isSticky
                        })}
                        style={{ left: isSticky ? 0 : 'auto' }}
                      >
                        <div style={{ width: 50 }}>
                          <CustomCheckBox
                            size={24}
                            onChange={() => onClickRow(id, true)}
                            checked={selected}
                          />
                        </div>
                      </TableCell>
                    )}
                    {withRecordType && (
                      <TableCell style={{ padding: 0 }}>
                        <div className={cn(classes.recordType, classes.center)}>
                          <InsertDriveFile className={classes.fileIcon} />
                        </div>
                      </TableCell>
                    )}
                    <BodyColumn
                      id={id}
                      isSticky={isSticky}
                      isCheckbox={isCheckbox}
                      statusMinWidth={statusMinWidth}
                      renderActionButton={renderActionButton}
                      classes={classes}
                      {...rest}
                    />
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </div>
      {withPagination && (
        <FilesPagination
          pages={pages}
          withPerPageSelector={withPerPageSelector}
          savePage={savePage}
          customQparams={customQparams}
        />
      )}
    </div>
  )
}

export default CustomTable
