import React, { useEffect, useMemo } from 'react'
import { theme } from '../theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import Routes from '../Routes'
import { connect } from 'react-redux'
import SnackbarManager from './SnackbarManager'
import Storage from './AppHeader/components/Storage'

const App = ({ darkMode }) => {
  const themeMemo = useMemo(
    () => theme(darkMode ? 'dark' : 'light'),
    [darkMode]
  )

  useEffect(() => {
    Storage.initStorage(window.localStorage)
  }, [])

  return (
    <ThemeProvider theme={themeMemo}>
      <CssBaseline />
      <SnackbarManager />
      <Routes />
    </ThemeProvider>
  )
}

const mapStateToProps = state => ({
  darkMode: state.darkMode
})

export const AppConnected = connect(mapStateToProps)(App)
