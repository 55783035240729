import React from 'react'
import { makeStyles } from '@material-ui/core'
import { ROUTES } from '../../utils/consts'
import { Route, Switch } from 'react-router-dom'
import TrainAIFilesTable from './components/TrainAIFilesTable'
import ExistingAiTable from './components/ExistingAITable/ExistingAITable'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    padding: 40,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.colors.backgrounds.files
  }
}))

const TrainAI = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Switch>
        <Route exact path={ROUTES.TRAIN_AI} component={TrainAIFilesTable} />
        <Route
          exact
          path={ROUTES.TRAIN_AI_EXISTING}
          component={ExistingAiTable}
        />
      </Switch>
    </div>
  )
}

export default TrainAI
