export function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n)
  }
}

export const arrFromNumber = n => Array.from(Array(n).keys())

export const closest = (num, arr) => {
  var curr = arr[0]
  var diff = Math.abs(num - curr)
  for (var val = 0; val < arr.length; val++) {
    var newdiff = Math.abs(num - arr[val])
    if (newdiff < diff) {
      diff = newdiff
      curr = arr[val]
    }
  }
  return curr
}

export const activeChannels = selectedGroups =>
  selectedGroups
    .map(({ from, to }) => {
      return {
        from,
        to
      }
    })
    .reduce((acc, v) => {
      const rest = acc.length === 1 && acc.includes(0) ? [] : acc
      const channels = Array.from(Array(v.to + 1).keys())
      return [...rest, ...channels.slice(v.from)]
    }, [])

export const uniqBy = (arr, fn, obj = {}) => (
  arr
    .reverse()
    .forEach(
      item => (obj[typeof fn === 'function' ? fn(item) : item[fn]] = item)
    ),
  Object.values(obj)
)

export const chunkGroups = (arr, by = 16) => [...chunks(arr, by)]

export const parseSelectedGroups = val => {
  const min = val[0]
  const max = val[val.length - 1]
  const parsed = `${min}-${max}`

  return {
    key: parsed,
    value: parsed,
    from: min,
    to: max
  }
}

export const arrToObject = parser => arr =>
  arr.reduce((acc, v) => parser(acc, v), {})

export const betweenRange = (x, min, max) => {
  return x >= min && x <= max
}

export const getPrevNextValueStatus = (arr, currentValue) => {
  let timeStampIndex = arr.findIndex(t => t.timestamp === currentValue)
  const hasPrevValue = timeStampIndex - 1 !== -1
  const hasNextValue = timeStampIndex + 1 < arr.length

  return { hasPrevValue, hasNextValue }
}
