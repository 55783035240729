import React, { memo, useCallback } from 'react'
import { IconButton, makeStyles, Typography } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import Grid from '@material-ui/core/Grid'
import { HelpOutline, InfoOutlined } from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { startTour } from '../../../../../../../actions/tours'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 2,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  text: {
    fontSize: 10,
    fontWeight: 400,
    lineHeight: '16px',
    fontStyle: 'italic',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[3],
      theme.palette.colors.neutral[7]
    )
  },
  tooltipText: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '16px',
    cursor: 'pointer',
    paddingLeft: '1ch',
    color: theme.palette.colors.lighterGreen
  },
  linkText: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: '16px',
    cursor: 'pointer',
    color: theme.palette.colors.customColor(
      theme.palette.colors.hyperlinkBlue,
      theme.palette.colors.accent[5]
    )
  },
  popper: {
    zIndex: 1
  },
  tooltip: {
    width: 295,
    height: 129,
    padding: 16,
    borderRadius: 8,
    border: `1px solid ${theme.palette.colors.lighterGreen}`,
    lineHeight: '18px',
    fontWeight: 600,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.colors.mainColor,
    background: theme.palette.colors.backgrounds.main,
    '&::before': {
      border: `1px solid ${theme.palette.colors.lighterGreen}`
    }
  },
  arrow: {
    fontSize: 22,
    background: theme.palette.colors.backgrounds.main,
    '&::before': {
      background: theme.palette.colors.backgrounds.main,
      border: `1px solid ${theme.palette.colors.lighterGreen}`
    }
  },
  header: {
    alignItems: 'center',
    paddingBottom: 8,
    justifyContent: 'space-between',
    flexDirection: 'row',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[3],
      'white'
    )
  },
  tooltipIcon: {
    height: 16,
    width: 16,
    cursor: 'pointer',
    marginRight: 12,
    color: theme.palette.colors.neutral[5],
    '& svg': {
      height: 16,
      width: 16
    },
    '&:hover': {
      color: theme.palette.colors.customColor(
        theme.palette.colors.black_27,
        'white'
      )
    }
  }
}))

const CurationAiCredits = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleStartTour = useCallback(() => dispatch(startTour()), [dispatch])

  return (
    <Tooltip
      arrow
      interactive
      disableFocusListener
      TransitionComponent={Zoom}
      title={
        <TooltipBody classes={classes} handleStartTour={handleStartTour} />
      }
      classes={{
        popper: classes.popper,
        arrow: classes.arrow,
        tooltip: classes.tooltip
      }}
    >
      <InfoOutlined className={classes.tooltipIcon} />
    </Tooltip>
  )
}

const TooltipBody = memo(({ classes, handleStartTour }) => {
  const link = 'https://doi.org/10.5281/zenodo.7303380'
  return (
    <Grid container>
      <Grid container className={classes.header}>
        <Typography className={classes.curationAILabel}>
          AI curation Beta
        </Typography>
        <IconButton className={classes.tooltipIcon} onClick={handleStartTour}>
          <HelpOutline />
        </IconButton>
      </Grid>
      <Typography className={classes.text}>
        Based on recent work from the Stark lab. Spivak Lidor, Remenick Tali, &
        Stark Eran. (2022). Automated spike sorting curation. Zenodo.
      </Typography>

      <Typography
        onClick={() => window.open(link)}
        className={classes.linkText}
      >
        {link}
      </Typography>
    </Grid>
  )
})

export default CurationAiCredits
