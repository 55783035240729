import React from 'react'
import { fade } from '@material-ui/core'

export const svg64_13 = ({ color, svgRef, flipped, preview = false }) => {
  if (preview) {
    return (
      <svg
        width="86"
        height="427"
        viewBox="0 0 86 427"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
      >
        <path
          d="M3.90909 348.179L43 427L82.0909 348.179V52.1337L86 32.2733V0H0V32.2733L3.90909 52.1337V348.179Z"
          fill={color}
        />
        <path
          d="M52 15.7056C52 14.0487 50.6569 12.7056 49 12.7056H37C35.3431 12.7056 34 14.0487 34 15.7056V26.7825C34 28.4393 35.3431 29.7825 37 29.7825H49C50.6569 29.7825 52 28.4393 52 26.7825V15.7056Z"
          fill="white"
        />
        <path
          d="M31 32.7827C31 31.1259 29.6569 29.7827 28 29.7827H16C14.3431 29.7827 13 31.1259 13 32.7827V43.8596C13 45.5165 14.3431 46.8596 16 46.8596H28C29.6569 46.8596 31 45.5165 31 43.8596V32.7827Z"
          fill="white"
        />
        <path
          d="M73 32.7827C73 31.1259 71.6569 29.7827 70 29.7827H58C56.3431 29.7827 55 31.1259 55 32.7827V43.8596C55 45.5165 56.3431 46.8596 58 46.8596H70C71.6569 46.8596 73 45.5165 73 43.8596V32.7827Z"
          fill="white"
        />
        <path
          d="M52 49.8594C52 48.2025 50.6569 46.8594 49 46.8594H37C35.3431 46.8594 34 48.2025 34 49.8594V60.9363C34 62.5931 35.3431 63.9363 37 63.9363H49C50.6569 63.9363 52 62.5931 52 60.9363V49.8594Z"
          fill="white"
        />
        <path
          d="M31 66.9365C31 65.2797 29.6569 63.9365 28 63.9365H16C14.3431 63.9365 13 65.2797 13 66.9365V78.0134C13 79.6703 14.3431 81.0134 16 81.0134H28C29.6569 81.0134 31 79.6703 31 78.0134V66.9365Z"
          fill="white"
        />
        <path
          d="M73 66.9365C73 65.2797 71.6569 63.9365 70 63.9365H58C56.3431 63.9365 55 65.2797 55 66.9365V78.0134C55 79.6703 56.3431 81.0134 58 81.0134H70C71.6569 81.0134 73 79.6703 73 78.0134V66.9365Z"
          fill="white"
        />
        <path
          d="M52 84.0132C52 82.3563 50.6569 81.0132 49 81.0132H37C35.3431 81.0132 34 82.3563 34 84.0132V95.0901C34 96.747 35.3431 98.0901 37 98.0901H49C50.6569 98.0901 52 96.747 52 95.0901V84.0132Z"
          fill="white"
        />
        <path
          d="M31 101.09C31 99.4335 29.6569 98.0903 28 98.0903H16C14.3431 98.0903 13 99.4335 13 101.09V112.167C13 113.824 14.3431 115.167 16 115.167H28C29.6569 115.167 31 113.824 31 112.167V101.09Z"
          fill="white"
        />
        <path
          d="M73 101.09C73 99.4335 71.6569 98.0903 70 98.0903H58C56.3431 98.0903 55 99.4335 55 101.09V112.167C55 113.824 56.3431 115.167 58 115.167H70C71.6569 115.167 73 113.824 73 112.167V101.09Z"
          fill="white"
        />
        <path
          d="M52 118.167C52 116.51 50.6569 115.167 49 115.167H37C35.3431 115.167 34 116.51 34 118.167V129.244C34 130.901 35.3431 132.244 37 132.244H49C50.6569 132.244 52 130.901 52 129.244V118.167Z"
          fill="white"
        />
        <path
          d="M31 135.244C31 133.587 29.6569 132.244 28 132.244H16C14.3431 132.244 13 133.587 13 135.244V146.321C13 147.978 14.3431 149.321 16 149.321H28C29.6569 149.321 31 147.978 31 146.321V135.244Z"
          fill="white"
        />
        <path
          d="M73 135.244C73 133.587 71.6569 132.244 70 132.244H58C56.3431 132.244 55 133.587 55 135.244V146.321C55 147.978 56.3431 149.321 58 149.321H70C71.6569 149.321 73 147.978 73 146.321V135.244Z"
          fill="white"
        />
        <path
          d="M52 152.321C52 150.664 50.6569 149.321 49 149.321H37C35.3431 149.321 34 150.664 34 152.321V163.398C34 165.055 35.3431 166.398 37 166.398H49C50.6569 166.398 52 165.055 52 163.398V152.321Z"
          fill="white"
        />
        <path
          d="M31 169.398C31 167.741 29.6569 166.398 28 166.398H16C14.3431 166.398 13 167.741 13 169.398V180.475C13 182.132 14.3431 183.475 16 183.475H28C29.6569 183.475 31 182.132 31 180.475V169.398Z"
          fill="white"
        />
        <path
          d="M73 169.398C73 167.741 71.6569 166.398 70 166.398H58C56.3431 166.398 55 167.741 55 169.398V180.475C55 182.132 56.3431 183.475 58 183.475H70C71.6569 183.475 73 182.132 73 180.475V169.398Z"
          fill="white"
        />
        <path
          d="M52 186.475C52 184.818 50.6569 183.475 49 183.475H37C35.3431 183.475 34 184.818 34 186.475V197.552C34 199.208 35.3431 200.552 37 200.552H49C50.6569 200.552 52 199.208 52 197.552V186.475Z"
          fill="white"
        />
        <path
          d="M31 203.552C31 201.895 29.6569 200.552 28 200.552H16C14.3431 200.552 13 201.895 13 203.552V214.629C13 216.286 14.3431 217.629 16 217.629H28C29.6569 217.629 31 216.286 31 214.629V203.552Z"
          fill="white"
        />
        <path
          d="M73 203.552C73 201.895 71.6569 200.552 70 200.552H58C56.3431 200.552 55 201.895 55 203.552V214.629C55 216.286 56.3431 217.629 58 217.629H70C71.6569 217.629 73 216.286 73 214.629V203.552Z"
          fill="white"
        />
        <path
          d="M52 220.628C52 218.972 50.6569 217.628 49 217.628H37C35.3431 217.628 34 218.972 34 220.628V231.705C34 233.362 35.3431 234.705 37 234.705H49C50.6569 234.705 52 233.362 52 231.705V220.628Z"
          fill="white"
        />
        <path
          d="M31 237.706C31 236.049 29.6569 234.706 28 234.706H16C14.3431 234.706 13 236.049 13 237.706V248.782C13 250.439 14.3431 251.782 16 251.782H28C29.6569 251.782 31 250.439 31 248.782V237.706Z"
          fill="white"
        />
        <path
          d="M73 237.706C73 236.049 71.6569 234.706 70 234.706H58C56.3431 234.706 55 236.049 55 237.706V248.782C55 250.439 56.3431 251.782 58 251.782H70C71.6569 251.782 73 250.439 73 248.782V237.706Z"
          fill="white"
        />
        <path
          d="M52 254.782C52 253.125 50.6569 251.782 49 251.782H37C35.3431 251.782 34 253.125 34 254.782V265.859C34 267.516 35.3431 268.859 37 268.859H49C50.6569 268.859 52 267.516 52 265.859V254.782Z"
          fill="white"
        />
        <path
          d="M31 271.859C31 270.203 29.6569 268.859 28 268.859H16C14.3431 268.859 13 270.203 13 271.859V282.936C13 284.593 14.3431 285.936 16 285.936H28C29.6569 285.936 31 284.593 31 282.936V271.859Z"
          fill="white"
        />
        <path
          d="M73 271.859C73 270.203 71.6569 268.859 70 268.859H58C56.3431 268.859 55 270.203 55 271.859V282.936C55 284.593 56.3431 285.936 58 285.936H70C71.6569 285.936 73 284.593 73 282.936V271.859Z"
          fill="white"
        />
        <path
          d="M52 288.937C52 287.28 50.6569 285.937 49 285.937H37C35.3431 285.937 34 287.28 34 288.937V300.013C34 301.67 35.3431 303.013 37 303.013H49C50.6569 303.013 52 301.67 52 300.013V288.937Z"
          fill="white"
        />
        <path
          d="M31 306.013C31 304.356 29.6569 303.013 28 303.013H16C14.3431 303.013 13 304.356 13 306.013V317.09C13 318.747 14.3431 320.09 16 320.09H28C29.6569 320.09 31 318.747 31 317.09V306.013Z"
          fill="white"
        />
        <path
          d="M73 306.013C73 304.356 71.6569 303.013 70 303.013H58C56.3431 303.013 55 304.356 55 306.013V317.09C55 318.747 56.3431 320.09 58 320.09H70C71.6569 320.09 73 318.747 73 317.09V306.013Z"
          fill="white"
        />
        <path
          d="M52 323.09C52 321.433 50.6569 320.09 49 320.09H37C35.3431 320.09 34 321.433 34 323.09V334.167C34 335.824 35.3431 337.167 37 337.167H49C50.6569 337.167 52 335.824 52 334.167V323.09Z"
          fill="white"
        />
        <path
          d="M31 340.167C31 338.51 29.6569 337.167 28 337.167H16C14.3431 337.167 13 338.51 13 340.167V351.244C13 352.901 14.3431 354.244 16 354.244H28C29.6569 354.244 31 352.901 31 351.244V340.167Z"
          fill="white"
        />
        <path
          d="M73 340.167C73 338.51 71.6569 337.167 70 337.167H58C56.3431 337.167 55 338.51 55 340.167V351.244C55 352.901 56.3431 354.244 58 354.244H70C71.6569 354.244 73 352.901 73 351.244V340.167Z"
          fill="white"
        />
        <path
          d="M52 357.244C52 355.587 50.6569 354.244 49 354.244H37C35.3431 354.244 34 355.587 34 357.244V368.321C34 369.978 35.3431 371.321 37 371.321H49C50.6569 371.321 52 369.978 52 368.321V357.244Z"
          fill="white"
        />
        <path
          d="M52 390.321C52 388.664 50.6569 387.321 49 387.321H37C35.3431 387.321 34 388.664 34 390.321V401.398C34 403.055 35.3431 404.398 37 404.398H49C50.6569 404.398 52 403.055 52 401.398V390.321Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="86"
      height="427"
      viewBox="0 0 86 427"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        d="M3.90909 348.179L43 427L82.0909 348.179V52.1337L86 32.2733V0H0V32.2733L3.90909 52.1337V348.179Z"
        fill={flipped ? fade(color, 0.5) : color}
      />
    </svg>
  )
}
