import React, { useState } from 'react'
import { makeStyles, IconButton } from '@material-ui/core'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import cn from 'classnames'
import WidgetHeader from './WidgetHeader'
import { useDrag } from '../contexts/drag'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    border: `1px solid ${theme.palette.colors.neutral[7]}`,
    flexDirection: 'column',
    borderRadius: 4,
    overflow: 'hidden'
  },
  hide: {
    opacity: 0.3
  },
  root: {
    flex: 1,
    overflow: 'auto',
    height: '100%',
    width: '100%',
    ...theme.scrollbar(5)
  },
  iconBtn: {
    padding: '0 4px',
    color: theme.palette.primary.text,
    '&:hover': {
      color: theme.palette.primary.light
    }
  },
  iconRoot: {
    width: 16
  }
}))

const WidgetWrapper = ({
  children,
  id,
  label,
  widgetId,
  clearWidget,
  actions,
  moveWidget,
  plugins
}) => {
  const [hide, setHide] = useState(false)
  const classes = useStyles()
  const actionWidget = actions[widgetId] ?? []
  const { source, setSource, destination } = useDrag()

  const refS = React.useRef(null)
  const WH = React.useRef([0, 0])
  const [, _] = React.useState(0)

  React.useEffect(() => {
    if (refS.current) {
      const BB = refS.current.getBoundingClientRect()

      WH.current = [BB.width, BB.height]

      _(current => current + 1)
    }
  }, [refS.current])

  const onDragStart = () => {
    setSource(id)
    if (!hide) setHide(true)
  }

  const onDragEnd = () => {
    if (hide) setHide(false)
    moveWidget(source, destination)
  }

  return (
    <div
      className={cn(classes.wrapper, {
        [classes.hide]: hide
      })}
      ref={refS}
      draggable
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <WidgetHeader widget={label}>
        {plugins?.header && <plugins.header />}
        <div>
          {actionWidget.map(action => (
            <action.wrapper key={action.id}>
              <IconButton key={action.id} className={classes.iconBtn}>
                <action.children classes={{ root: classes.iconRoot }} />
              </IconButton>
            </action.wrapper>
          ))}
          <IconButton className={classes.iconBtn} onClick={clearWidget}>
            <ClearOutlinedIcon
              classes={{
                root: classes.iconRoot
              }}
            />
          </IconButton>
        </div>
      </WidgetHeader>
      <div className={classes.root}>
        {React.cloneElement(children, {
          W: WH.current[0],
          H: WH.current[1]
        })}
      </div>
    </div>
  )
}

export default WidgetWrapper
