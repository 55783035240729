import React, { useCallback } from 'react'
import {
  fromHumanTime,
  getMiliseconds,
  msToTime,
  toHumanTime
} from '../../../../fns'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import EditableText from '../EditableText'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginRight: 8
  },
  editBox: {
    padding: '2px 8px',
    border: `1px solid ${theme.palette.colors.neutral['5']}`,
    marginLeft: 8,
    whiteSpace: 'nowrap',
    position: 'relative',
    fontSize: 13,
    lineHeight: '20px',
    overflow: 'hidden',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['4'],
      theme.palette.colors.neutral['9']
    ),
    cursor: 'pointer',
    borderRadius: 8
  },
  label: {
    fontSize: 13,
    marginRight: 4,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  }
}))

const CurrentTimeControl = props => {
  const { currentTime, samplerate, onChange } = props
  const classes = useStyles()

  const time = toHumanTime(currentTime, samplerate, {
    nounits: true
  })

  const parsedTime = time !== 'none' ? msToTime(time * 1000) : null

  const { minutes, seconds, milliseconds } = parsedTime || {}

  const _handleChange = useCallback(
    (txt, type = 'min') => {
      const ms = getMiliseconds({
        min: type === 'min' ? txt : minutes?.val,
        sec: type === 'sec' ? txt : seconds?.val,
        ms: type === 'ms' ? txt : milliseconds?.val
      })
      let t = fromHumanTime(ms, samplerate, { nounits: true })
      if (t) onChange(t)
    },
    [milliseconds?.val, minutes?.val, onChange, samplerate, seconds?.val]
  )

  if (!parsedTime) return null

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>Time:</Typography>
      <EditableText
        title="Click to edit current time"
        text={minutes?.val}
        label={minutes?.label}
        onChange={_handleChange}
      />
      <EditableText
        title="Click to edit current time"
        text={seconds?.val}
        label={seconds?.label}
        onChange={_handleChange}
      />
      <EditableText
        title="Click to edit current time"
        text={milliseconds?.val}
        label={milliseconds?.label}
        onChange={_handleChange}
      />
    </div>
  )
}

export default CurrentTimeControl
