import React, { memo, useMemo } from 'react'
import FilesSnackbar from '../../../../../FilesTable/components/FilesSnackbar'
import { makeStyles, useTheme } from '@material-ui/core'
import ActionButton from '../../../../../ActionButton'
import BrainIcon from '../../../../../Icons/BrainIcon'
import { MIN_TRAINING_DATASETS } from '../../../../hooks/consts'

const useStyles = makeStyles(() => ({
  children: {
    display: 'flex',
    alignItems: 'center'
  },
  snackbarText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px'
  },
  snackbar: {
    maxWidth: 368
  },
  brainIcon: ({ disabled }) => ({
    filter:
      disabled &&
      'invert(52%) sepia(9%) saturate(737%) hue-rotate(187deg) brightness(94%) contrast(94%)'
  })
}))

const ModelsSnackbar = ({ selected, deselectAll, toggleTrainModal }) => {
  const theme = useTheme()
  const disabled = selected?.length < MIN_TRAINING_DATASETS
  const classes = useStyles({ disabled })

  const actionButtonStyles = useMemo(
    () => ({
      width: 'auto',
      borderRadius: 8,
      marginRight: 7,
      marginLeft: 18,
      padding: '6px 16px',
      backgroundColor: theme.palette.colors.lighterGreen,
      color: disabled ? '#777f96' : 'white'
    }),
    [disabled]
  )

  const TrainButton = memo(() => {
    return (
      <ActionButton
        label="Train AI model"
        onClick={toggleTrainModal}
        disabled={disabled}
        overideClasses={classes}
        className={classes.trainAiButton}
        customStyles={actionButtonStyles}
        startIcon={
          <BrainIcon className={classes.brainIcon} height={20} width={20} />
        }
      />
    )
  })

  return (
    <FilesSnackbar
      _classes={classes}
      length={selected.length}
      deselectAll={deselectAll}
    >
      <TrainButton />
    </FilesSnackbar>
  )
}

export default memo(ModelsSnackbar)
