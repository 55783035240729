import React, { useEffect } from 'react'
import { SizeMe } from 'react-sizeme'
import TimeSeries from './TimeSeries'
import RecordNotFound from './components/RecordNotFound'
import { MAIN_APPBAR_HEIGHT } from '../../utils/styles'

const equal = (prev, next) => {
  return prev.recordingId === next.recordingId
}

export default React.memo(({ recordingId }) => {
  useEffect(() => {
    // reset child window ranges
    setTimeout(() => {
      localStorage.removeItem(`child-windows-${recordingId}`)
    }, 500)
  })

  if (!recordingId) return <RecordNotFound recordingId={recordingId} />

  return (
    <SizeMe>
      {({ size }) => {
        const { width } = size
        const height = window.innerHeight
        const childOuterHeight = window.outerHeight - MAIN_APPBAR_HEIGHT
        const childWindow = {
          height,
          outerWidth: window.outerWidth,
          outerHeight: childOuterHeight
        }
        localStorage.setItem('childWindow', JSON.stringify(childWindow))

        return (
          <TimeSeries recordingId={recordingId} width={width} height={height} />
        )
      }}
    </SizeMe>
  )
}, equal)
