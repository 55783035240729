import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MapData from '../../../../../../../utils/MapData/MapData'
import { Grid } from '@material-ui/core'
import { hexToRgba } from '../../../../../../../utils/styles'
import CustomSwitch from '../../../../../../CustomSwitch'
import DrawProbe, { isOffsetException } from '../../../DrawProbe/DrawProbe'
import Channel from '../../../channel'

const useStyles = makeStyles(theme => ({
  root: ({ multigroup, draw }) => ({
    margin: '0 14px 24px',
    transform: multigroup && !isOffsetException(draw) && `translateY(-10px)`
  }),
  toggle: ({ color, width }) => ({
    height: 25,
    width: width,
    padding: '0 8px',
    backgroundColor: hexToRgba(color, 0.25),
    margin: 'auto',
    justifyContent: 'center'
  }),
  biochip: ({ height }) => ({
    position: 'relative',
    height,
    margin: 'auto'
  })
}))

const Channels = ({
  multigroup,
  on,
  channels,
  onSwitch,
  color,
  selectedChannels,
  toggleChannel,
  draw,
  probeFileName,
  readOnly = false
}) => {
  const classes = useStyles({
    color,
    multigroup,
    draw
  })

  const [svgDimensions, setDimensions] = React.useState({
    width: '100%',
    height: 835
  })
  const drawRef = React.useRef(null)

  React.useLayoutEffect(() => {
    setDimensions({
      width: drawRef.current?.getBoundingClientRect().width,
      height: drawRef.current?.getBoundingClientRect().height
    })
  }, [drawRef])

  return (
    <div className={classes.root}>
      <Grid
        wrap="nowrap"
        container
        alignItems="center"
        justify="space-between"
        className={classes.toggle}
        style={{ width: svgDimensions.width }}
      >
        <CustomSwitch checked={on} disabled={readOnly} onChange={onSwitch} />
      </Grid>

      <div
        className={classes.biochip}
        style={{
          width: svgDimensions.width,
          height: svgDimensions.height,
          transform: multigroup && 'scale(-1,1)'
        }}
      >
        <DrawProbe
          multigroup={multigroup}
          ref={drawRef}
          color={color}
          probeFile={draw}
        />
        <MapData data={channels}>
          {({ item: { x, y, key, label }, index }) => (
            <Channel
              index={index}
              channelKey={key}
              x={x}
              y={y}
              draw={draw}
              label={label}
              readOnly={readOnly}
              multigroup={multigroup}
              selectedChannels={selectedChannels}
              toggleChannel={toggleChannel}
            />
          )}
        </MapData>
      </div>
    </div>
  )
}

export default memo(Channels)
