import React, { useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Typography, makeStyles } from '@material-ui/core'
import CustomModal from '../../../CustomModal'
import ActionButton from '../../../ActionButton'
import FullScreenLoader from '../../../FullScreenLoader'
import { closeModal } from '../../../../actions/modals'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List
} from 'react-virtualized'
import useLogs from './hooks/useLogs'

const MODAL = 'spikesorting_log_modal'

const useStyles = makeStyles(theme => ({
  logScreen: {
    background: theme.palette.colors.neutral[8],
    border: '1px solid #6D7580',
    borderRadius: 8,
    minHeight: '75vh',
    maxHeight: '75vh',
    overflowY: 'auto',
    padding: 10
  },
  content: {
    color: theme.palette.colors.black
  }
}))

const LogsModal = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const openModalState = useSelector(state => state.modals?.get(MODAL))
  const onClose = () => dispatch(closeModal({ id: MODAL }))

  const { state, fetchData, loadMore, hasMore, resetData } = useLogs(
    openModalState?.args?.spikesortingId,
    Boolean(openModalState)
  )

  const { data: result, loading, error } = state || {}
  const { data } = result || {}

  return (
    <CustomModal
      open={Boolean(openModalState)}
      onClose={onClose}
      title="Sorting Log"
      maxWidth="85%"
      renderActionButton={
        <ActionButton
          label="Reload"
          onClick={e => {
            e.stopPropagation()
            fetchData()
          }}
        />
      }
    >
      <div className={classes.logScreen}>
        <LogsViewBody
          loading={loading && !data}
          loadMore={loading && Boolean(data)}
          error={error}
        >
          <InfiniteLogs
            data={data}
            loadMore={loadMore}
            hasMore={hasMore}
            resetData={resetData}
          />
        </LogsViewBody>
      </div>
    </CustomModal>
  )
}

function InfiniteLogs({ data, loadMore, hasMore, resetData }) {
  const classes = useStyles()
  const dataParsed = parseData(data)

  let _cache = useMemo(
    () =>
      new CellMeasurerCache({
        minHeight: 1,
        fixedWidth: true
      }),
    []
  )

  useEffect(() => {
    return () => resetData()
  }, [])

  const rowRenderer = useCallback(
    ({ parent, key, index, style }) => {
      return (
        <CellMeasurer
          cache={_cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
          width={0}
        >
          <Typography key={key} style={style} className={classes.content}>
            {dataParsed[index]}
          </Typography>
        </CellMeasurer>
      )
    },
    [_cache, classes.content, dataParsed]
  )

  const loadMoreListener = useCallback(
    e => {
      const { clientHeight, scrollHeight, scrollTop } = e
      const atBottom = scrollTop + clientHeight >= scrollHeight

      if (atBottom && hasMore) loadMore()
    },
    [hasMore, loadMore]
  )

  return (
    <AutoSizer>
      {props => {
        const { width, height } = props
        return (
          <List
            rowCount={dataParsed?.length}
            width={width}
            height={height}
            rowHeight={_cache.rowHeight}
            rowRenderer={rowRenderer}
            overscanRowCount={5}
            onScroll={loadMoreListener}
          />
        )
      }}
    </AutoSizer>
  )
}

function LogsViewBody({ loading, loadMore, error, children }) {
  if (loading) return <FullScreenLoader />
  if (error) return <Typography>An error ocurred</Typography>

  return (
    <>
      {loadMore && <FullScreenLoader />} {children}
    </>
  )
}

function parseData(data) {
  if (data) {
    if (typeof data === 'string') {
      return data.split('|')
    }
  }

  return []
}

export default LogsModal
