import {
  DELETE_COMMENT,
  EDIT_COMMENT,
  INIT_SPIKESORTING,
  SELECT_SPIKESORTING,
  STORE_COMMENTS,
  STORE_RECORD,
  STORE_SPIKESORTING,
  UPDATE_RECORD,
  DELETE_DEFAULT_RUN,
  STORE_EVENT_FILES,
  DELETE_EVENT_FILE,
  RESET_RECORD,
  INIT_RECORD
} from '../actions/recording'
import { mapTags, generateUniqueMapKey } from '../utils/utils'
import { isEmpty, isEqual } from 'lodash/fp'
import {
  DELETE_DEFAULT_CURATION,
  SELECT_CURATION,
  STORE_CURATIONS,
  UPDATE_CURATION,
  UPDATE_SINGLE_CURATIONS
} from '../actions/curations'

const initialState = {
  recording: null,
  tags: [],
  publications: [],
  comments: [],

  // sortings
  sortings: null,
  selectedSorting: {},
  loadingSortings: false,
  sortings_pages: 1,

  // curations
  curations: new Map(),
  loadingCurations: true,
  selectedCuration: null,
  curations_pages: 1,

  //eventFiles
  eventFiles: new Map()
}

const recordingStore = (state = initialState, { type, payload }) => {
  switch (type) {
    case INIT_RECORD: {
      return payload
    }
    case RESET_RECORD: {
      return initialState
    }
    case STORE_RECORD: {
      const { data } = payload || {}
      const { recording, selectedSorting, selectedCuration } = state || {}
      if (isEqual(recording, data)) return state

      const { tags, publications, default_run } = data || {}

      const initCurations = {
        curations: new Map(),
        curations_pages: 1
      }

      return {
        ...state,
        ...initCurations,
        selectedSorting: selectedSorting ?? default_run,
        selectedCuration: selectedCuration ?? default_run?.default_curation,
        loadingCurations: !selectedCuration,
        recording: data,
        tags: mapTags(tags),
        publications: mapTags(publications)
      }
    }
    case UPDATE_RECORD:
      return { ...state, ...payload }
    case INIT_SPIKESORTING:
      return {
        ...state,
        loadingSortings: payload
      }
    case STORE_SPIKESORTING: {
      const { data: sortings, pages = 1 } = payload || {}
      const { recording } = state
      const defaultRun =
        recording?.default_run ?? sortings.find(item => item.default)
      const firstRun = sortings.length > 0 && sortings[0]
      const selected = defaultRun || firstRun
      const selectedCuration = selected?.default_curation

      return {
        ...state,
        sortings,
        selectedSorting: !isEmpty(sortings) && selected,
        ...(selectedCuration && { selectedCuration }),
        loadingSortings: false,
        sortings_pages: pages
      }
    }
    case SELECT_SPIKESORTING: {
      const { id, type } = payload || {}

      const { id: currentSortId } = state?.selectedSorting

      const isDefault = type === 'default'
      const selectedSorting = state.sortings.find(item => item.id === id)
      const default_run = isDefault
        ? { recording: { ...state.recording, default_run: selectedSorting } }
        : {}

      const selectedCuration = default_run?.default_curation

      const isSameSorting =
        (!isDefault && currentSortId === id) ||
        (isDefault && selectedSorting?.id === currentSortId)

      if (isSameSorting) {
        return { ...state, ...default_run }
      }

      return {
        ...state,
        ...default_run,
        selectedSorting,
        selectedCuration,
        loadingCurations: !selectedCuration
      }
    }
    case DELETE_DEFAULT_RUN: {
      const { sortings } = state
      const selectedSorting = sortings.length > 0 && sortings[0]
      return {
        ...state,
        recording: { ...state.recording, default_run: null },
        selectedSorting
      }
    }
    case STORE_COMMENTS:
      return { ...state, comments: payload }
    case DELETE_COMMENT:
      const comments = state.comments.filter(item => item.id !== payload)
      return { ...state, comments }
    case EDIT_COMMENT:
      const { id, value } = payload
      const allComments = state.comments
      const commentIndex = allComments.findIndex(item => item.id === id)
      const updatedItem = {
        ...allComments[commentIndex],
        value
      }
      const updatedComments = [
        ...allComments.slice(0, commentIndex),
        updatedItem,
        ...allComments.slice(commentIndex + 1)
      ]

      return { ...state, comments: updatedComments }

    case STORE_CURATIONS: {
      const {
        recording,
        curations: storedCurations,
        selectedSorting
      } = state || {}
      const { data: curations, pages = 1 } = payload || {}
      const run_id = selectedSorting.id
      const defaultCuration =
        selectedSorting?.default_curation ||
        curations?.find(item => item.default)
      const firstCuration = curations?.length > 0 && curations[0]
      const selected = defaultCuration || firstCuration

      const initialCuration = {
        name: `Initial file ${recording?.name}`,
        initial: true,
        user_name: null,
        single_units: null,
        split_steps: null,
        merge_steps: null,
        created_at: recording?.created_at
      }

      return {
        ...state,
        curations: new Map([
          ...storedCurations,
          [run_id, [initialCuration, ...curations]]
        ]),
        loadingCurations: false,
        selectedCuration: !isEmpty(curations) && selected,
        curations_pages: pages
      }
    }
    case UPDATE_SINGLE_CURATIONS: {
      const {
        curations: storedCurations,
        selectedSorting,
        selectedCuration
      } = state || {}
      const run_id = selectedSorting.id
      const curations = storedCurations.get(run_id)
      // CHECKED IF UPDATED CURATION WAS SELECTED TO UPDATE IT TOO
      const isSelected = selectedCuration.id === payload.id

      const index = curations.findIndex(i => i.id === payload.id)

      const updatedCurations = [
        ...curations.slice(0, index),
        payload,
        ...curations.slice(index + 1)
      ]

      return {
        ...state,
        curations: new Map([...storedCurations, [run_id, updatedCurations]]),
        ...(isSelected ? { selectedCuration: payload } : {})
      }
    }
    case SELECT_CURATION: {
      const { id, type } = payload || {}
      const { curations: storedCurations, recording, selectedSorting } = state
      const isDefault = type === 'default'
      const run_id = selectedSorting.id
      const curations = storedCurations.get(run_id)
      const selectedCuration = !isNaN(id)
        ? curations?.find(item => item.id === id)
        : null

      // is default curation
      const default_run = {
        ...selectedSorting,
        default_curation: selectedCuration
      }
      const default_curation = isDefault
        ? {
            recording: {
              ...recording,
              default_run: default_run,
              default_curation: selectedCuration
            },
            selectedSorting: default_run
          }
        : {}
      return { ...state, ...default_curation, selectedCuration }
    }
    case UPDATE_CURATION:
      return { ...state, ...payload }
    case DELETE_DEFAULT_CURATION: {
      const { curations: storedCurations, selectedSorting, recording } = state
      const run_id = selectedSorting.id
      const curations = storedCurations.get(run_id)
      const selectedCuration = curations.length > 0 && curations[0]

      const default_run = {
        ...selectedSorting,
        default_curation: null
      }

      return {
        ...state,
        recording: {
          ...recording,
          default_run: default_run
        },
        selectedSorting: default_run,
        selectedCuration
      }
    }
    case STORE_EVENT_FILES: {
      const files = payload.reduce((acc, current) => {
        const { file_content, name, id, kind } = current
        if (Object.keys(file_content).length !== 0 || kind) {
          const isNND = kind !== 'event'
          const key = generateUniqueMapKey(acc, name)
          acc.set(key, { id, isNND })
        }
        return acc
      }, new Map())

      return { ...state, eventFiles: files }
    }
    case DELETE_EVENT_FILE: {
      let newEvents = new Map(state.eventFiles)
      newEvents.delete(payload)
      return { ...state, eventFiles: newEvents }
    }
    default:
      return state
  }
}

export default recordingStore
