import {
  Button,
  ClickAwayListener,
  Fade,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
  Typography
} from '@material-ui/core'
import React, { useCallback, useRef, useState } from 'react'
import { ArrowDropDownRounded } from '@material-ui/icons'
import MapData from '../../utils/MapData/MapData'
import cn from 'classnames'
import useLimitedAccess from '../../hooks/useLimitedAccess'
import useToggleModal from '../../hooks/useToggleModal'
import { MODALS_ID } from '../../utils/consts'

const useStyles = makeStyles(theme => ({
  root: {},
  popper: {
    zIndex: 10
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 8px',
    paddingRight: 0,
    backgroundColor: 'transparent'
  },
  label: {
    pointerEvents: 'none',
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    padding: 0,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[2],
      theme.palette.colors.white
    )
  },
  icon: {
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[5],
      theme.palette.colors.white
    )
  },
  paper: ({ width = 150 }) => ({
    minWidth: width,
    backgroundColor: theme.palette.colors.backgrounds.contrast,
    boxShadow: `0px 5px 15px ${theme.palette.colors.grey_15}`,
    borderRadius: 4
  }),
  dropdownList: {
    padding: 0
  },
  menuItem: {
    padding: '3px 8px',
    minHeight: 36,
    '& $leftAdornment, & $endAdornment': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5
  },
  leftAdornment: {
    marginRight: 8
  },
  endAdornment: {
    marginLeft: 'auto'
  }
}))

/* FIELDS */
/*{
  id: '',
  label: '',
  disabled: false,
  endAdornment: null,
  leftAdornment: null,
  onClick: null
}*/

const CustomDropdown = ({
  label,
  items,
  width,
  onSelect = () => null,
  className,
  _classes = {},
  id = null,
  placement = 'bottom-end',
  tooltip = '',
  stopPropagation
}) => {
  const classes = useStyles({ width })
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const { isLimited } = useLimitedAccess()
  const { toggle } = useToggleModal(MODALS_ID.GET_PREMIUM_VERSION_MODAL)

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen)
  }, [])

  const handleClose = useCallback(event => {
    if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
      return
    }
    setOpen(false)
  }, [])

  const handleClick = useCallback(
    item => {
      const { disabled = false, onClick } = item
      if (disabled) return
      if (onClick) {
        onClick()
        handleClose()
        return
      }
      return onSelect(item, handleClose)
    },
    [handleClose, onSelect]
  )

  return (
    <div className={cn(classes.root, className)} id={id}>
      <Button
        className={cn(classes.button, _classes.popupButton)}
        ref={anchorRef}
        onClick={e => {
          if (e && stopPropagation) e.stopPropagation()
          handleToggle()
        }}
      >
        <Typography noWrap className={classes.label}>
          {label}
        </Typography>
        <ArrowDropDownRounded className={classes.icon} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef?.current}
        role={undefined}
        placement={placement}
        transition
        disablePortal
        className={cn(classes.popper, _classes.popper)}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <Tooltip
                  arrow
                  title={tooltip}
                  classes={_classes}
                  placement={'left'}
                >
                  <MenuList
                    autoFocusItem={open}
                    className={classes.dropdownList}
                  >
                    <MapData data={items}>
                      {({ item, index }) => {
                        const {
                          id,
                          label,
                          endAdornment,
                          leftAdornment,
                          disabled = false
                        } = item || {}
                        const togglePremiumModal =
                          isLimited && disabled ? toggle : null
                        return (
                          <div onClick={togglePremiumModal}>
                            <MenuItem
                              id={id}
                              key={index}
                              onClick={() => handleClick(item)}
                              className={cn(
                                classes.menuItem,
                                _classes.menuItem,
                                {
                                  [classes.disabled]: disabled
                                }
                              )}
                            >
                              {Boolean(leftAdornment) && (
                                <div className={classes.leftAdornment}>
                                  {leftAdornment}
                                </div>
                              )}
                              <Typography noWrap className={classes.label}>
                                {label}
                              </Typography>
                              {Boolean(endAdornment) && (
                                <div className={classes.endAdornment}>
                                  {endAdornment}
                                </div>
                              )}
                            </MenuItem>
                          </div>
                        )
                      }}
                    </MapData>
                  </MenuList>
                </Tooltip>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default CustomDropdown
