import apiConnection from '../utils/ApiConnection'
import { LOGOUT, LOGOUT_USER } from '../actions/login'
import { RESET_FILES } from '../actions/files'
import { STORE_RECORD } from '../actions/recording'
import {
  saveClusterUnits,
  saveSimilarityUnits
} from '../components/RecordingViewWithCuration/components/WidgetsLayout/redux/actions'

export const persistStateMiddleware = store => next => action => {
  // this middleware is applied to the redux store
  // it inserts itself as part
  // of the action-processesing pipeline
  const sendAction = async (key, theAction) => {
    delete theAction['persistKey']
    apiConnection.sendMessage({
      type: 'appendDocumentAction',
      key,
      action: theAction
    })
  }

  if (action.persistKey) {
    // if the action has persistKey field, then
    // send it to the server
    sendAction(action.persistKey, action)
    return
  }
  return next(action)
}

export const resetStore = store => next => action => {
  if (action.type === LOGOUT) {
    store.dispatch({ type: LOGOUT_USER, payload: null })
    store.dispatch({ type: RESET_FILES, payload: null })
  }
  if (action.type === STORE_RECORD) {
    store.dispatch(saveClusterUnits(null))
    store.dispatch(saveSimilarityUnits(null))
  }
  return next(action)
}
