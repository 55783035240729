import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import {
  curationLabel,
  curationMerge,
  curationSplit
} from '../../../../../endpoints/admin'
import { NOTIFICATION } from '../../../../../utils/consts'
import {
  loadClusterview,
  saveClusterUnits,
  setLoadingClusterview,
  updateSimilarityView
} from '../../WidgetsLayout/redux/actions'
import { useSafeDispatch } from '../../../../../hooks/useSafeDispatch'
import { initCuration } from '../../../../../actions/curations'
import useGetCurations from './useGetCurations'
import { useUnitsGen } from '../../WidgetsLayout/hooks'
import useClusterSimilarityStore from '../../WidgetsLayout/widgets/ClusterView/hooks/useClusterSimilarityStore'
import { getOr, last } from 'lodash/fp'

import {
  filterLabel,
  filterLabelWithSecondKey
} from '../../WidgetsLayout/redux/utils.js'
import useGetRecommendations from '../../WidgetsLayout/widgets/DataView/hooks/useGetRecommendations'

function useCurationOperations() {
  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)
  const addNotification = useNotification()
  const { fetchSingleCuration } = useGetCurations(false)
  const { fetchRecommendations } = useGetRecommendations()

  const {
    storedClustersData: storedData,
    clustersSortBy,
    clustersSecondSortBy,
    clustersSortDirection,
    curationId: curation_id,
    clusterUnits: clusters,
    keyClusterData,
    allSimilarity,
    keySimilarityData
  } = useClusterSimilarityStore()

  const { uniqueUnits } = useUnitsGen()
  const allClusters = storedData?.data.clusters

  const isMerge = curation_id && uniqueUnits.length > 1
  const isSplit = curation_id && clusters.length === 1
  const isLabel = curation_id && clusters.length >= 1

  const isCurationAI = useSelector(
    state => state.widgetsCache.curationAI.isCurationAI
  )

  const isUnitMerged = clusters.every(clusterId => {
    const filteredCluster = allClusters?.find(cluster => {
      return cluster.id === clusterId.toString()
    })
    return filteredCluster?.merged
  })

  const saveCluster = useCallback(
    payload => {
      safeDispatch(
        loadClusterview({
          sortingId: keyClusterData,
          data: payload
        })
      )
    },
    [safeDispatch, keyClusterData]
  )

  const updateSimilarity = useCallback(
    payload => {
      safeDispatch(updateSimilarityView(payload))
    },
    [safeDispatch]
  )

  const curationInit = useCallback(
    () => safeDispatch(initCuration()),
    [safeDispatch]
  )

  const setLoading = useCallback(
    data => safeDispatch(setLoadingClusterview(data)),
    [safeDispatch]
  )
  const saveUnit = useCallback(
    data => dispatch(saveClusterUnits(data)),
    [dispatch]
  )

  const extraParams = clustersSecondSortBy
    ? { sort_by_second: clustersSecondSortBy }
    : {}

  const additionalParams = {
    sort_by: clustersSortBy,
    direction: clustersSortDirection.toLowerCase(),
    extraParams
  }

  const handleCurationMerge = useCallback(async () => {
    if (!isMerge) return
    try {
      setLoading(true)

      let mergeParams = {
        curation_id,
        clusters: uniqueUnits,
        ...additionalParams
      }
      const res = await curationMerge(mergeParams)

      if (res) {
        curationInit()
        if (isCurationAI) fetchRecommendations()
        fetchSingleCuration(curation_id)
        saveCluster({ data: res })
        const highlited = res?.clusters.find(item => item?.highlited)
        if (highlited)
          saveUnit({ unit_id: Number(highlited?.id), multiple: false })
        addNotification({
          type: NOTIFICATION.SUCCESS,
          title: 'Successfully merged'
        })
      }
      setLoading(false)
    } catch (e) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [uniqueUnits, curation_id, isMerge, additionalParams])

  const handleCurationSplit = useCallback(
    async clustersData => {
      if (!isSplit && !clustersData) return
      let res
      try {
        setLoading(true)
        let splitParams = {
          curation_id,
          cluster_id: clusters[0],
          ...additionalParams
        }

        if (clustersData) {
          res = await clustersData()
        } else {
          res = await curationSplit(splitParams)
        }

        if (res) {
          curationInit()
          if (isCurationAI) fetchRecommendations()
          fetchSingleCuration(curation_id)
          saveCluster({ data: res })
          const highlited = res?.clusters.filter(item => item?.highlited) || []
          const units = highlited.map(item => Number(item?.id)).filter(Boolean)
          if (units) {
            // reset split unit
            saveUnit(null)
            const isMultiple = units.length > 1
            for (let i = 0; i < units.length; i++) {
              const unit = units[i]
              saveUnit({
                unit_id: unit,
                multiple: isMultiple
              })
            }
          }
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Successfully splitted'
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
      setLoading(false)

      // eslint-disable-next-line
    },
    [clusters, curation_id, isSplit]
  )

  const updateSimilarityWidget = useCallback(
    async (units, label) => {
      const dataId = last(units)
      const currentSimilarity = getOr(
        [],
        [keySimilarityData, dataId],
        allSimilarity
      )
      const { data, ...rest } = currentSimilarity || {}
      const clusters = data?.clusters

      const noLabeledItems = clusters?.some(item =>
        units.includes(Number(item.id))
      )

      /*
      it's done in case if you select 2 items that have the same similarity
      and you are labelling them concurrently (multiple select them and label in one request),
      last selected should be updated with current labelled units with the same similarity
      */
      const updatedClusters = noLabeledItems
        ? clusters?.map(item =>
            units.includes(Number(item.id)) ? { ...item, label } : item
          )
        : []

      const updated = {
        [keySimilarityData]: {
          [dataId]: {
            ...rest,
            data: {
              ...data,
              clusters: noLabeledItems ? updatedClusters : clusters
            }
          }
        }
      }

      await updateSimilarity(updated)
    },
    [allSimilarity, keySimilarityData, updateSimilarity]
  )

  const handleUnitsLabelling = useCallback(
    async label => {
      if (!isLabel) return
      try {
        const data = getOr({}, ['data'], storedData)
        let updatedClusters = data?.clusters.map(unit =>
          clusters.includes(Number(unit.id)) ? { ...unit, label } : unit
        )

        if (clustersSortBy === 'label') {
          updatedClusters = filterLabel(
            updatedClusters,
            clustersSortDirection,
            clustersSecondSortBy
          )
        } else if (clustersSecondSortBy === 'label') {
          updatedClusters = filterLabelWithSecondKey(
            updatedClusters,
            clustersSortBy,
            clustersSortDirection
          )
        }
        const result = {
          ...data,
          clusters: updatedClusters
        }

        saveCluster({
          data: result
        })

        const { status } = await curationLabel({ curation_id, clusters, label })
        if (status === 200) {
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Units labelled successfully'
          })

          // update similarity widget with labeled units
          await updateSimilarityWidget(clusters, label)

          curationInit()
          fetchSingleCuration(curation_id)
        } else resetLabelledUnits(storedData)
      } catch (e) {
        resetLabelledUnits(storedData)
        addNotification({
          type: NOTIFICATION.ERROR,
          title:
            e ||
            'Label has not been applied, something went wrong, try again please'
        })
      }
      setLoading(false)
    },
    [clusters, curation_id, isLabel, storedData, updateSimilarityWidget]
  )

  const resetLabelledUnits = storedData => {
    const data = getOr({}, ['data'], storedData)
    saveCluster({ data })
  }

  return {
    isMerge,
    isLabel,
    isSplit,
    isUnitMerged,
    handleUnitsLabelling,
    merge: handleCurationMerge,
    split: handleCurationSplit
  }
}

export default useCurationOperations
