import axios from 'axios'
import config from '../utils/config'
import { resolveError } from '../utils/utils'
axios.defaults.headers.common['Authorization'] =
  'Bearer ' + localStorage.getItem('access_token')

export const signIn = ({ username, password }) =>
  axios
    .post(`${config.apiUrl}/login`, { username, password })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const signUp = newUser =>
  axios
    .post(`${config.apiUrl}/free_user/registration`, newUser)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getCurrentUser = () =>
  axios
    .get(`${config.apiUrl}/user/info`)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const signPolicy = () =>
  axios
    .get(`${config.apiUrl}/sign_policy`)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const resetPassword = data =>
  axios
    .post(`${config.apiUrl}/forgot`, data)
    .then(res => {
      return res
    })
    .catch(error => resolveError(error))

export const setNewPassword = data =>
  axios
    .post(`${config.apiUrl}/reset`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
