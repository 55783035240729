import React from 'react'

const EmptyFolder = props => {
  return (
    <svg
      width="182"
      height="181"
      viewBox="0 0 182 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M24.5729 149.369C28.1082 154.037 33.6895 156.609 40.286 156.609H137.651C150.328 156.609 162.898 147.034 166.261 134.814L180.409 83.4685C182.159 77.1139 181.163 71.0457 177.608 66.3776C174.051 61.7095 168.466 59.1416 161.873 59.1416H152.187L150.064 50.7091C147.862 41.9297 136.165 35.0564 123.44 35.0564H87.6734C78.3436 35.0564 71.9699 33.1472 70.4489 32.0653C66.4426 24.772 48.5976 24.3965 44.9923 24.3965H21.8586C15.3799 24.3965 9.62352 26.9358 5.65229 31.553C1.68106 36.167 0.0231977 42.232 1.04464 48.9494L21.2126 141.379C21.6613 144.297 22.7878 147.015 24.5729 149.369ZM170.013 72.1626C171.722 74.406 172.142 77.5212 171.203 80.9324L157.059 132.282C154.853 140.294 145.966 147.065 137.651 147.065H40.286C36.7475 147.065 33.8709 145.837 32.1812 143.61C30.4916 141.379 30.0906 138.277 31.0484 134.872L40.776 100.321C43.0321 92.3083 51.9706 85.5369 60.2917 85.5369H91.3201C102.489 85.5369 115.062 81.4925 117.474 73.7791C118.097 72.2549 124.652 68.6878 134.526 68.6878H161.87C165.418 68.6878 168.307 69.9224 170.013 72.1626ZM12.8883 37.7739C15.0171 35.3015 18.2056 33.9363 21.8618 33.9363H44.9955C54.3253 33.9363 60.699 35.8456 62.22 36.9307C66.2294 44.224 84.0682 44.5995 87.6734 44.5995H123.443C132.172 44.5995 139.812 49.0512 140.811 53.032L142.345 59.1384H134.529C123.357 59.1384 110.788 63.1828 108.376 70.8961C107.749 72.4204 101.194 75.9875 91.3233 75.9875H60.2949C47.6334 75.9875 35.0228 85.5369 31.5894 97.7274L25.8839 118L10.4286 47.212C9.88445 43.6003 10.7595 40.2464 12.8883 37.7739Z"
          fill="#DADEE3"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="180.5"
            height="180.5"
            fill="white"
            transform="translate(0.75 0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EmptyFolder
