import React, { memo } from 'react'
import { makeStyles, CircularProgress, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DownloadIcon from '@material-ui/icons/GetApp'
import useProbeActions from './hooks/useProbeActions'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  actionContainer: { display: 'flex', alignItems: 'center' },
  icon: {
    color: theme.palette.colors.neutral[5],
    padding: 4,
    cursor: 'pointer'
  },
  loadingBar: {
    marginLeft: 8,
    marginRight: 8,
    color: theme.palette.colors.neutral[5]
  }
}))

const ProbeActions = ({ id }) => {
  const classes = useStyles()
  const { loading, downloadImpedanceFile, toggleEditProbe } = useProbeActions({
    id
  })
  const Download = memo(() => {
    if (loading)
      return <CircularProgress className={classes.loadingBar} size={16} />

    return (
      <IconButton onClick={downloadImpedanceFile} className={classes.icon}>
        <DownloadIcon />
      </IconButton>
    )
  })
  return (
    <div className={classes.root}>
      <div className={classes.actionContainer}>
        <IconButton onClick={toggleEditProbe} className={classes.icon}>
          <EditIcon />
        </IconButton>
        <Download />
      </div>
    </div>
  )
}

export default ProbeActions
