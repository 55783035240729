import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PlotBuilder } from '../../PlotJS'
import { useDarkModeEffect } from '../../hooks'
import { usePlot, buildFeatureViewFactory } from './hooks/usePlot'
import { pairs } from './helpers'
import { seedUnitColor } from '../../../../../../shared/utils'
import {
  REINIT_CLUSTER_UNITS,
  REINIT_SIMILARITY_UNITS,
  selectSpikesActionCreator,
  splitSpikesActionCreator
} from '../../redux/actions'
import { useFeaturesData } from './hooks/useFeaturesData'
import { M, OFFSET, SUBPLOT_OFFSET } from './helpers'
import {
  StandardKeyEvents,
  KeyMap
} from '../../../../../TimeseriesView/CaptureKeyEvent/helpers'
import { useReinitClusters } from '../utils'

export class PlotFeatures {
  static renderPlot = null
  static updatePlotData = () => {}
  static clearScatterTraces = () => {}

  static setRenderPlot(builder) {
    this.renderPlot = builder
  }

  static builder(updaters) {
    Object.keys(updaters).forEach(key => {
      this[key] = updaters[key]
    })
  }
}

export const useSelectSpikes = () => {
  const dispatch = useDispatch()
  const selectedSpikes = useSelector(
    state => state.widgetsCache.features.selectedSpikes
  )

  const payload = {
    subplot: selectedSpikes?.subplot ?? [],
    lassoPoints: selectedSpikes?.lassoPoints ?? {},
    warn: selectedSpikes?.warn ?? false
  }

  const selectSpikes = () => {
    return payload.subplot.length && dispatch(splitSpikesActionCreator(payload))
  }

  return {
    selectedSpikes,
    selectSpikes
  }
}

export const FeatureView = ({ W, H }) => {
  const elem = React.useRef(null)
  const plot = useRef(new PlotBuilder())
  const traces = useRef(new Map([]))
  const chs = useRef([])
  const chss = useRef([])

  //custom hooks
  const dispatch = useDispatch()
  const darkMode = useSelector(state => state.darkMode)
  const renderPlot = usePlot(W - 3, H - 30, darkMode)
  const { cluster: clusterUnits, similarity: similarityUnits } = useSelector(
    state => state.widgetsCache.units
  )
  const { sortingId, curationId } = useFeaturesData()
  const { selectSpikes } = useSelectSpikes()

  useEffect(() => {
    clearScatterTraces()
  }, [sortingId, curationId])

  useEffect(() => {
    PlotFeatures.setRenderPlot(renderPlot)
    PlotFeatures.builder({ updatePlotData, clearScatterTraces })

    return () => {
      //if feature view is not rendered the request should not be made
      PlotFeatures.setRenderPlot(null)
    }
  }, [renderPlot])

  const buildFeatureView = (unit, payloadData) => {
    const tracesUnit = []

    if (payloadData) {
      const dd = Object.values(payloadData).filter(x => x !== null)

      const clusterIds = Object.keys(payloadData).filter(x => x !== null)

      chss.current = []

      if (dd.length) {
        chs.current = dd[0].labels

        pairs(16).forEach(([m, n], i) => {
          chss.current.push({
            ch: chs.current?.[i] ?? '',
            m,
            n
          })
        })

        for (let k = 0; k < clusterIds.length; k++) {
          const data = dd[k]?.data

          if (!data) continue

          pairs(16).forEach(([m, n], i) => {
            tracesUnit.push(
              buildFeatureViewFactory({
                xData: data[i].map(x => x[0]),
                yData: data[i].map(x => x[1]),
                m,
                n,
                plot: {
                  ch: chs.current[i],
                  m,
                  n
                },
                idx: i,
                color: seedUnitColor(clusterIds[k])
              })
            )
          })
        }

        traces.current.set(unit, tracesUnit)
      }
    }
  }

  useDarkModeEffect(elem.current, renderPlot, darkMode)

  const size = W > 0 ? W + H : 0

  const reinitClusters = useReinitClusters()

  useEffect(() => {
    if (size === 0) return

    reinitClusters({
      arr: clusterUnits,
      type: REINIT_CLUSTER_UNITS
    })
    reinitClusters({
      arr: similarityUnits,
      type: REINIT_SIMILARITY_UNITS,
      multiple: true
    })
  }, [size])

  useEffect(() => {
    plot.current.changeBuilder(buildFeatureView)

    document
      .getElementById('feature-view-wrapper')
      .addEventListener('click', e => {
        if (e.ctrlKey || e.metaKey) {
          renderPlot.relayout(elem.current, {
            dragmode: 'lasso'
          })
        } else {
          renderPlot.relayout(elem.current, {
            dragmode: 'pan'
          })
        }
      })

    if (elem?.current) {
      renderPlot.render(elem.current, [])
    }
  }, [W + H])

  function clearScatterTraces() {
    var gd = document.getElementById('feature-view-wrapper')

    if (Array.isArray(gd?.data)) {
      const indices = gd.data
        .map((x, idx) => (x['data-type'] === 'data-point' ? idx : null))
        .filter(x => x)
      renderPlot.removeTrace(elem.current, indices)

      chs.current = []
      chss.current = []
    }
  }

  function updatePlotData(unit, payloadData) {
    if (!Array.isArray(document.getElementById('feature-view-wrapper')?.data))
      return

    if (elem?.current) {
      plot.current.buildPlot(unit, payloadData)
      return Promise.resolve().then(() => {
        renderPlot.update(elem.current, traces.current.get(unit), chss.current)
      })
    }
  }

  const gd = document.getElementById('feature-view-wrapper')

  useEffect(() => {
    StandardKeyEvents.register(
      [KeyMap.Ctrl, 16, 90],
      e => {
        renderPlot.deselect(elem.current)
        renderPlot.relayout(elem.current, {
          dragmode: 'pan'
        })
        selectSpikes()
      },
      { overrideEvent: true }
    )
  }, [selectSpikes])

  useEffect(() => {
    if (document.getElementById('feature-view-wrapper')?.on) {
      renderPlot.registerEvent(gd, 'plotly_selected', eventData => {
        if (!eventData) return

        const poss = eventData?.points?.map(p => p.data?.pos.join('-'))

        const dim = eventData?.points?.[0]?.data?.['pos']

        const lp = Object.entries(eventData.lassoPoints)
          .map(([k, v], i) => ({
            [k]: v.map(x => x - dim[i] * M - OFFSET * dim[i] - SUBPLOT_OFFSET)
          }))
          .reduce((acc, v) => ({ ...acc, ...v }), {})

        dispatch(
          selectSpikesActionCreator({
            subplot: eventData?.points?.[0]?.data?.['pos'] ?? [],
            warn: new Set(poss).size > 1,
            lassoPoints: lp
          })
        )
      })

      renderPlot.registerEvent(gd, 'plotly_doubleclick', () => {
        renderPlot.restyle(elem.current, 'selectedpoints', null)
      })
    }

    return () => clearScatterTraces()
  }, [gd])

  return <div ref={elem} id="feature-view-wrapper" />
}
