import React, { useCallback } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MapData from '../../../../utils/MapData'
import { useSelector } from 'react-redux'
import FilesPagination from '../../../FilesTable/components/FilesPagination'
import MembersListItem from '../MembersListItem'
import FilesSnackbar from '../../../FilesTable/components/FilesSnackbar/FilesSnackbar'
import useConfirmationModal from '../../../FilesTable/hooks/useConfirmationModal'
import DeleteConfirmationModal from '../../../FilesTable/components/DeleteConfirmationModal'

const useStyles = makeStyles(theme => ({
  pagination: {
    bottom: 0
  },
  membersList: {
    flex: 1,
    width: '100%',
    overflow: 'auto',
    ...theme.scrollbar()
  },
  membersTable: {
    width: '100%',
    borderSpacing: 0
  },
  noData: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center',
    color: theme.palette.primary.contrast
  }
}))

export default function MembersList({
  selected,
  onSelect,
  onDelete,
  deselectAll,
  isBulkSelect,
  setSelected,
  switchRole,
  membersToUpdate
}) {
  const classes = useStyles()
  const members = useSelector(state => state.membersStore.members)
  const pages = useSelector(state => state.membersStore.pages)
  const currentUser = useSelector(state => state.login.currentUser)
  const { id: loggedUserId } = currentUser?.user || {}
  const { toggleDeleteModal, isOpened } = useConfirmationModal()

  const membersArray = members?.filter(({ id }) => id !== loggedUserId)

  const handleDelete = useCallback(
    id => {
      onDelete({ ids: id, onSuccess: () => deselectAll() })
    },
    [deselectAll, onDelete]
  )

  const handleSingleDelete = useCallback(
    id => {
      setSelected(id)
      toggleDeleteModal()
    },
    [setSelected, toggleDeleteModal]
  )

  if (members?.length === 0) {
    return <Typography className={classes.noData}>No members yet</Typography>
  }

  return (
    <div className={classes.membersList}>
      <DeleteConfirmationModal
        isOpened={isOpened}
        toggleDeleteModal={toggleDeleteModal}
        onDelete={() => handleDelete(selected)}
        selected={selected}
      />

      <table className={classes.membersTable}>
        <tbody>
          <MapData data={membersArray}>
            {({ item, index }) => {
              const { id } = item
              const isSelected =
                Array.isArray(selected) && selected.indexOf(id) !== -1

              const isRoleSwitched = membersToUpdate.some(
                member => member.id === id
              )

              return (
                <MembersListItem
                  key={index}
                  isSelected={isSelected}
                  isRoleSwitched={isRoleSwitched}
                  onSelect={onSelect}
                  handleDelete={handleSingleDelete}
                  switchRole={switchRole}
                  {...item}
                />
              )
            }}
          </MapData>
        </tbody>
      </table>
      <FilesPagination
        pages={pages}
        saveParams={false}
        withPerPageSelector={false}
        className={classes.pagination}
      />

      {isBulkSelect && (
        <FilesSnackbar
          length={selected?.length}
          deselectAll={deselectAll}
          onDelete={toggleDeleteModal}
        />
      )}
    </div>
  )
}
