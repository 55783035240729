import React, { memo } from 'react'
import TabsContext from '../TabsContext'

const CustomTabs = ({ tabs, selectedTab, handleChangeTabs, ...rest }) => {
  return (
    <TabsContext
      selectedTab={selectedTab}
      handleChangeTabs={handleChangeTabs}
      tabs={tabs}
      {...rest}
    />
  )
}

export default memo(CustomTabs)
