import React, { useCallback } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core'
import ActionButton from '../../../ActionButton'
import cn from 'classnames'
import { UploadFileIcon } from '../../../Icons'
import SearchBar from './components/SearchBar'
import { CreateNewFolder, ViewWeek } from '@material-ui/icons'
import DownloadAppModal from '../DownloadAppModal'
import NewFolderModal from '../NewFolderModal'
import useNewFolderModal from '../../hooks/useNewFolderModal'
import useGetAllRecords from '../../../FilesTable/hooks/useGetAllRecords'
import Breadcrumb from '../../../Breadcrumb/Breadcrumb'
import useFoldersBreadcrumb from '../../hooks/useFoldersBreadcrumb'
import { useSelector } from 'react-redux'
import { validateName } from '../../../../utils/utils'
import useLimitedAccess from '../../../../hooks/useLimitedAccess'
import BrainIcon from '../../../Icons/BrainIcon'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../../utils/consts'
import { darken } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '16px 0'
  },
  breadcrumbRoot: {
    flex: 2,
    width: 100
  },
  breadcrumb: {
    flexWrap: 'wrap'
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
    marginBottom: 2,
    lineHeight: '32px',
    color: theme.palette.primary.contrast
  },
  actionsContainer: {
    flex: 1
  },
  action: {
    width: 'auto',
    minWidth: 'auto',
    padding: '6px 8px',
    borderRadius: 8,
    marginLeft: 25
  },
  btn: {
    padding: '6px 16px'
  },
  trainAiButton: {
    backgroundColor: theme.palette.colors.lighterGreen,
    '&:hover': {
      backgroundColor: darken(theme.palette.colors.lighterGreen, 0.3)
    }
  },
  filterIcon: {
    margin: 0,
    fontSize: 20
  }
}))

const Header = ({ toggleColumnsModal, isOpened, toggleDownloadModal }) => {
  const classes = useStyles()
  const history = useHistory()
  const { fetchRecords } = useGetAllRecords(false)
  const { foldersBreadcrumb } = useFoldersBreadcrumb()
  const { toggleModal: toggleNewFolderModal, ...rest } = useNewFolderModal()
  const files = useSelector(state => state.filesStore.files)
  const { isLimited } = useLimitedAccess()

  const handleToggleModal = useCallback(() => {
    toggleNewFolderModal({
      validateName: name => validateName(name, files),
      onSuccess: () => fetchRecords()
    })
  }, [toggleNewFolderModal, files, fetchRecords])

  return (
    <Grid
      container
      wrap="nowrap"
      justify="space-between"
      alignItems="center"
      className={classes.container}
    >
      <div className={classes.breadcrumbRoot}>
        <Typography variant="h2" noWrap className={classes.title}>
          Data
        </Typography>
        <Breadcrumb steps={foldersBreadcrumb} className={classes.breadcrumb} />
      </div>
      <Grid
        container
        wrap="nowrap"
        justify="flex-end"
        alignItems="center"
        className={classes.actionsContainer}
      >
        <SearchBar />
        <ActionButton
          className={classes.action}
          startIcon={<ViewWeek />}
          startIconCustomClass={classes.filterIcon}
          onClick={toggleColumnsModal}
        />
        <ActionButton
          className={cn(classes.action, classes.btn)}
          startIcon={<UploadFileIcon />}
          label="Upload File"
          onClick={toggleDownloadModal}
          id="upload"
          disabled={isLimited}
        />
        <ActionButton
          className={cn(classes.action, classes.btn)}
          startIcon={<CreateNewFolder />}
          label="New Folder"
          onClick={handleToggleModal}
          disabled={isLimited}
        />
        <ActionButton
          className={cn(classes.action, classes.btn, classes.trainAiButton)}
          startIcon={<BrainIcon height={20} width={20} />}
          label="Train AI model"
          onClick={() => history.push(ROUTES.TRAIN_AI)}
          disabled={isLimited}
        />
      </Grid>

      <DownloadAppModal isOpened={isOpened} toggleModal={toggleDownloadModal} />
      <NewFolderModal toggleModal={toggleNewFolderModal} {...rest} />
    </Grid>
  )
}

export default Header
