import { useEffect, useCallback } from 'react'
import { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { useDispatch, useSelector } from 'react-redux'
import { nextOrPrevTour, startTour } from '../../../actions/tours'
import { useLocation } from 'react-router-dom'
import { switchOnboardingStatus } from '../../../endpoints/admin'
import { storeCurrentUser } from '../../../actions/login'
import useLimitedAccess from '../../../hooks/useLimitedAccess'
import useCurationAI from '../../RecordingViewWithCuration/components/WidgetsLayout/widgets/DataView/hooks/useCurationAI'

export default function useTours(next = () => null) {
  const tourState = useSelector(state => state.toursStore)
  const currentUser = useSelector(state => state.login?.currentUser) || {}
  const onboardings = currentUser?.user?.onboardings || []
  const { isLimited } = useLimitedAccess()

  const dispatch = useDispatch()
  const location = useLocation()
  const { handleSet } = useCurationAI()

  const pathname = location.pathname.split('/')[1]
  const pageName =
    pathname === 'timeseriesForRecording' ? 'timeseries' : pathname
  let stepName = pageName
  const isRecordingPage = pageName === 'recording'

  const { status } = onboardings?.find(el => el?.step === pageName) || {}

  if (isRecordingPage && status) stepName = 'curation_AI'
  const { status: curAIStatus } =
    onboardings?.find(el => el?.step === 'curation_AI') || {}

  const storeUserInfo = useCallback(
    user => dispatch(storeCurrentUser(user)),
    [dispatch]
  )

  const handleStartTour = useCallback(() => {
    if (isRecordingPage) handleSet()
    dispatch(startTour())
  }, [dispatch, handleSet, isRecordingPage])

  const nextOrPrev = (index, cdt) => dispatch(nextOrPrevTour(index, cdt))

  const callback = data => {
    const { action, type, status } = data

    if (
      action === ACTIONS.CLOSE ||
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status)
    ) {
      dispatch({ type: 'STOP' })
      handleTourEnd()
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      next(data)
    }
  }

  const handleTourEnd = async () => {
    if (status && curAIStatus) return
    const filtredOnboardings = onboardings.filter(
      ({ step }) => step !== stepName
    )
    const newOnbaording = {
      step: stepName,
      status: true
    }
    try {
      await switchOnboardingStatus(newOnbaording)

      storeUserInfo({
        ...currentUser,
        user: {
          ...currentUser.user,
          onboardings: [...filtredOnboardings, newOnbaording]
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!status && !isLimited) {
      setTimeout(
        () => handleStartTour(),
        pageName === 'timeseries' ? 5000 : 1000
      )
    } else {
      dispatch({ type: 'STOP' })
    }
  }, [dispatch, pageName, status, handleStartTour, isLimited, curAIStatus])

  return { tourState, callback, nextOrPrev }
}
