import React, { memo } from 'react'
import FullScreenLoader from '../FullScreenLoader'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  loadingRoot: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto',
    zIndex: 1,
    backdropFilter: 'blur(2px)'
  },
  loading: {
    height: 150,
    display: 'table-row'
  }
}))

const BlurLoader = memo(({ size }) => {
  const classes = useStyles()
  return (
    <div className={classes.loadingRoot}>
      <FullScreenLoader size={size} className={classes.loading} />
    </div>
  )
})

export default BlurLoader
