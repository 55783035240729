import { MIN_8_CHARS, NOTIFICATION, REQUIRED } from '../../../utils/consts'
import { object, string } from 'yup'
import { signUp } from '../../../endpoints/auth'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import useNotification from '../../SnackbarManager/hooks/useNotification'

const useSignupForm = () => {
  const history = useHistory()
  const addNotification = useNotification()
  const initialValues = {
    name: '',
    lastname: '',
    username: '',
    email: '',
    password: ''
  }

  const validation = object({
    name: string().required(REQUIRED).trim(),
    email: string().email().required(REQUIRED).trim(),
    lastname: string().required(REQUIRED).trim(),
    username: string().required(REQUIRED).trim(),
    password: string()
      .required(REQUIRED)
      .matches(
        new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'),
        'Password should containt at least one number and one symbol (like !@#$%^)'
      )
      .min(8, MIN_8_CHARS)
      .trim()
  })

  const onSubmit = useCallback(
    async values => {
      const response = await signUp(values)
      if (response) {
        const { message, access_token: token } = response || {}
        if (!token) {
          addNotification({
            type: NOTIFICATION.ERROR,
            title: message
          })
          return
        }
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
        localStorage.setItem('access_token', token)
        addNotification({
          type: NOTIFICATION.SUCCESS,
          duration: 5000,
          title: 'Registration Successful!'
        })
        return history.push('/terms')
      }
    },
    [addNotification, history]
  )

  return { initialValues, validation, onSubmit }
}

export default useSignupForm
