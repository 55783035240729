import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ROUTES } from '../../utils/consts'
import EditOrganisation from '../EditOrganisation'
import { Edit, People } from '@material-ui/icons'
import OrganisationMembers from '../OrganisationMembers'
import { useSelector } from 'react-redux'
import BlurLoader from '../BlurLoader'
import ConfigurationLayout from '../ConfigurationLayout'

const menuItems = [
  {
    to: ROUTES.ORGANISATION,
    label: 'Information',
    exact: true,
    icon: Edit
  },
  {
    to: ROUTES.ORGANISATION_MEMBERS,
    label: 'Members',
    exact: true,
    icon: People
  }
]

const OrganisationPage = () => {
  const currentUser = useSelector(state => state.login.currentUser)

  const { admin, company } = currentUser?.user || {}
  const { manager, free } = company || {}
  const hasAccess = admin || manager || !free

  if (!currentUser) return <BlurLoader />

  if (!hasAccess) return <Redirect to="/files" />

  return (
    <ConfigurationLayout menuItem={menuItems} title="Organisation Settings">
      <Switch>
        <Route exact path={ROUTES.ORGANISATION} component={EditOrganisation} />

        <Route
          exact
          path={ROUTES.ORGANISATION_MEMBERS}
          component={OrganisationMembers}
        />
      </Switch>
    </ConfigurationLayout>
  )
}

export default OrganisationPage
