import React from 'react'

export const svg64_12 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        width="174"
        height="1364"
        viewBox="0 0 174 1364"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
      >
        <path d="M0 0H174V995.197L0 1364V0Z" fill={color} />
        <path
          d="M10 381C10 379.343 11.3431 378 13 378H25C26.6569 378 28 379.343 28 381V392.077C28 393.734 26.6569 395.077 25 395.077H13C11.3431 395.077 10 393.734 10 392.077V381Z"
          fill="white"
        />
        <path
          d="M46 381C46 379.343 47.3431 378 49 378H61C62.6569 378 64 379.343 64 381V392.077C64 393.734 62.6569 395.077 61 395.077H49C47.3431 395.077 46 393.734 46 392.077V381Z"
          fill="white"
        />
        <path
          d="M29 406.077C29 404.42 30.3431 403.077 32 403.077H44C45.6569 403.077 47 404.42 47 406.077V417.154C47 418.811 45.6569 420.154 44 420.154H32C30.3431 420.154 29 418.811 29 417.154V406.077Z"
          fill="white"
        />
        <path
          d="M65 406.077C65 404.42 66.3431 403.077 68 403.077H80C81.6569 403.077 83 404.42 83 406.077V417.154C83 418.811 81.6569 420.154 80 420.154H68C66.3431 420.154 65 418.811 65 417.154V406.077Z"
          fill="white"
        />
        <path
          d="M10 431.154C10 429.497 11.3431 428.154 13 428.154H25C26.6569 428.154 28 429.497 28 431.154V442.231C28 443.888 26.6569 445.231 25 445.231H13C11.3431 445.231 10 443.888 10 442.231V431.154Z"
          fill="white"
        />
        <path
          d="M46 431.154C46 429.497 47.3431 428.154 49 428.154H61C62.6569 428.154 64 429.497 64 431.154V442.231C64 443.888 62.6569 445.231 61 445.231H49C47.3431 445.231 46 443.888 46 442.231V431.154Z"
          fill="white"
        />
        <path
          d="M29 456.231C29 454.574 30.3431 453.231 32 453.231H44C45.6569 453.231 47 454.574 47 456.231V467.308C47 468.965 45.6569 470.308 44 470.308H32C30.3431 470.308 29 468.965 29 467.308V456.231Z"
          fill="white"
        />
        <path
          d="M65 456.231C65 454.574 66.3431 453.231 68 453.231H80C81.6569 453.231 83 454.574 83 456.231V467.308C83 468.965 81.6569 470.308 80 470.308H68C66.3431 470.308 65 468.965 65 467.308V456.231Z"
          fill="white"
        />
        <path
          d="M10 481.308C10 479.651 11.3431 478.308 13 478.308H25C26.6569 478.308 28 479.651 28 481.308V492.385C28 494.041 26.6569 495.385 25 495.385H13C11.3431 495.385 10 494.041 10 492.385V481.308Z"
          fill="white"
        />
        <path
          d="M46 481.308C46 479.651 47.3431 478.308 49 478.308H61C62.6569 478.308 64 479.651 64 481.308V492.385C64 494.041 62.6569 495.385 61 495.385H49C47.3431 495.385 46 494.041 46 492.385V481.308Z"
          fill="white"
        />
        <path
          d="M29 506.385C29 504.728 30.3431 503.385 32 503.385H44C45.6569 503.385 47 504.728 47 506.385V517.462C47 519.119 45.6569 520.462 44 520.462H32C30.3431 520.462 29 519.119 29 517.462V506.385Z"
          fill="white"
        />
        <path
          d="M65 506.385C65 504.728 66.3431 503.385 68 503.385H80C81.6569 503.385 83 504.728 83 506.385V517.462C83 519.119 81.6569 520.462 80 520.462H68C66.3431 520.462 65 519.119 65 517.462V506.385Z"
          fill="white"
        />
        <path
          d="M10 531.461C10 529.805 11.3431 528.461 13 528.461H25C26.6569 528.461 28 529.805 28 531.461V542.538C28 544.195 26.6569 545.538 25 545.538H13C11.3431 545.538 10 544.195 10 542.538V531.461Z"
          fill="white"
        />
        <path
          d="M46 531.461C46 529.805 47.3431 528.461 49 528.461H61C62.6569 528.461 64 529.805 64 531.461V542.538C64 544.195 62.6569 545.538 61 545.538H49C47.3431 545.538 46 544.195 46 542.538V531.461Z"
          fill="white"
        />
        <path
          d="M29 556.539C29 554.882 30.3431 553.539 32 553.539H44C45.6569 553.539 47 554.882 47 556.539V567.615C47 569.272 45.6569 570.615 44 570.615H32C30.3431 570.615 29 569.272 29 567.615V556.539Z"
          fill="white"
        />
        <path
          d="M65 556.539C65 554.882 66.3431 553.539 68 553.539H80C81.6569 553.539 83 554.882 83 556.539V567.615C83 569.272 81.6569 570.615 80 570.615H68C66.3431 570.615 65 569.272 65 567.615V556.539Z"
          fill="white"
        />
        <path
          d="M10 581.615C10 579.958 11.3431 578.615 13 578.615H25C26.6569 578.615 28 579.958 28 581.615V592.692C28 594.349 26.6569 595.692 25 595.692H13C11.3431 595.692 10 594.349 10 592.692V581.615Z"
          fill="white"
        />
        <path
          d="M46 581.615C46 579.958 47.3431 578.615 49 578.615H61C62.6569 578.615 64 579.958 64 581.615V592.692C64 594.349 62.6569 595.692 61 595.692H49C47.3431 595.692 46 594.349 46 592.692V581.615Z"
          fill="white"
        />
        <path
          d="M29 606.692C29 605.036 30.3431 603.692 32 603.692H44C45.6569 603.692 47 605.036 47 606.692V617.769C47 619.426 45.6569 620.769 44 620.769H32C30.3431 620.769 29 619.426 29 617.769V606.692Z"
          fill="white"
        />
        <path
          d="M65 606.692C65 605.036 66.3431 603.692 68 603.692H80C81.6569 603.692 83 605.036 83 606.692V617.769C83 619.426 81.6569 620.769 80 620.769H68C66.3431 620.769 65 619.426 65 617.769V606.692Z"
          fill="white"
        />
        <path
          d="M10 631.769C10 630.112 11.3431 628.769 13 628.769H25C26.6569 628.769 28 630.112 28 631.769V642.846C28 644.503 26.6569 645.846 25 645.846H13C11.3431 645.846 10 644.503 10 642.846V631.769Z"
          fill="white"
        />
        <path
          d="M46 631.769C46 630.112 47.3431 628.769 49 628.769H61C62.6569 628.769 64 630.112 64 631.769V642.846C64 644.503 62.6569 645.846 61 645.846H49C47.3431 645.846 46 644.503 46 642.846V631.769Z"
          fill="white"
        />
        <path
          d="M29 656.846C29 655.189 30.3431 653.846 32 653.846H44C45.6569 653.846 47 655.189 47 656.846V667.923C47 669.58 45.6569 670.923 44 670.923H32C30.3431 670.923 29 669.58 29 667.923V656.846Z"
          fill="white"
        />
        <path
          d="M65 656.846C65 655.189 66.3431 653.846 68 653.846H80C81.6569 653.846 83 655.189 83 656.846V667.923C83 669.58 81.6569 670.923 80 670.923H68C66.3431 670.923 65 669.58 65 667.923V656.846Z"
          fill="white"
        />
        <path
          d="M10 681.923C10 680.266 11.3431 678.923 13 678.923H25C26.6569 678.923 28 680.266 28 681.923V693C28 694.657 26.6569 696 25 696H13C11.3431 696 10 694.657 10 693V681.923Z"
          fill="white"
        />
        <path
          d="M46 681.923C46 680.266 47.3431 678.923 49 678.923H61C62.6569 678.923 64 680.266 64 681.923V693C64 694.657 62.6569 696 61 696H49C47.3431 696 46 694.657 46 693V681.923Z"
          fill="white"
        />
        <path
          d="M29 707C29 705.343 30.3431 704 32 704H44C45.6569 704 47 705.343 47 707V718.077C47 719.734 45.6569 721.077 44 721.077H32C30.3431 721.077 29 719.734 29 718.077V707Z"
          fill="white"
        />
        <path
          d="M65 707C65 705.343 66.3431 704 68 704H80C81.6569 704 83 705.343 83 707V718.077C83 719.734 81.6569 721.077 80 721.077H68C66.3431 721.077 65 719.734 65 718.077V707Z"
          fill="white"
        />
        <path
          d="M10 732.077C10 730.42 11.3431 729.077 13 729.077H25C26.6569 729.077 28 730.42 28 732.077V743.154C28 744.81 26.6569 746.154 25 746.154H13C11.3431 746.154 10 744.81 10 743.154V732.077Z"
          fill="white"
        />
        <path
          d="M46 732.077C46 730.42 47.3431 729.077 49 729.077H61C62.6569 729.077 64 730.42 64 732.077V743.154C64 744.81 62.6569 746.154 61 746.154H49C47.3431 746.154 46 744.81 46 743.154V732.077Z"
          fill="white"
        />
        <path
          d="M29 757.154C29 755.497 30.3431 754.154 32 754.154H44C45.6569 754.154 47 755.497 47 757.154V768.231C47 769.888 45.6569 771.231 44 771.231H32C30.3431 771.231 29 769.888 29 768.231V757.154Z"
          fill="white"
        />
        <path
          d="M65 757.154C65 755.497 66.3431 754.154 68 754.154H80C81.6569 754.154 83 755.497 83 757.154V768.231C83 769.888 81.6569 771.231 80 771.231H68C66.3431 771.231 65 769.888 65 768.231V757.154Z"
          fill="white"
        />
        <path
          d="M10 782.23C10 780.574 11.3431 779.23 13 779.23H25C26.6569 779.23 28 780.574 28 782.23V793.307C28 794.964 26.6569 796.307 25 796.307H13C11.3431 796.307 10 794.964 10 793.307V782.23Z"
          fill="white"
        />
        <path
          d="M46 782.23C46 780.574 47.3431 779.23 49 779.23H61C62.6569 779.23 64 780.574 64 782.23V793.307C64 794.964 62.6569 796.307 61 796.307H49C47.3431 796.307 46 794.964 46 793.307V782.23Z"
          fill="white"
        />
        <path
          d="M29 807.308C29 805.651 30.3431 804.308 32 804.308H44C45.6569 804.308 47 805.651 47 807.308V818.385C47 820.041 45.6569 821.385 44 821.385H32C30.3431 821.385 29 820.041 29 818.385V807.308Z"
          fill="white"
        />
        <path
          d="M65 807.308C65 805.651 66.3431 804.308 68 804.308H80C81.6569 804.308 83 805.651 83 807.308V818.385C83 820.041 81.6569 821.385 80 821.385H68C66.3431 821.385 65 820.041 65 818.385V807.308Z"
          fill="white"
        />
        <path
          d="M10 832.384C10 830.727 11.3431 829.384 13 829.384H25C26.6569 829.384 28 830.727 28 832.384V843.461C28 845.118 26.6569 846.461 25 846.461H13C11.3431 846.461 10 845.118 10 843.461V832.384Z"
          fill="white"
        />
        <path
          d="M46 832.384C46 830.727 47.3431 829.384 49 829.384H61C62.6569 829.384 64 830.727 64 832.384V843.461C64 845.118 62.6569 846.461 61 846.461H49C47.3431 846.461 46 845.118 46 843.461V832.384Z"
          fill="white"
        />
        <path
          d="M29 857.461C29 855.805 30.3431 854.461 32 854.461H44C45.6569 854.461 47 855.805 47 857.461V868.538C47 870.195 45.6569 871.538 44 871.538H32C30.3431 871.538 29 870.195 29 868.538V857.461Z"
          fill="white"
        />
        <path
          d="M65 857.461C65 855.805 66.3431 854.461 68 854.461H80C81.6569 854.461 83 855.805 83 857.461V868.538C83 870.195 81.6569 871.538 80 871.538H68C66.3431 871.538 65 870.195 65 868.538V857.461Z"
          fill="white"
        />
        <path
          d="M10 882.538C10 880.881 11.3431 879.538 13 879.538H25C26.6569 879.538 28 880.881 28 882.538V893.615C28 895.272 26.6569 896.615 25 896.615H13C11.3431 896.615 10 895.272 10 893.615V882.538Z"
          fill="white"
        />
        <path
          d="M46 882.538C46 880.881 47.3431 879.538 49 879.538H61C62.6569 879.538 64 880.881 64 882.538V893.615C64 895.272 62.6569 896.615 61 896.615H49C47.3431 896.615 46 895.272 46 893.615V882.538Z"
          fill="white"
        />
        <path
          d="M29 907.615C29 905.958 30.3431 904.615 32 904.615H44C45.6569 904.615 47 905.958 47 907.615V918.692C47 920.349 45.6569 921.692 44 921.692H32C30.3431 921.692 29 920.349 29 918.692V907.615Z"
          fill="white"
        />
        <path
          d="M65 907.615C65 905.958 66.3431 904.615 68 904.615H80C81.6569 904.615 83 905.958 83 907.615V918.692C83 920.349 81.6569 921.692 80 921.692H68C66.3431 921.692 65 920.349 65 918.692V907.615Z"
          fill="white"
        />
        <path
          d="M10 932.692C10 931.035 11.3431 929.692 13 929.692H25C26.6569 929.692 28 931.035 28 932.692V943.769C28 945.426 26.6569 946.769 25 946.769H13C11.3431 946.769 10 945.426 10 943.769V932.692Z"
          fill="white"
        />
        <path
          d="M46 932.692C46 931.035 47.3431 929.692 49 929.692H61C62.6569 929.692 64 931.035 64 932.692V943.769C64 945.426 62.6569 946.769 61 946.769H49C47.3431 946.769 46 945.426 46 943.769V932.692Z"
          fill="white"
        />
        <path
          d="M29 957.769C29 956.112 30.3431 954.769 32 954.769H44C45.6569 954.769 47 956.112 47 957.769V968.846C47 970.503 45.6569 971.846 44 971.846H32C30.3431 971.846 29 970.503 29 968.846V957.769Z"
          fill="white"
        />
        <path
          d="M65 957.769C65 956.112 66.3431 954.769 68 954.769H80C81.6569 954.769 83 956.112 83 957.769V968.846C83 970.503 81.6569 971.846 80 971.846H68C66.3431 971.846 65 970.503 65 968.846V957.769Z"
          fill="white"
        />
        <path
          d="M10 982.846C10 981.189 11.3431 979.846 13 979.846H25C26.6569 979.846 28 981.189 28 982.846V993.923C28 995.579 26.6569 996.923 25 996.923H13C11.3431 996.923 10 995.579 10 993.923V982.846Z"
          fill="white"
        />
        <path
          d="M46 982.846C46 981.189 47.3431 979.846 49 979.846H61C62.6569 979.846 64 981.189 64 982.846V993.923C64 995.579 62.6569 996.923 61 996.923H49C47.3431 996.923 46 995.579 46 993.923V982.846Z"
          fill="white"
        />
        <path
          d="M29 1007.92C29 1006.27 30.3431 1004.92 32 1004.92H44C45.6569 1004.92 47 1006.27 47 1007.92V1019C47 1020.66 45.6569 1022 44 1022H32C30.3431 1022 29 1020.66 29 1019V1007.92Z"
          fill="white"
        />
        <path
          d="M65 1007.92C65 1006.27 66.3431 1004.92 68 1004.92H80C81.6569 1004.92 83 1006.27 83 1007.92V1019C83 1020.66 81.6569 1022 80 1022H68C66.3431 1022 65 1020.66 65 1019V1007.92Z"
          fill="white"
        />
        <path
          d="M10 1033C10 1031.34 11.3431 1030 13 1030H25C26.6569 1030 28 1031.34 28 1033V1044.08C28 1045.73 26.6569 1047.08 25 1047.08H13C11.3431 1047.08 10 1045.73 10 1044.08V1033Z"
          fill="white"
        />
        <path
          d="M46 1033C46 1031.34 47.3431 1030 49 1030H61C62.6569 1030 64 1031.34 64 1033V1044.08C64 1045.73 62.6569 1047.08 61 1047.08H49C47.3431 1047.08 46 1045.73 46 1044.08V1033Z"
          fill="white"
        />
        <path
          d="M29 1058.08C29 1056.42 30.3431 1055.08 32 1055.08H44C45.6569 1055.08 47 1056.42 47 1058.08V1069.15C47 1070.81 45.6569 1072.15 44 1072.15H32C30.3431 1072.15 29 1070.81 29 1069.15V1058.08Z"
          fill="white"
        />
        <path
          d="M65 1058.08C65 1056.42 66.3431 1055.08 68 1055.08H80C81.6569 1055.08 83 1056.42 83 1058.08V1069.15C83 1070.81 81.6569 1072.15 80 1072.15H68C66.3431 1072.15 65 1070.81 65 1069.15V1058.08Z"
          fill="white"
        />
        <path
          d="M10 1083.15C10 1081.5 11.3431 1080.15 13 1080.15H25C26.6569 1080.15 28 1081.5 28 1083.15V1094.23C28 1095.89 26.6569 1097.23 25 1097.23H13C11.3431 1097.23 10 1095.89 10 1094.23V1083.15Z"
          fill="white"
        />
        <path
          d="M46 1083.15C46 1081.5 47.3431 1080.15 49 1080.15H61C62.6569 1080.15 64 1081.5 64 1083.15V1094.23C64 1095.89 62.6569 1097.23 61 1097.23H49C47.3431 1097.23 46 1095.89 46 1094.23V1083.15Z"
          fill="white"
        />
        <path
          d="M29 1108.23C29 1106.57 30.3431 1105.23 32 1105.23H44C45.6569 1105.23 47 1106.57 47 1108.23V1119.31C47 1120.96 45.6569 1122.31 44 1122.31H32C30.3431 1122.31 29 1120.96 29 1119.31V1108.23Z"
          fill="white"
        />
        <path
          d="M65 1108.23C65 1106.57 66.3431 1105.23 68 1105.23H80C81.6569 1105.23 83 1106.57 83 1108.23V1119.31C83 1120.96 81.6569 1122.31 80 1122.31H68C66.3431 1122.31 65 1120.96 65 1119.31V1108.23Z"
          fill="white"
        />
        <path
          d="M10 1133.31C10 1131.65 11.3431 1130.31 13 1130.31H25C26.6569 1130.31 28 1131.65 28 1133.31V1144.38C28 1146.04 26.6569 1147.38 25 1147.38H13C11.3431 1147.38 10 1146.04 10 1144.38V1133.31Z"
          fill="white"
        />
        <path
          d="M46 1133.31C46 1131.65 47.3431 1130.31 49 1130.31H61C62.6569 1130.31 64 1131.65 64 1133.31V1144.38C64 1146.04 62.6569 1147.38 61 1147.38H49C47.3431 1147.38 46 1146.04 46 1144.38V1133.31Z"
          fill="white"
        />
        <path
          d="M29 1158.38C29 1156.73 30.3431 1155.38 32 1155.38H44C45.6569 1155.38 47 1156.73 47 1158.38V1169.46C47 1171.12 45.6569 1172.46 44 1172.46H32C30.3431 1172.46 29 1171.12 29 1169.46V1158.38Z"
          fill="white"
        />
        <path
          d="M65 1158.38C65 1156.73 66.3431 1155.38 68 1155.38H80C81.6569 1155.38 83 1156.73 83 1158.38V1169.46C83 1171.12 81.6569 1172.46 80 1172.46H68C66.3431 1172.46 65 1171.12 65 1169.46V1158.38Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="174"
      height="1364"
      viewBox="0 0 174 1364"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M0 0H174V995.197L0 1364V0Z" fill={color} />
    </svg>
  )
}
