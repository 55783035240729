import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  deleteUserInOrganisation,
  switchUsersRoles
} from '../../../endpoints/admin'
import { NOTIFICATION } from '../../../utils/consts'

import useNotification from '../../SnackbarManager/hooks/useNotification'

function useMembersController({ fetchMembers }) {
  const addNotification = useNotification()
  const [isBulkSelect, setBulk] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selected, setSelected] = useState([])
  const { user } = useSelector(state => state.login.currentUser) || {}
  const members = useSelector(state => state.membersStore.members)
  const [membersToUpdate, setMembersToUpdate] = useState([])

  const switchRole = useCallback(
    (id, role) => {
      const itemExists = membersToUpdate.some(member => id === member.id)
      if (itemExists) {
        return setMembersToUpdate(prevState =>
          prevState?.filter(member => member.id !== id)
        )
      }
      const updated = {
        id,
        manager: role
      }
      return setMembersToUpdate([...membersToUpdate, updated])
    },
    [membersToUpdate, setMembersToUpdate, members]
  )

  const handleUpdate = useCallback(async () => {
    try {
      setLoading(true)
      await switchUsersRoles({ data: membersToUpdate })
      await fetchMembers()
      setMembersToUpdate([])
      const message =
        membersToUpdate.length > 1
          ? 'Users roles updated successfully'
          : 'User role updated successfully'
      addNotification({
        type: NOTIFICATION.SUCCESS,
        title: message
      })
    } catch (err) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: err
      })
    }
    setLoading(false)
  }, [
    membersToUpdate,
    setMembersToUpdate,
    switchUsersRoles,
    addNotification,
    NOTIFICATION
  ])

  const cancelUpdate = useCallback(
    () => setMembersToUpdate([]),
    [setMembersToUpdate]
  )

  const { company } = user || {}

  const [member, selectAuthor] = useState(null)

  const handleDelete = useCallback(
    async ({ ids: id, onSuccess }) => {
      try {
        const ids = Array.isArray(id) ? id : [id]
        const values = { user_ids: ids, company_id: company?.id }
        const { status } = await deleteUserInOrganisation(values)
        if (status === 200) {
          await onSuccess()
          await fetchMembers()
          const message =
            ids.length > 1
              ? 'Rows deleted successfully'
              : 'Row deleted successfully'
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: message
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, fetchMembers, company?.id]
  )

  const deselectAll = useCallback(() => setSelected([]), [])

  const handleSelect = useCallback(
    (id, isBulk = false) => {
      setBulk(isBulk)
      let newSelected
      if (!Array.isArray(selected)) {
        newSelected = [id]
      } else {
        const isSelected = selected.some(i => i === id)
        if (!isSelected) {
          newSelected = [...selected, id]
        } else {
          newSelected = selected.filter(i => i !== id)
        }
      }

      setSelected(newSelected)
    },
    [selected]
  )

  return {
    onSelect: handleSelect,
    onDelete: handleDelete,
    setSelected,
    isBulkSelect,
    deselectAll,
    selected,
    loading,
    member,
    selectAuthor,
    switchRole,
    onUpdate: handleUpdate,
    cancelUpdate,
    membersToUpdate
  }
}

export default useMembersController
