import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateRecord } from '../../../actions/recording'
import { editFolder, editRecord } from '../../../endpoints/admin'
import { NOTIFICATION, RECORD_TYPE } from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { useSafeDispatch } from '../../../hooks/useSafeDispatch'

function useEditData() {
  const [loading, setLoading] = useState(false)
  const addNotification = useNotification()
  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)
  const updateStore = useCallback(
    data => safeDispatch(updateRecord(data)),
    [safeDispatch]
  )
  const { probeOptions } = useSelector(state => state.probesStore)

  const handleEdit = useCallback(
    async ({ data, onSuccess, notifications = true }) => {
      try {
        setLoading(true)
        const { type, prb_file: selectedPrbFile, ...rest } = data || {}
        let probe_id = selectedPrbFile?.id ?? null
        const prb_file = selectedPrbFile?.name ?? selectedPrbFile

        const canGetId =
          selectedPrbFile &&
          !selectedPrbFile?.hasOwnProperty('id') &&
          selectedPrbFile?.hasOwnProperty('index')
        if (canGetId) {
          const probe = probeOptions?.get(selectedPrbFile?.index)
          probe_id = probe?.probe_id ?? null
        }

        const query = type === RECORD_TYPE.FOLDER ? editFolder : editRecord
        const withProbe = selectedPrbFile ? { probe_id, prb_file } : {}
        const requestData = { ...rest, ...withProbe }
        const response = await query(requestData)
        await onSuccess(updateStore, response)
        if (notifications)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Record successfully edited !'
          })
        setLoading(false)
      } catch (e) {
        setLoading(false)
        if (notifications)
          addNotification({
            type: NOTIFICATION.ERROR,
            title: e
          })
      }
    },
    [addNotification, updateStore, probeOptions]
  )

  return { onEdit: handleEdit, loading }
}

export default useEditData
