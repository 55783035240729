import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import MenuList from '../AppHeader/components/MenuList/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px 125px',
    height: '100%',
    flexWrap: 'nowrap',
    overflow: 'auto',
    backgroundColor: theme.palette.colors.backgrounds.main
  },
  container: {
    height: '100%',
    overflow: 'hidden'
  },
  title: {
    top: 0,
    fontSize: 28,
    lineHeight: '32px',
    fontWeight: 600,
    marginBottom: 40,
    position: 'sticky',
    color: theme.palette.primary.contrast
  },
  nav: {
    width: 195,
    position: 'sticky',
    top: 0
  },
  menuList: {
    padding: '12px 0',
    margin: 0,
    borderLeft: `1px solid ${theme.palette.colors.neutral['7']}`
  },
  content: {
    flex: 1,
    height: '100%',
    display: 'flex',
    overflow: 'auto',
    padding: '10px 40px 50px',
    borderLeft: `1px solid ${theme.palette.colors.neutral['7']}`,
    ...theme.scrollbar()
  }
}))

const ConfigurationLayout = ({
  title,
  menuItem,
  children,
  Header,
  classes: overrideClasses = {}
}) => {
  const classes = useStyles()

  return (
    <Grid container direction="column" className={classes.root}>
      {(Header && <Header />) ||
        (title && <Typography className={classes.title}>{title}</Typography>)}
      <Grid
        container
        wrap="nowrap"
        alignItems="flex-start"
        className={classes.container}
      >
        <div className={classes.nav}>
          <MenuList menuItem={menuItem} className={classes.menuList} />
        </div>
        <div className={cn(classes.content, overrideClasses.content)}>
          {children}
        </div>
      </Grid>
    </Grid>
  )
}

export default ConfigurationLayout
