import React from 'react'
import { makeStyles } from '@material-ui/core'
import Tree from './Tree'
import { HoverProvider } from '../contexts'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: 10,
    height: '50vh'
  }
})

export default function SelectPosition({ treeData, updateNodes }) {
  const classes = useStyles()
  
  return (
    <HoverProvider>
      <div className={classes.root}>
        <Tree treeData={treeData} updateNodes={updateNodes} />
      </div>
    </HoverProvider>
  )
}
