import React from 'react'
import JoyRide from 'react-joyride'
import CustomTooltip from './components'
import useSteps from './hooks/useSteps'
import { useTheme } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const JoyrideWrapper = ({ children, tourState, callback }) => {
  let stepName
  const location = useLocation()
  const currentUser = useSelector(state => state.login?.currentUser) || {}
  const onboardings = currentUser?.user?.onboardings || []
  const pathname = location.pathname.split('/')[1]
  if (pathname === 'recording') {
    const { status } = onboardings?.find(el => el?.step === pathname) || {}
    if (status) stepName = 'curation_AI'
  }
  const { steps } = useSteps(stepName)
  const theme = useTheme()

  return (
    <>
      <JoyRide
        {...tourState}
        steps={steps}
        callback={callback}
        showSkipButton={true}
        disableScrolling
        tooltipComponent={CustomTooltip}
        floaterProps={{
          styles: {
            floaterOpening: {
              filter: `drop-shadow(${theme.palette.colors.customColor(
                null,
                theme.palette.secondary[100]
              )} 0px 0px 1px)`
            }
          }
        }}
        styles={{
          overlay: {
            maxHeight: '100vh'
          },
          tooltipContainer: {
            textAlign: 'left'
          },
          buttonBack: {
            marginRight: 10
          },
          options: {
            arrowColor: theme.palette.colors.backgrounds.main
          }
        }}
        locale={{
          last: 'Complete'
        }}
      />
      {children}
    </>
  )
}
export default JoyrideWrapper
