import { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import axios from 'axios'
import { getRasterData } from '../../../endpoints/admin'
import { to } from '../../../utils/utils'
import { MyMap, makeKey } from './utils'
import { seedUnitColor } from '../../../shared/utils'
import { RASTER_UNITS } from '../../../utils/consts'
import { useSelector } from 'react-redux'

export const UNIT_HEIGHT = 15
export const BASE_HEIGHT = 200
const { uncurated } = RASTER_UNITS

const fetchData = async (
  dispatch,
  {
    timeRange,
    record_id,
    cancelTokenSource,
    curation_id,
    startChannels,
    endChannels
  },
  cache
) => {
  const { err, data } = await to(getRasterData, {
    startRange: timeRange[0],
    endRange: timeRange[1],
    record_id,
    curation_id,
    startChannels,
    endChannels,
    cancelTokenSource
  })

  if (!err) {
    if (data) {
      const newDataSpikes = getNewDataSpikes(data)
      dispatch(newDataSpikes)
      cache && cache(newDataSpikes)
    } else {
      dispatch(null)
    }
  }
}

const getNewDataSpikes = data => {
  const unitData = Object.values(data.units)
  let units = []
  let spikes = []
  unitData.forEach(data => {
    const unit = Number(Object.keys(data)[0])
    const spike = Object.values(data)[0]
    units.push(unit)
    spikes.push(spike)
  })

  return {
    spikes,
    units,
    unitsColor: units.map(seedUnitColor),
    layerHeight: Math.min(
      BASE_HEIGHT,
      units.reduce(acc => acc + UNIT_HEIGHT, 0)
    ),
    rawData: data
  }
}

export const useFetchRasterData = record_id => {
  const request = useRef(null)
  const cache = useRef(new MyMap([]))
  const [timeRange, setRange] = useState([0, 0])
  const [normalSpikes, setSpikes] = useState(null)
  const [filteredSpikes, setFilteredSpikes] = useState(null)
  const [filters, setFilters] = useState([])
  const { id: curation_id } =
    useSelector(state => state.recordingStore.selectedCuration) || {}
  const channelsRange = useSelector(
    state => state.timeSeriesLeftPanel.currentChannelsRange
  )
  const channelsRangeList = channelsRange?.split('-') || []
  const [startChannelsStr, endChannelsStr] = channelsRangeList
  const startChannels = startChannelsStr?.trim()
  const endChannels = endChannelsStr?.trim()

  useEffect(() => {
    const source = axios.CancelToken.source()

    if (!timeRange?.length) return
    const key = makeKey(timeRange, channelsRangeList)
    const value = cache.current.get(key)

    if (request.current) {
      request.current.cancel('axios request cancelled')
    }
    request.current = source

    if (!value && channelsRange) {
      fetchData(
        setSpikes,
        {
          timeRange,
          record_id,
          cancelTokenSource: source,
          curation_id,
          startChannels,
          endChannels
        },
        data => {
          cache.current.set(key, data)
        }
      )
    } else {
      setSpikes(value)
    }

    return () => {
      source.cancel('axios request cancelled')
    }
  }, [timeRange, record_id, curation_id, channelsRange])

  const update = tRange => {
    setRange(tRange)
  }

  const applyFilter = useCallback(
    filters => {
      const { rawData } = normalSpikes ?? {}
      if (rawData) {
        const { labels, units } = rawData
        const filteredChannels = labels.reduce((prev, channel) => {
          const label = Object.values(channel)[0]
          const hasLabel = filters.includes(label)
          const filterBy = filters.includes(uncurated) ? !hasLabel : hasLabel
          if (filterBy) return { ...prev, ...channel }
          return prev
        }, {})

        const filteredUnits = units.filter(unit => {
          const channel = Object.keys(unit)[0]
          const hasChannel = Object.keys(filteredChannels).includes(channel)
          return filters.includes(uncurated) ? !hasChannel : hasChannel
        })

        const newDataSpikes = getNewDataSpikes({ units: filteredUnits })
        setFilteredSpikes(newDataSpikes)
        return
      }
      setFilteredSpikes({ spikes: [] })
    },
    [normalSpikes]
  )

  useEffect(() => {
    if (filters.length > 0) applyFilter(filters)
  }, [normalSpikes])

  const onFilterItemClick = useCallback(
    param => {
      let newFilters = filters?.includes(param)
        ? filters.filter(val => val !== param)
        : [...filters, param]

      setFilters(newFilters)
      applyFilter(newFilters)
    },
    [filters, applyFilter]
  )

  let _spikes = useMemo(
    () => (filters.length > 0 ? filteredSpikes : normalSpikes),
    [filteredSpikes, filters, normalSpikes]
  )

  return {
    ..._spikes,
    layerHeight: _spikes?.layerHeight ?? BASE_HEIGHT,
    update,
    filters,
    onFilterItemClick
  }
}
