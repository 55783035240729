import React from 'react'

const Stagnate = props => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="18.0003"
        cy="18.0003"
        r="17.1429"
        fill="#21B784"
        fill-opacity="0.1"
      />
      <circle cx="18" cy="18" r="12" fill="#21B784" fill-opacity="0.2" />
      <circle cx="17.9997" cy="17.9997" r="6.85714" fill="#21B784" />
    </svg>
  )
}

export default Stagnate
