import React from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core'
import HoveredChild from './HoveredChild'
import { useHover } from '../contexts'
import { useSelectPositionModal } from '../hooks'
import { useDrag } from '../contexts/drag'

const WIDGET_PADDING = 3
const WIDGET_BORDER = 5

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    border: `5px solid ${theme.palette.colors.neutral[7]}`,
    '&.drag-over': {
      border: `2px dashed ${theme.palette.colors.neutral[6]}`
    }
  },
  boxLevel1: {
    width: '50%',
    display: 'flex',
    border: 'none',
    boxSizing: 'border-box',
    justifyContent: 'center'
  },
  boxLevel2: ({ unwrappChild, visible, reverse }) => ({
    width: '50%',
    display: visible ? 'flex' : 'none',
    padding: unwrappChild ? 0 : 10,
    border: 'none',
    boxSizing: 'border-box',
    justifyContent: unwrappChild ? 'flex-start' : 'space-between',
    flexDirection: reverse ? 'column-reverse' : 'column'
  }),
  boxLevel3: ({ visible, unwrappChild }) => ({
    width: unwrappChild
      ? '200%'
      : `calc(200% + ${WIDGET_PADDING * 4}px + ${WIDGET_BORDER * 2}px)`,
    height: unwrappChild ? '50%' : '48%',
    border: 'none',
    display: visible ? 'flex' : 'none',
    boxSizing: 'border-box',
    zIndex: '+1',
    justifyContent: 'space-between',
    flexDirection: 'column'
  }),
  boxLevel4: ({ visible, unwrappChild }) => ({
    display: visible ? 'block' : 'none',
    width: '100%',
    height: unwrappChild ? '50%' : '47%'
  }),
  boxHovered: {
    borderStyle: 'dashed',
    borderColor: `${theme.palette.colors.neutral[6]}`,
    padding: 0
  },
  widgetSelected: {
    padding: '3px!important',
    border: 'none'
  },
  unwrapped: {
    border: 'none',
    padding: 0
  }
}))

const SelectPositionBox = ({
  children,
  level,
  id,
  updateNodes,
  value,
  reverse = false,
  visible,
  unwrappChild
}) => {
  const { setDestination } = useDrag()
  const classes = useStyles({ unwrappChild, reverse, visible })
  const { selected } = useHover()
  const { modalState, mouseClickedElement, setMouseClickedElement } =
    useSelectPositionModal()

  const mouseDown = e => {
    setMouseClickedElement(e.target)
  }
  const onClick = e => {
    const mElement = mouseClickedElement?.getAttribute('data-id')
    if (!updateNodes) return

    let dataId = e.target.getAttribute('data-id')

    const l4Vals = ['11', '12', '13', '14', '15', '16', '17', '18']
    if (l4Vals.includes(mElement) && (dataId === '1' || dataId === '2')) {
      const l3Vals = [7, 8, 9, 10]

      let index = 0
      if (dataId === '1') {
        index = l4Vals.indexOf(mElement)
        if (index > 1) {
          index = index % 2 === 0 ? 0 : 1
        }
      } else if (dataId === '2') {
        index = l4Vals.indexOf(mElement)
        index = index % 2 === 0 ? 2 : 3
      }
      dataId = l3Vals[index]
    }
    if (!dataId) return

    updateNodes({
      id: dataId,
      value: modalState?.args?.label,
      widgetId: modalState?.args?.id
    })
  }

  const dragOverClass = 'drag-over'

  const dragEnter = e => {
    e.preventDefault()
    e.target.classList.add(dragOverClass)
  }

  const dragOver = e => {
    e.preventDefault()
    e.target.classList.add(dragOverClass)
  }

  const dragLeave = e => {
    e.target.classList.remove(dragOverClass)
  }

  const drop = e => {
    const t = e.target
    const dataId =
      t.getAttribute('data-id') ||
      t.closest('[data-id]').getAttribute('data-id')
    t.classList.remove(dragOverClass)

    if (level > 0 && unwrappChild) setDestination(Number(dataId) || id)
  }

  const usedChild =
    selected === id ? <HoveredChild selected={selected} /> : children

  return (
    <div
      data-id={id}
      className={cn(classes.box, classes[`boxLevel${level}`], {
        [classes.boxHovered]: selected === id,
        [classes.widgetSelected]: Boolean(value),
        [classes.unwrapped]: unwrappChild
      })}
      onClick={onClick}
      onDragEnter={dragEnter}
      onDragOver={dragOver}
      onDragLeave={dragLeave}
      onDrop={drop}
      onMouseDown={mouseDown}
    >
      {React.cloneElement(usedChild, {
        level,
        parentId: id
      })}
    </div>
  )
}

export default SelectPositionBox
