import React, { useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import PersonIcon from '@material-ui/icons/Person'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    width: '100%',
    maxWidth: '100%',
    minHeight: '150px',
    padding: '5px 0',
    paddingTop: '8px',
    paddingLeft: '12px',
    border: `1px ${theme.palette.colors.neutral[6]} solid`,
    borderRadius: '5px',
    color: 'black',
    position: 'relative'
  },
  input: () => ({
    background: theme.palette.colors.backgrounds.modals,
    color: theme.palette.colors.neutral.black,
    minWidth: '10px',
    padding: 0,
    border: 'none',
    borderRadius: '5px'
  }),
  tag: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.colors.neutral[8],
    color: theme.palette.colors.neutral.black_80,
    height: '30px',
    margin: '6px',
    paddingLeft: '10px',
    borderRadius: '24px',
    fontWeight: 600
  },
  icon: {
    marginLeft: '2px',
    marginRight: '2px',
    color: theme.palette.colors.neutral[5]
  },
  delete: {
    cursor: 'pointer'
  },
  label: {
    background: theme.palette.colors.backgrounds.modals,
    color: theme.palette.colors.neutral.black,
    padding: '0 5px',
    position: 'absolute',
    top: '0',
    transform: 'translateY(-50%)',
    fontWeight: 600,
    fontSize: 13
  }
}))

export default function TagsInput({
  inputLabel = 'Email Adresses',
  inputPlaceholder = 'Enter a tag',
  validateInput = () => true,
  tags,
  setTags
}) {
  const classes = useStyles()
  const [input, setInput] = useState('')
  const [isKeyReleased, setIsKeyReleased] = useState(false)

  const onChange = e => {
    const { value } = e.target
    setInput(value)
  }

  const onKeyDown = e => {
    const { key } = e
    const trimmedInput = input.trim()

    if (key === 'Enter' && trimmedInput.length) {
      e.preventDefault()
      if (!tags.includes(trimmedInput) && validateInput(trimmedInput)) {
        setTags(prevState => [...prevState, trimmedInput])
        setInput('')
      }
    }

    if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags]
      const poppedTag = tagsCopy.pop()
      e.preventDefault()
      setTags(tagsCopy)
      setInput(poppedTag)
    }

    setIsKeyReleased(false)
  }

  const onKeyUp = () => {
    setIsKeyReleased(true)
  }

  const deleteTag = index => {
    setTags(prevState => prevState.filter((tag, i) => i !== index))
  }

  return (
    <div className={classes.container}>
      <span className={classes.label}>{inputLabel}</span>
      {tags.map((tag, index) => (
        <Box className={classes.tag}>
          <PersonIcon className={classes.icon} />
          {tag}
          <HighlightOffIcon
            className={cn(classes.icon, classes.delete)}
            onClick={() => deleteTag(index)}
          />
        </Box>
      ))}
      <input
        className={classes.input}
        value={input}
        placeholder={tags.length === 0 ? inputPlaceholder : 'add more..'}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={onChange}
      />
    </div>
  )
}
