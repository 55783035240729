import { useState, useCallback } from 'react'
import { getTsInfo } from '../../../endpoints/admin'
import { to } from '../../../utils/utils'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  setTimeseriesInfo,
  resetTimeseries
} from '../../../components/TimeseriesView/NewLeftPanel/redux/actions'
import useNNDController from '../../../components/TimeseriesView/NewLeftPanel/components/FiltersTab/hooks/useNNDController'

export const useTimeseriesInfo = (recordingId, cb) => {
  const dispatch = useDispatch()
  const { nndTimeseriesInfo, fetchNND } = useNNDController()
  const { timeseriesInfo, isRefreshTimeSeries } = useSelector(
    state => state.timeSeriesLeftPanel,
    shallowEqual
  )
  const {
    status: [status, setStatus],
    statusMessage: [statusMessage, setStatusMessage]
  } = useStatus()

  const resetTSStore = useCallback(() => dispatch(resetTimeseries()), [])

  const effect = async () => {
    setStatus('calculating')
    setStatusMessage('Calculating timeseries info')

    const { err, data } = await to(getTsInfo, { record_id: recordingId })
    await fetchNND({ data })
    if (err) {
      setErrorStatus({ message: 'Problem calculating timeseries info.' })
      return
    } else {
      if (data) {
        dispatch(setTimeseriesInfo(data))
        setStatusMessage('Setting timeseries model')
        cb && cb(data)
        setStatus('finished')
      } else {
        setErrorStatus({
          message:
            'Unepected problem calculating timeseries info: info is null.'
        })

        return
      }
    }
  }
  const setErrorStatus = useCallback(
    ({ message }) => {
      setStatus('error')
      setStatusMessage(message)
    },
    [setStatus, setStatusMessage]
  )

  return {
    status,
    statusMessage,
    timeseriesInfo,
    nndTimeseriesInfo,
    effect,
    isRefreshTimeSeries,
    resetTimeseries: resetTSStore
  }
}

export const useStatus = () => {
  const [status, setStatus] = useState('pending')
  const [statusMessage, setStatusMessage] = useState(null)

  return {
    status: [status, setStatus],
    statusMessage: [statusMessage, setStatusMessage]
  }
}
