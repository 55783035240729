import { ROUTES } from '../../../utils/consts'

export const tabs = [
  {
    to: ROUTES.TRAIN_AI,
    label: 'Train new AI model'
  },
  {
    to: ROUTES.TRAIN_AI_EXISTING,
    label: 'Existing AI models'
  }
]
