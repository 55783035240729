import React, { memo, useCallback, useEffect } from 'react'
import CustomModal from '../../../CustomModal'
import ActionButton from '../../../ActionButton'
import { OpenWith } from '@material-ui/icons'
import useGetFolders from './hooks/useGetFolders'
import FoldersList from './components/FoldersList'
import { makeStyles } from '@material-ui/core/styles'
import useNewFolderModal from '../../hooks/useNewFolderModal'
import { validateName } from '../../../../utils/utils'
import useGetAllRecords from '../../../FilesTable/hooks/useGetAllRecords'
import useFilesQueryParams from '../../../FilesTable/hooks/useFilesQueryParams'

const useStyles = makeStyles(theme => ({
  body: {
    height: 350,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  actions: {
    display: 'flex',
    marginLeft: 15
  },
  uploadBtn: {
    background: 'transparent',
    border: `3px solid ${theme.palette.action.secondary}`,
    '& p': {
      color: theme.palette.action.secondary
    },
    '&:hover': {
      backgroundColor: 'transparent!important'
    }
  }
}))

const MoveToModal = ({
  isOpened,
  toggleModal,
  deselectAll = () => null,
  onSubmit = () => null,
  loading: submitLoading
}) => {
  const classes = useStyles()
  const {
    onSelect,
    state,
    folder_id: distination_folder,
    refetchFolders
  } = useGetFolders(isOpened)
  const { folder_id: currentFolder } = useFilesQueryParams()
  const { fetchRecords } = useGetAllRecords(false)
  const { data } = state || {}

  const { toggleModal: toggleNewFolderModal } = useNewFolderModal()

  const toggleCreateMode = useCallback(() => {
    toggleNewFolderModal({
      data: {
        parent_id: distination_folder
      },
      validateName: name => validateName(name, data?.data),
      onSuccess: () => {
        refetchFolders()

        // refetch main table in case if I created folder
        // inside move to modal in same directory
        if (Number(distination_folder) === Number(currentFolder)) fetchRecords()
      }
    })
  }, [
    toggleNewFolderModal,
    distination_folder,
    data?.data,
    refetchFolders,
    currentFolder,
    fetchRecords
  ])

  const onMoveTo = useCallback(async () => {
    await onSubmit({
      data: {
        distination_folder
      }
    })
    deselectAll()
  }, [onSubmit, deselectAll, distination_folder])

  const RenderActionButtons = memo(() => {
    return (
      <div className={classes.actions}>
        <ActionButton
          label="New Folder"
          onClick={toggleCreateMode}
          className={classes.uploadBtn}
        />

        <ActionButton
          label="Move here"
          onClick={onMoveTo}
          loading={submitLoading}
        />
      </div>
    )
  })

  useEffect(() => {
    return () => onSelect(null)
    // eslint-disable-next-line
  }, [isOpened])

  return (
    <CustomModal
      maxWidth={555}
      open={isOpened}
      title="Move to"
      onClose={toggleModal}
      headerIcon={<OpenWith />}
      renderActionButton={<RenderActionButtons />}
    >
      <FoldersList onSelect={onSelect} state={state} />
    </CustomModal>
  )
}

export default memo(MoveToModal)
