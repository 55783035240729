import React from 'react'
import cn from 'classnames'
import { IconButton, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  rootBtn: {
    padding: 5,
    '&:hover svg': {
      color: 'initial'
    }
  },
  checkbox: {
    width: 24,
    height: 24,
    borderRadius: 24,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette.action.disabled}`
  },
  selectedCheckbox: {
    backgroundColor: theme.palette.colors.white,
    border: `6px solid ${theme.palette.primary.main}`
  }
}))

const TableCheckbox = ({ isDefault, className, onClick }) => {
  const classes = useStyles()
  return (
    <IconButton className={cn(classes.rootBtn, className)} onClick={onClick}>
      <div
        className={cn(classes.checkbox, {
          [classes.selectedCheckbox]: isDefault
        })}
      />
    </IconButton>
  )
}

export default TableCheckbox
