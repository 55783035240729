import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'

const CustomSlider = withStyles(theme => ({
  root: {
    color: '#3a8589',
    height: 25,
    padding: '20px 0',
    width: '99%'
  },
  thumb: {
    height: 25,
    width: 28,
    backgroundColor: `rgba(${theme.palette.colors.neutral.rgb_3}, 0.24)`,
    border: 'none',
    marginTop: 0,
    borderRadius: 0,
    marginLeft: props => (props.value >= props.max / 2 ? -25 : 0),
    boxShadow: 'none',
    '&:focus, &:hover, &$active': {
      boxShadow: 'none',
      backgroundColor: `rgba(${theme.palette.colors.neutral.rgb_3}, 0.2)`
    },
    '& .bar': {
      height: 13,
      width: 2,
      backgroundColor: theme.palette.colors.white,
      marginLeft: 4,
      marginRight: 4
    }
  },
  active: {},
  track: {
    display: 'none',
    height: 3
  },
  rail: {
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['8'],
      theme.palette.colors.neutral['9']
    ),
    opacity: 1,
    height: 25,
    borderRadius: 8
  }
}))(Slider)

const AirbnbThumbComponent = React.memo(props => {
  return (
    <span {...props}>
      <span className="bar" />
      <span className="bar" />
    </span>
  )
})

const step = 0.001

const SpanWidget = ({
  onTimeRangeChanged,
  info: { timeRange = [0, 0], numTimepoints }
}) => {
  const [value, setValue] = useState(0)
  const [steper, setStepper] = useState(0)

  const rangeDiff = timeRange[1] - timeRange[0]

  React.useEffect(() => {
    if (timeRange && timeRange[0] !== 0) {
      setValue(timeRange[0])
      return
    }
    value !== 0 && setValue(0)
  }, [timeRange])

  React.useEffect(() => {
    setStepper(timeRange[1] - timeRange[0])
  }, [timeRange, rangeDiff])

  return (
    <Grid container alignItems="center">
      <CustomSlider
        ThumbComponent={AirbnbThumbComponent}
        step={step}
        min={0}
        max={numTimepoints}
        onChangeCommitted={() => {
          onTimeRangeChanged([value, value + steper])
        }}
        onChange={(_e, value) => {
          setValue(value)
        }}
        value={value}
      />
    </Grid>
  )
}

export default React.memo(SpanWidget)
