import React, { useCallback } from 'react'
import CustomInput from '../../../FormComponents/CustomInput/CustomInput'
import { Formik } from 'formik'
import useSignInUser from '../../hooks/useSignInUser'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Link, useHistory } from 'react-router-dom'
import CustomButton from '../../../CustomButton'
import { ROUTES } from '../../../../utils/consts'
import usePasswordType from '../../hooks/usePasswordType'

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 360,
    width: '100%'
  },
  loginButton: {
    marginTop: 24
  },
  forgotPasswordLink: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 600,
    lineHeight: '18px',
    color: theme.palette.colors.darkMain,
    marginTop: 24,
    textDecoration: 'none'
  }
}))

const LoginForm = () => {
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()

  const { onSubmit, initialValues, validation } = useSignInUser()

  const { passwordType, IconType } = usePasswordType()

  const routeToSignup = useCallback(() => {
    history.push(ROUTES.SIGNUP)
  }, [history])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={values => onSubmit(values)}
    >
      {({
        isSubmitting,
        values: { username, password },
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        errors
      }) => {
        const handleKeyPress = e => {
          if (e.key.toLowerCase() === 'enter') {
            handleSubmit()
          }
        }
        return (
          <form className={classes.form} onSubmit={handleSubmit}>
            <CustomInput
              label="Username"
              name="username"
              required
              fullWidth
              variant="outlined"
              value={username}
              error={errors}
              touched={touched}
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
            />
            <CustomInput
              label="Password"
              name="password"
              type={passwordType}
              required
              fullWidth
              variant="outlined"
              value={password}
              error={errors}
              touched={touched}
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              endAdornment={IconType}
            />
            <CustomButton
              label="Login"
              onClick={handleSubmit}
              loading={isSubmitting}
              loadingColor={theme.palette.colors.white}
              className={classes.loginButton}
            />

            <CustomButton
              label="Signup"
              backgroundColor={theme.palette.colors.white}
              color={theme.palette.colors.darkBlue}
              onClick={routeToSignup}
              className={classes.loginButton}
            />
            <Link
              to={ROUTES.RESET_PASSWORD}
              className={classes.forgotPasswordLink}
            >
              Forgot password?
            </Link>
          </form>
        )
      }}
    </Formik>
  )
}

export default LoginForm
