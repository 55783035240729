import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    marginRight: 8
  },
  label: {
    fontSize: 13,
    marginRight: 4,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  }
}))

const BottomWidget = ({ label, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography className={classes.label}>{label}:</Typography>
      {children}
    </div>
  )
}

export default BottomWidget
