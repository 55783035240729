import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import ActionButton from '../../../ActionButton'
import CheckIcon from '@material-ui/icons/Check'
import CustomModal from '../../../CustomModal'
import useToggleModal from '../../../../hooks/useToggleModal'
import { MODALS_ID } from '../../../../utils/consts'
import TimerIcon from '@material-ui/icons/Timer'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  headerIcon: {
    color: theme.palette.colors.status.success
  },
  headerIconError: {
    color: theme.palette.colors.status.error
  }
}))

export default function SuccessModal({
  title,
  children,
  message,
  deselectAll = null
}) {
  const classes = useStyles()
  const {
    toggle: toggleModal,
    isOpened,
    modalState
  } = useToggleModal(MODALS_ID.DATA_MANIPULATION_SUCCESS_MODAL)
  const {
    onClose,
    title: customTitle,
    message: customMessage,
    status_int
  } = modalState?.args || {}

  const inQueue = status_int === 4
  const TITLE = inQueue ? 'Operation in queue' : customTitle || title
  const MESSAGE = customMessage || message
  const ICON = inQueue ? TimerIcon : CheckIcon

  const handleOnClose = () => {
    toggleModal()
    if (deselectAll) deselectAll()
    if (onClose) onClose()
  }

  return (
    <CustomModal
      open={isOpened}
      title={TITLE}
      onClose={handleOnClose}
      maxWidth={600}
      headerIcon={
        <ICON
          className={cn(classes.headerIcon, {
            [classes.headerIconError]: inQueue
          })}
        />
      }
      hideCloseButton
      renderActionButton={<ActionButton label="Okay" onClick={handleOnClose} />}
    >
      <Typography>{MESSAGE}</Typography>
      {children}
    </CustomModal>
  )
}
