import { EXPERIMENTS_INPUT } from '../utils/consts'

export const STORE_EXPERIMENTS_INPUT = 'STORE_EXPERIMENTS_INPUT'
export const EDIT_EXPERIMENTS_INPUT = 'EDIT_EXPERIMENTS_INPUT'
export const SET_EXP_INPUT_TO_EDIT = 'SET_EXP_INPUT_TO_EDIT'

// UPLOADS INPUT

const updKey = EXPERIMENTS_INPUT.UPLOADS

export const storeUploadInputs = data => ({
  type: STORE_EXPERIMENTS_INPUT,
  payload: { key: updKey, data }
})

// SORTINGS INPUT

const ssKey = EXPERIMENTS_INPUT.SPIKESORTINGS

export const storeSortingsInputs = data => ({
  type: STORE_EXPERIMENTS_INPUT,
  payload: { key: ssKey, data }
})

export const setEditExoInput = data => ({
  type: SET_EXP_INPUT_TO_EDIT,
  payload: data
})

export const editExpInput = (key, data) => ({
  type: EDIT_EXPERIMENTS_INPUT,
  payload: { key, data }
})
