import { Observable } from 'rxjs'
import axios from 'axios'


/*
Is taking as input a function wich return an axios instance
and inject into it a cancelation token by converting it into an observable
*/
export const getAsObservable = (fn, config) =>
  new Observable(observer => {
    const cancelToken = axios.CancelToken.source()

    fn({
      cancelToken: cancelToken.token,
      resolved: result => {
        observer.next(result)
        observer.complete()
      },
      rejected: error => {
        if (axios.isCancel(error)) {
          observer.complete()
        } else {
          observer.error(error)
        }
      }
    })(config)

    return () => cancelToken.cancel()
  })
