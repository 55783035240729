import { useRef, useState, useMemo, useEffect, useCallback } from 'react'
import {
  enableHorizontalParentNode,
  enableVerticalParentNode,
  enableChildDisabledNodes,
  enableMainParentNode
} from './helpers'
import Storage from '../../../../../AppHeader/components/Storage'

const l4 = { level: 4, value: null }
const l3 = { level: 3, value: null }
const l2 = { level: 2, value: null }
const l1 = { level: 1, value: null }

const items = [
  { id: 1, parentId: 0, visible: true, ...l1 },
  { id: 2, parentId: 0, visible: true, ...l1 },
  { id: 3, parentId: 1, visible: true, ...l2 },
  { id: 4, parentId: 1, visible: true, ...l2 },
  { id: 5, parentId: 2, visible: true, ...l2 },
  { id: 6, parentId: 2, visible: true, ...l2 },
  { id: 7, parentId: 3, visible: false, ...l3 },
  { id: 8, parentId: 3, visible: false, ...l3 },
  { id: 9, parentId: 5, visible: false, ...l3 },
  { id: 10, parentId: 5, visible: false, ...l3 },
  { id: 11, parentId: 3, visible: true, ...l4 },
  { id: 12, parentId: 3, visible: true, ...l4 },
  { id: 13, parentId: 4, visible: true, ...l4 },
  { id: 14, parentId: 4, visible: true, ...l4 },
  { id: 15, parentId: 5, visible: true, ...l4 },
  { id: 16, parentId: 5, visible: true, ...l4 },
  { id: 17, parentId: 6, visible: true, ...l4 },
  { id: 18, parentId: 6, visible: true, ...l4 }
]

const defaultWidgets = [
  {
    id: 11,
    parentId: 3,
    visible: true,
    level: 4,
    value: 'Data',
    hasChildren: false,
    widgetId: 'data'
  },
  {
    id: 12,
    parentId: 3,
    level: 4,
    visible: true,
    value: 'ClusterView',
    hasChildren: false,
    widgetId: 'cluster_view'
  },
  {
    id: 13,
    parentId: 4,
    level: 4,
    visible: true,
    value: 'CorrelogramView',
    hasChildren: false,
    widgetId: 'correlogram_view'
  },
  {
    id: 14,
    parentId: 4,
    level: 4,
    visible: true,
    value: 'SimilarityView',
    hasChildren: false,
    widgetId: 'similarity_view'
  },
  {
    id: 5,
    parentId: 2,
    level: 2,
    visible: true,
    value: 'WaveFormView',
    hasChildren: false,
    widgetId: 'waveform_view'
  },
  {
    id: 6,
    parentId: 2,
    level: 2,
    visible: true,
    value: 'FeatureView',
    hasChildren: false,
    widgetId: 'feature_view'
  }
]

let horizontalNodes = [
  {
    id: 7,
    nodes: [11, 13],
    reverseNode: 4,
    oppositeNode: 8
  },
  {
    id: 8,
    nodes: [12, 14],
    reverseNode: 3,
    oppositeNode: 7
  },
  { id: 9, nodes: [15, 17], reverseNode: 6, oppositeNode: 10 },
  { id: 10, nodes: [16, 18], reverseNode: 5, oppositeNode: 9 }
]

function getTreeData(it) {
  return it.map(item => ({
    ...item,
    hasChildren: items.filter(i => i?.parentId === item?.id).length > 0
  }))
}

const getMaped = arr =>
  arr.map(v => [v?.id, v]).reduce((acc, v) => acc.set(v[0], v[1]), new Map([]))

const dafaultMaped = () => getMaped(defaultWidgets)

const itemsMaped = it => getMaped(getTreeData(it))

export const useDataNodes = () => {
  const [state, setState] = useState(itemsMaped(items))
  const [layoutDS, setDS] = useState(state)

  const nodeRef = useRef(null)

  const onSaveLayout = useCallback(() => {
    if (nodeRef.current === null) return

    let newLayoutDS = new Map(Array.from(layoutDS))

    const currentNodeID = Number(nodeRef.current?.id)

    if ([7, 8, 9, 10].includes(currentNodeID)) {
      let parentNode = [7, 8].includes(currentNodeID) ? 1 : 2
      if (newLayoutDS.get(parentNode).value !== null) {
        newLayoutDS.set(parentNode, {
          ...newLayoutDS.get(parentNode),
          visible: true,
          value: null,
          widgetId: null
        })
      }

      newLayoutDS = enableHorizontalParentNode(
        newLayoutDS,
        nodeRef,
        horizontalNodes,
        currentNodeID
      )
    } else {
      const disableNodes = horizontalNodes.find(value =>
        value.nodes.includes(currentNodeID)
      )

      let children = [11, 12, 13, 14].includes(currentNodeID)
        ? [11, 12, 13, 14]
        : [15, 16, 17, 18]
      let allTrue = true
      for (let [k, v] of newLayoutDS) {
        if (children.includes(Number(k)) && v.visible === false) {
          allTrue = false
          break
        }
      }

      if (disableNodes && !allTrue) {
        newLayoutDS = enableChildDisabledNodes(
          newLayoutDS,
          disableNodes,
          horizontalNodes,
          nodeRef,
          currentNodeID
        )
      } else {
        let verticalNodes = [
          {
            node: 3,
            children: [11, 12]
          },
          { node: 4, children: [13, 14] },
          { node: 5, children: [15, 16] },
          { node: 6, children: [17, 18] }
        ]

        let vNode = verticalNodes.find(
          value =>
            value.children.includes(currentNodeID) ||
            value.node === currentNodeID
        )

        if ([1, 2].includes(currentNodeID)) {
          newLayoutDS = enableMainParentNode(
            newLayoutDS,
            nodeRef,
            items,
            currentNodeID
          )
        } else {
          newLayoutDS = enableVerticalParentNode(
            newLayoutDS,
            nodeRef,
            vNode,
            currentNodeID
          )
        }
      }
    }

    const id = currentNodeID

    newLayoutDS.set(id, {
      ...newLayoutDS.get(id),
      value: nodeRef.current.value,
      widgetId: nodeRef.current.widgetId
    })

    setDS(newLayoutDS)
    resetNodes()
  }, [layoutDS])

  const updateNodes = useCallback(node => {
    nodeRef.current = node

    let nItems = []
    let nodeID = Number(node?.id)
    if ([7, 8, 9, 10].includes(nodeID)) {
      const filterWith = horizontalNodes.find(value => value?.id === nodeID)
      nItems = items.map(item => {
        const itemID = item?.id
        if (itemID === filterWith?.id) {
          return { ...item, visible: true }
        } else if (filterWith.nodes.includes(itemID)) {
          return { ...item, visible: false }
        } else if (
          itemID === filterWith.reverseNode &&
          [7, 9].includes(filterWith?.id)
        ) {
          return { ...item, reverse: true }
        } else if (
          [8, 10].includes(filterWith?.id) &&
          [3, 5].includes(itemID)
        ) {
          return { ...item, reverse: true }
        }
        return item
      })
    }

    const newItems =
      nItems.length === 0 ? itemsMaped(items) : itemsMaped(nItems)

    const el = newItems.get(nodeID)

    if (el?.id)
      newItems.set(el.id, {
        ...el,
        value: node.value,
        widgetId: node.widgetId
      })
    setState(new Map(Array.from(newItems)))
  }, [])

  const resetModal = () => setState(itemsMaped(items))

  const resetNodes = () => {
    const newItems = itemsMaped(items)

    setState(new Map(Array.from(newItems)))
    setDS(current => {
      const id = Number(nodeRef.current?.id ?? 0)
      const copy = new Map(Array.from(current))
      const old = [...copy.values()].find(
        x => x.value === nodeRef.current?.value
      )

      if (id) {
        copy.set(id, { ...copy.get(id), value: null, widgetId: null })
        if (old) {
          copy.set(old.id, old)
        }

        nodeRef.current = null

        return copy
      }

      return copy
    })
  }

  const clearWidget = node => {
    setDS(current => {
      const copy = new Map(Array.from(current))
      const nodeID = node?.id
      if (copy.has(nodeID)) {
        copy.set(nodeID, { ...copy.get(nodeID), value: null, widgetId: null })
      }

      return copy
    })
  }

  const moveWidget = useCallback(
    (sourceNodeId, destinationNodeId) => {
      setDS(current => {
        const copy = new Map(Array.from(current))
        const source = copy.get(Number(sourceNodeId))
        const destination = copy.get(Number(destinationNodeId))

        for (let [k, v] of copy) {
          if (
            v?.value &&
            (v?.parentId === destination?.id || destination?.parentId === v?.id)
          ) {
            const newDestination = items.find(
              el =>
                (el?.parentId === source?.id || source?.parentId === el?.id) &&
                el.level === v.level
            )

            if (newDestination && newDestination?.id)
              copy.set(newDestination.id, {
                ...newDestination,
                value: v.value,
                widgetId: v.widgetId
              })

            copy.set(k, { ...v, value: null, widgetId: null })
          }
        }

        if (destination) {
          copy.set(source.id, {
            ...source,
            value: destination.value,
            widgetId: destination.widgetId
          })

          copy.set(destination.id, {
            ...destination,
            value: source.value,
            widgetId: source.widgetId
          })
        }

        return copy
      })
    },
    [setDS]
  )

  const clearAllWidgets = () => {
    setDS(itemsMaped(items))
  }

  const loadWidgetsDs = () => {
    const key = 'is-visited-RV-#1'
    const checkFromStorage = Storage.getItem('widgets-layout-DS-2')
    const isVisited = Storage.getItem(key)

    if (checkFromStorage && Array.isArray(checkFromStorage) && isVisited) {
      return new Map(checkFromStorage)
    }

    Storage.setItem(key, true)

    return new Map([...state, ...dafaultMaped()])
  }

  useEffect(() => {
    window.requestAnimationFrame(() => {
      Storage.setItem('widgets-layout-DS-2', Array.from(layoutDS))
    }, 0)
  }, [layoutDS])

  useEffect(() => {
    setDS(loadWidgetsDs())
    // eslint-disable-next-line
  }, [])

  return useMemo(
    () => ({
      state,
      updateNodes,
      resetNodes,
      resetModal,
      onSaveLayout,
      clearWidget,
      moveWidget,
      clearAllWidgets,
      layoutDS
    }),
    [layoutDS, onSaveLayout, state, updateNodes]
  )
}

export const useComposeNodes = () => {
  const {
    state,
    updateNodes,
    resetNodes,
    resetModal,
    onSaveLayout,
    layoutDS,
    clearWidget,
    moveWidget,
    clearAllWidgets
  } = useDataNodes()

  return {
    layoutDS,
    state,
    updateNodes,
    resetNodes,
    resetModal,
    onSaveLayout,
    clearWidget,
    moveWidget,
    clearAllWidgets
  }
}
