import { useReducer } from 'react'
import { to } from '../utils/utils'

const initialState = {
  data: null,
  error: null,
  loading: false
}

const fetchResultReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'INIT':
      return { data: null, loading: true, error: null }
    case 'SUCCESS':
      return { ...state, data: action.payload, loading: false }
    case 'ERROR':
      return { ...state, error: action.payload, loading: false }
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export const useFetchResult = (promise, args = {}) => {
  const [state, dispatch] = useReducer(fetchResultReducer, initialState)

  const onInit = () => dispatch({ type: 'INIT' })
  const onSuccess = payload => dispatch({ type: 'SUCCESS', payload })
  const onError = payload => dispatch({ type: 'ERROR', payload })
  const onReset = () => dispatch({ type: 'RESET' })

  const fetchData = async (dispatcher, customArgs = {}) => {
    if (args.cache || customArgs.cache) {
      const usedArgs = { ...args, ...customArgs }
      dispatch({ type: usedArgs.cache.type, payload: usedArgs.cache.data })
      dispatcher &&
        typeof dispatcher === 'function' &&
        dispatcher({ type: 'SUCCESS', data: usedArgs.cache.data })
      return
    }
    onInit()
    const { err, data } = await to(promise, { ...args, ...customArgs })

    if (err) {
      dispatch({ type: 'ERROR', payload: err })
    } else {
      if (data) {
        dispatch({ type: 'SUCCESS', payload: data })
        dispatcher &&
          typeof dispatcher === 'function' &&
          dispatcher({ type: 'SUCCESS', data })
      }
    }

    return data
  }

  return { fetchData, onError, onInit, onSuccess, state, onReset }
}
