import React, { memo } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { FormControlLabel } from '@material-ui/core'
import RadioButton from '@material-ui/core/Radio'

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: -8
  },
  hideLabel: {
    display: 'none'
  },
  label: {
    marginLeft: 4,
    fontSize: 13,
    lineHeight: '20px'
  },

  radioRoot: {
    padding: 8
  },
  radioIcon: ({ size = 16 }) => ({
    position: 'relative',
    borderRadius: '50%',
    width: size,
    height: size,
    border: `1px solid ${theme.palette.action.disabled}`,
    backgroundColor: 'transparent',
    transition: 'border 0.2s ease-in-out'
  }),
  checkedIcon: ({ size = 16 }) => ({
    backgroundColor: theme.palette.colors.white,
    border: `${size / 3.5}px solid ${theme.palette.primary.main}`
  })
}))

const CustomCheckboxLabel = ({
  hideLabel,
  label,
  classes = {},
  control,
  ...rest
}) => {
  const classNames = useStyles()
  return (
    <FormControlLabel
      classes={{
        ...classes,
        root: classNames.root,
        label: cn(classes.label, classNames.label, {
          [classNames.hideLabel]: hideLabel
        })
      }}
      {...rest}
      control={control ?? <StyledRadio classes={classNames} />}
      label={label}
    />
  )
}

const StyledRadio = memo(({ classes, ...props }) => {
  return (
    <RadioButton
      className={cn(classes.radioRoot)}
      color="primary"
      checkedIcon={
        <span className={cn(classes.radioIcon, classes.checkedIcon)} />
      }
      icon={<span className={classes.radioIcon} />}
      {...props}
    />
  )
})

export default CustomCheckboxLabel
