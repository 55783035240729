import React from 'react'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    position: 'relative',
    '& *': {
      ...theme.scrollbar(3)
    }
  }
}))

const BaseWidget = ({ children, overrideClassName }) => {
  const classes = useStyles()

  return (
    <div className={cn(classes.wrapper, overrideClassName ?? '')}>
      {children}
    </div>
  )
}

export default BaseWidget
