export const initialColumns = [
  {
    id: 'name',
    label: 'Name',
    active: true
  },
  {
    id: 'description',
    active: true,
    label: 'Description'
  },
  {
    id: 'status',
    active: true,
    label: 'Status'
  },
  {
    id: 'progress',
    active: true,
    label: 'Progress'
  },
  {
    id: 'author',
    active: true,
    label: 'Author'
  },
  {
    id: 'upload_date',
    label: 'Upload Date',
    active: true
  }
]
