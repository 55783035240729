import React from 'react'
import { makeStyles, CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    top: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export const DrawingLoader = ({ width, height }) => {
  const classes = useStyles()

  return (
    <div
      className={classes.wrapper}
      style={{
        width: width - 2,
        height: height - 29
      }}
    >
      <CircularProgress size={25} />
    </div>
  )
}
