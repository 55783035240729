import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { ROUTES } from '../../utils/consts'

const TermsMiddleware = ({ currentUser, children }) => {
  const isTermsChecked = currentUser?.user.signed_policy

  if (typeof isTermsChecked === 'boolean' && !isTermsChecked) {
    return <Redirect to={ROUTES.TERMS} />
  }

  return children
}

const mapStateToProps = state => ({
  currentUser: state.login.currentUser
})

export default connect(mapStateToProps)(TermsMiddleware)
