import React, { useCallback } from 'react'
import { fromHumanTimeRange, toHumanTimeRange } from '../../../../fns'
import BottomWidget from '../BottomWidget'
import EditableText from '../EditableText'

const TimeRangeControl = props => {
  const { timeRange, samplerate, width, onChange } = props

  const _handleChange = useCallback(
    ms => {
      const range = fromHumanTimeRange(ms, samplerate, timeRange)
      if (range) onChange(range)
    },
    [onChange, samplerate, timeRange]
  )

  return (
    <BottomWidget label="Duration">
      <EditableText
        width={width - 50}
        title="Click to edit time range"
        text={toHumanTimeRange(timeRange, samplerate)}
        label="ms"
        onChange={_handleChange}
      />
    </BottomWidget>
  )
}

export default TimeRangeControl
