import {
  accessibilityOverscanIndicesGetter,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  InfiniteLoader,
  Table,
  SortIndicator
} from 'react-virtualized'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { makeStyles, TableCell, Typography, useTheme } from '@material-ui/core'
import cn from 'classnames'
import { seedUnitColor } from '../../../../../../shared/utils'
import useUnitColor from '../../hooks/useUnitColor'
import { propsAreEqual } from '../../../../../../utils/utils'
import memoize from 'memoize-one'
import getOr from 'lodash/fp/getOr'
import RightArrowIcon from '../../../../../../imgs/RightArrow'
import { alpha } from '@material-ui/core/styles'
import useDebouncedCallback from '../../../../../../../src/hooks/useDebouncedCallback'

const rowHeight = 16

const useStyles = makeStyles(theme => ({
  root: ({ minWidth = 340 }) => ({
    height: '100%',
    width: '100%',
    paddingTop: 15,
    paddingBottom: 5,
    '& .table-row': {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: 10,
      '&:hover': {
        background: theme.palette.colors.customColor(
          theme.palette.colors.neutral[8],
          theme.palette.colors.secongBG
        )
      }
    },
    '& .ReactVirtualized__Grid, & .ReactVirtualized__Grid__innerScrollContainer, & .table-row':
      {
        minWidth
      }
  }),
  title: {
    fontWeight: 600,
    position: 'relative',
    '& svg': {
      right: -15,
      top: 0,
      transform: 'translateY(-25%)',
      display: 'block',
      position: 'absolute'
    }
  },
  rightArrowSvg: {
    left: -15,
    position: 'absolute'
  },
  value: {
    fontSize: 10,
    lineHeight: '12px',
    whiteSpace: 'nowrap',
    color: theme.palette.colors.color
  },
  loader: {
    height: '100%',
    position: 'relative'
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: 'none'
  },
  tableCell: {
    flex: 1,
    padding: '0 10px'
  },
  selectedRow: {
    background: theme.palette.colors.customColor(
      theme.palette.colors.neutral[8],
      theme.palette.colors.secongBG
    )
  },
  selectedAndHighlighted: {
    borderTop: `1px solid ${theme.palette.colors.accent[5]}`,
    borderBottom: `1px solid ${theme.palette.colors.accent[5]}`,
    '& div': {
      '&:last-child': {
        borderRight: `1px solid ${theme.palette.colors.accent[5]}`
      },
      '&:first-child': {
        borderLeft: `1px solid ${theme.palette.colors.accent[5]}`
      }
    }
  },
  actions: {
    pointerEvents: 'none',
    position: 'fixed',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    border: `1px solid rgba(45, 155, 250, 0.2)`,
    transition: 'opacity 0.2s easy',
    visibility: 'hidden',
    '& svg': {
      pointerEvents: 'all'
    }
  },
  sortIndicator: {
    fill: theme.palette.colors.color
  }
}))

const _CACHE = new CellMeasurerCache({
  minWidth: 60,
  fixedWidth: true,
  keyMapper: rowIndex => rowIndex
})

const validLabelDescription = [
  { label: 'good', value: 'SU' },
  { label: 'noise', value: 'NU' },
  { label: 'mua', value: 'MU' }
]

const InfiniteList = ({
  parentId,
  list,
  total,
  sortBy,
  isColumnLocked = false,
  secondSortBy = null,
  loadMore,
  sortDirection,
  columns = [],
  onSort = null,
  selectedRows = [],
  handleRowSelect = () => null,
  recommendations,
  isSuggestionVisible,
  renderActionButton,
  noiseRecommendations
}) => {
  const [minWidth, setInitialWidth] = useState(0)

  const classes = useStyles({ minWidth })
  const isHighlightedItem = useMemo(
    () => list.find(item => item?.highlited),
    [list]
  )
  const selected = new Set([...selectedRows])

  useEffect(() => {
    let w = _CACHE
      ? Object.values(_CACHE?._cellWidthCache).reduce((a, b) => a + b, 0)
      : 0
    setInitialWidth(w)
  }, [])

  const _sort = useCallback(
    ({ sortBy, sortDirection }) => {
      if (onSort) onSort(sortBy, sortDirection)
    },
    [onSort]
  )
  const debounceHandleRowSelect = useDebouncedCallback((e) => handleRowSelect(e), 10)

  const onRowClick = useCallback(e =>{
    debounceHandleRowSelect(e)
     }, [handleRowSelect])

  const targetedUnit = list.findIndex(
    item => Number(item.id) === selectedRows.values().next().value
  )

  const rowGetter = ({ index }) => list[index]
  const isRowLoaded = ({ index }) => !!list[index]

  return (
    <div className={classes.root} id={parentId}>
      <InfiniteLoader
        threshold={5}
        rowCount={total}
        minimumBatchSize={20}
        loadMoreRows={loadMore}
        isRowLoaded={isRowLoaded}
      >
        {({ registerChild, onRowsRendered }) => {
          return (
            <InnerTable
              parentId={parentId}
              registerChild={registerChild}
              onRowsRendered={onRowsRendered}
              _sort={_sort}
              sortBy={sortBy}
              secondSortBy={secondSortBy}
              isColumnLocked={isColumnLocked}
              sortDirection={sortDirection}
              _cache={_CACHE}
              classes={classes}
              listLen={list.length}
              selectedRows={selected}
              columns={columns}
              onRowClick={onRowClick}
              isHighlightedItem={isHighlightedItem}
              targetedUnit={targetedUnit}
              rowGetter={rowGetter}
              recommendations={recommendations}
              isSuggestionVisible={isSuggestionVisible}
              renderActionButton={renderActionButton}
              noiseRecommendations={noiseRecommendations}
            />
          )
        }}
      </InfiniteLoader>
    </div>
  )
}

const InnerTable = ({
  parentId,
  registerChild,
  onRowsRendered,
  _sort,
  sortBy,
  isColumnLocked,
  secondSortBy,
  sortDirection,
  classes,
  selectedRows,
  columns,
  onRowClick,
  isHighlightedItem,
  targetedUnit,
  listLen,
  rowGetter,
  _cache,
  recommendations,
  isSuggestionVisible,
  renderActionButton,
  noiseRecommendations
}) => {
  const theme = useTheme()
  const props = {
    estimatedRowSize: 40,
    overscanIndicesGetter: accessibilityOverscanIndicesGetter
  }

  const utils = useCallback(
    (ID, index) => {
      let rowBackground = null
      const isNoiseUnit = noiseRecommendations?.includes(ID)
      const selected = selectedRows.has(Number(ID))
      const isRecommendation =
        recommendations && [].concat(...recommendations).includes(ID)
      const isVisible = isSuggestionVisible && isSuggestionVisible(ID)
      const isHighlighted = isRecommendation && isVisible
      const isHighlightedNoise = isVisible && isNoiseUnit

      const color = isHighlightedNoise ? theme.palette.colors.neutral[5] : null
      const cellBackground = index === 0 && selected ? seedUnitColor(ID) : null

      if (isHighlighted)
        rowBackground = alpha(theme.palette.colors.accent[5], 0.2)
      if (isHighlightedNoise)
        rowBackground = theme.palette.colors.customColor(
          theme.palette.colors.neutral[8],
          theme.palette.colors.neutral[2]
        )
      return {
        selected,
        color,
        isRecommendation,
        isNoiseUnit,
        rowBackground,
        cellBackground
      }
    },
    [isSuggestionVisible, recommendations, selectedRows, noiseRecommendations]
  )

  const [hoveredRow, setHoveredRowIndex] = useState(null)

  const rowStyle = useCallback(
    e => {
      let index = e.index,
        data = rowGetter({ index })
      if (!data) return {}

      let { rowBackground: background } = utils(Number(data?.id))

      return {
        background
      }
    },
    [rowGetter, utils]
  )

  const onRowMouseOver = useCallback(
    e => {
      const target = e.event.target.closest('.table-row')
      const index = e.index
      const position = target.getBoundingClientRect()
      const Suggestions = recommendations || noiseRecommendations
      if (!Suggestions) return

      let data = rowGetter({ index }),
        ID = Number(data?.id),
        { isRecommendation, isNoiseUnit } = utils(ID)

      const isSuggestion = isRecommendation || isNoiseUnit
      if (isSuggestion) setHoveredRowIndex({ index, id: ID, top: position.top })
      else setHoveredRowIndex(null)
    },
    [recommendations, noiseRecommendations, rowGetter, utils]
  )

  const onMouseLeave = useCallback(
    () =>
      recommendations || noiseRecommendations ? setHoveredRowIndex(null) : null,
    [recommendations, noiseRecommendations]
  )

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <div onMouseLeave={onMouseLeave} onScrollCapture={onMouseLeave}>
            <div
              id={`${parentId}-merge-visibility-Row`}
              className={classes.actions}
              style={{
                width,
                visibility: hoveredRow ? 'visible' : 'hidden',
                top: hoveredRow?.top
              }}
            >
              {renderActionButton(hoveredRow?.id)}
            </div>

            <Table
              {...props}
              width={width}
              height={height}
              rowCount={listLen}
              ref={registerChild}
              rowHeight={rowHeight}
              rowClassName="table-row"
              headerHeight={rowHeight}
              onRowsRendered={onRowsRendered}
              onRowClick={onRowClick}
              rowGetter={rowGetter}
              rowStyle={rowStyle}
              onRowMouseOver={onRowMouseOver}
              {...(isHighlightedItem && targetedUnit > 0
                ? {
                    scrollToIndex: targetedUnit
                  }
                : null)}
              //sort

              sort={_sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            >
              {columns.map((data, index) => {
                const { id, label } = data
                const width = getOr(null, [id], _cache?._cellWidthCache)
                return (
                  <Column
                    key={index}
                    dataKey={id}
                    width={width}
                    label={label}
                    cellRenderer={props => {
                      const ID = Number(props.rowData?.id)
                      const Component = props.rowData?.Component
                      const { cellBackground, color } = utils(ID, index)

                      const isLabelColumn = label === 'Label'

                      const cellData = isLabelColumn
                        ? validLabelDescription.find(
                            value => value.label === props.cellData
                          )?.value ?? 'UNC'
                        : props.cellData

                      return (
                        <CellRenderer
                          index={index}
                          Component={Component}
                          cellData={cellData}
                          label={props.rowData.label}
                          background={cellBackground}
                          color={color}
                        />
                      )
                    }}
                    headerRenderer={options => (
                      <HeaderRenderer
                        index={index}
                        classes={classes}
                        isColumnLocked={isColumnLocked}
                        secondSortBy={secondSortBy}
                        sortDirection={sortDirection}
                        {...options}
                      />
                    )}
                    className={classes.flexContainer}
                    headerClassName={classes.flexContainer}
                  />
                )
              })}
            </Table>
          </div>
        )
      }}
    </AutoSizer>
  )
}

const cellStyle = memoize((background = null, border = null) => ({
  height: rowHeight,
  background,
  border
}))

const textStyle = memoize((color = null) => ({
  color
}))

const isInt = n => parseInt(n) === Number(n)

const CellRenderer = memo(
  ({ cellData, label, background, color, Component, index }) => {
    const classes = useStyles()
    const { getUnitColor } = useUnitColor()

    const nr = isInt(cellData) ? cellData : Number(cellData).toFixed(2)
    const title = isNaN(cellData) ? cellData : nr
    const textColor = getUnitColor(label) || color
    if (Component && index === 0) return <Component />

    return (
      <TableCell
        component="div"
        className={cn(classes.tableCell, classes.flexContainer, {
          [classes.selectedRow]: Boolean(background)
        })}
        variant="body"
        style={cellStyle(background)}
      >
        <Typography
          className={classes.value}
          style={textStyle(textColor)}
          title={title}
        >
          {title}
        </Typography>
      </TableCell>
    )
  }
)

const HeaderRenderer = memo(
  props => {
    const classes = useStyles()
    const {
      label,
      dataKey,
      sortBy,
      secondSortBy,
      isColumnLocked,
      sortDirection
    } = props
    const isExistSecondSorting = secondSortBy && secondSortBy === dataKey

    const isDisplayLockedIndicator = sortBy === dataKey && isColumnLocked

    return (
      <CellMeasurer
        cache={_CACHE}
        columnIndex={0}
        key={dataKey}
        rowIndex={dataKey}
        width={0}
      >
        <TableCell
          component="div"
          className={cn(classes.tableCell, classes.flexContainer)}
          variant="head"
          style={{ height: rowHeight }}
        >
          <Typography className={cn(classes.value, classes.title)}>
            {isDisplayLockedIndicator && <LockIndicator />}
            {label}
            {(sortBy === dataKey || isExistSecondSorting) && (
              <div className={classes.sortIndicator}>
                <SortIndicator sortDirection={sortDirection} />
              </div>
            )}
          </Typography>
        </TableCell>
      </CellMeasurer>
    )
  },
  (...props) => propsAreEqual(...props, ['sortBy'])
)

const LockIndicator = () => {
  const classes = useStyles()
  return (
    <div className={classes.rightArrowSvg}>
      <RightArrowIcon />
    </div>
  )
}

export default memo(InfiniteList, (...props) =>
  propsAreEqual(...props, [
    'selectedRows',
    'list',
    'total',
    'handleRowSelect',
    'recommendations',
    'noiseRecommendations',
    'renderActionButton'
  ])
)
