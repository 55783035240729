import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storeMembers } from '../../../actions/members'
import { getOrganisationMembers } from '../../../endpoints/admin'
import useFilesQueryParams from '../../FilesTable/hooks/useFilesQueryParams'

function useGetAllMembers() {
  const [loading, setLoading] = useState(true)
  const { page, per_page, keyword } = useFilesQueryParams()
  const { user } = useSelector(state => state.login.currentUser) || {}
  const { company } = user || {}

  const dispatch = useDispatch()
  const store = useCallback(data => dispatch(storeMembers(data)), [dispatch])

  const fetchMembers = useCallback(async () => {
    try {
      setLoading(true)
      const data = await getOrganisationMembers({
        search: keyword,
        page,
        organisation_id: company?.id,
        limit: 10
      })
      store(data)
      setLoading(false)
    } catch (err) {
      store(null)
      setLoading(false)
    }
  }, [company?.id, keyword, page, per_page, store])

  useEffect(() => {
    fetchMembers()

    // eslint-disable-next-line
  }, [page, per_page, keyword])

  return { loading, fetchMembers }
}

export default useGetAllMembers
