export const toggleUnits = (arr, payload) => {
  const { multiple, unit_id } = payload
  let updated

  if (Array.isArray(unit_id)) updated = unit_id
  else if (multiple) {
    if (!arr.includes(unit_id)) updated = [...arr, unit_id]
    else updated = arr.filter(item => item !== unit_id)
  } else updated = [unit_id]

  return updated
}

export const cacheDataByKey = (key1, key2, state, data) => {
  if (!key1 || (!key2 && key2 !== 0)) return state
  let cloneState = { ...state }

  if (cloneState[key1] && cloneState[key1][key2]) {
    const re = cloneState[key1][key2]
    cloneState = {
      ...cloneState,
      [key1]: {
        ...cloneState[key1],
        [key2]: { ...re, ...data }
      }
    }
  } else if (cloneState[key1]) {
    const re = cloneState[key1]

    cloneState = {
      ...cloneState,
      [key1]: {
        ...re,
        [key2]: data
      }
    }
  } else {
    cloneState = {
      [key1]: {
        [key2]: data
      }
    }
  }

  return cloneState
}

export const filterLabelWithSecondKey = (cluster, paramKey, sortDirection) => {
  const sortWithLabel = filterLabel

  const groupCluster = cluster.reduce((groups, currentVal) => {
    const key = Number(currentVal[paramKey]).toFixed(2)
    const isKeyExists = groups[key]

    groups[key] = isKeyExists
      ? sortWithLabel([...groups[key], currentVal], sortDirection)
      : [currentVal]

    return groups
  }, {})

  return Object.keys(groupCluster).reduce(
    (arr, key) => [...arr, ...groupCluster[key]],
    []
  )
}

function compare(a, b, param) {
  if (Number(a[param]) < Number(b[param])) {
    return -1
  } else if (Number(a[param]) > Number(b[param])) {
    return 1
  }
  return 0
}
export const filterLabel = (cluster, direction, secondSortBy) => {
  //filter duplicates by id
  cluster = Array.from(new Set(cluster.map(a => a.id))).map(id => {
    return cluster.find(a => a.id === id)
  })

  if (!secondSortBy) {
    secondSortBy = 'id'
  }

  let singleUnits = cluster
    .filter(unit => unit['label'] === 'good')
    .sort((a, b) => compare(a, b, secondSortBy))
  let multiUnits = cluster
    .filter(unit => unit['label'] === 'mua')
    .sort((a, b) => compare(a, b, secondSortBy))
  let noiseUnits = cluster
    .filter(unit => unit['label'] === 'noise')
    .sort((a, b) => compare(a, b, secondSortBy))
  let uncuratedUnits = cluster
    .filter(unit => !['good', 'noise', 'mua'].includes(unit['label']))
    .sort((a, b) => compare(a, b, secondSortBy))

  if (direction === 'DESC') {
    singleUnits = singleUnits.reverse()
    uncuratedUnits = uncuratedUnits.reverse()
    multiUnits = multiUnits.reverse()
    noiseUnits = noiseUnits.reverse()
    return [...noiseUnits, ...uncuratedUnits, ...multiUnits, ...singleUnits]
  }
  return [...singleUnits, ...multiUnits, ...uncuratedUnits, ...noiseUnits]
}

export const filterClusterByKey = (clusterCopy, key, secondKey) => {
  if (secondKey) {
    return filterLabelWithSecondKey(clusterCopy, secondKey)
  }

  return filterLabel(clusterCopy, key)
}

export const sortByLabel = (state, payload) => {
  const { sortBy, curationId, sortingId } = payload
  const {
    sortBy: storedSortBy,
    sortDirection: storedSortDirection,
    localSorting,
    isTwoColumnSort,
    secondSortBy,
    prevSortBy
  } = state

  if (localSorting) {
    return {
      ...state,
      sortBy: prevSortBy ?? storedSortBy,
      sortDirection: storedSortDirection,
      localSorting: false
    }
  }

  const keyClusterData = [sortingId, curationId].join('-')

  const newKeyClusterData = [
    sortingId,
    curationId,
    sortBy,
    storedSortDirection
  ].join('-')

  // eslint-disable-next-line no-unused-vars
  const [_, originalDataValue] =
    Object.entries(state).find(([k, _]) => k.includes(keyClusterData)) || []
  const data = originalDataValue?.data || {}
  let clusters

  if (isTwoColumnSort) {
    //Apply second sorting to label
    clusters = filterClusterByKey(data?.clusters, 'label', storedSortBy)
    return {
      ...state,
      [newKeyClusterData]: {
        ...originalDataValue,
        data: { ...data, clusters }
      },
      secondSortBy: secondSortBy,
      localSorting: true
    }
  } else {
    clusters = filterClusterByKey(data?.clusters, 'label')
  }
  return {
    ...state,
    [newKeyClusterData]: {
      ...originalDataValue,
      data: { ...data, clusters }
    },
    sortBy,
    prevSortBy: storedSortBy,
    secondSortBy: secondSortBy,
    localSorting: true
  }
}

export const sortByParams = (state, payload) => {
  let { sortBy, sortDirection, curationId, sortingId } = payload
  const {
    sortBy: storedSortBy,
    sortDirection: storedSortDirection,
    secondSortBy,
    sortByClickCounter,
    isTwoColumnSort
  } = state
  const isSameSortMethod = sortBy === storedSortBy
  const isDefaultSortMethod = storedSortBy === 'n_spikes'
  const isRemoveSecondSortBy = secondSortBy && sortBy === secondSortBy
  const defaultKeys = [sortingId, curationId, storedSortBy, storedSortDirection]

  const keys = secondSortBy ? [...defaultKeys, secondSortBy] : defaultKeys
  const storedKeyClusterData = keys.join('-')
  let cloneState = { ...state }

  if (isSameSortMethod || (isDefaultSortMethod && !isTwoColumnSort)) {
    const lockColumn = sortByClickCounter !== 2
    const newLockCounter = lockColumn ? sortByClickCounter + 1 : 0
    cloneState = {
      ...cloneState,
      sortByClickCounter: newLockCounter,
      secondSortBy: lockColumn ? secondSortBy : null,
      isTwoColumnSort: lockColumn,
      sortBy,
      sortDirection
    }
  } else if (isRemoveSecondSortBy) {
    cloneState = {
      ...cloneState,
      secondSortBy: null,
      [storedKeyClusterData]: null,
      sortBy: storedSortBy,
      sortDirection: storedSortDirection
    }
  } else {
    const newValues = isTwoColumnSort
      ? { secondSortBy: sortBy }
      : { sortBy, isTwoColumnSort: true, sortByClickCounter: 1 }

    cloneState = {
      ...cloneState,
      [storedKeyClusterData]: null,
      ...newValues
    }
  }

  return cloneState
}
