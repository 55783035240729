import React, { useCallback, useState } from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import TagsInput from '../TagsInput'
import CustomModal from '../../../CustomModal'
import ActionButton from '../../../ActionButton'
import PersonIcon from '@material-ui/icons/Person'
import { validateEmail } from '../../../../utils/utils'

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  userIcon: {
    marginRight: 5,
    color: theme.palette.colors.neutral[3]
  },
  description: {
    marginBottom: 40
  }
}))

export default function InviteUsersModal({
  isOpened,
  toggleModal,
  handleSubmit,
  isSubmitting
}) {
  const classes = useStyles()
  const [emails, setEmails] = useState([])

  const handleInviteUsers = useCallback(async () => {
    await handleSubmit(emails)
    setEmails([])
  }, [handleSubmit, emails, setEmails])

  return (
    <CustomModal
      open={isOpened}
      title={
        <Box className={classes.title}>
          <PersonIcon className={classes.userIcon} />
          Invite user
        </Box>
      }
      onClose={toggleModal}
      maxWidth={650}
      renderActionButton={
        <ActionButton
          label="Invite"
          disabled={emails.length === 0}
          loading={isSubmitting}
          onClick={handleInviteUsers}
          className={classes.saveBtn}
        />
      }
    >
      <Typography className={classes.description}>
        Your teammates will get an email that gives them access to your team.
      </Typography>
      <TagsInput
        tags={emails}
        setTags={setEmails}
        inputPlaceholder="name@company.com"
        validateInput={validateEmail}
      />
    </CustomModal>
  )
}
