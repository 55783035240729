import Storage from '../../AppHeader/components/Storage'
import { useCallback, useMemo } from 'react'
import { ROUTES } from '../../../utils/consts'

function useFoldersBreadcrumb() {
  const storageName = 'folders-breadcrumb'
  const breadcrumb = Storage.getItem(storageName)
  const setBreadcrumb = useCallback(
    val => Storage.setItem(storageName, val),
    []
  )

  const handleBreadcrumb = useCallback(
    data => {
      try {
        const { parent_id = null, id = null, name = null } =
          data?.folder_data || {}

        const home = { parent_id: null, id: null, name: null }

        let arr
        const findParent =
          breadcrumb && breadcrumb.findIndex(i => i?.id === parent_id)

        if (!id) {
          arr = [{ parent_id, id, name }]
        } else if (findParent > -1) {
          const prev = breadcrumb ? breadcrumb.slice(0, findParent + 1) : [home]
          arr = [...prev, { parent_id, id, name }]
        } else {
          arr = [home, { parent_id, id, name }]
        }

        if (arr) setBreadcrumb(arr)
      } catch (e) {
        console.error('breadcrumb error', e)
        Storage.removeItem(storageName)
      }
    },
    [breadcrumb, setBreadcrumb]
  )

  const foldersBreadcrumb = useMemo(
    () =>
      breadcrumb &&
      breadcrumb.map(({ id, name }, i, arr) => ({
        label: name || 'Home',
        to: id ? `${ROUTES.FILES}?folder_id=${id}` : ROUTES.FILES,
        current: i === arr.length - 1
      })),
    [breadcrumb]
  )

  return {
    handleBreadcrumb,
    foldersBreadcrumb
  }
}

export default useFoldersBreadcrumb
