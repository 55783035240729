import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles(theme => ({
  root: ({ size }) => ({
    padding: 0,
    width: size,
    height: size / 3,
    overflow: 'visible'
  }),

  thumb: ({ size, color }) => {
    const border =
      color === 'secundary'
        ? theme.palette.colors.customColor(
            theme.palette.action.disabled,
            theme.palette.primary.disabled
          )
        : theme.palette.action.disabled
    return {
      width: size / 2,
      height: size / 2,
      boxShadow: 'none',
      border: `1px solid ${border}`,
      backgroundColor: theme.palette.colors.white,
      transition: 'background-color 0.2s ease-in-out'
    }
  },

  track: ({ color }) => {
    const backgroundColor =
      color === 'secundary'
        ? theme.palette.colors.customColor(
            theme.palette.action.disabled,
            theme.palette.primary.disabled
          )
        : theme.palette.action.disabled
    return {
      opacity: 1,
      backgroundColor,
      transition: 'background-color 0.2s ease-in-out'
    }
  },

  switchBase: ({ size, color }) => ({
    padding: 0,
    bottom: 0,
    '&.Mui-checked': {
      // + 1px because of border size
      transform: `translateX(${size / 2 + 1}px)`,
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          color === 'secundary'
            ? theme.palette.colors.neutral['7']
            : theme.palette.colors.white
      },
      '& $thumb': {
        backgroundColor:
          color === 'secundary'
            ? theme.palette.primary.main
            : theme.palette.colors.main
      }
    }
  })
}))

const CustomSwitch = props => {
  const { size = 28, color, ...rest } = props
  const classes = useStyles({ size, color })
  return (
    <Switch
      {...rest}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        checked: classes.checked,
        track: classes.track
      }}
    />
  )
}

export default CustomSwitch
