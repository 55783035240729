import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { WIDGET_WRAPPER_HEADER } from '../../../../../utils/styles'

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: WIDGET_WRAPPER_HEADER,
    padding: 6,
    boxSizing: 'border-box',
    cursor: 'move',
    width: '100%',
    background: theme.palette.colors.customColor(
      theme.palette.colors.neutral.bg,
      theme.palette.colors.secongBG
    ),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    color: theme.palette.primary.text,
    fontWeight: 600,
    fontSize: 11
  }
}))

const WidgetHeader = ({ widget, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>{widget}</Typography>
      {children}
    </div>
  )
}

export default WidgetHeader
