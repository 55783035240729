import React, { memo, useCallback } from 'react'
import { makeStyles, Typography, useTheme } from '@material-ui/core'
import CustomModal from '../../../CustomModal'
import ActionButton from '../../../ActionButton'
import useDelete from '../../hooks/useDelete'

const useStyles = makeStyles(theme => ({
  header: {
    height: 68
  },
  title: {
    fontSize: 20,
    lineHeight: '36px',
    fontWeight: 600,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[90],
      'white'
    )
  },
  bodyText: {
    fontSize: 16,
    lineHeight: '24px',
    marginLeft: 4,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      'white'
    )
  },
  modelName: {
    fontWeight: 600,
    display: 'inline'
  },
  actionButton: {
    borderRadius: 8,
    fontWeight: 600,
    fontSize: 14,
    color: 'white',
    padding: '9px 12px',
    margin: 0
  }
}))

const DeleteModal = ({ deselectAll }) => {
  const classes = useStyles()
  const theme = useTheme()
  const {
    name,
    loading,
    onDelete,
    isBulk,
    isDeleteModalOpen: isOpened,
    toggleDeleteModal: toggleModal
  } = useDelete()

  const onSuccess = useCallback(() => deselectAll(), [deselectAll])

  const handleDelete = useCallback(async () => {
    await onDelete({ onSuccess })
    toggleModal()
  }, [onDelete, onSuccess, toggleModal])

  const DeleteButton = memo(() => {
    return (
      <ActionButton
        label={'Delete'}
        loading={loading}
        className={classes.actionButton}
        customStyles={{ backgroundColor: theme.palette.colors.accent[8] }}
        onClick={handleDelete}
      />
    )
  })

  return (
    <CustomModal
      title={'Delete AI model'}
      open={isOpened}
      onClose={toggleModal}
      classes={classes}
      maxWidth={603}
      customPaperClass={classes.root}
      renderActionButton={<DeleteButton />}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Typography className={classes.bodyText}>
        Are you sure you would like to delete
        {isBulk ? (
          ' these items?'
        ) : (
          <>
            <Typography className={classes.modelName}> {name} </Typography>
            model ? You won't be able to recover it anymore.
          </>
        )}
      </Typography>
    </CustomModal>
  )
}

export default memo(DeleteModal)
