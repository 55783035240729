import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CanvasWidget, { CanvasWidgetLayer } from '../jscommon/CanvasWidget'
import { UNIT_HEIGHT } from '../TimeseriesView/hooks'
import FilterButton from './components/FilterButton/FilterButton'

const useStyles = makeStyles(theme => ({
  rasterRoot: { position: 'relative', width: '100%', height: '100%' }
}))

const RasterWidget = ({
  width,
  height,
  timeRange,
  rasterData,
  drag,
  onMousePress,
  onMouseDragRelease
}) => {
  const classes = useStyles()
  const rasterLayer = new CanvasWidgetLayer(paintLayer)

  const {
    spikes,
    update,
    layerHeight,
    unitsColor,
    onFilterItemClick,
    filters
  } = rasterData

  useEffect(() => {
    update(timeRange)
    rasterLayer.repaint()
  }, [timeRange, height])

  useEffect(() => {
    rasterLayer.repaint()
  }, [height])

  function paintLayer(painter, layer) {
    painter.useCoords()
    layer.setCoordXRange(0, 1)
    layer.setCoordYRange(0, 1)
    layer.setMargins(50, 10, 15, 50)
    paintPanels(painter, layer)
  }

  function paintPanels(painter, layer) {
    painter.useCoords()

    if (timeRange) rasterLayer.setCoordXRange(timeRange[0], timeRange[1])

    for (let i = 0; i < spikes.length; i++) {
      spikes[i].forEach(x => {
        painter.fillRect2(
          x,
          Math.round(height / spikes.length) * i,
          20,
          UNIT_HEIGHT,
          unitsColor[i] ?? 'black'
        )
      })
    }
  }

  return (
    <div className={classes.rasterRoot}>
      <FilterButton onFilterItemClick={onFilterItemClick} filters={filters} />
      <CanvasWidget
        id="raster-plot"
        key="canvas"
        layers={[rasterLayer]}
        width={width}
        height={height}
        onMousePress={onMousePress}
        onMouseRelease={onMouseDragRelease}
        onMouseDrag={drag}
      />
    </div>
  )
}

export default RasterWidget
