import React, { memo } from 'react'
import { List } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '../MenuItem/MenuItem'
import MapData from '../../../../utils/MapData'
import cn from 'classnames'

const useStyles = makeStyles(() => ({
  menuList: {
    padding: 0,
    marginTop: 60,
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  }
}))

const MenuList = ({ menuItem, className, callback = () => null }) => {
  const classes = useStyles()
  return (
    <List className={cn(classes.menuList, className)}>
      <MapData data={menuItem}>
        {({
          item: { to, label, icon, onClick, className, disabled, exact },
          index
        }) => {
          return (
            <MenuItem
              key={index}
              to={to}
              label={label}
              callback={callback}
              icon={icon}
              exact={exact}
              onClick={onClick}
              className={className}
              disabled={disabled}
            />
          )
        }}
      </MapData>
    </List>
  )
}

export default memo(MenuList)
