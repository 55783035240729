import { useCallback, useState } from 'react'
import { MODALS_ID } from '../../../utils/consts'
import useToggleModal from '../../../hooks/useToggleModal'
import { inviteUsersToOrganisation } from '../../../endpoints/admin'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { NOTIFICATION } from '../../../utils/consts'

function useInviteUsers({ fetchMembers }) {
  const { toggle: toggleModal, isOpened } = useToggleModal(
    MODALS_ID.EDIT_DATA_MODAL
  )
  const addNotification = useNotification()
  const [loading, setLoading] = useState(false)

  const handleSave = useCallback(
    async emails => {
      setLoading(true)
      try {
        const { logs } = await inviteUsersToOrganisation({ emails })
        toggleModal()

        const differentCases = [
          {
            status: 200,
            message: 'Users invited successfully:',
            notificationType: NOTIFICATION.SUCCESS,
            cb: () => fetchMembers()
          },
          {
            status: 403,
            message: 'Users already assigned:',
            notificationType: NOTIFICATION.INFO,
            cb: null
          },
          {
            status: 400,
            message: 'These users do not exist:',
            notificationType: NOTIFICATION.ERROR,
            cb: null
          }
        ]

        differentCases.forEach(
          ({ status, message, notificationType, cb }, index) => {
            setTimeout(() => {
              const condition = logs.some(log => log.status === status)
              if (condition) {
                const emails = logs
                  .filter(log => log.status === status)
                  .map(({ email }) => email)

                addNotification({
                  type: notificationType,
                  title: message,
                  message: emails.join(', ')
                })
                if (cb) cb()
              }
            }, index * 3000)
          }
        )
      } catch (err) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: err
        })
      }
      setLoading(false)
    },
    [
      toggleModal,
      inviteUsersToOrganisation,
      fetchMembers,
      addNotification,
      setLoading
    ]
  )

  return { toggleModal, handleSave, isOpened, loading }
}

export default useInviteUsers
