import React, { memo } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MAIN_APPBAR_HEIGHT } from '../../utils/styles'
import { withRouter } from 'react-router-dom'
import AppHeader from '../AppHeader/AppHeader'
import { connect } from 'react-redux'
import { setDocumentInfo } from '../../actions'
import CustomModal from '../CustomModal'
import useToggleModal from '../../hooks/useToggleModal'
import { MODALS_ID } from '../../utils/consts'
import ActionButton from '../ActionButton'
import useLimitedAccess from '../../hooks/useLimitedAccess'
import { propsAreEqual } from '../../utils/utils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  container: ({ fullScreenMode, isTSChildWindow }) => ({
    height: `calc(100vh - ${
      isTSChildWindow || fullScreenMode ? 0 : MAIN_APPBAR_HEIGHT
    }px)`,
    background: theme.palette.colors.backgrounds.main
  }),
  loader: {
    minHeight: '100vh'
  },
  premiumHeadingText: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.colors.neutral.black
  },
  premiumBodyText: {
    marginTop: 24,
    fontSize: 20,
    fontWeight: 400,
    color: theme.palette.colors.neutral.black,
    textAlign: 'center'
  },
  contactEmail: {
    fontWeight: 600
  },
  contactBtn: {
    width: 'fit-content',
    backgroundColor: theme.palette.primary.main
  }
}))

const AdminLayout = ({ children, fullScreenMode, isTSChildWindow }) => {
  const classes = useStyles({ fullScreenMode, isTSChildWindow })

  const { toggle: toggleModal, isOpened } = useToggleModal(
    MODALS_ID.GET_PREMIUM_VERSION_MODAL
  )
  const { isLimited } = useLimitedAccess()
  const PremiumModal = React.memo(() => {
    return (
      <CustomModal
        open={isOpened}
        title="Get premium version"
        onClose={toggleModal}
        hideCloseButton
        maxWidth={700}
        renderActionButton={
          <ActionButton
            label="Contact us"
            href="mailto:sales@diagnosticbiochips.com"
            onClick={toggleModal}
            className={classes.contactBtn}
          />
        }
      >
        <Typography className={classes.premiumHeadingText}>
          This is a premium feature
        </Typography>
        <Typography className={classes.premiumBodyText}>
          For full access, please contact Diagnostic Biochips at{' '}
          <span className={classes.contactEmail}>
            sales@diagnosticbiochips.com
          </span>
        </Typography>
      </CustomModal>
    )
  })

  return (
    <div className={`TheAppBar ${classes.root}`}>
      {!isTSChildWindow && <AppHeader />}
      <div className={classes.container}>{children}</div>
      {isLimited && <PremiumModal />}
    </div>
  )
}

const AdminLayoutComponent = memo(AdminLayout, (...props) =>
  propsAreEqual(...props, ['fullScreenMode', 'isTSChildWindow'])
)

const mapStateToProps = state => {
  return {
    documentInfo: state.documentInfo,
    extensionsConfig: state.extensionsConfig,
    currentUser: state.login.currentUser,
    initialLoadComplete: state.serverConnection.initialLoadComplete,
    fullScreenMode: state.timeSeriesLeftPanel.fullScreenMode,
    isTSChildWindow: state.timeSeriesLeftPanel.isChildWindow
  }
}

const mapDispatchToProps = dispatch => ({
  onSetDocumentInfo: documentInfo => dispatch(setDocumentInfo(documentInfo))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdminLayoutComponent)
)
