export const ADD_MEMBER = 'ADD_MEMBER'
export const REMOVE_MEMBER = 'REMOVE_MEMBER'
export const STORE_MEMBERS = 'STORE_MEMBERS'

export const storeMembers = data => ({
  type: STORE_MEMBERS,
  payload: data
})

export const addMember = data => ({
  type: ADD_MEMBER,
  payload: data
})

export const removeMember = id => ({
  type: REMOVE_MEMBER,
  payload: id
})
