import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { removeNotification } from '../../actions/notifications'
import { Snackbar, ClickAwayListener } from '@material-ui/core'
import ContentSnackbar from './components/ContentSnackbar'

const SnackbarManager = ({ state, onClose }) => {
  const { isOpened, persist } = state

  const handleClose = useCallback(
    (event, reason) => {
      if (reason === 'clickaway') {
        return
      }

      onClose()
    },
    [onClose]
  )

  if (!state.isOpened) return <div />

  const content = (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: state.type === 'plain' ? 'center' : 'left'
      }}
      open={isOpened}
      autoHideDuration={null}
      onClose={handleClose}
      bodyStyle={{ height: 'auto' }}
    >
      <ContentSnackbar {...state} onClose={onClose} />
    </Snackbar>
  )

  if (persist) return content

  return (
    <ClickAwayListener onClickAway={handleClose}>{content}</ClickAwayListener>
  )
}

const mapStateToProps = state => ({
  state: state.notifications
})

const mapDispatchToProps = dispatch => ({
  onClose: () => dispatch(removeNotification())
})

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarManager)
