import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import useEditOrganisation from './hooks/useEditOrganisation'
import { Formik } from 'formik'
import CustomInput from '../FormComponents/CustomInput/CustomInput'
import WatcherChanges from '../FormComponents/WatcherChanges'

const useStyles = makeStyles(() => ({
  form: {
    width: '100%'
  },
  formContainer: {
    '& > div.MuiGrid-item': {
      paddingBottom: 30
    }
  }
}))

const EditOrganisation = () => {
  const classes = useStyles()
  const { onSubmit, initialValues, validation, checkForChanges } =
    useEditOrganisation()

  const Input = useCallback(
    ({ errors, handleBlur, handleChange, ...props }) => (
      <CustomInput
        fullWidth
        error={errors}
        onBlur={handleBlur}
        onChange={handleChange}
        {...props}
      />
    ),
    []
  )

  return (
    <Grid container className={classes.root}>
      <Formik
        validateOnChange
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values, actions) => onSubmit(values, actions)}
      >
        {({
          initialValues,
          isSubmitting,
          values,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          resetForm
        }) => {
          const inputProps = {
            errors,
            touched,
            handleBlur,
            handleChange
          }
          const hasChanges = checkForChanges(values, initialValues)
          const { name, email, address } = values
          return (
            <form className={classes.form}>
              <Grid container spacing={2} className={classes.formContainer}>
                {/*row*/}
                <Grid item md={6}>
                  <Input
                    label="Name"
                    name="name"
                    value={name}
                    required
                    {...inputProps}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Email"
                    name="email"
                    value={email}
                    required
                    {...inputProps}
                  />
                </Grid>

                {/*row*/}
                <Grid item md={12}>
                  <Input
                    label="Address"
                    name="address"
                    value={address}
                    {...inputProps}
                  />
                </Grid>
              </Grid>
              {hasChanges && (
                <WatcherChanges
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                  resetForm={resetForm}
                />
              )}
            </form>
          )
        }}
      </Formik>
    </Grid>
  )
}

export default EditOrganisation
