import React, { memo } from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import CustomModal from '../../../CustomModal'
import CurationAIIcon from '../../../Icons/curationAIIcon'
import useAIOnboardingModal from './hooks/useAIOnboardingModal'
import Steps from './components/Steps'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 681,
    maxHeight: 'calc(100% - 64px)',
    borderRadius: 8,
    backgroundColor: theme.palette.colors.customColor(
      'white',
      theme.palette.colors.black2
    )
  },
  header: {
    display: 'none'
  },
  container: {
    padding: 20
  },
  AIHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 20px 15.7px 20px'
  },
  AIHeaderText: {
    fontWeight: 600,
    fontSize: 30,
    lineHeight: '45px',
    marginTop: 16,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      'white'
    )
  },
  curationAIIcon: {
    width: 56,
    height: 54
  },
  AIBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  description: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    margin: '24px 0px',
    textAlign: 'center',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[2],
      theme.palette.colors.neutral[8]
    )
  },
  indicatorContainer: {
    width: 54,
    display: 'flex',
    justifyContent: 'space-between'
  },
  indicator: {
    height: 10,
    width: 10,
    borderRadius: 20,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.colors.customColor(
        theme.palette.colors.neutral[3],
        theme.palette.colors.neutral[7]
      )
    },
    backgroundColor: theme.palette.colors.customColor(
      theme.palette.colors.neutral[7],
      theme.palette.colors.neutral[3]
    )
  },
  activeIndicator: {
    backgroundColor: theme.palette.colors.lighterGreen
  },
  ActionContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  actionButton: {
    fontWeight: 600,
    fontSize: 14,
    color: 'white',
    textTransform: 'none',
    backgroundColor: theme.palette.colors.mainColor,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.colors.mainColor
    }
  },
  finalAction: {
    backgroundColor: theme.palette.colors.lighterGreen,
    '&:hover': {
      backgroundColor: theme.palette.colors.lighterGreen
    }
  }
}))

const AIOnboardingModal = () => {
  const steps = Steps()
  const numSteps = steps.length
  const classes = useStyles()
  const { isOpened, endTour, currentStep, incrementStep, setCurrentStep } =
    useAIOnboardingModal()

  const item = steps[currentStep]

  const ActionButton = memo(() => {
    const isLastStep = currentStep === numSteps - 1
    return (
      <div className={classes.ActionContainer}>
        <Button
          className={cn(classes.actionButton, {
            [classes.finalAction]: isLastStep
          })}
          onClick={isLastStep ? endTour : incrementStep}
        >
          {isLastStep ? 'Try it now' : 'Next'}
        </Button>
      </div>
    )
  })

  return (
    <CustomModal
      open={isOpened}
      onClose={endTour}
      classes={classes}
      customPaperClass={classes.root}
      renderActionButton={<ActionButton />}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className={classes.container}>
        <div className={classes.AIHeader}>
          <CurationAIIcon className={classes.curationAIIcon} />
          <Typography className={classes.AIHeaderText}>{item.title}</Typography>
        </div>
        <div className={classes.AIBody}>
          {item.illustration}
          <Typography className={classes.description}>
            {item.description}
          </Typography>
          <div className={classes.indicatorContainer}>
            {[...Array(numSteps)].map((_, i) => {
              const isActive = currentStep === i
              return (
                <div
                  onClick={() => setCurrentStep(i)}
                  className={cn(classes.indicator, {
                    [classes.activeIndicator]: isActive
                  })}
                />
              )
            })}
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default AIOnboardingModal
