import Grid from '@material-ui/core/Grid'
import InfoPanelItem from '../../../InfoPanelItem'
import CardItem from '../../../../../CardItem'
import DataField from '../../DataField/DataField'
import CardContainer from '../../../../../CardContainer'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  margin: {
    marginTop: 40
  },
  columnLeft: {
    borderRight: `1px solid ${theme.palette.colors.neutral['7']}`,
    paddingRight: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > div': {
      marginBottom: 0
    }
  },
  columnRight: {
    paddingLeft: 15
  },
  dataFields: {
    marginTop: 'auto'
  }
}))

const SortingInfo = props => {
  const classes = useStyles()
  const { recording, selectedSorting } = props || {}
  const {
    id: sortingID,
    algo: sortingMethod,
    isolated_single_units,
    spike_detection_threshold
  } = selectedSorting || {}
  const { isolated_units } = recording || {}

  const cards = [
    {
      title: 'Min Spike Count',
      content: '-'
    },
    {
      title: 'Min Spike Amplitude',
      content: '-'
    },
    {
      title: 'Max L-ratio',
      content: '-'
    },
    {
      title: 'Max ISI Index',
      content: '-'
    }
  ]

  return (
    <Grid container justify="space-between" alignItems="stretch">
      <Grid item xs={5} className={classes.columnLeft}>
        <InfoPanelItem title="Data Statistics">
          <CardItem
            title="Isolated single units"
            content={isolated_single_units || isolated_units}
            colorAccent={2}
            rootStyle={classes.margin}
          />
          <div className={classes.dataFields}>
            <DataField
              title="Sorting ID"
              content={sortingID}
              className={classes.margin}
            />
            <DataField
              title="Sorting Method"
              content={sortingMethod}
              className={classes.margin}
            />
          </div>
        </InfoPanelItem>
      </Grid>
      <Grid item xs={7} className={classes.columnRight}>
        <InfoPanelItem title="Sorting Methodology">
          <CardContainer
            cards={cards}
            colorAccent={5}
            className={classes.margin}
          />
          <DataField
            title="Spike Detection Threshold"
            content={spike_detection_threshold}
            className={classes.margin}
          />
        </InfoPanelItem>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  recording: state.recordingStore.recording,
  selectedSorting: state.recordingStore.selectedSorting
})

export default connect(mapStateToProps)(SortingInfo)
