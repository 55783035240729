import {
  SAVE_CORRELOGRAM,
  SAVE_CLUSTERVIEW,
  SAVE_CLUSTER_UNITS,
  SAVE_SIMILARITY_UNITS,
  SAVE_SIMILARITYVIEW,
  SAVE_WAVEFORM,
  SWITCH_WAVEFORM_VIEW,
  CLEAR_WAVEFORMS_CACHE,
  CLUSTERVIEW_LOADING,
  SAVE_FEATURES,
  SAVE_FEATURES_ERROR,
  SAVE_WAVEFORMS_ERROR,
  SELECTED_SPIKES,
  SORT_CLUSTERVIEW,
  SORT_SIMILARITYVIEW,
  START_LOAD_WAVEFORMS,
  TOGGLE_WAVEFORM_GEOMETRY,
  UPDATE_SIMILARITYVIEW,
  TOGGLE_WAVEFORM_OVERLAPPING,
  TOGGLE_CURATION_AI,
  ADD_RECOMMENDATIONS,
  STORE_SUGGESTION_VISIBILITY
} from './actions'
import { cacheDataByKey, toggleUnits, sortByParams } from './utils'
import { SortDirection } from 'react-virtualized'

import { filterLabel, filterLabelWithSecondKey } from './utils'
import Storage from '../../../../AppHeader/components/Storage'

export const correlograms = (
  state = { data: {}, binSize: 0 },
  { type, payload }
) => {
  switch (type) {
    case SAVE_CORRELOGRAM: {
      const { record_id, sortingId, data, binSize } = payload
      return {
        data: cacheDataByKey(record_id, sortingId, state.data, data),
        binSize
      }
    }

    default:
      return state
  }
}

export const features = (
  state = { selectedSpikes: { spikes: [], subplot: [] } },
  { type, payload }
) => {
  switch (type) {
    case SAVE_FEATURES: {
      const { record_id, sortingId, data } = payload
      return {
        data: cacheDataByKey(record_id, sortingId, state.data, data)
      }
    }
    case SAVE_FEATURES_ERROR:
      return {
        data: payload
      }

    case SELECTED_SPIKES:
      return { ...state, selectedSpikes: payload }

    default:
      return state
  }
}

export const waveforms = (
  state = { data: {}, view: 'mean_waveforms', overlap: false, geometry: true },
  { type, payload }
) => {
  switch (type) {
    case START_LOAD_WAVEFORMS:
      return {
        ...state,
        loading: payload
      }

    case SAVE_WAVEFORM: {
      const { record_id, sortingId, data, cache, loading } = payload
      if (cache) return state
      return {
        ...state,
        loading,
        data: {
          ...state.data,
          [state.view]: cacheDataByKey(
            record_id,
            sortingId,
            state.data[state.view],
            data
          )
        }
      }
    }

    case SWITCH_WAVEFORM_VIEW: {
      return {
        ...state,
        view: payload
      }
    }
    case TOGGLE_WAVEFORM_GEOMETRY: {
      const { overlap } = state
      return {
        ...state,
        geometry: payload,
        // if geometry view is enabled then overlapping should be disabled
        overlap: payload ? false : overlap
      }
    }
    case TOGGLE_WAVEFORM_OVERLAPPING: {
      return {
        ...state,
        overlap: payload
      }
    }

    case CLEAR_WAVEFORMS_CACHE: {
      return {
        data: {},
        view: 'mean_waveforms',
        args: {},
        overlap: false,
        geometry: true
      }
    }
    case SAVE_WAVEFORMS_ERROR:
      return {
        loading: false,
        data: payload
      }

    default:
      return state
  }
}
const initialClusterState = {
  loading: true,
  isTwoColumnSort: false,
  sortByClickCounter: 0,
  sortBy: 'n_spikes',
  secondSortBy: null,
  sortDirection: SortDirection.DESC
}
export const clusters = (state = initialClusterState, { type, payload }) => {
  switch (type) {
    case SORT_CLUSTERVIEW: {
      return sortByParams(state, payload)
    }
    case CLUSTERVIEW_LOADING: {
      return { ...state, loading: payload }
    }
    case SAVE_CLUSTERVIEW: {
      const { sortingId, data } = payload
      const {
        sortBy,
        secondSortBy,
        sortByClickCounter,
        isTwoColumnSort,
        sortDirection
      } = state
      if (!sortingId) return state
      let cloneState = { ...state, loading: false }

      if (cloneState[sortingId]) {
        const prev = cloneState[sortingId]

        const next = [sortBy, secondSortBy].includes('label')
          ? {
              ...data,
              data: {
                ...data?.data,
                clusters:
                  sortBy === 'label'
                    ? filterLabel(
                        data?.data.clusters,
                        sortDirection,
                        secondSortBy
                      )
                    : filterLabelWithSecondKey(
                        data?.data.clusters,
                        sortBy,
                        sortDirection
                      )
              }
            }
          : data

        cloneState = {
          ...cloneState,
          [sortingId]: {
            ...prev,
            ...next
          }
        }
      } else {
        cloneState = {
          [sortingId]: data,
          sortByClickCounter,
          secondSortBy,
          isTwoColumnSort
        }
      }

      return {
        loading: false,
        sortBy,
        sortDirection,
        ...cloneState
      }
    }

    default:
      return state
  }
}

export const similarity = (
  state = { sortBy: 'similarity', sortDirection: SortDirection.DESC },
  { type, payload }
) => {
  switch (type) {
    case SORT_SIMILARITYVIEW: {
      const { sortBy, sortDirection } = payload
      return { ...state, sortBy, sortDirection }
    }
    case SAVE_SIMILARITYVIEW: {
      const { sortingId, dataId, data } = payload
      const { sortBy, sortDirection } = state
      const similarities = cacheDataByKey(sortingId, dataId, state, data)
      return { ...similarities, sortBy, sortDirection }
    }
    case UPDATE_SIMILARITYVIEW: {
      const { sortBy, sortDirection } = state
      if (!payload) return state
      return { sortBy, sortDirection, ...payload }
    }

    default:
      return state
  }
}

export const units = (
  state = { cluster: [], similarity: [] },
  { type, payload }
) => {
  switch (type) {
    case SAVE_CLUSTER_UNITS: {
      if (payload === null) return { ...state, cluster: [] }

      const { cluster } = state
      const updatedClusters = toggleUnits(cluster, payload)

      return {
        ...state,
        similarity: [],
        cluster: updatedClusters
      }
    }

    case SAVE_SIMILARITY_UNITS: {
      if (payload === null) return { ...state, similarity: [] }

      const { similarity } = state
      const updated = toggleUnits(similarity, payload)

      return { ...state, similarity: updated }
    }

    default:
      return state
  }
}

const initialRecommendationsState = {
  isCurationAI: false,
  recommendations: new Map(),
  hiddenRecommendations: new Map()
}
export const curationAI = (
  state = initialRecommendationsState,
  { type, payload }
) => {
  switch (type) {
    case TOGGLE_CURATION_AI: {
      return { ...state, isCurationAI: payload }
    }
    case ADD_RECOMMENDATIONS: {
      const { recommendations: storedRecommendations } = state
      const [ID] = payload

      let hiddenRecommendations = Storage.getItem(`${ID}-hidden-units`)
      return {
        ...state,
        hiddenRecommendations: new Map(JSON.parse(hiddenRecommendations)),
        recommendations: new Map([...storedRecommendations, payload])
      }
    }
    case STORE_SUGGESTION_VISIBILITY: {
      return {
        ...state,
        hiddenRecommendations: payload
      }
    }
    default:
      return state
  }
}
