import React from 'react'
import { connect } from 'react-redux'
import memoOne from 'memoize-one'
import { lighten } from '@material-ui/core/styles'
import { ArrowUpwardOutlined, ArrowDownwardOutlined } from '@material-ui/icons'
import TimeWidget, {
  PainterPath,
  TimeWidgetPanel
} from '../TimeWidget/TimeWidget'
import { TimeSeriesData } from './TimeseriesData'
import CaptureKeyEvent from './CaptureKeyEvent'
import {
  toggleChannel,
  toggleChannelBulk,
  replaceChannels,
  updateGroups,
  toggleFullScreenMode
} from '../TimeseriesView/NewLeftPanel/redux/actions'
import { updateView } from './NewLeftPanel/redux/actions'
import { hexToHSL } from '../../utils/styles'
import { DOWN_SAMPLE_FACTOR, MODALS_ID, NOTIFICATION } from '../../utils/consts'
import {
  largestTriangleThreeBuckets,
  determine_downsample_factor_from_num_timepoints
} from './utils'
import { OuterContainer } from '../TimeWidget/components'
import {
  setDataManipulationParams,
  toggleDataManipulationMode
} from '../../containers/NewTimeSeries/redux/actions'
import { addNotification } from '../../actions/notifications'
import isEqual from 'lodash/fp/isEqual'

class TimeseriesWidget extends TimeSeriesData {
  async componentDidMount() {
    const dataSegmentSet = (ds_factor, t1, t2) => {
      let trange = this.timeRange()
      if (!trange) return
      if (t1 <= trange[1] && t2 >= trange[0]) {
        // if the new chunk is in range of what we are viewing, we repaint
        this._repaint()
      }
    }
    if (this.props.timeseriesModel) {
      // this happens when the timeseries model receives new data
      this.props.timeseriesModel.onDataSegmentSet(dataSegmentSet)
    }

    if (this.props.nndTimeseriesModel.length !== 0) {
      this.props.nndTimeseriesModel.forEach(value =>
        value.onDataSegmentSet(dataSegmentSet)
      )
    }
    if (
      this.props.timeseriesModel ||
      this.props.nndTimeseriesModel.length !== 0
    ) {
      this.updateDownsampleFactor()
      this.updatePanels()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.y_scale_factor = this.props.y_scale_factor

    if (
      prevState.zoomedChannels?.size !== this.state.zoomedChannels?.size ||
      !isEqual([...prevProps.channelsData], [...this.props.channelsData]) ||
      prevProps.isAllNNDVisible !== this.props.isAllNNDVisible ||
      prevProps.nndData !== this.props.nndData
    ) {
      this.updatePanels()
    }
    this.updateDownsampleFactor()
    if (this.props.timeRange !== prevProps.timeRange) {
      this._repaint()
    } else if (this.state.panels !== prevState.panels) {
      this._repaint()
    }
  }

  get nndChannelsWithLabel() {
    const { nndData } = this.props
    const { zoomedChannels } = this.state

    const nndChannels = new Set()
    if (!nndData?.size) return nndChannels

    const recordIds = new Set(
      Array.from(nndData.values()).map(data => data.recordId)
    )

    Array.from(nndData.values()).forEach(data => {
      const { channelId, label, isVisible, recordId } = data
      const channel = Number(channelId)
      const isZoomActive =
        zoomedChannels && Array.from(zoomedChannels).length !== 0
      const modelIndex = Array.from(recordIds.values()).findIndex(
        value => value === recordId
      )

      const addChannelToSet = () =>
        nndChannels.add({
          channel,
          label,
          nndRecordId: recordId,
          modelIndex
        })

      if (isZoomActive) {
        Array.from(zoomedChannels).includes(channel) &&
          isVisible &&
          addChannelToSet()
      } else {
        isVisible && addChannelToSet()
      }
    })
    return nndChannels
  }

  updateDownsampleFactor() {
    let trange = Boolean(this.state.zoomedChannels)
      ? this.state.currentWindowTimeRange
      : this.timeRange()
    if (!trange) return

    let downsample_factor = determine_downsample_factor_from_num_timepoints(
      this.props.width * DOWN_SAMPLE_FACTOR,
      trange[1] - trange[0]
    )
    if (downsample_factor !== this._downsampleFactor) {
      this._downsampleFactor = downsample_factor
      this._repaint()
    }
  }

  updatePanels() {
    const { channelsData } = this.props
    const { zoomedChannels } = this.state
    const MAX_TS_CHANNELS = 192 // 128 neuronal channels + space for at least 64 non-neuronal channels = 192

    const filteredChannels =
      zoomedChannels &&
      new Set(
        Array.from(zoomedChannels).filter(channel => channelsData.has(channel))
      )

    const arr = filteredChannels || channelsData
    const TOTAL_NR_CHANNELS = arr.size
    let panels = []
    let nndPanels = []

    for (let m of arr) {
      let label = String(m)

      let panel = new TimeWidgetPanel(
        (painter, timeRange, c) => {
          this.paintChannel({ painter, timeRange, m: c })
        },
        { label: label, isNND: false, modelIndex: null },
        Number(label)
      )

      panel.setCoordYRange(-1, 1)
      panels.push(panel)
    }
    const AVAILABLE_CH_SPACE = MAX_TS_CHANNELS - TOTAL_NR_CHANNELS

    if (AVAILABLE_CH_SPACE > 0) {
      let counter = TOTAL_NR_CHANNELS
      for (let m of this.nndChannelsWithLabel) {
        let label = String(m.label)

        let nndPanel = new TimeWidgetPanel(
          (painter, timeRange, c) => {
            return this.paintChannel({
              painter,
              timeRange,
              m: c,
              isNND: true,
              nndRecordId: m.nndRecordId,
              nndModelIndex: m.modelIndex
            })
          },
          { label, isNND: true, modelIndex: m.modelIndex },
          m.channel
        )

        nndPanel.setCoordYRange(-1, 1)
        nndPanels.push(nndPanel)
        counter++
        if (counter === MAX_TS_CHANNELS) break
      }
    }
    const chunkedPanels = this.groupPanels({ panels }).filter(
      panel => panel.length !== 0
    )
    const chunkedNNDPanels = this.groupPanels({
      panels: nndPanels,
      isNND: true
    }).filter(panel => panel.length !== 0)

    this.setState({
      panels: [...chunkedPanels.map(c => c.reverse()), ...chunkedNNDPanels],
      groups: chunkedPanels.length + chunkedNNDPanels.length
    })
  }

  zoomDrag = newPanels => {
    const { channelsData, nndData, timeRange } = this.props
    const nndChannels = nndData?.size ? new Set(Array.from(nndData.keys())) : []

    const allChannelsData = new Set([...channelsData, ...nndChannels])

    const zoomedChannels = new Set(
      Array.from(allChannelsData).filter(channel => newPanels.has(channel))
    )

    this.setState(({ zoomLevel, mainTimeRange }) => ({
      mainTimeRange: zoomLevel === 0 ? timeRange : mainTimeRange,
      zoomLevel: zoomLevel + 1,
      zoomedChannels
    }))
  }

  getInitialZoom = () => {
    const { zoomLevel, mainTimeRange } = this.state
    const { setTimeRange } = this.props || {}

    if (zoomLevel > 0) {
      setTimeRange(mainTimeRange)
      this.setState(() => ({
        zoomLevel: 0,
        zoomedChannels: null
      }))
    }
  }

  removeChannelOnClick = channelIds => {
    if (channelIds) {
      setTimeout(() => {
        this.props.toggleChannelBulk(channelIds)
        this.setState({ selectedPaths: new Set() })
      }, 100)
    }
  }

  setCurrentWindowTimeRange = tr => {
    this.setState({
      currentWindowTimeRange: tr
    })
  }

  _handleTimeRangeChanged = (tr, isZoomRelease = false) => {
    const { setTimeRange } = this.props || {}

    if (isZoomRelease || Boolean(this.state.zoomedChannels))
      this.setCurrentWindowTimeRange(tr)
    else setTimeRange(tr)
    this.updateDownsampleFactor()
  }
  _handleCurrentTimeChanged = t => {
    this.setState({
      currentTime: t
    })
  }
  timeRange() {
    return this.props.timeRange
  }
  currentTime() {
    return this.state.currentTime
  }
  paintChannel = ({
    painter,
    timeRange,
    m,
    isNND = false,
    nndRecordId = null,
    nndModelIndex = null
  }) => {
    const {
      y_offsets,
      num_timepoints,
      timeseriesModel,
      nndTimeseriesInfo,
      nndTimeseriesModel,
      darkMode,
      biochipData,
      addNotification
    } = this.props || {}

    let offsets = y_offsets
    let nnd_offsets = []
    let nndChannelIndex = 0
    let timepoints = num_timepoints
    let model = timeseriesModel
    if (isNND && nndRecordId && nndTimeseriesInfo.has(nndRecordId)) {
      const { y_offsets: nnd_y_offsets, num_timepoints: nnd_num_timepoints } =
        nndTimeseriesInfo.get(nndRecordId)

      nnd_offsets = nnd_y_offsets
      const nndChannel = this.props.nndData.get(m)
      const channelRecord =
        this.props.timeseriesInfo?.nnd_data[nndChannel.recordId]
      nndChannelIndex = Object.keys(channelRecord).findIndex(
        val => val === m.toString()
      )

      timepoints = nnd_num_timepoints
      model = nndTimeseriesModel[nndModelIndex]
    }
    let trange = timeRange

    if (!offsets) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title:
          "There might be a wrong dtype selected, that's why channels are not drawing. Please go to Recording view and use the Data widget to provide another dtype (recommended int16)."
      })
      return
    }

    if (!trange) return

    let y_offset = isNND ? nnd_offsets[nndChannelIndex] : offsets[m]
    painter.setPen({ color: 'black', width: 1 })
    // painter.drawLine(trange[0], 0, trange[1], 0);

    let y_scale_factor = this.y_scale_factor

    let t1 = Math.floor(trange[0])
    let t2 = Math.floor(trange[1] + 1)

    if (t1 < 0) t1 = 0
    if (t2 >= num_timepoints) t2 = timepoints
    let downsample_factor = this._downsampleFactor
    let t1b = Math.floor(t1 / downsample_factor)
    let t2b = Math.floor(t2 / downsample_factor)
    let pp = new PainterPath()
    // this.setStatusText(`Painting timepoints ${t1b} to ${t2b}; downsampling ${downsample_factor}`);
    let data0 = model.getChannelData(m, t1b, t2b, downsample_factor)

    const dd = []

    // TODO: figure out better preloading strategy
    // ////////////////////////////////////////////////////////////////////////////////////////
    // // check to see if we actually got the data... if we did, then we will preload
    // let gotAllTheData = true;
    // for (let val of data0) {
    //     if (isNaN(val)) {
    //         gotAllTheData = false;
    //         break;
    //     }
    // }
    // if (gotAllTheData) {
    //     // trigger pre-loading
    //     console.log(' ---- preloading', m, Math.floor(t1b / 3), Math.floor(t2b / 3), downsample_factor * 3);
    //     this.props.timeseriesModel.getChannelData(m, Math.floor(t1b / 3), Math.floor(t2b / 3), downsample_factor * 3, { request_only: true });
    //     if ((downsample_factor > 1) && (this.currentTime >= 0)) {
    //         let t1c = Math.floor(Math.max(0, (this.currentTime - 800) / (downsample_factor / 3)))
    //         let t2c = Math.floor(Math.min(this.props.timeseriesModel.numTimepoints(), (this.currentTime + 800) / (downsample_factor / 3)))
    //         console.log(' ----*** preloading', m, t1c, t2c, downsample_factor / 3)
    //         this.props.timeseriesModel.getChannelData(m, t1c, t2c, downsample_factor / 3, { request_only: true });
    //     }
    // }
    // ////////////////////////////////////////////////////////////////////////////////////////
    if (downsample_factor === 1) {
      let penDown = false
      for (let tt = t1; tt < t2; tt++) {
        let val = data0[tt - t1]
        if (!isNaN(val)) {
          let val2 = (val + y_offset) * y_scale_factor
          if (penDown) {
            dd.push([tt, val2, 'lineTo'])
          } else {
            dd.push([tt, val2, 'moveTo'])
            penDown = true
          }
        } else {
          penDown = false
        }
      }
    } else {
      let penDown = false
      for (let tt = t1b; tt < t2b; tt++) {
        let val_min = data0[(tt - t1b) * 2]
        let val_max = data0[(tt - t1b) * 2 + 1]
        if (!isNaN(val_min) && !isNaN(val_max)) {
          let val2_min = (val_min + y_offset) * y_scale_factor
          let val2_max = (val_max + y_offset) * y_scale_factor

          if (penDown) {
            dd.push([tt * downsample_factor, val2_min, 'lineTo'])
            dd.push([tt * downsample_factor, val2_max, 'lineTo'])
          } else {
            dd.push([tt * downsample_factor, val2_min, 'moveTo'])
            dd.push([tt * downsample_factor, val2_max, 'lineTo'])
            penDown = true
          }
        } else {
          penDown = false
        }
      }
    }

    if (this.state.downsample.enable) {
      this.drawTimeSeries(
        dd,
        pp,
        largestTriangleThreeBuckets,
        this.state.downsample.value
      )
    } else {
      this.drawTimeSeries(dd, pp)
    }

    const defaultColor = darkMode ? '#ffffff' : '#000000'
    const color = isNND ? '#F2DF2D' : biochipData.mapColors[m] ?? defaultColor

    if (this.state.selectedPaths.has(m)) {
      painter.setPen({
        color: this.props.darkMode ? lighten(color, 0.75) : hexToHSL(color, 25),
        width: 1.25
      })
    } else {
      // Note that using width=2 here had some bad side-effects on the rendering - and I think it's the browser's fault
      painter.setPen({
        color: color,
        width: 1
      })
    }

    painter.drawPath(pp)

    this.setPaths(m, pp)
  }

  drawTimeSeries(data, painter, filter = () => data, ...filterArgs) {
    if (data.length) {
      filter(data, ...filterArgs)
        .filter(x => x)
        .forEach(d => {
          const [x, y, fn] = d
          painter[fn](x, y)
        })
    }
  }

  downsampleOnScroll = value => {
    this.setState(current => ({
      ...current,
      downsample: {
        value: current.downsample.value,
        enable: value
      }
    }))
  }

  setPaths(channel, pp) {
    this.paths.set(channel, pp)
  }

  paths = new Map()

  _zoomAmplitude = factor => {
    const { setYScaleFactor } = this.props || {}
    this.y_scale_factor *= factor
    setYScaleFactor(this.y_scale_factor)
    this._repaint()
  }
  _repaint = () => {
    this._repainter && this._repainter()
  }
  _registerPainter = repaintFunc => {
    this._repainter = repaintFunc
  }

  actions = memoOne(() => {
    return [
      {
        callback: this._zoomAmplitude.bind(this, 1.15),
        title: 'Move up',
        icon: ArrowUpwardOutlined,
        key: 38
      },
      {
        callback: this._zoomAmplitude.bind(this, 1 / 1.15),
        title: 'Move down',
        icon: ArrowDownwardOutlined,
        key: 40
      }
    ]
  })

  groupPanels = ({ panels, isNND = false }) => {
    if (!panels.length) return []
    const { biochipData, nndData } = this.props

    let nndChunks = {}
    let chunk = []

    const nndChannels = nndData?.size ? Array.from(nndData.keys()) : []
    nndChannels.forEach((channelId, index) => {
      chunk.push(channelId)
      const isFinalIndex = index === nndChannels.length - 1
      if (chunk.length === 32 || isFinalIndex) {
        nndChunks = { ...nndChunks, [index]: chunk }
        chunk = []
        index++
      }
    })

    const chunks = isNND ? nndChunks : biochipData.chunks

    const mapPanels = new Map(panels.map(panel => [panel._id, panel]))

    return Object.values(chunks).map(chunk =>
      chunk.map(channel => mapPanels.get(channel)).filter(panel => panel)
    )
  }

  handle_set_data_manipulation_mode = mode => {
    const {
      handleSetDataManipulationMode,
      fullScreenMode,
      handleFullScreenMode
    } = this.props
    if (fullScreenMode) handleFullScreenMode(false)
    handleSetDataManipulationMode(mode)
  }

  handle_set_data_manipulation_params = params => {
    const { handleSetDataManipulationParams } = this.props
    handleSetDataManipulationParams(params)
  }

  render() {
    const {
      timeseriesModel,
      leftWidth,
      width,
      height,
      eventsUploadModal,
      darkMode,
      fullScreenMode,
      handleFullScreenMode,
      updateView,
      timeSeriesView,
      rasterData,
      dataManipulationParams,
      eventsController,
      cursorType,
      isLimited,
      childWindow,
      timeRange,
      isDisabledShortCuts,
      isEditMode
    } = this.props
    const { mode } = dataManipulationParams
    const samplerate = timeseriesModel ? timeseriesModel.getSampleRate() : 0

    return (
      <OuterContainer width={width} height={height}>
        <CaptureKeyEvent>
          <TimeWidget
            /* short cuts are disabled when modals are opened */
            isDisabledShortCuts={isDisabledShortCuts}
            isEditMode={isEditMode}
            /* end */

            eventsUploadModal={eventsUploadModal}
            panels={this.state.panels}
            actions={this.actions()}
            width={childWindow ? width : width - leftWidth}
            height={height}
            registerRepainter={this._registerPainter}
            samplerate={samplerate}
            maxTimeSpan={1e6 / 15}
            numTimepoints={
              timeseriesModel ? timeseriesModel.numTimepoints() : 0
            }
            currentTime={this.state.currentTime}
            timeRange={
              Boolean(this.state.zoomedChannels)
                ? this.state.currentWindowTimeRange
                : timeRange
            }
            onCurrentTimeChanged={this._handleCurrentTimeChanged}
            onTimeRangeChanged={this._handleTimeRangeChanged}
            leftPanelMode={leftWidth >= 290}
            fullScreenMode={fullScreenMode}
            handleFullScreenMode={handleFullScreenMode}
            darkMode={darkMode}
            updateView={updateView}
            timeSeriesView={timeSeriesView}
            groups={this.state.groups}
            isZoomActive={Boolean(this.state.zoomedChannels)}
            zoomDrag={this.zoomDrag}
            zoomedChannels={this.state.zoomedChannels}
            getInitialZoom={this.getInitialZoom}
            leftPanelWidth={leftWidth}
            paths={this.paths}
            selectedPaths={this.state.selectedPaths}
            removeChannelOnClick={this.removeChannelOnClick}
            downsampleOnScroll={this.downsampleOnScroll}
            rasterData={rasterData}
            cursorType={cursorType}
            // slice cut
            dataManipulationMode={mode}
            dataManipulationParams={dataManipulationParams[mode]}
            setDataManipulationParams={this.handle_set_data_manipulation_params}
            setDataManipulationMode={this.handle_set_data_manipulation_mode}
            // events
            eventsController={eventsController}
            isShowAllEvents={eventsController.isShowAllEvents}
            selectedEvent={eventsController.selectedEvent}
            eventsTimestamps={eventsController.allTimestamps}
            //Access
            isLimited={isLimited}
          />
        </CaptureKeyEvent>
      </OuterContainer>
    )
  }
}

const mapStateToProps = state => ({
  channelsData: state.timeSeriesLeftPanel.channels,
  isAllNNDVisible: state.timeSeriesLeftPanel.isAllNNDVisible,
  timeseriesInfo: state.timeSeriesLeftPanel.timeseriesInfo,
  nndData: state.timeSeriesLeftPanel.nnd,
  leftWidth: state.timeSeriesLeftPanel.leftPanelWidth,
  fullScreenMode: state.timeSeriesLeftPanel.fullScreenMode,
  darkMode: state.darkMode,
  biochipData: state.biochipData,
  timeSeriesView: state.timeSeriesLeftPanel.timeSeriesView,
  cursorType: state.timeSeriesLeftPanel.cursorType,
  dataManipulationParams: state.dataManipulation,
  isDisabledShortCuts:
    state.modals?.has(MODALS_ID.DATA_MANIPULATION_MODAL) ||
    state.modals?.has(MODALS_ID.EVENTS_FILE_UPLOAD_MODAL),
  isEditMode: state.timeSeriesLeftPanel.editMode
})

const mapDispatchToProps = dispatch => ({
  addNotification: config => dispatch(addNotification(config)),
  replaceChannels: channels => dispatch(replaceChannels(channels)),
  updateView: view => dispatch(updateView(view)),
  toggleChannel: channelId => dispatch(toggleChannel(channelId)),
  toggleChannelBulk: (channels, disabled) =>
    dispatch(toggleChannelBulk(channels, disabled)),
  updateGroups: channels => dispatch(updateGroups(channels)),
  handleFullScreenMode: mode => dispatch(toggleFullScreenMode(mode)),
  handleSetDataManipulationParams: params =>
    dispatch(setDataManipulationParams(params)),
  handleSetDataManipulationMode: mode =>
    dispatch(toggleDataManipulationMode(mode))
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeseriesWidget)
