import { useSelector, useDispatch } from 'react-redux'
import {
  switchWaveformView,
  toggleWaveformGeometry,
  toggleWaveformOverlapping
} from '../../../redux/actions'
import { WAVEFORM_VIEWS_ENUM } from '../consts'

export const useOnSwitch = () => {
  const dispatch = useDispatch()
  const waveformsView = useSelector(state => state.widgetsCache.waveforms.view)

  return () => {
    dispatch(
      switchWaveformView(
        waveformsView === WAVEFORM_VIEWS_ENUM.MEAN
          ? WAVEFORM_VIEWS_ENUM.OVERLAP
          : WAVEFORM_VIEWS_ENUM.MEAN
      )
    )
  }
}

export const useToggleGeometry = () => {
  const dispatch = useDispatch()
  const waveformGeometry = useSelector(
    state => state.widgetsCache.waveforms.geometry
  )

  return () => {
    dispatch(toggleWaveformGeometry(!waveformGeometry))
  }
}

export const useToggleOverlapping = () => {
  const dispatch = useDispatch()
  const waveformOverlapping = useSelector(
    state => state.widgetsCache.waveforms.overlap
  )

  return () => {
    dispatch(toggleWaveformOverlapping(!waveformOverlapping))
  }
}
