export const STORE_CURATIONS = 'STORE_CURATIONS'
export const UPDATE_SINGLE_CURATIONS = 'UPDATE_SINGLE_CURATIONS'
export const SELECT_CURATION = 'SELECT_CURATION'
export const UPDATE_CURATION = 'UPDATE_CURATION'
export const DELETE_DEFAULT_CURATION = 'DELETE_DEFAULT_CURATION'
export const INIT_CURATION = 'INIT_CURATION'

export const storeCurations = data => ({
  type: STORE_CURATIONS,
  payload: data
})

export const updateSingleCuration = data => ({
  type: UPDATE_SINGLE_CURATIONS,
  payload: data
})

export const selectCuration = data => ({
  type: SELECT_CURATION,
  payload: data
})

export const updateCuration = data => ({
  type: UPDATE_CURATION,
  payload: data
})

export const deleteDefaultCuration = () => ({
  type: DELETE_DEFAULT_CURATION
})

export const initCuration = () => ({
  type: INIT_CURATION
})
