import React, { useMemo, useState, useCallback } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import CustomCheckbox from '../../../../FormComponents/CustomCheckbox'
import cn from 'classnames'
import { RASTER_UNITS } from '../../../../../utils/consts'

const { single, multi, noise, uncurated } = RASTER_UNITS

export default function useFilterButton(classes, onFilterItemClick, filters) {
  const darkMode = useSelector(state => state.darkMode)
  const [anchorEl, setAnchorEl] = useState()
  const theme = useTheme()
  const iconStyle = useMemo(
    () => ({
      fill: theme.palette.colors.backgrounds.main,
      color: darkMode
        ? theme.palette.colors.white
        : theme.palette.colors.neutral[5]
    }),
    [theme, darkMode]
  )
  const onIconClick = useCallback(
    e => {
      setAnchorEl(anchorEl ? null : e?.currentTarget?.parentElement)
    },
    [anchorEl]
  )
  const handleClickAway = useCallback(() => {
    anchorEl && setAnchorEl(null)
  }, [anchorEl])

  const handleItemChange = useCallback(
    value => {
      onIconClick()
      onFilterItemClick(value)
    },
    [onIconClick, onFilterItemClick]
  )

  const items = useMemo(
    () => [
      {
        label: (
          <CustomCheckbox
            label="Single"
            checked={filters.includes(single)}
            onChange={() => handleItemChange(single)}
            size={10}
            labelClassName={cn(classes.itemLabel, classes.first_item_color)}
            className={classes.checkboxRoot}
          />
        ),
        value: single
      },
      {
        label: (
          <CustomCheckbox
            label="Multi"
            checked={filters.includes(multi)}
            size={10}
            onChange={() => handleItemChange(multi)}
            labelClassName={cn(classes.itemLabel, classes.second_item_color)}
            className={classes.checkboxRoot}
          />
        ),
        value: multi
      },
      {
        label: (
          <CustomCheckbox
            label="Noise"
            size={10}
            checked={filters.includes(noise)}
            onChange={() => handleItemChange(noise)}
            labelClassName={cn(classes.itemLabel, classes.n_item_color)}
            className={classes.checkboxRoot}
          />
        ),
        value: noise
      },
      {
        label: (
          <CustomCheckbox
            label="Uncurated"
            size={10}
            checked={filters.includes(uncurated)}
            onChange={() => handleItemChange(uncurated)}
            labelClassName={cn(classes.itemLabel, classes.n_item_color)}
            className={classes.checkboxRoot}
          />
        ),
        value: uncurated
      }
    ],
    [classes, handleItemChange, filters]
  )

  return { iconStyle, items, anchorEl, onIconClick, handleClickAway }
}
