import { useCallback } from 'react'
import { findProbeDrawing } from '../../../../TimeseriesView/NewLeftPanel/components/DrawProbe/DrawProbe'
import { MODALS_ID } from '../../../../../utils/consts'
import useToggleModal from '../../../../../hooks/useToggleModal'
const useProbeName = ({ id, name, setProbeDesign }) => {
  const { onOpen } = useToggleModal(MODALS_ID.PROBE_DESIGN_MODAL)
  const hasMapping = findProbeDrawing(name)

  const openMapping = useCallback(() => {
    setProbeDesign({ id, name })
    onOpen()
  }, [id, name, setProbeDesign, onOpen])
  return { hasMapping, openMapping }
}

export default useProbeName
