import { TransformCoords } from './TransformCoords'

class CtxWrapper {
  constructor(ctx) {
    this.ctx = ctx
  }

  getCtx() {
    this.ctx.arrow = function (startX, startY, endX, endY, controlPoints) {
      var dx = endX - startX
      var dy = endY - startY
      var len = Math.sqrt(dx * dx + dy * dy)
      var sin = dy / len
      var cos = dx / len
      var a = []
      a.push(0, 0)
      for (let i = 0; i < controlPoints.length; i += 2) {
        let x = controlPoints[i]
        let y = controlPoints[i + 1]
        a.push(x < 0 ? len + x : x, y)
      }
      a.push(len, 0)
      for (let i = controlPoints.length; i > 0; i -= 2) {
        let x = controlPoints[i - 2]
        let y = controlPoints[i - 1]
        a.push(x < 0 ? len + x : x, -y)
      }
      a.push(0, 0)
      for (let i = 0; i < a.length; i += 2) {
        let x = a[i] * cos - a[i + 1] * sin + startX
        let y = a[i] * sin + a[i + 1] * cos + startY
        if (i === 0) this.moveTo(x, y)
        else this.lineTo(x, y)
      }
    }
    if (!this.ctx?.roundRect) {
      this.ctx.roundRect = function (x, y, w, h, radii) {
        if (![x, y, w, h].every(input => Number.isFinite(input))) {
          return
        }

        radii = parseRadiiArgument(radii)

        let upperLeft, upperRight, lowerRight, lowerLeft

        if (radii.length === 4) {
          upperLeft = toCornerPoint(radii[0])
          upperRight = toCornerPoint(radii[1])
          lowerRight = toCornerPoint(radii[2])
          lowerLeft = toCornerPoint(radii[3])
        } else if (radii.length === 3) {
          upperLeft = toCornerPoint(radii[0])
          upperRight = toCornerPoint(radii[1])
          lowerLeft = toCornerPoint(radii[1])
          lowerRight = toCornerPoint(radii[2])
        } else if (radii.length === 2) {
          upperLeft = toCornerPoint(radii[0])
          lowerRight = toCornerPoint(radii[0])
          upperRight = toCornerPoint(radii[1])
          lowerLeft = toCornerPoint(radii[1])
        } else if (radii.length === 1) {
          upperLeft = toCornerPoint(radii[0])
          upperRight = toCornerPoint(radii[0])
          lowerRight = toCornerPoint(radii[0])
          lowerLeft = toCornerPoint(radii[0])
        }

        const corners = [upperLeft, upperRight, lowerRight, lowerLeft]

        if (
          corners.some(({ x, y }) => !Number.isFinite(x) || !Number.isFinite(y))
        ) {
          return
        }

        fixOverlappingCorners(corners)

        if (w < 0 && h < 0) {
          this.moveTo(x - upperLeft.x, y)
          this.ellipse(
            x + w + upperRight.x,
            y - upperRight.y,
            upperRight.x,
            upperRight.y,
            0,
            -Math.PI * 1.5,
            -Math.PI
          )
          this.ellipse(
            x + w + lowerRight.x,
            y + h + lowerRight.y,
            lowerRight.x,
            lowerRight.y,
            0,
            -Math.PI,
            -Math.PI / 2
          )
          this.ellipse(
            x - lowerLeft.x,
            y + h + lowerLeft.y,
            lowerLeft.x,
            lowerLeft.y,
            0,
            -Math.PI / 2,
            0
          )
          this.ellipse(
            x - upperLeft.x,
            y - upperLeft.y,
            upperLeft.x,
            upperLeft.y,
            0,
            0,
            -Math.PI / 2
          )
        } else if (w < 0) {
          this.moveTo(x - upperLeft.x, y)
          this.ellipse(
            x + w + upperRight.x,
            y + upperRight.y,
            upperRight.x,
            upperRight.y,
            0,
            -Math.PI / 2,
            -Math.PI,
            1
          )
          this.ellipse(
            x + w + lowerRight.x,
            y + h - lowerRight.y,
            lowerRight.x,
            lowerRight.y,
            0,
            -Math.PI,
            -Math.PI * 1.5,
            1
          )
          this.ellipse(
            x - lowerLeft.x,
            y + h - lowerLeft.y,
            lowerLeft.x,
            lowerLeft.y,
            0,
            Math.PI / 2,
            0,
            1
          )
          this.ellipse(
            x - upperLeft.x,
            y + upperLeft.y,
            upperLeft.x,
            upperLeft.y,
            0,
            0,
            -Math.PI / 2,
            1
          )
        } else if (h < 0) {
          this.moveTo(x + upperLeft.x, y)
          this.ellipse(
            x + w - upperRight.x,
            y - upperRight.y,
            upperRight.x,
            upperRight.y,
            0,
            Math.PI / 2,
            0,
            1
          )
          this.ellipse(
            x + w - lowerRight.x,
            y + h + lowerRight.y,
            lowerRight.x,
            lowerRight.y,
            0,
            0,
            -Math.PI / 2,
            1
          )
          this.ellipse(
            x + lowerLeft.x,
            y + h + lowerLeft.y,
            lowerLeft.x,
            lowerLeft.y,
            0,
            -Math.PI / 2,
            -Math.PI,
            1
          )
          this.ellipse(
            x + upperLeft.x,
            y - upperLeft.y,
            upperLeft.x,
            upperLeft.y,
            0,
            -Math.PI,
            -Math.PI * 1.5,
            1
          )
        } else {
          this.moveTo(x + upperLeft.x, y)
          this.ellipse(
            x + w - upperRight.x,
            y + upperRight.y,
            upperRight.x,
            upperRight.y,
            0,
            -Math.PI / 2,
            0
          )
          this.ellipse(
            x + w - lowerRight.x,
            y + h - lowerRight.y,
            lowerRight.x,
            lowerRight.y,
            0,
            0,
            Math.PI / 2
          )
          this.ellipse(
            x + lowerLeft.x,
            y + h - lowerLeft.y,
            lowerLeft.x,
            lowerLeft.y,
            0,
            Math.PI / 2,
            Math.PI
          )
          this.ellipse(
            x + upperLeft.x,
            y + upperLeft.y,
            upperLeft.x,
            upperLeft.y,
            0,
            Math.PI,
            Math.PI * 1.5
          )
        }

        this.closePath()
        this.moveTo(x, y)

        function toDOMPointInit(value) {
          const { x, y, z, w } = value
          return { x, y, z, w }
        }

        function parseRadiiArgument(value) {
          const type = typeof value

          if (type === 'undefined' || value === null) {
            return [0]
          }
          if (type === 'function') {
            return [NaN]
          }
          if (type === 'object') {
            if (typeof value[Symbol.iterator] === 'function') {
              return [...value].map(elem => {
                const elemType = typeof elem
                if (elemType === 'undefined' || elem === null) {
                  return 0
                }
                if (elemType === 'function') {
                  return NaN
                }
                if (elemType === 'object') {
                  return toDOMPointInit(elem)
                }
                return toUnrestrictedNumber(elem)
              })
            }

            return [toDOMPointInit(value)]
          }

          return [toUnrestrictedNumber(value)]
        }

        function toUnrestrictedNumber(value) {
          return +value
        }

        function toCornerPoint(value) {
          const asNumber = toUnrestrictedNumber(value)
          if (Number.isFinite(asNumber)) {
            return {
              x: asNumber,
              y: asNumber
            }
          }
          if (Object(value) === value) {
            return {
              x: toUnrestrictedNumber(value.x ?? 0),
              y: toUnrestrictedNumber(value.y ?? 0)
            }
          }

          return {
            x: NaN,
            y: NaN
          }
        }

        function fixOverlappingCorners(corners) {
          const [upperLeft, upperRight, lowerRight, lowerLeft] = corners
          const factors = [
            Math.abs(w) / (upperLeft.x + upperRight.x),
            Math.abs(h) / (upperRight.y + lowerRight.y),
            Math.abs(w) / (lowerRight.x + lowerLeft.x),
            Math.abs(h) / (upperLeft.y + lowerLeft.y)
          ]
          const minFactor = Math.min(...factors)
          if (minFactor <= 1) {
            for (const radii of corners) {
              radii.x *= minFactor
              radii.y *= minFactor
            }
          }
        }
      }
    }

    return this.ctx
  }
}

export class CanvasPainter {
  constructor(context2d, canvasLayer) {
    this.ctx = new CtxWrapper(context2d).getCtx()
    this.canvasLayer = canvasLayer
  }

  get instance() {
    return new CanvasPainterFn(this.ctx, this.canvasLayer)
  }
}

function CanvasPainterFn(context2d, canvasLayer) {
  var that = this
  var ctx = context2d

  var m_pen = { color: 'black' }
  var m_font = { 'pixel-size': 12, family: 'Arial' }
  var m_brush = { color: 'black' }
  let m_exporting_figure = false

  const transformCoords = new TransformCoords(canvasLayer)
  const { coordsToPix, transformXYWH, transformXY, transformRect } =
    transformCoords

  this.pen = function () {
    return shallowClone(m_pen)
  }
  this.setPen = function (pen) {
    setPen(pen)
  }
  this.font = function () {
    return shallowClone(m_font)
  }
  this.setFont = function (font) {
    setFont(font)
  }
  this.brush = function () {
    return shallowClone(m_brush)
  }
  this.setBrush = function (brush) {
    setBrush(brush)
  }
  this.useCoords = function () {
    transformCoords.useCoords(true)
  }
  this.usePixels = function () {
    transformCoords.useCoords(false)
  }
  this.newPainterPath = function () {
    return new PainterPath()
  }

  this.setExportingFigure = function (val) {
    m_exporting_figure = val
  }
  this.exportingFigure = function () {
    return m_exporting_figure
  }

  this._initialize = function (W, H) {
    //ctx.fillStyle='black';
    //ctx.fillRect(0,0,W,H);
    // m_width = W;
    // m_height = H;
  }
  this._finalize = function () {}
  this.clear = function () {
    return _clearRect(0, 0, canvasLayer.width(), canvasLayer.height())
  }
  this.ctxSave = function () {
    ctx.save()
  }
  this.ctxRestore = function () {
    ctx.restore()
  }
  this.ctxTranslate = function (dx, dy) {
    if (dy === undefined) {
      let tmp = dx
      dx = tmp[0]
      dy = tmp[1]
    }
    ctx.translate(dx, dy)
  }
  this.coordsToPix = function (x, y) {
    return coordsToPix(x, y)
  }
  this.ctxRotate = function (theta) {
    ctx.rotate(theta)
  }
  this.clearRect = function (x, y, W, H) {
    this.fillRect(x, y, W, H, { color: 'transparent' })
    return

    // if (typeof(x) === 'object') {
    //     let a = x;
    //     x = a[0];
    //     y = a[1];
    //     W = a[2];
    //     H = a[3];
    // }
    // let a = transformXYWH(x, y, W, H);
    // return _clearRect(a[0], a[1], a[2], a[3]);
  }
  function _clearRect(x, y, W, H) {
    ctx.clearRect(x, y, W, H)
  }
  this.fillRect2 = function (x, y, W, H, brush) {
    let pt1 = transformCoords.transformX(x)
    let pt2 = transformCoords.transformX(x + W)
    const a = [Math.min(pt1, pt2), y, Math.abs(pt2 - pt1), H]

    return _fillRect(a[0], a[1], 2, a[3], brush)
  }

  this.setEventLabel = function (x, y, W, H, brush, label) {
    if (typeof x === 'object') {
      let a = x
      brush = y
      label = W
      x = a[0]
      y = a[1]
      W = a[2]
      H = a[3]
    }

    let a = transformXYWH(x, y, W, H)
    return _fillLabel(a[0], a[1], a[2], a[3], brush, label)
  }

  function _fillLabel(x, y, W, H, brush, label) {
    if (typeof brush === 'string') brush = { color: brush }
    if (!('color' in brush)) brush = { color: brush }

    var element = document.createElement('div')
    element.innerHTML = `<p>${label}</p>`
    element.setAttribute(
      'style',
      `
        background-color:${brush.color};
        font-size:16px;
        text-align: center;
        color: #fff;
        width: fit-content;
        padding: 0px 16px;
        border-radius: 20px;
        font-weight: 600;
        visibility: hidden;
      `
    )
    // position: absolute;
    element.setAttribute('id', label)
    document.body.appendChild(element)

    const { height, width } = element.getBoundingClientRect() || {}
    const posX = x - width / 2
    const padding = 16

    // background
    ctx.fillStyle = to_color(brush.color)
    ctx.roundRect(posX, 0, width, height, 20)

    ctx.fill()

    // title
    ctx.beginPath()
    ctx.fillStyle = to_color('#fff')
    ctx.font = '16px Poppins'
    ctx.fillText(label, posX + padding, padding + 1)
    ctx.fill()

    document.body.removeChild(element)
  }

  this.setDataManipulationPen = function (x, y, W, H, brush) {
    if (typeof x === 'object') {
      let a = x
      brush = y
      x = a[0]
      y = a[1]
      W = a[2]
      H = a[3]
    }
    let a = transformXYWH(x, y, W, H)
    return _fillTimeLine(a[0], a[1], a[2], a[3], brush)
  }

  function _fillTimeLine(x, y, W, H, brush) {
    if (typeof brush === 'string') brush = { color: brush }
    if (!('color' in brush)) brush = { color: brush }

    ctx.fillStyle = to_color(brush.color)
    ctx.fillRect(x, y, W, H)

    // arrow
    ctx.beginPath()
    ctx.fillStyle = to_color(brush.color)
    ctx.arrow(x + 1, y, x + 1, 40, [0, 7, -10, 7])
    ctx.fill()
  }

  this.fillRect = function (x, y, W, H, brush) {
    if (typeof x === 'object') {
      let a = x
      brush = y
      x = a[0]
      y = a[1]
      W = a[2]
      H = a[3]
    }
    let a = transformXYWH(x, y, W, H)
    return _fillRect(a[0], a[1], a[2], a[3], brush)
  }
  function _fillRect(x, y, W, H, brush) {
    if (typeof x === 'object') {
      let rect2 = x
      let brush2 = y
      that.fillRect(rect2[0], rect2[1], rect2[2], rect2[3], brush2)
      return
    }
    if (typeof brush === 'string') brush = { color: brush }
    if (!('color' in brush)) brush = { color: brush }
    ctx.fillStyle = to_color(brush.color)
    ctx.fillRect(x, y, W, H)
  }

  this.drawRect = function (x, y, W, H) {
    if (typeof x === 'object') {
      let a = x
      x = a[0]
      y = a[1]
      W = a[2]
      H = a[3]
    }
    let a = transformXYWH(x, y, W, H)
    return _drawRect(a[0], a[1], a[2], a[3])
  }
  function _drawRect(x, y, W, H) {
    apply_pen(ctx, m_pen)
    ctx.strokeRect(x, y, W, H)
  }

  this.drawPath = function (painter_path) {
    apply_pen(ctx, m_pen)
    painter_path?._draw && painter_path._draw(ctx, transformXY)
    that.path = painter_path
  }
  this.drawLine = function (x1, y1, x2, y2) {
    var ppath = new PainterPath()
    ppath.moveTo(x1, y1)
    ppath.lineTo(x2, y2)
    that.drawPath(ppath)
  }
  this.drawText = function (rect, alignment, txt, opts) {
    let rect2 = transformRect(rect)
    return _drawText(rect2, alignment, txt)
  }
  this.drawText2 = function (txt, options) {
    ctx.font = options?.fontSize ?? m_font['pixel-size'] + 'px ' + m_font.family
    apply_pen(ctx, m_pen)
    ctx.fillStyle = options.color ?? to_color(m_brush.color)
    const text = txt.length > 8 ? txt.substring(0, 7) + '...' : txt
    ctx.fillText(text, options.x, options.y)
  }
  this.createImageData = function (W, H) {
    return ctx.getImageData(W, H)
  }
  this.putImageData = function (imagedata, x, y) {
    ctx.putImageData(imagedata, x, y)
  }
  this.drawImage = function (image, dx, dy) {
    ctx.drawImage(image, dx, dy)
  }
  function _drawText(rect, alignment, txt) {
    var x, y, textAlign, textBaseline
    if (alignment.AlignLeft) {
      x = rect[0]
      textAlign = 'left'
    } else if (alignment.AlignCenter) {
      x = rect[0] + rect[2] / 2
      textAlign = 'center'
    } else if (alignment.AlignRight) {
      x = rect[0] + rect[2]
      textAlign = 'right'
    } else {
      console.error(
        'Missing horizontal alignment in drawText: AlignLeft, AlignCenter, or AlignRight'
      )
    }

    if (alignment.AlignTop) {
      y = rect[1]
      textBaseline = 'top'
    } else if (alignment.AlignBottom) {
      y = rect[1] + rect[3]
      textBaseline = 'bottom'
    } else if (alignment.AlignVCenter) {
      y = rect[1] + rect[3] / 2
      textBaseline = 'middle'
    } else {
      console.error(
        'Missing vertical alignment in drawText: AlignTop, AlignBottom, or AlignVCenter'
      )
    }

    ctx.font = m_font['pixel-size'] + 'px ' + m_font.family
    ctx.textAlign = textAlign
    ctx.textBaseline = textBaseline
    apply_pen(ctx, m_pen)
    ctx.fillStyle = to_color(m_brush.color)
    ctx.fillText(txt, x, y)
  }
  this.drawMarker = function (x, y, radius, shape, opts) {
    opts = opts || {}
    let pt = transformXY(x, y)
    _drawMarker(pt[0], pt[1], radius, shape, opts)
  }
  function _drawMarker(x, y, radius, shape, opts) {
    shape = shape || 'circle'
    let rect = [x - radius, y - radius, 2 * radius, 2 * radius]
    if (shape === 'circle') {
      if (opts.fill) {
        _fillEllipse(rect)
      } else {
        _drawEllipse(rect)
      }
    } else {
      console.error(`Unrecognized marker shape ${shape}`)
    }
  }
  this.fillMarker = function (x, y, radius, shape) {
    let pt = transformXY(x, y)
    _drawMarker(pt[0], pt[1], radius, shape, { fill: true })
  }
  this.drawEllipse = function (rect) {
    let rect2 = transformRect(rect)
    return _drawEllipse(rect2)
  }
  function _drawEllipse(rect) {
    apply_pen(ctx, m_pen)
    ctx.beginPath()
    ctx.ellipse(
      rect[0] + rect[2] / 2,
      rect[1] + rect[3] / 2,
      rect[2] / 2,
      rect[3] / 2,
      0,
      0,
      2 * Math.PI
    )
    ctx.stroke()
  }
  this.fillEllipse = function (rect, brush) {
    let rect2 = transformRect(rect)
    return _fillEllipse(rect2, brush)
  }
  function _fillEllipse(rect, brush) {
    if (brush) {
      if (typeof brush === 'string') brush = { color: brush }
      if (!('color' in brush)) brush = { color: brush }
      ctx.fillStyle = to_color(brush.color)
    } else {
      ctx.fillStyle = to_color(m_brush.color)
    }
    ctx.beginPath()
    ctx.ellipse(
      rect[0] + rect[2] / 2,
      rect[1] + rect[3] / 2,
      rect[2] / 2,
      rect[3] / 2,
      0,
      0,
      2 * Math.PI
    )
    ctx.fill()
  }

  function setPen(pen) {
    m_pen = shallowClone(pen)
  }

  function setFont(font) {
    m_font = shallowClone(font)
  }

  function setBrush(brush) {
    m_brush = shallowClone(brush)
  }

  function to_color(col) {
    if (typeof col === 'string') return col
    return (
      'rgb(' +
      Math.floor(col[0]) +
      ',' +
      Math.floor(col[1]) +
      ',' +
      Math.floor(col[2]) +
      ')'
    )
  }

  function apply_pen(context, pen) {
    if ('color' in pen) context.strokeStyle = to_color(pen.color)
    else context.strokeStyle = 'black'
    if ('width' in pen) context.lineWidth = pen.width
    if ('dashed' in pen) context.setLineDash([10, 10])
    else {
      context.setLineDash([])
      context.lineWidth = 1
    }
  }
}

export function PainterPath() {
  this.path = new Path2D()
  this.moveTo = function (x, y) {
    moveTo(x, y)
  }
  this.lineTo = function (x, y) {
    lineTo(x, y)
  }

  this._draw = function (ctx, transformXY) {
    for (var i = 0; i < m_actions.length; i++) {
      apply_action(this.path, m_actions[i], transformXY)
    }
    ctx.stroke(this.path, 'nonzero')
  }
  var m_actions = []

  function moveTo(x, y) {
    if (y === undefined) {
      moveTo(x[0], x[1])
      return
    }
    m_actions.push({
      name: 'moveTo',
      x: x,
      y: y
    })
  }
  function lineTo(x, y) {
    if (m_actions.length === 0) {
      moveTo(x, y)
      return
    }
    if (y === undefined) {
      this.path.lineTo(x[0], x[1])
      return
    }
    m_actions.push({
      name: 'lineTo',
      x: x,
      y: y
    })
  }

  function apply_action(ctx, a, transformXY) {
    let pos
    if (transformXY) {
      pos = transformXY(a.x, a.y)
    } else {
      pos = [a.x, a.y]
    }
    if (a.name === 'moveTo') {
      ctx.moveTo(pos[0], pos[1])
    } else if (a.name === 'lineTo') {
      ctx.lineTo(pos[0], pos[1])
    }
  }
}

export function MouseHandler() {
  this.setElement = function (elmt) {
    m_element = elmt
  }
  this.onMousePress = function (handler) {
    m_handlers['press'].push(handler)
  }
  this.onMouseRelease = function (handler) {
    m_handlers['release'].push(handler)
  }
  this.onMouseMove = function (handler) {
    m_handlers['move'].push(handler)
  }
  this.onMouseEnter = function (handler) {
    m_handlers['enter'].push(handler)
  }
  this.onMouseLeave = function (handler) {
    m_handlers['leave'].push(handler)
  }
  this.onMouseWheel = function (handler) {
    m_handlers['wheel'].push(handler)
  }
  this.onMouseDrag = function (handler) {
    m_handlers['drag'].push(handler)
  }
  this.onMouseDragRelease = function (handler) {
    m_handlers['drag_release'].push(handler)
  }

  this.mouseDown = function (e) {
    report('press', mouse_event(e))
    return true
  }
  this.mouseUp = function (e) {
    report('release', mouse_event(e))
    return true
  }
  this.mouseMove = function (e) {
    report('move', mouse_event(e))
    return true
  }
  this.mouseEnter = function (e) {
    report('enter', mouse_event(e))
    return true
  }
  this.mouseLeave = function (e) {
    report('leave', mouse_event(e))
    return true
  }
  this.mouseWheel = function (e) {
    report('wheel', wheel_event(e))
    return true
  }
  // elmt.on('dragstart',function() {return false;});
  // elmt.on('mousewheel', function(e){report('wheel',wheel_event($(this),e)); return false;});

  let m_element = null
  let m_handlers = {
    press: [],
    release: [],
    move: [],
    enter: [],
    leave: [],
    wheel: [],
    drag: [],
    drag_release: []
  }
  let m_dragging = false
  let m_drag_anchor = null
  let m_drag_pos = null
  let m_drag_rect = null
  let m_last_report_drag = new Date()
  let m_scheduled_report_drag_X = null

  function report(name, X) {
    if (name === 'drag') {
      let elapsed = new Date() - m_last_report_drag
      if (elapsed < 50) {
        schedule_report_drag(X, 50 - elapsed + 10)
        return
      }
      m_last_report_drag = new Date()
    }
    for (let i in m_handlers[name]) {
      m_handlers[name][i](X)
    }
    drag_functionality(name, X)
  }

  function schedule_report_drag(X, timeout) {
    if (m_scheduled_report_drag_X) {
      m_scheduled_report_drag_X = X
      return
    } else {
      m_scheduled_report_drag_X = X
      setTimeout(() => {
        let X2 = m_scheduled_report_drag_X
        m_scheduled_report_drag_X = null
        report('drag', X2)
      }, timeout)
    }
  }

  function drag_functionality(name, X) {
    if (name === 'press') {
      m_dragging = false
      m_drag_anchor = cloneSimpleArray(X.pos)
      m_drag_pos = null
    } else if (name === 'release') {
      if (m_dragging) {
        report('drag_release', {
          anchor: cloneSimpleArray(m_drag_anchor),
          pos: cloneSimpleArray(m_drag_pos),
          rect: cloneSimpleArray(m_drag_rect),
          ctx: X.ctx
        })
      }
      m_dragging = false
    }
    if (name === 'move' && X.buttons === 1) {
      // move with left button
      if (m_dragging) {
        m_drag_pos = cloneSimpleArray(X.pos)
      } else {
        if (!m_drag_anchor) {
          m_drag_anchor = cloneSimpleArray(X.pos)
        }
        const tol = 4
        if (
          Math.abs(X.pos[0] - m_drag_anchor[0]) > tol ||
          Math.abs(X.pos[1] - m_drag_anchor[1]) > tol
        ) {
          m_dragging = true
          m_drag_pos = cloneSimpleArray(X.pos)
        }
      }
      if (m_dragging) {
        m_drag_rect = [
          Math.min(m_drag_anchor[0], m_drag_pos[0]),
          Math.min(m_drag_anchor[1], m_drag_pos[1]),
          Math.abs(m_drag_pos[0] - m_drag_anchor[0]),
          Math.abs(m_drag_pos[1] - m_drag_anchor[1])
        ]
        report('drag', {
          anchor: cloneSimpleArray(m_drag_anchor),
          pos: cloneSimpleArray(m_drag_pos),
          rect: cloneSimpleArray(m_drag_rect),
          ctx: X.ctx
        })
      }
    }
  }

  function mouse_event(e) {
    if (!m_element) return {}
    //var parentOffset = $(this).parent().offset();
    //var offset=m_element.offset(); //if you really just want the current element's offset
    var rect = e.target.getBoundingClientRect()
    window.m_element = m_element
    window.dbg_m_element = m_element
    window.dbg_e = e
    var posx = e.clientX - rect.x
    var posy = e.clientY - rect.y
    return {
      rect,
      pos: [posx, posy],
      modifiers: { ctrlKey: e.ctrlKey, shiftKey: e.shiftKey },
      buttons: e.buttons,
      ctx: e.target.getContext('2d')
    }
  }
  function wheel_event(e) {
    return {
      delta: e.originalEvent.wheelDelta
    }
  }
}

// function clone(obj) {
//     return JSON.parse(JSON.stringify(obj));
// }

function shallowClone(obj) {
  return Object.assign({}, obj)
}

function cloneSimpleArray(x) {
  return x.slice(0)
}
