import { useRef } from 'react'
import Plotly from 'plotly.js-gl2d-dist-min'
import {
  pairs,
  axis,
  buildBounds,
  incrementAxes,
  getBounds,
  M,
  OFFSET,
  SUBPLOT_OFFSET,
  typeScatter
} from '../helpers'

const layout = (
  W = window.innerWidth - 10,
  H = window.innerHeight - 35,
  darkMode
) => {
  return {
    paper_bgcolor: darkMode ? '#000' : '#fff',
    plot_bgcolor: darkMode ? '#000' : '#fff',
    showlegend: false,
    height: H,
    width: W,
    xaxis: {
      ...axis()
      // autorange: 'reversed',

      // range: [-1, 1],
    },
    yaxis: {
      ...axis(),
      // autorange: 'reversed',
      scaleanchor: 'x',
      scaleratio: 1
    },
    dragmode: 'pan',
    autosize: false,
    hovermode: false,
    margin: {
      autoexpand: false,
      l: 0,
      r: 0,
      t: 0
    }
  }
}

const buildSubplot = ([m, n]) => {
  const bounds = getBounds(m, n)

  const bottomLine = buildBounds([bounds.x0, bounds.x1], [bounds.y0, bounds.y0])
  const topLine = buildBounds([bounds.x0, bounds.x1], [bounds.y1, bounds.y1])
  const leftLine = buildBounds([bounds.x0, bounds.x0], [bounds.y0, bounds.y1])
  const rightLine = buildBounds([bounds.x1, bounds.x1], [bounds.y0, bounds.y1])
  const yAxis = buildBounds(
    [
      (bounds.x1 - bounds.x0) / 2 + incrementAxes(m),
      (bounds.x1 - bounds.x0) / 2 + incrementAxes(m)
    ],
    [bounds.y0, bounds.y1]
  )
  const xAxis = buildBounds(
    [bounds.x0, bounds.x1],
    [
      (bounds.y1 - bounds.y0) / 2 + incrementAxes(n),
      (bounds.y1 - bounds.y0) / 2 + incrementAxes(n)
    ]
  )

  return [bottomLine, topLine, leftLine, rightLine, yAxis, xAxis]
}

var axes = pairs(16)
  .map(buildSubplot)
  .reduce((acc, v) => acc.concat(v), [])

export const usePlot = (W, H, darkMode) => {
  const ann = useRef([])

  return {
    render: (elem, traces, args) => {
      Plotly.newPlot(elem, axes, layout(W, H, darkMode), {
        scrollZoom: true,
        displayModeBar: false,
        plotGlPixelRatio: 1
      })
    },
    update: (elem, data, channelLabels) => {
      Plotly.addTraces(elem, data ?? [])

      if (channelLabels) {
        const ch = channelLabels.map(x => buildAnnotation(x, darkMode))
        ann.current = ch

        Plotly.relayout(elem, {
          annotations: ch
        })
      }
    },
    removeTrace: (elem, data) => {
      Plotly.deleteTraces(elem, data)
      Plotly.relayout(elem, {
        annotations: []
      })
    },
    relayout: (elem, args) => {
      Plotly.relayout(elem, args)
    },
    restyle: (elem, args) => {
      const color = darkMode ? '#fff' : '#000'
      const backgorund = darkMode ? '#000' : '#fff'

      ann.current = ann.current.map(x => ({ ...x, font: { ...x.font, color } }))

      Plotly.relayout(elem, {
        paper_bgcolor: backgorund,
        plot_bgcolor: backgorund,
        annotations: ann.current,
        ...args
      })
    },
    deselect: elem => {
      Plotly.restyle(elem, 'selectedpoints', null)
      Plotly.restyle(elem, 'dragmode', 'pan')
    },
    registerEvent: (elem, event, cb) => {
      if (elem) {
        elem.on(event, cb)
      }
    }
  }
}

function buildAnnotation(channelLabel, darkMode) {
  return {
    text: `${channelLabel.ch}`,
    align: 'left',
    showarrow: false,
    x: (channelLabel.m - 1) * M + OFFSET * (channelLabel.m - 1) + 1.65,
    y: (channelLabel.n - 1) * M + OFFSET * (channelLabel.n - 1) + 0.2,
    font: {
      color: darkMode ? '#fff' : 'black',
      size: 10
    }
  }
}

export const buildFeatureViewFactory = ({
  xData = [],
  yData = [],
  m,
  n,
  plot,
  color
}) => {
  return {
    x: xData.map(x => x + (m - 1) * M + OFFSET * (m - 1) + SUBPLOT_OFFSET),
    y: yData.map(x => x + (n - 1) * M + OFFSET * (n - 1) + SUBPLOT_OFFSET),
    ...typeScatter,
    marker: { color, size: 4 },
    'data-type': 'data-point',
    dim: plot.ch,
    pos: [m - 1, n - 1]
  }
}

export function transformPoint(point,dimension) {
  return 
}