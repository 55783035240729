import { useCallback, useEffect, useState } from 'react'
import { deleteRecordByIdAndType } from '../../../endpoints/admin'
import { NOTIFICATION } from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { normalizeFormValues } from '../../../utils/utils'
import useGetAllRecords from './useGetAllRecords'
import isEqual from 'lodash/fp/isEqual'
import useStorageKeys from '../../../hooks/useStorageKeys'

function useFilesController() {
  const [isBulkSelect, setBulk] = useState(false)
  const [selected, setSelected] = useState([])
  const addNotification = useNotification()
  const { fetchRecords } = useGetAllRecords(false)
  const { resetStoragePageParams } = useStorageKeys()
  const query = window.location.search
  const hasPageParams = query.includes('page')

  const isSelected = (curation_id_passed, type) =>
    Array.isArray(selected) &&
    selected.some(item => item?.curation_id_passed === curation_id_passed && item?.type === type)

  const deselectAll = useCallback(() => setSelected([]), [])

  const handleSelect = useCallback(({ id, type, name, curation_id, curation_id_passed, name_passed }, isBulk = false) => {
    setBulk(isBulk)

    setSelected(prevState => {
      let newSelected
      if (!Array.isArray(prevState)) {
        newSelected = [{ id, type, name, curation_id, curation_id_passed, name_passed }]
      } else {
        const selected = prevState.find(i => i?.curation_id_passed === curation_id_passed && i?.type === type)
        if (!selected) {
          newSelected = [...prevState, { id, type, name, curation_id, curation_id_passed, name_passed }]
        } else {
          newSelected = prevState.filter(i => !isEqual(i, selected))
        }
      }
      return newSelected
    })
  }, [])


  const isExistingSelected = (id, type) =>
    Array.isArray(selected) &&
    selected.some(item => item?.id === id && item?.type === type)

  const handleExsistingSelect = useCallback(({ id, type, name, curation_id }, isBulk = false) => {
    setBulk(isBulk)

    setSelected(prevState => {
      let newSelected
      if (!Array.isArray(prevState)) {
        newSelected = [{ id, type, name, curation_id }]
      } else {
        const selected = prevState.find(i => i?.id === id && i?.type === type)
        if (!selected) {
          newSelected = [...prevState, { id, type, name, curation_id }]
        } else {
          newSelected = prevState.filter(i => !isEqual(i, selected))
        }
      }
      return newSelected
    })
  }, [])



  const handleDeleteRecord = useCallback(
    async ids => {
      try {
        const values = Array.isArray(ids) ? ids : [ids]
        const params = normalizeFormValues({ id: values })
        const { status, data } = await deleteRecordByIdAndType(params)
        if (status === 200) {
          const { permission_flag, permission_message } = data || {}
          deselectAll()
          if (!permission_flag) {
            const records = permission_message
              ? permission_message.map(item => item.name).join(', ')
              : null
            return addNotification({
              type: NOTIFICATION.ERROR,
              title: `You don't have permission to delete record${permission_message?.length > 1 ? 's' : ''
                }: ${records}`
            })
          }

          const success =
            values?.length > 1
              ? 'Rows deleted successfully'
              : 'Row deleted successfully'
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: success
          })
          fetchRecords()
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, deselectAll, fetchRecords]
  )

  useEffect(() => {
    if (!hasPageParams) {
      resetStoragePageParams()
    }
  }, [hasPageParams, resetStoragePageParams])

  return {
    selected,
    isSelected,
    setSelected,
    deselectAll,
    handleSelect,
    handleDeleteRecord,
    isBulkSelect,
    isExistingSelected,
    handleExsistingSelect
  }
}

export default useFilesController
