import React from 'react'
import { Typography, Grid, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Breadcrumb from '../../../Breadcrumb/Breadcrumb'
import { Link, useLocation, useParams } from 'react-router-dom'
import ActionButton from '../../../ActionButton'
import {
  Chat,
  GetApp,
  ImportExport,
  ShowChart,
  Visibility
} from '@material-ui/icons'
import NewSpikesortingModal from './components/NewSpikesortingModal'
import useQueryParams from '../../../../hooks/useQueryParams'
import { MODALS_ID, QPARAMS, ROUTES } from '../../../../utils/consts'
import CommentsDrawer from './components/CommentsDrawer'
import useComments from './hooks/useComments'
import Storage from '../../../AppHeader/components/Storage'
import { useSelector } from 'react-redux'
import useRecordingInfo from '../../hooks/useRecordingInfo'
import useToggleModal from '../../../../hooks/useToggleModal'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 28,
    marginTop: 1,
    fontWeight: 600,
    padding: '1px 0',
    lineHeight: '32px',
    color: theme.palette.colors.neutral.black
  },
  button: {
    marginLeft: 16,
    width: 'auto'
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 600
  },
  date: {
    fontSize: 14,
    color:
      theme.palette.type === 'dark'
        ? theme.palette.colors.white
        : theme.palette.colors.grey2
  },
  breadcrumb: {
    flex: 2,
    width: 100
  },
  actionBtns: {
    flex: 1
  }
}))

const RecordingHeader = props => {
  const { fileName, folderId, selectedSorting } = props
  const classes = useStyles()
  const theme = useTheme()
  const sortings = useSelector(state => state.recordingStore.sortings)
  const { recordingId } = useParams()
  const location = useLocation()
  const { getByName, setQueryParams } = useQueryParams()
  const page = getByName(QPARAMS.PAGE)
  const isSortings = location.pathname.includes('/sortings')
  const toSortings = `/recording/${recordingId}/sortings${setQueryParams(
    QPARAMS.PAGE,
    page
  )}`
  const { isFileUploaded } = useRecordingInfo()

  const filesPage = Storage.getItem(QPARAMS.PAGE)
  const keyword = Storage.getItem(QPARAMS.KEYWORD)

  const toFiles = `${ROUTES.FILES}${setQueryParams([
    { key: QPARAMS.FOLDER_ID, value: folderId },
    { key: QPARAMS.PAGE, value: filesPage },
    { key: QPARAMS.KEYWORD, value: keyword }
  ])}`

  const { toggleDrawer: toggleCommentsDrawer, ...restOfComments } =
    useComments()

  const modalID = MODALS_ID.NEW_SPIKESORTING_MODAL
  const { toggle: toggleSortingModal } = useToggleModal(modalID)

  const breadcrumb = [
    {
      label: 'Data',
      to: toFiles
    },
    (isSortings || selectedSorting) && {
      label: 'Sortings',
      to: toSortings,
      current: isSortings
    },
    !isSortings && {
      label: selectedSorting?.name || fileName,
      current: true
    }
  ]

  const showTimeSeries = !isSortings

  const { zip_link } = selectedSorting || {}

  return (
    <Grid container justify="space-between" alignItems="center" wrap="nowrap">
      <div className={classes.breadcrumb}>
        <Breadcrumb steps={breadcrumb} />
        <Typography noWrap className={classes.title}>
          Recording View
        </Typography>
      </div>

      <Grid
        container
        justify="flex-end"
        alignItems="center"
        wrap="nowrap"
        className={classes.actionBtns}
      >
        {showTimeSeries && isFileUploaded && (
          <ActionButton
            leftAdornment={<ShowChart />}
            label="Timeseries"
            component={Link}
            to={`/timeseriesForRecording/${recordingId}`}
            customStyles={{
              backgroundColor: theme.palette.action.secondary
            }}
            className={classes.button}
          />
        )}

        {zip_link && (
          <ActionButton
            leftAdornment={<GetApp />}
            label="Download"
            className={classes.button}
            href={zip_link}
            component="a"
            target="_blank"
          />
        )}

        {sortings?.length > 0 && !isSortings && (
          <ActionButton
            leftAdornment={<Visibility />}
            label="View Sortings"
            component={Link}
            to={toSortings}
            className={classes.button}
          />
        )}

        {isFileUploaded && (
          <ActionButton
            leftAdornment={<ImportExport />}
            label="New Spikesorting"
            onClick={toggleSortingModal}
            className={classes.button}
          />
        )}

        {selectedSorting && (
          <ActionButton
            leftAdornment={<Chat />}
            label="Comments"
            onClick={toggleCommentsDrawer}
            className={classes.button}
          />
        )}
      </Grid>

      <CommentsDrawer toggleDrawer={toggleCommentsDrawer} {...restOfComments} />

      <NewSpikesortingModal />
    </Grid>
  )
}

export default RecordingHeader
