import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import useGetAllRecords from '../FilesTable/hooks/useGetAllRecords'
import Header from './components/Header'
import FilesTable from '../FilesTable'
import useColumnFilters from './hooks/useColumnFilters'
import ColumnFiltersModal from './components/ColumnFiltersModal'
import ToursWrapper from '../ToursWrapper'
import useDownloadAppModal from './hooks/useDownloadAppModal'
import useTours from '../ToursWrapper/hooks/useTours'
import useToursController from './hooks/useToursController'
import AIOnboardingModal from './components/AIOnboardingModal'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    padding: '0 40px 60px',
    background: theme.palette.colors.backgrounds.files
  }
}))

const Files = () => {
  const classes = useStyles()
  const { loading } = useGetAllRecords()

  const {
    isOpened: isOpenedColumnModal,
    filteredColumns,
    allColumns,
    toggleModal: toggleColumnsModal,
    handleToggleCheckbox,
    handleApply
  } = useColumnFilters()

  const { toggleModal: toggleDownloadModal, isOpened } = useDownloadAppModal()

  const { nextTour } = useToursController(isOpened, toggleDownloadModal)

  const { tourState, callback, nextOrPrev } = useTours(nextTour)

  const handleToggleDownloadModal = () => {
    const { stepIndex } = tourState || {}
    toggleDownloadModal()

    setTimeout(() => nextOrPrev(stepIndex, false), 100)
  }

  return (
    <ToursWrapper callback={callback} tourState={tourState}>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        className={classes.container}
      >
        <ColumnFiltersModal
          isOpened={isOpenedColumnModal}
          allColumns={allColumns}
          toggleModal={toggleColumnsModal}
          handleApply={handleApply}
          handleToggleCheckbox={handleToggleCheckbox}
        />
        <Header
          toggleColumnsModal={toggleColumnsModal}
          isOpened={isOpened}
          toggleDownloadModal={handleToggleDownloadModal}
        />
        <FilesTable loading={loading} columns={filteredColumns} />
        <AIOnboardingModal />
      </Grid>
    </ToursWrapper>
  )
}

export default memo(Files)
