import React from 'react'

const FilterIcon = props => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="14"
        height="14"
      >
        <path
          d="M3.54157 4.67516C5.2249 6.8335 8.33324 10.8335 8.33324 10.8335V15.8335C8.33324 16.2918 8.70824 16.6668 9.16657 16.6668H10.8332C11.2916 16.6668 11.6666 16.2918 11.6666 15.8335V10.8335C11.6666 10.8335 14.7666 6.8335 16.4499 4.67516C16.8749 4.12516 16.4832 3.3335 15.7916 3.3335H4.1999C3.50824 3.3335 3.11657 4.12516 3.54157 4.67516Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect width="20" height="20" fill="white" />
      </g>
    </svg>
  )
}

export default FilterIcon
