import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import CustomInput from '../FormComponents/CustomInput/CustomInput'
import { Formik } from 'formik'
import useChangePassword from './hooks/useChangePassword'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined'
import WatcherChanges from '../FormComponents/WatcherChanges'
import { isEqual } from '../../utils/utils'

const useStyles = makeStyles(() => ({
  form: {
    width: '100%'
  }
}))

const ChangePassword = () => {
  const classes = useStyles()
  const { initialValues, onSubmit, validation } = useChangePassword()
  const [passwordType, setPasswordType] = useState({
    old_password: 'password',
    new_password: 'password',
    confirm_password: 'password'
  })

  const setPassType = key => {
    setPasswordType(current => ({
      ...current,
      [key]: current[key] === 'password' ? 'text' : 'password'
    }))
  }

  const IconType = useCallback(
    key => (
      <IconButton onClick={() => setPassType(key)}>
        {passwordType[key] === 'password' ? (
          <VisibilityIcon />
        ) : (
          <VisibilityOffIcon />
        )}
      </IconButton>
    ),
    [passwordType]
  )

  return (
    <Grid container className={classes.root}>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values, actions) => onSubmit(values, actions)}
      >
        {({
          initialValues,
          isSubmitting,
          values: { confirm_password, new_password, old_password },
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          resetForm
        }) => {
          const hasChanges =
            !isEqual(confirm_password, initialValues.confirm_password) ||
            !isEqual(new_password, initialValues.new_password) ||
            !isEqual(old_password, initialValues.old_password)
          return (
            <form className={classes.form}>
              <CustomInput
                label="Current Password"
                name="old_password"
                type={passwordType['old_password']}
                fullWidth
                value={old_password}
                error={errors}
                touched={touched}
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={IconType('old_password')}
              />
              <CustomInput
                label="New Password"
                name="new_password"
                type={passwordType['new_password']}
                hint="8 characters or longer. At least one number and one symbol (like !@#$%^)"
                fullWidth
                value={new_password}
                error={errors}
                touched={touched}
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={IconType('new_password')}
              />
              <CustomInput
                label="Repeat password"
                name="confirm_password"
                type={passwordType['confirm_password']}
                fullWidth
                value={confirm_password}
                error={errors}
                touched={touched}
                onBlur={handleBlur}
                onChange={handleChange}
                endAdornment={IconType('confirm_password')}
              />
              {hasChanges && (
                <WatcherChanges
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                  resetForm={resetForm}
                />
              )}
            </form>
          )
        }}
      </Formik>
    </Grid>
  )
}

export default ChangePassword
