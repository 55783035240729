import React, { createRef, memo, useEffect } from 'react'
import {
  DTYPES,
  MODALS_ID,
  PARAMETER_OPTIONS,
  UPLOAD_PRBFILE_METHODS
} from '../../../../utils/consts'
import { Formik } from 'formik'
import CustomModal from '../../../CustomModal'
import ActionButton from '../../../ActionButton'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomInput from '../../../FormComponents/CustomInput/CustomInput'
import CheckboxesGroup from '../../../CheckboxesGroup'
import SelectMenu from '../../../SelectMenu'
import { getAllSpecies } from '../../../../endpoints/admin'
import { useSelector } from 'react-redux'
import CustomTag from '../../../CustomTag'
import useApiResult from '../../../../hooks/useApiResult'
import UploadFile from './components/UploadFile'
import useUploadExperimentsInputModal from './hooks/useUploadExperimentsInputModal'
import isEmpty from 'lodash/fp/isEmpty'
import { Search } from '@material-ui/icons'
import useToggleModal from '../../../../hooks/useToggleModal'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 18,
    lineHeight: '20px',
    fontWeight: 600,
    color: theme.palette.colors.neutral.black
  },
  section: {
    padding: '24px 0',
    borderBottom: `1px solid ${theme.palette.colors.neutral[7]}`,
    '&:nth-child(2)': {
      paddingTop: 0
    },
    '&:nth-child(4)': {
      paddingBottom: 0,
      borderBottom: 'none'
    }
  },
  radiosSection: {
    margin: '24px 0'
  },

  tagsRoot: {
    border: 'none',
    padding: 0
  },
  inputsContainer: {
    margin: '24px 0'
  },
  selectPrbFileBtn: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    color: theme.palette.colors.accent[5],
    textTransform: 'initial'
  },
  selectPrbFileBtnText: {
    fontSize: 13,
    marginLeft: 8,
    fontWeight: 600,
    lineHeight: '20px',
    textDecoration: 'underline'
  }
}))

const UploadExperimentsInputModal = ({
  onClose,
  oldValues,
  onEdit,
  onSuccess
}) => {
  const classes = useStyles()
  const formRef = createRef(null)
  const {
    loading,
    isOpened,
    toggleModal,
    handleSubmit,
    initialValues,
    validation,

    // parameters
    parametersOption,
    parametersOptions,
    handleChangeOption,
    parseFile,
    parametersFile,
    resetParams,

    // probe file
    uploadProbeOption,
    uploadProbeOptions
  } = useUploadExperimentsInputModal({ oldValues, onEdit, onSuccess })

  const modalID = MODALS_ID.SELECT_DEFAULT_PRB_FILE_MODAL
  const { toggle: togglePrbFileModal } = useToggleModal(modalID)

  const useSpecies = () => useApiResult(getAllSpecies)

  const { companies } = useSelector(state => state.login.currentUser)

  const companiesSelect = companies
    ? companies?.map(({ id, name }) => ({
        label: name,
        value: id
      }))
    : []

  useEffect(() => {
    const { resetForm, isSubmitting } = formRef?.current || {}
    if (!isOpened && resetForm && !isSubmitting) {
      resetForm()
      resetParams()
    }
  }, [isOpened, formRef])

  return (
    <Formik
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validation}
      innerRef={formRef}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
    >
      {({
        values,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues,
        handleBlur,
        errors
      }) => {
        const {
          company_id,
          name,
          sample_rate,
          number_channels,
          prb_file,
          species_id,
          dtype,
          tags
        } = values

        const inputProps = {
          errors,
          touched,
          handleBlur,
          handleChange
        }

        const setPrbFile = val => {
          val?.probeId && setFieldValue('probe_id', val?.probeId)
          setFieldValue('prb_file', val?.name ?? val)
        }

        const selectedCompany =
          companies?.find(({ id }) => id === company_id)?.name ?? ''

        return (
          <CustomModal
            open={isOpened}
            title={`${oldValues ? 'Edit' : 'New'} Value`}
            onClose={() => {
              onClose()
              toggleModal()
            }}
            maxWidth={740}
            customBodyClass={classes.body}
            renderActionButton={
              <ActionButton
                label="Save"
                onClick={handleSubmit}
                loading={loading}
              />
            }
          >
            <Input
              required
              label="Name"
              name="name"
              value={name}
              {...inputProps}
            />

            <Section title="Parameters">
              <CheckboxesGroup
                value={parametersOption}
                handleChange={e => {
                  if (!oldValues) resetParams(setValues)
                  handleChangeOption(e, 'params')
                }}
                radios={parametersOptions}
                className={classes.radiosSection}
              />

              {parametersOption === PARAMETER_OPTIONS.FILE ? (
                <UploadFile
                  name="parametersFile"
                  file={parametersFile}
                  accept={['.xml', '.info']}
                  onUpload={file => parseFile(file, setValues)}
                />
              ) : (
                <React.Fragment>
                  <Input
                    label="Number of channels"
                    name="number_channels"
                    value={number_channels}
                    {...inputProps}
                  />
                  <Input
                    label="Sampling Rate (Hz)"
                    name="sample_rate"
                    value={sample_rate}
                    {...inputProps}
                  />
                </React.Fragment>
              )}
            </Section>

            <Section title="Probe file">
              <CheckboxesGroup
                value={uploadProbeOption}
                handleChange={e => {
                  if (!oldValues) setPrbFile(null)
                  handleChangeOption(e, 'probe')
                }}
                radios={uploadProbeOptions}
                className={classes.radiosSection}
              />

              {uploadProbeOption === UPLOAD_PRBFILE_METHODS.DEFAULT ? (
                <>
                  {prb_file ? (
                    <UploadFile
                      preview
                      name="prb_file"
                      onUpload={setPrbFile}
                      file={prb_file}
                    />
                  ) : (
                    <div
                      onClick={() =>
                        togglePrbFileModal({
                          onSubmit: setPrbFile
                        })
                      }
                      className={classes.selectPrbFileBtn}
                    >
                      <Search />
                      <Typography className={classes.selectPrbFileBtnText}>
                        Select a default DBC Probe file
                      </Typography>
                    </div>
                  )}
                </>
              ) : uploadProbeOption === UPLOAD_PRBFILE_METHODS.CUSTOM ? (
                <UploadFile
                  name="prb_file"
                  file={prb_file}
                  accept={['.json', '.prb']}
                  onUpload={setPrbFile}
                />
              ) : null}
            </Section>

            <Section title="Other">
              <SelectMenu
                onChangeValue={val => setFieldValue('company_id', val)}
                items={companiesSelect}
                value={selectedCompany}
                classes={{
                  container: classes.inputsContainer
                }}
                textFieldProps={{
                  variant: 'outlined',
                  label: 'Organisation'
                }}
              />

              <CustomTag
                tags={tags}
                label="Tags"
                required={false}
                updateTags={val => setFieldValue('tags', val)}
                classes={{
                  root: classes.tagsRoot,
                  container: classes.inputsContainer
                }}
              />

              <Select
                setFieldValue={setFieldValue}
                value={species_id}
                name="species_id"
                query={useSpecies}
                label="Animal Species"
              />

              <SelectMenu
                onChangeValue={val => setFieldValue('dtype', val)}
                items={DTYPES}
                value={dtype}
                classes={{
                  container: classes.inputsContainer
                }}
                placement="top"
                textFieldProps={{
                  variant: 'outlined',
                  label: 'Dtype'
                }}
              />
            </Section>
          </CustomModal>
        )
      }}
    </Formik>
  )
}

const Section = ({ children, title }) => {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </div>
  )
}

const Select = ({
  setFieldValue,
  value,
  name,
  query,
  label,
  required = false
}) => {
  const classes = useStyles()

  const { startFetch, state } = query()

  const onChange = val => setFieldValue(name, val)

  const items = state.data?.map((item, index) => ({
    label: item,
    value: index
  }))

  const selected = items?.find(
    ({ value: v, label: l }) => v === value || l === value
  )
  const { label: selectedLabel, value: selectedValue } = selected || {}

  // when I edit this exp input I receive name of specie of brain region
  // and I have to set id instead of name, because is required id
  useEffect(() => {
    if (typeof value === 'string' && !isNaN(selectedValue))
      onChange(selectedValue)
    // eslint-disable-next-line
  }, [value, selectedValue])

  useEffect(() => {
    if (isEmpty(state.data)) startFetch()

    // eslint-disable-next-line
  }, [state.data])

  return (
    <SelectMenu
      items={items}
      onChangeValue={onChange}
      textFieldProps={{
        label,
        required,
        variant: 'outlined'
      }}
      placement="top"
      value={selectedLabel}
      classes={{
        container: classes.inputsContainer
      }}
    />
  )
}

const Input = memo(({ errors, handleBlur, handleChange, ...props }) => (
  <CustomInput
    fullWidth
    error={errors}
    onBlur={handleBlur}
    onChange={handleChange}
    {...props}
  />
))

export default memo(UploadExperimentsInputModal)
