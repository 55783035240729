import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useCallback, useMemo, useState } from 'react'
import {
  changeTimestamp,
  deleteTimestamp,
  navigateEvents,
  selectEvent,
  storeEvents,
  toggleAllEventsVisibility
} from '../../../redux/actions'
import { useFetchResult } from '../../../../../../hooks/useFetchResult'
import { editEvents, getEvents } from '../../../../../../endpoints/admin'
import { MODALS_ID, NOTIFICATION } from '../../../../../../utils/consts'
import useNotification from '../../../../../SnackbarManager/hooks/useNotification'
import { mv_default_channel_colors } from '../../../../../../containers/NewTimeSeries/utils'
import useToggleModal from '../../../../../../hooks/useToggleModal'

function useEventsController() {
  const dispatch = useDispatch()
  const addNotification = useNotification()
  const defaultColors = mv_default_channel_colors()
  const [loading, setTSLoading] = useState(false)

  // actions
  const store = useCallback(
    ({ data }) => dispatch(storeEvents(data)),
    [dispatch]
  )
  const select = useCallback(data => dispatch(selectEvent(data)), [dispatch])
  const change = useCallback(
    data => dispatch(changeTimestamp(data)),
    [dispatch]
  )
  const deleteTime = useCallback(() => dispatch(deleteTimestamp()), [dispatch])

  // states
  const {
    hasPrev,
    hasNext,
    isShowAllEvents,
    hiddenEventsSection: hiddenEvents,
    events: allEvents,
    selectedEvent,
    timeseriesInfo
  } = useSelector(state => state.timeSeriesLeftPanel, shallowEqual)

  const { toggle: toggleTimestampDeleteModal, isOpened } = useToggleModal(
    MODALS_ID.DELETE_TIMESTAMP_CONFIRMATION_MODAL
  )

  const groupedEvents = useMemo(() => {
    let eventsList = []
    Array.from(allEvents).forEach(([k, event], index) => {
      const eventFile = eventsList.find(x => x.id === event.id)
      if (!eventFile) {
        return eventsList.push({
          id: event.id,
          name: event.eventName,
          color: defaultColors[index % defaultColors.length],
          events: [event]
        })
      }
      return eventFile.events.push(event)
    })
    return eventsList
  }, [allEvents, defaultColors])

  const allTimestamps = Array.from(allEvents).reduce((acc, current) => {
    const [_, item] = current
    const items = item?.timestamps.map(t => ({
      ...item,

      // here is sampled timestamp and toHumanTime
      ...t,
      isVisible: !hiddenEvents.get(item.uniq_key)
    }))

    return [...acc, ...items]
  }, [])

  // console.log('allTimestamps', { allTimestamps, allEvents })

  const recording = useSelector(state => state.recordingStore.recording)
  const { state, fetchData } = useFetchResult(getEvents)
  const { fetchData: fetchEdit } = useFetchResult(editEvents)
  const { id: record_id } = recording || {}

  const fetchEvents = useCallback(
    () =>
      fetchData(store, {
        record_id
      }),
    [fetchData, record_id, store]
  )

  const handleEditEventsLabel = useCallback(
    async ({ item, onInit, onSuccess }) => {
      onInit()
      try {
        const { uniq_key, name, id } = item || {}
        const eventsOfLabel = allEvents.get(uniq_key)

        const data = eventsOfLabel.timestamps.reduce(
          (acc, current) => ({
            ...acc,
            [current.toHumanTime.toString()]: name
          }),
          {}
        )
        const res = await fetchEdit(null, {
          id: id,
          update_data: data
        })
        if (res?.status === 200) {
          // if user had selected event from this group,
          // we have to reset selected event after edit of key
          select(null)
          await fetchEvents()
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Edited successfully!'
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
      onSuccess()
    },
    [allEvents, select]
  )

  const handleDeleteEvent = useCallback(async () => {
    try {
      const { toHumanTime, name, id } = selectedEvent || {}
      deleteTime()
      addNotification({
        type: NOTIFICATION.SUCCESS,
        title: 'Deleted successfully!'
      })

      await fetchEdit(null, {
        id: id,
        remove: {
          [toHumanTime]: name
        }
      })
    } catch (e) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [deleteTime, selectedEvent])

  const handleEditTimestamp = useCallback(
    async new_time => {
      setTSLoading(true)
      try {
        const { toHumanTime, name, id } = selectedEvent || {}
        change(new_time)
        const res = await fetchEdit(null, {
          id,
          remove: {
            [toHumanTime]: name
          },
          new_data: {
            [new_time]: name
          }
        })
        if (res?.status === 200) {
          await fetchEvents()
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Replaced successfully!'
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
      setTSLoading(false)
    },
    [selectedEvent]
  )

  const next = useCallback(() => dispatch(navigateEvents('next')), [dispatch])
  const prev = useCallback(() => dispatch(navigateEvents('prev')), [dispatch])

  const toggleHideEventSwitch = useCallback(() => {
    dispatch(toggleAllEventsVisibility())
  }, [dispatch])

  return {
    fetchEvents,
    timeseriesInfo,
    state,
    allEvents,
    groupedEvents,
    allTimestamps,
    selectedEvent,
    select,
    next,
    prev,
    hasPrev,
    hasNext,
    loading,
    toggleHideEventSwitch,
    isShowAllEvents,
    toggleTimestampDeleteModal,
    isOpened,
    deleteEvent: handleDeleteEvent,
    editTimestamp: handleEditTimestamp,
    editEventsLabel: handleEditEventsLabel
  }
}

export default useEventsController
