import useTriggerKeyEvent from '../../../../../../../hooks/useTriggerKeyEvent'
import { useCallback } from 'react'

function useRowsSelection(action) {
  const { isCtrl } = useTriggerKeyEvent()

  return useCallback(
    e => {
      const { id: unit_id } = e?.rowData || {}
      if (unit_id) action({ unit_id: Number(unit_id), multiple: isCtrl })
    },
    [action, isCtrl]
  )
}

export default useRowsSelection
