import React from 'react'

const ChannelIcon = props => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.8929 2.27122L13.5609 1.13561L12.8929 0H11.5569L11.0191 0.914235L8.08329 2.67203L8.08328 2.67203L8.08329 2.67204V5.74486V5.74487H7.27059L5.64954 2.93922L2.17591 2.93922L0.439087 5.94525L2.17591 8.95128H5.64954L7.27056 6.14567H8.08329V6.27927V9.3521L10.9473 10.9228L10.8889 11.0221L11.5569 12.1577H12.8929L13.5609 11.0221L12.8929 9.8865H11.5569L11.1547 10.5703L8.48409 9.02844V6.27927V5.74486V2.83663L11.0262 1.36896L11.5569 2.27122L12.8929 2.27122ZM13.0265 6.07887L12.3585 7.21448H11.0225L10.3938 6.14567H8.4841V5.74487H10.551L11.0225 4.94326H12.3585L13.0265 6.07887Z"
      />
    </svg>
  )
}

export default ChannelIcon
