export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION'

export const addNotification = config => ({
  type: ADD_NOTIFICATION,
  payload: config
})

export const removeNotification = () => ({
  type: REMOVE_NOTIFICATION
})
