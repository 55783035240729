import { ADD_MEMBER, REMOVE_MEMBER, STORE_MEMBERS } from '../actions/members'

const initialState = {
  members: [],
  pages: 1
}

const membersStore = (state = initialState, { type, payload }) => {
  switch (type) {
    case STORE_MEMBERS: {
      const { data, ...res } = payload || {}
      return { ...state, members: data, ...res }
    }
    case ADD_MEMBER: {
      return { ...state, members: [...state.members, payload] }
    }
    case REMOVE_MEMBER: {
      const { ids } = payload || {}
      let members = state.members.filter(rec => ids.indexOf(rec.id) === -1)
      return { ...state, members }
    }
    default:
      return state
  }
}

export default membersStore
