import React, { useCallback } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles, useTheme, lighten } from '@material-ui/core/styles'
import { Formik } from 'formik'
import CustomInput from '../../../FormComponents/CustomInput'
import CustomButton from '../../../CustomButton'
import useSignupForm from '../../hooks/useSignupUser'
import usePasswordType from '../../hooks/usePasswordType'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../../utils/consts'

const useStyles = makeStyles(theme => ({
  signupHeading: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.colors.neutral[90],
    marginBottom: 40
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 474
  },
  formRoot: {
    width: '100%'
  },
  formSubtitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 24,
    color: theme.palette.colors.neutral[90]
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  nameInput: {
    width: '48%',
    maxHeight: '48px'
  },
  loginButton: {
    marginTop: 24
  },
  submitButton: {
    marginTop: 24
  },
  backToLogin: {
    marginTop: 24,
    fontSize: 14,
    fontWeight: 400
  },
  backToLoginLink: {
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      color: lighten(theme.palette.colors.neutral[2], 0.1)
    }
  }
}))

const SignupForm = () => {
  const classes = useStyles()
  const theme = useTheme()
  const { initialValues, validation, onSubmit } = useSignupForm()
  const { passwordType, IconType } = usePasswordType()
  const history = useHistory()

  const routeToSignin = useCallback(() => {
    history.replace(ROUTES.LOGIN)
  }, [history])

  return (
    <div className={classes.root}>
      <Typography className={classes.signupHeading} component="p">
        Create a free trial account
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={values => onSubmit(values)}
      >
        {({
          isSubmitting,
          values: { name, lastname, username, email, password },
          handleChange,
          touched,
          errors,
          handleBlur,
          handleSubmit
        }) => {
          const handleKeyPress = e => {
            if (e.key.toLowerCase() === 'enter') {
              handleSubmit()
            }
          }
          return (
            <form className={classes.formRoot}>
              <Typography className={classes.formSubtitle} component="p">
                Information
              </Typography>
              <div className={classes.nameContainer}>
                <CustomInput
                  label="First Name"
                  name="name"
                  required
                  variant="outlined"
                  className={classes.nameInput}
                  value={name}
                  onChange={handleChange}
                  error={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                />
                <CustomInput
                  label="Last Name"
                  name="lastname"
                  required
                  variant="outlined"
                  className={classes.nameInput}
                  value={lastname}
                  onChange={handleChange}
                  error={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  onKeyPress={handleKeyPress}
                />
              </div>
              <CustomInput
                label="Username"
                name="username"
                required
                variant="outlined"
                fullWidth
                value={username}
                onChange={handleChange}
                error={errors}
                touched={touched}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
              />
              <CustomInput
                label="Email"
                name="email"
                type="email"
                required
                variant="outlined"
                fullWidth
                value={email}
                onChange={handleChange}
                error={errors}
                touched={touched}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
              />
              <Typography className={classes.formSubtitle} component="p">
                Security
              </Typography>

              <CustomInput
                label="Password"
                name="password"
                type={passwordType}
                required
                fullWidth
                variant="outlined"
                value={password}
                onChange={handleChange}
                error={errors}
                touched={touched}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                endAdornment={IconType}
              />

              <CustomButton
                label="Create account"
                backgroundColor={theme.palette.secondary[100]}
                hoverColor={theme.palette.secondaryLight[100]}
                loading={isSubmitting}
                loadingColor={theme.palette.colors.white}
                onClick={handleSubmit}
                className={classes.submitButton}
                fullWidth
              />
            </form>
          )
        }}
      </Formik>
      <Typography className={classes.backToLogin} component="p">
        Already have an account?{' '}
        <span onClick={routeToSignin} className={classes.backToLoginLink}>
          Log in
        </span>
      </Typography>
    </div>
  )
}

export default SignupForm
