import React from 'react'
import { useTheme } from '@material-ui/core'
import { UNITS_LABELS } from '../../../../../utils/consts'

export default function useUnitColor() {
  const theme = useTheme()

  const getUnitColor = label => {
    switch (label) {
      case UNITS_LABELS.GOOD:
        return theme.palette.colors.status.success
      case UNITS_LABELS.NOISE:
        return theme.palette.colors.neutral[6]
      case UNITS_LABELS.MULTIPLE:
        return theme.palette.colors.status.error
      default:
        return null
    }
  }

  return { getUnitColor }
}
