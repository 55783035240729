import { useCallback, useState } from 'react'
import { AI_MODELS_FILTERS } from '../../../../../../../../../utils/consts'
import { getAIModels } from '../../../../../../../../../endpoints/admin'

function useAIModelsMenu() {
  const [state, setState] = useState({
    data: null,
    error: null,
    loading: false
  })

  const startFetch = useCallback(async () => {
    try {
      if (!state.data) {
        setState({ ...state, loading: true })
        const params = {
          filter: AI_MODELS_FILTERS.ORG_AND_PUBLIC
        }
        const data = await getAIModels(params)
        setState({ ...state, loading: false, data })
      }
    } catch (error) {
      setState({ ...state, loading: false, error })
    }
  }, [state])

  return {
    state,
    startFetch,
    asObject: true
  }
}

export default useAIModelsMenu
