import React from 'react'

const Filter = props => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_1670_35490)">
        <circle cx="16" cy="16" r="12" {...props} />
        <mask
          id="mask0_1670_35490"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="12"
          y="12"
          width="8"
          height="8"
        >
          <path
            d="M12.1253 12.805C13.1353 14.1 15.0003 16.5 15.0003 16.5V19.5C15.0003 19.775 15.2253 20 15.5003 20H16.5003C16.7753 20 17.0003 19.775 17.0003 19.5V16.5C17.0003 16.5 18.8603 14.1 19.8703 12.805C20.1253 12.475 19.8903 12 19.4753 12H12.5203C12.1053 12 11.8703 12.475 12.1253 12.805Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_1670_35490)">
          <rect x="10" y="10" width="12" height="12" fill={props.style.color} />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1670_35490"
          x="0"
          y="0"
          width="32"
          height="32"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1670_35490"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1670_35490"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

export default Filter
