import React from 'react'
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import cn from 'classnames'
import useLinearProgressValue from './hooks/useLinearProgressValue'

const useStyles = makeStyles(theme => ({
  root: ({ width = '100%', height = '100%' }) => ({
    width,
    height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    padding: 0,
    margin: 0,
    background: theme.palette.colors.backgrounds.main
  }),
  progressIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: 'auto'
  }
}))

const BorderLinearProgress = withStyles(theme =>
  createStyles({
    root: {
      width: '100%',
      height: 6
    },
    colorPrimary: {
      backgroundColor: 'rgba(33,183,132,0.1)'
    },
    bar: {
      backgroundColor: '#21b784'
    }
  })
)(LinearProgress)

function FullScreenLoader(props) {
  const { color = 'primary', size = 40, className, mainView = false } = props
  const classes = useStyles(props)
  const value = useLinearProgressValue()

  if (mainView)
    return <BorderLinearProgress variant="determinate" value={value} />

  return (
    <div className={cn(classes.root, className)}>
      <CircularProgress
        color={color}
        size={size}
        className={classes.progressIcon}
      />
    </div>
  )
}

export default FullScreenLoader
