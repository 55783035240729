import { ADD_MODAL, CLOSE_MODAL } from '../actions/modals'

const initialState = new Map()

const modalsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_MODAL:
      return new Map([...Array.from(state), [payload.id, payload]])
    case CLOSE_MODAL:
      return new Map([...Array.from(state).filter(x => x[0] !== payload.id)])
    default:
      return state
  }
}

export default modalsReducer
