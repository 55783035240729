import useGetCurrentUser from './useGetCurrentUser'
import { getOr } from 'lodash/fp'

function useLimitedAccess() {
  const currentUser = useGetCurrentUser()
  const isLimited = getOr(false, ['user', 'company', 'free'], currentUser)
  const isDefaultOrganization = getOr(
    false,
    ['user', 'company', 'free_default'],
    currentUser
  )

  return { isLimited, isDefaultOrganization }
}

export default useLimitedAccess
