import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import RadioGroup from '@material-ui/core/RadioGroup'
import CustomCheckboxLabel from './components/CustomCheckboxLabel'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {}
}))

const CheckboxesGroup = ({ value, handleChange, radios, className }) => {
  const classes = useStyles()

  return (
    <Grid container wrap="wrap" className={cn(classes.root, className)}>
      <RadioGroup value={value} onChange={handleChange}>
        {radios.map((radio, idx) => (
          <CustomCheckboxLabel
            key={idx}
            value={radio.value}
            label={radio.label}
          />
        ))}
      </RadioGroup>
    </Grid>
  )
}

export default CheckboxesGroup
