import {
  HANDLE_SET_DATA_MANIPULATION_PARAMS,
  RESET_ALL_DATA_MANIPULATION_PARAMS,
  RESET_DATA_MANIPULATION_PARAMS,
  TOGGLE_DATA_MANIPULATION_MODE
} from '../actions'

const initialState = {
  mode: null,
  slice: {
    from: 0,
    to: null,
    direction: 'ASC'
  },
  cut: {
    from: null,
    to: null
  }
}

export const dataManipulation = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_DATA_MANIPULATION_MODE:
      if (payload === state.mode) return state
      return { ...initialState, mode: payload }
    case HANDLE_SET_DATA_MANIPULATION_PARAMS: {
      const { mode } = state
      const prevParams = state[mode]
      return { ...state, [mode]: { ...prevParams, ...payload } }
    }
    case RESET_DATA_MANIPULATION_PARAMS: {
      // *undo functionality
      const { mode } = state
      const initial = initialState[mode]
      return { ...state, [mode]: initial }
    }
    case RESET_ALL_DATA_MANIPULATION_PARAMS: {
      return initialState
    }
    default:
      return state
  }
}
