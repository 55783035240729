import React, { useState, useEffect, useMemo, useCallback } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'
import ArrowDropUpRoundedIcon from '@material-ui/icons/ArrowDropUpRounded'
import PopperMenu from './PopperMenu'
import CustomInput from '../FormComponents/CustomInput'
import CustomClickOutside from '../CustomClickOutside'
import { NOTIFICATION } from '../../utils/consts'
import useNotification from '../SnackbarManager/hooks/useNotification'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    position: 'relative'
  },
  endAdornment: {
    pointerEvents: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  arrowIcon: {
    color: theme.palette.colors.neutral['5'],
    fontSize: 38
  }
}))

const SelectMenu = ({
  items = null,
  name,
  CustomMenu,
  textFieldProps,
  onChangeValue,
  value: overrideValue,
  disabled = false,
  classes: overrideClasse = {},
  placement = 'bottom-start',
  asyncData = () => ({ state: null, startFetch: () => {} })
}) => {
  const classes = useStyles()
  const addNotification = useNotification()

  const [value, setValue] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const { state: asyncDataState, asObject, startFetch } = asyncData()
  const subData = asyncDataState?.subData

  useEffect(() => {
    setValue(overrideValue ?? '')
  }, [overrideValue])

  const handleToggle = useCallback(
    event => {
      try {
        if (event?.currentTarget && !items && !asyncDataState?.data?.length) {
          startFetch()
        }
        setAnchorEl(anchorEl ? null : event?.currentTarget?.parentElement)
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [anchorEl, asyncDataState?.data?.length, items, startFetch]
  )

  const selectValue = useCallback(
    value => {
      let selectVal = value
      if (subData) {
        const probe = asyncDataState?.data && asyncDataState.data[value]
        selectVal = { name: probe, index: value }
        setValue({ name: probe, index: value })
      } else {
        setValue(value)
      }

      if (onChangeValue) {
        if (name) {
          onChangeValue(selectVal, name)
        } else {
          onChangeValue(selectVal)
        }
      }
    },
    [name, onChangeValue, asyncDataState?.data, subData]
  )

  const textInputProps = useMemo(
    () => ({
      endAdornment: anchorEl ? (
        <ArrowDropUpRoundedIcon className={classes.arrowIcon} />
      ) : (
        <ArrowDropDownRoundedIcon className={classes.arrowIcon} />
      ),
      onFocus: handleToggle,
      value: value?.name ?? value,
      fullWidth: true,
      classes: {
        endAdornment: classes.endAdornment
      },
      disabled,
      withBottomMargin: false,
      ...textFieldProps
    }),
    [
      anchorEl,
      classes.arrowIcon,
      classes.endAdornment,
      handleToggle,
      textFieldProps,
      value,
      disabled
    ]
  )

  let asyncItems = asObject
    ? asyncDataState?.data
    : asyncDataState?.data?.map((value, index) => ({
        label: value,
        value: subData ? index : value,
        subLabel: subData && subData[index]
      }))

  return (
    <CustomClickOutside clickedOutside={handleToggle}>
      {({ innerRef }) => (
        <div
          ref={innerRef}
          className={cn(classes.container, overrideClasse.container)}
        >
          <CustomInput {...textInputProps} />
          <PopperMenu
            placement={placement}
            anchorEl={anchorEl}
            loading={asyncDataState?.loading}
            error={asyncDataState?.error}
            items={items || asyncItems || []}
            CustomMenu={CustomMenu}
            selectValue={selectValue}
            handleClose={handleToggle}
          />
        </div>
      )}
    </CustomClickOutside>
  )
}

export default SelectMenu
