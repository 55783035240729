import React, { memo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/icons/Check'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  itemRoot: ({ align = 'left' }) => ({
    width: '100%',
    cursor: 'pointer',
    justifyContent: align
  }),
  label: ({ checked }) => ({
    fontWeight: `${checked ? 600 : 400} !important`,
    fontSize: 13,
    color: theme.palette.primary.contrast,
    marginLeft: 12,
    lineHeight: '20px'
  }),
  root: ({ size }) => ({
    width: size,
    height: size,
    borderRadius: 2,
    border: `1px solid ${theme.palette.action.disabled}`,
    overflow: 'hidden',
    padding: 1,
    cursor: 'pointer',
    backgroundColor: theme.palette.colors.white
  }),
  disabled: () => ({
    border: `1px solid #edeef0`,
    '&:hover': {
      border: 'inherit'
    }
  }),
  rootHover: {
    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`
    }
  },
  checked: () => ({
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  }),
  icon: {
    display: 'none',
    color: 'white',
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },
  iconVisible: {
    display: 'block'
  }
}))

function CustomCheckBox({
  checked = false,
  onChange = () => {},
  label = '',
  children = null,
  disabled,
  className,
  classNameRoot,
  labelClassName,
  size = 24,
  indeterminate = false,
  ...rest
}) {
  const classes = useStyles({ size, checked })
  return (
    <Grid
      className={cn(classes.itemRoot, className, classNameRoot)}
      container
      justify="center"
      alignItems="center"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        if (!disabled) onChange(e)
      }}
      {...rest}
    >
      <div
        className={cn(classes.root, className, {
          [classes.checked]: checked,
          [classes.indeterminate]: indeterminate,
          [classes.rootHover]: !checked && !disabled,
          [classes.disabled]: disabled
        })}
      >
        <Icon
          className={cn(classes.icon, {
            [classes.iconVisible]: checked
          })}
        />
        <div className={classes.icon} />
      </div>
      {label ? (
        <Typography className={cn(classes.label, labelClassName)}>
          {label}
        </Typography>
      ) : (
        children
      )}
    </Grid>
  )
}

export default memo(CustomCheckBox)
