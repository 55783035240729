import React, { useCallback, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import TimeseriesView from '../../components/TimeseriesView'
import { addProbeFileDataActionCreator } from './redux/actions'
import { fetchRecord, getProbeFileData } from './utils'
import IntegrateLeftPanel from './components/LeftPanel'
import { useTimeseriesInfo } from './hooks/useTimeseriesInfo'
import {
  resetRecord,
  storeRecord as storeRecordActionCreator
} from '../../actions/recording'
import ErrorPageRedirector from '../../components/ErrorPageRedirector'
import BlurLoader from '../../components/BlurLoader'
import ToursWrapper from '../../components/ToursWrapper'
import useToursController from './hooks/useToursController'
import useTours from '../../components/ToursWrapper/hooks/useTours'
import { NOTIFICATION, REQUIRED_TS_FIELDS, ROUTES } from '../../utils/consts'
import useNotification from '../../components/SnackbarManager/hooks/useNotification'
import { useHistory } from 'react-router-dom'
import UseSettingsModal from '../../components/TimeseriesView/NewLeftPanel/components/MultiScreenTab/components/SettingsModal/hooks/useSettingsModal'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    height: '100%',
    background: theme.palette.colors.customColor(
      theme.palette.colors.white,
      'rgb(18, 18, 18)'
    )
  }
}))

export const useFetchProbeFileData = () => {
  const [numChannels, setChannels] = React.useState(0)
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const addNotification = useNotification()
  const history = useHistory()

  const dispatch = useDispatch()

  const addProbeFileData = fileData =>
    dispatch(addProbeFileDataActionCreator(fileData))

  const storeRecord = data => dispatch(storeRecordActionCreator(data))
  const reset = useCallback(() => dispatch(resetRecord()), [dispatch])

  const fetchProbeFileData = async recordingId => {
    try {
      reset()
      await fetchRecord(recordingId, async data => {
        storeRecord({ data })

        const hasMissingFields = REQUIRED_TS_FIELDS.filter(item => !data[item])

        if (hasMissingFields?.length) {
          addNotification({
            type: NOTIFICATION.ERROR,
            title: `There might be some missing info (${hasMissingFields.join(
              ', '
            )}), please go to Recording view and use the Data widget to provide it.`,
            action: {
              label: 'Go back to Recording view',
              onClick: () =>
                history.push(
                  ROUTES.RECORDING_VIEW.replace(
                    ':recordingId',
                    `${recordingId}`
                  )
                )
            }
          })
          setLoading(false)
          return
        }
        const {
          disabled_channels,
          prb_file,
          number_channels,
          probe_file_type
        } = data || {}
        const probeFileData = await getProbeFileData(prb_file, number_channels)

        addProbeFileData({
          ...probeFileData,
          draw: prb_file,
          type: probe_file_type,
          disabled_channels: disabled_channels?.map(Number) || []
        })

        setChannels(probeFileData?.raw_num_channels ?? 0)
        setLoading(false)
      })
    } catch (err) {
      const { status } = err?.response || {}
      setError(status || 404)
      setLoading(false)
    }
  }

  return { loading, error, numChannels, fetchProbeFileData }
}

const TimeSeries = ({ recordingId, width, height }) => {
  const classes = useStyles()

  const { nextTour } = useToursController()

  const { tourState, callback } = useTours(nextTour)

  const { loading, error, numChannels, fetchProbeFileData } =
    useFetchProbeFileData()

  const {
    status,
    statusMessage,
    timeseriesInfo,
    nndTimeseriesInfo,
    effect,
    isRefreshTimeSeries,
    resetTimeseries
  } = useTimeseriesInfo(recordingId)
  const { handleCloseAllChildWindows: onCloseAllChildWindows } =
    UseSettingsModal()

  React.useEffect(() => {
    effect()

    if (fetchProbeFileData) {
      fetchProbeFileData(recordingId)
    }

    return () => {
      onCloseAllChildWindows()
      resetTimeseries()
    }
  }, [recordingId, isRefreshTimeSeries])

  return (
    <ToursWrapper tourState={tourState} callback={callback}>
      <div className={classes.wrapper}>
        <TSWrapper loading={loading} error={error}>
          <IntegrateLeftPanel channels={numChannels} loading={loading} />
          <TimeseriesView
            recordingId={recordingId}
            width={width}
            height={height}
            status={status}
            statusMessage={statusMessage}
            timeseriesInfo={timeseriesInfo}
            nndTimeseriesInfo={nndTimeseriesInfo}
          />
        </TSWrapper>
      </div>
    </ToursWrapper>
  )
}

const TSWrapper = ({ error, loading, children }) => {
  if (loading) return <BlurLoader />

  if (error) return <ErrorPageRedirector error={error} />

  return children
}

export default TimeSeries
