import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/notifications'

const initialState = {
  type: 'message',
  isOpened: false,
  title: '',
  message: '',
  body: () => null,
  duration: 10000,
  action: {
    label: '',
    onClick: null
  }
}

const notifications = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_NOTIFICATION:
      return { ...initialState, ...payload, isOpened: true }
    case REMOVE_NOTIFICATION:
      return initialState
    default:
      return state
  }
}

export default notifications
