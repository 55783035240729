export const OFFSETY = 4
export const DEPH = 40
export const MARGIN_X = 2
export const MARGIN_Y = 10
export const DECREASE_X_AXIS_BY = 1.45
export const DEPTH_UPDATER = 3.5
export const SENSITIVITY = 0.5
export const WAVEFORM_CANVAS_ID = 'waveform-wrapper'

export const STD_TYPE = { ADD: 'add', SUB: 'sub' }

export const WAVEFORM_VIEWS_ENUM = {
  OVERLAP: 'overlap_waveforms',
  MEAN: 'mean_waveforms'
}

export const LAYOUT_ARGS_DEFAULT = {
  Kilosort: {
    xaxis: { range: [-5, 5] },
    yaxis: {
      range: [-1, 1]
    }
  },
  Klusta: {
    xaxis: { range: [-2, 2] },
    yaxis: { range: [6, -6] }
  }
}
