import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import useMenuItems from './hooks/useMenuItems'
import { ROUTES } from '../../utils/consts'
import ConfigurationLayout from '../ConfigurationLayout'
import ExperimentsInputUpload from './components/ExperimentsInputUpload'
import ExperimentsInputRuns from './components/ExperimentsInputRuns'
import ExperimentsInputHead from './components/ExperimentsInputHead'
import { makeStyles } from '@material-ui/core/styles'
import useLimitedAccess from '../../hooks/useLimitedAccess'

const useStyles = makeStyles(() => ({
  content: {
    paddingBottom: 0
  }
}))

const ExperimentsInput = () => {
  const menuItem = useMenuItems()
  const classes = useStyles()
  const { isLimited } = useLimitedAccess()

  if (isLimited) return <Redirect noThrow to="/files" />

  return (
    <ConfigurationLayout
      menuItem={menuItem}
      Header={ExperimentsInputHead}
      classes={{
        content: classes.content
      }}
    >
      <Switch>
        <Route
          exact
          path={ROUTES.EXPERIMENTS_INPUT}
          component={ExperimentsInputUpload}
        />
        <Route
          exact
          path={ROUTES.EXPERIMENTS_INPUT_SPIKESRTING}
          component={ExperimentsInputRuns}
        />
      </Switch>
    </ConfigurationLayout>
  )
}

export default ExperimentsInput
