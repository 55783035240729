import React, { memo } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import SadIcon from '../../../Icons/SadIcon'

const useStyles = makeStyles(theme => ({
  root: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    height: 'fit-content',
    margin: 'auto',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    width: 693,
    padding: 24,
    fontSize: 30,
    fontWeight: 600,
    lineHeight: '45px',
    textAlign: 'center',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      'white'
    )
  },
  subTitle: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '36px',
    color: theme.palette.colors.customColor('#071740', 'white')
  }
}))

const EmptyComponent = ({ title, message }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <SadIcon />
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.subTitle}>{message}</Typography>
    </div>
  )
}

export default memo(EmptyComponent)
