import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import FullScreenLoader from '../../../components/FullScreenLoader'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    height: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: '50%'
  },
  content: {
    position: 'absolute',
    top: -70
  }
}))

const Loader = ({ children }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <FullScreenLoader />
      {children &&
        React.cloneElement(children, {
          className: classes.content
        })}
    </div>
  )
}

export default Loader
