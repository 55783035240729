import { getClusterView } from '../../../../../../../endpoints/curation'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadClusterview,
  setLoadingClusterview,
  sortClusterview,
  toggleCurationAI
} from '../../../redux/actions'
import { useSafeDispatch } from '../../../../../../../hooks/useSafeDispatch'
import useClusterData from './useClusterData'
import useClusterSimilarityStore from './useClusterSimilarityStore'
import useSpikeSortingInfo from '../../../../../hooks/useSpikeSortingInfo'

function useClusterView(props) {
  const { isInit } = props || {}
  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)
  const {
    storedClustersData: storedData,
    curationId,
    sortingId,
    keyClusterData,
    isClusterColumnLocked: isColumnLocked,
    clusterPrevSortBy: prevSortBy,
    clustersSortBy: sortBy,
    clustersSecondSortBy: secondSortBy,
    clustersSortDirection: sortDirection,
    recommendations,
    noiseRecommendations,
    toggleRecommendation,
    isSuggestionVisible
  } = useClusterSimilarityStore()

  const recording = useSelector(state => state.recordingStore.recording)

  const { isSorted, default_curation, selectedSorting } = useSpikeSortingInfo()

  const loadingCurations = useSelector(
    state => state.recordingStore.loadingCurations
  )

  const saveData = useCallback(
    payload => {
      if (payload?.data?.clusters)
        safeDispatch(
          loadClusterview({
            sortingId: keyClusterData,
            data: payload
          })
        )
    },
    [safeDispatch, keyClusterData]
  )

  const saveSortMethod = useCallback(
    ({ sortBy, sortDirection }) => {
      safeDispatch(
        sortClusterview({
          sortBy,
          sortDirection,
          curationId,
          sortingId
        })
      )
    },
    [safeDispatch, sortingId, curationId]
  )

  const setLoading = useCallback(
    data => safeDispatch(setLoadingClusterview(data)),
    [safeDispatch]
  )

  const { state, loadMore, fetchData, refetch, onSort } = useClusterData({
    promise: getClusterView,
    storedData,
    saveData,
    saveSortMethod,
    customParams: {
      sort_by: sortBy,
      sort_by_second: secondSortBy,
      direction: sortDirection.toLowerCase()
    }
  })

  const { default_run } = recording || {}

  useEffect(() => {
    if (isInit) return
    const isFetch = sortingId && isSorted && !loadingCurations

    if (isFetch) fetchData(saveData)
    else setLoading(false)
    // eslint-disable-next-line
  }, [
    sortingId,
    curationId,
    default_curation?.id,
    default_run?.id,
    loadingCurations,
    sortBy,
    secondSortBy,
    sortDirection,
    isSorted
  ])

  useEffect(() => {
    return () => {
      dispatch(toggleCurationAI(false))
    }
  }, [])

  return {
    state,
    refetch,
    loadMore,
    storedData,
    onSort,
    isColumnLocked,
    prevSortBy,
    sortBy,
    secondSortBy,
    sortDirection,
    selectedSorting,
    recommendations,
    noiseRecommendations,
    toggleRecommendation,
    isSuggestionVisible
  }
}

export default useClusterView
