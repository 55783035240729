import React from 'react'

export const svg128_3 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        width="80"
        height="950"
        viewBox="0 0 80 950"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H76V80.2958L80 118.436H0V98.0346V80.2958V0ZM80 118.436H0V875.207L27 950L54 875.207L80 118.436Z"
          fill={color}
        />
        <path
          d="M7 124C7 122.343 8.34315 121 10 121H22C23.6569 121 25 122.343 25 124V135.077C25 136.734 23.6569 138.077 22 138.077H10C8.34315 138.077 7 136.734 7 135.077V124Z"
          fill="white"
        />
        <path
          d="M7 149.077C7 147.42 8.34315 146.077 10 146.077H22C23.6569 146.077 25 147.42 25 149.077V160.154C25 161.811 23.6569 163.154 22 163.154H10C8.34315 163.154 7 161.811 7 160.154V149.077Z"
          fill="white"
        />
        <path
          d="M7 174.154C7 172.497 8.34315 171.154 10 171.154H22C23.6569 171.154 25 172.497 25 174.154V185.231C25 186.888 23.6569 188.231 22 188.231H10C8.34315 188.231 7 186.888 7 185.231V174.154Z"
          fill="white"
        />
        <path
          d="M7 199.231C7 197.574 8.34315 196.231 10 196.231H22C23.6569 196.231 25 197.574 25 199.231V210.308C25 211.964 23.6569 213.308 22 213.308H10C8.34315 213.308 7 211.964 7 210.308V199.231Z"
          fill="white"
        />
        <path
          d="M7 224.308C7 222.651 8.34315 221.308 10 221.308H22C23.6569 221.308 25 222.651 25 224.308V235.385C25 237.041 23.6569 238.385 22 238.385H10C8.34315 238.385 7 237.041 7 235.385V224.308Z"
          fill="white"
        />
        <path
          d="M7 249.385C7 247.728 8.34315 246.385 10 246.385H22C23.6569 246.385 25 247.728 25 249.385V260.462C25 262.118 23.6569 263.462 22 263.462H10C8.34315 263.462 7 262.118 7 260.462V249.385Z"
          fill="white"
        />
        <path
          d="M7 274.462C7 272.805 8.34315 271.462 10 271.462H22C23.6569 271.462 25 272.805 25 274.462V285.538C25 287.195 23.6569 288.538 22 288.538H10C8.34315 288.538 7 287.195 7 285.538V274.462Z"
          fill="white"
        />
        <path
          d="M7 299.538C7 297.882 8.34315 296.538 10 296.538H22C23.6569 296.538 25 297.882 25 299.538V310.615C25 312.272 23.6569 313.615 22 313.615H10C8.34315 313.615 7 312.272 7 310.615V299.538Z"
          fill="white"
        />
        <path
          d="M7 324.615C7 322.959 8.34315 321.615 10 321.615H22C23.6569 321.615 25 322.959 25 324.615V335.692C25 337.349 23.6569 338.692 22 338.692H10C8.34315 338.692 7 337.349 7 335.692V324.615Z"
          fill="white"
        />
        <path
          d="M7 349.692C7 348.035 8.34315 346.692 10 346.692H22C23.6569 346.692 25 348.035 25 349.692V360.769C25 362.426 23.6569 363.769 22 363.769H10C8.34315 363.769 7 362.426 7 360.769V349.692Z"
          fill="white"
        />
        <path
          d="M7 374.769C7 373.112 8.34315 371.769 10 371.769H22C23.6569 371.769 25 373.112 25 374.769V385.846C25 387.503 23.6569 388.846 22 388.846H10C8.34315 388.846 7 387.503 7 385.846V374.769Z"
          fill="white"
        />
        <path
          d="M7 399.846C7 398.189 8.34315 396.846 10 396.846H22C23.6569 396.846 25 398.189 25 399.846V410.923C25 412.58 23.6569 413.923 22 413.923H10C8.34315 413.923 7 412.58 7 410.923V399.846Z"
          fill="white"
        />
        <path
          d="M7 424.923C7 423.266 8.34315 421.923 10 421.923H22C23.6569 421.923 25 423.266 25 424.923V436C25 437.657 23.6569 439 22 439H10C8.34315 439 7 437.657 7 436V424.923Z"
          fill="white"
        />
        <path
          d="M7 450C7 448.343 8.34315 447 10 447H22C23.6569 447 25 448.343 25 450V461.077C25 462.734 23.6569 464.077 22 464.077H10C8.34315 464.077 7 462.734 7 461.077V450Z"
          fill="white"
        />
        <path
          d="M7 475.077C7 473.42 8.34315 472.077 10 472.077H22C23.6569 472.077 25 473.42 25 475.077V486.154C25 487.811 23.6569 489.154 22 489.154H10C8.34315 489.154 7 487.811 7 486.154V475.077Z"
          fill="white"
        />
        <path
          d="M7 500.154C7 498.497 8.34315 497.154 10 497.154H22C23.6569 497.154 25 498.497 25 500.154V511.231C25 512.888 23.6569 514.231 22 514.231H10C8.34315 514.231 7 512.888 7 511.231V500.154Z"
          fill="white"
        />
        <path
          d="M7 525.231C7 523.574 8.34315 522.231 10 522.231H22C23.6569 522.231 25 523.574 25 525.231V536.308C25 537.964 23.6569 539.308 22 539.308H10C8.34315 539.308 7 537.964 7 536.308V525.231Z"
          fill="white"
        />
        <path
          d="M7 550.308C7 548.651 8.34315 547.308 10 547.308H22C23.6569 547.308 25 548.651 25 550.308V561.384C25 563.041 23.6569 564.384 22 564.384H10C8.34315 564.384 7 563.041 7 561.384V550.308Z"
          fill="white"
        />
        <path
          d="M7 575.384C7 573.728 8.34315 572.384 10 572.384H22C23.6569 572.384 25 573.728 25 575.384V586.461C25 588.118 23.6569 589.461 22 589.461H10C8.34315 589.461 7 588.118 7 586.461V575.384Z"
          fill="white"
        />
        <path
          d="M7 600.461C7 598.805 8.34315 597.461 10 597.461H22C23.6569 597.461 25 598.805 25 600.461V611.538C25 613.195 23.6569 614.538 22 614.538H10C8.34315 614.538 7 613.195 7 611.538V600.461Z"
          fill="white"
        />
        <path
          d="M7 625.538C7 623.881 8.34315 622.538 10 622.538H22C23.6569 622.538 25 623.881 25 625.538V636.615C25 638.272 23.6569 639.615 22 639.615H10C8.34315 639.615 7 638.272 7 636.615V625.538Z"
          fill="white"
        />
        <path
          d="M7 650.615C7 648.958 8.34315 647.615 10 647.615H22C23.6569 647.615 25 648.958 25 650.615V661.692C25 663.349 23.6569 664.692 22 664.692H10C8.34315 664.692 7 663.349 7 661.692V650.615Z"
          fill="white"
        />
        <path
          d="M7 675.692C7 674.035 8.34315 672.692 10 672.692H22C23.6569 672.692 25 674.035 25 675.692V686.769C25 688.426 23.6569 689.769 22 689.769H10C8.34315 689.769 7 688.426 7 686.769V675.692Z"
          fill="white"
        />
        <path
          d="M7 700.769C7 699.112 8.34315 697.769 10 697.769H22C23.6569 697.769 25 699.112 25 700.769V711.846C25 713.503 23.6569 714.846 22 714.846H10C8.34315 714.846 7 713.503 7 711.846V700.769Z"
          fill="white"
        />
        <path
          d="M7 725.846C7 724.189 8.34315 722.846 10 722.846H22C23.6569 722.846 25 724.189 25 725.846V736.923C25 738.58 23.6569 739.923 22 739.923H10C8.34315 739.923 7 738.58 7 736.923V725.846Z"
          fill="white"
        />
        <path
          d="M7 750.923C7 749.266 8.34315 747.923 10 747.923H22C23.6569 747.923 25 749.266 25 750.923V762C25 763.657 23.6569 765 22 765H10C8.34315 765 7 763.657 7 762V750.923Z"
          fill="white"
        />
        <path
          d="M7 776C7 774.343 8.34315 773 10 773H22C23.6569 773 25 774.343 25 776V787.077C25 788.733 23.6569 790.077 22 790.077H10C8.34315 790.077 7 788.733 7 787.077V776Z"
          fill="white"
        />
        <path
          d="M7 801.077C7 799.42 8.34315 798.077 10 798.077H22C23.6569 798.077 25 799.42 25 801.077V812.154C25 813.81 23.6569 815.154 22 815.154H10C8.34315 815.154 7 813.81 7 812.154V801.077Z"
          fill="white"
        />
        <path
          d="M7 826.154C7 824.497 8.34315 823.154 10 823.154H22C23.6569 823.154 25 824.497 25 826.154V837.23C25 838.887 23.6569 840.23 22 840.23H10C8.34315 840.23 7 838.887 7 837.23V826.154Z"
          fill="white"
        />
        <path
          d="M7 851.23C7 849.574 8.34315 848.23 10 848.23H22C23.6569 848.23 25 849.574 25 851.23V862.307C25 863.964 23.6569 865.307 22 865.307H10C8.34315 865.307 7 863.964 7 862.307V851.23Z"
          fill="white"
        />
        <path
          d="M31 112C31 110.343 32.3431 109 34 109H46C47.6569 109 49 110.343 49 112V123.077C49 124.734 47.6569 126.077 46 126.077H34C32.3431 126.077 31 124.734 31 123.077V112Z"
          fill="white"
        />
        <path
          d="M31 137.077C31 135.42 32.3431 134.077 34 134.077H46C47.6569 134.077 49 135.42 49 137.077V148.154C49 149.811 47.6569 151.154 46 151.154H34C32.3431 151.154 31 149.811 31 148.154V137.077Z"
          fill="white"
        />
        <path
          d="M31 162.154C31 160.497 32.3431 159.154 34 159.154H46C47.6569 159.154 49 160.497 49 162.154V173.231C49 174.888 47.6569 176.231 46 176.231H34C32.3431 176.231 31 174.888 31 173.231V162.154Z"
          fill="white"
        />
        <path
          d="M31 187.231C31 185.574 32.3431 184.231 34 184.231H46C47.6569 184.231 49 185.574 49 187.231V198.308C49 199.964 47.6569 201.308 46 201.308H34C32.3431 201.308 31 199.964 31 198.308V187.231Z"
          fill="white"
        />
        <path
          d="M31 212.308C31 210.651 32.3431 209.308 34 209.308H46C47.6569 209.308 49 210.651 49 212.308V223.385C49 225.041 47.6569 226.385 46 226.385H34C32.3431 226.385 31 225.041 31 223.385V212.308Z"
          fill="white"
        />
        <path
          d="M31 237.385C31 235.728 32.3431 234.385 34 234.385H46C47.6569 234.385 49 235.728 49 237.385V248.462C49 250.118 47.6569 251.462 46 251.462H34C32.3431 251.462 31 250.118 31 248.462V237.385Z"
          fill="white"
        />
        <path
          d="M31 262.462C31 260.805 32.3431 259.462 34 259.462H46C47.6569 259.462 49 260.805 49 262.462V273.538C49 275.195 47.6569 276.538 46 276.538H34C32.3431 276.538 31 275.195 31 273.538V262.462Z"
          fill="white"
        />
        <path
          d="M31 287.538C31 285.882 32.3431 284.538 34 284.538H46C47.6569 284.538 49 285.882 49 287.538V298.615C49 300.272 47.6569 301.615 46 301.615H34C32.3431 301.615 31 300.272 31 298.615V287.538Z"
          fill="white"
        />
        <path
          d="M31 312.615C31 310.959 32.3431 309.615 34 309.615H46C47.6569 309.615 49 310.959 49 312.615V323.692C49 325.349 47.6569 326.692 46 326.692H34C32.3431 326.692 31 325.349 31 323.692V312.615Z"
          fill="white"
        />
        <path
          d="M31 337.692C31 336.035 32.3431 334.692 34 334.692H46C47.6569 334.692 49 336.035 49 337.692V348.769C49 350.426 47.6569 351.769 46 351.769H34C32.3431 351.769 31 350.426 31 348.769V337.692Z"
          fill="white"
        />
        <path
          d="M31 362.769C31 361.112 32.3431 359.769 34 359.769H46C47.6569 359.769 49 361.112 49 362.769V373.846C49 375.503 47.6569 376.846 46 376.846H34C32.3431 376.846 31 375.503 31 373.846V362.769Z"
          fill="white"
        />
        <path
          d="M31 387.846C31 386.189 32.3431 384.846 34 384.846H46C47.6569 384.846 49 386.189 49 387.846V398.923C49 400.58 47.6569 401.923 46 401.923H34C32.3431 401.923 31 400.58 31 398.923V387.846Z"
          fill="white"
        />
        <path
          d="M31 412.923C31 411.266 32.3431 409.923 34 409.923H46C47.6569 409.923 49 411.266 49 412.923V424C49 425.657 47.6569 427 46 427H34C32.3431 427 31 425.657 31 424V412.923Z"
          fill="white"
        />
        <path
          d="M31 438C31 436.343 32.3431 435 34 435H46C47.6569 435 49 436.343 49 438V449.077C49 450.734 47.6569 452.077 46 452.077H34C32.3431 452.077 31 450.734 31 449.077V438Z"
          fill="white"
        />
        <path
          d="M31 463.077C31 461.42 32.3431 460.077 34 460.077H46C47.6569 460.077 49 461.42 49 463.077V474.154C49 475.811 47.6569 477.154 46 477.154H34C32.3431 477.154 31 475.811 31 474.154V463.077Z"
          fill="white"
        />
        <path
          d="M31 488.154C31 486.497 32.3431 485.154 34 485.154H46C47.6569 485.154 49 486.497 49 488.154V499.231C49 500.888 47.6569 502.231 46 502.231H34C32.3431 502.231 31 500.888 31 499.231V488.154Z"
          fill="white"
        />
        <path
          d="M31 513.231C31 511.574 32.3431 510.231 34 510.231H46C47.6569 510.231 49 511.574 49 513.231V524.308C49 525.964 47.6569 527.308 46 527.308H34C32.3431 527.308 31 525.964 31 524.308V513.231Z"
          fill="white"
        />
        <path
          d="M31 538.308C31 536.651 32.3431 535.308 34 535.308H46C47.6569 535.308 49 536.651 49 538.308V549.384C49 551.041 47.6569 552.384 46 552.384H34C32.3431 552.384 31 551.041 31 549.384V538.308Z"
          fill="white"
        />
        <path
          d="M31 563.384C31 561.728 32.3431 560.384 34 560.384H46C47.6569 560.384 49 561.728 49 563.384V574.461C49 576.118 47.6569 577.461 46 577.461H34C32.3431 577.461 31 576.118 31 574.461V563.384Z"
          fill="white"
        />
        <path
          d="M31 588.461C31 586.805 32.3431 585.461 34 585.461H46C47.6569 585.461 49 586.805 49 588.461V599.538C49 601.195 47.6569 602.538 46 602.538H34C32.3431 602.538 31 601.195 31 599.538V588.461Z"
          fill="white"
        />
        <path
          d="M31 613.538C31 611.881 32.3431 610.538 34 610.538H46C47.6569 610.538 49 611.881 49 613.538V624.615C49 626.272 47.6569 627.615 46 627.615H34C32.3431 627.615 31 626.272 31 624.615V613.538Z"
          fill="white"
        />
        <path
          d="M31 638.615C31 636.958 32.3431 635.615 34 635.615H46C47.6569 635.615 49 636.958 49 638.615V649.692C49 651.349 47.6569 652.692 46 652.692H34C32.3431 652.692 31 651.349 31 649.692V638.615Z"
          fill="white"
        />
        <path
          d="M31 663.692C31 662.035 32.3431 660.692 34 660.692H46C47.6569 660.692 49 662.035 49 663.692V674.769C49 676.426 47.6569 677.769 46 677.769H34C32.3431 677.769 31 676.426 31 674.769V663.692Z"
          fill="white"
        />
        <path
          d="M31 688.769C31 687.112 32.3431 685.769 34 685.769H46C47.6569 685.769 49 687.112 49 688.769V699.846C49 701.503 47.6569 702.846 46 702.846H34C32.3431 702.846 31 701.503 31 699.846V688.769Z"
          fill="white"
        />
        <path
          d="M31 713.846C31 712.189 32.3431 710.846 34 710.846H46C47.6569 710.846 49 712.189 49 713.846V724.923C49 726.58 47.6569 727.923 46 727.923H34C32.3431 727.923 31 726.58 31 724.923V713.846Z"
          fill="white"
        />
        <path
          d="M31 738.923C31 737.266 32.3431 735.923 34 735.923H46C47.6569 735.923 49 737.266 49 738.923V750C49 751.657 47.6569 753 46 753H34C32.3431 753 31 751.657 31 750V738.923Z"
          fill="white"
        />
        <path
          d="M31 764C31 762.343 32.3431 761 34 761H46C47.6569 761 49 762.343 49 764V775.077C49 776.733 47.6569 778.077 46 778.077H34C32.3431 778.077 31 776.733 31 775.077V764Z"
          fill="white"
        />
        <path
          d="M31 789.077C31 787.42 32.3431 786.077 34 786.077H46C47.6569 786.077 49 787.42 49 789.077V800.154C49 801.81 47.6569 803.154 46 803.154H34C32.3431 803.154 31 801.81 31 800.154V789.077Z"
          fill="white"
        />
        <path
          d="M31 814.154C31 812.497 32.3431 811.154 34 811.154H46C47.6569 811.154 49 812.497 49 814.154V825.23C49 826.887 47.6569 828.23 46 828.23H34C32.3431 828.23 31 826.887 31 825.23V814.154Z"
          fill="white"
        />
        <path
          d="M31 839.23C31 837.574 32.3431 836.23 34 836.23H46C47.6569 836.23 49 837.574 49 839.23V850.307C49 851.964 47.6569 853.307 46 853.307H34C32.3431 853.307 31 851.964 31 850.307V839.23Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="80"
      height="928"
      viewBox="0 0 80 928"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H76V64.4481L79.5 117.643H0V97.6734V64.4481V0ZM44.5 887.852L79 117.643H0V887.852L22.25 928L44.5 887.852Z"
        fill={color}
      />
    </svg>
  )
}
