import React from 'react'

class CustomClickOutside extends React.Component {
  node = React.createRef()

  componentDidMount() {
    document.addEventListener('click', this.outsideClickHandler, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.outsideClickHandler, true)
  }

  outsideClickHandler = e => {
    const domNode = this.node.current

    if (domNode !== null && !domNode.contains(e.target)) {
      const { clickedOutside } = this.props
      clickedOutside()
    }
  }

  render() {
    return this.props.children({
      innerRef: this.node
    })
  }
}

export default CustomClickOutside
