import React from 'react'

const EventIcon = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_106_52408"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="12"
        height="12"
      >
        <path
          d="M7.99993 11.6798L10.7666 13.3531C11.2733 13.6598 11.8933 13.2064 11.7599 12.6331L11.0266 9.48643L13.4733 7.36643C13.9199 6.97976 13.6799 6.24643 13.0933 6.19976L9.87326 5.92643L8.61326 2.9531C8.38659 2.4131 7.61326 2.4131 7.38659 2.9531L6.12659 5.91976L2.90659 6.1931C2.31993 6.23976 2.07993 6.9731 2.52659 7.35976L4.97326 9.47976L4.23993 12.6264C4.10659 13.1998 4.72659 13.6531 5.23326 13.3464L7.99993 11.6798Z"
          fill="black"
        />
      </mask>
      <g mask="url(#mask0_106_52408)">
        <rect width="16" height="16" />
      </g>
    </svg>
  )
}

export default EventIcon
