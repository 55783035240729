import React from 'react'
import ExperimentsInputList from '../ExperimentsInputList'
import { useSelector } from 'react-redux'
import { storeUploadInputs } from '../../../../actions/experimentsInput'
import { queryUploadExpInputs } from '../../../../endpoints/admin'
import useExpInputsActions from '../../hooks/useExpInputsActions'
import { MODALS_ID } from '../../../../utils/consts'

const ExperimentsInputUpload = () => {
  const uploads = useSelector(state => state.experimentsInputStore.uploads)
  const { pages, data: items } = uploads || {}

  const { loading, handleDelete, handleEdit } = useExpInputsActions({
    query: queryUploadExpInputs,
    modalId: MODALS_ID.UPLOAD_EXPERIMENTS_INPUT_MODAL,
    storeAction: storeUploadInputs,
    storedItems: items
  })

  return (
    <ExperimentsInputList
      loading={loading}
      items={items}
      onDelete={handleDelete}
      onEdit={handleEdit}
      pages={pages}
    />
  )
}

export default ExperimentsInputUpload
