import { checkAndCallFunction } from '../utils'

export class DragZoom {
  rect = {
    locA: {},
    locB: {}
  }
  dragging = false

  constructor(context, clientRect, events, darkMode) {
    this.ctx = context
    this.clientRect = clientRect
    this.events = events
    this.darkMode = darkMode
  }

  mouseDown = X => {
    if(this.events.disabled) return 
    
    const [posX, posY] = X.pos
    this.rect.locA = { x: posX, y: posY }
    this.dragging = true
    checkAndCallFunction(this.events.mouseDown)
  }

  mouseRelease = X => {
    const { width, height } = this.clientRect
    this.ctx.clearRect(0, 0, width, height)
    this.dragging = false
    //if the user dont move the mouse
    if (!this.rect.locB.x || !this.rect.locB.y) return
    checkAndCallFunction(this.events.mouseRelease, this.rect)
  }

  mouseMove = X => {
    const [posX, posY] = X.pos
    const { width, height } = this.clientRect
    if (this.dragging) {
      this.ctx.clearRect(0, 0, width, height)
      this.rect.locB = { x: posX, y: posY }
      this.draw()
    }
    checkAndCallFunction(this.events.mouseMove)
  }

  draw = () => {
    const { locA, locB } = this.rect
    const color = this.darkMode ? 'white' : 'black'
    this.ctx.beginPath()
    this.ctx.rect(locA.x, locA.y, locB.x - locA.x, locB.y - locA.y)
    this.ctx.strokeStyle = color
    this.ctx.lineWidth = 2
    this.ctx.stroke()
  }
}
