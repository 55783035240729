import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { MAIN_APPBAR_HEIGHT } from '../../../../utils/styles'
import cn from 'classnames'
import React from 'react'
import FullScreenLoader from '../../../FullScreenLoader'

const useStyles = makeStyles(theme => ({
  content: ({ leftPanelWidth, left, fullScreenMode, isChildWindow }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -leftPanelWidth,
    position: 'relative',
    left: left || 0,
    top: 0,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: fullScreenMode ? 'row-reverse' : 'column',
    width: '100%',
    height: `calc(100vh - ${
      isChildWindow || fullScreenMode ? 0 : MAIN_APPBAR_HEIGHT
    }px)`,
    background: theme.palette.colors.backgrounds.main
  }),
  contentShift: ({ isChildWindow }) => ({
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: isChildWindow ? 0 : -72
  }),
  loader: {
    zIndex: 510,
    top: 0,
    position: 'absolute',
    right: 0,
    left: 0,
    background: theme.palette.colors.black_07
  }
}))

export const InnerContainer = ({
  left,
  leftPanelMode,
  width,
  fullScreenMode,
  children,
  loading
}) => {
  const leftPanelWidth = useSelector(
    state => state.timeSeriesLeftPanel.leftPanelWidth
  )
  const isChildWindow = useSelector(
    state => state.timeSeriesLeftPanel.isChildWindow
  )

  const renderChildren = () => {
    if (!children.map) {
      return React.cloneElement(children, {
        width
      })
    } else {
      return children.map((child, ii) => (
        <child.type key={ii} {...child.props} width={width} />
      ))
    }
  }

  const classes = useStyles({
    leftPanelWidth,
    isChildWindow,
    left,
    fullScreenMode
  })
  return (
    <div
      className={cn(classes.content, {
        [classes.contentShift]: leftPanelMode
      })}
    >
      {renderChildren()}
      {loading && <FullScreenLoader className={classes.loader} />}
    </div>
  )
}
