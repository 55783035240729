import React from 'react'
import { Edit } from '@material-ui/icons'
import InfoPanelItem from '../../../InfoPanelItem'
import CustomTag from '../../../../../CustomTag'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1
  }
}))

const InfoPanelTags = ({ tags, toggleModal, title, colorAccent }) => {
  const classes = useStyles()
  return (
    <InfoPanelItem title={title} Icon={<Edit />} onClick={toggleModal}>
      <CustomTag
        mode="preview"
        tags={tags}
        colorAccent={colorAccent}
        classes={{
          container: classes.container
        }}
      />
    </InfoPanelItem>
  )
}

export default InfoPanelTags
