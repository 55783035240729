import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import getTourSteps from '../utils/steps'
import useLimitedAccess from '../../../hooks/useLimitedAccess'

export default function useSteps(stepName) {
  const location = useLocation()
  const currentUser = useSelector(state => state.login.currentUser)
  const { isLimited } = useLimitedAccess()

  const { name: userName = 'Name' } = currentUser?.user || {}
  const steps = getTourSteps(userName)
  if (isLimited) return { steps: [] }

  const pageSteps = steps[stepName || location.pathname.split('/')[1]]

  return { steps: pageSteps }
}
