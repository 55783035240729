import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useLocalStorage } from 'usehooks-ts'
import useQueryParams from '../../../hooks/useQueryParams'
import isEqual from 'lodash/fp/isEqual'
import { useCallback, useEffect } from 'react'
import {
  addDefaultChannels,
  initChildTimeSeries,
  resetTimeseries,
  updateDisabledChannels
} from '../../../components/TimeseriesView/NewLeftPanel/redux/actions'
import { initRecord } from '../../../actions/recording'
import { addProbeFileDataActionCreator } from '../../NewTimeSeries/redux/actions'
import useTriggerKeyEvent from '../../../hooks/useTriggerKeyEvent'
import { useParams } from 'react-router-dom'

function useInitChildWindow() {
  const dispatch = useDispatch()

  const [store] = useLocalStorage('store', {})
  const { timeSeriesLeftPanel, recordingStore, biochipData } = store || {}

  const query = useQueryParams()
  const range = query.getByName('range')

  const params = useParams()
  const recordingId = params.recordingId

  // stored
  const { timeseriesInfo, channels, disabledChannels } = useSelector(
    state => state.timeSeriesLeftPanel,
    shallowEqual
  )
  const recording = useSelector(state => state.recordingStore.recording)
  const { channel_ids: channelIds } = useSelector(
    state => state.biochipData,
    shallowEqual
  )

  const isTsInfo = !isEqual(timeSeriesLeftPanel?.timeseriesInfo, timeseriesInfo)
  const initTimeSeriesInfo = useCallback(
    () =>
      dispatch(
        initChildTimeSeries({
          ...timeSeriesLeftPanel,
          range,
          isChildWindow: true
        })
      ),
    [dispatch, timeSeriesLeftPanel, range]
  )

  const isRecordInfo = !isEqual(recordingStore?.recording, recording)
  const initRecordInfo = useCallback(
    () => dispatch(initRecord(recordingStore)),
    [dispatch, recordingStore]
  )

  const isBiochipInfo = !isEqual(biochipData?.channel_ids, channelIds)
  const initBiochipInfo = useCallback(
    () => dispatch(addProbeFileDataActionCreator(biochipData)),
    [biochipData, dispatch]
  )

  const isDisabled = !isEqual(
    timeSeriesLeftPanel?.disabledChannels,
    disabledChannels
  )

  const saveDefaultCh = useCallback(
    props => {
      const { disabled_channels = [] } = props || {}
      dispatch(addDefaultChannels({ ...biochipData, disabled_channels, range }))
    },
    [dispatch, biochipData, range]
  )

  const syncDisableChannels = useCallback(
    () =>
      dispatch(
        updateDisabledChannels({
          disabledChannels: timeSeriesLeftPanel?.disabledChannels
        })
      ),
    [dispatch, timeSeriesLeftPanel]
  )

  const { isCtrl, keyKode } = useTriggerKeyEvent()
  const [windows, storeWindow] = useLocalStorage(`child-windows-${recordingId}`)

  // save range to trigger in main window that this range is active
  useEffect(() => {
    storeWindow(old => ({
      ...old,
      [range.replace('-', ' - ')]: true
    }))
  }, [])

  const resetWindow = useCallback(() => {
    let r = range.replace('-', ' - ')
    let newObj = Object.entries(windows).filter(([k]) => k !== r)
    storeWindow(Object.fromEntries(newObj))
  }, [range, storeWindow, windows])

  // on close remove window range from localStorage
  useEffect(() => {
    window.onbeforeunload = function (e) {
      e.preventDefault()
      // prevent triggerof the action on refresh, it should be handled only on close
      if (isCtrl && keyKode === 82) return ''
      resetWindow()
    }
  }, [isCtrl, keyKode, windows, resetWindow])

  // close window on refresh
  useEffect(() => {
    if (timeseriesInfo && !timeSeriesLeftPanel?.timeseriesInfo) {
      window.close()
    }
  }, [timeSeriesLeftPanel?.timeseriesInfo, timeseriesInfo])

  useEffect(() => {
    initTimeSeriesInfo()

    return () => dispatch(resetTimeseries())
    // eslint-disable-next-line
  }, [isTsInfo])

  useEffect(() => {
    initRecordInfo()

    // eslint-disable-next-line
  }, [isRecordInfo])

  useEffect(() => {
    initBiochipInfo()

    // eslint-disable-next-line
  }, [isBiochipInfo])

  useEffect(() => {
    saveDefaultCh()

    // eslint-disable-next-line
  }, [isBiochipInfo]) // biochipData?.disabled_channels

  useEffect(() => {
    if (isDisabled) {
      syncDisableChannels()
    }

    // eslint-disable-next-line
  }, [isDisabled])

  return { timeseriesInfo, recording, channelIds, channels, range, recordingId }
}

export default useInitChildWindow
