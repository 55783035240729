import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Resizable } from 're-resizable'

const useStyles = makeStyles(theme => ({
  child2Wrapper: ({ width }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: 'none',
    borderTop: 'solid 3px #ddd',
    width,
    height: '100%',
    minHeight: '20px'
  })
}))

const config = {
  top: false,
  right: false,
  bottom: true,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false
}

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderBottom: 'solid 1px #ddd'
}

const minHeight = 100

const ResizableWidgets = ({
  child1,
  child2,
  child3,
  child4,
  onResize,
  onResizeStop,
  width,
  height,
  maxHeight,
  renderChild2
}) => {
  const classes = useStyles({ width })

  return (
    <>
      <Resizable
        style={style}
        enable={config}
        maxHeight={maxHeight}
        minHeight={minHeight}
        onResize={onResize}
        onResizeStop={onResizeStop}
        size={{
          width,
          height
        }}
      >
        {child1}
        {child3}
        {child4}
      </Resizable>
      {renderChild2 && <div className={classes.child2Wrapper}>{child2}</div>}
    </>
  )
}

export default ResizableWidgets
