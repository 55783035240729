import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { addModal, closeModal } from '../actions/modals'
import { useSafeDispatch } from './useSafeDispatch'

function useToggleModal(modalID, argsProps = {}) {
  const modalState = useSelector(state => state.modals?.get(modalID))

  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)

  const toggle = useCallback(
    (args = {}) => {
      if (modalState) return safeDispatch(closeModal({ id: modalID }))
      safeDispatch(
        addModal({
          id: modalID,
          args: {
            ...args,
            ...argsProps
          }
        })
      )
    },
    [modalID, argsProps, modalState, safeDispatch]
  )

  const onClose = () => safeDispatch(closeModal({ id: modalID }))
  const onOpen = args => safeDispatch(addModal({ id: modalID, args }))

  return { toggle, onClose, onOpen, isOpened: Boolean(modalState), modalState }
}

export default useToggleModal
