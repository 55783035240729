import React, { memo } from 'react'
import { Grid, Typography, useTheme } from '@material-ui/core'
import ActionButton from '../../../ActionButton'
import { UploadFileIcon } from '../../../Icons'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import NewSpikesortingModal from '../../../NewRecordingView/components/RecordingHeader/components/NewSpikesortingModal'
import useExperimentsInputController from '../../hooks/useExperimentsInputController'
import UploadExperimentsInputModal from '../UploadExperimentsInputModal'
import { EXPERIMENTS_INPUT } from '../../../../utils/consts'
import DownloadAppModal from '../../../Files/components/DownloadAppModal'
import useDownloadAppModal from '../../../Files/hooks/useDownloadAppModal'
import SelectDefaultPrbFileModal from '../SelectDefaultPrbFileModal'

const useStyles = makeStyles(theme => ({
  title: {
    top: 0,
    fontSize: 28,
    lineHeight: '32px',
    fontWeight: 600,
    position: 'sticky',
    color: theme.palette.primary.contrast
  },
  description: {
    fontSize: 16,
    lineHeight: '24px',
    padding: '26px 0 32px'
  },

  actionsContainer: {
    flex: 1
  },
  action: {
    width: 'auto',
    minWidth: 'auto',
    borderRadius: 8,
    marginLeft: 25
  }
}))

const ExperimentsInputHead = () => {
  const classes = useStyles()
  const theme = useTheme()

  const {
    createSpikesorting,
    loading,
    addValue,
    oldValues,
    handleClose,
    handleEdit,
    refetchInputs
  } = useExperimentsInputController()

  const { toggleModal: toggleDownloadModal, isOpened } = useDownloadAppModal()

  return (
    <React.Fragment>
      <NewSpikesortingModal
        onSubmit={createSpikesorting}
        onEdit={options => handleEdit(EXPERIMENTS_INPUT.SPIKESORTINGS, options)}
        btnLabel="Save"
        loading={loading}
        isExperimentInput
        oldValues={oldValues}
        onClose={handleClose}
      />
      <UploadExperimentsInputModal
        oldValues={oldValues}
        onClose={handleClose}
        onEdit={options => handleEdit(EXPERIMENTS_INPUT.UPLOADS, options)}
        onSuccess={refetchInputs}
      />
      <DownloadAppModal isOpened={isOpened} toggleModal={toggleDownloadModal} />
      <SelectDefaultPrbFileModal withSerialNr />

      <Grid container justify="space-between">
        <Typography className={classes.title}>Experiments Input</Typography>
        <Grid
          container
          wrap="nowrap"
          justify="flex-end"
          alignItems="center"
          className={classes.actionsContainer}
        >
          <ActionButton
            className={cn(classes.action)}
            startIcon={<UploadFileIcon />}
            label="Upload File"
            onClick={toggleDownloadModal}
          />
          <ActionButton
            className={cn(classes.action)}
            label="Add values"
            customStyles={{
              backgroundColor: theme.palette.action.secondary
            }}
            onClick={addValue}
          />
        </Grid>
      </Grid>
      <Typography className={classes.description}>
        Use this page to configure default parameters for uploading your
        experiments from the desktop app or automatically execute spikesorting
        after the upload succeeds.
      </Typography>
    </React.Fragment>
  )
}

export default memo(ExperimentsInputHead)
