import React, { memo, useCallback } from 'react'
import BaseWidget from '../BaseWidget'
import useClusterView from './hooks/useClusterView'
import { getOr, isEmpty } from 'lodash/fp'
import WidgetLoader from '../../components/WidgetLoader'
import { useDispatch, useSelector } from 'react-redux'
import { saveClusterUnits, saveSimilarityUnits } from '../../redux/actions'
import useRowsSelection from '../../components/InfiniteList/hooks/useRowsSelection'
import InfiniteList from '../../components/InfiniteList'
import useKeyboardShortcuts from './hooks/useKeyboardShortcuts'
import { useSafeDispatch } from '../../../../../../hooks/useSafeDispatch'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import cn from 'classnames'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const columns = [
  { id: 'id', active: true, label: 'ID' },
  { id: 'ch', active: true, label: 'Ch.' },
  { id: 'sh', active: true, label: 'Group' },
  { id: 'depth', active: true, label: 'Depth' },
  { id: 'fr', active: true, label: 'Firing R. (Hz)' },
  { id: 'amp', active: true, label: 'Amplitude' },
  { id: 'n_spikes', active: true, label: 'Spikes' },
  { id: 'label', active: true, label: 'Label' }
]

const CONTER_HEIGHT = 21

const useStyles = makeStyles(theme => ({
  root: {
    height: `calc(100% - ${CONTER_HEIGHT}px)`
  },
  labelsRoot: {
    marginTop: 4,
    position: 'sticky',
    left: 0,
    top: 0,
    borderBottom: `1px solid ${theme.palette.colors.grey_15}`
  },
  label: {
    fontSize: 11,
    minWidth: 'calc(100% / 4)',
    padding: '2px 6px',
    lineHeight: '12px',
    color: theme.palette.colors.neutral.black,
    '& span': {
      fontWeight: 600,
      marginRight: 8
    },
    '&:nth-child(2)': {
      color: theme.palette.secondary[100]
    },
    '&:nth-child(3)': {
      color: theme.palette.colors.error
    },
    '&:nth-child(4)': {
      color: theme.palette.colors.neutral[5]
    }
  },
  rootIcon: ({ isNoiseUnit }) => ({
    padding: '0 5px',
    height: 14,
    background: isNoiseUnit
      ? theme.palette.colors.customColor(
          theme.palette.colors.neutral[8],
          theme.palette.colors.neutral[2]
        )
      : theme.palette.colors.customColor('#d7ecff', '#1a2e41')
  }),

  icon: {
    width: 14,
    height: 14,
    left: 0,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['5'],
      theme.palette.colors.white
    ),
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

export const HideRecommandation = ({
  id,
  isVisible,
  toggleRecommendation,
  isNoiseUnit
}) => {
  const classes = useStyles({ isNoiseUnit })

  const Icon = isVisible ? VisibilityIcon : VisibilityOffIcon
  return (
    <div className={classes.rootIcon} id={id}>
      <Icon
        className={classes.icon}
        onClick={e => {
          e.stopPropagation()
          toggleRecommendation()
        }}
      />
    </div>
  )
}

export const ClusterView = () => {
  const classes = useStyles()
  const {
    selectedSorting,
    state,
    storedData,
    loadMore,
    refetch,
    onSort,
    sortBy,
    isColumnLocked,
    secondSortBy,
    sortDirection,
    recommendations,
    toggleRecommendation,
    isSuggestionVisible,
    noiseRecommendations
  } = useClusterView()
  const { loading: loadingData, error } = state || {}
  const data = getOr(null, ['data'], storedData)
  const { clusters, total } = data || {}
  const isError = !selectedSorting || error || isEmpty(clusters)
  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)
  const saveUnits = useCallback(
    data => safeDispatch(saveClusterUnits(data)),
    [safeDispatch]
  )
  const saveSimilarityUnit = useCallback(
    data => safeDispatch(saveSimilarityUnits(data)),
    [safeDispatch]
  )

  const selectedRows = useSelector(state => state.widgetsCache.units.cluster)

  const loadingOperations = useSelector(
    state => state.widgetsCache.clusters.loading
  )

  const loading = loadingData || loadingOperations

  useKeyboardShortcuts({
    loading,
    saveClusterUnits: saveUnits,
    saveSimilarityUnit
  })

  const handleRowSelect = useRowsSelection(saveUnits)

  const renderActionButton = useCallback(
    id => {
      const isVisible = isSuggestionVisible(id)
      const isNoiseUnit = noiseRecommendations?.includes(id)

      return (
        <HideRecommandation
          id={`cluster-view-base-merge-visibility-icon`}
          isVisible={isVisible}
          isNoiseUnit={isNoiseUnit}
          toggleRecommendation={() => toggleRecommendation(id)}
        />
      )
    },
    [isSuggestionVisible, noiseRecommendations, toggleRecommendation]
  )

  return (
    <>
      <Counter />
      <BaseWidget overrideClassName={classes.root}>
        <WidgetLoader
          refetch={refetch}
          requestFailed={error}
          error={isError}
          message="Failed to read cluster"
          loading={loading && isEmpty(clusters)}
          isLoadMore={loading && !isEmpty(clusters)}
        >
          <InfiniteList
            parentId={'cluster-view-base'}
            sortBy={sortBy}
            secondSortBy={secondSortBy}
            sortDirection={sortDirection}
            onSort={onSort}
            isColumnLocked={isColumnLocked}
            columns={columns}
            list={clusters}
            loadMore={loadMore}
            total={total}
            selectedRows={selectedRows}
            handleRowSelect={handleRowSelect}
            recommendations={recommendations}
            isSuggestionVisible={isSuggestionVisible}
            renderActionButton={renderActionButton}
            noiseRecommendations={noiseRecommendations}
          />
        </WidgetLoader>
      </BaseWidget>
    </>
  )
}

export const Counter = memo(({ classes: overideClasses = {} }) => {
  const classes = useStyles()
  const selectedCuration = useSelector(
    state => state.recordingStore.selectedCuration
  )

  const { uncurated_units, good_units, multi_units, noise_units } =
    selectedCuration || {}

  return (
    <Grid
      container
      wrap="nowrap"
      className={cn(classes.labelsRoot, overideClasses?.root)}
    >
      <Typography className={cn(classes.label, overideClasses?.label)}>
        <span>UNC :</span>
        {uncurated_units || '-'}
      </Typography>
      <Typography className={cn(classes.label, overideClasses?.label)}>
        <span>SU :</span>
        {good_units || '-'}
      </Typography>
      <Typography className={cn(classes.label, overideClasses?.label)}>
        <span>MU :</span>
        {multi_units || '-'}
      </Typography>
      <Typography className={cn(classes.label, overideClasses?.label)}>
        <span>NOI :</span>
        {noise_units || '-'}
      </Typography>
    </Grid>
  )
})
