import React, { useCallback } from 'react'
import { Menu, MenuItem, IconButton, makeStyles } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import Typography from '@material-ui/core/Typography'
import CustomTooltip from '../../../CustomTooltip'
import useLimitedAccess from '../../../../hooks/useLimitedAccess'

const useStyles = makeStyles(theme => ({
  actionBtn: {
    padding: 5,
    '& svg': {
      fontSize: 20,
      color: theme.palette.colors.white
    }
  },
  menuItem: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.colors.neutral.black
  },

  tooltipText: {
    fontSize: 13,
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.palette.colors.neutral['2']
  }
}))

export default function ActionsMenu({ selected, onConcat, onWeave, onDelete }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const { isLimited } = useLimitedAccess()
  const disabled =
    isLimited ||
    selected.length !== 2 ||
    selected.some(el => el.type === 'folder')
  const classes = useStyles()

  const tooltip = useCallback(
    topic =>
      !isLimited && (
        <Typography className={classes.tooltipText}>
          You can {topic} only 2 datasets per time
        </Typography>
      ),
    [classes.tooltipText, isLimited]
  )

  const actions = [
    {
      name: 'Concat Datasets',
      onClick: onConcat,
      disabled,
      tooltip: tooltip('concatenate')
    },
    {
      name: 'Weave Datasets',
      onClick: onWeave,
      disabled,
      tooltip: tooltip('weave')
    },
    {
      name: 'Delete',
      onClick: onDelete,
      disabled: isLimited
    }
  ]

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className={classes.actionBtn}
        onClick={handleClick}
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        {actions.map(({ name, onClick, disabled, tooltip = null }, i) => {
          const item = (
            <MenuItem
              key={i}
              className={classes.menuItem}
              disabled={disabled}
              onClick={() => {
                onClick()
                handleClose()
              }}
            >
              {name}
            </MenuItem>
          )

          if (tooltip && disabled) {
            return (
              <CustomTooltip tooltip={tooltip} width={150}>
                <div>{item}</div>
              </CustomTooltip>
            )
          }

          return item
        })}
      </Menu>
    </>
  )
}
