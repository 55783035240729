import { ofType } from 'redux-observable'
import { from } from 'rxjs'
import {
  switchMap,
  tap,
  filter,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators'
import {
  SWITCH_WAVEFORM_VIEW,
  TOGGLE_WAVEFORM_GEOMETRY,
  TOGGLE_WAVEFORM_OVERLAPPING
} from '../../actions'
import { PlotWaveforms } from '../../../widgets/WaveFormView'
import { fetchWaveformsEpic, fetchMeanWaveformsEpic } from './reusable.epics'
import { WAVEFORM_VIEWS_ENUM } from '../../../widgets/WaveFormView/consts'

export const switchWaveformEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      SWITCH_WAVEFORM_VIEW,
      TOGGLE_WAVEFORM_GEOMETRY,
      TOGGLE_WAVEFORM_OVERLAPPING
    ),
    withLatestFrom(state$),
    filter(_action => PlotWaveforms.renderPlot !== null),
    tap(([action, state]) => {
      PlotWaveforms.clearAllTraces()
      if (action.type === SWITCH_WAVEFORM_VIEW) {
        PlotWaveforms.setBuilder(action.payload)
      }
      return [action, state]
    }),
    switchMap(([action, state]) => {
      const waveformsView = action.payload
      const units = state.widgetsCache.units.cluster
      const similarityUnits = state.widgetsCache.units.similarity

      return from(
        units.concat(similarityUnits).map(u => ({ payload: { unit_id: u } }))
      ).pipe(
        mergeMap(action => {
          if (waveformsView === WAVEFORM_VIEWS_ENUM.MEAN) {
            return fetchMeanWaveformsEpic(action, state)
          }
          return fetchWaveformsEpic(action, state)
        })
      )
    })
  )
