import { useCallback, useEffect, useRef, useState } from 'react'
import { resetPassword } from '../../../../../../../endpoints/auth'
import { useSelector } from 'react-redux'
import { NOTIFICATION, ROUTES } from '../../../../../../../utils/consts'
import useNotification from '../../../../../../SnackbarManager/hooks/useNotification'
import { useHistory } from 'react-router-dom'

function UseEmailSent() {
  const countdownInterval = 60
  const history = useHistory()
  const timerRef = useRef(null)
  const addNotification = useNotification()
  const [isTimedOut, setIsTimedOut] = useState(false)
  const [secs, setSecs] = useState(countdownInterval)

  const email = useSelector(state => state.login.currentUserEmail)

  const onSubmit = useCallback(() => {
    try {
      setIsTimedOut(true)
      resetPassword({ email })
      timerRef.current = setInterval(() => {
        setSecs(prev => prev - 1)
      }, 1000)
    } catch (err) {
      setIsTimedOut(false)
      addNotification({
        type: NOTIFICATION.ERROR,
        title: err
      })
    }
  }, [addNotification, email])

  useEffect(() => {
    if (secs === 0) {
      setIsTimedOut(false)
      clearInterval(timerRef.current)
      setSecs(countdownInterval)
    }
  }, [secs])

  useEffect(() => {
    if (!email) {
      history.push(ROUTES.RESET_PASSWORD)
    }
    return () => clearInterval(timerRef.current)
  }, [])

  return { onSubmit, isTimedOut, secs }
}

export default UseEmailSent
