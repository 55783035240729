import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomTooltip from '../../../../../../../CustomTooltip'

const useStyles = makeStyles(theme => ({
  chipId: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.colors.neutral.black,
    cursor: 'pointer',
    margin: 'auto',
    width: '30ch'
  },
  tooltip: {
    fontSize: 12,
    fontWeight: 600,
    border: '0px',
    borderRadius: '8px',
    padding: '6px 12px',
    filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))'
  },
  tooltipArrow: {
    fontSize: 12,
    '&::before': {
      backgroundColor: theme.palette.colors.white,
      border: `0px`,
      filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))'
    }
  },
  chipIdContainer: {
    marginBottom: '30px',
    justifyContent: 'center',
    textAlign: 'center'
  }
}))

const FileName = memo(({ probeFileName }) => {
  const classes = useStyles()
  return (
    <div className={classes.chipIdContainer}>
      {probeFileName.length > 30 ? (
        <LongProbeFileName probeFileName={probeFileName} classes={classes} />
      ) : (
        <Typography className={classes.chipId} noWrap>
          {probeFileName}
        </Typography>
      )}
    </div>
  )
})

const LongProbeFileName = memo(({ probeFileName, classes }) => {
  const truncatedName = `${probeFileName.substring(0, 30)}...`
  return (
    <CustomTooltip
      placement={'right'}
      _classes={classes}
      tooltip={probeFileName}
    >
      <Typography className={classes.chipId} noWrap>
        {truncatedName}
      </Typography>
    </CustomTooltip>
  )
})

export default FileName
