import React, { useCallback, useMemo } from 'react'
import cn from 'classnames'
import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  ControlCameraOutlined,
  FullscreenExit,
  ViewColumn,
  ZoomInOutlined,
  ZoomOutOutlined
} from '@material-ui/icons'
import DecorateIcon from '../../../shared/components/DecorateIcon'
import { useDispatch, useSelector } from 'react-redux'
import { RenderCombination } from '../../RecordingViewWithCuration/components/RecordingHeader/hooks/useRenderCombination'
import {
  changeCursor,
  toggleFullScreenMode
} from '../../TimeseriesView/NewLeftPanel/redux/actions'
import {
  DATA_MANIPULATION_MODE,
  MODALS_ID,
  TS_CURSOR_TYPE
} from '../../../utils/consts'
import useToggleModal from '../../../hooks/useToggleModal'
import useEventsController from '../../TimeseriesView/NewLeftPanel/components/EventsTab/hooks/useEventsController'
import SelectChannelCursorIcon from '../../../imgs/RasterSection/SelectChannelCursor/SelectChannelCursor'
import SelectEventCursor from '../../../imgs/RasterSection/SelectEventCursor'
import useLimitedAccess from '../../../hooks/useLimitedAccess'

const CounterColumnsIcon = ({ classes, counter, type, selectedView }) => {
  return (
    <div className={classes.viewColWrapper}>
      <DecorateIcon icon={ViewColumn} />
      <span
        className={cn(classes.viewColCount, {
          [classes.viewColCountSelected]: type === selectedView
        })}
      >
        {counter}
      </span>
    </div>
  )
}

export default function useTimeWidgetToolBar({
  classes,
  groups,
  onViewColumns,
  selectedView,
  customActions,
  fullScreenMode,
  onShiftTimeLeft,
  onShiftTimeRight,
  onZoomIn,
  onZoomOut,
  resetZoom,
  toggleRasterPlot,
  hideRasterToggler,
  setDataManipulationMode,
  clearHighlightedChannels,
  removeChannelsOnClick
}) {
  const cursorType = useSelector(state => state.timeSeriesLeftPanel.cursorType)
  const isChildWindow = useSelector(
    state => state.timeSeriesLeftPanel.isChildWindow
  )
  const dispatch = useDispatch()

  const selectCursor = useCallback(
    type => {
      dispatch(changeCursor(type))
    },
    [dispatch, cursorType]
  )

  const eventsUploadModal = useToggleModal(MODALS_ID.EVENTS_FILE_UPLOAD_MODAL)
  const dataManipulationMode = useSelector(state => state.dataManipulation.mode)

  const handleFullScreenMode = useCallback(
    () => dispatch(toggleFullScreenMode(!fullScreenMode)),
    [dispatch, fullScreenMode]
  )
  const eventsController = useEventsController()
  const { isLimited } = useLimitedAccess()

  let buttons = useMemo(
    () =>
      [
        {
          type: 'button',
          title: 'View one column',
          onClick: onViewColumns('single'),
          icon: (
            <CounterColumnsIcon
              classes={classes}
              counter={1}
              type="single"
              selectedView={selectedView}
            />
          ),
          props: {
            style: {
              display: groups === 1 ? 'none' : 'block'
            }
          }
        },
        !dataManipulationMode && {
          type: 'button',
          title: 'View multiple columns',
          onClick: onViewColumns('multiple'),
          icon: (
            <CounterColumnsIcon
              classes={classes}
              counter={groups}
              type="multiple"
              selectedView={selectedView}
            />
          ),
          props: {
            style: {
              display: groups === 1 ? 'none' : 'block'
            }
          }
        },
        !isChildWindow && {
          type: 'button',
          title: 'Select channel (ctrl + left click)',
          onClick: selectCursor.bind(this, 'select_channel'),
          selected: cursorType === TS_CURSOR_TYPE.SELECT_CHANNELS,
          icon: <SelectChannelCursorIcon />,
          props: {
            style: {
              opacity: cursorType === TS_CURSOR_TYPE.SELECT_CHANNELS ? 1 : 0.5
            }
          }
        },
        !isChildWindow && {
          type: 'button',
          title: 'Select timestamp',
          onClick: selectCursor.bind(this, 'select_timestamp'),
          selected: cursorType === TS_CURSOR_TYPE.SELECT_TIMESTAMPS,
          icon: <SelectEventCursor />,
          props: {
            style: {
              opacity: cursorType === TS_CURSOR_TYPE.SELECT_TIMESTAMPS ? 1 : 0.5
            },
            disabled: isLimited
          }
        },
        fullScreenMode && {
          type: 'button',
          title: 'Full screen',
          onClick: handleFullScreenMode,
          selected: fullScreenMode,
          icon: <DecorateIcon icon={FullscreenExit} />
        }
      ].filter(Boolean),
    [
      isChildWindow,
      onViewColumns,
      classes,
      selectedView,
      groups,
      dataManipulationMode,
      selectCursor,
      cursorType,
      isLimited,
      fullScreenMode,
      handleFullScreenMode
    ]
  )

  const viewActions = useMemo(
    () => [
      ...customActions.map(a => ({
        keyCode: a.key,
        label: a.title,
        leftAdornment: (
          <RenderCombination
            keys={[
              {
                key: <a.icon style={{ fontSize: 12 }} />
              }
            ]}
          />
        ),
        onClick: a.callback
      })),
      {
        keyCode: 39,
        label: 'Move Right',
        leftAdornment: (
          <RenderCombination
            keys={[
              {
                key: <ArrowForwardOutlined style={{ fontSize: 12 }} />
              }
            ]}
          />
        ),
        onClick: onShiftTimeRight
      },
      {
        keyCode: 37,
        label: 'Move Left',
        leftAdornment: (
          <RenderCombination
            keys={[
              {
                key: <ArrowBackOutlined style={{ fontSize: 12 }} />
              }
            ]}
          />
        ),
        onClick: onShiftTimeLeft
      },
      {
        keyCode: 70,
        label: 'Full Screen',
        leftAdornment: (
          <RenderCombination
            keys={[
              {
                key: 'F'
              }
            ]}
          />
        ),
        onClick: handleFullScreenMode
      },
      {
        keyCode: 107,
        isAlt: false,
        label: 'Zoom In',
        leftAdornment: (
          <RenderCombination
            keys={[
              {
                key: '+'
              }
            ]}
          />
        ),
        onClick: onZoomIn
      },
      {
        keyCode: 109,
        isAlt: false,
        label: 'Zoom Out',
        leftAdornment: (
          <RenderCombination
            keys={[
              {
                key: '-'
              }
            ]}
          />
        ),
        onClick: onZoomOut
      },
      {
        isAlt: true,
        label: 'Reset zoom',
        leftAdornment: (
          <RenderCombination
            keys={[
              {
                key: 'Shift'
              },
              {
                key: 'Left click'
              }
            ]}
          />
        ),
        onClick: resetZoom
      }
    ],
    [
      customActions,
      onShiftTimeRight,
      onShiftTimeLeft,
      handleFullScreenMode,
      onZoomIn,
      onZoomOut,
      resetZoom
    ]
  )

  const isMulticolumn = selectedView === 'multiple'

  const dataActions = useMemo(
    () =>
      [
        {
          disabled: isChildWindow || isLimited || isMulticolumn,
          keyCode: 90,
          label: 'Slice Data',
          leftAdornment: <RenderCombination keys={[{ key: 'Z' }]} />,
          onClick: () => setDataManipulationMode(DATA_MANIPULATION_MODE.SLICE)
        },
        {
          disabled: isChildWindow || isLimited || isMulticolumn,
          keyCode: 88,
          label: 'Cut Data',
          leftAdornment: <RenderCombination keys={[{ key: 'X' }]} />,
          onClick: () => setDataManipulationMode(DATA_MANIPULATION_MODE.CUT)
        },
        !hideRasterToggler.hide && {
          id: 'raster-plot-button',
          keyCode: 82,
          label: 'Raster Plot',
          leftAdornment: <RenderCombination keys={[{ key: 'R' }]} />,
          onClick: toggleRasterPlot,
          disabled: hideRasterToggler.disable
        },
        {
          keyCode: 72,
          isAlt: true,
          disabled: isChildWindow,
          label: 'Hide Hightlighted Channels',
          leftAdornment: <RenderCombination keys={[{ key: 'H' }]} />,
          onClick: removeChannelsOnClick
        },
        {
          keyCode: 89,
          isAlt: true,
          label: 'Deselect Hightlighted  Channels',
          leftAdornment: <RenderCombination keys={[{ key: 'Y' }]} />,
          onClick: clearHighlightedChannels
        },
        {
          isAlt: true,
          label: 'Select Channel',
          leftAdornment: (
            <RenderCombination keys={[{ key: 'Ctrl + left click' }]} />
          ),
          onClick: selectCursor.bind(this, 'select_channel')
        }
      ].filter(Boolean),
    [
      isLimited,
      isMulticolumn,
      hideRasterToggler.hide,
      hideRasterToggler.disable,
      toggleRasterPlot,
      removeChannelsOnClick,
      clearHighlightedChannels,
      setDataManipulationMode
    ]
  )

  const eventActions = useMemo(
    () =>
      [
        {
          keyCode: 85,
          label: 'Upload File Event(s)',
          leftAdornment: (
            <RenderCombination maxWidth="26px" keys={[{ key: 'U' }]} />
          ),
          disabled: isChildWindow || isLimited,
          onClick: eventsUploadModal.toggle
        },
        {
          keyCode: 81,
          disabled: isChildWindow || isLimited || !eventsController.hasPrev,
          label: 'Previous Event',
          leftAdornment: (
            <RenderCombination width="26px" keys={[{ key: 'Q' }]} />
          ),
          onClick: eventsController.prev
        },
        {
          keyCode: 87,
          disabled: isChildWindow || isLimited || !eventsController.hasNext,
          label: 'Next Event',
          leftAdornment: (
            <RenderCombination width="26px" keys={[{ key: 'W' }]} />
          ),
          onClick: eventsController.next
        },
        {
          keyCode: 69,
          disabled:
            isChildWindow || isLimited || !eventsController.selectedEvent,
          label: 'Delete Event',
          leftAdornment: (
            <RenderCombination width="26px" keys={[{ key: 'E' }]} />
          ),
          onClick: eventsController.toggleTimestampDeleteModal
        }
      ].filter(Boolean),
    [
      isChildWindow,
      isLimited,
      eventsUploadModal.toggle,
      eventsController.hasPrev,
      eventsController.prev,
      eventsController.hasNext,
      eventsController.next,
      eventsController.selectedEvent,
      eventsController.toggleTimestampDeleteModal
    ]
  )

  let timeSeriesActions = useMemo(
    () => [
      {
        type: 'button',
        title: 'Time zoom in (+)',
        onClick: onZoomIn,
        icon: <DecorateIcon icon={ZoomInOutlined} />
      },
      {
        type: 'button',
        title: 'Time zoom out (-)',
        onClick: onZoomOut,
        icon: <DecorateIcon icon={ZoomOutOutlined} />
      },
      {
        type: 'button',
        title: 'Shift time left [left arrow]',
        onClick: onShiftTimeLeft,
        icon: <DecorateIcon icon={ArrowBackOutlined} />
      },
      {
        type: 'button',
        title: 'Shift time right [right arrow]',
        onClick: onShiftTimeRight,
        icon: <DecorateIcon icon={ArrowForwardOutlined} />
      },
      {
        type: 'button',
        title: 'Reset zoom',
        onClick: resetZoom,
        icon: <DecorateIcon icon={ControlCameraOutlined} />
      },
      ...customActions.map(a => ({
        type: a.type || 'button',
        title: a.title,
        onClick: a.callback,
        icon: <DecorateIcon icon={a.icon} />
      }))
    ],
    [
      customActions,
      onShiftTimeLeft,
      onShiftTimeRight,
      onZoomIn,
      onZoomOut,
      resetZoom
    ]
  )

  return {
    buttons,
    viewActions,
    dataActions,
    eventActions,
    timeSeriesActions,
    cursorType,
    selectCursor
  }
}
