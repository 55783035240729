import {
  svg128_1,
  svg128_2,
  svg128_3,
  svg128_4,
  svg128_5,
  svg128_6,
  svg128_7,
  svg128_8,
  svg128_9,
  svg128_10,
  svg64_1,
  svg64_2,
  svg64_3,
  svg64_4,
  svg64_7,
  svg64_9,
  svg64_11,
  svg64_5,
  svg64_6,
  svg64_8,
  svg64_10,
  svg64_12,
  svg64_13,
  svg64_14,
  svg128_3_auto,
  svg64_3_DA
} from '../components/Icons/Probes'

export const drawings = {
  'ASSY156-Intan-P64-7dbc': {
    svg: svg64_7,
    x: 7,
    y: 80,
    position: 'bottom'
  },
  'ASSY156-P64-7dbc': {
    svg: svg64_7,
    x: 7,
    y: 80,
    position: 'bottom'
  },
  'DA128-1': {
    svg: svg128_3_auto,
    x: 3,
    y: 140,
    position: 'bottom'
  },
  'DA128-1-O': {
    svg: svg128_3_auto,
    x: 3,
    y: 140,
    position: 'bottom'
  },
  'DA128-2': {
    svg: svg128_10,
    x: 3,
    y: 150,
    position: 'bottom'
  },
  'DA128-2-O': {
    svg: svg128_10,
    x: 3,
    y: 150,
    position: 'bottom'
  },
  'DA128-3': {
    svg: svg128_10,
    x: 3,
    y: 150,
    position: 'bottom'
  },
  'DA31-1': {
    svg: svg128_3_auto,
    x: 3,
    y: 140,
    position: 'bottom'
  },
  'DA31-2': {
    svg: svg128_3_auto,
    x: 3,
    y: 140,
    position: 'bottom'
  },
  'DA31-3': {
    svg: svg128_3_auto,
    x: 3,
    y: 140,
    position: 'bottom'
  },
  'DA64-3': {
    svg: svg64_3_DA,
    x: 3,
    y: 30,
    position: 'bottom'
  },
  'DA64-3-KH': {
    svg: svg64_3_DA,
    x: 3,
    y: 30,
    position: 'bottom'
  },
  'DA64-3-KH0': {
    svg: svg64_3_DA,
    x: 3,
    y: 30,
    position: 'bottom'
  },
  'int128-DS64-1dbc': {
    svg: svg64_1,
    x: 35,
    y: 50,
    multigroup: true,
    position: 'bottom'
  },
  'int128-DS64-7dbc': {
    svg: svg64_7,
    x: 7,
    y: 50,
    position: 'bottom',
    multigroup: true
  },
  'int128-DS64-13dbc': {
    svg: svg64_13,
    x: 35,
    y: 25,
    multigroup: true,
    position: 'bottom'
  },
  '128-1': {
    svg: svg128_1,
    x: 0,
    y: 45,
    position: 'bottom'
  },
  '128-2': {
    svg: svg128_2,
    x: 0,
    y: 28,
    position: 'bottom'
  },
  '128-3': {
    svg: svg128_3,
    x: 0,
    y: 50,
    position: 'bottom'
  },
  '128-4': {
    svg: svg128_4,
    x: 0,
    y: 25,
    position: 'bottom'
  },
  '128-5': {
    svg: svg128_5,
    x: 20,
    y: 22,
    position: 'bottom'
  },
  '128-6': {
    svg: svg128_6,
    x: 7,
    y: 30,
    position: 'bottom'
  },
  '128-7': {
    svg: svg128_7,
    x: 0,
    y: 35,
    position: 'bottom'
  },
  '128-8': {
    svg: svg128_8,
    x: 29,
    y: 28,
    position: 'bottom'
  },
  '128-9': {
    svg: svg128_9,
    x: 0,
    y: 28,
    position: 'bottom'
  },
  '64-1': {
    svg: svg64_1,
    x: 32,
    y: 50,
    position: 'bottom'
  },
  '64-2': {
    svg: svg64_2,
    x: 5,
    y: 40,
    position: 'bottom'
  },
  '64-3': {
    svg: svg64_3,
    x: 2,
    y: 40,
    position: 'bottom'
  },
  '64-4': {
    svg: svg64_4,
    x: 2,
    y: 40,
    position: 'bottom'
  },
  '64-5': {
    svg: svg64_5,
    x: 0,
    y: 60,
    position: 'bottom'
  },
  '64-6': {
    svg: svg64_6,
    x: 0,
    y: 90,
    position: 'bottom'
  },
  '64-7': {
    svg: svg64_7,
    x: 7,
    y: 80,
    position: 'bottom'
  },
  '64-8': {
    svg: svg64_8,
    x: 7,
    y: 45,
    position: 'bottom'
  },
  '64-9': {
    svg: svg64_9,
    x: 0,
    y: 105,
    position: 'bottom'
  },
  '64-10': {
    svg: svg64_10,
    x: 29,
    y: 30,
    position: 'bottom'
  },
  '64-11': {
    svg: svg64_11,
    x: 0,
    y: 40,
    position: 'bottom'
  },
  '64-12': {
    svg: svg64_12,
    x: 2,
    y: 150,
    position: 'bottom'
  },
  '64-13': {
    svg: svg64_13,
    x: 35,
    y: 25,
    position: 'bottom'
  },
  '64-14': {
    svg: svg64_14,
    x: 2,
    y: 100,
    position: 'bottom'
  }
}
