import {
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  ACCEPT_TERMS,
  LOGOUT_USER,
  STORE_USER_EMAIL
} from '../actions/login'

const initialState = {
  loading: false,
  currentUser: null,
  error: null
}

const loginReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case START_LOGIN:
      return { ...state, loading: true }
    case LOGIN_SUCCESS:
      return { ...state, loading: false, currentUser: payload }
    case ACCEPT_TERMS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          user: {
            ...state.currentUser.user,
            signed_policy: true
          }
        }
      }
    case LOGOUT_USER:
      return { ...state, loading: false, currentUser: null }
    case LOGIN_ERROR:
      return { ...state, loading: false, error: payload }
    case STORE_USER_EMAIL:
      return { ...state, loading: false, currentUserEmail: payload }
    default:
      return state
  }
}

export default loginReducer
