import React from 'react'
import { useTheme } from '@material-ui/core'

const AltKeyIcon = props => {
  const theme = useTheme()
  const color = theme.palette.colors.customColor(
    theme.palette.colors.neutral[2],
    theme.palette.colors.white
  )
  return (
    <svg
      {...props}
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 0.992188H3.555L7.075 7.99219H9.5V8.99219H6.44L2.92 1.99219H0.5V0.992188ZM6 0.992188H9.5V1.99219H6V0.992188Z"
        fill={color}
      />
    </svg>
  )
}

export default AltKeyIcon
