import React from 'react'

const SelectEventCursor = props => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.7078 13.4408L16.2418 14.3674C15.9766 14.4361 15.863 14.7106 16.0145 14.9166L18.7987 18.7947C18.9123 18.9663 18.8745 19.1894 18.6851 19.3095L16.7532 20.4421C16.5638 20.545 16.3175 20.5107 16.1849 20.3391L13.4007 16.461C13.2492 16.255 12.9272 16.2379 12.7568 16.4266L10.3892 18.8977C10.1619 19.1379 9.72632 19.0178 9.6695 18.7089L8.00276 6.91998C7.96488 6.6111 8.32474 6.38802 8.62779 6.55962L19.8025 12.7716C20.1056 12.9432 20.0488 13.355 19.7078 13.4408Z"
        fill="#FEAC4C"
      />
      <line
        x1="4.5"
        y1="2.5"
        x2="4.5"
        y2="22.5"
        stroke="#FEAC4C"
        strokeDasharray="2 2"
      />
    </svg>
  )
}

export default SelectEventCursor
