export const helpers = DS => {
  const sibling = nodeId => {
    return Array.from(DS.values()).find(
      node => node.id !== nodeId && DS.get(nodeId).parentId === node.parentId
    )
  }

  const parent = node => DS.get(node.parentId)

  return {
    sibling,
    parent
  }
}

export const recursiveFindAncestor = (DS, node, levelsSearched = 0) => {
  if (node?.value) return { ...node, levelsSearched }
  if (node?.parentId === 0) return { ...node, levelsSearched }

  const DSNode = DS.get(node.id)
  const parentNode = DS.get(DSNode.parentId)

  return recursiveFindAncestor(DS, parentNode, levelsSearched + 1)
}

export const clearChildrens = (node, DS) => {
  if (!node?.hasChildren) return node

  const childs = Array.from(DS.values()).filter(x => x.parentId === node.id)

  childs.forEach(child => {
    DS.set(child.id, { ...child, value: null, widgetId: null })
    clearChildrens(child, DS)
  })
}

export const enableHorizontalParentNode = (
  newLayoutDS,
  nodeRef,
  horizontalNodes,
  currentNodeID
) => {
  const disableNodes = horizontalNodes.find(value => value.id === currentNodeID)

  let verticalParents = [7, 8].includes(currentNodeID) ? [3, 4] : [5, 6]

  newLayoutDS.set(verticalParents[0], {
    ...newLayoutDS.get(verticalParents[0]),
    value: null,
    widgetId: null,
    reverse: false
  })
  newLayoutDS.set(verticalParents[1], {
    ...newLayoutDS.get(verticalParents[1]),
    value: null,
    widgetId: null,
    reverse: false
  })

  for (let [k, v] of newLayoutDS) {
    if (Number(k) === disableNodes?.id) {
      newLayoutDS.set(k, { ...v, visible: true, value: null, widgetId: null })
    } else if (disableNodes?.nodes.includes(Number(k))) {
      newLayoutDS.set(k, { ...v, visible: false, value: null, widgetId: null })
    } else if (
      Number(k) === disableNodes?.oppositeNode &&
      newLayoutDS.get(disableNodes?.oppositeNode).value == null
    ) {
      newLayoutDS.set(k, { ...v, visible: false, value: null, widgetId: null })
    } else {
      if (
        Number(k) === disableNodes?.reverseNode &&
        newLayoutDS.get(disableNodes?.oppositeNode).value == null
      ) {
        newLayoutDS.set(k, { ...v, reverse: true })
      }
      if (nodeRef.current !== null && v.value === nodeRef.current.value) {
        newLayoutDS.set(k, { ...v, value: null, widgetId: null })
      }
    }
  }

  return newLayoutDS
}

export const enableChildDisabledNodes = (
  newLayoutDS,
  disableNodes,
  horizontalNodes,
  nodeRef,
  currentNodeID
) => {
  for (let [k, v] of newLayoutDS) {
    //Disable horizontal node
    if (Number(k) === disableNodes?.id) {
      newLayoutDS.set(k, {
        ...v,
        visible: false,
        value: null,
        widgetId: null
      })
    } else if (nodeRef.current !== null && v.value === nodeRef.current.value) {
      newLayoutDS.set(k, { ...v, value: null, widgetId: null })
    } else {
      if (
        disableNodes?.id % 2 === 0 &&
        newLayoutDS.get(disableNodes?.id - 1).value !== null
      ) {
        const opposite = horizontalNodes.find(
          value => value.id === disableNodes?.id - 1
        ).reverseNode

        if (Number(k) === disableNodes?.id - 1) {
          newLayoutDS.set(opposite, {
            ...newLayoutDS.get(opposite),
            reverse: true
          })
        }
      } else if (
        disableNodes?.id % 2 !== 0 &&
        newLayoutDS.get(disableNodes?.id + 1).value !== null
      ) {
        const opposite = horizontalNodes.find(
          value => value.id === disableNodes?.id + 1
        ).reverseNode
        if (Number(k) === opposite) {
          newLayoutDS.set(opposite, {
            ...newLayoutDS.get(opposite),
            reverse: true
          })
        }
      }

      if (disableNodes?.nodes.includes(Number(k))) {
        if (v.id === currentNodeID || v.value === nodeRef.current.value) {
          newLayoutDS.set(k, {
            ...v,
            visible: true,
            value: null,
            widgetId: null
          })
        } else {
          newLayoutDS.set(k, { ...v, visible: true })
        }
      }

      if (Number(k) === disableNodes?.reverseNode) {
        newLayoutDS.set(k, { ...v, reverse: false })
      }
    }
  }
  return newLayoutDS
}

export const enableMainParentNode = (n, nodeRef, items, currentNodeID) => {
  let generalNodes = [
    {
      node: 1,
      children: [3, 4, 7, 8, 11, 12, 13, 14]
    },
    { node: 2, children: [5, 6, 9, 10, 15, 16, 17, 18] }
  ]
  let gNode = generalNodes.find(value => value.node === currentNodeID)
  for (let [k, v] of n) {
    if (gNode?.children.includes(k)) {
      n.set(
        k,
        items.find(value => value.id === Number(k))
      )
    }

    if (nodeRef.current !== null && v.id === currentNodeID) {
      n.set(k, {
        ...v,
        visible: true,
        value: null,
        widgetId: null
      })
    }

    if (v.id === currentNodeID) {
      n.set(k, { ...v, value: null, widgetId: null })
    }

    if (nodeRef.current !== null && v.value === nodeRef.current.value) {
      n.set(k, { ...v, value: null, widgetId: null })
    }
  }
  return n
}

export const enableVerticalParentNode = (
  newLayoutDS,
  nodeRef,
  vNode,
  currentNodeID
) => {
  const verticalParents = [3, 4, 11, 12, 13, 14].includes(currentNodeID)
    ? [3, 4]
    : [5, 6]

  const mainParent = [3, 4, 11, 12, 13, 14].includes(currentNodeID) ? 1 : 2

  const horizontalNodes = [3, 4, 11, 12, 13, 14].includes(currentNodeID)
    ? [7, 8]
    : [9, 10]

  let hNodesChildren = [
    {
      node: 7,
      children: [11, 13]
    },
    { node: 8, children: [12, 14] },
    { node: 9, children: [15, 17] },
    { node: 10, children: [16, 18] }
  ]

  for (let [k, v] of newLayoutDS) {
    const resetValue = { ...v, value: null, widgetId: null }
    if (verticalParents.includes(Number(k))) {
      newLayoutDS.set(k, {
        ...v,
        reverse: false
      })

      const isVerticalParentFilled =
        (vNode &&
          Number(k) === vNode?.node &&
          v.value !== null &&
          vNode.children.includes(currentNodeID)) ||
        (nodeRef.current !== null && v.value === nodeRef.current.value)

      if (isVerticalParentFilled) {
        newLayoutDS.set(k, resetValue)
      }
    } else {
      const isMainParentFilled = Number(k) === mainParent && v.value !== null
      if (isMainParentFilled) {
        newLayoutDS.set(k, resetValue)
      }

      //Disable horizontal nodes
      if (horizontalNodes.includes(Number(k))) {
        newLayoutDS.set(k, {
          ...resetValue,
          visible: false
        })
        let hNodeChild = hNodesChildren.find(value => value.node === Number(k))
        hNodeChild.children.forEach(node =>
          newLayoutDS.set(node, {
            ...newLayoutDS.get(node),
            visible: true
          })
        )
      }

      const isMakeNodeVisible =
        nodeRef.current !== null && v.id === currentNodeID

      if (isMakeNodeVisible) {
        newLayoutDS.set(k, {
          ...resetValue,
          visible: true
        })
      }

      const isChildFilled =
        vNode.children.includes(Number(k)) &&
        v.value !== null &&
        currentNodeID === vNode.node

      const isSetNodeValueToNull =
        v.id === currentNodeID ||
        (nodeRef.current !== null && v.value === nodeRef.current.value) ||
        isChildFilled

      if (isSetNodeValueToNull) {
        newLayoutDS.set(k, resetValue)
      }
    }
  }

  return newLayoutDS
}
