import React, { useCallback, useMemo, useState } from 'react'
import { getUploadDate } from '../../../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import TableCheckbox from '../../../../CustomTable/components/TableCheckbox'
import { IconButton } from '@material-ui/core'
import { DeleteRounded, VisibilityRounded } from '@material-ui/icons'
import {
  deleteDefaultCuration,
  selectCuration
} from '../../../../../actions/curations'
import {
  deleteCuration,
  createCuration,
  editCuration
} from '../../../../../endpoints/admin'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import { NOTIFICATION } from '../../../../../utils/consts'
import { QPARAMS } from '../../../../../utils/consts'
import useQueryParams from '../../../../../hooks/useQueryParams'
import useSearchQuery from '../../../../../hooks/useSearchQuery'
import useSpikeSortingInfo from '../../../hooks/useSpikeSortingInfo'
import ApproveComponent from '../../WidgetsLayout/widgets/ClusterView/components/ApproveComponent'

function useCurationList({ fetchCurations, toggleDeleteModal, isLimited }) {
  const [loading, setLoading] = useState(null)
  const addNotification = useNotification()
  const pages = useSelector(state => state.recordingStore.curations_pages)
  const { run_id, isSorted, default_curation } = useSpikeSortingInfo()
  const currentUser = useSelector(state => state.login?.currentUser) || {}
  const allCurations = useSelector(state => state.recordingStore.curations)
  const curations = allCurations.get(run_id) || []
  const isCurations = curations?.length > 1
  const dispatch = useDispatch()
  const store = useCallback(data => dispatch(selectCuration(data)), [dispatch])
  const resetDefaultCuration = useCallback(
    () => dispatch(deleteDefaultCuration()),
    [dispatch]
  )

  const [itemToDelete, setItemToDelete] = useState(null)

  const handleSelectCuration = useCallback(
    (id, type) => store({ id, type }),
    [store]
  )

  const query = useQueryParams()
  const { insertSearchQuery } = useSearchQuery()

  const handleEditCuration = useCallback(
    async ID => {
      const data = { id: ID, default: 1 }
      try {
        setLoading(true)
        const res = await editCuration(data)
        if (res?.status === 200) {
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Row edited successfully'
          })
          fetchCurations()
          handleSelectCuration(ID, 'default')
        }
        setLoading(false)
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
        setLoading(false)
      }
    },
    [addNotification, fetchCurations]
  )

  const handleDelete = useCallback(
    async ID => {
      if (!ID) return
      try {
        setLoading(true)
        const res = await deleteCuration({ ids: [ID] })
        if (res?.status === 200) {
          const isDefaultCuration = ID === default_curation?.id
          if (isDefaultCuration) resetDefaultCuration()
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Row deleted successfully'
          })

          const isLastCuration = curations?.length <= 2

          if (isLastCuration) {
            const prevCurationPage =
              query.getByName(QPARAMS.CURATIONS_PAGE) - 1 || 1

            insertSearchQuery({
              key: QPARAMS.CURATIONS_PAGE,
              value: prevCurationPage
            })
          } else fetchCurations()
        }
        setLoading(false)
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
        setLoading(false)
      }
    },
    [addNotification, fetchCurations, default_curation]
  )

  const errors = [
    {
      show: !run_id,
      message: 'This dataset doesn’t have any spikesortings yet'
    },
    {
      show: !isSorted,
      message:
        "Spikesorting of this record isn't completed, you can't create new curation"
    }
  ]

  const handleCreate = useCallback(async () => {
    const isError = errors.find(item => item.show)
    if (isError) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: isError.message
      })
      return
    }
    try {
      setLoading({ create: true })
      const res = await createCuration(run_id)
      if (res?.status === 200) {
        addNotification({
          type: NOTIFICATION.SUCCESS,
          title: 'Curation successfully created !'
        })
        fetchCurations()
      }
      setLoading(null)
    } catch (e) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
      setLoading(null)
    }
  }, [run_id, fetchCurations, errors])

  const renderActionButton = useCallback(
    id => {
      const isInitial = curations.find(item => item?.id === id)?.initial
      return (
        <React.Fragment>
          <IconButton>
            <VisibilityRounded />
          </IconButton>
          {!isInitial && (
            <IconButton
              onClick={e => {
                e.stopPropagation()
                setItemToDelete(id)
                return toggleDeleteModal()
              }}
            >
              <DeleteRounded />
            </IconButton>
          )}
        </React.Fragment>
      )
    },
    [handleDelete, curations]
  )

  const renderCheckbox = useCallback(
    (isDefault, id) => (
      <TableCheckbox
        isDefault={isDefault}
        onClick={e => {
          e.stopPropagation()
          if (!isDefault && !isLimited) handleEditCuration(id)
        }}
      />
    ),
    [handleEditCuration, isLimited]
  )

  const rows = useMemo(
    () =>
      curations?.map(item => {
        const {
          created_at,
          id,
          name,
          approved,
          author_id,
          default: isDefault,
          user_name,
          single_units,
          split_steps,
          merge_steps,
          initial,
          uncurated_units
        } = item
        const date = getUploadDate(created_at)
        const isAuthor = currentUser?.user.id === author_id
        const status = uncurated_units ? null : (
          <ApproveComponent
            large
            curationId={id}
            isAuthor={isAuthor}
            isApproved={approved}
            uncuratedUnits={uncurated_units}
          />
        )
        return {
          id,
          name: initial ? name : id,
          user_name,
          status: initial ? null : status,
          single_units,
          split_steps,
          merge_steps,
          initial,
          default: !initial ? renderCheckbox(isDefault, id) : null,
          date
        }
      }),
    [curations, renderCheckbox]
  )

  const columns = [
    {
      id: 0,
      label: 'Curation ID',
      active: true
    },
    {
      id: 1,
      label: 'Author',
      active: true
    },
    {
      id: 1,
      label: 'Status',
      active: true
    },
    {
      id: 2,
      label: 'Single Units',
      active: true
    },
    {
      id: 3,
      label: 'Split steps',
      active: true
    },
    {
      id: 4,
      label: 'Merge steps',
      active: true
    },
    {
      id: 5,
      label: 'Default',
      active: true
    },
    {
      id: 6,
      label: 'Date',
      active: true
    }
  ]

  return {
    columns,
    rows,
    pages,
    isCurations,
    renderActionButton,
    handleCreate,
    handleDelete,
    itemToDelete,
    loading,
    handleClickRow: handleSelectCuration
  }
}

export default useCurationList
