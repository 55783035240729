import { useDispatch } from 'react-redux'
import { ACTIONS } from 'react-joyride'
import { nextOrPrevTour } from '../../../actions/tours'

export default function useToursController(isOpened, toggleDownloadModal) {
  const dispatch = useDispatch()

  const nextOrPrev = (index, cdt) => dispatch(nextOrPrevTour(index, cdt))

  const nextTour = data => {
    const { action, index } = data
    const isPrev = action === ACTIONS.PREV

    if ((isOpened && index === 1) || (index === 2 && isPrev)) {
      toggleDownloadModal()
      const tableRow = document.querySelector('.actions-table-row')
      if (tableRow) tableRow.style.opacity = 1
      const pTag = document.querySelector('.actions-table-row+p')
      if (pTag) pTag.style.opacity = 0
    }

    if (index === 2) {
      const tableRow = document.querySelector('.actions-table-row')
      if (tableRow) tableRow.style.opacity = ''
      const pTag = document.querySelector('.actions-table-row+p')
      if (pTag) pTag.style.opacity = 1
    }

    setTimeout(() => nextOrPrev(index, isPrev), 0)
  }

  return { nextTour }
}
