import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import RecordingHeader from './components/RecordingHeader'
import FullScreenLoader from '../FullScreenLoader'
import useRecordingView from '../NewRecordingView/hooks/useRecordingView'
import useGetSpikesortings from '../NewRecordingView/hooks/useGetSpikesortings'
import cn from 'classnames'
import SideComponents from './components/SideComponents'
import { SelectPositionModal } from './components/WidgetsLayout'
import { useComposeNodes } from './components/WidgetsLayout/hooks'
import Tree from './components/WidgetsLayout/components/Tree'
import WidgetWrapper from './components/WidgetsLayout/components/WidgetWrapper'
import { actions } from './components/WidgetsLayout/widgets/actions'
import ErrorPageRedirector from '../ErrorPageRedirector'
import ToursWrapper from '../ToursWrapper'
import useTours from '../ToursWrapper/hooks/useTours'
import useToursController from './hooks/useToursController'
import { DragProvider } from './components/WidgetsLayout/contexts/drag'
import RunSpikeSortingPrompt from './components/RunSpikeSortingPrompt'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    background: theme.palette.colors.backgrounds.main
  },
  container: theme.container({
    flex: 1,
    display: 'flex',
    height: 'auto',
    background: theme.palette.colors.backgrounds.main,
    '&::-webkit-scrollbar': { width: 2, height: 2 },
    '&::-webkit-scrollbar-track': { background: 'transparent' },
    '&::-webkit-scrollbar-corner': { background: 'transparent' },
    '&::-webkit-scrollbar-thumb': { background: theme.palette.primary.main }
  }),
  infoPannel: {
    borderTop: `1px solid ${theme.palette.colors.backgrounds.main}`,
    background: theme.palette.colors.backgrounds.pannel
  },
  body: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: 0,
    justifyContent: 'center',
    overflow: 'hidden'
  },
  widget: {}
}))

const ComposeBody = ({ children, recordingId }) => {
  const classes = useStyles()

  const {
    layoutDS,
    state,
    updateNodes,
    onSaveLayout,
    clearWidget,
    clearAllWidgets,
    moveWidget,
    resetModal
  } = useComposeNodes()

  const { nextTour } = useToursController()

  const { callback, tourState } = useTours(nextTour)

  return (
    <ToursWrapper callback={callback} tourState={tourState}>
      {children(
        new Set(
          Array.from(layoutDS.values())
            .map(w => w.widgetId)
            .filter(w => w)
        ),
        clearAllWidgets
      )}
      <div className={cn(classes.body, classes.container)}>
        <DragProvider>
          <Tree
            treeData={layoutDS}
            recordingId={recordingId}
            clearWidget={clearWidget}
            unwrappChild
          >
            <WidgetWrapper
              className={classes.widget}
              actions={actions}
              moveWidget={moveWidget}
            />
          </Tree>
        </DragProvider>
      </div>
      <SelectPositionModal
        state={state}
        updateNodes={updateNodes}
        onSaveLayout={onSaveLayout}
        resetModal={resetModal}
      />
    </ToursWrapper>
  )
}

const NewRecordingView = props => {
  const { recording, selectedSorting } = props
  const classes = useStyles()
  const { loading, error } = useRecordingView()
  useGetSpikesortings()

  if (loading) {
    return <FullScreenLoader mainView={true} />
  }

  if (error) return <ErrorPageRedirector error={error} />

  return (
    <Grid container direction="column" className={classes.root}>
      <ComposeBody recordingId={recording.id}>
        {(layoutDS, clearAllWidgets) => (
          <RecordingHeader
            fileName={recording?.name}
            folderId={recording?.folder_id}
            selectedSorting={selectedSorting}
            layoutDS={layoutDS}
            clearAllWidgets={clearAllWidgets}
          />
        )}
      </ComposeBody>
      <RunSpikeSortingPrompt />
      <SideComponents />
    </Grid>
  )
}

const mapStateToProps = state => ({
  recording: state.recordingStore.recording,
  selectedSorting: state.recordingStore.selectedSorting
})

export default connect(mapStateToProps)(NewRecordingView)
