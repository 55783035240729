import React from 'react'
import { Box, Typography, Avatar, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import useLogout from '../../hooks/useLogout'
import { LoadingSpinner } from '../RecordingViewWithCuration/components/WidgetsLayout/widgets/CorrelogramView/components'
import noPreview from '../../imgs/nopreview.png'
import ErrorPageLayout from '../ErrorPageLayout'

const useStyles = makeStyles(theme => ({
  userContainer: {
    marginTop: 50,
    marginBottom: 20,
    display: 'flex',

    alignItems: 'center'
  },
  signedIn: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.colors.neutral.black
  },
  userInfo: {
    display: 'flex',
    marginLeft: 20,
    alignItems: 'center'
  },
  avatar: {
    marginRight: 20
  },
  name: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.colors.neutral.black
  },
  company: {
    fontSize: 12,
    fontWeight: 400
  },
  button: {
    fontWeight: 600,
    textTransform: 'none',
    marginRight: 20,
    color: theme.palette.colors.white,
    border: `2px ${theme.palette.primary} solid`,
    borderRadius: 8
  },
  outlindButton: {
    color: theme.palette.primary.text,
    borderColor: theme.palette.primary.text,
    '&:hover': {
      color: 'inherit',
      borderColor: 'inherit'
    }
  }
}))

function UnauthorizedPage() {
  const currentUser = useSelector(state => state.login.currentUser)
  const logout = useLogout()
  const classes = useStyles()
  const history = useHistory()
  const { user } = currentUser || {}
  const { name: userName = 'Name', image, company } = user || {}
  const { name: companyName = 'Company name' } = company || {}
  const profileImage = image || noPreview

  const toFiles = () => history.push('/files')

  if (!currentUser) return <LoadingSpinner />

  return (
    <ErrorPageLayout
      title="Sorry..."
      subTitle="maybe you don't have the right permissions"
      content={
        <>
          You don't have permissions to see this dataset,
          <br /> it does not belong to your organization.
          <br />
          Please try to log in with a user belonging
          <br /> to the right organization.
        </>
      }
    >
      <Box className={classes.userContainer}>
        <Typography className={classes.signedIn}>
          YOU ARE signed IN as
        </Typography>
        <Box className={classes.userInfo}>
          <Avatar className={classes.avatar} alt="Avatar" src={profileImage} />
          <Box>
            <Typography className={classes.name}>{userName}</Typography>
            <Typography className={classes.company}>{companyName}</Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.actions}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
          onClick={toFiles}
        >
          Go to files
        </Button>
        <Button
          variant="outlined"
          size="large"
          color="primary"
          className={cn(classes.button, classes.outlindButton)}
          onClick={logout}
        >
          Switch account
        </Button>
      </Box>
    </ErrorPageLayout>
  )
}

export default UnauthorizedPage
