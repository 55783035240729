import {
  ADD_PROBES,
  LOADING_PROBES,
  SET_CURRENT_PROBE,
  SET_PROBE_OPTIONS
} from '../actions/probes'

const initialState = {
  probes: null,
  currentProbe: null,
  pages: 1,
  loading: false,
  probeOptions: null
}

const probes = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_PROBES: {
      const { data, ...res } = payload || {}
      return { ...state, probes: data, ...res, loading: false }
    }
    case LOADING_PROBES: {
      return { ...state, loading: !state.loading }
    }
    case SET_CURRENT_PROBE: {
      const id = payload
      const { probes } = state
      const currentProbe = probes?.find(probe => probe.id === id) ?? null
      return { ...state, currentProbe }
    }
    case SET_PROBE_OPTIONS: {
      return { ...state, probeOptions: payload }
    }
    default:
      return state
  }
}

export default probes
