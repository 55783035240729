import React from 'react'

export const svg64_8 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="61"
        height="850"
        viewBox="0 0 61 850"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.421875 804.261V0H60.4219V268.665L45.502 804.261L24.002 850L0.421875 804.261Z"
          fill={color}
        />
        <path
          d="M4 137C4 135.343 5.34315 134 7 134H19C20.6569 134 22 135.343 22 137V148.077C22 149.734 20.6569 151.077 19 151.077H7C5.34315 151.077 4 149.734 4 148.077V137Z"
          fill="white"
        />
        <path
          d="M4 183.077C4 181.42 5.34315 180.077 7 180.077H19C20.6569 180.077 22 181.42 22 183.077V194.154C22 195.811 20.6569 197.154 19 197.154H7C5.34315 197.154 4 195.811 4 194.154V183.077Z"
          fill="white"
        />
        <path
          d="M4 229.154C4 227.497 5.34315 226.154 7 226.154H19C20.6569 226.154 22 227.497 22 229.154V240.231C22 241.888 20.6569 243.231 19 243.231H7C5.34315 243.231 4 241.888 4 240.231V229.154Z"
          fill="white"
        />
        <path
          d="M4 275.231C4 273.574 5.34315 272.231 7 272.231H19C20.6569 272.231 22 273.574 22 275.231V286.308C22 287.964 20.6569 289.308 19 289.308H7C5.34315 289.308 4 287.964 4 286.308V275.231Z"
          fill="white"
        />
        <path
          d="M4 321.308C4 319.651 5.34315 318.308 7 318.308H19C20.6569 318.308 22 319.651 22 321.308V332.385C22 334.041 20.6569 335.385 19 335.385H7C5.34315 335.385 4 334.041 4 332.385V321.308Z"
          fill="white"
        />
        <path
          d="M4 367.385C4 365.728 5.34315 364.385 7 364.385H19C20.6569 364.385 22 365.728 22 367.385V378.461C22 380.118 20.6569 381.461 19 381.461H7C5.34315 381.461 4 380.118 4 378.461V367.385Z"
          fill="white"
        />
        <path
          d="M4 413.461C4 411.805 5.34315 410.461 7 410.461H19C20.6569 410.461 22 411.805 22 413.461V424.538C22 426.195 20.6569 427.538 19 427.538H7C5.34315 427.538 4 426.195 4 424.538V413.461Z"
          fill="white"
        />
        <path
          d="M4 459.539C4 457.882 5.34315 456.539 7 456.539H19C20.6569 456.539 22 457.882 22 459.539V470.615C22 472.272 20.6569 473.615 19 473.615H7C5.34315 473.615 4 472.272 4 470.615V459.539Z"
          fill="white"
        />
        <path
          d="M4 505.615C4 503.958 5.34315 502.615 7 502.615H19C20.6569 502.615 22 503.958 22 505.615V516.692C22 518.349 20.6569 519.692 19 519.692H7C5.34315 519.692 4 518.349 4 516.692V505.615Z"
          fill="white"
        />
        <path
          d="M4 551.692C4 550.036 5.34315 548.692 7 548.692H19C20.6569 548.692 22 550.036 22 551.692V562.769C22 564.426 20.6569 565.769 19 565.769H7C5.34315 565.769 4 564.426 4 562.769V551.692Z"
          fill="white"
        />
        <path
          d="M4 597.769C4 596.112 5.34315 594.769 7 594.769H19C20.6569 594.769 22 596.112 22 597.769V608.846C22 610.503 20.6569 611.846 19 611.846H7C5.34315 611.846 4 610.503 4 608.846V597.769Z"
          fill="white"
        />
        <path
          d="M4 643.846C4 642.189 5.34315 640.846 7 640.846H19C20.6569 640.846 22 642.189 22 643.846V654.923C22 656.58 20.6569 657.923 19 657.923H7C5.34315 657.923 4 656.58 4 654.923V643.846Z"
          fill="white"
        />
        <path
          d="M4 689.923C4 688.266 5.34315 686.923 7 686.923H19C20.6569 686.923 22 688.266 22 689.923V701C22 702.657 20.6569 704 19 704H7C5.34315 704 4 702.657 4 701V689.923Z"
          fill="white"
        />
        <path
          d="M4 736C4 734.343 5.34315 733 7 733H19C20.6569 733 22 734.343 22 736V747.077C22 748.734 20.6569 750.077 19 750.077H7C5.34315 750.077 4 748.734 4 747.077V736Z"
          fill="white"
        />
        <path
          d="M4 782.077C4 780.42 5.34315 779.077 7 779.077H19C20.6569 779.077 22 780.42 22 782.077V793.154C22 794.81 20.6569 796.154 19 796.154H7C5.34315 796.154 4 794.81 4 793.154V782.077Z"
          fill="white"
        />
        <path
          d="M25 121C25 119.343 26.3431 118 28 118H40C41.6569 118 43 119.343 43 121V132.077C43 133.734 41.6569 135.077 40 135.077H28C26.3431 135.077 25 133.734 25 132.077V121Z"
          fill="white"
        />
        <path
          d="M25 167.077C25 165.42 26.3431 164.077 28 164.077H40C41.6569 164.077 43 165.42 43 167.077V178.154C43 179.811 41.6569 181.154 40 181.154H28C26.3431 181.154 25 179.811 25 178.154V167.077Z"
          fill="white"
        />
        <path
          d="M25 213.154C25 211.497 26.3431 210.154 28 210.154H40C41.6569 210.154 43 211.497 43 213.154V224.231C43 225.888 41.6569 227.231 40 227.231H28C26.3431 227.231 25 225.888 25 224.231V213.154Z"
          fill="white"
        />
        <path
          d="M25 259.231C25 257.574 26.3431 256.231 28 256.231H40C41.6569 256.231 43 257.574 43 259.231V270.308C43 271.964 41.6569 273.308 40 273.308H28C26.3431 273.308 25 271.964 25 270.308V259.231Z"
          fill="white"
        />
        <path
          d="M25 305.308C25 303.651 26.3431 302.308 28 302.308H40C41.6569 302.308 43 303.651 43 305.308V316.385C43 318.041 41.6569 319.385 40 319.385H28C26.3431 319.385 25 318.041 25 316.385V305.308Z"
          fill="white"
        />
        <path
          d="M25 351.385C25 349.728 26.3431 348.385 28 348.385H40C41.6569 348.385 43 349.728 43 351.385V362.461C43 364.118 41.6569 365.461 40 365.461H28C26.3431 365.461 25 364.118 25 362.461V351.385Z"
          fill="white"
        />
        <path
          d="M25 397.461C25 395.805 26.3431 394.461 28 394.461H40C41.6569 394.461 43 395.805 43 397.461V408.538C43 410.195 41.6569 411.538 40 411.538H28C26.3431 411.538 25 410.195 25 408.538V397.461Z"
          fill="white"
        />
        <path
          d="M25 443.539C25 441.882 26.3431 440.539 28 440.539H40C41.6569 440.539 43 441.882 43 443.539V454.615C43 456.272 41.6569 457.615 40 457.615H28C26.3431 457.615 25 456.272 25 454.615V443.539Z"
          fill="white"
        />
        <path
          d="M25 489.615C25 487.958 26.3431 486.615 28 486.615H40C41.6569 486.615 43 487.958 43 489.615V500.692C43 502.349 41.6569 503.692 40 503.692H28C26.3431 503.692 25 502.349 25 500.692V489.615Z"
          fill="white"
        />
        <path
          d="M25 535.692C25 534.036 26.3431 532.692 28 532.692H40C41.6569 532.692 43 534.036 43 535.692V546.769C43 548.426 41.6569 549.769 40 549.769H28C26.3431 549.769 25 548.426 25 546.769V535.692Z"
          fill="white"
        />
        <path
          d="M25 581.769C25 580.112 26.3431 578.769 28 578.769H40C41.6569 578.769 43 580.112 43 581.769V592.846C43 594.503 41.6569 595.846 40 595.846H28C26.3431 595.846 25 594.503 25 592.846V581.769Z"
          fill="white"
        />
        <path
          d="M25 627.846C25 626.189 26.3431 624.846 28 624.846H40C41.6569 624.846 43 626.189 43 627.846V638.923C43 640.58 41.6569 641.923 40 641.923H28C26.3431 641.923 25 640.58 25 638.923V627.846Z"
          fill="white"
        />
        <path
          d="M25 673.923C25 672.266 26.3431 670.923 28 670.923H40C41.6569 670.923 43 672.266 43 673.923V685C43 686.657 41.6569 688 40 688H28C26.3431 688 25 686.657 25 685V673.923Z"
          fill="white"
        />
        <path
          d="M25 720C25 718.343 26.3431 717 28 717H40C41.6569 717 43 718.343 43 720V731.077C43 732.734 41.6569 734.077 40 734.077H28C26.3431 734.077 25 732.734 25 731.077V720Z"
          fill="white"
        />
        <path
          d="M25 766.077C25 764.42 26.3431 763.077 28 763.077H40C41.6569 763.077 43 764.42 43 766.077V777.154C43 778.81 41.6569 780.154 40 780.154H28C26.3431 780.154 25 778.81 25 777.154V766.077Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="61"
      height="850"
      viewBox="0 0 61 850"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        d="M0.419922 804.261V0H60.4199V268.665L45.5 804.261L24 850L0.419922 804.261Z"
        fill={color}
      />
    </svg>
  )
}
