import { makeStyles } from '@material-ui/core'
import {
  FILES_PAGINATOR_HEIGHT,
  TABLE_SCROLLBAR_SIZE
} from '../../utils/styles/CONSTANTS'

const useStyles = makeStyles(theme => ({
  root: ({ withBorders = true }) => ({
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
    background: withBorders
      ? theme.palette.colors.backgrounds.contrast
      : 'transparent',
    border: withBorders
      ? `1px solid  ${theme.palette.colors.neutral['8']}`
      : 'none',
    borderRadius: 4
  }),
  emptyRoot: {
    flex: 1,
    overflow: 'hidden',
    position: 'relative'
  },
  tableRoot: ({ withPagination }) => ({
    position: 'relative',
    overflow: withPagination ? 'scroll' : 'auto',
    paddingBottom: withPagination ? FILES_PAGINATOR_HEIGHT : 0,
    height: '100%',
    '&::-webkit-scrollbar': {
      width: TABLE_SCROLLBAR_SIZE,
      height: TABLE_SCROLLBAR_SIZE,
      zIndex: 1
    },
    '&::-webkit-scrollbar-track': { background: 'transparent' },
    '&::-webkit-scrollbar-corner': { background: 'transparent' },
    '&::-webkit-scrollbar-thumb': { background: theme.palette.primary.main }
  }),
  loadingRoot: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto',
    backdropFilter: 'blur(2px)'
  },
  loading: {
    height: 150,
    display: 'table-row'
  },
  tableHead: ({ withBorders = true }) => ({
    '& th': {
      height: 50,
      minWidth: 50,
      zIndex: 5,
      padding: '8px 0',
      borderBottom: withBorders
        ? `1px solid ${theme.palette.colors.neutral['8']}`
        : 'none',
      background: withBorders
        ? theme.palette.colors.backgrounds.contrast
        : 'transparent'
    }
  }),
  row: ({ height = 52, withBorders = true }) => ({
    cursor: 'pointer',
    '& td': {
      maxWidth: 250,
      height,
      padding: '2px',
      borderBottom: 'none',
      background: withBorders
        ? theme.palette.colors.backgrounds.contrast
        : 'transparent'
    },
    '&:hover': {
      '& td': {
        background: theme.palette.colors.backgrounds.fileHover
      }
    },
    '&:hover .actions-table-row': {
      opacity: 1,
      pointerEvents: 'auto',
      '& ~ p': {
        display: 'none'
      }
    }
  }),
  initial: () => ({
    '& td': {
      borderBottom: `2px solid ${theme.palette.colors.inputBorderColor}`
    }
  }),
  sticky: {
    position: 'sticky',
    zIndex: 2
  },
  shadow: {
    zIndex: 3,
    opacity: 0,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    bottom: FILES_PAGINATOR_HEIGHT,
    transition: 'opacity 0.1s ease-in-out'
  },
  rightShadow: {
    right: 0,
    width: 200,
    boxShadow: '0px -1px 3px rgba(0, 0, 0, 0.3)'
  },
  leftShadow: {
    left: 0,
    width: 75,
    zIndex: 2,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.3)'
  },
  visibleShadow: {
    opacity: 1
  },
  activeRow: () => ({
    '& td': {
      background: theme.palette.colors.backgrounds.fileHover
    }
  }),
  inProgress: () => ({
    '& td': {
      background: theme.palette.colors.backgrounds.file_inprogress
    }
  }),
  progressContent: {
    fontSize: 12,
    opacity: 0.7,
    textTransform: 'capitalize',
    color: theme.palette.primary.text
  },
  text: {
    lineHeight: '24px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.black_27,
      theme.palette.colors.white
    )
  },
  textShift: {
    paddingLeft: 10
  },
  tags: {
    fontSize: 13,
    maxWidth: 100,
    margin: '0 auto',
    lineHeight: '20px',
    padding: '2px 8px',
    borderRadius: 4,
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.accent['6']
  },
  value: {
    fontSize: 14
  },
  recordType: {
    width: 'auto',
    display: 'flex',
    marginRight: 10
  },
  center: {
    justifyContent: 'center'
  },
  folderIcon: {
    color: theme.palette.colors.accent[3]
  },
  fileIcon: {
    color: theme.palette.colors.accent[5]
  },
  iconButtonDisabled: () => ({
    '&:hover': { backgroundColor: 'inherit' }
  }),
  columnName: {
    fontSize: 14,
    fontWeight: 600,
    padding: '0 12px',
    margin: '0 auto'
  },
  firstColumn: {
    paddingLeft: 2
  },
  actions: {
    opacity: 0,
    minWidth: 150,
    pointerEvents: 'none',
    '& button, & a': {
      padding: 4,
      '& svg': {
        color: theme.palette.colors.neutral['5']
      }
    },
    '& ~ p': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      height: 'fit-content',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  actionBtn: {
    padding: 4,
    '& a': {
      display: 'block'
    },
    '& svg': {
      color: theme.palette.colors.neutral['5']
    }
  },
  message: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.colors.neutral.black
  },
  blured: theme.blured
}))

export default useStyles
