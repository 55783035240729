import { useDispatch } from 'react-redux'
import { ACTIONS } from 'react-joyride'
import { nextOrPrevTour } from '../../../actions/tours'

export default function useToursController(data) {
  const dispatch = useDispatch()

  const nextOrPrev = (index, cdt) => dispatch(nextOrPrevTour(index, cdt))

  const nextTour = data => {
    const { action, index } = data
    const isPrev = action === ACTIONS.PREV

    if (index === 0) {
      const mappingButton = document.querySelector('#mapping-tab')
      if (mappingButton) mappingButton.click()
    }

    if ((index === 1 && !isPrev) || (index === 2 && isPrev)) {
      document.querySelector('#view-dropdown>button:nth-child(1)').click()
    }
    if ((index === 2 && !isPrev) || (index === 3 && isPrev)) {
      document.querySelector('#view-dropdown>button:nth-child(1)').click()
      document.querySelector('#actions-dropdown>button:nth-child(1)').click()
    }
    if ((index === 3 && !isPrev) || (index === 4 && isPrev)) {
      document.querySelector('#actions-dropdown>button:nth-child(1)').click()
      const rasterPlotBtn = document.querySelector('#raster-plot-button')
      const rasterPlot = document.querySelector('#raster-plot')
      if (rasterPlotBtn && !rasterPlot) rasterPlotBtn.click()
    }

    nextOrPrev(index, isPrev)
  }

  return { nextTour }
}
