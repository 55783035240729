import React from 'react'
import { makeStyles } from '@material-ui/core'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import ActionButton from '../../../../ActionButton'

const useStyles = makeStyles(theme => ({
  clearBtn: {
    color: theme.palette.colors.error,
    marginRight: 'auto',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.colors.neutral['8']
    }
  },
  clearBtnLabel: {
    color: 'red'
  },
  leftAdornment: {
    fill: theme.palette.colors.error,
    width: 20,
    height: 17,
    fontSize: 11
  }
}))

export const SaveDialogBtn = ({ onClick }) => {
  return <ActionButton label="Save" onClick={onClick} />
}

export const ClearPositionBtn = ({ onClick }) => {
  const classes = useStyles()
  return (
    <ActionButton
      className={classes.clearBtn}
      label="Clear Position"
      onClick={onClick}
      overideClasses={{
        label: classes.clearBtnLabel
      }}
      leftAdornment={<CancelRoundedIcon className={classes.leftAdornment} />}
    />
  )
}
