import { QPARAMS } from '../utils/consts'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import Storage from '../components/AppHeader/components/Storage'

function useStorageKeys(customQparams) {
  const currentUser = useSelector(state => state.login.currentUser)
  const userId = currentUser?.user.id
  const orgId = currentUser?.user.company_id
  const ID = [userId, orgId].join('-')

  const PAGE = `${customQparams?.page || QPARAMS.PAGE}-${ID}`
  const KEYWORD = `${customQparams?.keyword || QPARAMS.KEYWORD}-${ID}`
  const FOLDER_ID = `${QPARAMS.FOLDER_ID}-${ID}`
  const PER_PAGE = `${customQparams?.per_page || QPARAMS.PER_PAGE}-${ID}`

  const resetStoragePageParams = useCallback(() => {
    Storage.setItem(PAGE, 1)
    Storage.setItem(PER_PAGE, 25)
  }, [PAGE, PER_PAGE])
  return { PAGE, PER_PAGE, KEYWORD, FOLDER_ID, resetStoragePageParams }
}

export default useStorageKeys
