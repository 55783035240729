import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { editModel } from '../../../actions/aiModels'
import { editAIModel } from '../../../endpoints/admin'
import { MODALS_ID, NOTIFICATION } from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import useToggleModal from '../../../hooks/useToggleModal'

function useEdit() {
  const [state, setState] = useState(null)
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const addNotification = useNotification()
  const modalID = MODALS_ID.EDIT_AI_MODEL
  const { isOpened: isEditModalOpen, toggle: toggleEditModal } =
    useToggleModal(modalID)

  const modalState = useSelector(state => state.modals?.get(modalID))
  const selected = modalState?.args || {}

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    [state]
  )

  const updateFile = useCallback(data => dispatch(editModel(data)), [dispatch])

  const onClose = useCallback(() => {
    setState(null)
    toggleEditModal()
  }, [toggleEditModal])

  const onEdit = useCallback(
    async props => {
      try {
        setLoading(true)
        const { id, name, description } = props || {}
        const newName =
          name &&
          name
            .replaceAll(/[@%&*!#$~+={}()|?^<>:,;]/g, '')
            .replaceAll(/[\s]/g, '_')

        const res = await editAIModel({ id, name: newName, description })
        if (res.status === 200) {
          updateFile(res?.data)
          setState(null)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Model successfully edited'
          })
        }
        setLoading(false)
      } catch (e) {
        setState(null)
        setLoading(false)
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, updateFile]
  )

  return {
    state,
    loading,
    selected,
    onEdit,
    onClose,
    handleChange,
    isEditModalOpen,
    toggleEditModal
  }
}

export default useEdit
