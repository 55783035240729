import React from 'react'
import { fade } from '@material-ui/core'

export const svg64_7 = ({ color, svgRef, flipped, preview = false }) => {
  if (preview) {
    return (
      <svg
        width="48"
        height="251"
        viewBox="0 0 48 251"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
      >
        <path
          d="M0 113.323V0H48V119.861L40 202.674L14 250.619L6 220.109L0 113.323Z"
          fill={color}
        />
        <path
          d="M6 32C6 30.3431 7.34315 29 9 29H19C20.6569 29 22 30.3431 22 32V42C22 43.6569 20.6569 45 19 45H9C7.34315 45 6 43.6569 6 42V32Z"
          fill="white"
        />
        <path
          d="M6 50C6 48.3431 7.34315 47 9 47H19C20.6569 47 22 48.3431 22 50V60C22 61.6569 20.6569 63 19 63H9C7.34315 63 6 61.6569 6 60V50Z"
          fill="white"
        />
        <path
          d="M6 68C6 66.3431 7.34315 65 9 65H19C20.6569 65 22 66.3431 22 68V78C22 79.6569 20.6569 81 19 81H9C7.34315 81 6 79.6569 6 78V68Z"
          fill="white"
        />
        <path
          d="M6 86C6 84.3431 7.34315 83 9 83H19C20.6569 83 22 84.3431 22 86V96C22 97.6569 20.6569 99 19 99H9C7.34315 99 6 97.6569 6 96V86Z"
          fill="white"
        />
        <path
          d="M6 104C6 102.343 7.34315 101 9 101H19C20.6569 101 22 102.343 22 104V114C22 115.657 20.6569 117 19 117H9C7.34315 117 6 115.657 6 114V104Z"
          fill="white"
        />
        <path
          d="M6 122C6 120.343 7.34315 119 9 119H19C20.6569 119 22 120.343 22 122V132C22 133.657 20.6569 135 19 135H9C7.34315 135 6 133.657 6 132V122Z"
          fill="white"
        />
        <path
          d="M6 140C6 138.343 7.34315 137 9 137H19C20.6569 137 22 138.343 22 140V150C22 151.657 20.6569 153 19 153H9C7.34315 153 6 151.657 6 150V140Z"
          fill="white"
        />
        <path
          d="M6 158C6 156.343 7.34315 155 9 155H19C20.6569 155 22 156.343 22 158V168C22 169.657 20.6569 171 19 171H9C7.34315 171 6 169.657 6 168V158Z"
          fill="white"
        />
        <path
          d="M6 176C6 174.343 7.34315 173 9 173H19C20.6569 173 22 174.343 22 176V186C22 187.657 20.6569 189 19 189H9C7.34315 189 6 187.657 6 186V176Z"
          fill="white"
        />
        <path
          d="M6 194C6 192.343 7.34315 191 9 191H19C20.6569 191 22 192.343 22 194V204C22 205.657 20.6569 207 19 207H9C7.34315 207 6 205.657 6 204V194Z"
          fill="white"
        />
        <path
          d="M24 21C24 19.3431 25.3431 18 27 18H37C38.6569 18 40 19.3431 40 21V31C40 32.6569 38.6569 34 37 34H27C25.3431 34 24 32.6569 24 31V21Z"
          fill="white"
        />
        <path
          d="M24 39C24 37.3431 25.3431 36 27 36H37C38.6569 36 40 37.3431 40 39V49C40 50.6569 38.6569 52 37 52H27C25.3431 52 24 50.6569 24 49V39Z"
          fill="white"
        />
        <path
          d="M24 57C24 55.3431 25.3431 54 27 54H37C38.6569 54 40 55.3431 40 57V67C40 68.6569 38.6569 70 37 70H27C25.3431 70 24 68.6569 24 67V57Z"
          fill="white"
        />
        <path
          d="M24 75C24 73.3431 25.3431 72 27 72H37C38.6569 72 40 73.3431 40 75V85C40 86.6569 38.6569 88 37 88H27C25.3431 88 24 86.6569 24 85V75Z"
          fill="white"
        />
        <path
          d="M24 93C24 91.3431 25.3431 90 27 90H37C38.6569 90 40 91.3431 40 93V103C40 104.657 38.6569 106 37 106H27C25.3431 106 24 104.657 24 103V93Z"
          fill="white"
        />
        <path
          d="M24 111C24 109.343 25.3431 108 27 108H37C38.6569 108 40 109.343 40 111V121C40 122.657 38.6569 124 37 124H27C25.3431 124 24 122.657 24 121V111Z"
          fill="white"
        />
        <path
          d="M24 129C24 127.343 25.3431 126 27 126H37C38.6569 126 40 127.343 40 129V139C40 140.657 38.6569 142 37 142H27C25.3431 142 24 140.657 24 139V129Z"
          fill="white"
        />
        <path
          d="M24 147C24 145.343 25.3431 144 27 144H37C38.6569 144 40 145.343 40 147V157C40 158.657 38.6569 160 37 160H27C25.3431 160 24 158.657 24 157V147Z"
          fill="white"
        />
        <path
          d="M24 165C24 163.343 25.3431 162 27 162H37C38.6569 162 40 163.343 40 165V175C40 176.657 38.6569 178 37 178H27C25.3431 178 24 176.657 24 175V165Z"
          fill="white"
        />
        <path
          d="M24 183C24 181.343 25.3431 180 27 180H37C38.6569 180 40 181.343 40 183V193C40 194.657 38.6569 196 37 196H27C25.3431 196 24 194.657 24 193V183Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="48"
      height="251"
      viewBox="0 0 48 251"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        d="M0 113.323V0H48V119.861L40 202.674L14 250.619L6 220.109L0 113.323Z"
        fill={flipped ? fade(color, 0.5) : color}
      />
    </svg>
  )
}
