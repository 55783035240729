import React from 'react'
import VisibilityIcon from '@material-ui/icons/Visibility'
import BaseWidget from '../BaseWidget'
import useEditData from '../../../../../NewRecordingView/components/RecordingInfoPanel/hooks/useEditData'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import useToggleModal from '../../../../../../hooks/useToggleModal'
import { MODALS_ID } from '../../../../../../utils/consts'
import ProbeDesignModal from './components/ProbeDesignModal'
import { useProbeDesignModal } from './hooks/useProbeDesignModal'
import { Counter } from '../ClusterView'
import CurationAIToggle from './components/CurationAIToggle'
import CurationAICredits from './components/CurationAICredits'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '16px 9px',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflowX: 'hidden',
    ...theme.scrollbar(3)
  },
  section: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  finalSection: {
    width: '100%',
    paddingBottom: theme.spacing(2)
  },
  icon: {
    color: theme.palette.colors.neutral['5'],
    '&:hover': {
      cursor: 'pointer'
    }
  },
  title: {
    fontSize: 12,
    marginRight: 8
  },
  content: {
    fontSize: 12,
    fontWeight: 600
  },
  clusterUnitRoot: {
    display: 'flex',
    paddingLeft: 5,
    width: '100%',
    justifyContent: 'space-between',
    position: 'static',
    borderBottom: 'none'
  },
  clusterUnitlabel: {
    fontSize: 12,
    padding: '0px'
  },
  curationAIContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export const DataView = () => {
  const classes = useStyles()
  const { datas } = useEditData()
  const { showMaping, onOpen } = useProbeDesignModal()

  return (
    <BaseWidget overrideClassName={classes.root}>
      {datas[0].content && (
        <Grid className={classes.section}>
          <Grid container>
            <Grid justifyContent="space-between" container>
              <Grid item>
                {datas[0].content && (
                  <Grid container>
                    <Typography className={classes.title}>
                      Spikesorting ID :{' '}
                    </Typography>
                    <Typography className={classes.content}>
                      {datas[0].content}
                    </Typography>
                  </Grid>
                )}
                {datas[1].content && (
                  <Grid container>
                    <Typography className={classes.title}>
                      Curation ID :{' '}
                    </Typography>
                    <Typography className={classes.content}>
                      {datas[1].content}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid className={classes.curationAIContainer}>
                <CurationAICredits />
                <CurationAIToggle />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Grid container className={classes.section}>
        <Grid justifyContent="space-between" container>
          <Typography className={classes.title}>Probe Design </Typography>
          {showMaping && (
            <VisibilityIcon onClick={onOpen} className={classes.icon} />
          )}
        </Grid>
        <Typography className={classes.content}>{datas[2].content}</Typography>
      </Grid>

      <Grid container className={classes.section}>
        <Grid justifyContent="space-between" container>
          <Typography className={classes.title}>Probe ID </Typography>
        </Grid>
        <Typography className={classes.content}>{datas[9].content}</Typography>
      </Grid>

      <Grid container className={classes.section}>
        <Grid container direction="row" justifyContent="flex-start" spacing={6}>
          {datas[3].content && (
            <Grid direction="column" md={6} item>
              <Typography className={classes.title}>Sorting Method </Typography>
              <Typography className={classes.content}>
                {datas[3].content}
              </Typography>
            </Grid>
          )}
          <Grid direction="column" md={6} item>
            <Typography className={classes.title}>Sample Rate</Typography>
            <Typography className={classes.content}>
              {datas[4].content}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.section}>
        <Grid container direction="row" justifyContent="flex-start" spacing={6}>
          <Grid direction="column" md={6} item>
            <Typography className={classes.title}>Duration</Typography>
            <Typography className={classes.content}>
              {datas[5].content}
            </Typography>
          </Grid>
          <Grid direction="column" md={6} item>
            <Typography className={classes.title}>Ch.Count</Typography>
            <Typography className={classes.content}>
              {datas[6].content}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* {datas[7].content && (
        <Grid container className={classes.section}>
          <Typography className={classes.title}>
            Isolated single units
          </Typography>

          <Grid container direction="row">
            <Grid direction="row" container md={4}>
              <Typography className={classes.title}>Start : </Typography>
              <Typography className={classes.content}>
                {datas[7].content}
              </Typography>
            </Grid>
            <Grid direction="row" container md={4}>
              <Typography className={classes.title}>Current : </Typography>
              <Typography className={classes.content}>
                {datas[8].content}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )} */}

      <Grid container className={classes.finalSection}>
        <Typography className={classes.title}>Units</Typography>
        <Counter
          classes={{
            root: classes.clusterUnitRoot,
            label: classes.clusterUnitlabel
          }}
        />
      </Grid>

      <ProbeDesignModal />
    </BaseWidget>
  )
}

export function useEditDataAction() {
  return useToggleModal(MODALS_ID.EDIT_DATA_MODAL)
}
