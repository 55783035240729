import { Grid, Typography } from '@material-ui/core'
import ActionButton from '../../../ActionButton'
import { Add } from '@material-ui/icons'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 24
  },
  title: {
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  },
  btn: {
    width: 'auto'
  }
}))

const DrawerHeader = ({
  title,
  labelBtn,
  loading,
  onClick = () => null,
  isLimited = false
}) => {
  const classes = useStyles()
  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={classes.root}
    >
      <Typography className={classes.title}>{title}</Typography>
      <ActionButton
        label={labelBtn}
        onClick={onClick}
        loading={loading}
        leftAdornment={<Add />}
        className={classes.btn}
        id="newSpikesorting"
        disabled={isLimited}
      />
    </Grid>
  )
}

export default DrawerHeader
