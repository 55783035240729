import React, { memo, useCallback } from 'react'
import CustomModal from '../../../../../CustomModal'
import { makeStyles, Typography } from '@material-ui/core'
import ActionButton from '../../../../../ActionButton'

const useStyles = makeStyles(theme => ({
  header: {
    height: 68
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '36px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[90],
      'white'
    )
  },
  bodyText: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      'white'
    )
  }
}))

const TrainResultModal = ({ isOpened, deselectAll, toggleModal }) => {
  const classes = useStyles()

  const onClose = useCallback(() => {
    toggleModal()
    deselectAll()
  }, [deselectAll, toggleModal])

  return (
    <CustomModal
      hideCloseButton
      open={isOpened}
      onClose={onClose}
      classes={classes}
      title={'Training AI model in progress'}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      renderActionButton={<ActionButton onClick={onClose} label={'Okay'} />}
    >
      <Typography className={classes.bodyText}>
        Training AI model is in progress. The operation will be executed in
        background, you can check it on the the Existing AI models tab. The
        process will take some time to be finished.
      </Typography>
    </CustomModal>
  )
}

export default memo(TrainResultModal)
