export const baseConfig = (override = {}) => ({
  startTime: Date.now(),
  partNum: 0,
  partSize: 1024 * 1024 * 5, // Minimum 5MB per chunk (except the last part) http://docs.aws.amazon.com/AmazonS3/latest/API/mpUploadComplete.html
  allPartsNumber: 0,
  multipartMap: {
    Parts: []
  },
  ...override
})

export const calcPartSize = (fileSize, maxParts) => {
  const maxim = fileSize / maxParts

  return Math.max(10 * 1024 * 1024, maxim)
}

//this function will help to handle async easily
export function to(promise) {
  return promise
    .then(data => {
      return { err: null, data }
    })
    .catch(err => ({ err, data: null }))
}

const poll = ({ fn, validate, interval, maxAttempts }) => {
  console.log('Start poll...')
  let attempts = 0

  const executePoll = async (resolve, reject) => {
    if (attempts === 0) {
      console.log('First request')
    } else {
      console.log('- poll')
    }
    const result = await fn()
    attempts++

    if (validate(result.err)) {
      return resolve(result)
    } else if (maxAttempts && attempts === maxAttempts) {
      return reject(new Error('Exceeded max attempts'))
    } else {
      setTimeout(executePoll, interval, resolve, reject)
    }
  }

  return new Promise(executePoll)
}

export const handleRemoteRequest = async (request, options) => {
  const { err, data } = await to(request(options.body, options?.url))

  if (err) {
    options.onError && options.onError(err)
    return { err }
  } else {
    if (data) {
      options.onSuccess && (await options.onSuccess(data))
      return { err: null }
    }
  }
}

export const handleRemoteRequestWithPolling = async (request, options) => {
  await poll({
    fn: handleRemoteRequest.bind(this, request, options),
    interval: 15000,
    maxAttempts: 3,
    validate: err => !err || err?.response?.status === 400
  }).catch(err => console.log(err))
}

export class MultiPartUpload {
  fileKey = null
  uploadIdVal = null
  config = null
  bucket = null
  multiPartParams = null

  constructor(file, config) {
    this.fileKey = file.fileKey
    this.config = config
  }

  set uploadId(val) {
    this.uploadIdVal = val
  }

  get uploadId() {
    return this.uploadIdVal
  }

  get partsInfo() {
    return this.config.multipartMap
  }

  nextPart = () => {
    this.config.partNum++
    console.log('Uploading part: #', this.config.partNum)

    return this.config.partNum
  }

  completeUploadPartRequest = data => {
    const { config } = this
    config.multipartMap.Parts[config.partNum - 1] = {
      ETag: data.ETag,
      PartNumber: Number(config.partNum)
    }
    console.log('Completed part', config.partNum)
    console.log('mData', data)

    if (config.partNum < config.allPartsNumber) {
      return { done: false, partNum: config.partNum }
    }

    return { done: true }
  }
}
