export class PlotBuilder {
  constructor() {
    this.builderType = () => ({})
  }

  buildPlot(...args) {
    return this.builderType(...args)
  }

  changeBuilder(builder) {
    this.builderType = builder
  }
}
