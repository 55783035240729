import { MODALS_ID, UNITS_LABELS } from '../../../../../utils/consts'
import { makeStyles } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import useToggleModal from '../../../../../hooks/useToggleModal'
import useRecordingInfo from '../../../../NewRecordingView/hooks/useRecordingInfo'
import { WIDGETS } from './consts'
import { RenderCombination } from './useRenderCombination'
import useKeyboardEventListener from './useKeyboardEventListener'
import useCurationOperations from '../../CurationsDrawer/hooks/useCurationOperations'
import { useSelectSpikes } from '../../WidgetsLayout/widgets/FeatureView'
import { useDownloadFilesModal } from '../../WidgetsLayout/hooks/'
import useLimitedAccess from '../../../../../hooks/useLimitedAccess'

const useStyles = makeStyles(theme => ({
  done: {
    color: theme.palette.secondary[100]
  }
}))

function useActionButtons(layoutDS) {
  const classes = useStyles()
  const {
    SPIKESORTING_DRAWER,
    FILES_MODAL,
    COMMENTS_DRAWER,
    CURATIONS_DRAWER,
    NEW_SPIKESORTING_MODAL,
    EDIT_DATA_MODAL,
    EDIT_TAGS_PUBLICATIONS_MODAL
  } = MODALS_ID
  const { isOpened: isSortingsOpened, toggle: toggleSortings } =
    useToggleModal(SPIKESORTING_DRAWER)
  const { isOpened: isFilesOpened, toggle: toggleFiles } =
    useToggleModal(FILES_MODAL)
  const { isOpened: isCommentsOpened, toggle: toggleComments } =
    useToggleModal(COMMENTS_DRAWER)
  const { isOpened: isCurationOpened, toggle: toggleCurationList } =
    useToggleModal(CURATIONS_DRAWER)
  const currentUser = useSelector(state => state.login?.currentUser) || {}
  const { approved, author_id } =
    useSelector(state => state.recordingStore.selectedCuration) || {}
  const isAuthor = currentUser?.user.id === author_id
  const isCurationDisabled = !isAuthor && approved
  const { isLimited } = useLimitedAccess()
  const modals = useSelector(state => state.modals)
  const isModals = modals?.size > 0
  const disableShortcuts =
    modals?.has(NEW_SPIKESORTING_MODAL) ||
    modals?.has(EDIT_TAGS_PUBLICATIONS_MODAL) ||
    modals?.has(EDIT_DATA_MODAL) ||
    isCommentsOpened

  const history = useHistory()
  const { recordingId } = useParams()
  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )
  const { isFileUploaded } = useRecordingInfo()

  const {
    merge,
    split,
    isMerge,
    isSplit,
    isLabel,
    isUnitMerged,
    handleUnitsLabelling
  } = useCurationOperations()

  const handleOpenTS = useCallback(
    () => history.push(`/timeseriesForRecording/${recordingId}`),
    [history, recordingId]
  )

  const { selectSpikes, selectedSpikes } = useSelectSpikes()
  const { onOpen } = useDownloadFilesModal()

  const actions = [
    /* FIELDS */
    /*{
      id: '',
      label: '',
      disabled: false,
      endAdornment: null,
      leftAdornment: null,
      onClick: null
    }*/

    isFileUploaded && {
      keyCode: 84,
      label: 'Time Series',
      leftAdornment: <RenderCombination keys={[{ key: 'T' }]} />,
      onClick: handleOpenTS
    },
    {
      keyCode: 80,
      label: 'Spikesorting',
      leftAdornment: <RenderCombination keys={[{ key: 'P' }]} />,
      disabled: isModals && !isSortingsOpened,
      onClick: toggleSortings
    },
    {
      keyCode: 76,
      label: 'Curation List',
      leftAdornment: <RenderCombination keys={[{ key: 'L' }]} />,
      disabled: isModals && !isCurationOpened,
      onClick: toggleCurationList
    },
    {
      keyCode: 70,
      label: 'View Files',
      leftAdornment: <RenderCombination keys={[{ key: 'F' }]} />,
      disabled: isModals && !isFilesOpened,
      onClick: toggleFiles
    },
    {
      keyCode: 68,
      label: 'Download',
      leftAdornment: <RenderCombination keys={[{ key: 'D' }]} />,
      onClick: onOpen
    },
    selectedSorting && {
      keyCode: 67,
      label: 'Comments',
      leftAdornment: <RenderCombination keys={[{ key: 'C' }]} />,
      disabled: isModals && !isCommentsOpened,
      onClick: toggleComments
    }
  ].filter(Boolean)

  const curation = [
    {
      keyCode: 71,
      label: 'Merge',
      disabled: isLimited || !isMerge || isCurationDisabled,
      leftAdornment: <RenderCombination keys={[{ key: 'G' }]} />,
      onClick: merge
    },
    {
      keyCode: 75,
      label: 'Undo Merge',
      disabled: isLimited || !isSplit || !isUnitMerged || isCurationDisabled,
      leftAdornment: <RenderCombination keys={[{ key: 'K' }]} />,
      onClick: split
    },
    {
      keyCode: 66,
      isAlt: true,
      disabled: isLimited || !isLabel || isCurationDisabled,
      label: 'Label Good',
      leftAdornment: (
        <RenderCombination
          keys={[{ key: 'alt', metaKey: true }, { key: 'B' }]}
        />
      ),
      onClick: () => handleUnitsLabelling(UNITS_LABELS.GOOD)
    },
    {
      keyCode: 77,
      isAlt: true,
      disabled: isLimited || !isLabel || isCurationDisabled,
      label: 'Label Multi-unit',
      leftAdornment: (
        <RenderCombination
          keys={[{ key: 'alt', metaKey: true }, { key: 'M' }]}
        />
      ),
      onClick: () => handleUnitsLabelling(UNITS_LABELS.MULTIPLE)
    },
    {
      keyCode: 78,
      isAlt: true,
      disabled: isLimited || !isLabel || isCurationDisabled,
      label: 'Label  Noise',
      leftAdornment: (
        <RenderCombination
          keys={[{ key: 'alt', metaKey: true }, { key: 'N' }]}
        />
      ),
      onClick: () => handleUnitsLabelling(UNITS_LABELS.NOISE)
    },
    {
      keyCode: 90,
      disabled:
        isLimited ||
        !selectedSpikes.lassoPoints?.x?.length ||
        isCurationDisabled,
      label: 'Split',
      leftAdornment: (
        <RenderCombination
          keys={[
            { key: 'ctrl', metaKey: true },
            { key: 'shift', metaKey: true },
            { key: 'z' }
          ]}
        />
      ),
      onClick: () => selectSpikes()
    }
  ].filter(Boolean)

  const widgets = WIDGETS.map(widget => ({
    ...widget,
    endAdornment: layoutDS.has(widget.id) ? (
      <Done className={classes.done} />
    ) : null
  }))

  useKeyboardEventListener([...actions, ...curation], disableShortcuts)

  return { actions, curation, widgets, isBlockedByApproval: isCurationDisabled }
}

export default useActionButtons
