import { useCallback, useState } from 'react'
import useToggleModal from '../../../hooks/useToggleModal'
import { MODALS_ID, NOTIFICATION } from '../../../utils/consts'
import { concatFiles } from '../../../endpoints/admin'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import useGetAllRecords from './useGetAllRecords'

export default function useConcatFilesModal() {
  const addNotification = useNotification()
  const { fetchRecords } = useGetAllRecords(false)
  const { toggle: toggleSuccessModal } = useToggleModal(
    MODALS_ID.DATA_MANIPULATION_SUCCESS_MODAL
  )
  const { toggle: toggleModal, isOpened } = useToggleModal(
    MODALS_ID.CONCAT_FILES_MODAL
  )

  const [loading, setLoading] = useState(false)

  const handleConcatFiles = useCallback(
    async d => {
      try {
        setLoading(true)
        const { new_file_name, remove, order, selected } = d
        const isReversed = order === 2
        const selectedIds = selected.map(item => item.id)
        const [first_rec_id, second_rec_id] = isReversed
          ? selectedIds.reverse()
          : selectedIds

        const fields = {
          new_file_name,
          remove,
          first_rec_id,
          second_rec_id
        }

        const { status, data } = await concatFiles(fields)
        if (status === 200) {
          const { status_int } = data?.record || {}
          toggleSuccessModal({ status_int })
          toggleModal()
          fetchRecords()
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
      setLoading(false)
    },
    //eslint-disable-next-line
    [fetchRecords]
  )

  return {
    onConcat: handleConcatFiles,
    loading,
    toggleModal,
    isOpened
  }
}
