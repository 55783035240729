export function toHumanTimeRange(tr, samplerate) {
  if (!tr) return 'none'
  const options = { nounits: true }
  const diference =
    toHumanTime(tr[1], samplerate, options) -
    toHumanTime(tr[0], samplerate, options)
  return parseInt(diference * 1000)
}

export function toHumanTimeRangeMs(tr, samplerate) {
  if (!tr) return 'none'
  const options = { nounits: true }
  const diference =
    toHumanTimeMs(tr[1], samplerate, options) -
    toHumanTimeMs(tr[0], samplerate, options)
  return parseInt(diference * 1000)
}

export function fromHumanTimeRange(txt, samplerate, currentTimeRange) {
  if (!txt) return null
  const [t1] = currentTimeRange || []
  let t = fromHumanTime(txt, samplerate, { nounits: true, num_digits: 0 })
  let t2 = t1 + t
  return [t1, t2]
}

export function toHumanTime(t, samplerate, opts) {
  opts = opts || {}
  if (t === null) return 'none'
  let sec = round(t / samplerate, opts.num_digits || 6)
  if (opts.nounits) return sec
  else return `${sec} s`
}

export function toHumanTimeMs(t, samplerate, opts) {
  opts = opts || {}
  if (!t || !samplerate) return 0
  let val = Number(t)
  return round((val * 1000) / samplerate, opts.num_digits || 6)?.toFixed(2)
}

export function fromHumanTime(txt, samplerate, opts) {
  opts = opts || {}
  if (!txt) return null
  let val = Number(txt)
  return round((val / 1000) * samplerate, opts.num_digits || 6)
}

export function round(val, num_digits) {
  return Math.round(val * Math.pow(10, num_digits)) / Math.pow(10, num_digits)
}

export function msToTime(duration) {
  return {
    minutes: {
      val: Math.floor(duration / (1000 * 60)),
      label: 'min'
    },
    seconds: {
      val: Math.floor((duration / 1000) % 60),
      label: 'sec'
    },
    milliseconds: {
      val: parseInt(duration % 1000),
      label: 'ms'
    }
  }
}

export const getMiliseconds = ({ min, sec, ms }) => {
  return (min * 60 + sec) * 1000 + ms
}
