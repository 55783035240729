import { useCallback, useEffect, useState } from 'react'
import { initialColumns as defaultColumns } from '../initialColumns'
import Storage from '../../AppHeader/components/Storage'
import { useSelector } from 'react-redux'

function useColumnFilters(props) {
  const { customStorageName, customInitialColumns } = props || {}
  const [isOpened, setOpen] = useState(false)
  const initialColumns = customInitialColumns ?? defaultColumns
  const [allColumns, setColumns] = useState(initialColumns)
  const [filteredColumns, applyFilter] = useState(initialColumns)
  const currentUser = useSelector(state => state.login.currentUser)
  const username = currentUser?.user.username
  const currentStorageName = customStorageName ?? 'activeColumns'
  const storageName = `${currentStorageName}-${username}`

  const toggleModal = useCallback(() => setOpen(open => !open), [])

  const handleApply = useCallback(() => {
    toggleModal()
    Storage.setItem(storageName, JSON.stringify(allColumns))
    return applyFilter(allColumns)
  }, [allColumns, storageName, toggleModal])

  const checkForStoredColumns = useCallback(() => {
    try {
      const storedActiveColumns = Storage.getItem(storageName)
      const parsed = JSON.parse(storedActiveColumns)

      // to check if stored columns correspond with initial columns,
      // in case if we will add or remove any column in initial columns
      const isValidFields =
        parsed.filter(
          p =>
            !initialColumns.some(
              ({ id, label }) => id === p.id && label === p.label
            )
        ).length === 0 && parsed.length === initialColumns.length

      if (storedActiveColumns && isValidFields) {
        setColumns(parsed)
        applyFilter(parsed)
      } else throw new Error()
    } catch (e) {
      // in case if user selected/deselected columns but didn't click on apply
      setColumns(initialColumns)
      Storage.removeItem(storageName)
    }
  }, [storageName])

  useEffect(() => {
    checkForStoredColumns()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened])

  const handleToggleCheckbox = useCallback(
    id => {
      const columnIndex = allColumns.findIndex(item => item.id === id)
      const updatedItem = {
        ...allColumns[columnIndex],
        active: !allColumns[columnIndex].active
      }
      const updatedColumns = [
        ...allColumns.slice(0, columnIndex),
        updatedItem,
        ...allColumns.slice(columnIndex + 1)
      ]
      setColumns(updatedColumns)
    },
    [allColumns]
  )

  return {
    isOpened,
    allColumns,
    toggleModal,
    filteredColumns,
    handleToggleCheckbox,
    handleApply
  }
}

export default useColumnFilters
