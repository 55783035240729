import { ofType } from 'redux-observable'
import {
  switchMap,
  tap,
  filter,
  debounceTime,
  catchError,
  withLatestFrom,
  ignoreElements
} from 'rxjs/operators'
import { from, merge, of } from 'rxjs'
import { getAsObservable } from '../../../../../../utils/rxjs.utils'
import { getFeatures } from '../../../../../../endpoints/admin'
import {
  REINIT_CLUSTER_UNITS,
  REINIT_SIMILARITY_UNITS,
  SAVE_CLUSTER_UNITS,
  SAVE_FEATURES_ERROR,
  SAVE_SIMILARITY_UNITS
} from '../actions'
import { PlotFeatures } from '../../widgets/FeatureView'
import { NOTIFICATION } from '../../../../../../utils/consts'

export const DEBOUNCE = 400

export const saveClusterEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      SAVE_CLUSTER_UNITS,
      SAVE_SIMILARITY_UNITS,
      REINIT_CLUSTER_UNITS,
      REINIT_SIMILARITY_UNITS
    ),
    filter(x => x.payload),
    filter(_x => PlotFeatures.renderPlot !== null),
    withLatestFrom(state$),
    tap(([action, state]) => {
      PlotFeatures.clearScatterTraces()
      return [action, state]
    }),
    debounceTime(DEBOUNCE),
    switchMap(([action, state]) => {
      return fetchFeaturesEpic(action, state)
    })
  )

function fetchFeaturesEpic(action$, state) {
  const { cluster = [], similarity = [] } = state.widgetsCache.units
  const unit = action$.payload.unit_id
  const sortingId = state.recordingStore.selectedSorting?.id
  const record_id = state.recordingStore.recording.id
  const curationId = state.recordingStore.selectedCuration?.id

  const units = [...cluster, ...similarity]

  return from(
    getAsObservable(getFeatures, {
      unitId: units,
      run_id: sortingId,
      curation_id: curationId
    })
  ).pipe(
    // map(res => ({
    //   type: SAVE_FEATURES,
    //   payload: { data: { [unit]: res.data }, record_id, sortingId, unit }
    // })),
    catchError(error =>
      merge(
        of({
          type: SAVE_FEATURES_ERROR,
          payload: error,
          error: true
        }),
        of({
          type: 'ADD_NOTIFICATION',
          payload: {
            title: `Feature view: ${error.message ?? error}`,
            type: NOTIFICATION.ERROR
          }
        })
      )
    ),
    // filter(action => action.type === SAVE_FEATURES),
    switchMap(x => {
      PlotFeatures.clearScatterTraces()

      return PlotFeatures.renderPlot !== null
        ? from(PlotFeatures.updatePlotData(unit, x.data))
        : of(x)
    }),
    ignoreElements()
  )
}
