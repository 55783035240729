import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { storeCurrentUser } from '../../../../actions/login'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
//slick slider
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const useStyles = makeStyles(theme => ({
  slider: {
    height: '100%',
    '& div': {
      height: '100%'
    }
  },
  slideRoot: {
    minHeight: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&[data-slide="1"]': {
      backgroundImage: `url(${require('../../../../imgs/Login/labbox-login-1.jpg')})`
    },
    '&[data-slide="2"]': {
      backgroundImage: `url(${require('../../../../imgs/Login/labbox-login-2.jpg')})`
    },
    '&[data-slide="3"]': {
      backgroundImage: `url(${require('../../../../imgs/Login/labbox-login-3.jpg')})`
    }
  },
  slide: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.colors.white,
    textAlign: 'center',
    padding: 20
  },
  title: {
    fontSize: 40,
    fontWeight: 600,
    lineHeight: '44px',
    marginBottom: 25,
    maxWidth: 500
  },
  dots: {
    position: 'absolute',
    bottom: 50,
    right: 0,
    left: 0,
    textAlign: 'center',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      margin: '0px 8px',
      display: 'inline-block',
      '&.slick-active': {
        '& button': {
          backgroundColor: theme.palette.colors.white
        }
      },
      '& button': {
        fontSize: 0,
        padding: 0,
        width: 12,
        height: 12,
        border: 'none',
        borderRadius: 12,
        backgroundColor: '#C4C4C4'
      }
    }
  }
}))

const LoginCarousel = () => {
  const classes = useStyles()
  const settings = {
    dots: true,
    speed: 250,
    autoplaySpeed: 4000,
    autoplay: true,
    arrows: false,
    fade: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: classes.dots
  }

  const title = (
    <Typography className={classes.title}>
      The science is hard...
      <br /> the tools shouldn't be
    </Typography>
  )

  return (
    <Slider {...settings} className={classes.slider}>
      <div className={classes.slideRoot} data-slide={1}>
        <div className={classes.slide}>{title}</div>
      </div>
      <div className={classes.slideRoot} data-slide={2}>
        <div className={classes.slide}>{title}</div>
      </div>
      <div className={classes.slideRoot} data-slide={3}>
        <div className={classes.slide}>{title}</div>
      </div>
    </Slider>
  )
}

const mapDispatchToProps = dispatch => ({
  storeCurrentUser: user => dispatch(storeCurrentUser(user))
})

export default withRouter(connect(null, mapDispatchToProps)(LoginCarousel))
