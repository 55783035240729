import React, { memo } from 'react'
import ActionButton from '../../../../../ActionButton'
import CustomModal from '../../../../../CustomModal'
import { makeStyles } from '@material-ui/core/styles'
import CustomTable from '../../../../../CustomTable'
import { IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import UploadModeView from './components/UploadModeView'

const useStyles = makeStyles(theme => ({
  body: {
    minHeight: 350
  },
  actions: {
    display: 'flex',
    marginLeft: 15
  },
  table: {
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.colors.grey_15}`
  },
  uploadBtn: {
    background: 'transparent',
    border: `3px solid ${theme.palette.action.secondary}`,
    '& p': {
      color: theme.palette.action.secondary
    },
    '&:hover': {
      backgroundColor: 'transparent!important'
    }
  },
  backBtn: {
    padding: 0,
    marginRight: 8,
    '& svg': {
      color: theme.palette.colors.neutral['3']
    }
  }
}))

const FilesModal = props => {
  const {
    isOpened,
    toggleModal,
    // isSelectedRow,
    // handleSelectRow,
    handleSubmit,
    columns,
    rows,

    // uploading prob file
    toggleUploadMode,
    uploadMode,
    selectedFile,
    handleChangeView,
    setPrbFile,
    view,
    loading,
    isLimited
  } = props
  const classes = useStyles()

  const RenderActionButtons = memo(() => {
    const title = uploadMode ? 'Upload file' : 'Upload new .json or .prb'
    return (
      <div className={classes.actions}>
        {uploadMode ? (
          <ActionButton
            label="Save"
            onClick={handleSubmit}
            disabled={isLimited}
          />
        ) : (
          <ActionButton
            label={title}
            onClick={toggleUploadMode}
            className={classes.uploadBtn}
          />
        )}

        {/*THE LOGIC FOR DOWNLOAD WILL BE IMPLEMENTED LATER*/}

        {/*<ActionButton*/}
        {/*  label="Download"*/}
        {/*  disabled*/}
        {/*  onClick={() => null}*/}
        {/*/>*/}
      </div>
    )
  })

  return (
    <CustomModal
      open={isOpened}
      headerIcon={
        uploadMode && (
          <IconButton className={classes.backBtn} onClick={toggleUploadMode}>
            <ArrowBack />
          </IconButton>
        )
      }
      title={uploadMode ? 'Uploading file .json or .prb' : 'Files'}
      onClose={toggleModal}
      maxWidth={555}
      renderActionButton={<RenderActionButtons />}
    >
      <div className={classes.body}>
        {uploadMode ? (
          <UploadModeView
            loading={loading}
            view={view}
            selectedFile={selectedFile}
            handleChangeView={handleChangeView}
            setPrbFile={setPrbFile}
          />
        ) : (
          <CustomTable
            className={classes.table}
            columns={columns}
            rows={rows}
            cellWidth={135}
            withBorders={false}
            // onClickRow={handleSelectRow}
            // isSelected={isSelectedRow}
          />
        )}
      </div>
    </CustomModal>
  )
}

export default FilesModal
