import React from 'react'
import {
  Grid,
  IconButton,
  SnackbarContent,
  Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import cn from 'classnames'
import ActionButton from '../../../ActionButton'
import { CheckCircle, Error, Info, Star, Warning } from '@material-ui/icons'
import { darken, makeStyles } from '@material-ui/core/styles'
import Stagnante from '../../../../imgs/Stagnante'

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
  message: Star,
  plain: Info
}

const useStyles = makeStyles(theme => ({
  root: ({ type }) => ({
    background: theme.notifications[type],
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.22)',
    borderRadius: 8,
    maxWidth: type === 'plain' ? 700 : 300,
    color:
      type === 'plain'
        ? theme.palette.colors.neutral[1]
        : theme.palette.colors.status[type] ||
          theme.palette.colors.neutral.black,
    padding: 16
  }),
  messageRoot: {
    padding: 0,
    width: '100%'
  },
  content: {
    width: '45vw',
    overflowWrap: 'break-word',
    overflow: 'hidden'
  },
  icon: ({ type }) => ({
    marginRight: 10,
    color: type === 'message' && theme.palette.colors.accent['3']
  }),
  text: ({ type }) => ({
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: type === 'plain' ? 'normal' : 600,
    hyphens: 'auto',
    width: '60%',
    flexGrow: type === 'plain' ? 0 : 1
  }),
  message: ({ type }) => {
    const color =
      type === 'plain'
        ? theme.palette.colors.neutral[1]
        : type === 'message' && theme.palette.type === 'dark'
        ? theme.palette.colors.white
        : theme.palette.colors.neutral['3']
    return {
      fontWeight: 400,
      marginTop: 6,
      wordBreak: 'break-word',
      width: '100%',
      hyphens: 'auto',
      color,
      '& span': {
        fontWeight: 600
      }
    }
  },
  close: {
    position: 'relative',
    right: -5,
    color: theme.palette.colors.neutral['5'],
    padding: 5,
    '& svg': {
      fontSize: 20
    },
    '&:hover': {
      color: 'initial'
    }
  },
  action: ({ type }) => {
    const color = theme.palette.colors.status[type]
    return {
      marginTop: type === 'plain' ? 0 : 15,
      marginLeft: type === 'plain' ? 0 : 34,
      width: 'auto',
      backgroundColor: color,
      '&:hover': {
        backgroundColor: color ? darken(color, 0.3) : 'initial'
      }
    }
  }
}))

const ContentSnackbar = props => {
  const {
    title,
    type,
    isOpened,
    message,
    body,
    action: { label, onClick },
    onClose
  } = props
  const classes = useStyles({ type })

  const Icon = variantIcon[type]
  const hasAction = onClick && label
  const hasMessage = message || body()
  return (
    <SnackbarContent
      classes={{
        root: classes.root,
        message: classes.messageRoot
      }}
      aria-describedby="client-snackbar"
      message={
        <div style={{ opacity: isOpened ? 1 : 0 }}>
          <Grid
            container
            justify="space-between"
            wrap="nowrap"
            alignItems="center"
          >
            <Grid
              container
              wrap="nowrap"
              justify="flex-start"
              alignItems="center"
              className={classes.content}
            >
              {type === 'plain' ? (
                <Stagnante className={classes.icon} />
              ) : (
                <Icon className={classes.icon} />
              )}
              <Typography className={classes.text}>{title}</Typography>
              {type === 'plain' && (
                <ActionButton
                  className={classes.action}
                  label={label}
                  onClick={onClick}
                />
              )}
            </Grid>
            {type !== 'plain' && (
              <IconButton
                key="close"
                aria-label="Close"
                className={classes.close}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
          {hasMessage && (
            <Typography className={cn(classes.text, classes.message)}>
              {message || body()}
            </Typography>
          )}
          {hasAction && type !== 'plain' && (
            <ActionButton
              className={classes.action}
              label={label}
              onClick={onClick}
            />
          )}
        </div>
      }
    />
  )
}

export default ContentSnackbar
