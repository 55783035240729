import { createMuiTheme } from '@material-ui/core/styles'
import { hexToRgba, MAIN_APPBAR_HEIGHT } from './utils/styles'
import { TABLE_SCROLLBAR_SIZE } from './utils/styles/CONSTANTS'

export const colors = {
  white: '#fff',
  white_80: 'rgba(255, 255, 255, 0.8)',
  white_fa: '#fafafa',
  white_f5: '#F5F5F5',
  darkBlue: '#04385A',
  hyperlinkBlue: '#376AAA',
  lightBlue: 'rgba(12, 180, 206, 1)',
  lightBlue1: 'rgba(12, 180, 206, 0.3)',
  lightBlue2: 'rgba(12, 180, 206, 0.05)',
  grey: '#666666',
  grey2: 'rgba(0, 0, 0, 0.38)',
  grey3: 'rgba(0, 0, 0, 0.12)',
  grey_15: 'rgba(0, 0, 0, 0.15)',
  grey_25: 'rgba(0, 0, 0, 0.25)',
  black_07: 'rgba(0, 0, 0, 0.07)',
  black_23: 'rgba(255, 255, 255, 0.23)',
  grey4: '#DADADA',
  red: '#FF0C3E',
  darkRed: '#AE1010',
  green: '#58B744',
  lightGreen: 'rgba(88, 183, 68, 0.5)',
  lighterGreen: '#21B784',
  darkGrey: 'rgba(0, 0, 0, 0.6)',
  darkWhite: 'rgba(255, 255, 255, 0.7)',
  purple: '#AB47BC',
  lightYellow: '#F2DF2D',
  mainColor: '#071740',
  darkMain: '#2D9BFA',
  mainHover: '#243561',
  orange: '#FF9800',
  lightGrey: '#E8E8E8',
  black: '#000000',
  black2: '#121212',
  black_27: '#272833',
  black_15: 'rgba(0, 0, 0, 0.15)',
  black_60: 'rgba(0, 0, 0, 0.60)',
  error: '#DA1414',
  secongBG:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #121212',
  dp_03:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #121212',
  dp_04:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), #121212',
  backdrop:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), rgba(18, 18, 18, 0.72)',
  layer: {
    blue: 'linear-gradient(rgba(68,185,202,0.7), rgba(68,185,202,0.7))',
    purple: 'linear-gradient(rgba(163,109,193,0.6), rgba(163,109,193,0.6))',
    yellow: 'linear-gradient(rgba(255,199,0,0.6), rgba(255,199,0,0.6))'
  },
  accent: {
    1: '#F06ACC',
    2: '#3e9f9f',
    3: '#FEAC4C',
    4: '#AC65FE',
    5: '#2D9BFA',
    6: '#E1604D',
    7: '#2D6A6A',
    8: '#FF4339',
    9: '#34D182'
  },
  status: {
    success: '#287D3C',
    warning: '#B95000',
    error: '#DA1414',
    info: '#2E5AAC',
    in_progress: '#2D9BFA',
    in_queue: '#2E5AAC',
    plain: '#21B685'
  },
  bg: {
    plain: '#fff',
    success: '#EDF9F0',
    success2: '#D6FCDA',
    warning: '#FFF4EC',
    info: '#EEF2FA',
    pending: '#FEF4DE',
    queue: '#DFFFFD'
  }
}

export const theme = mode =>
  createMuiTheme({
    palette: {
      type: mode,
      primary: {
        main: mode === 'light' ? colors.mainColor : colors.darkMain,
        text: mode === 'light' ? colors.mainColor : colors.white,
        contrast: mode === 'light' ? colors.mainColor : colors.white,
        disabled: 'rgba(45, 155, 250, 0.3)',
        disabled2: 'rgba(130, 138, 159, 1)'
      },
      secondary: {
        main: mode === 'light' ? colors.mainColor : '#121212',
        black: {
          900: '#002F33'
        },
        100: colors.lighterGreen,
        90: 'rgba(33, 183, 132, 0.9)',
        80: 'rgba(33, 183, 132, 0.8)',
        70: 'rgba(33, 183, 132, 0.7)',
        60: 'rgba(33, 183, 132, 0.6)',
        50: 'rgba(33, 183, 132, 0.5)',
        40: 'rgba(33, 183, 132, 0.4)',
        30: 'rgba(33, 183, 132, 0.3)',
        20: '#d9f1e6',
        10: 'rgba(33, 183, 132, 0.1)'
      },
      secondaryLight: {
        100: '#24c890'
      },
      action: {
        secondary: '#21B685',
        disabled:
          mode === 'light' ? 'rgba(7, 23, 64, 0.5)' : 'rgba(45, 155, 250, 0.3)'
      },
      colors: {
        ...colors,
        main: colors.mainColor,
        backgrounds: {
          main: mode === 'light' ? colors.white : colors.black2,
          files: mode === 'light' ? '#F7F8FC' : colors.black2,
          file_inprogress: mode === 'light' ? '#ffecd6' : '#66533d',
          fileHover: mode === 'light' ? '#F4F6F9' : colors.dp_03,
          pannel: mode === 'light' ? '#F7F8FC' : colors.dp_04,
          contrast: mode === 'light' ? colors.white : colors.secongBG,
          dp_24: mode === 'light' ? colors.mainColor : colors.secongBG,
          modals: mode === 'light' ? colors.white : colors.dp_03
        },
        mainColor: mode === 'light' ? colors.mainColor : colors.darkMain,
        color: mode === 'light' ? colors.black : colors.white,
        svgFill: mode === 'light' ? colors.grey2 : colors.white,
        inputBorderColor: mode === 'light' ? colors.grey3 : colors.grey4,
        textFieldBorderColor:
          mode === 'light' ? colors.mainColor : colors.darkMain,
        neutral: {
          default: '#9098A1',
          bg: '#F7F8FC',
          1: 'rgba(9, 16, 29, 1)',
          2: '#394452',
          3: '#545D69',
          rgb_3: '84,93,105',
          4: '#6D7580',
          5: '#858C94',
          6: '#A5ABB3',
          7: '#DADEE3',
          8: '#EBEEF2',
          9: '#F4F6F9',
          90: '#2C3A4B',
          100: '#09101D',
          black_80: 'rgba(9, 16, 29, 0.8)',
          black: mode === 'light' ? 'rgba(9, 16, 29, 1)' : colors.white,
          contrast: mode === 'light' ? '#394452' : colors.white
        },
        customColor: (light, dark) => (mode === 'light' ? light : dark)
      }
    },
    overrides: {
      MuiToolbar: {
        gutters: {
          paddingLeft: '16px !important'
        }
      }
    },
    spikesorting: {
      stopped: {
        color: colors.accent[3],
        bg: hexToRgba(colors.accent[3], 0.25)
      },
      pending: {
        color: colors.status.warning,
        bg: colors.bg.warning
      },
      success: {
        color: colors.status.success,
        bg: colors.bg.success
      },
      queue: {
        color: colors.status.info,
        bg: colors.bg.info
      }
    },
    aiTraining: {
      failure: {
        color: colors.accent[6],
        bg: 'transparent'
      },
      pending: {
        color: colors.accent[3],
        bg: colors.bg.pending
      },
      success: {
        color: colors.accent[9],
        bg: colors.bg.success2
      },
      queue: {
        color: colors.darkMain,
        bg: colors.bg.queue
      }
    },
    probes: {
      status: {
        unused: mode === 'light' ? colors.darkBlue : colors.lightBlue,
        consumed: colors.lighterGreen
      }
    },
    notifications: {
      plain: colors.bg.plain,
      success: colors.bg.success,
      warning: colors.bg.warning,
      error: '#FEEFEF',
      info: colors.bg.info,
      in_progress: 'rgba(45, 155, 250, 0.25)',
      in_queue: '#EEF2FA',
      message:
        mode === 'light'
          ? colors.white
          : 'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #121212'
    },
    typography: {
      fontFamily: 'Poppins, sans-serif',
      button: {
        fontWeight: 400
      }
    },
    searchInput: {
      height: 36,
      padding: 0,
      fontSize: 13,
      '&::placeholder': {
        opacity: 1,
        color: 'red'
      },

      '&::-ms-input-placeholder': {
        color: 'red'
      },

      '&::-webkit-input-placeholder': { color: 'red' },
      '&::-moz-placeholder': { color: 'red' },
      '&:-ms-input-placeholder': {
        color: 'red'
      },
      '&:-moz-placeholder': { color: 'red' }
    },
    container: customStyle => ({
      height: `calc(100vh - ${0}px)`,
      overflowY: 'auto',
      background: mode === 'light' ? colors.white : colors.secongBG,
      ...customStyle
    }),
    status: customStyle => ({
      textAlign: 'center',
      fontSize: 13,
      maxWidth: 100,
      margin: '0 auto',
      borderRadius: 4,
      lineHeight: '20px',
      padding: '2px 8px',
      ...customStyle
    }),
    errorMessage: {
      margin: '15px 0',
      textAlign: 'center',
      fontSize: 12,
      color: colors.error
    },
    blured: {
      pointerEvents: 'none',
      filter: 'blur(2px)'
    },
    scrollbar: size => ({
      '&::-webkit-scrollbar': {
        width: size || TABLE_SCROLLBAR_SIZE,
        height: size || TABLE_SCROLLBAR_SIZE,
        zIndex: 1
      },
      '&::-webkit-scrollbar-track': { background: 'transparent' },
      '&::-webkit-scrollbar-corner': { background: 'transparent' },
      '&::-webkit-scrollbar-thumb': {
        background: mode === 'light' ? colors.mainColor : colors.darkMain
      }
    })
  })

export default theme
