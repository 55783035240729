import { combineReducers } from 'redux'
import {
  clusters,
  correlograms,
  similarity,
  units,
  waveforms,
  features,
  curationAI
} from './reducer'

export default combineReducers({
  correlograms,
  clusters,
  similarity,
  units,
  waveforms,
  features,
  curationAI
})
