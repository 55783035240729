import axios from 'axios'
import config from '../utils/config'
import { resolveError } from '../utils/utils'

export const getClusterView = async ({
  page = 1,
  per_page = 20,
  run_id = null,
  curation_id = null,
  sort_by = null,
  direction = null,
  sort_by_second = null
}) =>
  axios
    .get(`${config.apiUrl}/run/clusterview`, {
      params: {
        page,
        per_page,
        run_id,
        curation_id,
        sort_by,
        direction,
        sort_by_second
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getSimilarityView = async ({
  page = 1,
  per_page = 20,
  run_id = null,
  cluster_id = null,
  curation_id = null,
  sort_by = null,
  direction = null,
  ai = false
}) =>
  axios
    .post(`${config.apiUrl}/run/clustersimilarity`, {
      page,
      per_page,
      run_id,
      cluster_id,
      curation_id,
      sort_by,
      direction,
      ai
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getRecommendations = async id =>
  axios
    .get(`${config.apiUrl}/run/recommendations`, { params: id })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
