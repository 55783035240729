import React from 'react'
import CurationAIIcon from '../../Icons/curationAIIcon'

const curation_AI = [
  {
    target: '#curation-AI-toggle',
    title: (
      <>
        <CurationAIIcon
          style={{
            width: 24,
            height: 24,
            marginRight: 8,
            verticalAlign: 'text-bottom'
          }}
        />
        AI curation
      </>
    ),
    content: (
      <>
        AI provides recommendations to curate a <br />
        spikesorted dataset based on models <br />
        trained on human curation techniques. <br />
        Click AI Curation button to start.
      </>
    ),
    disableBeacon: true,
    hideBackButton: true,
    disableOverlay: true
  },
  {
    target: '#cluster-view-base-merge-visibility-icon',
    placement: 'right-center',
    title: (
      <>
        <CurationAIIcon
          style={{
            width: 24,
            height: 24,
            marginRight: 8,
            verticalAlign: 'text-bottom'
          }}
        />
        AI curation
      </>
    ),
    content: (
      <>
        Units highlighted in blue are Curation AI merge suggestions. <br />
        Units highlighted in grey are noise units. Clicking on the <br />
        highlighted unit you can see merge suggestions in the <br />
        Similarity view. You can hide the suggestion by clicking the <br />
        eye icon.
      </>
    ),
    disableBeacon: true,
    hideBackButton: false,
    disableOverlay: true
  },
  {
    target: '#similarity-view-base-merge-visibility-icon',
    placement: 'right-center',
    title: (
      <>
        <CurationAIIcon
          style={{
            width: 24,
            height: 24,
            marginRight: 8,
            verticalAlign: 'text-bottom'
          }}
        />
        AI curation
      </>
    ),
    content: (
      <>
        In the Similarity view widget highlighted units are AI <br />
        merge suggestions. You can label units, merge them or <br />
        deselect and hide units. Let’s try it now 🎉!
      </>
    ),
    disableBeacon: true,
    hideBackButton: false,
    disableOverlay: true
  }
]

// Define the steps
const getTourSteps = user => ({
  files: [
    {
      target: '#upload',
      title: 'Upload File',
      content: (
        <>
          Welcome to DBCloud {user} 👋 . Click <br /> here to download a Desktop
          data <br /> upload app.{' '}
        </>
      ),
      placement: 'bottom-end',
      hideNextButton: true,
      disableBeacon: true,
      spotlightClicks: true
      // styles: {
      //   options: {
      //     zIndex: 1100
      //   }
      // }
    },
    {
      target: '#download-app-modal',
      title: 'Download App',
      content: (
        <>
          Please select your operating system, and return to <br /> this page
          after initiating data upload.
        </>
      ),
      spotlightPadding: 0,
      offset: 0,
      styles: {
        options: {
          zIndex: 20000
        }
      }
    },
    {
      target: '.actions-table-row',
      title: 'Dataset actions',
      content: (
        <>
          Use this action bar to rename, move, download or <br /> delete a
          dataset.
        </>
      ),
      placement: 'bottom-end',
      spotlightClicks: true,
      disableBeacon: true
    },
    {
      target: '#row-0-first',
      title: 'Viewing Data',
      content: (
        <>
          Click on any dataset to view and run analysis using <br /> your
          interactive dashboard.
        </>
      ),
      placement: 'bottom-start',
      spotlightClicks: true,
      disableBeacon: true
    }
  ],
  recording: [
    {
      target: '#recording-tour-step-1',
      title: 'Widgets interface',
      content: (
        <>
          {user}, let’s start 🚀 . <br />
          This is your interactive dashboard to view a summary
          <br /> of your data and to perform various analysis.
        </>
      ),
      placement: 'right-start',
      disableBeacon: true,
      hideBackButton: true,
      disableOverlay: true
    },
    {
      target: '#widgets-menu>div',
      title: 'Widgets menu',
      spotlightPadding: 0,

      content: <>Click on this dropdown menu to select widgets.</>,
      placement: 'left',

      styles: {
        options: {
          zIndex: 1100
        }
      }
    },
    {
      target: '#actions-menu>div',
      title: 'Actions menu',
      content: (
        <>
          Click on this dropdown menu to run analytic tools <br /> as well as
          other utility functions.
        </>
      ),
      spotlightPadding: 0,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 1100
        }
      }
    },
    {
      target: '#newSpikesorting',
      title: 'New Spikesorting',
      content: <>Click this button to start a new spikesorting session.</>,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000
        }
      }
    },
    {
      target: '#table-row0',
      title: ' Spikesorting',
      content: (
        <>
          All spikesorting sessions from one dataset are <br /> displayed in
          this table.{' '}
        </>
      ),
      placement: 'bottom',
      spotlightPadding: 0,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000
        }
      }
    },
    {
      target: '.actions-table-row>button:nth-child(2)',
      title: 'Sorting log',
      content: (
        <>
          Click here to see more detailed progression of a <br /> spikesorting
          session from the sorting log.
        </>
      ),
      spotlightPadding: 2,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000
        },
        spotlight: {
          borderRadius: 40
        }
      }
    },
    ...curation_AI
    // {
    //   target: '.actions-table-row>button:nth-child(4)',
    //   title: 'Download spikesorted data',
    //   content: <>Click here to download the spikesorted dataset.</>,
    //   spotlightPadding: 2,
    //   disableBeacon: true,
    //   styles: {
    //     options: {
    //       zIndex: 10000
    //     },
    //     spotlight: {
    //       borderRadius: 40
    //     }
    //   }
    // }
  ],

  curation_AI,
  timeseriesForRecording: [
    {
      target: '#timeseries-widget',
      title: 'Time Series',
      content: (
        <>
          This is an interactive <br /> GUI to view any <br /> recording
          session.
        </>
      ),
      placement: 'left',
      spotlightPadding: 0,

      hideBackButton: true,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 20000
        }
      }
    },
    {
      target: '#timeseries-mapping',
      title: 'Mapping',
      content: (
        <>
          This is an interactive probe map. You can build your <br /> own or use
          existing DBC probe maps.{' '}
        </>
      ),
      placement: 'right',
      placementBeacon: 'top',
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000
        }
      }
    },
    {
      target: '#view-dropdown>div',
      title: 'Navigation Menu',
      content: (
        <>
          Click on this dropdown menu to see a list of <br /> actions to
          navigate through the Time Series. The <br /> keyboard shortcuts are
          shown.
        </>
      ),
      placement: 'left',
      spotlightPadding: 0,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000
        }
      }
    },
    {
      target: '#actions-dropdown>div',
      title: 'Actions menu',
      content: (
        <>
          Click on this dropdown menu to see a list of <br /> possible actions.
        </>
      ),
      placement: 'left',
      spotlightPadding: 0,
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000
        }
      }
    },
    {
      target: '#raster-plot',
      title: 'Raster plot',
      content: (
        <>
          The raster plot shows the spike timestamps of <br /> every unit for
          the “default” spikesorting session.
        </>
      ),
      placement: 'top-start',
      placementBeacon: 'left',
      disableBeacon: true,
      styles: {
        options: {
          zIndex: 10000
        }
      }
    }
  ]
})

export default getTourSteps
