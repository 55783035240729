import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import './style.css'
import { Grid, Typography } from '@material-ui/core'
import { hexToRgba } from '../../utils/styles'

const background = progress => {
  if (progress < 50) {
    return '#E1604D'
  } else if (progress >= 50 && progress < 100) {
    return '#FEAC4C'
  }

  return '#21B784'
}

const useStyles = makeStyles(() => ({
  meter: ({ progress }) => ({
    background: hexToRgba(background(progress), 0.15),
    height: 6,
    position: 'relative',
    maxWidth: 110,
    width: '100%',
    overflow: 'hidden',
    borderRadius: 6,
    '& span': {
      display: 'block',
      height: '100%'
    }
  }),
  progress: ({ progress }) => ({
    background: background(progress),
    borderRadius: 6,
    animation: 'progressBar 3s ease-in-out',
    animationFillMode: 'both'
  }),
  progressCount: ({ progress }) => ({
    color: background(progress),
    marginLeft: 6,
    fontSize: 14,
    lineHeight: '20px',
    fontWeight: 600
  })
}))

const ProgressBar = ({ progress, overrideClasses = {} }) => {
  const classes = useStyles({ progress })

  return (
    <Grid
      wrap="nowrap"
      alignItems="center"
      justify="center"
      container
      className={cn(classes.root, overrideClasses.root)}
    >
      <div className={cn(classes.meter, overrideClasses.meter)}>
        <span
          style={{
            width: `${progress}%`
          }}
        >
          <span className={cn(classes.progress, overrideClasses.progress)} />
        </span>
      </div>
      <Typography className={classes.progressCount}>
        {progress || 0}%
      </Typography>
    </Grid>
  )
}

export default React.memo(ProgressBar)
