import React from 'react'
import { Grid, Typography, useTheme } from '@material-ui/core'
import ActionButton from '../../ActionButton'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  triggerChanges: {
    position: 'absolute',
    padding: '0 165px',
    bottom: '0',
    width: '100%',
    height: 62,
    left: '0',
    zIndex: 2,
    background: theme.palette.colors.customColor(
      theme.palette.secondary['20'],
      theme.palette.colors.secongBG
    )
  },
  body: {
    maxWidth: 955,
    width: '100%',
    margin: '0 auto',
    '& button': {
      height: 36,
      minHeight: 36,
      marginLeft: 16,
      borderRadius: 6
    }
  },
  text: {
    marginRight: 24,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.colors.color
  }
}))

const WatcherChanges = ({ resetForm, handleSubmit, isSubmitting }) => {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.triggerChanges}
    >
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        className={classes.body}
      >
        <Typography className={classes.text}>
          There are unsaved changes
        </Typography>
        <ActionButton
          label="Cancel"
          onClick={resetForm}
          customStyles={{
            backgroundColor: theme.palette.colors.neutral.default,
            width: 'auto'
          }}
        />
        <ActionButton
          label="Save"
          onClick={handleSubmit}
          loading={isSubmitting}
          customStyles={{
            backgroundColor: theme.palette.secondary['100'],
            width: 'auto'
          }}
        />
      </Grid>
    </Grid>
  )
}

export default WatcherChanges
