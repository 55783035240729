import React from 'react'

export const svg128_2 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="76"
        height="1345"
        viewBox="0 0 76 1345"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76 0H0V151.081V1332L21 1345L76 151.081V0Z"
          fill={color}
        />
        <path
          d="M2 112C2 110.343 3.34315 109 5 109H17C18.6569 109 20 110.343 20 112V123.077C20 124.734 18.6569 126.077 17 126.077H5C3.34315 126.077 2 124.734 2 123.077V112Z"
          fill="white"
        />
        <path
          d="M2 131.077C2 129.42 3.34315 128.077 5 128.077H17C18.6569 128.077 20 129.42 20 131.077V142.154C20 143.811 18.6569 145.154 17 145.154H5C3.34315 145.154 2 143.811 2 142.154V131.077Z"
          fill="white"
        />
        <path
          d="M2 150.154C2 148.497 3.34315 147.154 5 147.154H17C18.6569 147.154 20 148.497 20 150.154V161.231C20 162.888 18.6569 164.231 17 164.231H5C3.34315 164.231 2 162.888 2 161.231V150.154Z"
          fill="white"
        />
        <path
          d="M2 169.231C2 167.574 3.34315 166.231 5 166.231H17C18.6569 166.231 20 167.574 20 169.231V180.308C20 181.964 18.6569 183.308 17 183.308H5C3.34315 183.308 2 181.964 2 180.308V169.231Z"
          fill="white"
        />
        <path
          d="M2 188.308C2 186.651 3.34315 185.308 5 185.308H17C18.6569 185.308 20 186.651 20 188.308V199.385C20 201.041 18.6569 202.385 17 202.385H5C3.34315 202.385 2 201.041 2 199.385V188.308Z"
          fill="white"
        />
        <path
          d="M2 207.385C2 205.728 3.34315 204.385 5 204.385H17C18.6569 204.385 20 205.728 20 207.385V218.462C20 220.118 18.6569 221.462 17 221.462H5C3.34315 221.462 2 220.118 2 218.462V207.385Z"
          fill="white"
        />
        <path
          d="M2 226.462C2 224.805 3.34315 223.462 5 223.462H17C18.6569 223.462 20 224.805 20 226.462V237.538C20 239.195 18.6569 240.538 17 240.538H5C3.34315 240.538 2 239.195 2 237.538V226.462Z"
          fill="white"
        />
        <path
          d="M2 245.538C2 243.882 3.34315 242.538 5 242.538H17C18.6569 242.538 20 243.882 20 245.538V256.615C20 258.272 18.6569 259.615 17 259.615H5C3.34315 259.615 2 258.272 2 256.615V245.538Z"
          fill="white"
        />
        <path
          d="M2 264.615C2 262.959 3.34315 261.615 5 261.615H17C18.6569 261.615 20 262.959 20 264.615V275.692C20 277.349 18.6569 278.692 17 278.692H5C3.34315 278.692 2 277.349 2 275.692V264.615Z"
          fill="white"
        />
        <path
          d="M2 283.692C2 282.035 3.34315 280.692 5 280.692H17C18.6569 280.692 20 282.035 20 283.692V294.769C20 296.426 18.6569 297.769 17 297.769H5C3.34315 297.769 2 296.426 2 294.769V283.692Z"
          fill="white"
        />
        <path
          d="M2 302.769C2 301.112 3.34315 299.769 5 299.769H17C18.6569 299.769 20 301.112 20 302.769V313.846C20 315.503 18.6569 316.846 17 316.846H5C3.34315 316.846 2 315.503 2 313.846V302.769Z"
          fill="white"
        />
        <path
          d="M2 321.846C2 320.189 3.34315 318.846 5 318.846H17C18.6569 318.846 20 320.189 20 321.846V332.923C20 334.58 18.6569 335.923 17 335.923H5C3.34315 335.923 2 334.58 2 332.923V321.846Z"
          fill="white"
        />
        <path
          d="M2 340.923C2 339.266 3.34315 337.923 5 337.923H17C18.6569 337.923 20 339.266 20 340.923V352C20 353.657 18.6569 355 17 355H5C3.34315 355 2 353.657 2 352V340.923Z"
          fill="white"
        />
        <path
          d="M2 360C2 358.343 3.34315 357 5 357H17C18.6569 357 20 358.343 20 360V371.077C20 372.734 18.6569 374.077 17 374.077H5C3.34315 374.077 2 372.734 2 371.077V360Z"
          fill="white"
        />
        <path
          d="M2 379.077C2 377.42 3.34315 376.077 5 376.077H17C18.6569 376.077 20 377.42 20 379.077V390.154C20 391.811 18.6569 393.154 17 393.154H5C3.34315 393.154 2 391.811 2 390.154V379.077Z"
          fill="white"
        />
        <path
          d="M2 398.154C2 396.497 3.34315 395.154 5 395.154H17C18.6569 395.154 20 396.497 20 398.154V409.231C20 410.888 18.6569 412.231 17 412.231H5C3.34315 412.231 2 410.888 2 409.231V398.154Z"
          fill="white"
        />
        <path
          d="M2 417.231C2 415.574 3.34315 414.231 5 414.231H17C18.6569 414.231 20 415.574 20 417.231V428.308C20 429.964 18.6569 431.308 17 431.308H5C3.34315 431.308 2 429.964 2 428.308V417.231Z"
          fill="white"
        />
        <path
          d="M2 436.308C2 434.651 3.34315 433.308 5 433.308H17C18.6569 433.308 20 434.651 20 436.308V447.385C20 449.041 18.6569 450.385 17 450.385H5C3.34315 450.385 2 449.041 2 447.385V436.308Z"
          fill="white"
        />
        <path
          d="M2 455.385C2 453.728 3.34315 452.385 5 452.385H17C18.6569 452.385 20 453.728 20 455.385V466.461C20 468.118 18.6569 469.461 17 469.461H5C3.34315 469.461 2 468.118 2 466.461V455.385Z"
          fill="white"
        />
        <path
          d="M2 474.461C2 472.805 3.34315 471.461 5 471.461H17C18.6569 471.461 20 472.805 20 474.461V485.538C20 487.195 18.6569 488.538 17 488.538H5C3.34315 488.538 2 487.195 2 485.538V474.461Z"
          fill="white"
        />
        <path
          d="M2 493.538C2 491.881 3.34315 490.538 5 490.538H17C18.6569 490.538 20 491.881 20 493.538V504.615C20 506.272 18.6569 507.615 17 507.615H5C3.34315 507.615 2 506.272 2 504.615V493.538Z"
          fill="white"
        />
        <path
          d="M2 512.615C2 510.958 3.34315 509.615 5 509.615H17C18.6569 509.615 20 510.958 20 512.615V523.692C20 525.349 18.6569 526.692 17 526.692H5C3.34315 526.692 2 525.349 2 523.692V512.615Z"
          fill="white"
        />
        <path
          d="M2 531.692C2 530.035 3.34315 528.692 5 528.692H17C18.6569 528.692 20 530.035 20 531.692V542.769C20 544.426 18.6569 545.769 17 545.769H5C3.34315 545.769 2 544.426 2 542.769V531.692Z"
          fill="white"
        />
        <path
          d="M2 550.769C2 549.112 3.34315 547.769 5 547.769H17C18.6569 547.769 20 549.112 20 550.769V561.846C20 563.503 18.6569 564.846 17 564.846H5C3.34315 564.846 2 563.503 2 561.846V550.769Z"
          fill="white"
        />
        <path
          d="M2 569.846C2 568.189 3.34315 566.846 5 566.846H17C18.6569 566.846 20 568.189 20 569.846V580.923C20 582.58 18.6569 583.923 17 583.923H5C3.34315 583.923 2 582.58 2 580.923V569.846Z"
          fill="white"
        />
        <path
          d="M2 588.923C2 587.266 3.34315 585.923 5 585.923H17C18.6569 585.923 20 587.266 20 588.923V600C20 601.657 18.6569 603 17 603H5C3.34315 603 2 601.657 2 600V588.923Z"
          fill="white"
        />
        <path
          d="M2 608C2 606.343 3.34315 605 5 605H17C18.6569 605 20 606.343 20 608V619.077C20 620.734 18.6569 622.077 17 622.077H5C3.34315 622.077 2 620.734 2 619.077V608Z"
          fill="white"
        />
        <path
          d="M2 627.077C2 625.42 3.34315 624.077 5 624.077H17C18.6569 624.077 20 625.42 20 627.077V638.154C20 639.81 18.6569 641.154 17 641.154H5C3.34315 641.154 2 639.81 2 638.154V627.077Z"
          fill="white"
        />
        <path
          d="M2 646.154C2 644.497 3.34315 643.154 5 643.154H17C18.6569 643.154 20 644.497 20 646.154V657.23C20 658.887 18.6569 660.23 17 660.23H5C3.34315 660.23 2 658.887 2 657.23V646.154Z"
          fill="white"
        />
        <path
          d="M2 665.23C2 663.574 3.34315 662.23 5 662.23H17C18.6569 662.23 20 663.574 20 665.23V676.307C20 677.964 18.6569 679.307 17 679.307H5C3.34315 679.307 2 677.964 2 676.307V665.23Z"
          fill="white"
        />
        <path
          d="M2 684.307C2 682.651 3.34315 681.307 5 681.307H17C18.6569 681.307 20 682.651 20 684.307V695.384C20 697.041 18.6569 698.384 17 698.384H5C3.34315 698.384 2 697.041 2 695.384V684.307Z"
          fill="white"
        />
        <path
          d="M2 703.384C2 701.727 3.34315 700.384 5 700.384H17C18.6569 700.384 20 701.727 20 703.384V714.461C20 716.118 18.6569 717.461 17 717.461H5C3.34315 717.461 2 716.118 2 714.461V703.384Z"
          fill="white"
        />
        <path
          d="M2 722.461C2 720.804 3.34315 719.461 5 719.461H17C18.6569 719.461 20 720.804 20 722.461V733.538C20 735.195 18.6569 736.538 17 736.538H5C3.34315 736.538 2 735.195 2 733.538V722.461Z"
          fill="white"
        />
        <path
          d="M2 741.538C2 739.881 3.34315 738.538 5 738.538H17C18.6569 738.538 20 739.881 20 741.538V752.615C20 754.272 18.6569 755.615 17 755.615H5C3.34315 755.615 2 754.272 2 752.615V741.538Z"
          fill="white"
        />
        <path
          d="M2 760.615C2 758.958 3.34315 757.615 5 757.615H17C18.6569 757.615 20 758.958 20 760.615V771.692C20 773.349 18.6569 774.692 17 774.692H5C3.34315 774.692 2 773.349 2 771.692V760.615Z"
          fill="white"
        />
        <path
          d="M2 779.692C2 778.035 3.34315 776.692 5 776.692H17C18.6569 776.692 20 778.035 20 779.692V790.769C20 792.426 18.6569 793.769 17 793.769H5C3.34315 793.769 2 792.426 2 790.769V779.692Z"
          fill="white"
        />
        <path
          d="M2 798.769C2 797.112 3.34315 795.769 5 795.769H17C18.6569 795.769 20 797.112 20 798.769V809.846C20 811.503 18.6569 812.846 17 812.846H5C3.34315 812.846 2 811.503 2 809.846V798.769Z"
          fill="white"
        />
        <path
          d="M2 817.846C2 816.189 3.34315 814.846 5 814.846H17C18.6569 814.846 20 816.189 20 817.846V828.923C20 830.579 18.6569 831.923 17 831.923H5C3.34315 831.923 2 830.579 2 828.923V817.846Z"
          fill="white"
        />
        <path
          d="M2 836.923C2 835.266 3.34315 833.923 5 833.923H17C18.6569 833.923 20 835.266 20 836.923V848C20 849.656 18.6569 851 17 851H5C3.34315 851 2 849.656 2 848V836.923Z"
          fill="white"
        />
        <path
          d="M2 856C2 854.343 3.34315 853 5 853H17C18.6569 853 20 854.343 20 856V867.076C20 868.733 18.6569 870.076 17 870.076H5C3.34315 870.076 2 868.733 2 867.076V856Z"
          fill="white"
        />
        <path
          d="M2 875.076C2 873.42 3.34315 872.076 5 872.076H17C18.6569 872.076 20 873.42 20 875.076V886.153C20 887.81 18.6569 889.153 17 889.153H5C3.34315 889.153 2 887.81 2 886.153V875.076Z"
          fill="white"
        />
        <path
          d="M2 894.153C2 892.496 3.34315 891.153 5 891.153H17C18.6569 891.153 20 892.496 20 894.153V905.23C20 906.887 18.6569 908.23 17 908.23H5C3.34315 908.23 2 906.887 2 905.23V894.153Z"
          fill="white"
        />
        <path
          d="M2 913.23C2 911.573 3.34315 910.23 5 910.23H17C18.6569 910.23 20 911.573 20 913.23V924.307C20 925.964 18.6569 927.307 17 927.307H5C3.34315 927.307 2 925.964 2 924.307V913.23Z"
          fill="white"
        />
        <path
          d="M2 932.307C2 930.65 3.34315 929.307 5 929.307H17C18.6569 929.307 20 930.65 20 932.307V943.384C20 945.041 18.6569 946.384 17 946.384H5C3.34315 946.384 2 945.041 2 943.384V932.307Z"
          fill="white"
        />
        <path
          d="M2 951.384C2 949.727 3.34315 948.384 5 948.384H17C18.6569 948.384 20 949.727 20 951.384V962.461C20 964.118 18.6569 965.461 17 965.461H5C3.34315 965.461 2 964.118 2 962.461V951.384Z"
          fill="white"
        />
        <path
          d="M2 970.461C2 968.804 3.34315 967.461 5 967.461H17C18.6569 967.461 20 968.804 20 970.461V981.538C20 983.195 18.6569 984.538 17 984.538H5C3.34315 984.538 2 983.195 2 981.538V970.461Z"
          fill="white"
        />
        <path
          d="M2 989.538C2 987.881 3.34315 986.538 5 986.538H17C18.6569 986.538 20 987.881 20 989.538V1000.61C20 1002.27 18.6569 1003.61 17 1003.61H5C3.34315 1003.61 2 1002.27 2 1000.61V989.538Z"
          fill="white"
        />
        <path
          d="M2 1008.61C2 1006.96 3.34315 1005.61 5 1005.61H17C18.6569 1005.61 20 1006.96 20 1008.61V1019.69C20 1021.35 18.6569 1022.69 17 1022.69H5C3.34315 1022.69 2 1021.35 2 1019.69V1008.61Z"
          fill="white"
        />
        <path
          d="M2 1027.69C2 1026.03 3.34315 1024.69 5 1024.69H17C18.6569 1024.69 20 1026.03 20 1027.69V1038.77C20 1040.43 18.6569 1041.77 17 1041.77H5C3.34315 1041.77 2 1040.43 2 1038.77V1027.69Z"
          fill="white"
        />
        <path
          d="M2 1046.77C2 1045.11 3.34315 1043.77 5 1043.77H17C18.6569 1043.77 20 1045.11 20 1046.77V1057.85C20 1059.5 18.6569 1060.85 17 1060.85H5C3.34315 1060.85 2 1059.5 2 1057.85V1046.77Z"
          fill="white"
        />
        <path
          d="M2 1065.85C2 1064.19 3.34315 1062.85 5 1062.85H17C18.6569 1062.85 20 1064.19 20 1065.85V1076.92C20 1078.58 18.6569 1079.92 17 1079.92H5C3.34315 1079.92 2 1078.58 2 1076.92V1065.85Z"
          fill="white"
        />
        <path
          d="M2 1084.92C2 1083.27 3.34315 1081.92 5 1081.92H17C18.6569 1081.92 20 1083.27 20 1084.92V1096C20 1097.66 18.6569 1099 17 1099H5C3.34315 1099 2 1097.66 2 1096V1084.92Z"
          fill="white"
        />
        <path
          d="M2 1104C2 1102.34 3.34315 1101 5 1101H17C18.6569 1101 20 1102.34 20 1104V1115.08C20 1116.73 18.6569 1118.08 17 1118.08H5C3.34315 1118.08 2 1116.73 2 1115.08V1104Z"
          fill="white"
        />
        <path
          d="M2 1123.08C2 1121.42 3.34315 1120.08 5 1120.08H17C18.6569 1120.08 20 1121.42 20 1123.08V1134.15C20 1135.81 18.6569 1137.15 17 1137.15H5C3.34315 1137.15 2 1135.81 2 1134.15V1123.08Z"
          fill="white"
        />
        <path
          d="M2 1142.15C2 1140.5 3.34315 1139.15 5 1139.15H17C18.6569 1139.15 20 1140.5 20 1142.15V1153.23C20 1154.89 18.6569 1156.23 17 1156.23H5C3.34315 1156.23 2 1154.89 2 1153.23V1142.15Z"
          fill="white"
        />
        <path
          d="M2 1161.23C2 1159.57 3.34315 1158.23 5 1158.23H17C18.6569 1158.23 20 1159.57 20 1161.23V1172.31C20 1173.96 18.6569 1175.31 17 1175.31H5C3.34315 1175.31 2 1173.96 2 1172.31V1161.23Z"
          fill="white"
        />
        <path
          d="M2 1180.31C2 1178.65 3.34315 1177.31 5 1177.31H17C18.6569 1177.31 20 1178.65 20 1180.31V1191.38C20 1193.04 18.6569 1194.38 17 1194.38H5C3.34315 1194.38 2 1193.04 2 1191.38V1180.31Z"
          fill="white"
        />
        <path
          d="M2 1199.38C2 1197.73 3.34315 1196.38 5 1196.38H17C18.6569 1196.38 20 1197.73 20 1199.38V1210.46C20 1212.12 18.6569 1213.46 17 1213.46H5C3.34315 1213.46 2 1212.12 2 1210.46V1199.38Z"
          fill="white"
        />
        <path
          d="M2 1218.46C2 1216.8 3.34315 1215.46 5 1215.46H17C18.6569 1215.46 20 1216.8 20 1218.46V1229.54C20 1231.19 18.6569 1232.54 17 1232.54H5C3.34315 1232.54 2 1231.19 2 1229.54V1218.46Z"
          fill="white"
        />
        <path
          d="M2 1237.54C2 1235.88 3.34315 1234.54 5 1234.54H17C18.6569 1234.54 20 1235.88 20 1237.54V1248.61C20 1250.27 18.6569 1251.61 17 1251.61H5C3.34315 1251.61 2 1250.27 2 1248.61V1237.54Z"
          fill="white"
        />
        <path
          d="M2 1256.61C2 1254.96 3.34315 1253.61 5 1253.61H17C18.6569 1253.61 20 1254.96 20 1256.61V1267.69C20 1269.35 18.6569 1270.69 17 1270.69H5C3.34315 1270.69 2 1269.35 2 1267.69V1256.61Z"
          fill="white"
        />
        <path
          d="M2 1275.69C2 1274.03 3.34315 1272.69 5 1272.69H17C18.6569 1272.69 20 1274.03 20 1275.69V1286.77C20 1288.43 18.6569 1289.77 17 1289.77H5C3.34315 1289.77 2 1288.43 2 1286.77V1275.69Z"
          fill="white"
        />
        <path
          d="M2 1294.77C2 1293.11 3.34315 1291.77 5 1291.77H17C18.6569 1291.77 20 1293.11 20 1294.77V1305.85C20 1307.5 18.6569 1308.85 17 1308.85H5C3.34315 1308.85 2 1307.5 2 1305.85V1294.77Z"
          fill="white"
        />
        <path
          d="M2 1313.85C2 1312.19 3.34315 1310.85 5 1310.85H17C18.6569 1310.85 20 1312.19 20 1313.85V1324.92C20 1326.58 18.6569 1327.92 17 1327.92H5C3.34315 1327.92 2 1326.58 2 1324.92V1313.85Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="76"
      height="1345"
      viewBox="0 0 76 1345"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76 0H0V151.081V1332L21 1345L76 151.081V0Z"
        fill={color}
      />
    </svg>
  )
}
