import React, { memo } from 'react'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'
import Grid from '@material-ui/core/Grid'
import MapData from '../../utils/MapData/MapData'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    width: 'auto',
    overflow: 'hidden',
    '& a': {
      textDecoration: 'none',
      color: 'inherit'
    }
  },
  breadcrumbItem: {
    marginRight: 6,
    whiteSpace: 'nowrap',
    fontSize: 13,
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.colors.neutral['5']
  },
  currentUrl: {
    color: theme.palette.colors.neutral.black
  }
}))

const Breadcrumb = ({ steps, className }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      wrap="nowrap"
      justify="flex-start"
      alignItems="center"
      className={cn(classes.breadcrumb, className)}
    >
      <MapData data={steps}>
        {({ item, index }) => {
          if (!item) return null
          const { label, to = '#', current, disabled = false } = item
          return (
            <React.Fragment key={index}>
              {index > 0 && (
                <Typography className={classes.breadcrumbItem}>/</Typography>
              )}
              {disabled ? (
                <Item label={label} />
              ) : !current ? (
                <Link to={to}>
                  <Item label={label} />
                </Link>
              ) : (
                <Item label={label} current />
              )}
            </React.Fragment>
          )
        }}
      </MapData>
    </Grid>
  )
}

const Item = memo(({ label, current }) => {
  const classes = useStyles()
  return (
    <Typography
      className={cn(classes.breadcrumbItem, {
        [classes.currentUrl]: current
      })}
      noWrap
    >
      {label}
    </Typography>
  )
})

export default memo(Breadcrumb)
