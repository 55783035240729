import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  initSpikesortings,
  storeSpikesortings
} from '../../../actions/recording'
import { getListOfSpikesortings } from '../../../endpoints/admin'
import useQueryParams from '../../../hooks/useQueryParams'
import { QPARAMS } from '../../../utils/consts'

function useGetSpikesortings(fetchOnRender = true) {
  const loading = useSelector(state => state.recordingStore.loadingSortings)
  const { recordingId } = useParams()
  const query = useQueryParams()
  const page = query.getByName(QPARAMS.SORTINGS_PAGE) || 1

  const dispatch = useDispatch()
  const store = useCallback(
    data => dispatch(storeSpikesortings(data)),
    [dispatch]
  )
  const init = useCallback(
    data => dispatch(initSpikesortings(data)),
    [dispatch]
  )

  const fetchSortings = useCallback(async () => {
    try {
      init(true)
      const data = await getListOfSpikesortings({
        record_id: recordingId,
        page
      })
      store(data)
    } catch (err) {
      console.error(err)
      init(false)
    }
  }, [init, page, recordingId, store])

  useEffect(() => {
    if (fetchOnRender) fetchSortings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, fetchOnRender])

  return { loading, fetchSortings }
}

export default useGetSpikesortings
