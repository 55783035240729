import React from 'react'
import { darken, makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography, Button } from '@material-ui/core'
import cn from 'classnames'
import { hexToRgba } from '../../utils/styles'
import useLimitedAccess from '../../hooks/useLimitedAccess'
import useToggleModal from '../../hooks/useToggleModal'
import { MODALS_ID } from '../../utils/consts'

const useStyles = makeStyles(theme => ({
  disabled: ({ backgroundColor = theme.palette.primary.main }) => ({
    '&[disabled]': {
      color: theme.palette.colors.white,
      backgroundColor: hexToRgba(backgroundColor, 0.5)
    }
  }),
  button: ({
    boxShadow = 'none',
    backgroundColor = theme.palette.primary.main,
    height = 36,
    width = '100%',
    borderRadius = 8,
    color = 'white',
    disabled,
    ...rest
  }) => ({
    position: 'relative',
    backgroundColor,
    width,
    fontWeight: 600,
    borderRadius: borderRadius || 8,
    height,
    minHeight: height,
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    color,
    boxShadow,
    padding: '6px 16px',
    '&:hover': {
      backgroundColor: darken(backgroundColor, 0.3),
      boxShadow
    },
    ...rest
  }),
  customLabel: {
    pointerEvents: 'none'
  },
  label: props => ({
    pointerEvents: 'none',
    color: props.color ? props.color : 'white',
    textTransform: 'none',
    fontSize: props.fontSize ? props.fontSize : 14,
    fontWeight: props.fontWeight ? props.fontWeight : 600,
    lineHeight: '20px',
    padding: 0,
    transition: 'color 0.1s ease-in-out'
  }),
  loading: ({ color }) => ({
    color: color ? color : 'white'
  }),
  icon: {
    display: 'flex',
    marginRight: 8,
    transition: 'color 0.1s ease-in-out',
    '& svg': {
      fontSize: 16
    }
  },
  endIcon: {
    marginRight: 0,
    marginLeft: 8
  },
  hidden: {
    visibility: 'hidden'
  },
  loadingWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const ActionButton = props => {
  const {
    onClick,
    loading,
    label,
    className,
    leftAdornment,
    rightAdornment,
    disabled = false,
    tooltip,
    endIcon,
    customStyles,
    startIcon,
    startIconCustomClass,
    component = 'button',
    overideClasses = {},
    iconClassName,
    ...others
  } = props
  const classes = useStyles({ ...customStyles, disabled })
  const { toggle } = useToggleModal(MODALS_ID.GET_PREMIUM_VERSION_MODAL)
  const { isLimited } = useLimitedAccess()
  const togglePremiumModal = isLimited && disabled ? toggle : null
  return (
    <div onClick={togglePremiumModal}>
      <Button
        component={component}
        onClick={loading || disabled ? () => undefined : onClick}
        variant="contained"
        className={cn(classes.button, className)}
        classes={{
          label: classes.customLabel,
          startIcon: startIconCustomClass,
          disabled: classes.disabled,
          ...overideClasses
        }}
        disabled={disabled}
        {...others}
        endIcon={endIcon}
        startIcon={startIcon}
      >
        {Boolean(leftAdornment) && (
          <div
            className={cn(classes.icon, iconClassName, {
              [classes.hidden]: loading
            })}
          >
            {leftAdornment}
          </div>
        )}
        <Typography
          noWrap
          className={cn(classes.label, overideClasses.label ?? '', {
            [classes.hidden]: loading
          })}
        >
          {label}
        </Typography>
        {Boolean(rightAdornment) && (
          <div
            className={cn(classes.icon, classes.endIcon, iconClassName, {
              [classes.hidden]: loading
            })}
          >
            {rightAdornment}
          </div>
        )}
        {loading && (
          <div className={classes.loadingWrapper}>
            <CircularProgress
              disableShrink
              className={classes.loading}
              size={18}
            />
          </div>
        )}
      </Button>
    </div>
  )
}

export default ActionButton
