import { useMemo } from 'react'
const initialColumns = [
  {
    id: 0,
    label: 'Name Probe',
    active: true
  },
  {
    id: 1,
    label: 'Serial N.',
    active: false
  },
  {
    id: 2,
    label: 'Shanks N.',
    active: true
  },
  {
    id: 3,
    label: 'Channels N.',
    active: true
  },
  {
    id: 4,
    label: 'Select',
    active: true
  }
]
const usePrbFileModalColumns = ({ withSerialNr }) => {
  const columns = useMemo(() => {
    let clone = [...initialColumns]
    clone[1] = { ...clone[1], active: withSerialNr }
    return clone
  }, [withSerialNr])

  return { columns }
}

export default usePrbFileModalColumns
