import React, { useMemo, useCallback } from 'react'
import { QPARAMS } from '../../../../utils/consts'
import CustomDrawer from '../../../CustomDrawer'
import { darken, makeStyles } from '@material-ui/core/styles'
import CurationAIIcon from '../../../Icons/curationAIIcon'
import Typography from '@material-ui/core/Typography'
import CustomTable from '../../../CustomTable'
import useInfoPanel from './hooks/useInfoPanel'
import ActionButton from '../../../ActionButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import cn from 'classnames'
import CustomInput from '../../../FormComponents/CustomInput'
import useEdit from '../../hooks/useEdit'
import { TABLE_SCROLLBAR_SIZE } from '../../../../utils/styles/CONSTANTS'

const useStyles = makeStyles(theme => ({
  drawer: {
    background: theme.palette.colors.backgrounds.contrast,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      'white'
    )
  },
  modalRoot: {
    padding: 24
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    width: 'auto',
    minWidth: 'auto',
    padding: '6px 8px',
    borderRadius: 8,
    marginLeft: 16
  },
  saveButton: {
    width: 68,
    height: 36,
    backgroundColor: theme.palette.colors.lighterGreen,
    '&:hover': {
      backgroundColor: darken(theme.palette.colors.lighterGreen, 0.3)
    }
  },
  filterIcon: {
    margin: 0,
    fontSize: 20
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  titleText: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '36px',
    padding: '8px 16px'
  },
  titleEdit: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginRight: 24,
    marginLeft: 16,
    height: 44
  },
  description: {
    fontSize: 14,
    marginTop: 12,
    marginBottom: 24,
    lineHeight: '18px',
    overflow: 'auto',
    overflowWrap:'break-word',
    maxHeight: '25%',
    '&::-webkit-scrollbar': {
      width: TABLE_SCROLLBAR_SIZE,
      height: TABLE_SCROLLBAR_SIZE,
      zIndex: 1
    },
    '&::-webkit-scrollbar-track': { background: 'transparent' },
    '&::-webkit-scrollbar-corner': { background: 'transparent' },
    '&::-webkit-scrollbar-thumb': { background: theme.palette.primary.main }
  },
  descriptionEdit: {
    borderRadius: 8,
    margin: 0,
    width: '100%',
    padding: '12px 16px 0px 16px'
  },
  expandText: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    cursor: 'pointer',
    display: 'inline',
    color: theme.palette.colors.lighterGreen
  }
}))

const InfoPanel = () => {
  const classes = useStyles()
  const {
    rows,
    pages,
    columns,
    loading,
    selected,
    isAuthorized,
    isEdit,
    isOpened,
    isExpanded,
    onDelete,
    toggleEdit,
    toggleDrawer,
    toggleExpanded
  } = useInfoPanel()

  const { state, loading: saveLoading, handleChange, onEdit } = useEdit()
  const { name: editName, description: editDescription } = state || {}
  const { id, name, description } = selected || {}
  const currentName = editName ?? name
  const currentDescription = editDescription ?? description
  const isLongDescription = currentDescription?.length > 375

  const handleEdit = useCallback(async () => {
    if (name !== currentName || description !== currentDescription)
      await onEdit({
        id,
        name: currentName,
        description: currentDescription
      })
    toggleEdit()
  }, [
    id,
    name,
    description,
    currentName,
    currentDescription,
    onEdit,
    toggleEdit
  ])

  return (
    <CustomDrawer
      open={isOpened}
      onClose={toggleDrawer}
      maxWidth="80%"
      anchor="left"
      customPaperClass={classes.drawer}
      classNames={classes.modalRoot}
    >
      <div className={classes.header}>
        <div className={classes.title}>
          <CurationAIIcon height={32} width={32} />

          {isEdit ? (
            <CustomInput
              name={'name'}
              onChange={handleChange}
              customInputStyle={classes.titleText}
              className={classes.titleEdit}
              value={currentName}
              disabled={!isEdit}
            />
          ) : (
            <Typography className={classes.titleText}>{currentName}</Typography>
          )}
        </div>

        {isAuthorized && (
          <div className={classes.actionContainer}>
            {isEdit ? (
              <ActionButton
                label={'Save'}
                loading={saveLoading}
                onClick={handleEdit}
                className={classes.saveButton}
              />
            ) : (
              <ActionButton
                className={classes.action}
                startIcon={<EditIcon />}
                startIconCustomClass={classes.filterIcon}
                onClick={toggleEdit}
              />
            )}
            <ActionButton
              className={classes.action}
              startIcon={<DeleteIcon />}
              startIconCustomClass={classes.filterIcon}
              onClick={onDelete}
            />
          </div>
        )}
      </div>

      <div className={classes.description}>
        {isEdit ? (
          <CustomInput
            multiline
            name={'description'}
            onChange={handleChange}
            value={currentDescription}
            customInputStyle={cn(classes.description, classes.descriptionEdit)}
          />
        ) : !isLongDescription ? (
          currentDescription
        ) : (
          <>
            {isExpanded
              ? currentDescription
              : `${currentDescription.substring(0, 375)}...`}{' '}
            <Typography onClick={toggleExpanded} className={classes.expandText}>
              {isExpanded ? 'Read less' : 'Read more'}
            </Typography>
          </>
        )}
      </div>
      <CustomTable
        rows={rows}
        pages={pages}
        rowHeight={65}
        cellWidth={120}
        withPagination
        withRecordType={false}
        isSticky
        loading={loading}
        columns={columns}
        customQparams={{
          page: QPARAMS.MODEL_CURATIONS
        }}
      />
    </CustomDrawer>
  )
}

export default InfoPanel
