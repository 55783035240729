import React, { memo, useCallback, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  wrapper: ({ withBottomMargin = true }) => ({
    marginBottom: withBottomMargin ? 24 : 0,
    position: 'relative',
    '&:last-of-type': {
      marginBottom: 0
    }
  }),
  root: ({ isFocused, required = false }) => ({
    width: '100%',
    padding: 0,
    margin: 0,
    backgroundColor: 'transparent',
    '& textarea': {
      padding: 0,
      margin: 0
    },
    '& label': {
      fontSize: 16,
      fontWeight: isFocused ? 600 : 400,
      transform: 'translate(16px, 17px)',
      color: `${theme.palette.colors.neutral.black}!important`,
      '& span': {
        color: required
          ? theme.palette.colors.error
          : theme.palette.colors.neutral.black
      }
    },
    '& > div': {
      margin: 0,
      borderRadius: 8,
      '& fieldset': {
        borderColor: theme.palette.colors.neutral[6]
      }
    },
    '&:hover': {
      '& fieldset': {
        borderColor: theme.palette.colors.neutral[6]
      }
    }
  }),
  focused: () => ({
    '& *': {
      borderColor: `${theme.palette.primary.contrast}!important`
    }
  }),
  disabled: () => ({
    borderColor: theme.palette.colors.neutral[6],
    '& fieldset': {
      borderColor: `${theme.palette.colors.neutral[6]}!important`,
      backgroundColor: theme.palette.colors.grey_15
    }
  }),
  input: {
    padding: '15px 16px 14px',
    fontSize: 16,
    fontWeight: 400,
    color: theme.palette.colors.neutral.black
  },
  hint: {
    fontSize: 13,
    lineHeight: '20px',
    marginTop: 12,
    margin: '12px 15px 0',
    opacity: 0.6,
    color: theme.palette.colors.neutral.black
  },
  errorLabel: {
    fontSize: 10,
    position: 'absolute',
    bottom: -2,
    color: theme.palette.error.main,
    transform: 'translateY(100%)'
  },
  endAdornment: {
    '& button': {
      padding: 0,
      fontSize: 24,
      color: theme.palette.colors.neutral[5],
      '&:hover': {
        color: 'initial'
      }
    }
  }
}))

const CustomInput = props => {
  const [isFocused, toggleFocus] = useState(false)
  const {
    type,
    className,
    classes: customClasses,
    placeholder,
    onChange,
    value,
    label,
    error = {},
    touched = {},
    name = '',
    fullWidth,
    inputBaseRootClass,
    customInputStyle,
    customInputDisabledStyle,
    onBlur,
    onFocus,
    endAdornment,
    variant = 'outlined',
    hint,
    multiline = false,
    rows = 1,
    rowsMax = 5,
    withBottomMargin = true,
    ...others
  } = props || {}

  const classes = useStyles({ isFocused, withBottomMargin, ...props })

  const isError = error[name] && touched[name]

  const handleBlur = useCallback(
    e => {
      toggleFocus(isFocus => !isFocus)
      return onBlur && onBlur(e)
    },
    [onBlur]
  )

  const handleFocus = useCallback(
    e => {
      toggleFocus(isFocus => !isFocus)
      return onFocus && onFocus(e)
    },
    [onFocus]
  )

  return (
    <div className={cn(classes.wrapper, className)}>
      <TextField
        type={type}
        label={label ?? ''}
        placeholder={placeholder}
        name={name}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        className={cn(classes.root, inputBaseRootClass)}
        InputProps={{
          classes: {
            input: cn(classes.input, customInputStyle),
            label: classes.label,
            focused: classes.focused,
            disabled: cn(classes.disabled, customInputDisabledStyle)
          },
          endAdornment: endAdornment && (
            <div className={classes.endAdornment}>{endAdornment}</div>
          )
        }}
        onChange={onChange}
        value={value}
        fullWidth={fullWidth}
        error={isError}
        onBlur={handleBlur}
        onFocus={handleFocus}
        variant={variant}
        {...others}
      />
      {hint && <Typography className={classes.hint}>{hint}</Typography>}
      {isError && (
        <Typography className={classes.errorLabel}>* {error[name]}</Typography>
      )}
    </div>
  )
}

export default memo(CustomInput)
