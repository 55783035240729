import React, { useCallback, useEffect, useState } from 'react'
import SearchInput from '../SearchInput'
import SearchIcon from '@material-ui/icons/Search'
import useQueryParams from '../../../../../../hooks/useQueryParams'
import { QPARAMS } from '../../../../../../utils/consts'
import useSearchQuery from '../../../../../../hooks/useSearchQuery'
import { isEmpty } from 'lodash/fp'
import { IconButton } from '@material-ui/core'
import Storage from '../../../../../AppHeader/components/Storage'
import useStorageKeys from '../../../../../../hooks/useStorageKeys'

const SearchBar = ({ className, inputBaseRootClass, placeholder }) => {
  const query = useQueryParams()
  const keyword = query.getByName(QPARAMS.KEYWORD)?.trim()
  const [value, setValue] = useState('')
  const { insertSearchQuery, deleteSearchQuery } = useSearchQuery()
  const STORAGE_KEY = useStorageKeys()
  const removeFromStorage = key => Storage.removeItem(key)

  const handleSubmit = useCallback(() => {
    deleteSearchQuery(QPARAMS.PAGE)
    removeFromStorage(STORAGE_KEY.PAGE)
    if (isEmpty(value)) {
      removeFromStorage(STORAGE_KEY.KEYWORD)
      return deleteSearchQuery(QPARAMS.KEYWORD)
    }
    Storage.setItem(STORAGE_KEY.KEYWORD, value)
    insertSearchQuery({
      key: QPARAMS.KEYWORD,
      value
    })
  }, [
    STORAGE_KEY.KEYWORD,
    STORAGE_KEY.PAGE,
    deleteSearchQuery,
    insertSearchQuery,
    value
  ])

  const handleChange = useCallback(e => {
    const { value } = e.target
    setValue(value)
  }, [])

  const handleKeyPress = useCallback(
    e => {
      if (e.key?.toLowerCase() === 'enter') {
        handleSubmit()
      }
    },
    [handleSubmit]
  )

  // set value on page refresh
  useEffect(() => {
    if (!isEmpty(keyword)) {
      setValue(keyword)
    } else {
      setValue('')
      removeFromStorage(STORAGE_KEY.KEYWORD)
    }
  }, [STORAGE_KEY.KEYWORD, keyword])

  return (
    <SearchInput
      className={className}
      inputBaseRootClass={inputBaseRootClass}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      onKeyPress={handleKeyPress}
      endAdornment={
        <IconButton
          onClick={handleSubmit}
          data-id="input-search-bar-submit-button"
        >
          <SearchIcon />
        </IconButton>
      }
    />
  )
}

export default SearchBar
