import React from 'react'

export const svg64_10 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="65"
        height="870"
        viewBox="0 0 65 870"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 0H65L59.5833 835.241L32.5 870L5.41667 835.241L0 0Z"
          fill={color}
        />
        <path
          d="M37 111C37 109.343 38.3431 108 40 108H52C53.6569 108 55 109.343 55 111V122.077C55 123.734 53.6569 125.077 52 125.077H40C38.3431 125.077 37 123.734 37 122.077V111Z"
          fill="white"
        />
        <path
          d="M37 158.077C37 156.42 38.3431 155.077 40 155.077H52C53.6569 155.077 55 156.42 55 158.077V169.154C55 170.811 53.6569 172.154 52 172.154H40C38.3431 172.154 37 170.811 37 169.154V158.077Z"
          fill="white"
        />
        <path
          d="M37 205.154C37 203.497 38.3431 202.154 40 202.154H52C53.6569 202.154 55 203.497 55 205.154V216.231C55 217.888 53.6569 219.231 52 219.231H40C38.3431 219.231 37 217.888 37 216.231V205.154Z"
          fill="white"
        />
        <path
          d="M37 252.231C37 250.574 38.3431 249.231 40 249.231H52C53.6569 249.231 55 250.574 55 252.231V263.308C55 264.964 53.6569 266.308 52 266.308H40C38.3431 266.308 37 264.964 37 263.308V252.231Z"
          fill="white"
        />
        <path
          d="M37 299.308C37 297.651 38.3431 296.308 40 296.308H52C53.6569 296.308 55 297.651 55 299.308V310.385C55 312.041 53.6569 313.385 52 313.385H40C38.3431 313.385 37 312.041 37 310.385V299.308Z"
          fill="white"
        />
        <path
          d="M37 346.385C37 344.728 38.3431 343.385 40 343.385H52C53.6569 343.385 55 344.728 55 346.385V357.461C55 359.118 53.6569 360.461 52 360.461H40C38.3431 360.461 37 359.118 37 357.461V346.385Z"
          fill="white"
        />
        <path
          d="M37 393.461C37 391.805 38.3431 390.461 40 390.461H52C53.6569 390.461 55 391.805 55 393.461V404.538C55 406.195 53.6569 407.538 52 407.538H40C38.3431 407.538 37 406.195 37 404.538V393.461Z"
          fill="white"
        />
        <path
          d="M37 440.539C37 438.882 38.3431 437.539 40 437.539H52C53.6569 437.539 55 438.882 55 440.539V451.615C55 453.272 53.6569 454.615 52 454.615H40C38.3431 454.615 37 453.272 37 451.615V440.539Z"
          fill="white"
        />
        <path
          d="M37 487.615C37 485.958 38.3431 484.615 40 484.615H52C53.6569 484.615 55 485.958 55 487.615V498.692C55 500.349 53.6569 501.692 52 501.692H40C38.3431 501.692 37 500.349 37 498.692V487.615Z"
          fill="white"
        />
        <path
          d="M37 534.692C37 533.036 38.3431 531.692 40 531.692H52C53.6569 531.692 55 533.036 55 534.692V545.769C55 547.426 53.6569 548.769 52 548.769H40C38.3431 548.769 37 547.426 37 545.769V534.692Z"
          fill="white"
        />
        <path
          d="M37 581.769C37 580.112 38.3431 578.769 40 578.769H52C53.6569 578.769 55 580.112 55 581.769V592.846C55 594.503 53.6569 595.846 52 595.846H40C38.3431 595.846 37 594.503 37 592.846V581.769Z"
          fill="white"
        />
        <path
          d="M37 628.846C37 627.189 38.3431 625.846 40 625.846H52C53.6569 625.846 55 627.189 55 628.846V639.923C55 641.58 53.6569 642.923 52 642.923H40C38.3431 642.923 37 641.58 37 639.923V628.846Z"
          fill="white"
        />
        <path
          d="M37 675.923C37 674.266 38.3431 672.923 40 672.923H52C53.6569 672.923 55 674.266 55 675.923V687C55 688.657 53.6569 690 52 690H40C38.3431 690 37 688.657 37 687V675.923Z"
          fill="white"
        />
        <path
          d="M37 723C37 721.343 38.3431 720 40 720H52C53.6569 720 55 721.343 55 723V734.077C55 735.734 53.6569 737.077 52 737.077H40C38.3431 737.077 37 735.734 37 734.077V723Z"
          fill="white"
        />
        <path
          d="M37 770.077C37 768.42 38.3431 767.077 40 767.077H52C53.6569 767.077 55 768.42 55 770.077V781.154C55 782.81 53.6569 784.154 52 784.154H40C38.3431 784.154 37 782.81 37 781.154V770.077Z"
          fill="white"
        />
        <path
          d="M37 817.154C37 815.497 38.3431 814.154 40 814.154H52C53.6569 814.154 55 815.497 55 817.154V828.231C55 829.888 53.6569 831.231 52 831.231H40C38.3431 831.231 37 829.888 37 828.231V817.154Z"
          fill="white"
        />
        <path
          d="M12 87C12 85.3431 13.3431 84 15 84H27C28.6569 84 30 85.3431 30 87V98.0769C30 99.7338 28.6569 101.077 27 101.077H15C13.3431 101.077 12 99.7338 12 98.0769V87Z"
          fill="white"
        />
        <path
          d="M12 134.077C12 132.42 13.3431 131.077 15 131.077H27C28.6569 131.077 30 132.42 30 134.077V145.154C30 146.811 28.6569 148.154 27 148.154H15C13.3431 148.154 12 146.811 12 145.154V134.077Z"
          fill="white"
        />
        <path
          d="M12 181.154C12 179.497 13.3431 178.154 15 178.154H27C28.6569 178.154 30 179.497 30 181.154V192.231C30 193.888 28.6569 195.231 27 195.231H15C13.3431 195.231 12 193.888 12 192.231V181.154Z"
          fill="white"
        />
        <path
          d="M12 228.231C12 226.574 13.3431 225.231 15 225.231H27C28.6569 225.231 30 226.574 30 228.231V239.308C30 240.964 28.6569 242.308 27 242.308H15C13.3431 242.308 12 240.964 12 239.308V228.231Z"
          fill="white"
        />
        <path
          d="M12 275.308C12 273.651 13.3431 272.308 15 272.308H27C28.6569 272.308 30 273.651 30 275.308V286.385C30 288.041 28.6569 289.385 27 289.385H15C13.3431 289.385 12 288.041 12 286.385V275.308Z"
          fill="white"
        />
        <path
          d="M12 322.385C12 320.728 13.3431 319.385 15 319.385H27C28.6569 319.385 30 320.728 30 322.385V333.461C30 335.118 28.6569 336.461 27 336.461H15C13.3431 336.461 12 335.118 12 333.461V322.385Z"
          fill="white"
        />
        <path
          d="M12 369.461C12 367.805 13.3431 366.461 15 366.461H27C28.6569 366.461 30 367.805 30 369.461V380.538C30 382.195 28.6569 383.538 27 383.538H15C13.3431 383.538 12 382.195 12 380.538V369.461Z"
          fill="white"
        />
        <path
          d="M12 416.539C12 414.882 13.3431 413.539 15 413.539H27C28.6569 413.539 30 414.882 30 416.539V427.615C30 429.272 28.6569 430.615 27 430.615H15C13.3431 430.615 12 429.272 12 427.615V416.539Z"
          fill="white"
        />
        <path
          d="M12 463.615C12 461.958 13.3431 460.615 15 460.615H27C28.6569 460.615 30 461.958 30 463.615V474.692C30 476.349 28.6569 477.692 27 477.692H15C13.3431 477.692 12 476.349 12 474.692V463.615Z"
          fill="white"
        />
        <path
          d="M12 510.692C12 509.036 13.3431 507.692 15 507.692H27C28.6569 507.692 30 509.036 30 510.692V521.769C30 523.426 28.6569 524.769 27 524.769H15C13.3431 524.769 12 523.426 12 521.769V510.692Z"
          fill="white"
        />
        <path
          d="M12 557.769C12 556.112 13.3431 554.769 15 554.769H27C28.6569 554.769 30 556.112 30 557.769V568.846C30 570.503 28.6569 571.846 27 571.846H15C13.3431 571.846 12 570.503 12 568.846V557.769Z"
          fill="white"
        />
        <path
          d="M12 604.846C12 603.189 13.3431 601.846 15 601.846H27C28.6569 601.846 30 603.189 30 604.846V615.923C30 617.58 28.6569 618.923 27 618.923H15C13.3431 618.923 12 617.58 12 615.923V604.846Z"
          fill="white"
        />
        <path
          d="M12 651.923C12 650.266 13.3431 648.923 15 648.923H27C28.6569 648.923 30 650.266 30 651.923V663C30 664.657 28.6569 666 27 666H15C13.3431 666 12 664.657 12 663V651.923Z"
          fill="white"
        />
        <path
          d="M12 699C12 697.343 13.3431 696 15 696H27C28.6569 696 30 697.343 30 699V710.077C30 711.734 28.6569 713.077 27 713.077H15C13.3431 713.077 12 711.734 12 710.077V699Z"
          fill="white"
        />
        <path
          d="M12 746.077C12 744.42 13.3431 743.077 15 743.077H27C28.6569 743.077 30 744.42 30 746.077V757.154C30 758.81 28.6569 760.154 27 760.154H15C13.3431 760.154 12 758.81 12 757.154V746.077Z"
          fill="white"
        />
        <path
          d="M12 793.154C12 791.497 13.3431 790.154 15 790.154H27C28.6569 790.154 30 791.497 30 793.154V804.231C30 805.888 28.6569 807.231 27 807.231H15C13.3431 807.231 12 805.888 12 804.231V793.154Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="65"
      height="870"
      viewBox="0 0 65 870"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        d="M0 0H65L59.5833 835.241L32.5 870L5.41667 835.241L0 0Z"
        fill={color}
      />
    </svg>
  )
}
