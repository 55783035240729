import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  svgIcon: {
    fill: theme.palette.colors.svgFill,
    width: 24,
    height: 24
  }
}))

const defaultStyle = {}

const DecorateIcon = ({
  icon: SVGIcon,
  style = defaultStyle,
  className = ''
}) => {
  const classes = useStyles()

  return <SVGIcon style={style} className={cn(classes.svgIcon, className)} />
}

export default memo(DecorateIcon)
