import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import CustomTooltip from '../../../CustomTooltip'
import cn from 'classnames'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'

const useStyle = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 502
  },
  labelContainer: ({ labelContainerWidth, labelContainerHeight }) => ({
    display: 'flex',
    alignItems: 'center',
    width: labelContainerWidth,
    height: labelContainerHeight,
    textAlign: 'justify',
    borderRadius: '4px',
    padding: '4px 8px 4px 4px',
    backgroundColor: theme.palette.colors.customColor(
      theme.palette.colors.neutral[3],
      theme.palette.colors.darkRed
    ),
    '&, & p': {
      fontSize: 11,
      fontWeight: 600,
      color: theme.palette.colors.white
    }
  }),
  rightLabel: {
    borderBottomRightRadius: '0px !important',
    borderTopRightRadius: '0px !important'
  },
  leftLabel: {
    borderBottomLeftRadius: '0px !important',
    borderTopLeftRadius: '0px !important'
  },
  directionIcon: {
    width: '16px',
    height: '16px'
  },
  hideLabel: {
    display: 'none !important'
  }
}))

const swapValuePositions = positionsArray => {
  const temp = positionsArray[1]
  positionsArray[1] = positionsArray[0]
  positionsArray[0] = temp

  return positionsArray
}

const CutLabels = ({ positions }) => {
  if (positions[0] > positions[1]) {
    positions = swapValuePositions(positions)
  }

  const selectedWidth = positions[1] - positions[0]
  let labelContainerWidth = 'fit-content'
  let labelContainerHeight = '24px'

  const isChangeContainerDimensions =
    selectedWidth >= 300 && selectedWidth <= 400

  if (isChangeContainerDimensions) {
    labelContainerWidth = '117px'
    labelContainerHeight = '40px'
  }

  const classes = useStyle({ labelContainerWidth, labelContainerHeight })
  const isShowRegularLabel = selectedWidth >= 300

  return (
    <div>
      <div
        className={classes.root}
        style={{
          transform: `translateX(calc(${positions[0]}px))`
        }}
      >
        <LabelInfo
          alignment="left"
          Icon={NavigateNext}
          alignmentClass={classes.leftLabel}
          isShowRegularLabel={isShowRegularLabel}
          selectedWidth={selectedWidth}
          classes={classes}
        />
      </div>

      <div
        className={classes.root}
        style={{
          transform: `translateX(calc(${positions[1]}px - 100%))`
        }}
      >
        <LabelInfo
          alignment="right"
          Icon={NavigateBefore}
          alignmentClass={classes.rightLabel}
          isShowRegularLabel={isShowRegularLabel}
          selectedWidth={selectedWidth}
          classes={classes}
        />
      </div>
    </div>
  )
}

const LabelInfo = ({
  isShowRegularLabel = false,
  Icon,
  alignment,
  alignmentClass,
  selectedWidth = 100,
  classes
}) => {
  const labelText = 'This portion will be removed'
  let contents = [
    <Typography component="p">{labelText}</Typography>,
    <Icon className={classes.directionIcon} />
  ]
  const isSwapContents = alignment === 'right'
  if (isSwapContents) {
    contents = swapValuePositions(contents)
  }

  if (isShowRegularLabel)
    return (
      <div className={cn(classes.labelContainer, alignmentClass)}>
        {contents.map(content => content)}
      </div>
    )

  return (
    <CustomTooltip
      tooltip={labelText}
      placement={alignment === 'left' ? 'left-start' : 'right-start'}
      width="100px"
    >
      <div className={cn(classes.labelContainer, alignmentClass)}>
        {selectedWidth > 60 && <Icon className={classes.directionIcon} />}
      </div>
    </CustomTooltip>
  )
}

export default CutLabels
