import React, { memo, useCallback } from 'react'
import Pagination from '@material-ui/lab/Pagination'
import { Grid, makeStyles } from '@material-ui/core'
import { QPARAMS } from '../../../../utils/consts'
import useSearchQuery from '../../../../hooks/useSearchQuery'
import FilesPerPageSelector from '../FilesPerPageSelector'
import Storage from '../../../AppHeader/components/Storage'
import useFilesQueryParams from '../../hooks/useFilesQueryParams'
import cn from 'classnames'
import {
  FILES_PAGINATOR_HEIGHT,
  TABLE_SCROLLBAR_SIZE
} from '../../../../utils/styles/CONSTANTS'

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 3,
    position: 'sticky',
    padding: `12px 10px`,
    maxHeight: FILES_PAGINATOR_HEIGHT - TABLE_SCROLLBAR_SIZE,
    bottom: TABLE_SCROLLBAR_SIZE - 1,
    left: 0,
    right: 0,
    boxShadow: '0px -1px 0px rgba(0, 0, 0, 0.15)',
    borderTop: `1px solid rgba(0, 0, 0, 0.15)`,
    background: theme.palette.colors.backgrounds.contrast
  },
  list: {
    justifyContent: 'flex-end',
    '& li': {
      '&:first-child': {
        marginRight: 4
      },
      '&:last-child': {
        marginLeft: 4
      },
      '& div': {
        minWidth: 10
      },
      '& button': {
        height: 20,
        minWidth: 20,
        fontSize: 13,
        padding: '0 5px',
        margin: '0 1px',
        opacity: 0.5,
        color: theme.palette.primary.contrast,
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          fontWeight: 600,
          opacity: 1,
          color: theme.palette.primary.contrast,
          '&:hover': {
            backgroundColor: 'initial'
          }
        },
        '& svg': {
          fontSize: 24,
          marginTop: -2
        }
      }
    }
  }
}))

const FilesPagination = ({
  pages,
  withPerPageSelector = false,
  savePage = false,
  className,
  customQparams
}) => {
  const classes = useStyles()
  const { page, per_page, STORAGE_KEY } = useFilesQueryParams(customQparams)
  const { insertSearchQuery, deleteSearchQuery } = useSearchQuery()
  const qparamPage = customQparams?.page || QPARAMS.PAGE
  const qparamPerPage = customQparams?.per_page || QPARAMS.PER_PAGE

  const handlePageChange = useCallback(
    (event, value) => {
      if (!value) {
        return deleteSearchQuery(qparamPage)
      }
      if (savePage) Storage.setItem(STORAGE_KEY.PAGE, value)
      insertSearchQuery({
        key: qparamPage,
        value
      })
    },
    [
      STORAGE_KEY.PAGE,
      deleteSearchQuery,
      insertSearchQuery,
      qparamPage,
      savePage
    ]
  )

  const handleSetRowsPerPage = useCallback(
    value => {
      if (!value) {
        return deleteSearchQuery(qparamPerPage)
      }
      Storage.setItem(STORAGE_KEY.PER_PAGE, value)
      insertSearchQuery([
        {
          key: qparamPerPage,
          value
        },
        {
          key: qparamPage,
          value: 1
        }
      ])

      // remove page to reset page and avoid bugs
      Storage.removeItem(STORAGE_KEY.PAGE)
    },
    [
      STORAGE_KEY.PAGE,
      STORAGE_KEY.PER_PAGE,
      deleteSearchQuery,
      insertSearchQuery,
      qparamPage,
      qparamPerPage
    ]
  )

  return (
    <Grid container justify="flex-end" className={cn(classes.root, className)}>
      {withPerPageSelector && (
        <FilesPerPageSelector
          perPage={per_page}
          onSelect={handleSetRowsPerPage}
        />
      )}
      <Pagination
        count={pages}
        page={Number(page) || 1}
        siblingCount={1}
        boundaryCount={1}
        classes={{
          ul: classes.list
        }}
        color="primary"
        onChange={handlePageChange}
      />
    </Grid>
  )
}

export default memo(FilesPagination)
