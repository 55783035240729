import React from 'react'
import BaseWidget from '../BaseWidget'
import { makeStyles } from '@material-ui/core'
import { MODALS_ID, RECORD_TABS } from '../../../../../../utils/consts'
import { useSelector } from 'react-redux'
import TagsBody from './components/TagsBody'
import useToggleModal from '../../../../../../hooks/useToggleModal'

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px 9px'
  }
}))

export const TagsView = () => {
  const classes = useStyles()
  const tags = useSelector(state => state.recordingStore.tags)

  const { toggle: toggleModal } = useEditTags()

  return (
    <BaseWidget overrideClassName={classes.root}>
      <TagsBody onBodyClick={toggleModal} tags={tags} />
    </BaseWidget>
  )
}

export function useEditTags() {
  return useToggleModal(MODALS_ID.EDIT_TAGS_PUBLICATIONS_MODAL, {
    type: RECORD_TABS.TAGS
  })
}
