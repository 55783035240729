import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getOr } from 'lodash/fp'
import { useSafeDispatch } from '../../../../../../hooks/useSafeDispatch'
import { getCorrelograms } from '../../../../../../endpoints/admin'
import { useFetchResult } from '../../../../../../hooks/useFetchResult'
import { loadCorrelogram } from '../../redux/actions'

export const usePlotData = (record_id, sortingId, unitID1, unitID2) => {
  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)
  const correlograms = useSelector(
    state => state.widgetsCache.correlograms?.data
  )

  const selectedCuration = useSelector(
    state => state.recordingStore.selectedCuration
  )
  const curationId = getOr(null, ['id'], selectedCuration)

  const dataId = `${unitID1}-${unitID2}`

  const saveCorrelogram = payload =>
    safeDispatch(
      loadCorrelogram({
        record_id,
        sortingId,
        data: { [dataId]: payload },
        binSize: payload.data.bin_size_sec * 1000
      })
    )

  const { fetchData, state } = useFetchResult(getCorrelograms, {
    unit1: unitID1,
    unit2: unitID2,
    run_id: sortingId,
    curation_id: curationId,
    cache:
      correlograms[record_id] && correlograms[record_id][sortingId]
        ? correlograms[record_id][sortingId][dataId]
        : null
  })

  useEffect(() => {
    fetchData(saveCorrelogram)
  }, [])

  return state
}
