import { useSelector } from 'react-redux'
import { getOr } from 'lodash/fp'
import { onSplitSpikes } from '../../../../../../../endpoints/admin'

export const useFeaturesData = () => {
  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )
  const { id: sortingId } = selectedSorting || {}

  const selectedCuration = useSelector(
    state => state.recordingStore.selectedCuration
  )
  const curationId = getOr(null, ['id'], selectedCuration)

  const features = useSelector(state => state.widgetsCache.features.data) ?? {}

  const record_id = useSelector(state => state.recordingStore.recording.id)

  const getFeaturesOnUnit = unit =>
    features[record_id] && features[record_id][sortingId]
      ? features[record_id][sortingId][unit]
      : null

  const onSplitSpikeFeatures = (spikes, unitId, subplot) =>
    onSplitSpikes({
      run_id: sortingId,
      curation_id: curationId,
      unitId,
      spikes,
      subplot
    })

  return {
    onSplitSpikeFeatures,
    sortingId,
    curationId
  }
}
