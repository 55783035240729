import React from 'react'
import CustomModal from '../../../../CustomModal'
import SelectPosition from './SelectPosition'
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { SaveDialogBtn, ClearPositionBtn } from './DialogButtons'
import { useSelectPositionModal } from '../hooks'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 15,

    marginLeft: theme.spacing(1),

    fontWeight: 600
  },

  body: {
    fontSize: 16
  },

  icon: {
    color: theme.palette.colors.neutral['5']
  }
}))

export function SelectPositionModal({
  state,
  updateNodes,
  onSaveLayout,
  resetModal
}) {
  const { isOpened, onClose } = useSelectPositionModal()
  const classes = useStyles()
  return (
    <CustomModal
      title="Select position of your widget"
      open={isOpened}
      onClose={() => {
        onClose()
        resetModal()
      }}
      maxWidth="50vw"
      renderActionButton={
        <SaveDialogBtn
          onClick={() => {
            onSaveLayout()
            onClose()
          }}
        />
      }
      leftButton={<ClearPositionBtn onClick={resetModal} />}
    >
      <Grid direction="column" container>
        <Grid container alignItems="center" direction="row">
          <InfoIcon className={classes.icon} fontSize="15px" />

          <Typography className={classes.title}>
            How to select the location:
          </Typography>
        </Grid>

        <Typography className={classes.body}>
          You can select multiple blocks both horizontal and vertical or simply
          drag, the selected area will be the position where the widget will be
          inserted{' '}
        </Typography>
      </Grid>
      <SelectPosition treeData={state} updateNodes={updateNodes} />
    </CustomModal>
  )
}
