import React, { memo } from 'react'
import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import cn from 'classnames'

const HeadTable = props => {
  const {
    columns,
    classes,
    withCheckbox = false,
    withRecordType = false,
    isSticky = false,
    cellWidth = 120
  } = props
  const activeColumns = columns.filter(item => item.active)

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {withCheckbox && <TableCell />}
        {withRecordType && <TableCell />}
        {activeColumns.map(({ id, label }, i) => {
          const isLast = i === Object.keys(activeColumns).length - 1
          const isFirst = (withCheckbox || withRecordType) && i === 0
          return (
            <TableCell
              key={i}
              align={i > 0 ? 'center' : 'left'}
              style={{ right: isLast && isSticky ? 0 : 'auto' }}
            >
              <Typography
                className={cn(classes.columnName, classes.text, {
                  [classes.firstColumn]: isFirst
                })}
                style={{ minWidth: label ? cellWidth : 0 }}
              >
                {label}
              </Typography>
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default memo(HeadTable)
