export const INIT = 'INIT'
export const SET_MAIN_WINDOW_RANGE = 'SET_MAIN_WINDOW_RANGE'
export const SET_CHILD_WINDOW = 'SET_CHILD_WINDOW'
export const TOGGLE_FILTER = 'TOGGLE_FILTER'
export const TOGGLE_CHANNEL = 'TOGGLE_CHANNEL'
export const TOGGLE_CHANNEL_BULK = 'TOGGLE_CHANNEL_BULK'
export const RESET = 'RESET'
export const ADD_DEFAULT_CHANNELS = 'ADD_DEFAULT_CHANNELS'
export const CHANGE_LAFT_PANEL_WIDTH = 'CHANGE_LAFT_PANEL_WIDTH'
export const TOGGLE_FULL_SCREEN_MODE = 'TOGGLE_FULL_SCREEN_MODE'
export const REPLACE_CHANNELS = 'REPLACE_CHANNELS'
export const UPDATE_VIEW = 'UPDATE_VIEW'
export const UPDATE_GROUPS = 'UPDATE_GROUPS'
export const STORE_EVENTS = 'STORE_EVENTS'
export const RESET_TIMESERIES = 'RESET_TIMESERIES'
export const SELECT_EVENT = 'SELECT_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const NAVIGATE_EVENTS = 'NAVIGATE_EVENTS'
export const HIDE_EVENTS_SECTION = 'HIDE_EVENTS_SECTION'
export const HIDE_ALL_EVENTS = 'HIDE_ALL_EVENTS'
export const TOGGLE_ALL_EVENTS_VISIBILITY = 'TOGGLE_ALL_EVENTS_VISIBILITY'
export const SET_EVENT_LOADING = 'SET_EVENT_LOADING'
export const CHANGE_CURSOR = 'CHANGE_CURSOR'
export const CHANGE_TIMESTAMP = 'CHANGE_TIMESTAMP'
export const ADD_NND_DATA = 'ADD_NND_DATA'
export const TOGGLE_NND_VISIBILITY = 'TOGGLE_NND_VISIBILITY'
export const TOGGLE_NND_CHANNEL_VISIBILITY = 'TOGGLE_NND_CHANNEL_VISIBILITY'
export const EDIT_NND_CHANNEL_LABEL = 'EDIT_NND_CHANNEL_LABEL'
export const SET_TIMESERIES_INFO = 'SET_TIMESERIES_INFO'
export const SET_NND_TIMESERIES_INFO = 'SET_NND_TIMESERIES_INFO'
export const REFRESH_TIMESERIES = 'REFRESH_TIMESERIES'
export const SET_CHANNELS_RANGE = 'SET_CHANNELS_RANGE'
export const UPDATE_DISABLED_CHANNELS = 'UPDATE_DISABLED_CHANNELS'
export const TOGGLE_EDIT_MODE = 'TOGGLE_EDIT_MODE'

export const initChildTimeSeries = payload => ({
  type: INIT,
  payload
})

export const updateDisabledChannels = payload => ({
  type: UPDATE_DISABLED_CHANNELS,
  payload
})

export const setMainWindowRange = payload => ({
  type: SET_MAIN_WINDOW_RANGE,
  payload
})

export const setTimeSeriesChildWindow = payload => ({
  type: SET_CHILD_WINDOW,
  payload
})

export const setTimeseriesInfo = payload => ({
  type: SET_TIMESERIES_INFO,
  payload
})

export const setNNDTimeseriesInfo = payload => ({
  type: SET_NND_TIMESERIES_INFO,
  payload
})

export const refreshTimeseries = () => ({
  type: REFRESH_TIMESERIES
})

export const resetTimeseries = () => ({
  type: RESET_TIMESERIES
})

export const storeEvents = data => ({
  type: STORE_EVENTS,
  payload: data
})

export const navigateEvents = direction => ({
  type: NAVIGATE_EVENTS,
  payload: direction
})

export const selectEvent = event => ({
  type: SELECT_EVENT,
  payload: event
})

export const changeTimestamp = new_time => ({
  type: CHANGE_TIMESTAMP,
  payload: new_time
})

export const deleteTimestamp = () => ({
  type: DELETE_EVENT
})

export const hideEventsSection = label => ({
  type: HIDE_EVENTS_SECTION,
  payload: { label }
})

export const hideAllEvents = () => ({ type: HIDE_ALL_EVENTS })

export const toggleAllEventsVisibility = () => ({
  type: TOGGLE_ALL_EVENTS_VISIBILITY
})

export const addNNDData = data => ({
  type: ADD_NND_DATA,
  payload: { data }
})

export const toggleNNDVisibility = () => ({ type: TOGGLE_NND_VISIBILITY })

export const toggleNNDCHannelVisibility = payload => ({
  type: TOGGLE_NND_CHANNEL_VISIBILITY,
  payload
})
export const editNNDChannelLabel = data => ({
  type: EDIT_NND_CHANNEL_LABEL,
  payload: data
})

export const changeCursor = type => ({
  type: CHANGE_CURSOR,
  payload: { type }
})
export const addDefaultChannels = channels => ({
  type: ADD_DEFAULT_CHANNELS,
  payload: { channels }
})

export const toggleFilter = (type, value) => ({
  type: TOGGLE_FILTER,
  payload: { type, value }
})

export const toggleChannel = (channel, group = null) => ({
  type: TOGGLE_CHANNEL,
  payload: { channel, group }
})

export const toggleChannelBulk = (channels, disabled) => ({
  type: TOGGLE_CHANNEL_BULK,
  payload: { channels, disabled }
})

export const replaceChannels = channels => ({
  type: REPLACE_CHANNELS,
  payload: { channels }
})

export const resetData = options => ({
  type: RESET,
  payload: { options }
})

export const changeLeftPanelWidth = leftPanelWidth => ({
  type: CHANGE_LAFT_PANEL_WIDTH,
  payload: { leftPanelWidth }
})

export const toggleFullScreenMode = fullScreenMode => ({
  type: TOGGLE_FULL_SCREEN_MODE,
  payload: { fullScreenMode }
})

export const updateView = view => ({
  type: UPDATE_VIEW,
  payload: { view }
})

export const updateGroups = channels => ({
  type: UPDATE_GROUPS,
  payload: { channels }
})

export const setChannelsRange = range => ({
  type: SET_CHANNELS_RANGE,
  payload: range
})

export const toggleTSEditMODE = payload => ({
  type: TOGGLE_EDIT_MODE,
  payload
})
