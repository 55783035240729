export const SAVE_CORRELOGRAM = 'SAVE_CORRELOGRAM'
export const CLUSTERVIEW_LOADING = 'CLUSTERVIEW_LOADING'
export const SORT_CLUSTERVIEW = 'SORT_CLUSTERVIEW'
export const SAVE_CLUSTERVIEW = 'SAVE_CLUSTERVIEW'
export const SAVE_SIMILARITYVIEW = 'SAVE_SIMILARITYVIEW'
export const UPDATE_SIMILARITYVIEW = 'UPDATE_SIMILARITYVIEW'
export const SORT_SIMILARITYVIEW = 'SORT_SIMILARITYVIEW'
export const SAVE_CLUSTER_UNITS = 'SAVE_CLUSTER_UNITS'
export const SAVE_SIMILARITY_UNITS = 'SAVE_SIMILARITY_UNITS'

export const REINIT_CLUSTER_UNITS = 'REINIT_CLUSTER_UNITS'
export const REINIT_SIMILARITY_UNITS = 'REINIT_SIMILARITY_UNITS'

export const SAVE_WAVEFORM = 'SAVE_WAVEFORM'
export const SWITCH_WAVEFORM_VIEW = 'SWITCH_WAVEFORM_VIEW'
export const CLEAR_WAVEFORMS_CACHE = 'CLEAR_WAVEFORMS_CACHE'
export const SAVE_FEATURES = 'SAVE_FEATURES'
export const SAVE_FEATURES_ERROR = 'SAVE_FEATURES_ERROR'
export const SAVE_WAVEFORMS_ERROR = 'SAVE_WAVEFORMS_ERROR'
export const SELECTED_SPIKES = 'SELECTED_SPIKES'
export const SPLIT_SPIKES = 'SPLIT_SPIKES'
export const START_LOAD_WAVEFORMS = 'START_LOAD_WAVEFORMS'
export const TOGGLE_WAVEFORM_GEOMETRY = 'TOGGLE_WAVEFORM_GEOMETRY'
export const TOGGLE_WAVEFORM_OVERLAPPING = 'TOGGLE_WAVEFORM_OVERLAPPING'

export const TOGGLE_CURATION_AI = 'TOGGLE_CURATION_AI'
export const ADD_RECOMMENDATIONS = 'ADD_RECOMMENDATIONS'
export const STORE_SUGGESTION_VISIBILITY = 'STORE_SUGGESTION_VISIBILITY'
export const TOGGLE_RECOMMENDATIONS = 'TOGGLE_RECOMMENDATIONS'

export const loadCorrelogram = data => ({
  type: SAVE_CORRELOGRAM,
  payload: data
})

export const loadClusterview = data => ({
  type: SAVE_CLUSTERVIEW,
  payload: data
})

export const sortClusterview = data => ({
  type: SORT_CLUSTERVIEW,
  payload: data
})

export const setLoadingClusterview = data => ({
  type: CLUSTERVIEW_LOADING,
  payload: data
})

export const loadSimilarityView = data => ({
  type: SAVE_SIMILARITYVIEW,
  payload: data
})

export const updateSimilarityView = data => ({
  type: UPDATE_SIMILARITYVIEW,
  payload: data
})

export const sortSimilarityView = data => ({
  type: SORT_SIMILARITYVIEW,
  payload: data
})

export const saveClusterUnits = data => ({
  type: SAVE_CLUSTER_UNITS,
  payload: data
})

export const saveSimilarityUnits = data => ({
  type: SAVE_SIMILARITY_UNITS,
  payload: data
})

export const loadWaveform = data => ({
  type: SAVE_WAVEFORM,
  payload: data
})

export const startLoadWaveform = data => ({
  type: START_LOAD_WAVEFORMS,
  payload: data
})

export const loadFeatures = data => ({
  type: SAVE_FEATURES,
  payload: data
})

export const switchWaveformView = data => ({
  type: SWITCH_WAVEFORM_VIEW,
  payload: data
})

export const toggleWaveformGeometry = data => ({
  type: TOGGLE_WAVEFORM_GEOMETRY,
  payload: data
})

export const toggleWaveformOverlapping = data => ({
  type: TOGGLE_WAVEFORM_OVERLAPPING,
  payload: data
})

export const clearWaveformsCache = () => ({
  type: CLEAR_WAVEFORMS_CACHE
})

export const selectSpikesActionCreator = data => ({
  type: SELECTED_SPIKES,
  payload: data
})

export const splitSpikesActionCreator = data => ({
  type: SPLIT_SPIKES,
  payload: data
})

export const toggleCurationAI = option => ({
  type: TOGGLE_CURATION_AI,
  payload: option
})

export const addRecommendations = data => ({
  type: ADD_RECOMMENDATIONS,
  payload: data
})

export const toggleSuggestionVisibility = payload => ({
  type: TOGGLE_RECOMMENDATIONS,
  payload
})

export const storeSuggestionVisibility = payload => ({
  type: STORE_SUGGESTION_VISIBILITY,
  payload
})
