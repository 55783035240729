import React, { createRef, memo, useEffect } from 'react'
import ActionButton from '../../../../../ActionButton'
import CustomModal from '../../../../../CustomModal'
import { Formik } from 'formik'
import SelectMenu from '../../../../../SelectMenu'
import CustomInput from '../../../../../FormComponents/CustomInput/CustomInput'
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { isNotNull } from '../../../../../../utils/utils'
import useNotification from '../../../../../SnackbarManager/hooks/useNotification'
import {
  NOTIFICATION,
  SPIKESORTING_ALGORITHMS
} from '../../../../../../utils/consts'
import useRecordingInfo from '../../../../hooks/useRecordingInfo'
import useNewSpikesortingModal from '../../hooks/useNewSpikesortingModal'
import AIModelsMenu from './components/AIModelsMenu'
import useAIModelsMenu from './components/AIModelsMenu/hooks/useAIModelsMenu'

const useStyles = makeStyles(theme => ({
  body: {
    paddingBottom: 15
  },
  text: {
    fontSize: 18,
    fontWeight: 600,
    margin: '40px 0 24px',
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  },
  parameters: {
    position: 'relative',
    textTransform: 'capitalize',
    marginBottom: 25
  },
  checkbox: {
    marginTop: 5
  },
  checkboxLabel: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  },
  labelText: {
    fontSize: 16,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: `${theme.palette.colors.neutral.black}!important`
  }
}))

const Collection = memo(({ k, items, setFieldValue }) => {
  const classes = useStyles()
  return (
    <Grid container justifyContent="space-around" spacing={3}>
      <Grid item xs={12} sm={3}>
        <Typography className={classes.labelText}>{k}</Typography>
      </Grid>
      {items.map((value, valIndex) => (
        <Grid item xs={4} sm={3} key={valIndex}>
          <Input
            name={k}
            value={value}
            errors
            touched
            handleChange={e => {
              const all = items
              const updatedItem = Number(e.target.value)
              const updated = [
                ...all.slice(0, valIndex),
                updatedItem,
                ...all.slice(valIndex + 1)
              ]
              setFieldValue(updated)
            }}
          />
        </Grid>
      ))}
    </Grid>
  )
})

const NewSpikesortingModal = ({
  onSubmit,
  onClose = () => null,
  onEdit = () => null,
  loading,
  isExperimentInput,
  btnLabel,
  oldValues = null
}) => {
  const classes = useStyles()
  const formRef = createRef(null)
  const addNotification = useNotification()
  const { isFileUploaded } = useRecordingInfo()
  const {
    isOpened,
    toggleModal,
    handleRunSpikesorting,
    initialValues,
    validation,

    //name
    handleChangeName,
    name,

    // selectors
    options,
    isSelectors,

    // algo
    algorithms,
    selectedAlgorithm,
    handleSelectAlgorithm,

    // AI model
    AIModel,
    handleSelectAIModel,

    // disabled channels
    withMuteChannels,
    handleToggleMuteChannels,

    // less spikes
    withLessSpikes,
    handleToggleLessSpikes
  } = useNewSpikesortingModal({
    onSubmit,
    onEdit,
    oldValues,
    isExperimentInput
  })

  useEffect(() => {
    const { resetForm, isSubmitting } = formRef?.current || {}
    if (!isOpened && resetForm && !isSubmitting) {
      resetForm()
    }
  }, [isOpened, formRef])

  useEffect(() => {
    if (!isExperimentInput && !isFileUploaded && isOpened) {
      toggleModal()
      return addNotification({
        type: NOTIFICATION.ERROR,
        title:
          "Uploading of this record isn't completed, you can't run new spikesorting"
      })
    }

    // eslint-disable-next-line
  }, [isFileUploaded, isOpened])

  if (!isExperimentInput && !isFileUploaded) {
    return null
  }

  return (
    <Formik
      validateOnChange={true}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validation}
      innerRef={formRef}
      onSubmit={(values, actions) => handleRunSpikesorting(values, actions)}
    >
      {({
        values,
        touched,
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
        errors
      }) => {
        const inputProps = {
          errors,
          touched,
          handleBlur,
          handleChange,
          required: true
        }
        return (
          <CustomModal
            open={isOpened}
            title={`${oldValues ? 'Edit' : 'New'} Spikesorting`}
            onClose={() => {
              onClose()
              toggleModal()
            }}
            maxWidth={740}
            customBodyClass={classes.body}
            renderActionButton={
              <ActionButton
                label={btnLabel || 'Run Spikesorting'}
                onClick={handleSubmit}
                loading={loading}
              />
            }
          >
            <Input
              required
              label="Name"
              name="name"
              value={name}
              handleChange={handleChangeName}
            />
            <SelectMenu
              onChangeValue={handleSelectAlgorithm}
              items={algorithms}
              value={selectedAlgorithm}
              disabled={oldValues}
              textFieldProps={{
                variant: 'outlined',
                label: 'Algorithm',
                required: true
              }}
            />
            <Typography className={classes.text}>Paramaters</Typography>
            <Grid container spacing={3} className={classes.parameters}>
              {Object.entries(values).map(([k, v], index) => {
                if (!isNotNull(v)) return null
                return (
                  <Grid item xs={6} key={index}>
                    {isSelectors.includes(k) ? (
                      <SelectMenu
                        onChangeValue={val => setFieldValue(k, val)}
                        items={options[k]}
                        value={v}
                        textFieldProps={{
                          variant: 'outlined',
                          label: k,
                          required: true
                        }}
                      />
                    ) : Array.isArray(v) ? (
                      <Collection
                        k={k}
                        items={v}
                        setFieldValue={val => setFieldValue(k, val)}
                      />
                    ) : (
                      <Input label={k} name={k} value={v} {...inputProps} />
                    )}
                  </Grid>
                )
              })}
            </Grid>
            <Typography className={classes.text}>AI models</Typography>

            <Grid container spacing={3} className={classes.parameters}>
              <SelectMenu
                value={AIModel}
                placement={'top'}
                CustomMenu={AIModelsMenu}
                asyncData={useAIModelsMenu}
                onChangeValue={handleSelectAIModel}
                textFieldProps={{
                  variant: 'outlined',
                  label: 'Name',
                  required: true
                }}
              />
            </Grid>

            {!isExperimentInput && (
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    checked={withMuteChannels}
                    onChange={handleToggleMuteChannels}
                    size="small"
                    color="primary"
                  />
                }
                label={
                  <Typography className={classes.checkboxLabel}>
                    Mute channels previously disabled on timeseries chart
                  </Typography>
                }
              />
            )}
            {selectedAlgorithm === SPIKESORTING_ALGORITHMS.KILOSORT && (
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    checked={withLessSpikes}
                    onChange={handleToggleLessSpikes}
                    size="small"
                    color="primary"
                  />
                }
                label={
                  <Typography className={classes.checkboxLabel}>
                    Automatically remove units with less than 100 spikes
                  </Typography>
                }
              />
            )}
          </CustomModal>
        )
      }}
    </Formik>
  )
}

const Input = memo(({ errors, handleBlur, handleChange, ...props }) => (
  <CustomInput
    fullWidth
    error={errors}
    onBlur={handleBlur}
    onChange={handleChange}
    {...props}
  />
))

export default NewSpikesortingModal
