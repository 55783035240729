import { useCallback, useState } from 'react'
import { getRecommendations } from '../../../../../../../endpoints/curation'
import { addRecommendations, toggleCurationAI } from '../../../redux/actions'
import { NOTIFICATION } from '../../../../../../../utils/consts'
import { useDispatch, useSelector } from 'react-redux'
import useNotification from '../../../../../../SnackbarManager/hooks/useNotification'

export default function useGetRecommendations() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const addNotification = useNotification()

  const { selectedSorting, selectedCuration } = useSelector(
    state => state.recordingStore
  )
  const { id: selectedSortingId } = selectedSorting || {}
  const { id: selectedCurationId } = selectedCuration || {}
  const ID = [selectedSortingId, selectedCurationId].join('-')

  const storeRecommendations = useCallback(
    data => dispatch(addRecommendations(data)),
    [dispatch]
  )

  const fetchRecommendations = useCallback(
    async props => {
      const { onSuccess = () => null } = props || {}
      try {
        setLoading(true)
        const data = await getRecommendations({
          id: selectedSortingId,
          curation_id: selectedCurationId
        })

        storeRecommendations([ID, data])
        setLoading(false)
        onSuccess && onSuccess()
      } catch (error) {
        addNotification({ type: NOTIFICATION.ERROR, title: error })
        setLoading(false)
      }
    },
    [ID, dispatch, addNotification, storeRecommendations]
  )

  return { loading, fetchRecommendations }
}
