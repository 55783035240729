import React from 'react'

export const svg128_8 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="60"
        height="341"
        viewBox="0 0 60 341"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M60 0H0V28L8.53846 93.5L11.5385 303.412L42.5 341L50.7692 303.412L53.7692 93.5L60 28V0Z"
          fill={color}
        />
        <path
          d="M12 144C12 142.343 13.3431 141 15 141H27C28.6569 141 30 142.343 30 144V155.077C30 156.734 28.6569 158.077 27 158.077H15C13.3431 158.077 12 156.734 12 155.077V144Z"
          fill="white"
        />
        <path
          d="M12 165.077C12 163.42 13.3431 162.077 15 162.077H27C28.6569 162.077 30 163.42 30 165.077V176.154C30 177.811 28.6569 179.154 27 179.154H15C13.3431 179.154 12 177.811 12 176.154V165.077Z"
          fill="white"
        />
        <path
          d="M12 186.154C12 184.497 13.3431 183.154 15 183.154H27C28.6569 183.154 30 184.497 30 186.154V197.231C30 198.888 28.6569 200.231 27 200.231H15C13.3431 200.231 12 198.888 12 197.231V186.154Z"
          fill="white"
        />
        <path
          d="M12 207.231C12 205.574 13.3431 204.231 15 204.231H27C28.6569 204.231 30 205.574 30 207.231V218.308C30 219.964 28.6569 221.308 27 221.308H15C13.3431 221.308 12 219.964 12 218.308V207.231Z"
          fill="white"
        />
        <path
          d="M12 228.308C12 226.651 13.3431 225.308 15 225.308H27C28.6569 225.308 30 226.651 30 228.308V239.385C30 241.041 28.6569 242.385 27 242.385H15C13.3431 242.385 12 241.041 12 239.385V228.308Z"
          fill="white"
        />
        <path
          d="M12 249.385C12 247.728 13.3431 246.385 15 246.385H27C28.6569 246.385 30 247.728 30 249.385V260.462C30 262.118 28.6569 263.462 27 263.462H15C13.3431 263.462 12 262.118 12 260.462V249.385Z"
          fill="white"
        />
        <path
          d="M12 270.462C12 268.805 13.3431 267.462 15 267.462H27C28.6569 267.462 30 268.805 30 270.462V281.538C30 283.195 28.6569 284.538 27 284.538H15C13.3431 284.538 12 283.195 12 281.538V270.462Z"
          fill="white"
        />
        <path
          d="M32 160C32 158.343 33.3431 157 35 157H47C48.6569 157 50 158.343 50 160V171.077C50 172.734 48.6569 174.077 47 174.077H35C33.3431 174.077 32 172.734 32 171.077V160Z"
          fill="white"
        />
        <path
          d="M32 181.077C32 179.42 33.3431 178.077 35 178.077H47C48.6569 178.077 50 179.42 50 181.077V192.154C50 193.811 48.6569 195.154 47 195.154H35C33.3431 195.154 32 193.811 32 192.154V181.077Z"
          fill="white"
        />
        <path
          d="M32 202.154C32 200.497 33.3431 199.154 35 199.154H47C48.6569 199.154 50 200.497 50 202.154V213.231C50 214.888 48.6569 216.231 47 216.231H35C33.3431 216.231 32 214.888 32 213.231V202.154Z"
          fill="white"
        />
        <path
          d="M32 223.231C32 221.574 33.3431 220.231 35 220.231H47C48.6569 220.231 50 221.574 50 223.231V234.308C50 235.964 48.6569 237.308 47 237.308H35C33.3431 237.308 32 235.964 32 234.308V223.231Z"
          fill="white"
        />
        <path
          d="M32 244.308C32 242.651 33.3431 241.308 35 241.308H47C48.6569 241.308 50 242.651 50 244.308V255.385C50 257.041 48.6569 258.385 47 258.385H35C33.3431 258.385 32 257.041 32 255.385V244.308Z"
          fill="white"
        />
        <path
          d="M32 265.385C32 263.728 33.3431 262.385 35 262.385H47C48.6569 262.385 50 263.728 50 265.385V276.462C50 278.118 48.6569 279.462 47 279.462H35C33.3431 279.462 32 278.118 32 276.462V265.385Z"
          fill="white"
        />
        <path
          d="M32 286.462C32 284.805 33.3431 283.462 35 283.462H47C48.6569 283.462 50 284.805 50 286.462V297.538C50 299.195 48.6569 300.538 47 300.538H35C33.3431 300.538 32 299.195 32 297.538V286.462Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="60"
      height="341"
      viewBox="0 0 60 341"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        d="M60 0H0V28L8.53846 93.5L11.5385 303.412L42.5 341L50.7692 303.412L53.7692 93.5L60 28V0Z"
        fill={color}
      />
    </svg>
  )
}
