import { useLocation } from 'react-router-dom'
import { useCallback, useMemo } from 'react'

function useQueryParams() {
  const location = useLocation()

  const query = useMemo(() => new URLSearchParams(location.search), [
    location.search
  ])

  const getByName = useCallback(name => query.get(name), [query])

  const setQueryParams = useCallback(props => {
    const q = new URLSearchParams()
    if (Array.isArray(props)) {
      props.forEach(({ key, value }) => value && q.set(key, value))
    } else {
      const { key, value } = props
      if (!value) return ''
      q.set(key, value)
    }
    const string = q.toString()
    return string ? `?${string}` : ''
  }, [])

  return { getByName, query, setQueryParams }
}

export default useQueryParams
