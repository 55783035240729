import { useCallback, useEffect, useState } from 'react'
import { useFetchResult } from '../../../../../hooks/useFetchResult'
import { foldersList } from '../../../../../endpoints/admin'

function useGetFolders(fetchOn) {
  const [folder_id, selectFolderId] = useState(null)
  const { state, fetchData } = useFetchResult(foldersList, { folder_id })
  const handleSetFolderId = useCallback(id => selectFolderId(id), [])

  useEffect(() => {
    fetchOn && fetchData()
    // eslint-disable-next-line
  }, [fetchOn, folder_id])

  return {
    state,
    folder_id,
    refetchFolders: fetchData,
    onSelect: handleSetFolderId
  }
}

export default useGetFolders
