import React from 'react'

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="201"
      height="200"
      fill="none"
      viewBox="0 0 201 200"
      {...props}
    >
      <mask
        id="mask0_5750_515356"
        style={{ maskType: 'alpha' }}
        width="167"
        height="168"
        x="17"
        y="16"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#000"
          fillRule="evenodd"
          d="M17.166 100c0-46 37.25-83.334 83.25-83.334C146.5 16.666 183.833 54 183.833 100s-37.333 83.333-83.417 83.333c-46 0-83.25-37.333-83.25-83.333zm54.167-33.334c-6.903 0-12.5 5.597-12.5 12.5 0 6.904 5.597 12.5 12.5 12.5 6.904 0 12.5-5.596 12.5-12.5 0-6.903-5.596-12.5-12.5-12.5zm58.333 0c-6.903 0-12.5 5.597-12.5 12.5 0 6.904 5.597 12.5 12.5 12.5 6.904 0 12.5-5.596 12.5-12.5 0-6.903-5.596-12.5-12.5-12.5zm-29.166 100c-36.834 0-66.667-29.833-66.667-66.666 0-36.834 29.834-66.667 66.667-66.667S167.166 63.167 167.166 100s-29.833 66.666-66.666 66.666zm-38.75-28.5c8.5-13.416 22.916-21.5 38.75-21.5 15.833 0 30.25 8 38.833 21.5 1.833 2.917.917 6.751-2 8.584-1.083.583-2.167.917-3.333.917-2.084 0-4.167-1-5.334-2.917-6.166-9.834-16.666-15.667-28.166-15.667s-22 5.833-28.167 15.667c-1.833 2.917-5.666 3.833-8.583 2-2.917-1.833-3.834-5.667-2-8.584z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_5750_515356)">
        <path fill="#858C94" d="M0.5 0H200.5V200H0.5z"></path>
      </g>
    </svg>
  )
}

export default Icon
