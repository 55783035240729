import React from 'react'

const Empty = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37 6H11C9.67429 6.00122 8.40323 6.52839 7.46581 7.46581C6.52839 8.40323 6.00122 9.67429 6 11V37C6.00122 38.3257 6.52839 39.5968 7.46581 40.5342C8.40323 41.4716 9.67429 41.9988 11 42H37C38.3257 41.9988 39.5968 41.4716 40.5342 40.5342C41.4716 39.5968 41.9988 38.3257 42 37V11C41.9988 9.67429 41.4716 8.40323 40.5342 7.46581C39.5968 6.52839 38.3257 6.00122 37 6ZM40 37C39.9991 37.7954 39.6828 38.5579 39.1204 39.1204C38.5579 39.6828 37.7954 39.9991 37 40H11C10.2046 39.9991 9.44206 39.6828 8.87964 39.1204C8.31722 38.5579 8.00087 37.7954 8 37V11C8.00087 10.2046 8.31722 9.44206 8.87964 8.87964C9.44206 8.31722 10.2046 8.00087 11 8H37C37.7954 8.00087 38.5579 8.31722 39.1204 8.87964C39.6828 9.44206 39.9991 10.2046 40 11V37Z"
        fill="#646464"
      />
      <path
        d="M31.7076 16.293C31.6148 16.2001 31.5045 16.1264 31.3832 16.0762C31.2619 16.0259 31.1319 16 31.0006 16C30.8693 16 30.7392 16.0259 30.6179 16.0762C30.4966 16.1264 30.3864 16.2001 30.2936 16.293L28.8806 17.7061C27.4905 16.6077 25.7723 16.007 24.0006 16C21.8795 16.0021 19.8459 16.8457 18.346 18.3455C16.8462 19.8453 16.0027 21.8789 16.0006 24C16.0075 25.7717 16.6082 27.49 17.7067 28.88L16.2936 30.293C16.1981 30.3853 16.1219 30.4956 16.0695 30.6176C16.0171 30.7396 15.9895 30.8709 15.9883 31.0036C15.9872 31.1364 16.0125 31.2681 16.0627 31.391C16.113 31.5139 16.1873 31.6255 16.2812 31.7194C16.3751 31.8133 16.4867 31.8876 16.6096 31.9379C16.7325 31.9881 16.8642 32.0134 16.997 32.0123C17.1298 32.0111 17.261 31.9835 17.383 31.9311C17.505 31.8787 17.6153 31.8025 17.7076 31.707L19.1206 30.2939C20.5106 31.3924 22.2289 31.9931 24.0006 32C26.1217 31.9979 28.1553 31.1544 29.6551 29.6546C31.155 28.1547 31.9985 26.1211 32.0006 24C31.9937 22.2283 31.3929 20.5101 30.2945 19.12L31.7076 17.707C31.8005 17.6142 31.8742 17.504 31.9244 17.3827C31.9747 17.2614 32.0006 17.1314 32.0006 17C32.0006 16.8687 31.9747 16.7387 31.9244 16.6174C31.8742 16.4961 31.8005 16.3858 31.7076 16.293ZM18.0006 24C17.9972 22.8986 18.2979 21.8176 18.8697 20.8763C19.4415 19.9349 20.2622 19.1697 21.2412 18.665C22.2201 18.1603 23.3195 17.9357 24.418 18.016C25.5165 18.0963 26.5715 18.4784 27.4667 19.12L19.1206 27.4661C18.3941 26.4562 18.0024 25.2441 18.0006 24ZM30.0006 24C30.004 25.1015 29.7032 26.1824 29.1314 27.1238C28.5596 28.0652 27.739 28.8304 26.76 29.3351C25.781 29.8398 24.6817 30.0644 23.5832 29.9841C22.4847 29.9038 21.4297 29.5217 20.5345 28.88L28.8806 20.5339C29.607 21.5438 29.9987 22.756 30.0006 24Z"
        fill="#646464"
      />
    </svg>
  )
}

export default Empty
