import React, { createContext, useContext, useState } from 'react'

const HoverCtx = createContext({
  element: null
})

export const HoverProvider = ({ children }) => {
  const [selected, setSelected] = useState(null)

  return (
    <HoverCtx.Provider
      value={{
        selected,
        setSelected
      }}
    >
      {children}
    </HoverCtx.Provider>
  )
}

export const useHover = () => useContext(HoverCtx)
