import React from 'react'
import { Edit, Done } from '@material-ui/icons'
import { mapLabelToWidgetComponent } from '../utils'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import CustomDropdown from '../../../../CustomDropdown'
import { WAVEFORM_VIEWS_ENUM } from './WaveFormView/consts'
import ApproveComponent from './ClusterView/components/ApproveComponent'
import useIsAuthor from '../../../../../common/hooks/useIsAuthor'

const useStyles = makeStyles(theme => ({
  done: {
    color: theme.palette.secondary[100]
  },
  dropdown: {
    height: 20,
    display: 'inline-flex'
  }
}))

const Wrapper = ({ children, id }) => {
  const { onEdit } = mapLabelToWidgetComponent.get(id) || {}
  const { toggle } = (onEdit && onEdit()) || {}

  return children
    ? React.cloneElement(children, {
        onClick: toggle
      })
    : null
}

const wfLoadingMEssage =
  'Waveform is loading, wait until it would be ready to switch views'

const SwitchWFView = () => {
  const classes = useStyles()
  const { onSwitch } = mapLabelToWidgetComponent.get('waveform_view') || {}
  const f = (onSwitch && onSwitch()) || {}
  const { view: waveformsView } = useSelector(
    state => state.widgetsCache.waveforms
  )

  const isRawView = waveformsView === WAVEFORM_VIEWS_ENUM.OVERLAP

  const viewTypeOptions = [
    {
      label: 'Raw waveforms',
      endAdornment: isRawView ? <Done className={classes.done} /> : null,
      onClick: () => (!isRawView ? f() : null)
    },
    {
      label: 'Mean +/- Std',
      endAdornment: !isRawView ? <Done className={classes.done} /> : null,
      onClick: () => (isRawView ? f() : null)
    }
  ]

  return (
    <CustomDropdown
      className={classes.dropdown}
      label="Type"
      items={viewTypeOptions}
      width={230}
    />
  )
}

const SwitchChannelsView = () => {
  const classes = useStyles()
  const { toggleGeometry, toggleOverlapping } =
    mapLabelToWidgetComponent.get('waveform_view') || {}
  const f = (toggleGeometry && toggleGeometry()) || {}
  const overalapFn = (toggleOverlapping && toggleOverlapping()) || {}
  const { geometry: waveformGeometry, overlap: waveformOverlapping } =
    useSelector(state => state.widgetsCache.waveforms)

  const channelPositionOptions = [
    {
      label: 'Linear column view',
      endAdornment: !waveformGeometry ? (
        <Done className={classes.done} />
      ) : null,
      onClick: () => (waveformGeometry ? f() : null)
    },
    {
      label: 'Linear overlap view',
      disabled: waveformGeometry,
      endAdornment: waveformOverlapping ? (
        <Done className={classes.done} />
      ) : null,
      onClick: overalapFn
    },
    {
      label: 'True coordinates view',
      endAdornment: waveformGeometry ? <Done className={classes.done} /> : null,
      onClick: () => (!waveformGeometry ? f() : null)
    }
  ]

  return (
    <CustomDropdown
      className={classes.dropdown}
      label="Ch. Position"
      items={channelPositionOptions}
      width={184}
    />
  )
}

const ApproveComponentWrapper = () => {
  const { id, approved, uncurated_units } =
    useSelector(state => state.recordingStore.selectedCuration) || {}

  const { isAuthor } = useIsAuthor()

  return (
    <ApproveComponent
      showNotification
      isAuthor={isAuthor}
      uncuratedUnits={uncurated_units}
      isApproved={approved}
      curationId={id}
    />
  )
}

// const ToggleOverlappingWF = ({ children }) => {
//   const { toggleOverlapping } =
//     mapLabelToWidgetComponent.get('waveform_view') || {}
//   const f = (toggleOverlapping && toggleOverlapping()) || {}
//   const {
//     loading,
//     overlap,
//     geometry: waveformGeometry
//   } = useSelector(state => state.widgetsCache.waveforms)
//
//   if (waveformGeometry) return null
//
//   return React.cloneElement(
//     <Tooltip
//       title={
//         loading
//           ? wfLoadingMEssage
//           : `${overlap ? 'Disable' : 'Enable'} overlapping view`
//       }
//       arrow
//       placement="top"
//     >
//       {children}
//     </Tooltip>,
//     {
//       onClick: () => {
//         if (!loading) f()
//       }
//     }
//   )
// }

export const actions = {
  data: [
    {
      id: 'data-edit',
      wrapper: ({ children }) => <Wrapper id="data">{children}</Wrapper>,
      children: Edit
    }
  ],
  publications: [
    {
      id: 'publications-edit',
      wrapper: ({ children }) => (
        <Wrapper id="publications">{children}</Wrapper>
      ),
      children: Edit
    }
  ],
  tags: [
    {
      id: 'tags-edit',
      wrapper: ({ children }) => <Wrapper id="tags">{children}</Wrapper>,
      children: Edit
    }
  ],
  waveform_view: [
    {
      id: 'toggle-waveform-geometry',
      wrapper: SwitchChannelsView
    },
    {
      id: 'switch-waveform-view',
      wrapper: SwitchWFView
    }
  ],
  cluster_view: [
    {
      id: 'cluster-view-approve-component',
      wrapper: ApproveComponentWrapper
    }
  ]
}
