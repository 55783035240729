import React, { memo } from 'react'
import Button from '@material-ui/core/Button'
import useApproveCuration from '../ApproveComponent/hooks/useApproveCuration'
import ApproveConfirmationModal from '../ApproveConfirmationModal'
import { Tooltip } from '@material-ui/core'

const ApproveCurationButton = ({ classes, curationId, uncuratedUnits }) => {
  const {
    loading,
    onApprove,
    isApproveModalOpened,
    toggleApproveCurationModal
  } = useApproveCuration({ curationId })

  return (
    <>
      <Tooltip title={uncuratedUnits ? 'UNC must be 0' : ''}>
        <Button
          className={classes.buttonRoot}
          disabled={uncuratedUnits}
          onClick={toggleApproveCurationModal}
        >
          Approve Curation
        </Button>
      </Tooltip>
      <ApproveConfirmationModal
        loading={loading}
        onApprove={onApprove}
        toggleModal={toggleApproveCurationModal}
        isOpened={isApproveModalOpened}
        curationId={curationId}
      />
    </>
  )
}

export default memo(ApproveCurationButton)
