import { useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import isEmpty from 'lodash/fp/isEmpty'
import { getNNDTsInfo } from '../../../../../../endpoints/admin'
import { to } from '../../../../../../utils/utils'
import {
  addNNDData,
  toggleNNDVisibility,
  toggleNNDCHannelVisibility,
  setNNDTimeseriesInfo
} from '../../../redux/actions'
import { NOTIFICATION } from '../../../../../../utils/consts'
import useNotification from '../../../../../SnackbarManager/hooks/useNotification'

function useNNDController() {
  const dispatch = useDispatch()
  const addNotification = useNotification()
  const { nnd, timeseriesInfo, isAllNNDVisible, nndTimeseriesInfo } =
    useSelector(state => state.timeSeriesLeftPanel, shallowEqual)

  const nndChannels = Array.from(nnd.values())

  const showAllNND = useCallback(() => {
    dispatch(toggleNNDVisibility())
  }, [dispatch])

  const toggleNNDChannel = channel => {
    dispatch(toggleNNDCHannelVisibility(channel))
  }

  const fetchNND = useCallback(
    async props => {
      const { data = timeseriesInfo } = props || {}
      if (!isEmpty(data?.nnd_data)) {
        const nndInfo = new Map()
        await Promise.all(
          Object.keys(data.nnd_data).map(async data_id => {
            const { data } = await to(getNNDTsInfo, { record_id: data_id })
            if (data) {
              nndInfo.set(data_id, data)
            }
          })
        )

        if (nndInfo.size !== 0) {
          dispatch(setNNDTimeseriesInfo(nndInfo))
          dispatch(addNNDData(data?.nnd_data))
        } else {
          addNotification({
            type: NOTIFICATION.ERROR,
            title: 'Failed to read non neuronal channels!'
          })
        }
      }
    },
    [dispatch, timeseriesInfo]
  )

  return {
    nndChannels,
    fetchNND,
    nndTimeseriesInfo,
    isAllNNDVisible,
    showAllNND,
    toggleNNDChannel
  }
}

export default useNNDController
