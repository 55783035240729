export const initialColumns = [
  {
    id: 'name',
    label: 'File Name',
    active: true
  },
  {
    id: 'rights',
    active: false,
    label: 'Right and Permissions'
  },
  { id: 'author', active: true, label: 'Author' },
  {
    id: 'isolated_units',
    active: true,
    label: 'Isolated Units'
  },
  {
    id: 'status',
    active: true,
    label: 'Status'
  },
  {
    id: 'corresponding_author_id',
    active: false,
    label: 'Corresponding Author'
  },
  { id: 'pub_ref_id', active: false, label: 'Publications Ref' },
  {
    id: 'tags',
    active: true,
    label: 'Tags'
  },
  { id: 'species_id', active: false, label: 'Specie' },
  {
    id: 'brain_region_id',
    active: false,
    label: 'Brain Region'
  },
  // { id: 'author', active: false, label: 'Progress' },
  { id: 'sub_region_id', active: false, label: 'Sub Region' },
  { id: 'aim_data_id', active: false, label: 'Aim of Data' },
  { id: 'file_size', active: false, label: 'File Size' },
  {
    id: 'duration',
    active: true,
    label: 'Duration (sec.)'
  },
  {
    id: 'prb_manuf_id',
    active: false,
    label: 'Probe Manufacture'
  },
  {
    id: 'prb_manuf_version_id',
    active: false,
    label: 'Probe version'
  },
  { id: 'sample_rate', active: false, label: 'Sample Rate' },
  {
    id: 'number_channels',
    active: false,
    label: 'Number of Channels'
  },
  { id: 'neurons_id', active: false, label: 'Neurons' },
  {
    id: 'upload_date',
    label: 'Upload Date',
    active: true
  }
]
