import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  EVENT_FILE_TYPE,
  MODALS_ID,
  NOTIFICATION
} from '../../../../../utils/consts'
import useEventsController from '../../../../TimeseriesView/NewLeftPanel/components/EventsTab/hooks/useEventsController'
import useToggleModal from '../../../../../hooks/useToggleModal'
import { useSelector, useDispatch } from 'react-redux'
import {
  completeMultipartUpload,
  createEventFile,
  createUploadPart,
  getPresignedUrl,
  validateNNDFile
} from '../../../../../endpoints/admin'
import {
  baseConfig,
  calcPartSize,
  handleRemoteRequestWithPolling,
  MultiPartUpload
} from '../utils'
import { parseFileSize } from '../../../../../utils/utils'
import { getOr } from 'lodash/fp'
import { refreshTimeseries } from '../../../../TimeseriesView/NewLeftPanel/redux/actions'

function useEventsFileUpload() {
  const dispatch = useDispatch()
  const addNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [eventFiles, setEventFiles] = useState([])
  const [multipartFiles, setMultipartFiles] = useState([])
  // progress of multipart file upload
  const [uploadProgress, setProgress] = useState(0)

  const [loadingPresignedUr, setLoadingPresignedUr] = useState(false)
  const [view, setView] = useState(EVENT_FILE_TYPE.ANALOGIC)
  const initialParams = {
    number_channels: null,
    sample_rate: null,
    dtype: 'int16'
  }
  const [params, setParams] = useState(initialParams)
  const isMultipart = view !== EVENT_FILE_TYPE.TIMESTAMP

  const { fetchEvents: refetchEvents } = useEventsController()
  const { toggle: toggleModal, isOpened } = useToggleModal(
    MODALS_ID.EVENTS_FILE_UPLOAD_MODAL
  )
  const recording = useSelector(state => state.recordingStore.recording)
  const isUpload = !Boolean(eventFiles?.length || multipartFiles?.length)
  const _isUploading = useRef()

  useEffect(() => {
    return () => {
      if (!_isUploading.current) {
        setEventFiles([])
        setMultipartFiles([])
        setParams(initialParams)
      }
    }
  }, [isOpened])

  useEffect(() => {
    const handleLeavePage = e => {
      e.returnValue = ''
      return ''
    }

    if (_isUploading.current)
      window.addEventListener('beforeunload', handleLeavePage)
    else window.removeEventListener('beforeunload', handleLeavePage)

    return () => {
      window.removeEventListener('beforeunload', handleLeavePage)
    }
  }, [_isUploading.current])

  const handleChange = useCallback(event => {
    const { value, name } = event?.target || event
    if (value) setParams(p => ({ ...p, [name]: value }))
  }, [])

  const handleChangeView = useCallback(event => {
    const { value } = event.target
    if (value) setView(value)
  }, [])

  const views = [
    {
      value: EVENT_FILE_TYPE.DIGITAL,
      label: 'Digital'
    },
    {
      value: EVENT_FILE_TYPE.ANALOGIC,
      label: 'Analogic'
    },
    {
      value: EVENT_FILE_TYPE.TIMESTAMP,
      label: 'Timestamp'
    }
  ]

  const validateNNDFileReq = useCallback(
    async ({ file_size }) => {
      try {
        const { number_channels, sample_rate, dtype } = params || {}
        const res = await validateNNDFile({
          record_id: recording?.id,
          file_size,
          dtype,
          number_channels: Number(number_channels),
          sample_rate: Number(sample_rate)
        })
        if (!res?.valide) {
          addNotification({
            type: NOTIFICATION.ERROR,
            title:
              "File didn't pass validation. Please check if you inserted correct number of channels, sample rate and dtype."
          })
        }

        return { success: Boolean(res.valide) }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
      return { success: false }
    },
    [addNotification, params, recording?.id]
  )

  const handleMultipartFile = useCallback(
    async ({ file, key, id }) => {
      const { size, name } = file || {}
      const fileRead = await file.arrayBuffer()
      setProgress(0)
      setLoadingPresignedUr(true)

      try {
        let chunks = []

        const { success } = await validateNNDFileReq({ file_size: size })

        if (!success) return setLoadingPresignedUr(false)

        const multipartData = await getPresignedUrl({
          key,
          multi_part: true
        })
        const { UploadId } = multipartData || {}

        if (!UploadId) return setLoadingPresignedUr(false)

        const partSize = calcPartSize(size, 10000)
        const allPartsNumber = Math.ceil(size / partSize, partSize)

        const uploadInstance = new MultiPartUpload(
          {
            fileKey: key,
            fileSize: size
          },
          baseConfig({ allPartsNumber })
        )

        uploadInstance.uploadId = UploadId

        for (let i = 0; i < allPartsNumber; i++) {
          let offset = i * partSize
          const part = fileRead.slice(offset, offset + partSize)
          chunks.push({
            part,
            bytesRead: offset + part.byteLength
          })
        }

        const newItem = {
          id,
          file,
          key,
          size: parseFileSize(size),
          fullSize: size,
          name,
          chunks,
          uploadInstance
        }

        // for now only one file is allowed
        setMultipartFiles([newItem])
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
      setLoadingPresignedUr(false)
    },
    [addNotification, validateNNDFileReq]
  )

  const handleValidateUploadFields = useCallback(() => {
    const reqFields = Object.entries(params)
      .map(([k, v]) => (!v ? k : null))
      .filter(Boolean)

    if (reqFields.length > 0) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: `There is some missing info (${reqFields.join(
          ', '
        )}), please provide it.`
      })
      return { err: true }
    }

    return { err: false }
  }, [addNotification, params])

  const handleUploadFile = useCallback(
    async (files, e) => {
      const file = files[0]

      const acceptedFormat = isMultipart ? ['dat'] : ['evt']
      const { size, type, name } = file || {}
      const id = Math.floor(Math.random() * 10000000)
      const key = `nnd/${id}/${name}`

      const isValidFormat = acceptedFormat.some(
        item => type?.includes(item) || name?.includes(item)
      )

      if (!isValidFormat) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: 'Invalid file type'
        })
        return
      }

      if (isMultipart) {
        const { err } = handleValidateUploadFields()
        if (!err) handleMultipartFile({ file, key, id })
        // reset dnd zone
        return (e.target.value = null)
      }

      setLoadingPresignedUr(true)
      try {
        const presignedData = await getPresignedUrl({
          key
        })

        if (!presignedData) return

        const newItem = {
          id,
          file,
          key,
          size: parseFileSize(size),
          presignedData
        }

        setEventFiles(f => [...f, newItem])
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }

      setLoadingPresignedUr(false)
    },
    [
      addNotification,
      handleMultipartFile,
      handleValidateUploadFields,
      isMultipart
    ]
  )

  const uploadFileToS3 = useCallback((presignedData, file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      let exceptionFields = ['Content-Type']
      const URL = presignedData?.url || presignedData
      if (presignedData?.fields) {
        Object.keys(presignedData.fields).forEach(key => {
          if (exceptionFields.includes(key)) return
          formData.append(key, presignedData.fields[key])
        })
      }
      formData.append('file', file, file?.name)

      const xhr = new XMLHttpRequest()
      xhr.upload.addEventListener('progress', function (e) {
        // fired every time a progress event happens
        if (!e.lengthComputable) {
          return
        }

        // eslint-disable-next-line no-unused-vars
        const progress = (e.loaded / e.total) * 100
      })
      xhr.open('POST', URL, true)
      xhr.send(formData)
      xhr.onload = function () {
        this.status === 204
          ? resolve({
              success: true,
              ETag: this.getResponseHeader('ETag').replaceAll('"', '')
            })
          : reject(this.responseText)
      }
      xhr.onerror = function () {
        reject(this.responseText)
      }
    })
  }, [])

  const handleNNDCreate = useCallback(
    async ({ fields, name, onSuccess = () => null }) => {
      try {
        await handleRemoteRequestWithPolling(createEventFile, {
          body: fields,
          onSuccess: () => {
            addNotification({
              type: NOTIFICATION.SUCCESS,
              title: `${name}: Successfully uploaded`
            })
            toggleModal()
            onSuccess()
          }
        })
      } catch (e) {
        addNotification({
          type: NOTIFICATION.SUCCESS,
          title: e
        })
      }
    },
    [addNotification, toggleModal]
  )

  const handleCompleteMultipartUpload = useCallback(
    async ({ fileKey, name, uploadInstance, fullSize }) => {
      try {
        const { number_channels, sample_rate, dtype } = params || {}
        const completeData = await completeMultipartUpload({
          key: fileKey,
          upload_id: uploadInstance.uploadId,
          part_info: uploadInstance.partsInfo
        })

        if (completeData?.status === 200) {
          // REQ TO CREATE NND FILE
          setProgress(100)
          _isUploading.current = false
          handleNNDCreate({
            fields: {
              dtype,
              key: fileKey,
              file_size: fullSize,
              record_id: recording?.id,
              sample_rate: Number(sample_rate),
              number_channels: Number(number_channels)
            },
            name,
            onSuccess: () => {
              dispatch(refreshTimeseries())
            }
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, dispatch, handleNNDCreate, params, recording?.id]
  )

  const createMultipartUpload = useCallback(
    async f => {
      try {
        _isUploading.current = true
        const { chunks, key: fileKey, uploadInstance, fullSize, name } = f || {}
        setMultipartFiles(f => {
          const oldVal = f[0]
          return [
            {
              ...oldVal,
              _isUploading: true
            }
          ]
        })
        addNotification({
          type: NOTIFICATION.ERROR,
          title:
            "Please don't refresh the page or close the app while upload is in progress!"
        })
        for (const chunk of chunks) {
          if (!_isUploading.current) break

          const onError = err => {
            _isUploading.current = false
            addNotification({
              type: NOTIFICATION.ERROR,
              title: err || 'Failed to upload file, please try again later !'
            })
          }

          const { part, bytesRead } = chunk
          const partNumber = uploadInstance.nextPart()
          await handleRemoteRequestWithPolling(getPresignedUrl, {
            body: {
              key: fileKey,
              multi_part: true,
              part: partNumber,
              upload_id: uploadInstance.uploadId
            },
            onError,
            onSuccess: async presignedData => {
              await handleRemoteRequestWithPolling(createUploadPart, {
                body: part,
                url: presignedData,
                onError,
                onSuccess: async data => {
                  const res = uploadInstance.completeUploadPartRequest({
                    ETag: data.headers.get('ETag')
                  })

                  if (res.done)
                    await handleCompleteMultipartUpload({
                      uploadInstance,
                      fileKey,
                      name,
                      fullSize
                    })
                  else setProgress((bytesRead * 100) / fullSize)
                }
              })
            }
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, handleCompleteMultipartUpload]
  )

  const createSimpleUpload = useCallback(
    async f => {
      try {
        const name = getOr(null, ['file', 'name'], f)
        const { file, presignedData, key } = f
        const res = await uploadFileToS3(presignedData, file)
        const createRes = await createEventFile({
          record_id: recording?.id,
          key
        })
        if (res.success && createRes.status === 200) {
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: `${name}: Successfully uploaded`
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
      return true
    },
    [addNotification, recording?.id, uploadFileToS3]
  )

  const runTask = useCallback(
    async f => {
      if (isMultipart) return createMultipartUpload(f)
      else return createSimpleUpload(f)
    },
    [isMultipart, createMultipartUpload, createSimpleUpload]
  )

  const handleSubmit = useCallback(async () => {
    let completed = 0

    if (isMultipart) {
      if (multipartFiles?.length === 0) return
      runTask(multipartFiles[0])
      return
    }

    // upload event file
    if (eventFiles?.length === 0) return
    setLoading(true)

    for (let f of eventFiles) {
      const res = await runTask(f)
      if (res) completed++
    }
    if (completed === eventFiles.length) {
      // we should refetch events if was added new files
      refetchEvents()
      setLoading(false)
      toggleModal()
    }
  }, [
    isMultipart,
    eventFiles,
    multipartFiles,
    runTask,
    refetchEvents,
    toggleModal
  ])

  const handleDelete = useCallback(
    id => {
      if (isMultipart) {
        _isUploading.current = false
        setMultipartFiles([])
        return
      }
      setEventFiles(f => f.filter(i => i.id !== id))
    },
    [isMultipart]
  )

  return {
    isUpload,
    loading,
    toggleModal,
    isOpened,
    onUpload: handleUploadFile,
    onSubmit: handleSubmit,
    onDelete: handleDelete,
    isMultipart,
    eventFiles,
    multipartFiles,
    loadingPresignedUr,
    uploadProgress,

    view,
    params,
    handleChangeView,
    handleChange,
    views
  }
}

export default useEventsFileUpload
