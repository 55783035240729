import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import RecordingHeader from './components/RecordingHeader'
import RecordingBody from './components/RecordingBody/RecordingBody'
import { makeStyles } from '@material-ui/core/styles'
import RecordingInfoPanel from './components/RecordingInfoPanel'
import useRecordingView from './hooks/useRecordingView'
import EmptyPage from '../EmptyPage'
import FullScreenLoader from '../FullScreenLoader'
import LogsModal from './components/LogsView/LogsModal'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  container: theme.container({
    display: 'flex',
    background: theme.palette.colors.backgrounds.main,
    ...theme.scrollbar(2)
  }),
  infoPannel: {
    borderTop: `1px solid ${theme.palette.colors.backgrounds.main}`,
    background: theme.palette.colors.backgrounds.pannel
  },
  body: {
    padding: '15px 40px 0',
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column'
  }
}))

const NewRecordingView = props => {
  const { recording, selectedSorting } = props
  const { loading } = useRecordingView()
  const classes = useStyles()

  if (loading) {
    return <FullScreenLoader />
  }

  if (!loading && !recording) {
    return <EmptyPage />
  }

  return (
    <Grid container>
      <Grid item sm={3} className={cn(classes.container, classes.infoPannel)}>
        <RecordingInfoPanel recordingId={recording?.id} />
      </Grid>
      <Grid item sm={9} className={classes.container}>
        <div className={classes.body}>
          <RecordingHeader
            fileName={recording?.name}
            folderId={recording?.folder_id}
            selectedSorting={selectedSorting}
          />
          <RecordingBody />
        </div>
      </Grid>
      <LogsModal />
    </Grid>
  )
}

const mapStateToProps = state => ({
  recording: state.recordingStore.recording,
  selectedSorting: state.recordingStore.selectedSorting
})

export default connect(mapStateToProps)(NewRecordingView)
