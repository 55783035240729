import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { CircularProgress, Typography } from '@material-ui/core'
import cn from 'classnames'

const maxHeight = 225

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 2
  },
  paper: () => ({
    padding: 15,
    boxSizing: 'border-box',
    maxHeight,
    overflowY: 'auto',
    display: 'flex'
  }),
  circularProgress: {
    margin: '0 auto'
  },
  list: {
    width: '100%'
  },
  listItem: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: 8,

    '& p': {
      display: 'block',
      fontSize: 13,
      lineHeight: '20px',
      '&:first-of-type': {
        fontWeight: 600
      }
    }
  }
}))

const PopperMenu = ({
  anchorEl,
  items,
  selectValue,
  handleClose,
  loading,
  error,
  CustomMenu,
  customClasses = {},
  customWidth = null,
  placement = 'bottom-start'
}) => {
  const classes = useStyles()

  const handleSelect = useCallback(
    value => {
      selectValue(value)
      handleClose()
    },
    [handleClose, selectValue]
  )

  const Content = useMemo(
    () => () => {
      if (loading) {
        return (
          <CircularProgress size={24} className={classes.circularProgress} />
        )
      } else if (error) {
        return <Typography>Something went wrong.Please try again</Typography>
      }
      const ContentBody = ({ item }) => {
        if (typeof item?.label === 'string') {
          return (
            <React.Fragment>
              <Typography>{item?.label}</Typography>
              {item?.subLabel && <Typography>{item.subLabel}</Typography>}
            </React.Fragment>
          )
        }
        return item?.label
      }
      return (
        <MenuList className={cn(classes.list, customClasses?.listRoot)}>
          {items.map((item, i) => (
            <MenuItem
              key={i}
              onClick={() => handleSelect(item.value)}
              className={cn(classes.listItem, customClasses?.listItem)}
              disabled={item?.disabled}
            >
              <ContentBody item={item} />
            </MenuItem>
          ))}
        </MenuList>
      )
    },
    [
      loading,
      error,
      classes.list,
      classes.circularProgress,
      classes.listItem,
      items,
      selectValue,
      handleClose,
      customClasses
    ]
  )

  const { width } = anchorEl?.getBoundingClientRect
    ? anchorEl?.getBoundingClientRect()
    : {}

  return (
    <Popper
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      role={undefined}
      placement={placement}
      style={{
        width: customWidth ?? width
      }}
      transition
      className={classes.root}
      disablePortal
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={cn(classes.paper, customClasses?.paper)}>
            {CustomMenu ? (
              <CustomMenu
                handleSelect={handleSelect}
                loading={loading}
                error={error}
                items={items}
              />
            ) : (
              <Content />
            )}
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default PopperMenu
