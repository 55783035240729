export const WIDGETS = [
  {
    id: 'cluster_view',
    label: 'Cluster View',
    endAdornment: null
  },
  {
    disabled: false,
    id: 'correlogram_view',
    label: 'Correlogram View',
    endAdornment: null
  },
  {
    id: 'data',
    label: 'Data',
    endAdornment: null
  },
  {
    disabled: false,
    id: 'feature_view',
    label: 'Feature View',
    endAdornment: null
  },
  {
    id: 'publications',
    label: 'Publications',
    endAdornment: null
  },
  {
    id: 'similarity_view',
    label: 'Similarity View',
    endAdornment: null
  },
  {
    disabled: true,
    id: 'summary_plots',
    label: 'Summary Plots',
    endAdornment: null
  },
  {
    id: 'tags',
    label: 'Tags',
    endAdornment: null
  },
  {
    disabled: false,
    id: 'waveform_view',
    label: 'WaveForm View',
    endAdornment: null
  }
]
