export const initialColumns = [
  {
    id: 'curation_id',
    label: 'Curation ID',
    active: true
  },
  {
    id: 'name',
    label: 'Datasets',
    active: true
  },
  {
    id: 'spikesorting_name',
    label: 'Spikesorting',
    active: true
  },
  {
    id: 'author',
    active: true,
    label: 'Author'
  },
  {
    id: 'approvalStatus',
    active: true,
    label: 'Status'
  },
  {
    id: 'duration',
    active: true,
    label: 'Duration (sec.)'
  },
  {
    id: 'tags',
    active: true,
    label: 'Tags'
  },
  {
    id: 'isolated_units',
    active: true,
    label: 'Isolated Units'
  },
  {
    id: 'upload_date',
    label: 'Upload Date',
    active: true
  }
]
