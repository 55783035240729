import { useCallback, useEffect, useState } from 'react'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { useDispatch } from 'react-redux'
import { NOTIFICATION, QPARAMS } from '../../../utils/consts'
import { setEditExoInput } from '../../../actions/experimentsInput'
import useToggleModal from '../../../hooks/useToggleModal'
import useFilesQueryParams from '../../FilesTable/hooks/useFilesQueryParams'
import useSearchQuery from '../../../hooks/useSearchQuery'

export function useFetchExpInputs({
  fetchOnRender = true,
  query = () => null,
  storeAction = () => null,
  params = {}
}) {
  const [loading, setLoading] = useState(true)

  const { page } = useFilesQueryParams()
  const dispatch = useDispatch()
  const store = useCallback(
    data => dispatch(storeAction(data)),
    [dispatch, storeAction]
  )

  const fetchInputs = useCallback(async () => {
    try {
      setLoading(true)
      const { data: D } = await query({
        method: 'GET',
        params: { per_page: 10, page, ...params }
      })
      if (D?.data) store(D)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }, [params, query, store, page])

  useEffect(() => {
    if (fetchOnRender) fetchInputs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnRender, page])

  return { fetchInputs, loading }
}

function useExpInputsActions({
  query = () => null,
  params = {},
  storeAction = () => null,
  modalId = null,
  storedItems = null
}) {
  const [loadingActions, setLoading] = useState(false)

  const { fetchInputs, loading: loadingFetch } = useFetchExpInputs({
    query,
    params,
    storeAction
  })

  const loading = loadingFetch || loadingActions

  const { insertSearchQuery } = useSearchQuery()
  const { page } = useFilesQueryParams()
  const { toggle: toggleModal } = useToggleModal(modalId)
  const addNotification = useNotification()
  const dispatch = useDispatch()

  const setForEdit = useCallback(
    data => dispatch(setEditExoInput(data)),
    [dispatch]
  )

  const handleEdit = item => {
    setForEdit(item)
    toggleModal()
  }

  const resetPage = useCallback(() => {
    if (page > 1 && storedItems?.length === 0) {
      insertSearchQuery({
        key: QPARAMS.PAGE,
        value: page - 1
      })
    }
  }, [page, storedItems])

  useEffect(() => {
    resetPage()
  }, [resetPage])

  const handleDelete = useCallback(
    async id => {
      try {
        setLoading(true)
        const { status } = await query({
          method: 'DELETE',
          data: { id }
        })
        if (status) {
          fetchInputs()
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Successfully deleted!'
          })
        }
        setLoading(false)
      } catch (err) {
        setLoading(false)
        addNotification({
          type: NOTIFICATION.ERROR,
          title: 'Impossible to delete!',
          message: err
        })
      }
    },
    [addNotification, page, storedItems, fetchInputs]
  )

  return { loading, fetchInputs, handleDelete, handleEdit }
}

export default useExpInputsActions
