import React, { memo } from 'react'
import { List, ListItem, makeStyles, Typography } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import MapData from '../../utils/MapData'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  tabs: {
    padding: 0,
    display: 'inline-flex',
    borderBottom: `1px solid ${theme.palette.colors.neutral[7]}`
  },
  link: {
    padding: 0,
    textTransform: 'none',
    background: 'fixed'
  },
  listItem: {
    padding: '8px 16px',
    '& span': {
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: 3,
      left: 0,
      bottom: 0,
      margin: 0,
      borderRadius: 3,
      opacity: 0,
      backgroundColor: theme.palette.primary.main,
      transform: 'translateY(4px)'
    },
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[4],
      'white'
    )
  },
  linkActive: {
    color: theme.palette.primary.main,
    '& span': {
      opacity: 1,
      transform: 'translateX(0)'
    }
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px'
  }
}))

const PageTabs = ({ tabs }) => {
  const classes = useStyles()
  return (
    <List className={classes.tabs}>
      <MapData data={tabs}>
        {({ item, index }) => {
          return <TabItem key={index} {...item} />
        }}
      </MapData>
    </List>
  )
}

const TabItem = ({ to, label }) => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const match = location.pathname === to

  const Item = memo(() => (
    <ListItem
      button
      disableRipple
      className={cn(classes.listItem, {
        [classes.linkActive]: match
      })}
    >
      <Typography className={classes.label}>{label}</Typography>
      <span />
    </ListItem>
  ))

  return (
    <Button
      disableRipple
      className={classes.link}
      onClick={() => history.push(to)}
    >
      <Item />
    </Button>
  )
}

export default memo(PageTabs)
