import React, { memo, useEffect } from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { IconButton, Typography } from '@material-ui/core'
import { TextRotationNone } from '@material-ui/icons'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DecorateIcon from '../../shared/components/DecorateIcon'
import { toggleFullScreenMode } from '../TimeseriesView/NewLeftPanel/redux/actions'
import MapData from '../../utils/MapData/MapData'
import { TOOLBAR_HEIGHT } from '../../utils/styles/CONSTANTS'
import { QPARAMS, ROUTES } from '../../utils/consts'
import useQueryParams from '../../hooks/useQueryParams'
import Storage from '../AppHeader/components/Storage'

import Breadcrumb from '../Breadcrumb'
import { getOr } from 'lodash/fp'
import useStorageKeys from '../../hooks/useStorageKeys'
import CustomDropdown from '../CustomDropdown'
import DataManipulationModal from './components/DataManipulationModal'
import SuccessModal from '../FilesTable/components/SuccessModal'

import useTimeWidgetToolBar from './hooks/useTimeWidgetToolBar'
import EventsFileUploadModal from './components/EventsFileUploadModal'
import CustomTooltip from '../CustomTooltip'
import { propsAreEqual } from '../../utils/utils'

const useStyles = makeStyles(theme => ({
  root: {
    height: TOOLBAR_HEIGHT,
    padding: '10px 15px 10px 24px'
  },
  actionBar: {
    width: 45,
    height: '100%',
    padding: '10px 0 60px',
    borderBottom: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline'
  },
  toolbar: ({ fullScreenMode }) => ({
    marginTop: 8,
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    width: fullScreenMode ? '100%' : 'auto',
    flexDirection: fullScreenMode ? 'column' : 'row',
    justifyContent: fullScreenMode ? 'space-between' : 'flex-end'
  }),

  toolbarItem: ({ fullScreenMode }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    width: fullScreenMode ? '100%' : 'auto',
    flexDirection: fullScreenMode ? 'column' : 'row'
  }),
  toolbarActionEvents: ({ fullScreenMode }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    flexDirection: fullScreenMode ? 'column-reverse' : 'row'
  }),
  iconButtonRoot: ({ fullScreenMode }) => ({
    padding: 5,
    borderRadius: 0,
    margin: fullScreenMode ? '15px 0' : '0 15px',
    '& svg': {
      width: fullScreenMode ? 20 : 24,
      height: fullScreenMode ? 20 : 24,
      [theme.breakpoints.down(1300)]: {
        height: 20,
        width: 20
      }
    },
    [theme.breakpoints.down(1300)]: {
      margin: fullScreenMode ? '15px 0' : '0 7px'
    }
  }),
  activeIcon: {
    '& svg': {
      fill: theme.palette.colors.mainColor
    }
  },
  viewColWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  viewColCount: {
    position: 'absolute',
    fontSize: 13,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    right: -5,
    top: -3,
    width: 16,
    height: 16,
    background: theme.palette.colors.grey,
    color: theme.palette.colors.white,
    borderRadius: '50%',
    [theme.breakpoints.down(1300)]: {
      fontSize: 10,
      width: 13,
      height: 13
    }
  },
  viewColCountSelected: {
    background: theme.palette.colors.mainColor
  },
  disabledIcon: {
    fill: theme.palette.colors.grey_15
  },
  dropdown: ({ fullScreenMode }) => ({
    marginLeft: fullScreenMode ? 0 : 16,
    zIndex: 1001
  }),
  textRotationIcon: {
    transform: 'rotateY(180deg)'
  },
  tooltip: {
    borderRadius: 8,
    padding: '6px 12px 6px 12px',
    alignItems: 'center',
    border: '0px',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: '#2C3A4B',
    width: 'auto'
  },
  tooltipArrow: {
    fontSize: 9,
    '&::before': {
      backgroundColor: theme.palette.colors.white,
      border: `0px`,
      filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))'
    }
  }
}))

const TimeWidgetToolBar = ({
  onZoomIn,
  onViewColumns,
  onZoomOut,
  onShiftTimeLeft,
  onShiftTimeRight,
  selectedView,
  groups,
  fullScreenMode,
  leftPanelMode,
  removeChannelsOnClick,
  clearHighlightedChannels,
  customActions = [],
  toggleRasterPlot,
  hideRasterToggler,
  resetZoom,
  setDataManipulationMode
}) => {
  const classes = useStyles({
    fullScreenMode
  })
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(toggleFullScreenMode(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { buttons, viewActions, dataActions, eventActions, timeSeriesActions } =
    useTimeWidgetToolBar({
      classes,
      groups,
      onViewColumns,
      selectedView,
      fullScreenMode,
      onShiftTimeLeft,
      onShiftTimeRight,
      onZoomIn,
      onZoomOut,
      resetZoom,
      customActions,
      toggleRasterPlot,
      hideRasterToggler,
      setDataManipulationMode,
      clearHighlightedChannels,
      removeChannelsOnClick
    })

  const isChildWindow = useSelector(
    state => state.timeSeriesLeftPanel.isChildWindow
  )

  const actionsLabel = fullScreenMode ? (
    <DecorateIcon
      icon={TextRotationNone}
      className={classes.textRotationIcon}
    />
  ) : (
    'Actions'
  )

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
      className={cn(classes.root, {
        [classes.actionBar]: fullScreenMode
      })}
    >
      <LeftSide fullScreenMode={fullScreenMode} isChildWindow={isChildWindow} />

      <div className={classes.toolbar}>
        <div className={classes.toolbarItem}>
          <div className={classes.toolbarActionEvents}>
            <MapData data={buttons}>
              {({ item, index }) => (
                <ActionButton
                  item={item}
                  key={index}
                  fullScreenMode={fullScreenMode}
                />
              )}
            </MapData>

            {!isChildWindow && (
              <CustomDropdown
                id="events-dropdown"
                label="Events"
                width="277px"
                items={eventActions}
                className={classes.dropdown}
              />
            )}

            <CustomDropdown
              id="actions-dropdown"
              label={actionsLabel}
              items={dataActions}
              className={classes.dropdown}
            />
          </div>

          {fullScreenMode ? (
            <MapData data={timeSeriesActions}>
              {({ item, index }) => (
                <ActionButton
                  item={item}
                  key={index}
                  fullScreenMode={fullScreenMode}
                />
              )}
            </MapData>
          ) : (
            <CustomDropdown
              id="view-dropdown"
              label="View"
              items={viewActions}
              className={classes.dropdown}
            />
          )}
        </div>
      </div>

      <Modals />
    </Grid>
  )
}

const ActionButton = memo(
  ({
    item: { type, selected, title, onClick, icon, props = {} },
    fullScreenMode
  }) => {
    const classes = useStyles({
      fullScreenMode
    })

    if (type === 'button') {
      let color = 'inherit'
      if (selected) color = 'primary'
      return (
        <CustomTooltip _classes={classes} tooltip={title} placement={'bottom'}>
          <IconButton
            onClick={onClick}
            color={color}
            classes={{
              root: cn(classes.iconButtonRoot, {
                [classes.activeIcon]: selected
              })
            }}
            {...props}
          >
            {React.cloneElement(icon, {
              className: cn({
                [classes.disabledIcon]: props.disabled
              })
            })}
          </IconButton>
        </CustomTooltip>
      )
    } else {
      return <span />
    }
  },
  (...props) => propsAreEqual(...props, ['item', 'fullScreenMode'])
)

const useStylesLeftSide = makeStyles(theme => ({
  range: ({ fullScreenMode }) => ({
    marginTop: 5,
    borderRadius: 2,
    padding: fullScreenMode ? '4px 2px' : '2px 8px',
    width: fullScreenMode ? '100%' : 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.primary.main
  }),
  rangeText: {
    fontSize: 9,
    lineHeight: '12px',
    color: theme.palette.colors.white
  }
}))

const LeftSide = memo(({ fullScreenMode, isChildWindow }) => {
  const classes = useStylesLeftSide({ fullScreenMode })
  const { recordingId } = useParams()
  const { setQueryParams } = useQueryParams()
  const STORAGE_KEY = useStorageKeys()
  const { recording, selectedCuration } = useSelector(
    state => state.recordingStore
  )
  const fileName = getOr(null, ['name'], recording)
  const sortingName = getOr(null, ['default_run', 'name'], recording)
  const { id: curationId } = selectedCuration || {}

  const query = useQueryParams()
  const rangeItem = query.getByName('range')
  const range =
    rangeItem?.length > 7 && fullScreenMode
      ? rangeItem
      : rangeItem?.replace('-', ' - ')

  const filesPage = Storage.getItem(STORAGE_KEY.PAGE)
  const keyword = Storage.getItem(STORAGE_KEY.KEYWORD)

  const toFiles = `${ROUTES.FILES}${setQueryParams([
    { key: QPARAMS.FOLDER_ID, value: recording?.folder_id },
    { key: QPARAMS.PAGE, value: filesPage },
    { key: QPARAMS.KEYWORD, value: keyword }
  ])}`

  const breadcrumb = [
    {
      label: 'Data',
      to: toFiles,
      disabled: isChildWindow
    },
    {
      label: 'Recording View',
      to: `/recording/${recordingId}`,
      disabled: isChildWindow
    },
    {
      label: [sortingName || fileName, curationId].filter(Boolean).join(' - '),
      current: true
    }
  ]

  const [from, to] = range?.split('-') || []

  return (
    <div>
      {!fullScreenMode && (
        <Breadcrumb label={recording?.recordingLabel} steps={breadcrumb} />
      )}
      {range && (
        <div className={classes.range}>
          <Typography
            className={classes.rangeText}
          >{`${from} - ${to}`}</Typography>
        </div>
      )}
    </div>
  )
})

const Modals = memo(() => {
  return (
    <>
      <DataManipulationModal />
      <EventsFileUploadModal />
      <SuccessModal
        title="Operation in progress"
        message="The operation will be executed in background, you can see the progress in the status column."
      />
    </>
  )
})

export default memo(TimeWidgetToolBar, (...props) =>
  propsAreEqual(...props, [
    'onZoomIn',
    'onViewColumns',
    'onZoomOut',
    'onShiftTimeLeft',
    'onShiftTimeRight',
    'selectedView',
    'groups',
    'fullScreenMode',
    'leftPanelMode',
    'removeChannelsOnClick',
    'clearHighlightedChannels',
    'customActions',
    'toggleRasterPlot',
    'hideRasterToggler',
    'resetZoom',
    'setDataManipulationMode'
  ])
)
