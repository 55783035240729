//also we can cache new intervals which are included in an another interval already cached
export class MyMap {
  constructor(d = []) {
    this._map = new Map(d)
  }

  get(k) {
    if (this._map.has(k)) {
      return this._map.get(k)
    }

    const keys = new Set(Array.from(this._map.keys()).map(v => v.split('-')))
    const kk = k.split('-')

    for (let key of keys) {
      const x1 = parseFloat(kk[0])
      const x2 = parseFloat(kk[1])

      const y1 = parseFloat(key[0])
      const y2 = parseFloat(key[1])

      if (x1 > y1 && x1 < y2 && x2 > y1 && x2 < y2) {
        return this._map.get(key.join('-'))
      }
    }
  }

  set(k, v) {
    this._map.set(k, v)
  }

  has(k) {
    return this._map.has(k)
  }
}

export const makeKey = (range, channelsRange) => {
  const rangeKey = range ? `${range[0]}-${range[1]}` : ''
  const channelsRangeKey = channelsRange
    ? `${channelsRange[0]}-${channelsRange[1]}`
    : ''
  return `${rangeKey}--${channelsRangeKey}`
}
