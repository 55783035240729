import _, { getOr, uniq } from 'lodash/fp'
import { XMLParser } from 'fast-xml-parser'
import streamSaver from 'streamsaver'

export function getOS() {
  const userAgent = window.navigator.userAgent
  const platform = window.navigator.platform
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}

export const resolveError = error => {
  if (error.response) {
    const { data } = error.response
    if (typeof data === 'string') {
      return Promise.reject(data)
    } else if (data.message) {
      return Promise.reject(data.message)
    }
  }
  return Promise.reject('Something went wrong! Please try again later!')
}

export const isEqual = (val1, val2) => {
  return val1 === val2
}

export const getUploadDate = (value, withHours = false) =>
  value &&
  new Date(value).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    ...(withHours && {
      hour: 'numeric',
      minute: 'numeric'
    })
  })

export const mapSortByKey = (array, order, key) =>
  array?.sort(function (a, b) {
    let A = a[0],
      B = b[0]

    let keys = order?.map(e => e[key])
    return keys?.indexOf(A) > keys?.indexOf(B) ? 1 : -1
  }) || []

export const normalizeFormValues = (
  values,
  withNullValue = false,
  allowEmpty = false
) => {
  const formData = new FormData()
  Object.keys(values).forEach(i => {
    const val = values[i]
    if (val === '' && allowEmpty) return formData.append(i, '')
    const item =
      val && formData.append(i, Array.isArray(val) ? JSON.stringify(val) : val)
    return withNullValue && !val ? formData.append(i, null) : item
  })
  return formData
}

export const isNotNull = val => val !== null && val !== undefined

export const mapTags = tags => {
  return tags?.length > 0
    ? tags.map(({ value, id }) => ({
        id,
        label: value
      }))
    : []
}

export const parseFileSize = fs => {
  let i = -1
  let byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB']
  do {
    fs = fs / 1024
    i++
  } while (fs > 1024)

  return Math.max(fs, 0.1).toFixed(2) + byteUnits[i]
}

export function to(promise, ...args) {
  return promise(...args)
    .then(data => {
      return { err: null, data }
    })
    .catch(err => ({ err, data: null }))
}

export function validateName(name, obj) {
  const isNameExist = obj?.filter(
    item =>
      item.name === name ||
      (item.name.includes(name) && item.name.includes('('))
  )
  if (isNameExist.length > 0) {
    return `${name}(${isNameExist.length})`
  }
  return name
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const flatMap = (map = new Map([])) =>
  Array.from(map.values()).reduce((acc, v) => [...acc, ...v], [])

export const propsAreEqual = (prevProps, nextProps, fields) => {
  const update = fields.map(item => {
    const p = getOr(null, [item], prevProps),
      n = getOr(null, [item], nextProps)

    if ((_.isArray(p) || _.isObject(p)) && (_.isArray(n) || _.isObject(n)))
      return !_.isEqual(p, n)

    return p !== n
  })

  return !update.includes(true)
}

export class Poll {
  constructor(props) {
    this._timeout = null
    this.props = props
  }

  start = async () => {
    const { fn, cb, params, validateRequest, interval } = this.props
    const RETRY_TIME = 5000
    try {
      const { data, status } = await fn(params)

      if (status === 200) {
        cb({ data, status })
        if (validateRequest(data)) {
          this._timeout = setTimeout(this.start, interval)
        }
      } else this._timeout = setTimeout(this.start, interval + RETRY_TIME)
    } catch (e) {
      this._timeout = setTimeout(this.start, interval + RETRY_TIME)
    }
  }

  stop = () => {
    clearTimeout(this._timeout)
  }
}

export const removeObjectProperty = (obj, attr) => {
  const { [attr]: removed, ...rest } = obj

  return rest
}

export const removeObjectPropertyByValue = (obj, value) => {
  return Object.entries(obj)
    .filter(([k, v]) => v !== value)
    .reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: v
      }),
      {}
    )
}

export const arrayMap = m => flatMap(uniq([...m.values()]))

export function promisify(f) {
  return new Promise((resolve, reject) => {
    try {
      f.call(this)
      resolve()
    } catch (err) {
      reject(err)
    }
  })
}

async function blobToString(blob) {
  return await blob.text()
}

const fileToBlob = async file =>
  new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type })

export const parseXml = async file => {
  const blob = await fileToBlob(file)

  const options = {}
  const parser = new XMLParser()

  return await parser.parse(await blobToString(blob), options)
}

export const parseXmlRes = async file => {
  if (!file) return
  const options = {}
  const parser = new XMLParser()

  return await parser.parse(file, options)
}

export const createFileDownloadStream = async (res, name, onMessage) => {
  const fileStream = streamSaver.createWriteStream(`${name}.zip`)
  const writer = fileStream.getWriter()

  const reader = res.body.getReader()

  const pump = () =>
    reader.read().then(({ value, done }) => {
      onMessage()
      if (done) {
        writer.close()
      } else {
        writer.write(value)
        return writer.ready.then(pump)
      }
    })

  await pump()
    .then(() => console.log('Closed the stream, Done writing'))
    .catch(err => console.log(err))
}

export function groupBy(data, by, map, parseName) {
  const groups = new Set(data.map(x => x[by]))
  const dataGrouped = new Map()

  for (let k of groups) {
    const values = data.filter(x => x[by] === k).map(x => x[0])
    dataGrouped.set(parseName ? parseName(k) : k, map ? map(values, k) : values)
  }

  return dataGrouped
}

export function generateUniqueMapKey(mapList, valueKey, counter = 1) {
  if (!mapList.has(valueKey)) return valueKey

  const hasBrackets = valueKey.lastIndexOf(')') === valueKey.length - 1
  if (hasBrackets) {
    const currentCount = valueKey.slice(
      valueKey.lastIndexOf('(') + 1,
      valueKey.lastIndexOf(')')
    )
    counter = Number(currentCount) + 1
    valueKey = valueKey.slice(0, valueKey.lastIndexOf('('))
  }
  return generateUniqueMapKey(mapList, `${valueKey}(${counter})`)
}

export function getHashFromStr(input) {
  let output = ''
  for (let i = 0; i < input.length; i++) {
    output += input[i].charCodeAt(0)
  }

  return output
}
