import { useCallback, useState } from 'react'
import useNotification from '../components/SnackbarManager/hooks/useNotification'
import { NOTIFICATION } from '../utils/consts'

function useUploadPicture() {
  const [loading, setLoading] = useState(false)
  const addNotification = useNotification()

  const handleUpload = useCallback(
    props => {
      const {
        files,
        loadingLabel = true,
        onSuccess,
        acceptedFormat = ['image']
      } = props
      const file = files[0]
      const isValidFormat = acceptedFormat.some(
        item => file?.type.includes(item) || file?.name.includes(item)
      )
      if (!isValidFormat) {
        return addNotification({
          type: NOTIFICATION.ERROR,
          title: 'Invalid file type'
        })
      }

      let fileSize = file.size / 1024 / 1024
      if (fileSize > 10) {
        return addNotification({
          type: NOTIFICATION.ERROR,
          title: 'Error! Maximum file size is 10MB'
        })
      }
      setLoading(loadingLabel)
      const reader = new FileReader()
      reader.addEventListener('load', async event => {
        await onSuccess({
          file,
          src: event.target.result
        })
        setLoading(undefined)
      })
      reader.readAsDataURL(file)
    },
    [addNotification]
  )

  return {
    loading,
    onUpload: handleUpload
  }
}

export default useUploadPicture
