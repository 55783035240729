import React from 'react'

export const svg64_5 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="82"
        height="1659"
        viewBox="0 0 82 1659"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1626.94V0H72.5181V168.477C88.0577 497.888 85.2991 946.742 52 1596.76L19.9795 1658.5L0 1626.94Z"
          fill={color}
        />
        <path
          d="M5 307C5 305.343 6.34315 304 8 304H20C21.6569 304 23 305.343 23 307V318.077C23 319.734 21.6569 321.077 20 321.077H8C6.34315 321.077 5 319.734 5 318.077V307Z"
          fill="white"
        />
        <path
          d="M5 351.077C5 349.42 6.34315 348.077 8 348.077H20C21.6569 348.077 23 349.42 23 351.077V362.154C23 363.811 21.6569 365.154 20 365.154H8C6.34315 365.154 5 363.811 5 362.154V351.077Z"
          fill="white"
        />
        <path
          d="M5 395.154C5 393.497 6.34315 392.154 8 392.154H20C21.6569 392.154 23 393.497 23 395.154V406.231C23 407.888 21.6569 409.231 20 409.231H8C6.34315 409.231 5 407.888 5 406.231V395.154Z"
          fill="white"
        />
        <path
          d="M5 439.231C5 437.574 6.34315 436.231 8 436.231H20C21.6569 436.231 23 437.574 23 439.231V450.308C23 451.964 21.6569 453.308 20 453.308H8C6.34315 453.308 5 451.964 5 450.308V439.231Z"
          fill="white"
        />
        <path
          d="M5 483.308C5 481.651 6.34315 480.308 8 480.308H20C21.6569 480.308 23 481.651 23 483.308V494.385C23 496.041 21.6569 497.385 20 497.385H8C6.34315 497.385 5 496.041 5 494.385V483.308Z"
          fill="white"
        />
        <path
          d="M5 527.385C5 525.728 6.34315 524.385 8 524.385H20C21.6569 524.385 23 525.728 23 527.385V538.461C23 540.118 21.6569 541.461 20 541.461H8C6.34315 541.461 5 540.118 5 538.461V527.385Z"
          fill="white"
        />
        <path
          d="M5 571.461C5 569.805 6.34315 568.461 8 568.461H20C21.6569 568.461 23 569.805 23 571.461V582.538C23 584.195 21.6569 585.538 20 585.538H8C6.34315 585.538 5 584.195 5 582.538V571.461Z"
          fill="white"
        />
        <path
          d="M5 615.539C5 613.882 6.34315 612.539 8 612.539H20C21.6569 612.539 23 613.882 23 615.539V626.615C23 628.272 21.6569 629.615 20 629.615H8C6.34315 629.615 5 628.272 5 626.615V615.539Z"
          fill="white"
        />
        <path
          d="M5 659.615C5 657.958 6.34315 656.615 8 656.615H20C21.6569 656.615 23 657.958 23 659.615V670.692C23 672.349 21.6569 673.692 20 673.692H8C6.34315 673.692 5 672.349 5 670.692V659.615Z"
          fill="white"
        />
        <path
          d="M5 703.692C5 702.036 6.34315 700.692 8 700.692H20C21.6569 700.692 23 702.036 23 703.692V714.769C23 716.426 21.6569 717.769 20 717.769H8C6.34315 717.769 5 716.426 5 714.769V703.692Z"
          fill="white"
        />
        <path
          d="M5 747.769C5 746.112 6.34315 744.769 8 744.769H20C21.6569 744.769 23 746.112 23 747.769V758.846C23 760.503 21.6569 761.846 20 761.846H8C6.34315 761.846 5 760.503 5 758.846V747.769Z"
          fill="white"
        />
        <path
          d="M5 791.846C5 790.189 6.34315 788.846 8 788.846H20C21.6569 788.846 23 790.189 23 791.846V802.923C23 804.58 21.6569 805.923 20 805.923H8C6.34315 805.923 5 804.58 5 802.923V791.846Z"
          fill="white"
        />
        <path
          d="M5 835.923C5 834.266 6.34315 832.923 8 832.923H20C21.6569 832.923 23 834.266 23 835.923V847C23 848.657 21.6569 850 20 850H8C6.34315 850 5 848.657 5 847V835.923Z"
          fill="white"
        />
        <path
          d="M5 880C5 878.343 6.34315 877 8 877H20C21.6569 877 23 878.343 23 880V891.077C23 892.734 21.6569 894.077 20 894.077H8C6.34315 894.077 5 892.734 5 891.077V880Z"
          fill="white"
        />
        <path
          d="M5 924.077C5 922.42 6.34315 921.077 8 921.077H20C21.6569 921.077 23 922.42 23 924.077V935.154C23 936.81 21.6569 938.154 20 938.154H8C6.34315 938.154 5 936.81 5 935.154V924.077Z"
          fill="white"
        />
        <path
          d="M5 968.154C5 966.497 6.34315 965.154 8 965.154H20C21.6569 965.154 23 966.497 23 968.154V979.231C23 980.888 21.6569 982.231 20 982.231H8C6.34315 982.231 5 980.888 5 979.231V968.154Z"
          fill="white"
        />
        <path
          d="M5 1012.23C5 1010.57 6.34315 1009.23 8 1009.23H20C21.6569 1009.23 23 1010.57 23 1012.23V1023.31C23 1024.96 21.6569 1026.31 20 1026.31H8C6.34315 1026.31 5 1024.96 5 1023.31V1012.23Z"
          fill="white"
        />
        <path
          d="M5 1056.31C5 1054.65 6.34315 1053.31 8 1053.31H20C21.6569 1053.31 23 1054.65 23 1056.31V1067.38C23 1069.04 21.6569 1070.38 20 1070.38H8C6.34315 1070.38 5 1069.04 5 1067.38V1056.31Z"
          fill="white"
        />
        <path
          d="M5 1100.38C5 1098.73 6.34315 1097.38 8 1097.38H20C21.6569 1097.38 23 1098.73 23 1100.38V1111.46C23 1113.12 21.6569 1114.46 20 1114.46H8C6.34315 1114.46 5 1113.12 5 1111.46V1100.38Z"
          fill="white"
        />
        <path
          d="M5 1144.46C5 1142.8 6.34315 1141.46 8 1141.46H20C21.6569 1141.46 23 1142.8 23 1144.46V1155.54C23 1157.2 21.6569 1158.54 20 1158.54H8C6.34315 1158.54 5 1157.2 5 1155.54V1144.46Z"
          fill="white"
        />
        <path
          d="M5 1188.54C5 1186.88 6.34315 1185.54 8 1185.54H20C21.6569 1185.54 23 1186.88 23 1188.54V1199.62C23 1201.27 21.6569 1202.62 20 1202.62H8C6.34315 1202.62 5 1201.27 5 1199.62V1188.54Z"
          fill="white"
        />
        <path
          d="M5 1232.62C5 1230.96 6.34315 1229.62 8 1229.62H20C21.6569 1229.62 23 1230.96 23 1232.62V1243.69C23 1245.35 21.6569 1246.69 20 1246.69H8C6.34315 1246.69 5 1245.35 5 1243.69V1232.62Z"
          fill="white"
        />
        <path
          d="M5 1276.69C5 1275.04 6.34315 1273.69 8 1273.69H20C21.6569 1273.69 23 1275.04 23 1276.69V1287.77C23 1289.43 21.6569 1290.77 20 1290.77H8C6.34315 1290.77 5 1289.43 5 1287.77V1276.69Z"
          fill="white"
        />
        <path
          d="M5 1320.77C5 1319.11 6.34315 1317.77 8 1317.77H20C21.6569 1317.77 23 1319.11 23 1320.77V1331.85C23 1333.5 21.6569 1334.85 20 1334.85H8C6.34315 1334.85 5 1333.5 5 1331.85V1320.77Z"
          fill="white"
        />
        <path
          d="M5 1364.85C5 1363.19 6.34315 1361.85 8 1361.85H20C21.6569 1361.85 23 1363.19 23 1364.85V1375.92C23 1377.58 21.6569 1378.92 20 1378.92H8C6.34315 1378.92 5 1377.58 5 1375.92V1364.85Z"
          fill="white"
        />
        <path
          d="M5 1408.92C5 1407.27 6.34315 1405.92 8 1405.92H20C21.6569 1405.92 23 1407.27 23 1408.92V1420C23 1421.66 21.6569 1423 20 1423H8C6.34315 1423 5 1421.66 5 1420V1408.92Z"
          fill="white"
        />
        <path
          d="M5 1453C5 1451.34 6.34315 1450 8 1450H20C21.6569 1450 23 1451.34 23 1453V1464.08C23 1465.73 21.6569 1467.08 20 1467.08H8C6.34315 1467.08 5 1465.73 5 1464.08V1453Z"
          fill="white"
        />
        <path
          d="M5 1497.08C5 1495.42 6.34315 1494.08 8 1494.08H20C21.6569 1494.08 23 1495.42 23 1497.08V1508.15C23 1509.81 21.6569 1511.15 20 1511.15H8C6.34315 1511.15 5 1509.81 5 1508.15V1497.08Z"
          fill="white"
        />
        <path
          d="M5 1541.15C5 1539.5 6.34315 1538.15 8 1538.15H20C21.6569 1538.15 23 1539.5 23 1541.15V1552.23C23 1553.89 21.6569 1555.23 20 1555.23H8C6.34315 1555.23 5 1553.89 5 1552.23V1541.15Z"
          fill="white"
        />
        <path
          d="M5 1585.23C5 1583.57 6.34315 1582.23 8 1582.23H20C21.6569 1582.23 23 1583.57 23 1585.23V1596.31C23 1597.96 21.6569 1599.31 20 1599.31H8C6.34315 1599.31 5 1597.96 5 1596.31V1585.23Z"
          fill="white"
        />
        <path
          d="M28 283C28 281.343 29.3431 280 31 280H43C44.6569 280 46 281.343 46 283V294.077C46 295.734 44.6569 297.077 43 297.077H31C29.3431 297.077 28 295.734 28 294.077V283Z"
          fill="white"
        />
        <path
          d="M28 327.077C28 325.42 29.3431 324.077 31 324.077H43C44.6569 324.077 46 325.42 46 327.077V338.154C46 339.811 44.6569 341.154 43 341.154H31C29.3431 341.154 28 339.811 28 338.154V327.077Z"
          fill="white"
        />
        <path
          d="M28 371.154C28 369.497 29.3431 368.154 31 368.154H43C44.6569 368.154 46 369.497 46 371.154V382.231C46 383.888 44.6569 385.231 43 385.231H31C29.3431 385.231 28 383.888 28 382.231V371.154Z"
          fill="white"
        />
        <path
          d="M28 415.231C28 413.574 29.3431 412.231 31 412.231H43C44.6569 412.231 46 413.574 46 415.231V426.308C46 427.964 44.6569 429.308 43 429.308H31C29.3431 429.308 28 427.964 28 426.308V415.231Z"
          fill="white"
        />
        <path
          d="M28 459.308C28 457.651 29.3431 456.308 31 456.308H43C44.6569 456.308 46 457.651 46 459.308V470.385C46 472.041 44.6569 473.385 43 473.385H31C29.3431 473.385 28 472.041 28 470.385V459.308Z"
          fill="white"
        />
        <path
          d="M28 503.385C28 501.728 29.3431 500.385 31 500.385H43C44.6569 500.385 46 501.728 46 503.385V514.461C46 516.118 44.6569 517.461 43 517.461H31C29.3431 517.461 28 516.118 28 514.461V503.385Z"
          fill="white"
        />
        <path
          d="M28 547.461C28 545.805 29.3431 544.461 31 544.461H43C44.6569 544.461 46 545.805 46 547.461V558.538C46 560.195 44.6569 561.538 43 561.538H31C29.3431 561.538 28 560.195 28 558.538V547.461Z"
          fill="white"
        />
        <path
          d="M28 591.539C28 589.882 29.3431 588.539 31 588.539H43C44.6569 588.539 46 589.882 46 591.539V602.615C46 604.272 44.6569 605.615 43 605.615H31C29.3431 605.615 28 604.272 28 602.615V591.539Z"
          fill="white"
        />
        <path
          d="M28 635.615C28 633.958 29.3431 632.615 31 632.615H43C44.6569 632.615 46 633.958 46 635.615V646.692C46 648.349 44.6569 649.692 43 649.692H31C29.3431 649.692 28 648.349 28 646.692V635.615Z"
          fill="white"
        />
        <path
          d="M28 679.692C28 678.036 29.3431 676.692 31 676.692H43C44.6569 676.692 46 678.036 46 679.692V690.769C46 692.426 44.6569 693.769 43 693.769H31C29.3431 693.769 28 692.426 28 690.769V679.692Z"
          fill="white"
        />
        <path
          d="M28 723.769C28 722.112 29.3431 720.769 31 720.769H43C44.6569 720.769 46 722.112 46 723.769V734.846C46 736.503 44.6569 737.846 43 737.846H31C29.3431 737.846 28 736.503 28 734.846V723.769Z"
          fill="white"
        />
        <path
          d="M28 767.846C28 766.189 29.3431 764.846 31 764.846H43C44.6569 764.846 46 766.189 46 767.846V778.923C46 780.58 44.6569 781.923 43 781.923H31C29.3431 781.923 28 780.58 28 778.923V767.846Z"
          fill="white"
        />
        <path
          d="M28 811.923C28 810.266 29.3431 808.923 31 808.923H43C44.6569 808.923 46 810.266 46 811.923V823C46 824.657 44.6569 826 43 826H31C29.3431 826 28 824.657 28 823V811.923Z"
          fill="white"
        />
        <path
          d="M28 856C28 854.343 29.3431 853 31 853H43C44.6569 853 46 854.343 46 856V867.077C46 868.734 44.6569 870.077 43 870.077H31C29.3431 870.077 28 868.734 28 867.077V856Z"
          fill="white"
        />
        <path
          d="M28 900.077C28 898.42 29.3431 897.077 31 897.077H43C44.6569 897.077 46 898.42 46 900.077V911.154C46 912.81 44.6569 914.154 43 914.154H31C29.3431 914.154 28 912.81 28 911.154V900.077Z"
          fill="white"
        />
        <path
          d="M28 944.154C28 942.497 29.3431 941.154 31 941.154H43C44.6569 941.154 46 942.497 46 944.154V955.231C46 956.888 44.6569 958.231 43 958.231H31C29.3431 958.231 28 956.888 28 955.231V944.154Z"
          fill="white"
        />
        <path
          d="M28 988.23C28 986.574 29.3431 985.23 31 985.23H43C44.6569 985.23 46 986.574 46 988.23V999.307C46 1000.96 44.6569 1002.31 43 1002.31H31C29.3431 1002.31 28 1000.96 28 999.307V988.23Z"
          fill="white"
        />
        <path
          d="M28 1032.31C28 1030.65 29.3431 1029.31 31 1029.31H43C44.6569 1029.31 46 1030.65 46 1032.31V1043.38C46 1045.04 44.6569 1046.38 43 1046.38H31C29.3431 1046.38 28 1045.04 28 1043.38V1032.31Z"
          fill="white"
        />
        <path
          d="M28 1076.38C28 1074.73 29.3431 1073.38 31 1073.38H43C44.6569 1073.38 46 1074.73 46 1076.38V1087.46C46 1089.12 44.6569 1090.46 43 1090.46H31C29.3431 1090.46 28 1089.12 28 1087.46V1076.38Z"
          fill="white"
        />
        <path
          d="M28 1120.46C28 1118.8 29.3431 1117.46 31 1117.46H43C44.6569 1117.46 46 1118.8 46 1120.46V1131.54C46 1133.2 44.6569 1134.54 43 1134.54H31C29.3431 1134.54 28 1133.2 28 1131.54V1120.46Z"
          fill="white"
        />
        <path
          d="M28 1164.54C28 1162.88 29.3431 1161.54 31 1161.54H43C44.6569 1161.54 46 1162.88 46 1164.54V1175.62C46 1177.27 44.6569 1178.62 43 1178.62H31C29.3431 1178.62 28 1177.27 28 1175.62V1164.54Z"
          fill="white"
        />
        <path
          d="M28 1208.62C28 1206.96 29.3431 1205.62 31 1205.62H43C44.6569 1205.62 46 1206.96 46 1208.62V1219.69C46 1221.35 44.6569 1222.69 43 1222.69H31C29.3431 1222.69 28 1221.35 28 1219.69V1208.62Z"
          fill="white"
        />
        <path
          d="M28 1252.69C28 1251.04 29.3431 1249.69 31 1249.69H43C44.6569 1249.69 46 1251.04 46 1252.69V1263.77C46 1265.43 44.6569 1266.77 43 1266.77H31C29.3431 1266.77 28 1265.43 28 1263.77V1252.69Z"
          fill="white"
        />
        <path
          d="M28 1296.77C28 1295.11 29.3431 1293.77 31 1293.77H43C44.6569 1293.77 46 1295.11 46 1296.77V1307.85C46 1309.5 44.6569 1310.85 43 1310.85H31C29.3431 1310.85 28 1309.5 28 1307.85V1296.77Z"
          fill="white"
        />
        <path
          d="M28 1340.85C28 1339.19 29.3431 1337.85 31 1337.85H43C44.6569 1337.85 46 1339.19 46 1340.85V1351.92C46 1353.58 44.6569 1354.92 43 1354.92H31C29.3431 1354.92 28 1353.58 28 1351.92V1340.85Z"
          fill="white"
        />
        <path
          d="M28 1384.92C28 1383.27 29.3431 1381.92 31 1381.92H43C44.6569 1381.92 46 1383.27 46 1384.92V1396C46 1397.66 44.6569 1399 43 1399H31C29.3431 1399 28 1397.66 28 1396V1384.92Z"
          fill="white"
        />
        <path
          d="M28 1429C28 1427.34 29.3431 1426 31 1426H43C44.6569 1426 46 1427.34 46 1429V1440.08C46 1441.73 44.6569 1443.08 43 1443.08H31C29.3431 1443.08 28 1441.73 28 1440.08V1429Z"
          fill="white"
        />
        <path
          d="M28 1473.08C28 1471.42 29.3431 1470.08 31 1470.08H43C44.6569 1470.08 46 1471.42 46 1473.08V1484.15C46 1485.81 44.6569 1487.15 43 1487.15H31C29.3431 1487.15 28 1485.81 28 1484.15V1473.08Z"
          fill="white"
        />
        <path
          d="M28 1517.15C28 1515.5 29.3431 1514.15 31 1514.15H43C44.6569 1514.15 46 1515.5 46 1517.15V1528.23C46 1529.89 44.6569 1531.23 43 1531.23H31C29.3431 1531.23 28 1529.89 28 1528.23V1517.15Z"
          fill="white"
        />
        <path
          d="M28 1561.23C28 1559.57 29.3431 1558.23 31 1558.23H43C44.6569 1558.23 46 1559.57 46 1561.23V1572.31C46 1573.96 44.6569 1575.31 43 1575.31H31C29.3431 1575.31 28 1573.96 28 1572.31V1561.23Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="82"
      height="1659"
      viewBox="0 0 82 1659"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        d="M0 1626.94V0H72.5181V168.477C88.0577 497.888 85.2991 946.742 52 1596.76L19.9795 1658.5L0 1626.94Z"
        fill={color}
      />
    </svg>
  )
}
