export const TOOLBAR_HEIGHT = 80
export const MAIN_HEADER_HEIGHT = 75
export const BOTTOM_TOOLBAR_HEIGHT = 56
export const TIME_AXIS_LAYER_HEIGHT = 50
export const FILES_PAGINATOR_HEIGHT = 45
export const TABLE_SCROLLBAR_SIZE = 7
export const MENU_DRAWER_WIDTH = 240

export const TOTAL_HEIGHT_SINGLE_VIEW =
  TOOLBAR_HEIGHT + MAIN_HEADER_HEIGHT + BOTTOM_TOOLBAR_HEIGHT
export const TOTAL_HEIGHT_MULTI_VIEW =
  TOOLBAR_HEIGHT + MAIN_HEADER_HEIGHT + BOTTOM_TOOLBAR_HEIGHT
