import React from 'react'

export const svg128_9 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="60"
        height="850"
        viewBox="0 0 60 850"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 824V0H60L27.5 824L12.5 850L0 824Z" fill={color} />
        <path
          d="M5 95C5 93.3431 6.34315 92 8 92H20C21.6569 92 23 93.3431 23 95V106.077C23 107.734 21.6569 109.077 20 109.077H8C6.34315 109.077 5 107.734 5 106.077V95Z"
          fill="white"
        />
        <path
          d="M5 144.077C5 142.42 6.34315 141.077 8 141.077H20C21.6569 141.077 23 142.42 23 144.077V155.154C23 156.811 21.6569 158.154 20 158.154H8C6.34315 158.154 5 156.811 5 155.154V144.077Z"
          fill="white"
        />
        <path
          d="M5 193.154C5 191.497 6.34315 190.154 8 190.154H20C21.6569 190.154 23 191.497 23 193.154V204.231C23 205.888 21.6569 207.231 20 207.231H8C6.34315 207.231 5 205.888 5 204.231V193.154Z"
          fill="white"
        />
        <path
          d="M5 242.231C5 240.574 6.34315 239.231 8 239.231H20C21.6569 239.231 23 240.574 23 242.231V253.308C23 254.964 21.6569 256.308 20 256.308H8C6.34315 256.308 5 254.964 5 253.308V242.231Z"
          fill="white"
        />
        <path
          d="M5 291.308C5 289.651 6.34315 288.308 8 288.308H20C21.6569 288.308 23 289.651 23 291.308V302.385C23 304.041 21.6569 305.385 20 305.385H8C6.34315 305.385 5 304.041 5 302.385V291.308Z"
          fill="white"
        />
        <path
          d="M5 340.385C5 338.728 6.34315 337.385 8 337.385H20C21.6569 337.385 23 338.728 23 340.385V351.462C23 353.118 21.6569 354.462 20 354.462H8C6.34315 354.462 5 353.118 5 351.462V340.385Z"
          fill="white"
        />
        <path
          d="M5 389.462C5 387.805 6.34315 386.462 8 386.462H20C21.6569 386.462 23 387.805 23 389.462V400.538C23 402.195 21.6569 403.538 20 403.538H8C6.34315 403.538 5 402.195 5 400.538V389.462Z"
          fill="white"
        />
        <path
          d="M5 438.538C5 436.882 6.34315 435.538 8 435.538H20C21.6569 435.538 23 436.882 23 438.538V449.615C23 451.272 21.6569 452.615 20 452.615H8C6.34315 452.615 5 451.272 5 449.615V438.538Z"
          fill="white"
        />
        <path
          d="M5 487.615C5 485.959 6.34315 484.615 8 484.615H20C21.6569 484.615 23 485.959 23 487.615V498.692C23 500.349 21.6569 501.692 20 501.692H8C6.34315 501.692 5 500.349 5 498.692V487.615Z"
          fill="white"
        />
        <path
          d="M5 536.692C5 535.035 6.34315 533.692 8 533.692H20C21.6569 533.692 23 535.035 23 536.692V547.769C23 549.426 21.6569 550.769 20 550.769H8C6.34315 550.769 5 549.426 5 547.769V536.692Z"
          fill="white"
        />
        <path
          d="M5 585.769C5 584.112 6.34315 582.769 8 582.769H20C21.6569 582.769 23 584.112 23 585.769V596.846C23 598.503 21.6569 599.846 20 599.846H8C6.34315 599.846 5 598.503 5 596.846V585.769Z"
          fill="white"
        />
        <path
          d="M5 634.846C5 633.189 6.34315 631.846 8 631.846H20C21.6569 631.846 23 633.189 23 634.846V645.923C23 647.58 21.6569 648.923 20 648.923H8C6.34315 648.923 5 647.58 5 645.923V634.846Z"
          fill="white"
        />
        <path
          d="M5 683.923C5 682.266 6.34315 680.923 8 680.923H20C21.6569 680.923 23 682.266 23 683.923V695C23 696.657 21.6569 698 20 698H8C6.34315 698 5 696.657 5 695V683.923Z"
          fill="white"
        />
        <path
          d="M5 733C5 731.343 6.34315 730 8 730H20C21.6569 730 23 731.343 23 733V744.077C23 745.734 21.6569 747.077 20 747.077H8C6.34315 747.077 5 745.734 5 744.077V733Z"
          fill="white"
        />
        <path
          d="M5 782.077C5 780.42 6.34315 779.077 8 779.077H20C21.6569 779.077 23 780.42 23 782.077V793.154C23 794.811 21.6569 796.154 20 796.154H8C6.34315 796.154 5 794.811 5 793.154V782.077Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="60"
      height="850"
      viewBox="0 0 60 850"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M0 824V0H60L27.5 824L12.5 850L0 824Z" fill={color} />
    </svg>
  )
}
