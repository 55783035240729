import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LeftPanel from '../../../components/TimeseriesView/NewLeftPanel'
import { addDefaultChannels } from '../../../components/TimeseriesView/NewLeftPanel/redux/actions'
import { useSafeDispatch } from '../../../hooks/useSafeDispatch'
import { changeLeftPanelWidth } from '../../../components/TimeseriesView/NewLeftPanel/redux/actions'
import Loader from './Loader'
import { propsAreEqual } from '../../../utils/utils'

const IntegrateLeftPanel = ({ channels, loading }) => {
  const [leftPanelMode, update] = useState(true)

  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)

  const biochipData = useSelector(state => state.biochipData)

  const toggleLeftPanelMode = () => {
    update(current => {
      if (current) {
        safeDispatch(changeLeftPanelWidth(65))
      } else {
        safeDispatch(changeLeftPanelWidth(290))
      }

      return !current
    })
  }

  useEffect(() => {
    safeDispatch(addDefaultChannels(biochipData))
  }, [biochipData])

  return (
    <LeftPanel
      key="left-panel-filter"
      probeFileName={biochipData.probeName}
      channels={channels}
      leftPanelMode={leftPanelMode}
      toggleLeftPanelMode={toggleLeftPanelMode}
      loading={loading}
      Loader={Loader}
    />
  )
}

export default React.memo(IntegrateLeftPanel, (...props) =>
  propsAreEqual(...props, ['channels', 'loading'])
)
