import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import LogoBig from '../../imgs/LogoBig'
import LoginForm from './components/LoginForm'
import SignupForm from './components/SignupForm/SignupForm'
import LoginCarousel from './components/LoginCarousel'
import { setDarkMode } from '../../actions'
import { connect } from 'react-redux'
import { ROUTES } from '../../utils/consts'
import ResetPassword from './components/ResetPassword'
import { Route, Switch } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh'
  },
  loginWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 40
  },
  logo: ({ isSignup }) => ({
    marginBottom: isSignup ? 40 : 100
  }),
  sliderWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
}))

const Login = props => {
  const isSignup = props.match.url.includes(ROUTES.SIGNUP)
  const { onSetDarkMode } = props
  const classes = useStyles({ isSignup })

  useEffect(() => {
    onSetDarkMode(false)
  }, [])

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={6} className={classes.loginWrapper}>
        <LogoBig className={classes.logo} />
        <Switch>
          <Route exact path={ROUTES.LOGIN} component={LoginForm} />
          <Route exact path={ROUTES.SIGNUP} component={SignupForm} />
          <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
        </Switch>
      </Grid>
      <Grid item xs={12} md={6} className={classes.sliderWrapper}>
        <LoginCarousel />
      </Grid>
    </Grid>
  )
}

const mapDispatchToProps = dispatch => ({
  onSetDarkMode: value => dispatch(setDarkMode(value))
})

export default connect(null, mapDispatchToProps)(Login)
