import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { DoneAll } from '@material-ui/icons'
import CustomButton from '../../../../../CustomButton'
import { ReturnToLoginLink } from '../ResetPasswordForm/ResetPasswordForm'
import useEmailSent from './hooks/useEmailSent'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: ' center',
    marginTop: 12,
    maxWidth: 382
  },
  title: {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 600,
    marginTop: 12
  },
  mainIcon: {
    height: 64,
    width: 64,
    color: theme.palette.secondary[100]
  },
  text: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.colors.neutral[4]
  },
  resendButton: ({ isTimedOut }) => ({
    marginTop: 24,
    border: isTimedOut && 'none'
  }),
  secondsText: {
    fontWeight: 600,
    lineHeight: '18px',
    color: theme.palette.colors.darkMain
  },
  resendText: {
    marginTop: 16,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.colors.mainColor
  }
}))

const EmailSent = () => {
  const theme = useTheme()
  const { onSubmit, isTimedOut, secs } = useEmailSent()
  const classes = useStyles({ isTimedOut })

  return (
    <div className={classes.root}>
      <DoneAll className={classes.mainIcon}></DoneAll>
      <Typography className={classes.title}>
        Your email has been successfully sent
      </Typography>
      <Typography className={classes.text}>
        If you didn't receive the email, please check your Spam folder or click
        Resend Email.
      </Typography>
      <CustomButton
        label="Resend Email"
        disabled={isTimedOut}
        backgroundColor={theme.palette.colors.white}
        color={theme.palette.colors.darkBlue}
        onClick={onSubmit}
        className={classes.resendButton}
      />
      {isTimedOut && (
        <Typography className={classes.resendText}>
          <Typography component={'span'} className={classes.secondsText}>
            {secs} secs{' '}
          </Typography>
          left to resend again
        </Typography>
      )}
      <ReturnToLoginLink />
    </div>
  )
}

export default EmailSent
