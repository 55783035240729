import CustomTag from '../../../../../../../CustomTag'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import { isEmpty } from 'lodash/fp'
import EmptyWidget from '../../../../components/EmptyWidget'

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    width: '100%'
  }
}))

const TagsBody = ({ tags, onBodyClick = () => null, colorAccent }) => {
  const classes = useStyles()

  if (isEmpty(tags)) {
    return <EmptyWidget onBodyClick={onBodyClick} message="Click to edit" />
  }

  return (
    <>
      <CustomTag
        mode="preview"
        tags={tags}
        colorAccent={colorAccent}
        classes={{
          container: classes.container
        }}
      />
    </>
  )
}

export default TagsBody
