import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'

const CustomRadio = withStyles(theme => ({
  root: {
    color: theme.palette.colors.color,
    '&$checked': {
      color: theme.palette.colors.color
    }
  },
  checked: {}
}))(props => <Radio color="default" {...props} />)

const RadioChoices = ({ label, value, onSetValue, options }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup value={value} onChange={evt => onSetValue(evt.target.value)}>
        {options.map(opt => (
          <FormControlLabel
            key={opt.label}
            value={opt.value}
            control={<CustomRadio />}
            label={opt.label}
            disabled={opt.disabled ? true : false}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioChoices
