import React, { memo } from 'react'
import CustomDropdown from '../../../../../../../CustomDropdown'
import { Done } from '@material-ui/icons'
import useApproveCuration from '../ApproveComponent/hooks/useApproveCuration'
import cn from 'classnames'

const EditApproveDropdown = ({ classes, isApproved, curationId }) => {
  const { setApprovalStatus } = useApproveCuration({ curationId })
  const options = [
    {
      label: <p className={classes.label}>Approved</p>,
      endAdornment: isApproved ? <Done className={classes.done} /> : null,
      onClick: () => setApprovalStatus(true)
    },
    {
      label: <p className={classes.label}>Edit session</p>,
      endAdornment: !isApproved ? <Done className={classes.done} /> : null,
      onClick: () => setApprovalStatus(false)
    }
  ]

  return (
    <CustomDropdown
      stopPropagation
      label={isApproved ? 'Approved' : 'Edit session'}
      className={classes.dropdown}
      _classes={{
        popupButton: cn(classes.buttonRoot, { [classes.approved]: isApproved })
      }}
      items={options}
      width={174}
    />
  )
}

export default memo(EditApproveDropdown)
