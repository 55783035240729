import React from 'react'
import useGetCurrentUserWatcher from '../../hooks/useGetCurrentUserWatcher'
import FullScreenLoader from '../FullScreenLoader'
import { storeCurrentUser } from '../../actions/login'
import { connect } from 'react-redux'
import NoActiveOrganization from '../NoActiveOrganizationPage'
import { getOr } from 'lodash/fp'

const AuthMiddleware = ({ storeCurrentUser, currentUser, children }) => {
  const { loading } = useGetCurrentUserWatcher({
    storeCurrentUser,
    currentUser
  })
  const currentCompany = getOr(null, ['user', 'company'], currentUser)
  const status = getOr(0, ['user', 'company', 'status'], currentUser)
  const companies = getOr([], ['companies'], currentUser)
  const activeCompanies = companies.some(item => item?.status === 1)
  const showErrorPage = !activeCompanies || !currentCompany || status === 0

  if (loading || !currentUser) {
    return <FullScreenLoader />
  }

  if (showErrorPage && currentUser)
    return (
      <NoActiveOrganization
        hasActiveCompanies={activeCompanies}
        selectedCompanyActive={status === 1}
        currentCompany={currentCompany}
      />
    )

  return children
}

const mapStateToProps = state => ({
  currentUser: state.login.currentUser
})

const mapDispatchToProps = dispatch => ({
  storeCurrentUser: user => dispatch(storeCurrentUser(user))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthMiddleware)
