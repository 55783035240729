import React from 'react'
import { useDispatch } from 'react-redux'
import { IconButton } from '@material-ui/core'
import FormatListBulletedRoundedIcon from '@material-ui/icons/FormatListBulletedRounded'
import { addModal } from '../../../../actions/modals'

const LogsView = ({ id }) => {
  const dispatch = useDispatch()

  const onOpen = () =>
    dispatch(
      addModal({
        id: 'spikesorting_log_modal',
        args: {
          spikesortingId: id
        }
      })
    )

  return (
    <>
      <IconButton
        onClick={e => {
          e.stopPropagation()
          onOpen()
        }}
      >
        <FormatListBulletedRoundedIcon />
      </IconButton>
    </>
  )
}

export default LogsView
