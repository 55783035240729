import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: ({ colorAccent }) => ({
    borderRadius: 10,
    padding: '16px 16px 35px',
    backgroundColor: theme.palette.colors.accent[colorAccent]
  }),
  title: {
    fontSize: 13,
    marginBottom: 8,
    lineHeight: '20px',
    color: theme.palette.colors.white
  },
  pos: {
    fontSize: 28,
    fontWeight: 600,
    lineHeight: '32px',
    color: theme.palette.colors.white
  },
  loading: {
    display: 'block',
    margin: '10px auto 0',
    color: theme.palette.colors.white
  }
}))

const CardItem = ({
  title,
  content,
  rootStyle,
  titleStyle,
  contentStyle,
  colorAccent = 4
}) => {
  const classes = useStyles({ colorAccent })

  return (
    <Card className={cn(rootStyle, classes.root)}>
      <Typography noWrap className={cn(titleStyle, classes.title)}>
        {title}
      </Typography>
      <Typography noWrap className={cn(contentStyle, classes.pos)}>
        {content || '-'}
      </Typography>
    </Card>
  )
}

export default CardItem
