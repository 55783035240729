import React, { memo } from 'react'
import {
  Button,
  DialogActions,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import Draggable from 'react-draggable'
import BrainIcon from '../../../../../../Icons/BrainIcon'
import { Paper, Dialog } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CustomDropdown from '../../../../../../CustomDropdown'
import useSettingsModal from './hooks/useSettingsModal'

const useStyles = makeStyles(theme => ({
  root: {
    width: 417,
    height: 260
  },
  dialogPaper: {
    overflow: 'visible',
    backgroundColor: theme.palette.colors.customColor('white', '#232323')
  },
  body: {
    padding: 16
  },
  icon: {
    height: 16,
    width: 16,
    color: theme.palette.colors.white
  },
  iconAndLabel: {
    display: 'inline-flex'
  },
  dialogBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    height: 32,
    backgroundColor: theme.palette.colors.customColor(
      theme.palette.colors.mainColor,
      theme.palette.colors.black2
    ),
    cursor: 'move',
    color: 'white'
  },
  dialogBarText: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: '600',
    marginLeft: 8
  },
  headerText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    marginBottom: 8,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      'white'
    )
  },
  subtitleText: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      theme.palette.colors.white
    ),
    marginBottom: 8
  },
  bodyText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[3],
      theme.palette.colors.neutral[7]
    ),
    marginBottom: 8
  },
  closeButton: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[4],
      theme.palette.colors.neutral[6]
    ),
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.colors.neutral[4]
    }
  },
  actionButton: {
    backgroundColor: theme.palette.colors.mainColor,
    color: 'white',
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 8,
    lineHeight: '20px',
    textTransform: 'none'
  },
  done: {
    color: theme.palette.secondary[100],
    height: 24
  },
  popper: {
    top: '4px !important'
  },
  dropdown: {
    border: `1px solid ${theme.palette.colors.neutral[7]}`,
    borderRadius: 8,
    '& p': {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '18px'
    },
    '& .MuiButton-root': {
      height: 48,
      width: 380,
      justifyContent: 'space-between',
      padding: 16
    },
    '& svg': {
      fontSize: 44
    },
    '& li': {
      padding: 16,
      height: 40
    },
    '& .MuiPaper-root': {
      borderRadius: 8
    }
  }
}))

const SettingsModal = ({ isOpened, handleClose }) => {
  const classes = useStyles()

  const { items, splitChannels, selectedItem } = useSettingsModal({
    handleClose
  })

  const selectedLabel = selectedItem ? `${selectedItem} Channels` : 'loading...'

  return (
    <Dialog
      hideBackdrop
      open={isOpened}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick
      classes={{ paper: classes.dialogPaper }}
      disableEnforceFocus
      style={{
        position: 'sticky',
        transform: 'translate(50vw, -100%)',
        width: '0%',
        pointerEvents: 'auto'
      }}
    >
      <div className={classes.root}>
        <div className={classes.dialogBar}>
          <div className={classes.iconAndLabel}>
            <BrainIcon height={16} width={16} />
            <Typography className={classes.dialogBarText}>
              Split channels into multiple screens
            </Typography>
          </div>
          <IconButton onClick={handleClose} className={classes.icon}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </div>
        <div className={classes.body}>
          <Typography className={classes.headerText}>
            Choose the range of channels
          </Typography>
          <Typography className={classes.bodyText}>
            Choose the range of channels that you want to split into multiple
            windows.
          </Typography>
          <Typography className={classes.subtitleText}>
            Channels per screen
          </Typography>
          <CustomDropdown
            className={classes.dropdown}
            _classes={classes}
            label={selectedLabel}
            items={items}
            placement={'bottom-center'}
            width={385}
          />
        </div>
        <DialogActions>
          <Button onClick={handleClose} className={classes.closeButton}>
            Close
          </Button>
          <Button
            onClick={splitChannels}
            className={classes.actionButton}
            variant={'contained'}
          >
            Split channels
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

const togglePointerEvents = () => {
  document.body.style.pointerEvents =
    document.body.style.pointerEvents === 'none' ? 'auto' : 'none'
}

const PaperComponent = memo(props => {
  return (
    <Draggable onStart={togglePointerEvents} onStop={togglePointerEvents}>
      <Paper {...props} />
    </Draggable>
  )
})

export default SettingsModal
