import React from 'react'
import MapData from '../../../../utils/MapData/MapData'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { Delete, Edit } from '@material-ui/icons'
import BlurLoader from '../../../BlurLoader'
import FilesPagination from '../../../FilesTable/components/FilesPagination/FilesPagination'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column'
  },
  item: {
    minHeight: 56,
    padding: '6px 16px',
    borderRadius: 4,
    marginBottom: 16,
    background: theme.palette.colors.customColor(
      theme.palette.colors.neutral[9],
      theme.palette.colors.backgrounds.contrast
    )
  },
  title: {
    fontSize: 15,
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.colors.color
  },

  actionsContainer: {
    flex: 1,
    '& button:hover': {
      color: theme.palette.primary.main
    }
  },
  pagination: {
    bottom: 0,
    marginTop: 'auto'
  }
}))

const ExperimentsInputList = ({ items, onDelete, onEdit, loading, pages }) => {
  const classes = useStyles()

  if (!loading && (!items || items.length === 0))
    return <Typography className={classes.title}>No inputs</Typography>

  return (
    <div className={classes.root}>
      {loading && <BlurLoader />}
      <MapData data={items}>
        {({ item, index }) => {
          const { name, id } = item

          return (
            <Grid
              container
              justify="space-between"
              alignItems="center"
              key={index}
              className={classes.item}
            >
              <Typography className={classes.title}>{name}</Typography>
              <Grid
                container
                wrap="nowrap"
                justify="flex-end"
                alignItems="center"
                className={classes.actionsContainer}
              >
                <IconButton onClick={() => onEdit(item)}>
                  <Edit />
                </IconButton>
                <IconButton onClick={() => onDelete(id)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          )
        }}
      </MapData>
      <FilesPagination
        pages={pages}
        saveParams={false}
        withPerPageSelector={false}
        className={classes.pagination}
      />
    </div>
  )
}

export default ExperimentsInputList
