import useQueryParams from '../../../hooks/useQueryParams'
import { QPARAMS } from '../../../utils/consts'
import Storage from '../../AppHeader/components/Storage'
import useStorageKeys from '../../../hooks/useStorageKeys'

function useFilesQueryParams(customQparams) {
  const query = useQueryParams()
  const STORAGE_KEY = useStorageKeys(customQparams)
  const qparamPage = customQparams?.page || QPARAMS.PAGE
  const qparamPerPage = customQparams?.per_page || QPARAMS.PER_PAGE

  const keyword = query.getByName(QPARAMS.KEYWORD)?.trim()
  const page = query.getByName(qparamPage) || 1
  const folder_id = query.getByName(QPARAMS.FOLDER_ID)
  const filter = query.getByName(QPARAMS.FILTER)
  const per_page =
    query.getByName(qparamPerPage) ||
    Storage.getItem(STORAGE_KEY.PER_PAGE) ||
    25

  return { page, per_page, keyword, filter, folder_id, STORAGE_KEY }
}

export default useFilesQueryParams
