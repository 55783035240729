export const ADD_FILES = 'ADD_FILES'
export const DELETE_FILE = 'DELETE_FILE'
export const EDIT_FILE = 'EDIT_FILE'
export const RESET_FILES = 'RESET_FILES'
export const ADD_APPROVED_DATASETS = 'ADD_APPROVED_DATASETS'

export const addFiles = data => ({
  type: ADD_FILES,
  payload: data
})

export const addApprovedDatasets = data => ({
  type: ADD_APPROVED_DATASETS,
  payload: data
})

export const deleteFile = data => ({
  type: DELETE_FILE,
  payload: data
})

export const editFile = data => ({
  type: EDIT_FILE,
  payload: data
})
