import {
  ADD_NND_DATA,
  CHANGE_CURSOR,
  CHANGE_TIMESTAMP,
  DELETE_EVENT,
  EDIT_NND_CHANNEL_LABEL,
  HIDE_ALL_EVENTS,
  HIDE_EVENTS_SECTION,
  NAVIGATE_EVENTS,
  REFRESH_TIMESERIES,
  RESET_TIMESERIES,
  SELECT_EVENT,
  SET_NND_TIMESERIES_INFO,
  SET_TIMESERIES_INFO,
  STORE_EVENTS,
  TOGGLE_ALL_EVENTS_VISIBILITY,
  TOGGLE_NND_CHANNEL_VISIBILITY,
  TOGGLE_NND_VISIBILITY,
  SET_CHANNELS_RANGE,
  INIT,
  SET_CHILD_WINDOW,
  SET_MAIN_WINDOW_RANGE,
  UPDATE_DISABLED_CHANNELS,
  TOGGLE_EDIT_MODE
} from './actions'
import { getHashFromStr, groupBy } from '../../../../utils/utils'
import { seedUnitColor } from '../../../../shared/utils'
import { closest, getPrevNextValueStatus } from '../utils'
import { isEmpty } from 'lodash/fp'
import { fromHumanTime } from '../../../TimeWidget/fns'

export const initialState = {
  timeseriesInfo: null,
  hideHeader: false,
  nndTimeseriesInfo: new Map(),
  firstGroupChannels: [],
  customGroups: [],
  groups: { 0: [] },
  filters: {
    group: { value: false, disabled: false },
    custom: { value: false, disabled: false },
    custom_groups: { value: false, disabled: false },
    view_only: { value: false, disabled: false }
  },
  selectedGroups: new Set(),
  channel_map: null,
  allChannels: new Set(),
  channels: new Set(),
  numChannels: 32,
  leftPanelWidth: 290,
  fullScreenMode: false,
  timeSeriesView: 'single',
  disabledChannels: [],
  cursorType: 'default',

  /* if is edit mode, keyboard short cuts should be disbled */
  editMode: false,

  nnd: new Map(),
  isAllNNDVisible: true,
  isRefreshTimeSeries: true,

  events: new Map(),
  isShowAllEvents: true,
  hasPrev: false,
  hasNext: false,
  selectedEvent: null,
  hiddenEventsSection: new Map(),

  channelsImpedance: new Map(),
  threshold: null,

  currentChannelsRange: null,
  originalChannelsOrder: [],
  isChildWindow: false,
  childWindows: new Map(),
  selectedRange: null,
  mainRange: null,
  neuronalRanges: [],
  nonNeuronalRanges: []
}

const Switch = (condition, cases) => {
  if (cases[condition]) {
    if (typeof cases[condition] === 'function') {
      return cases[condition]()
    } else {
      throw new Error('The case function must be type function')
    }
  } else {
    if (cases.default) {
      return cases.default()
    } else {
      throw new Error('Default case is required')
    }
  }
}

export const MAX_CHANNELS = 128
export const CHANNELS_RANGES = [32, 64, 128]

const createRanges = (max, selectedRange, from = 0) => {
  let result = []
  let m = max - 1

  for (let i = from; i < m; i += selectedRange) {
    const end = m < i + selectedRange ? max : i + selectedRange
    result = [...result, `${i} - ${end - 1}`]
  }
  return result
}

export const getChannelsByRange = (range, channels, disabled, is = false) => {
  const [from, to] = range?.replaceAll(' ', '').split('-') || []
  const ch = range ? channels.slice(from, Number(to) + 1) : channels
  const newChannels = ch.filter(item =>
    is ? disabled.includes(item) : !disabled.includes(item)
  )

  return new Set(newChannels)
}

const getRanges = (range, timeseriesInfo) => {
  const numChannels = timeseriesInfo?.num_channels
  const nndData = timeseriesInfo?.nnd_data
  const neuronalRanges = createRanges(numChannels, range)
  const nndNumChannels = nndData
    ? Object.entries(nndData).reduce(
        (acc, [k, v]) => acc + Object.keys(v).length,
        0
      )
    : null
  const mainRange = neuronalRanges.length ? neuronalRanges[0] : null
  const nonNeuronalRanges = createRanges(
    numChannels + nndNumChannels,
    range,
    numChannels
  )

  return { mainRange, neuronalRanges, nonNeuronalRanges }
}

const timeSeriesLeftPanel = (state = initialState, { type, payload }) => {
  return Switch(type, {
    [INIT]: () => {
      return {
        ...payload,
        numChannels: payload?.num_channels,
        currentChannelsRange: payload?.range,
        selectedRange: closest(payload?.num_channels, CHANNELS_RANGES)
      }
    },
    [SET_MAIN_WINDOW_RANGE]: () => {
      const { disabledChannels, originalChannelsOrder: channel_ids } = state

      return {
        ...state,
        mainRange: payload,
        currentChannelsRange: payload,
        channels: getChannelsByRange(payload, channel_ids, disabledChannels)
      }
    },
    [SET_CHILD_WINDOW]: () => {
      const { childWindows } = state
      const { range, window, type = 'add' } = payload

      if (!range || !window) return state

      if (type === 'add') childWindows.set(range, window)
      else childWindows.delete(range)

      return {
        ...state,
        childWindows
      }
    },
    [SET_TIMESERIES_INFO]: () => {
      const { threshold, impedance_data } = payload
      const { disabledChannels, originalChannelsOrder: channel_ids } = state
      const OHM_CONSTANT = 1000000
      let extras = {}
      if (threshold && !isEmpty(impedance_data)) {
        const channelsImpedance = Object.keys(impedance_data)?.reduce(
          (prev, current) => {
            const currentValue = Number(current)
            const impedanceObj = impedance_data[currentValue]
            const impedanceNumber = (
              impedanceObj.impedance / OHM_CONSTANT
            ).toFixed(3)
            const impedance = `${impedanceNumber}M`
            return prev.set(currentValue, { ...impedanceObj, impedance })
          },
          new Map()
        )
        extras = { threshold, channelsImpedance }
      }

      const numChannels = payload?.num_channels
      const selectedRange = closest(numChannels, CHANNELS_RANGES)

      const { mainRange, nonNeuronalRanges, neuronalRanges } = getRanges(
        selectedRange,
        payload
      )

      const allChannels = channel_ids.filter(
        item => !disabledChannels.includes(item)
      )

      const currentChannelsRange = payload?.range ? payload?.range : mainRange

      return {
        ...state,
        allChannels: new Set(allChannels),
        channels: getChannelsByRange(mainRange, channel_ids, disabledChannels),
        timeseriesInfo: payload,
        mainRange,
        currentChannelsRange,
        neuronalRanges,
        nonNeuronalRanges,
        selectedRange,
        numChannels,
        ...extras
      }
    },
    [SET_CHANNELS_RANGE]: () => {
      const {
        timeseriesInfo,
        disabledChannels,
        originalChannelsOrder: channel_ids
      } = state

      const { mainRange, nonNeuronalRanges, neuronalRanges } = getRanges(
        payload,
        timeseriesInfo
      )

      return {
        ...state,
        mainRange,
        neuronalRanges,
        nonNeuronalRanges,
        selectedRange: payload,
        currentChannelsRange: mainRange,
        channels: getChannelsByRange(mainRange, channel_ids, disabledChannels)
      }
    },
    ADD_DEFAULT_CHANNELS: () => {
      if (!payload.channels) return state

      const { currentChannelsRange } = state
      const {
        chunks,
        channel_ids = [],
        channel_map,
        disabled_channels: disabledChannels = [],
        range: channelsRange
      } = payload.channels || {}
      const range = channelsRange || currentChannelsRange

      const staticGroups = Object.keys(chunks).reduce(
        (acc, group) => ({ ...acc, [group]: [] }),
        {}
      )

      let selectedGroups = Object.keys(chunks).map(g => Number(g))

      for (let v of disabledChannels) {
        const channelGroup = channel_map[v]
        if (staticGroups[channelGroup])
          staticGroups[channelGroup] = [...staticGroups[channelGroup], v]
        selectedGroups = selectedGroups.filter(group => group !== channelGroup)
      }

      // spread operator is helping to keep channel_ids original
      // order of channels, otherwise it would be reverted as well
      const channels = [...channel_ids]

      const allChannels = channels.filter(
        item => !disabledChannels.includes(item)
      )

      return {
        ...state,
        originalChannelsOrder: [...channel_ids],
        groups: staticGroups,
        allChannels: new Set(allChannels),
        channels: getChannelsByRange(range, channels, disabledChannels),
        selectedGroups: new Set(selectedGroups),
        channel_map,
        disabledChannels
      }
    },
    [UPDATE_DISABLED_CHANNELS]: () => {
      const { originalChannelsOrder: channel_ids, currentChannelsRange } = state
      const { disabledChannels } = payload

      const allChannels = channel_ids.filter(
        item => !disabledChannels.includes(item)
      )

      const channels = getChannelsByRange(
        currentChannelsRange,
        channel_ids,
        allChannels,
        true
      )

      return {
        ...state,
        channels,
        allChannels: new Set(allChannels),
        disabledChannels
      }
    },
    [REFRESH_TIMESERIES]: () => ({
      ...state,
      isRefreshTimeSeries: !state.isRefreshTimeSeries
    }),
    [SET_NND_TIMESERIES_INFO]: () => ({ ...state, nndTimeseriesInfo: payload }),
    [RESET_TIMESERIES]: () => initialState,
    [STORE_EVENTS]: () => {
      const { timeseriesInfo } = state || {}
      const allEvents = payload?.reduce((acc, current) => {
        const { file_content, s3_file_link, id, name, ...rest } = current || {}
        const parsedData = Object.entries(file_content)
        const parse = (val, key) => {
          const timestamps = val.map(item => ({
            toHumanTime: item,
            timestamp: fromHumanTime(item, timeseriesInfo?.samplerate)
          }))
          let hash = getHashFromStr(parseName(key))
          return {
            ...rest,
            id,
            timestamps,
            eventName: name,
            name: key,
            uniq_key: parseName(key),
            color: seedUnitColor(hash)
          }
        }
        // this is done in case if would be the same labels in different
        // files and in this case, label would be based on event file path
        const parseName = k => (acc.has(k) ? `${s3_file_link}-${k}` : k)
        const g = groupBy(parsedData, '1', parse, parseName)

        acc = new Map([...acc, ...g])
        return acc
      }, new Map())

      return {
        ...state,
        events: allEvents,
        eventLoading: { status: false, uniq_key: null }
      }
    },
    [SELECT_EVENT]: () => {
      const { selectedEvent: prevState } = state
      const { timestamp, timestamps, id } = payload || {}
      if (prevState?.timestamp === timestamp && prevState?.id === id)
        return { ...state, selectedEvent: null, hasPrev: false, hasNext: false }

      const eventTimeStamps = timestamps ?? []

      const { hasPrevValue: hasPrev, hasNextValue: hasNext } =
        getPrevNextValueStatus(eventTimeStamps, timestamp)

      return { ...state, selectedEvent: payload, hasPrev, hasNext }
    },
    [DELETE_EVENT]: () => {
      const { selectedEvent, events } = state
      const { uniq_key, timestamp } = selectedEvent || {}

      const goupEvents = events.get(uniq_key)
      const timestamps = goupEvents.timestamps
      goupEvents.timestamps = timestamps.filter(t => t.timestamp !== timestamp)

      if (goupEvents.timestamps.length === 0) events.delete(uniq_key)

      return {
        ...state,
        hasPrev: false,
        hasNext: false,
        selectedEvent: null,
        events
      }
    },
    [HIDE_EVENTS_SECTION]: () => {
      const { hiddenEventsSection, selectedEvent } = state
      const { label } = payload
      let newValues = new Map(hiddenEventsSection)
      const hasKey = newValues.has(label)
      if (hasKey) {
        newValues.set(label, !newValues.get(label))
      } else {
        newValues.set(label, true)
      }

      const isAllVisible = Array.from(newValues).every(([k, v]) => !v)
      const newState = {
        ...state,
        hiddenEventsSection: newValues,
        isShowAllEvents: isAllVisible
      }

      if (selectedEvent?.uniq_key === label)
        return {
          ...newState,
          selectedEvent: null
        }

      return newState
    },

    [TOGGLE_ALL_EVENTS_VISIBILITY]: () => {
      const { hiddenEventsSection, selectedEvent, events, isShowAllEvents } =
        state
      let newValues = new Map(hiddenEventsSection)
      events.forEach(event => {
        const key = event.uniq_key
        newValues.set(key, isShowAllEvents)
      })

      return {
        ...state,
        hiddenEventsSection: newValues,
        selectedEvent: isShowAllEvents ? selectedEvent : null,
        isShowAllEvents: !isShowAllEvents
      }
    },
    [HIDE_ALL_EVENTS]: () => {
      const { isShowAllEvents } = state
      return {
        ...state,
        isShowAllEvents: !isShowAllEvents
      }
    },
    [NAVIGATE_EVENTS]: () => {
      const { selectedEvent } = state
      const eventTimeStamps = selectedEvent?.timestamps ?? []
      let timeStampIndex = eventTimeStamps.findIndex(
        t => t.timestamp === selectedEvent?.timestamp
      )
      if (timeStampIndex === -1) return state

      let newTimeStamp = timeStampIndex
      if (payload === 'next') {
        newTimeStamp = eventTimeStamps[timeStampIndex + 1].timestamp
      } else if (payload === 'prev') {
        newTimeStamp = eventTimeStamps[timeStampIndex - 1].timestamp
      }

      const { hasPrevValue: hasPrev, hasNextValue: hasNext } =
        getPrevNextValueStatus(eventTimeStamps, newTimeStamp)

      return {
        ...state,
        hasPrev,
        hasNext,
        selectedEvent: { ...selectedEvent, timestamp: newTimeStamp }
      }
    },
    [ADD_NND_DATA]: () => {
      const { data } = payload
      const newData = new Map()

      Object.keys(data).forEach(key => {
        const channels = data[key]
        Object.keys(channels).map(channelId =>
          newData.set(Number(channelId), {
            recordId: key,
            channelId,
            isVisible: true,
            label: channels[channelId]
          })
        )
      })

      return { ...state, nnd: newData }
    },
    [TOGGLE_NND_CHANNEL_VISIBILITY]: () => {
      const { key, visibleState } = payload
      const { nnd } = state
      const cloneData = new Map(nnd)
      const prevChannelValue = cloneData.get(key)
      prevChannelValue &&
        cloneData.set(key, { ...prevChannelValue, isVisible: visibleState })
      const isAllNNDVisible = Array.from(cloneData.values()).every(
        chan => chan.isVisible
      )

      return { ...state, nnd: cloneData, isAllNNDVisible }
    },
    [TOGGLE_NND_VISIBILITY]: () => {
      const { isAllNNDVisible, nnd } = state
      const cloneData = new Map(nnd)
      cloneData.forEach((channel, key) =>
        cloneData.set(key, {
          ...channel,
          isVisible: !isAllNNDVisible
        })
      )
      return { ...state, nnd: cloneData, isAllNNDVisible: !isAllNNDVisible }
    },
    [EDIT_NND_CHANNEL_LABEL]: () => {
      const { channelId } = payload
      const { nnd } = state
      const cloneData = new Map(nnd)

      cloneData.set(Number(channelId), payload)

      return { ...state, nnd: cloneData }
    },
    [CHANGE_CURSOR]: () => {
      const { cursorType } = state
      const { type } = payload
      if (cursorType === type) return { ...state, cursorType: 'default' }
      return { ...state, cursorType: type }
    },
    [CHANGE_TIMESTAMP]: () => {
      const { selectedEvent, events, timeseriesInfo } = state
      const { uniq_key, timestamp } = selectedEvent || {}
      const goupEvents = events.get(uniq_key)
      const timestamps = goupEvents?.timestamps
      const is = timestamps?.some(t => t.timestamp === timestamp)
      const updated = {
        timestamp: fromHumanTime(payload, timeseriesInfo?.samplerate),
        toHumanTime: payload
      }
      goupEvents.timestamps = timestamps.map(t => {
        const is = t.timestamp === timestamp
        return is ? updated : t
      })

      return {
        ...state,
        events,
        ...(is
          ? {
              selectedEvent: {
                ...selectedEvent,
                ...updated,
                timestamps: goupEvents?.timestamps
              }
            }
          : {})
      }
    },
    TOGGLE_FILTER: () => {
      const filters = { ...state.filters }
      const { type: typeEvent } = payload
      const data = {
        ...filters[typeEvent],
        value: payload.value ?? !filters[typeEvent].value
      }

      if (payload.type === 'custom') {
        const groupVal = state.filters.group.value
        if (!filters.custom.value) {
          filters[typeEvent] = data
          filters.group = { value: groupVal, disabled: true }
        } else {
          filters[typeEvent] = data
          filters.group = { value: groupVal, disabled: false }
        }
      } else {
        filters[typeEvent] = data
      }
      return {
        ...state,
        filters
      }
    },
    RESET: () => {
      if (!Array.isArray(payload.options)) {
        return initialState
      }
      return Object.keys(state).reduce(
        (acc, v) => ({
          ...acc,
          [v]: payload.options.includes(v) ? [] : state[v]
        }),
        {}
      )
    },
    TOGGLE_CHANNEL: () => {
      const { currentChannelsRange, originalChannelsOrder: channel_ids } = state
      let newGroups = Array.from(state.selectedGroups)

      let allChannelsArray = Array.from(state.allChannels)
      const staticGroups = { ...state.groups }

      if (payload.group !== null) {
        //if the group was called
        if (state.selectedGroups.has(payload.group)) {
          //if the group is already active
          newGroups = newGroups.filter(group => group !== payload.group)

          const filteredChannels = allChannelsArray.filter(
            channel => state.channel_map[channel] === payload.group
          )

          allChannelsArray = allChannelsArray.filter(
            channel => state.channel_map[channel] !== payload.group
          )

          staticGroups[payload.group] = filteredChannels
        } else {
          //if the group is not active
          const getGroupChannels = state.groups[payload.group]

          newGroups.push(payload.group)

          allChannelsArray = [...allChannelsArray, ...getGroupChannels]

          staticGroups[payload.group] = []
        }
      } else {
        //if the single channel was called
        const payloadChannelGroup = state.channel_map[payload.channel]

        if (state.allChannels.has(payload.channel)) {
          //if the channel is already active
          allChannelsArray = allChannelsArray.filter(
            channel => channel !== payload.channel
          )

          staticGroups[payloadChannelGroup] = [
            ...staticGroups[payloadChannelGroup],
            payload.channel
          ]
        } else {
          //if the channel is not active
          allChannelsArray.push(payload.channel)

          staticGroups[payloadChannelGroup] = staticGroups[
            payloadChannelGroup
          ].filter(channel => channel !== payload.channel)
        }

        if (staticGroups[payloadChannelGroup].length !== 0) {
          newGroups = newGroups.filter(group => group !== payloadChannelGroup)
        } else {
          newGroups.push(payloadChannelGroup)
        }
      }

      if (state.disabledChannels) {
        var disabledChannels = Object.values(staticGroups).reduce(
          (acc, v) => [...acc, ...v],
          []
        )
      }

      const channels = getChannelsByRange(
        currentChannelsRange,
        channel_ids,
        allChannelsArray,
        true
      )

      return {
        ...state,
        allChannels: new Set(allChannelsArray),
        channels,
        selectedGroups: new Set(newGroups),
        groups: staticGroups,
        disabledChannels
      }
    },
    TOGGLE_CHANNEL_BULK: () => {
      const { currentChannelsRange, originalChannelsOrder: channel_ids } = state

      var filteredChannels = Array.from(payload.channels.values()).filter(
        channel => (state.nnd?.size ? !state.nnd?.get(channel) : true)
      )

      var nndChannels = Array.from(payload.channels.values()).filter(channel =>
        state.nnd?.size ? state.nnd?.get(channel) : true
      )

      const nndCloneData = state.nnd?.size ? new Map(state.nnd) : new Map()
      if (nndChannels.length > 0) {
        nndChannels.forEach(channel => {
          const prevChannelValue = nndCloneData.get(channel)
          prevChannelValue &&
            nndCloneData.set(channel, { ...prevChannelValue, isVisible: false })
        })
      }

      const selectedChannels = new Set(filteredChannels)
      const newChannels = Array.from(state.allChannels).filter(
        x => !selectedChannels.has(x)
      )
      const staticGroups = { ...state.groups }
      let newGroups = Array.from(state.selectedGroups)
      let disabledChannels = []

      if (newChannels.length > 0) {
        for (let v of selectedChannels) {
          const payloadChannelGroup = state.channel_map[v]

          staticGroups[payloadChannelGroup] = [
            ...staticGroups[payloadChannelGroup],
            v
          ]

          newGroups = newGroups.filter(group => group !== payloadChannelGroup)
        }

        if (state.disabledChannels && !payload.disabled) {
          disabledChannels = Object.values(staticGroups).reduce(
            (acc, v) => [...acc, ...v],
            []
          )
        }
      }

      const channels = getChannelsByRange(
        currentChannelsRange,
        channel_ids,
        newChannels,
        true
      )

      const disabledChannelsWithoutDuplicates = disabledChannels.filter(
        (e, i, a) => a.indexOf(e) === i
      )

      return {
        ...state,
        allChannels: new Set(newChannels),
        channels,
        selectedGroups: new Set(newGroups),
        groups: staticGroups,
        nnd: nndCloneData,
        disabledChannels: disabledChannelsWithoutDuplicates
      }
    },
    UPDATE_GROUPS: () => {
      let newGroups = Array.from(state.selectedGroups)
      const staticGroups = { ...state.groups }

      for (let v of payload.channels) {
        const payloadChannelGroup = state.channel_map[v]

        if (state.channels.has(v)) {
          staticGroups[payloadChannelGroup] = [
            ...staticGroups[payloadChannelGroup],
            v
          ]
        } else {
          staticGroups[payloadChannelGroup] = staticGroups[
            payloadChannelGroup
          ].filter(channel => channel !== v)
        }

        if (staticGroups[payloadChannelGroup].length !== 0) {
          newGroups = newGroups.filter(group => group !== payloadChannelGroup)
        } else {
          newGroups.push(payloadChannelGroup)
        }
      }

      return {
        ...state,
        selectedGroups: new Set(newGroups),
        groups: staticGroups
      }
    },
    REPLACE_CHANNELS: () => {
      const channels = payload.channels
      const channelsWithoutDuplicates = channels.filter(
        (e, i, a) => a.indexOf(e) === i
      )
      return {
        ...state,
        channels: new Set(channelsWithoutDuplicates)
      }
    },
    CHANGE_LAFT_PANEL_WIDTH: () => {
      return {
        ...state,
        leftPanelWidth: payload.leftPanelWidth
      }
    },
    TOGGLE_FULL_SCREEN_MODE: () => {
      return {
        ...state,
        fullScreenMode: payload.fullScreenMode
      }
    },
    [TOGGLE_EDIT_MODE]: () => {
      return {
        ...state,
        editMode: payload
      }
    },
    UPDATE_VIEW: () => {
      return { ...state, timeSeriesView: payload.view }
    },
    default: () => {
      return state
    }
  })
}

export default timeSeriesLeftPanel
