import * as Widgets from './widgets'

export const mapLabelToWidgetComponent = new Map([
  ['cluster_view', { widget: Widgets.ClusterView }],
  [
    'correlogram_view',
    { widget: Widgets.CorrelogramView, plugins: Widgets.correlogramPlugins }
  ],
  [
    'data',
    {
      widget: Widgets.DataView,
      onEdit: Widgets.useEditDataAction
    }
  ],
  ['feature_view', { widget: Widgets.FeatureView }],
  [
    'publications',
    { widget: Widgets.PublicationsView, onEdit: Widgets.useEditPublications }
  ],
  ['similarity_view', { widget: Widgets.SimilarityView }],
  ['summary_plots', null],
  ['tags', { widget: Widgets.TagsView, onEdit: Widgets.useEditTags }],
  [
    'waveform_view',
    {
      widget: Widgets.WaveFormView,
      onSwitch: Widgets.useOnSwitch,
      toggleGeometry: Widgets.useToggleGeometry,
      toggleOverlapping: Widgets.useToggleOverlapping
    }
  ]
])
