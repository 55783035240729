import React from 'react'

const RightArrowIcon = props => {
  return (
    <svg
      width="6"
      height="7"
      viewBox="0 0 6 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6 3.5L0.75 6.53109L0.75 0.468911L6 3.5Z" fill="#21B784" />
    </svg>
  )
}

export default RightArrowIcon
