import { getOS } from '../../../../../utils/utils'
import { OS } from '../../../../../utils/consts'
import CommandIcon from '../../../../Icons/CommandIcon'
import AltKeyIcon from '../../../../Icons/AltKeyIcon'
import { makeStyles, Typography } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
  keysCombination: ({ width }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    minWidth: 26,
    minHeight: 26,
    width: width,
    padding: '0 8px',
    pointerEvents: 'none',
    textTransform: 'none',
    fontSize: 12,
    wordWrap: 'nowrap',
    fontWeight: 600,
    lineHeight: '20px',
    boxShadow: `0px 1px 0px ${theme.palette.colors.neutral[6]}`,
    border: `1px solid ${theme.palette.colors.neutral[7]}`,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[2],
      theme.palette.colors.white
    ),
    '& svg': {
      marginRight: 3
    }
  }),
  plusSign: {
    fontSize: 10,
    margin: '0 3px',
    fontWeight: 100
  }
}))

const renderIconByKey = key => {
  switch (key) {
    case 'cmd':
      return getOS() === OS.MAC_OS ? <CommandIcon /> : 'ctrl'
    case 'alt':
      return getOS() === OS.MAC_OS ? <AltKeyIcon /> : 'alt'
    default:
      return key
  }
}

export const RenderCombination = ({ keys, width = '100%' }) => {
  const classes = useStyles({ width })

  return (
    <Typography noWrap className={classes.keysCombination}>
      {keys.map((key, idx) => {
        const Icon = renderIconByKey(key.key)
        return (
          <>
            {key.metaKey ? Icon : key.key}
            {idx !== keys.length - 1 && (
              <span className={classes.plusSign}> + </span>
            )}
          </>
        )
      })}
    </Typography>
  )
}
