import React from 'react'
import SelectPositionBox from './SelectPositionBox'
import SelectedPositionWidget from './SelectedPositionWidget'
import { mapLabelToWidgetComponent } from '../utils'

function Tree({
  treeData = [],
  updateNodes,
  parentId = 0,
  level = 0,
  children,
  unwrappChild,
  clearWidget,
  recordingId
}) {
  const items = Array.from(treeData)
    .map(v => v[1])
    .filter(item => item.parentId === parentId)

  const cloneChildren = item => {
    const { widget: Widget, plugins } =
      mapLabelToWidgetComponent.get(item.widgetId) || {}

    return children
      ? React.cloneElement(children, {
          children: Widget ? (
            <Widget recordingId={recordingId} level={item.level} />
          ) : (
            item.value
          ),
          id: item.id,
          label: item.value,
          widgetId: item.widgetId,
          plugins,
          clearWidget: () => clearWidget(item)
        })
      : null
  }

  if (!items.length) return null

  return (
    <>
      {items.map(item => {
        return item.value ? (
          <SelectPositionBox
            key={item.id}
            id={item.id}
            level={item.level}
            value={item.value}
            visible={item.visible}
            reverse={item.reverse}
            unwrappChild={unwrappChild}
            clearWidget={() => clearWidget(item)}
          >
            {children ? (
              cloneChildren(item)
            ) : (
              <SelectedPositionWidget widget={item.value} />
            )}
          </SelectPositionBox>
        ) : (
          <SelectPositionBox
            key={item.id}
            id={item.id}
            level={item.level}
            visible={item.visible}
            reverse={item.reverse}
            updateNodes={updateNodes}
            unwrappChild={unwrappChild}
          >
            <Tree
              treeData={treeData}
              updateNodes={updateNodes}
              unwrappChild={unwrappChild}
              clearWidget={clearWidget}
              recordingId={recordingId}
            >
              {cloneChildren(item)}
            </Tree>
          </SelectPositionBox>
        )
      })}
    </>
  )
}

export default function TreeWithTour(props) {
  return (
    <>
      <Tree {...props} />
      <span
        id="recording-tour-step-1"
        style={{ position: 'absolute', bottom: 200, right: 550 }}
      />
    </>
  )
}
