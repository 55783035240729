import { useCallback, useEffect, useState } from 'react'
import useEditRecord from '../../../hooks/useEditRecord'
import { uploadProbeFile } from '../../../../../endpoints/admin'
import {
  MODALS_ID,
  NOTIFICATION,
  UPLOAD_PRBFILE_METHODS
} from '../../../../../utils/consts'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import {
  getUploadDate,
  normalizeFormValues,
  parseFileSize
} from '../../../../../utils/utils'
import useToggleModal from '../../../../../hooks/useToggleModal'
import useRecordingInfo from '../../../hooks/useRecordingInfo'

function useFilesModal() {
  const [loadingUpload, setLoading] = useState(false)
  const [uploadMode, setUploadMode] = useState(false)
  const [selectedRow, setSelected] = useState([])

  const { recording } = useRecordingInfo()
  const { id, prb_file, upload_date, name, file_size, created_at } =
    recording || {}
  const [view, setView] = useState(UPLOAD_PRBFILE_METHODS.DEFAULT)
  const [selectedFile, setPrbFile] = useState(prb_file)
  const { onEdit, loading: loadingEditRecord } = useEditRecord()
  const addNotification = useNotification()
  const loading = loadingUpload || loadingEditRecord

  const modalID = MODALS_ID.FILES_MODAL
  const { toggle: toggleModal, isOpened } = useToggleModal(modalID)

  useEffect(() => {
    if (view === UPLOAD_PRBFILE_METHODS.DEFAULT) setPrbFile(prb_file)
    else setPrbFile(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view])

  useEffect(() => {
    //Update default select dropdown value on render
    setPrbFile(recording?.prb_file)
  }, [recording])

  const handleChangeView = useCallback(event => {
    const { value } = event.target
    if (value) setView(value)
  }, [])

  const handleEditRecording = useCallback(
    file => {
      const isDefault = view === UPLOAD_PRBFILE_METHODS.DEFAULT
      const data = {
        id,
        prb_file: file,
        ...(isDefault
          ? { probe_file_type: 'default' }
          : { probe_file_type: null })
      }
      return onEdit({
        data,
        onSuccess: (updateStore, res) => {
          const { probe_id, prb_file } = res
          updateStore({
            recording: { ...recording, ...data, probe_id, prb_file }
          })
          toggleModal()
        }
      })
    },
    [id, onEdit, recording, toggleModal, view]
  )

  const handleUploadProbFile = useCallback(async () => {
    try {
      setLoading(true)
      const submitValues = normalizeFormValues({
        probe_file: selectedFile,
        record_id: id
      })
      const response = await uploadProbeFile(submitValues)
      setLoading(false)
      if (response?.message) {
        handleEditRecording(response?.message)
      }
    } catch (e) {
      setLoading(false)
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [addNotification, handleEditRecording, id, selectedFile])

  const handleSubmit = useCallback(() => {
    if (!selectedFile) return null
    if (view === UPLOAD_PRBFILE_METHODS.DEFAULT)
      handleEditRecording(selectedFile)
    else handleUploadProbFile()
  }, [handleEditRecording, handleUploadProbFile, selectedFile, view])

  const toggleUploadMode = useCallback(
    () => setUploadMode(oldVal => !oldVal),
    [setUploadMode]
  )

  const isSelectedRow = id =>
    Array.isArray(selectedRow) && selectedRow.indexOf(id) !== -1

  const handleSelectRow = useCallback(
    id => {
      let newSelected
      if (!Array.isArray(selectedRow)) {
        newSelected = [id]
      } else {
        const isSelected = selectedRow.some(i => i === id)
        if (!isSelected) {
          newSelected = [...selectedRow, id]
        } else {
          newSelected = selectedRow.filter(i => i !== id)
        }
      }

      setSelected(newSelected)
    },
    [selectedRow, setSelected]
  )

  const uploadDate = getUploadDate(upload_date || created_at)

  const columns = [
    {
      id: 0,
      label: 'File Name',
      active: true
    },
    {
      id: 1,
      label: 'Size',
      active: true
    },
    {
      id: 2,
      label: 'Upload Date',
      active: true
    }
  ]

  const rows = [
    {
      id: 0,
      name,
      file_size: parseFileSize(file_size),
      upload_date: uploadDate
    },
    prb_file
      ? {
          id: 1,
          prb_file,
          file_size: null,
          upload_date: uploadDate
        }
      : null
  ].filter(Boolean)

  return {
    isOpened,
    toggleModal,
    isSelectedRow,
    handleSelectRow,
    handleSubmit,

    // re-upload view
    toggleUploadMode,
    uploadMode,
    selectedFile,
    handleChangeView,
    setPrbFile,
    view,
    loading,
    columns,
    rows
  }
}

export default useFilesModal
