import React from 'react'
import {
  Button,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import CurationAIIcon from '../../../../../../Icons/curationAIIcon'
import CustomTooltip from '../../../../../../CustomTooltip'
import useCurationAI from '../hooks/useCurationAI'
import TourTooltip from './TourTooltip'
import { alpha } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  curationAIToggle: ({ isCurationAI }) => ({
    width: 63,
    height: 32,
    padding: 8,
    borderRadius: 32,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 2,
    border: isCurationAI
      ? `2px solid ${theme.palette.colors.lighterGreen}`
      : 'none',
    backgroundColor: theme.palette.colors.customColor(
      theme.palette.colors.white,
      theme.palette.colors.neutral[90]
    ),
    boxShadow: `0px 0px 4px ${alpha(theme.palette.colors.color, 0.5)}`
  }),
  curationAILabel: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '12px',
    color: theme.palette.colors.mainColor
  },
  tooltip: {
    width: 102,
    height: 30,
    border: 'none',
    lineHeight: '18px',
    fontWeight: 600,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.colors.customColor(
      theme.palette.colors.neutral[9],
      theme.palette.colors.neutral[90]
    ),
    color: theme.palette.colors.mainColor
  },
  tooltipArrow: {
    fontSize: 22,
    '&::before': {
      backgroundColor: theme.palette.colors.customColor(
        theme.palette.colors.neutral[9],
        theme.palette.colors.neutral[90]
      ),
      border: `0px`
    }
  },
  popper: {
    zIndex: 1
  },
  curationAIIcon: {
    marginRight: 4
  }
}))

const CurationAiToggle = () => {
  const {
    loading,
    toggle: toggleCurationAI,
    isCurationAI,
    showTourTooltip,
    handleToggle
  } = useCurationAI()
  const classes = useStyles({ isCurationAI })

  const Tooltip = showTourTooltip ? TourTooltip : CustomTooltip

  return (
    <Tooltip
      showTourTooltip={showTourTooltip}
      handleClose={() => handleToggle(false)}
      tooltip={'AI curation'}
      placement={'bottom'}
      _classes={classes}
    >
      <Button
        id={'curation-AI-toggle'}
        className={classes.curationAIToggle}
        onClick={toggleCurationAI}
        disabled={loading}
      >
        <CurationAIIcon className={classes.curationAIIcon} />
        {loading ? (
          <CircularProgress size={18} />
        ) : (
          <Typography className={classes.curationAILabel}>
            {isCurationAI ? 'ON' : 'OFF'}
          </Typography>
        )}
      </Button>
    </Tooltip>
  )
}

export default CurationAiToggle
