import React from 'react'
import cn from 'classnames'
import CustomModal from '../../../../CustomModal'
import ActionButton from '../../../../ActionButton'
import { Grid, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { darken, makeStyles } from '@material-ui/core/styles'
import { useDownloadFilesModal } from '../hooks'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(3)
  },
  title: {
    fontSize: 13
  },
  content: {
    fontSize: 16,
    fontWeight: 600
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  extraContainerStyle: { width: '140px' },
  deleteIcon: {
    color: theme.palette.colors.neutral[5],
    cursor: 'pointer'
  },
  action: {
    width: 'fit-content',
    backgroundColor: '#21B685',
    '&:hover': {
      backgroundColor: darken('#21B685', 0.3)
    }
  }
}))

export default function DownloadFilesModal({ isLimited }) {
  const { isOpened, onClose, modalContent, loading } = useDownloadFilesModal()
  const classes = useStyles()

  return (
    <CustomModal
      title="Files"
      open={isOpened}
      onClose={() => {
        onClose()
      }}
      hideCloseButton
      renderActionButton={<ActionButton label="Close" onClick={onClose} />}
      maxWidth="554px"
    >
      {modalContent.map((value, index) => {
        const {
          title,
          content,
          subtitle,
          canDelete,
          deleteFunction,
          disabled
        } = value || {}
        return (
          <Grid
            container
            className={classes.container}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Typography className={classes.title}>{title}</Typography>
              {subtitle !== '' && (
                <Typography className={classes.title}>{subtitle}</Typography>
              )}
              <Typography className={classes.content}>{content}</Typography>
            </Grid>

            <div
              className={cn(classes.actionContainer, {
                [classes.extraContainerStyle]: !isLimited && canDelete
              })}
            >
              {!isLimited && canDelete && (
                <DeleteIcon
                  onClick={deleteFunction}
                  className={classes.deleteIcon}
                />
              )}
              <ActionButton
                className={classes.action}
                loading={loading.index === index}
                label="Download"
                disabled={
                  isLimited || disabled || loading.index === index
                    ? loading
                    : false
                }
                onClick={value.downloadFunction}
              />
            </div>
          </Grid>
        )
      })}
    </CustomModal>
  )
}
