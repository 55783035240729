import Typography from '@material-ui/core/Typography'
import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: 8,
    borderBottom: `1px solid ${theme.palette.colors.neutral['7']}`
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 8,
    lineHeight: '24px',
    color: theme.palette.colors.neutral.black
  },
  content: {
    marginBottom: 0,
    fontWeight: 400,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['2'],
      theme.palette.colors.neutral['9']
    )
  }
}))

const DataField = ({ title, className, content }) => {
  const classes = useStyles()
  return (
    <div className={cn(classes.root, className)}>
      <Typography className={classes.title}>{title}:</Typography>
      <Typography className={classes.content}>{content || '-'}</Typography>
    </div>
  )
}

export default memo(DataField)
