import { useSelector } from 'react-redux'
import { getOr } from 'lodash/fp'

export const useWaveformData = () => {
  const {
    geometry: waveformGeometry,
    view: waveformsView,
    overlap: waveformOverlapping
  } = useSelector(state => state.widgetsCache.waveforms)

  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )
  const { id: sortingId } = selectedSorting || {}

  const selectedCuration = useSelector(
    state => state.recordingStore.selectedCuration
  )
  const curationId = getOr(null, ['id'], selectedCuration)

  return {
    waveformsView,
    waveformGeometry,
    waveformOverlapping,
    algo: selectedSorting?.algo,
    sortingId,
    curationId
  }
}
