import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, Typography, useTheme } from '@material-ui/core'
import { MODALS_ID } from '../../../../utils/consts'
import useToggleModal from '../../../../hooks/useToggleModal'
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useFetchResult } from '../../../../hooks/useFetchResult'
import { getAllProbeFilesInfo } from '../../../../endpoints/admin'
import CustomModal from '../../../CustomModal'
import ActionButton from '../../../ActionButton'
import TableCheckbox from '../../../CustomTable/components/TableCheckbox'
import BlurLoader from '../../../BlurLoader'
import CustomTable from '../../../CustomTable'
import DrawProbe from '../../../TimeseriesView/NewLeftPanel/components/DrawProbe'
import { hexToRgba } from '../../../../utils/styles'
import cn from 'classnames'
import CustomDropdown from '../../../CustomDropdown'
import { uniqBy } from '../../../TimeseriesView/NewLeftPanel/utils'
import { isNotNull } from '../../../../utils/utils'
import CloseIcon from '@material-ui/icons/Close'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../../../utils/consts'
import { isEmpty } from 'lodash/fp'
import usePrbFileModalColumns from './hooks/usePrbFIleModalColumns'

const useStyles = makeStyles(theme => ({
  body: {
    flex: 1,
    minHeight: 470,
    position: 'relative'
  },
  modalRoot: {
    display: 'flex'
  },
  modalTitle: {
    fontSize: 23,
    lineHeight: '24px'
  },
  modalHeader: {
    padding: '25px 25px 12px'
  },

  dropdown: {
    marginRight: 20
  },
  resetBtn: {
    color: theme.palette.colors.error,
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    backgroundColor: theme.palette.colors.backgrounds.main,
    '&:hover': {
      color: theme.palette.colors.error
    }
  },
  container: {
    height: 480
  },
  tableRoot: ({ withSerialNr }) => ({
    width: withSerialNr ? 600 : 540,
    height: '100%',
    overflow: 'auto'
  }),

  filteringRoot: {
    width: '100%',
    display: 'flex',
    paddingBottom: 25
  },

  shankRoot: {
    flex: '1 1 0%',
    marginLeft: 16,
    padding: 20,
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: theme.palette.colors.backgrounds.files
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center'
  },
  noData: {
    margin: 'auto'
  },
  shank: {
    marginTop: 16
  },

  toggle: {
    height: 25,
    padding: '0 8px',
    backgroundColor: hexToRgba(theme.palette.colors.accent[1], 0.1)
  },
  chipId: {
    fontSize: 9,
    lineHeight: '12px',
    fontWeight: 600,
    color: theme.palette.colors.neutral.black
  },

  table: {
    borderRadius: 0,
    border: 'none',
    '& thead tr th': {
      borderBottom: `1px solid ${theme.palette.colors.grey_15}`
    }
  }
}))

const SelectDefaultPrbFileModal = ({ withSerialNr = false }) => {
  const classes = useStyles({ withSerialNr })
  const { pathname } = useLocation()
  const isProbeScreen = pathname === ROUTES.PROBE_INVENTORY

  const theme = useTheme()

  const {
    toggle: toggleModal,
    isOpened,
    modalState
  } = useToggleModal(MODALS_ID.SELECT_DEFAULT_PRB_FILE_MODAL)
  const { onSubmit } = modalState?.args || {}

  const [selectedPrbFile, setPrbFile] = useState(null)
  const fetchParams = isProbeScreen ? {} : { inventory: 1 }

  const { state, fetchData } = useFetchResult(getAllProbeFilesInfo, fetchParams)

  useEffect(() => {
    if (isOpened && !state?.data) fetchData()

    return () => setPrbFile(null)
    //eslint-disable-next-line
  }, [isOpened, state?.data])

  const handleClose = useCallback(() => {
    const { id: probeId, name } = selectedPrbFile ?? {}
    const submitArgs = probeId ? { probeId, name } : name
    if (selectedPrbFile && onSubmit) onSubmit(submitArgs)
    toggleModal()
  }, [toggleModal, onSubmit, selectedPrbFile])

  return (
    <CustomModal
      noBorders
      classes={{
        title: classes.modalTitle,
        header: classes.modalHeader
      }}
      classNames={classes.modalRoot}
      customBodyClass={classes.body}
      open={isOpened}
      maxWidth={withSerialNr ? 1000 : 880}
      onClose={toggleModal}
      hideCloseButton
      title="Select a Probe file"
      renderActionButton={
        <ActionButton
          label="Save"
          onClick={handleClose}
          disabled={!selectedPrbFile}
          customStyles={{
            backgroundColor: theme.palette.action.secondary
          }}
        />
      }
    >
      <PrbFileBody
        state={state}
        setPrbFile={setPrbFile}
        selectedPrbFile={selectedPrbFile}
        withSerialNr={withSerialNr}
      />
    </CustomModal>
  )
}

const PrbFileBody = memo(
  ({ state, selectedPrbFile, setPrbFile, withSerialNr }) => {
    const classes = useStyles({ withSerialNr })
    const { loading, data } = state

    const [nr_chanks, selectShank] = useState(null)
    const [nr_channels, selectChannelsN] = useState(null)

    const { columns } = usePrbFileModalColumns({ withSerialNr })

    const filtered = useMemo(
      () =>
        data?.filter(item => {
          const { number_channels, chunks } = item
          const c1 = isNotNull(nr_channels)
            ? number_channels === nr_channels
            : true
          const c2 = isNotNull(nr_chanks) ? chunks === nr_chanks : true

          return c1 && c2
        }),
      [data, nr_channels, nr_chanks]
    )

    const rows = useMemo(() => {
      return filtered?.map((item, index) => {
        const { id, name, chunks, number_channels, serial } = item
        const { id: selectedId, name: selectedName } = selectedPrbFile ?? {}
        let isSelected = name === selectedName
        if (selectedId) isSelected = id === selectedId
        const serialValue =
          serial && `${serial.slice(0, 3)}...${serial.slice(serial.length - 3)}`

        let row = {
          id: index,
          name,
          serial: { shortText: serialValue, fullText: serial },
          chunks,
          number_channels,
          select: <TableCheckbox isDefault={isSelected} />
        }
        !withSerialNr && delete row.serial
        return row
      })
    }, [filtered, selectedPrbFile, withSerialNr])

    const shanks =
      data &&
      uniqBy(data, 'chunks').map(item => ({
        id: item.chunks,
        label: item.chunks
      }))

    const nchannels =
      data &&
      uniqBy(data, 'number_channels').map(item => ({
        id: item.number_channels,
        label: item.number_channels
      }))

    const handleSelect = useCallback(
      (item, cb, fn) => {
        fn(item?.id)
        cb()
        setPrbFile(null)
      },
      [setPrbFile]
    )

    const handleResetFilter = useCallback(() => {
      selectShank(null)
      selectChannelsN(null)
      setPrbFile(null)
    }, [setPrbFile])

    const handleClickRow = useCallback(
      index => {
        const probe = filtered && filtered[index]
        if (probe) setPrbFile(probe)
      },
      [setPrbFile, filtered]
    )

    if (loading) return <BlurLoader />

    return (
      <Grid container>
        <div className={classes.filteringRoot}>
          {shanks && (
            <CustomDropdown
              label={`Shanks N. ${nr_chanks ?? ''}`}
              items={shanks}
              width={80}
              onSelect={(...opt) => handleSelect(...opt, selectShank)}
              placement="bottom-start"
              className={classes.dropdown}
            />
          )}
          {nchannels && (
            <CustomDropdown
              label={`Channels N. ${nr_channels ?? ''}`}
              items={nchannels}
              width={80}
              placement="bottom-start"
              onSelect={(...opt) => handleSelect(...opt, selectChannelsN)}
              className={classes.dropdown}
            />
          )}

          <Button
            color="secondary"
            className={classes.resetBtn}
            startIcon={<CloseIcon />}
            onClick={handleResetFilter}
          >
            Reset Filter
          </Button>
        </div>

        <Grid container className={classes.container}>
          <div className={classes.tableRoot}>
            <CustomTable
              isSticky
              rows={rows}
              rowHeight={46}
              cellWidth={80}
              loading={loading}
              columns={columns}
              withBorders={false}
              className={classes.table}
              onClickRow={handleClickRow}
            />
          </div>
          {!isEmpty(rows) && (
            <DrawShank selectedPrbFile={selectedPrbFile?.name} />
          )}
        </Grid>
      </Grid>
    )
  }
)

const DrawShank = memo(({ selectedPrbFile }) => {
  const classes = useStyles()
  const theme = useTheme()

  const drawRef = useRef(null)

  const [svgData, setDimensions] = useState(null)

  useLayoutEffect(() => {
    const svg = drawRef.current
    setDimensions({
      width: svg?.getBoundingClientRect().width,
      height: svg?.getBoundingClientRect().height,
      hasMapping: svg
    })
  }, [drawRef, selectedPrbFile])

  const { hasMapping, width } = svgData || {}
  const noMapping = selectedPrbFile && !hasMapping

  return (
    <div className={classes.shankRoot}>
      <Typography
        className={cn(classes.title, {
          [classes.noData]: !(selectedPrbFile && hasMapping)
        })}
      >
        {noMapping
          ? 'No mapping found'
          : selectedPrbFile || 'No Probe file selected'}
      </Typography>

      {selectedPrbFile && (
        <div className={classes.shank}>
          {hasMapping && (
            <Grid
              wrap="nowrap"
              container
              alignItems="center"
              justify="space-between"
              className={classes.toggle}
              style={{ width }}
            >
              <Typography
                className={classes.chipId}
                title={selectedPrbFile}
                noWrap
              >
                {selectedPrbFile}
              </Typography>
            </Grid>
          )}

          <DrawProbe
            preview
            probeFile={selectedPrbFile}
            ref={drawRef}
            color={theme.palette.colors.accent[1]}
          />
        </div>
      )}
    </div>
  )
})

export default SelectDefaultPrbFileModal
