import React, { memo } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomInput from '../../../../../FormComponents/CustomInput'
import CustomButton from '../../../../../CustomButton'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../../../../utils/consts'
import { Formik } from 'formik'
import useResetPasswordForm from './hooks/useResetPasswordForm'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: 382
  },
  title: {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 600,
    color: theme.palette.colors.mainColor
  },
  subtitle: {
    marginTop: 12,
    marginBottom: 50,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.colors.neutral[4]
  },
  sendButton: {
    marginTop: 24
  },
  text: {
    fontWeight: 400,
    lineHeight: '16px',
    marginTop: 24,
    color: theme.palette.colors.neutral[5]
  },
  errorPlainText: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    color: theme.palette.colors.neutral[3],
    textAlign: 'left',
    marginTop: 4,
    marginLeft: 16
  },
  loginLink: {
    marginTop: 24,
    fontWeight: 600,
    lineHeight: '18px',
    color: theme.palette.colors.darkMain,
    textDecoration: 'none'
  },
  formInputError: {
    '& fieldset': {
      border: `2px solid ${theme.palette.colors.accent[8]}`,
      borderRadius: 8
    },
    '& label': {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '18px',
      color: `${theme.palette.colors.accent[8]} !important`
    }
  }
}))

const ResetPasswordForm = () => {
  const classes = useStyles()
  const theme = useTheme()
  const {
    initialValues,
    validation,
    onSubmit,
    responseError,
    setResponseError
  } = useResetPasswordForm()

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Change password</Typography>
      <Typography className={classes.subtitle}>
        Please enter your email address and we'll send you a link to reset your
        password.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={values => onSubmit(values)}
      >
        {({
          isSubmitting,
          values: { email },
          handleChange,
          touched,
          errors,
          handleBlur,
          handleSubmit
        }) => {
          const handleKeyPress = e => {
            if (e.key.toLowerCase() === 'enter') {
              handleSubmit()
            }
            setResponseError('')
          }
          errors = responseError ? { email: ' ' } : errors
          return (
            <form>
              <CustomInput
                label="Email"
                name="email"
                type="email"
                required
                variant="outlined"
                fullWidth
                value={email}
                onChange={handleChange}
                error={errors}
                touched={touched}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                className={cn({
                  [classes.formInputError]: responseError
                })}
              />
              {responseError && (
                <Typography className={classes.errorPlainText}>
                  {responseError}
                </Typography>
              )}
              <CustomButton
                label="Send email"
                color={theme.palette.colors.white}
                loading={isSubmitting}
                loadingColor={theme.palette.colors.white}
                onClick={handleSubmit}
                className={classes.sendButton}
              />
            </form>
          )
        }}
      </Formik>
      <ReturnToLoginLink />
    </div>
  )
}

export const ReturnToLoginLink = memo(() => {
  const classes = useStyles()
  return (
    <Typography className={classes.text}>
      Return to{' '}
      <Link to={ROUTES.LOGIN} className={classes.loginLink}>
        Login
      </Link>
    </Typography>
  )
})

export default ResetPasswordForm
