class Storage {
  static _storage = null

  static initStorage(storage) {
    if (this._storage === null) {
      this._storage = storage
    }
  }

  static removeItem(key) {
    if (
      this._storage?.removeItem &&
      typeof this._storage?.removeItem === 'function'
    ) {
      this._storage.removeItem(key)
    }
  }

  static getItem(key) {
    if (this._storage?.getItem && typeof this._storage.getItem === 'function') {
      const item = this._storage.getItem(key)
      if (item) {
        return JSON.parse(item)
      }
      return null
    }
  }

  static setItem(key, value) {
    if (this._storage.setItem && typeof this._storage.setItem === 'function') {
      this._storage.setItem(key, JSON.stringify(value))
    }
  }
}

export default Storage
