import React from 'react'
import ExperimentsInputList from '../ExperimentsInputList'
import useExpInputsActions from '../../hooks/useExpInputsActions'
import { querySortingExpInputs } from '../../../../endpoints/admin'
import { storeSortingsInputs } from '../../../../actions/experimentsInput'
import { useSelector } from 'react-redux'
import { MODALS_ID } from '../../../../utils/consts'

const ExperimentsInputRuns = () => {
  const spikesortings = useSelector(
    state => state.experimentsInputStore.spikesortings
  )
  const { pages, data: items } = spikesortings || {}

  const { loading, handleDelete, handleEdit } = useExpInputsActions({
    query: querySortingExpInputs,
    modalId: MODALS_ID.NEW_SPIKESORTING_MODAL,
    storeAction: storeSortingsInputs,
    storedItems: items
  })

  return (
    <ExperimentsInputList
      loading={loading}
      items={items}
      pages={pages}
      onDelete={handleDelete}
      onEdit={handleEdit}
    />
  )
}

export default ExperimentsInputRuns
