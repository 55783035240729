import { useCallback, useEffect } from 'react'
import { getAllProbes } from '../../../endpoints/admin'
import { useDispatch, useSelector } from 'react-redux'
import useFilesQueryParams from '../../FilesTable/hooks/useFilesQueryParams'
import { addProbes, loadingProbes } from '../../../actions/probes'

function useGetAllProbes() {
  const { page, per_page } = useFilesQueryParams()
  const dispatch = useDispatch()

  const storeProbes = useCallback(data => dispatch(addProbes(data)), [dispatch])

  const { pages, loading } = useSelector(state => state.probesStore)

  const fetchProbes = useCallback(async () => {
    try {
      dispatch(loadingProbes())
      const data = await getAllProbes({ page, per_page })
      storeProbes(data)
    } catch (err) {
      dispatch(loadingProbes())
    }
  }, [page, per_page, storeProbes, dispatch])

  useEffect(() => {
    fetchProbes()
  }, [page, per_page])

  return { loading, pages, fetchProbes }
}

export default useGetAllProbes
