import { ofType } from 'redux-observable'
import { of } from 'rxjs'
import {
  switchMap,
  filter,
  mergeMap,
  pairwise,
  withLatestFrom,
  tap,
  map
} from 'rxjs/operators'
import {
  SAVE_CLUSTER_UNITS,
  SAVE_SIMILARITY_UNITS,
  REINIT_SIMILARITY_UNITS,
  REINIT_CLUSTER_UNITS
} from '../../actions'
import { PlotWaveforms } from '../../../widgets/WaveFormView'
import { fetchWaveformsEpic, fetchMeanWaveformsEpic } from './reusable.epics'
import { WAVEFORM_VIEWS_ENUM } from '../../../widgets/WaveFormView/consts'

const LAUNCH_SINGLE_SELECTION_REQ = 'LAUNCH_SINGLE_SELECTION_REQ'
const LAUNCH_MULTI_SELECTION_REQ = 'LAUNCH_MULTI_SELECTION_REQ'

export const saveWaveformsEpic = (action$, state$) => {
  const statePairs$ = state$.pipe(pairwise())

  return action$.pipe(
    ofType(
      SAVE_CLUSTER_UNITS,
      SAVE_SIMILARITY_UNITS,
      REINIT_CLUSTER_UNITS,
      REINIT_SIMILARITY_UNITS
    ),
    filter(action => action.payload),
    filter(_action => PlotWaveforms.renderPlot !== null),
    withLatestFrom(statePairs$),
    tap(([action, [_prevState, currentState]]) => {
      const similarityUnits = currentState.widgetsCache.units.similarity
      const clusterUnits = currentState.widgetsCache.units.cluster
      const units = clusterUnits.concat(similarityUnits)
      const removedState = !units.includes(action.payload.unit_id)
      //handle if is selected a unit that is already selected and is single mode selection
      const test = units.length === 1 && units[0] === action.payload.unit_id
      if (test) {
        PlotWaveforms.clearAllTraces()
      } else {
        PlotWaveforms.clearTraces(
          new Set(units),
          removedState,
          action.payload.unit_id
        )
      }

      PlotWaveforms.setUnits(units)

      return [action, [_prevState, currentState]]
    }),
    mergeMap(([action, [_prevState, currentState]]) => {
      const loading = currentState.widgetsCache.waveforms.loading
      const { cluster } = currentState.widgetsCache.units
      const { similarity } = _prevState.widgetsCache.units

      // this is done to not cancel request from cluster view if WF was not loaded and user selected similarity unit
      if (
        loading &&
        cluster.length === 1 &&
        similarity.length === 0 &&
        action.type === SAVE_SIMILARITY_UNITS
      ) {
        return multiple(action)
      }

      if (action.payload.multiple) {
        return multiple(action)
      } else {
        return single(action, currentState)
      }
    })
  )
}

const single = (action, currentState) => {
  return of(action).pipe(
    filter(action => {
      /*
            Here is the moment when the units were updated with the new cluster selected.
            This means if we are in the multi select state and if a cluster was deselected we should complete the observable, otherwise will be made a new request to a deselected cluster
        */
      const unit = action.payload.unit_id
      const units = currentState.widgetsCache.units.cluster
      const similarityUnits = currentState.widgetsCache.units.similarity

      return units.concat(similarityUnits).includes(unit)
    }),
    map(action => ({
      type: LAUNCH_SINGLE_SELECTION_REQ,
      payload: { ...action.payload, type: action.type }
    }))
  )
}

const multiple = action =>
  of({
    type: LAUNCH_MULTI_SELECTION_REQ,
    payload: {
      unit_id: action.payload.unit_id,
      type: action.type,
      multiple: true
    }
  })

export function saveSingleSelectionWaveforms(action$, state$) {
  return action$.pipe(
    ofType(LAUNCH_SINGLE_SELECTION_REQ),
    withLatestFrom(state$),
    filter(([action, state]) => {
      const type = action.payload.type
      const T = [SAVE_CLUSTER_UNITS, REINIT_CLUSTER_UNITS]
      const units = T.includes(type)
        ? state.widgetsCache.units.cluster
        : state.widgetsCache.units.similarity

      return units.length === 1
    }),
    switchMap(([action, state]) => {
      const waveformsView = state.widgetsCache.waveforms.view
      if (waveformsView === WAVEFORM_VIEWS_ENUM.MEAN) {
        return fetchMeanWaveformsEpic(action, state)
      }
      return fetchWaveformsEpic(action, state)
    })
  )
}

export function saveMultipleSelectionWaveforms(action$, state$) {
  return action$.pipe(
    ofType(LAUNCH_MULTI_SELECTION_REQ),
    withLatestFrom(state$),
    filter(([_action, state]) => {
      const type = _action.payload.type
      const T = [SAVE_CLUSTER_UNITS, REINIT_CLUSTER_UNITS]
      const units = T.includes(type)
        ? state.widgetsCache.units.cluster
        : state.widgetsCache.units.similarity
      return units.length > 0 && units.includes(_action.payload.unit_id)
    }),
    mergeMap(([action, state]) => {
      const waveformsView = state.widgetsCache.waveforms.view
      if (waveformsView === WAVEFORM_VIEWS_ENUM.MEAN) {
        return fetchMeanWaveformsEpic(action, state)
      }
      return fetchWaveformsEpic(action, state)
    })
  )
}
