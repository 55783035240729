import React from 'react'

const WindowsIcon = props => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#WindowsIcon)">
        <path
          d="M0.194336 14.1467L40.9025 8.60311L40.92 47.8693L0.231301 48.1008L0.194336 14.1471V14.1467ZM40.8831 52.393L40.9142 91.6934L0.225853 86.0992L0.223519 52.1296L40.8827 52.393H40.8831ZM45.8177 7.87743L99.7928 0V47.3696L45.8177 47.7977V7.87782V7.87743ZM99.8052 52.7626L99.7924 99.9187L45.8173 92.3008L45.7418 52.6743L99.8052 52.7626Z"
          fill="#00ADEF"
        />
      </g>
      <defs>
        <clipPath id="WindowsIcon">
          <rect
            width="99.6109"
            height="100"
            fill="white"
            transform="translate(0.194336)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WindowsIcon
