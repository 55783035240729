import React from 'react'

const AppleIcon = props => {
  return (
    <svg
      width="86"
      height="100"
      viewBox="0 0 86 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#AppleIcon)">
        <path
          d="M71.3951 52.7851C71.3467 44.5978 75.0819 38.4274 82.6249 33.8775C78.4062 27.8751 72.0237 24.5738 63.6103 23.9376C55.6442 23.3133 46.9287 28.5474 43.7374 28.5474C40.3648 28.5474 32.6526 24.1537 26.5843 24.1537C14.061 24.3457 0.751953 34.0696 0.751953 53.8535C0.751953 59.6999 1.8278 65.7383 3.97949 71.9568C6.85647 80.1441 17.2281 100.204 28.047 99.8799C33.7042 99.7479 37.7054 95.8943 45.0671 95.8943C52.2112 95.8943 55.9102 99.8799 62.2202 99.8799C73.1358 99.7239 82.5162 81.4886 85.2481 73.2773C70.6093 66.4226 71.3951 53.2053 71.3951 52.7851ZM58.6904 16.1705C64.8191 8.94358 64.2631 2.36495 64.0817 0C58.6663 0.312125 52.4046 3.66146 48.8386 7.77911C44.9099 12.1969 42.6011 17.6591 43.0967 23.8175C48.9474 24.2617 54.2903 21.2725 58.6904 16.1705Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="AppleIcon">
          <rect
            width="84.4961"
            height="100"
            fill="white"
            transform="translate(0.751953)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AppleIcon
