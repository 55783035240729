import { useCallback, useState } from 'react'

function useConfirmationModal() {
  const [isOpened, setOpen] = useState(false)
  const toggleDeleteModal = useCallback(() => setOpen(open => !open), [])

  return {
    toggleDeleteModal,
    isOpened
  }
}

export default useConfirmationModal
