function useTermsConditions() {
  return [
    {
      title: '1. Use of the Service Offerings.',
      items: [
        '1.1 Generally. You may access and use the Service Offerings in accordance with this Agreement. Service Level Agreements and Service Terms apply to certain Service Offerings. You will comply with the terms of this Agreement and all laws, rules and regulations applicable to your use of the Service Offerings.',
        '1.2 Your Account. To access the Services, you must have an DBC account associated with a valid email address and a valid form of payment. Unless explicitly permitted by the Service Terms, you will only create one account per email address.',
        '1.3 Third-Party Content. Third-Party Content may be used by you at your election. Third-Party Content is governed by this Agreement and, if applicable, separate terms and conditions accompanying such Third-Party Content, which terms and conditions may include separate fees and charges.'
      ]
    },
    {
      title: '2. Changes.',
      items: [
        '2.1 To the Services. We may change or discontinue any of the Services from time to time. We will provide you at least 12 months’ prior notice if we discontinue material functionality of a Service that you are using, or materially alter a customer-facing API that you are using in a backwards-incompatible fashion, except that this notice will not be required if the 12-month notice period (a) would pose a security or intellectual property issue to us or the Services, (b) is economically or technically burdensome, or (c) would cause us to violate legal requirements.',
        '2.2 To the Service Level Agreements. We may change, discontinue or add Service Level Agreements from time to time in accordance with Section 12.'
      ]
    },
    {
      title: '3. Security and Data Privacy.',
      items: [
        '3.1 DBC Security. Without limiting Section 10 or your obligations under Section 4.2, we will implement reasonable and appropriate measures designed to help you secure Your Content against accidental or unlawful loss, access or disclosure.',
        '3.2 Data Privacy. You may specify the DBC regions in which Your Content will be stored. You consent to the storage of Your Content in, and transfer of Your Content into, the DBC regions you select. We will not access or use Your Content except as necessary to maintain or provide the Service Offerings, or as necessary to comply with the law or a binding order of a governmental body. We will not (a) disclose Your Content to any government or third party or (b) subject to Section 3.3, move Your Content from the DBC regions selected by you; except in each case as necessary to comply with the law or a binding order of a governmental body. Unless it would violate the law or a binding order of a governmental body, we will give you notice of any legal requirement or order referred to in this Section 3.2. We will only use your Account Information in accordance with the Privacy Notice, and you consent to such usage. The Privacy Notice does not apply to Your Content.'
      ]
    },
    {
      title: '4. Your Responsibilities.',
      items: [
        '4.1 Your Accounts. Except to the extent caused by our breach of this Agreement, (a) you are responsible for all activities that occur under your account, regardless of whether the activities are authorized by you or undertaken by you, your employees or a third party (including your contractors, agents or End Users), and (b) we and our affiliates are not responsible for unauthorized access to your account.',
        '4.2 Your Content. You will ensure that Your Content and your and End Users’ use of Your Content or the Service Offerings will not violate any of the Policies or any applicable law. You are solely responsible for the development, content, operation, maintenance, and use of Your Content.',
        '4.3 Your Security and Backup. You are responsible for properly configuring and using the Service Offerings and otherwise taking appropriate action to secure, protect and backup your accounts and Your Content in a manner that will provide appropriate security and protection, which might include use of encryption to protect Your Content from unauthorized access and routinely archiving Your Content.',
        '4.4 Log-In Credentials and Account Keys. DBC log-in credentials and private keys generated by the Services are for your internal use only and you will not sell, transfer or sublicense them to any other entity or person, except that you may disclose your private key to your agents and subcontractors performing work on your behalf.',
        '4.5 End Users. You will be deemed to have taken any action that you permit, assist or facilitate any person or entity to take related to this Agreement, Your Content or use of the Service Offerings. You are responsible for End Users’ use of Your Content and the Service Offerings. You will ensure that all End Users comply with your obligations under this Agreement and that the terms of your agreement with each End User are consistent with this Agreement. If you become aware of any violation of your obligations under this Agreement caused by an End User, you will immediately suspend access to Your Content and the Service Offerings by such End User. We do not provide any support or services to End Users unless we have a separate agreement with you or an End User obligating us to provide such support or services.'
      ]
    }
  ]
}

export default useTermsConditions
