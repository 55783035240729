import useEditRecord from '../../NewRecordingView/hooks/useEditRecord'
import { useDispatch, useSelector } from 'react-redux'
import { MODALS_ID } from '../../../utils/consts'
import { useCallback, useState } from 'react'
import { editFile } from '../../../actions/files'
import { addModal, closeModal } from '../../../actions/modals'

function useRenameFileModal() {
  const { onEdit, loading } = useEditRecord()
  const dispatch = useDispatch()
  const modalID = MODALS_ID.RENAME_FILE_NAME
  const modalState = useSelector(state => state.modals?.get(modalID))
  const { id: recordId, type: recordType } = modalState?.args || {}
  const [value, setValue] = useState('')
  const updateFile = useCallback(data => dispatch(editFile(data)), [dispatch])

  const toggleModal = useCallback(
    args => {
      const { name } = args || {}
      if (modalState) return dispatch(closeModal({ id: modalID }))
      setValue(name)
      dispatch(
        addModal({
          id: modalID,
          args
        })
      )
    },
    [dispatch, modalID, modalState]
  )

  const handleChange = useCallback(e => setValue(e.target.value), [])

  const handleSubmit = useCallback(async () => {
    const newName = value
      .replaceAll(/[@%&*!#$~+={}()|?^<>:,;]/g, '')
      .replaceAll(/[\s]/g, '_')
    const data = { id: recordId, name: newName }
    onEdit({
      data: {
        ...data,
        type: recordType
      },
      onSuccess: () => {
        updateFile(data)
        toggleModal()
      }
    })
  }, [recordId, value, recordType, onEdit, updateFile, toggleModal])

  const handleKeyPress = useCallback(
    e => {
      if (e.key?.toLowerCase() === 'enter' && value) {
        handleSubmit()
      }
    },
    [value, handleSubmit]
  )

  return {
    toggleModal,
    onChange: handleChange,
    onSubmit: handleSubmit,
    onKeyPress: handleKeyPress,
    isOpened: Boolean(modalState),
    loading,
    value
  }
}

export default useRenameFileModal
