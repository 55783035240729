import React from 'react'
import CustomModal from '../../../../../../CustomModal'
import { Grid, makeStyles, darken } from '@material-ui/core'
import { useProbeDesignModal } from '../hooks/useProbeDesignModal'
import ActionButton from '../../../../../../ActionButton'
import ChannelsView from '../../../../../../TimeseriesView/NewLeftPanel/components/MappingTab/components/ChannelsView/ChannelsView'
import { LoadingSpinner } from '../../../../../../../UI'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 15,
    marginLeft: theme.spacing(1),
    fontWeight: 600
  },
  body: {
    fontSize: 16
  },
  actionButtonsContainer: {
    display: 'flex'
  },
  downloadContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  downloadButton: {
    marginLeft: '2px !important',
    width: 'fit-content',
    backgroundColor: '#21B685',
    '&:hover': {
      backgroundColor: darken('#21B685', 0.3)
    }
  }
}))

export default function ProbeDesignModal({ prbFile = null }) {
  const {
    isOpened,
    onClose,
    probeFileName,
    loading,
    bData,
    showMaping,
    handleDownloadMapFile,
    isDownloading,
    canDownloadMatFile
  } = useProbeDesignModal(prbFile)
  const classes = useStyles()

  if (!showMaping) return null

  const ActionButtons = (
    <div className={classes.actionButtonsContainer}>
      {canDownloadMatFile && (
        <ActionButton
          className={classes.downloadButton}
          disabled={isDownloading}
          loading={isDownloading}
          label="Download (.mat)"
          onClick={handleDownloadMapFile}
        />
      )}
      <ActionButton label="Close" onClick={onClose} />
    </div>
  )

  return (
    <CustomModal
      title="Probe Design"
      open={isOpened}
      onClose={onClose}
      hideCloseButton
      renderActionButton={ActionButtons}
      maxWidth="550px"
    >
      <Content loading={loading} bData={bData} fileName={probeFileName} />
    </CustomModal>
  )
}

const Content = ({ loading, bData, fileName }) => {
  const { selectedChannels, biochipsData, multigroup, draw } = bData
  if (loading) return <LoadingSpinner />

  return (
    <Grid direction="column" container>
      <ChannelsView
        data={biochipsData}
        multigroup={multigroup}
        selectedChannels={selectedChannels}
        draw={draw}
        probeFileName={fileName}
        readOnly={true}
      />
    </Grid>
  )
}
