import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { downloadImpedance } from '../../../../../endpoints/admin'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import { NOTIFICATION } from '../../../../../utils/consts'
import useToggleModal from '../../../../../hooks/useToggleModal'
import { MODALS_ID } from '../../../../../utils/consts'
import { setCurrentProbe } from '../../../../../actions/probes'

export default function useProbeActions({ id }) {
  const [loading, setLoading] = useState(false)
  const addNotification = useNotification()
  const { toggle: toggleModal } = useToggleModal(MODALS_ID.PROBE_UPLOAD_MODAL)
  const dispatch = useDispatch()
  const downloadImpedanceFile = useCallback(async () => {
    try {
      setLoading(true)
      const res = await downloadImpedance({ id })
      if (res?.url) window.open(res.url)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [id, addNotification])

  const toggleEditProbe = useCallback(() => {
    dispatch(setCurrentProbe(id))
    toggleModal()
  }, [toggleModal, dispatch, id])
  return { downloadImpedanceFile, loading, toggleEditProbe }
}
