import React, { memo, Suspense } from 'react'
import { Tab, Tabs } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import TabBox from '../TimeseriesView/NewLeftPanel/components/TabBox'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  tabs: {
    width: '100%',
    minHeight: 40,
    marginBottom: 25
  },
  flexContainer: {
    borderBottom: `1px solid ${theme.palette.colors.neutral['7']}`
  },
  tabRoot: {
    flex: 1,
    width: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column'
  },
  tabsItem: () => {
    const color = theme.palette.colors.customColor(
      theme.palette.colors.neutral['3'],
      theme.palette.colors.neutral['7']
    )
    return {
      minWidth: 65,
      minHeight: 40,
      paddingLeft: 0,
      paddingRight: 0,
      color,
      textTransform: 'initial',
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        '&:hover': {
          color: theme.palette.primary.main
        }
      },
      '&:hover': {
        color,
        backgroundColor: theme.palette.colors.black_07
      }
    }
  },
  tabsItemText: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600
  },
  indicator: ({ tabsLen }) => ({
    width: `calc(100%/${tabsLen})!important`,
    borderRadius: 2,
    zIndex: 2,
    backgroundColor: theme.palette.primary.main
  })
}))

const TabsContext = ({
  selectedTab,
  handleChangeTabs,
  customClasses = { header: null },
  tabs,
  variant = 'fullWidth'
}) => {
  const classes = useStyles({
    tabsLen: tabs.filter(tab => tab !== null).length
  })

  return (
    <React.Fragment>
      <Tabs
        value={selectedTab}
        textColor="primary"
        onChange={handleChangeTabs}
        variant={variant}
        aria-label="tabs"
        className={cn(classes.tabs, customClasses.header)}
        classes={{
          flexContainer: classes.flexContainer,
          indicator: cn(classes.indicator, customClasses?.indicator)
        }}
      >
        {tabs.map((tab, index) => {
          const isIconOnly = tab?.icon

          const tabProps = {
            key: `tab-key-${index}`,
            className: classes.tabsItem,
            value: index,
            onClick: tab?.onClick,
            id: `tab-index-${index}`
          }

          const tabComponent = <Tab {...tabProps} />

          if (isIconOnly) {
            return React.cloneElement(tabComponent, {
              icon: tab.icon,
              title: tab.label
            })
          }

          return React.cloneElement(tabComponent, {
            label: (
              <Typography variant="h5" className={classes.tabsItemText}>
                {tab.label}
              </Typography>
            )
          })
        })}
      </Tabs>
      <ContentTabs tabs={tabs} selectedTab={selectedTab} />
    </React.Fragment>
  )
}

export const ContentTabs = memo(({ tabs, selectedTab }) => {
  const classes = useStyles()
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {tabs.map((tab, index) => {
        return (
          <TabBox
            key={`content-tab-${index}`}
            value={selectedTab}
            index={index}
            className={classes.tabRoot}
          >
            {tab.content}
          </TabBox>
        )
      })}
    </Suspense>
  )
})

export default memo(TabsContext)
