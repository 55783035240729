import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  yAxis: {
    display: 'flex',
    flexDirection: 'column'
  },
  cell: ({ height }) => ({
    display: 'flex',
    height,
    justifyContent: 'center',
    alignItems: 'center'
  })
})

export const CrossCorrelogramYAxis = ({ data, height }) => {
  const classes = useStyles({ height })

  return (
    <div className={classes.yAxis}>
      {data.map(u => {
        return (
          <div key={`YAxis-${u}`} className={classes.cell}>
            {u}
          </div>
        )
      })}
    </div>
  )
}
