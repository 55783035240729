import React, { useState } from 'react'
import { IconButton, Typography, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CloseRounded } from '@material-ui/icons'
import CustomCheckbox from '../../../FormComponents/CustomCheckbox/CustomCheckbox'
import ActionButton from '../../../ActionButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import noPreview from '../../../../imgs/nopreview.png'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  row: {
    width: '100%',
    '& td': {
      padding: '0 12px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.15)'
    },
    '& td:first-of-type': {
      padding: 0
    },
    '&:last-of-type td': {
      borderBottom: 'none'
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.colors.backgrounds.files
    }
  },
  rowCell: {
    display: 'table-cell'
  },

  avatar: {
    width: 24,
    height: 24,
    borderRadius: 24
  },
  label: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.colors.neutral.black
  },
  action: {
    width: 110,
    minWidth: 'auto',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 10,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  superadmin: {
    width: 110,
    minWidth: 'auto',
    borderRadius: 4,
    backgroundColor: theme.palette.colors.status.error,
    '&:hover': {
      backgroundColor: theme.palette.colors.status.error
    }
  },
  actionAdmin: {
    backgroundColor: theme.palette.secondary[100],
    '&:hover': {
      backgroundColor: theme.palette.secondary[100]
    }
  },
  menu: {
    width: 110
  },
  menuItem: {
    fontSize: 12,
    fontWeight: 600
  },
  removeBtn: {
    marginRight: 0,
    display: 'block',
    marginLeft: 'auto',
    '& svg': {
      color: theme.palette.primary.main
    }
  }
}))

export default function MembersListItem({
  id,
  username,
  image,
  email,
  manager,
  admin,
  isSelected = false,
  isRoleSwitched = false,
  onSelect,
  handleDelete,
  switchRole
}) {
  const classes = useStyles()
  const img = image || noPreview

  const isManager = isRoleSwitched ? !Boolean(manager) : Boolean(manager)

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSwitchRole = role => {
    handleClose()
    if (isManager == role) return
    switchRole(id, role)
  }

  return (
    <tr className={classes.row}>
      <td>
        <IconButton onClick={() => onSelect(id, true)}>
          <CustomCheckbox
            size={24}
            onChange={() => onSelect(id, true)}
            checked={isSelected}
          />
        </IconButton>
      </td>
      <td>
        <img src={img} alt="avatar" className={classes.avatar} />
      </td>
      <td>
        <Typography className={classes.label}>{username}</Typography>
      </td>
      <td>
        {admin ? (
          <ActionButton
            className={classes.superadmin}
            label="Superadmin"
            customStyles={{
              height: 30,
              fontWeight: 400,
              padding: 0
            }}
          />
        ) : (
          <>
            <ActionButton
              className={cn(classes.action, {
                [classes.actionAdmin]: isManager
              })}
              customStyles={{
                height: 30,
                fontWeight: 400
              }}
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              label={isManager ? 'Admin' : 'User'}
            />

            <Menu
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              classes={{
                paper: classes.menu
              }}
            >
              <MenuItem
                classes={{ root: classes.menuItem }}
                onClick={() => handleSwitchRole(1)}
              >
                Admin
              </MenuItem>
              <MenuItem
                classes={{ root: classes.menuItem }}
                onClick={() => handleSwitchRole(0)}
              >
                User
              </MenuItem>
            </Menu>
          </>
        )}
      </td>
      <td>
        <Typography className={classes.label}>{email}</Typography>
      </td>
      <td>
        <IconButton
          className={classes.removeBtn}
          onClick={() => handleDelete(id)}
        >
          <CloseRounded />
        </IconButton>
      </td>
    </tr>
  )
}
