import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Row } from './components'
import CorrelogramPlot from './CorrelogramPlot'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  plotsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  }
})

export const CrossCorrelogram = ({
  data,
  recordingId,
  width,
  height,
  PADDING
}) => {
  const classes = useStyles()

  const sorting = useSelector(state => state.recordingStore.selectedSorting)

  const widthFromPadding = PADDING * 2

  return (
    <div className={classes.plotsWrapper}>
      {data.map((arr, idx) => {
        return (
          <Row
            data={arr}
            recordingId={recordingId}
            key={idx}
            width={width - 10}
            height={height}
            PADDING={PADDING}
          >
            <CorrelogramPlot
              width={width - widthFromPadding}
              height={height - widthFromPadding}
              recordingId={recordingId}
              sortingId={sorting?.id}
            />
          </Row>
        )
      })}
    </div>
  )
}
