import { useEffect, useState } from 'react'
import { Poll } from '../../../utils/utils'
import { getOperationProgress } from '../../../endpoints/admin'
import { ROUTES } from '../../../utils/consts'

function useProgressHandler({
  initial_progress,
  status_int,
  status,
  operation,
  id
}) {
  const [progress, setProgress] = useState(initial_progress)
  const [file_status, setStatus] = useState({ status_int, status })
  const inQueue = file_status?.status_int === 4
  const inProgressOperation = Boolean(operation) && progress < 100
  const validateRequest = res => res?.progress.replace('%', '') < 100
  const isFilesPage = window.location.pathname.includes(ROUTES.FILES)

  useEffect(() => {
    setProgress(initial_progress)
    setStatus({ status_int, status })
  }, [initial_progress, status_int, status])

  const POLL_INTERVAL = 10000

  useEffect(() => {
    const poll = new Poll({
      fn: getOperationProgress,
      params: { id },
      cb: ({ data }) => {
        const { status, status_int, progress } = data || {}
        const pr = progress?.replace('%', '')
        if (pr) {
          setProgress(pr)
          setStatus({ status_int, status })
        }
        if (pr == 100) setStatus({ status_int: 2, status: 'Uploaded' })
      },
      validateRequest,
      interval: POLL_INTERVAL
    })

    if (inProgressOperation && isFilesPage) poll.start()

    return () => poll.stop()
    //eslint-disable-next-line
  }, [inProgressOperation, isFilesPage, id])

  return {
    progress,
    inProgressOperation,
    inQueue,
    status: file_status.status,
    status_int: file_status.status_int
  }
}

export default useProgressHandler
