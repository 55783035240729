import { useCallback, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { setMainWindowRange } from '../../../redux/actions'

function UseSection({ isNeuronal }) {
  const [isAccordionOpen, setAccordionOpen] = useState(true)
  const dispatch = useDispatch()
  const setMainRange = useCallback(
    range => dispatch(setMainWindowRange(range)),
    [dispatch]
  )

  const toggleAccordionOpen = useCallback(() => {
    setAccordionOpen(prev => !prev)
  }, [setAccordionOpen])

  const { mainRange, neuronalRanges, nonNeuronalRanges, childWindows } =
    useSelector(state => state.timeSeriesLeftPanel, shallowEqual)

  return {
    mainRange,
    isAccordionOpen,
    toggleAccordionOpen,
    childWindows,
    setMainRange,
    ranges: isNeuronal ? neuronalRanges : nonNeuronalRanges
  }
}

export default UseSection
