import { makeStyles } from '@material-ui/core/styles'
import { Close } from '@material-ui/icons'
import React from 'react'
import { Dialog, IconButton, Slide } from '@material-ui/core'
import cn from 'classnames'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import ActionButton from '../ActionButton'
import { TABLE_SCROLLBAR_SIZE } from '../../utils/styles/CONSTANTS'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiBackdrop-root': {
      background: theme.palette.colors.backdrop
    }
  },
  paper: ({ maxWidth }) => ({
    borderRadius: 8,
    maxWidth: maxWidth || 600,
    width: '100%',
    margin: 20,
    background: theme.palette.colors.backgrounds.modals
  }),
  header: ({ type, noBorders }) => {
    const borderColor = theme.palette.colors.customColor(
      theme.palette.colors.status[type] || theme.palette.colors.neutral['7'],
      theme.palette.colors.neutral['4']
    )
    const backgroundColor = theme.palette.colors.customColor(
      theme.notifications[type],
      'transparent'
    )
    const color =
      theme.palette.colors.status[type] || theme.palette.colors.neutral.black
    return {
      minHeight: 56,
      padding: '10px 24px',
      backgroundColor,
      color,
      borderBottom: noBorders ? 'none' : `1px solid ${borderColor}`,
      '& *[fill]': {
        fill: color
      }
    }
  },
  modalRoot: {
    position: 'relative',
    minHeight: 'auto',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    overflowY:'scroll',
    '&::-webkit-scrollbar': {
      width: TABLE_SCROLLBAR_SIZE,
      height: TABLE_SCROLLBAR_SIZE,
      zIndex: 1
    },
    '&::-webkit-scrollbar-track': { background: 'transparent' },
    '&::-webkit-scrollbar-corner': { background: 'transparent' },
    '&::-webkit-scrollbar-thumb': { background: theme.palette.primary.main }
  },
  title: {
    fontSize: 18,
    lineHeight: '20px',
    fontWeight: 600,
    '& ~ svg': {
      marginRight: 8
    }
  },
  body: {
    padding: 24
  },
  closeBtn: {
    padding: 5,
    color: theme.palette.colors.black1,
    '&:hover': {
      color: 'initial'
    }
  },
  closeText: {
    fontSize: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: 30
    }
  },

  footer: ({ type, noBorders }) => {
    const borderColor = theme.palette.colors.customColor(
      theme.palette.colors.neutral['7'],
      theme.palette.colors.neutral['4']
    )
    return {
      minHeight: 70,
      padding: '15px 24px',
      borderTop: noBorders ? 'none' : `1px solid ${borderColor}`,
      '& div': {
        '&:last-child': {
          '& button': {
            marginLeft: 15,
            backgroundColor: type ? theme.palette.colors.status[type] : 'none',
            width: 'auto'
          }
        }
      }
    }
  },
  cancelButton: {
    backgroundColor: 'transparent',
    width: 'auto',
    '& p': {
      color: theme.palette.colors.neutral.default
    },
    '&:hover': {
      backgroundColor: theme.palette.colors.neutral['8']
    }
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function CustomModal(props) {
  const {
    onClose = () => null,
    open,
    noBorders = false,
    hideCloseButton = false,
    title = null,
    children,
    classNames,
    fullScreen,
    TransitionComponent = Transition,
    maxWidth,
    disableBackdropClick,
    disableEscapeKeyDown,
    customPaperClass,
    customBodyClass,
    customScrollPaper,
    withFooter = true,
    headerIcon = null,
    type = null,
    renderActionButton = null,
    leftButton = null,
    id = null,
    classes: overideClasses = {}
  } = props
  const classes = useStyles({ maxWidth, type, noBorders })

  return (
    <Dialog
      TransitionComponent={TransitionComponent}
      fullScreen={fullScreen}
      onClose={onClose}
      open={open}
      disableBackdropClick={disableBackdropClick}
      disableEscapeKeyDown={disableEscapeKeyDown}
      classes={{
        root: classes.root,
        paper: cn(customPaperClass, classes.paper),
        scrollPaper: customScrollPaper
      }}
      id={id}
    >
      <div className={cn(classNames, classes.modalRoot)}>
        <Grid
          container
          wrap="nowrap"
          direction="row"
          justify="space-between"
          alignItems="center"
          className={cn(classes.header, overideClasses?.header)}
        >
          <Grid
            container
            wrap="nowrap"
            direction="row-reverse"
            justify="flex-end"
            alignItems="center"
          >
            <Typography className={cn(classes.title, overideClasses?.title)}>
              {title}
            </Typography>
            {headerIcon}
          </Grid>
          <IconButton className={classes.closeBtn} onClick={onClose}>
            <Close className={classes.closeText} />
          </IconButton>
        </Grid>
        <div className={cn(classes.body, customBodyClass)}>{children}</div>
        {withFooter && (
          <Grid
            container
            wrap="nowrap"
            justify="flex-end"
            alignItems="center"
            className={classes.footer}
          >
            {leftButton && leftButton}
            {!hideCloseButton && (
              <ActionButton
                label="Close"
                onClick={onClose}
                className={classes.cancelButton}
              />
            )}
            {renderActionButton}
          </Grid>
        )}
      </div>
    </Dialog>
  )
}

export default CustomModal
