import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeLeftPanelWidth, resetData } from '../redux/actions'
import { LeftPanelMaxWidth } from '../index'

function useLeftPanelController({ toggleLeftPanelMode }) {
  const [selectedTab, setTab] = useState(0)
  const dispatch = useDispatch()
  const leftPanelWidth = useSelector(
    state => state.timeSeriesLeftPanel.leftPanelWidth
  )

  const handleChangeTabs = useCallback((event, newValue) => {
    setTab(newValue)
  }, [])

  const handleSetMaxWidth = useCallback(
    width => dispatch(changeLeftPanelWidth(width)),
    [dispatch]
  )

  const toggleDrawer = useCallback(() => {
    toggleLeftPanelMode(selectedTab)
  }, [selectedTab, toggleLeftPanelMode])

  useEffect(() => {
    LeftPanelMaxWidth.update(leftPanelWidth)
  }, [leftPanelWidth])

  const reset = useCallback(
    options => {
      dispatch(resetData(options))
    },
    [dispatch]
  )

  useEffect(() => {
    handleSetMaxWidth(290)
    return () => {
      reset(['customGroups'])
    }
  }, [])

  return {
    selectedTab,
    leftPanelWidth,
    handleChangeTabs,
    handleSetMaxWidth,
    toggleDrawer
  }
}

export default useLeftPanelController
