import React, { useCallback, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { setDarkMode } from '../../actions'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Checkbox,
  IconButton,
  Grid,
  AppBar,
  Typography
} from '@material-ui/core'
import {
  Brightness3,
  Menu,
  WbSunny,
  HelpOutline,
  Undo
} from '@material-ui/icons'
import { Link, useLocation } from 'react-router-dom'
import { MAIN_APPBAR_HEIGHT } from '../../utils/styles'
import cn from 'classnames'
import Logo from '../../imgs/Logo'
import Storage from './components/Storage'
import MenuDrawer from './components/MenuDrawer'
import { DATA_MANIPULATION_MODE, MODALS_ID } from '../../utils/consts'
import useToggleModal from '../../hooks/useToggleModal'
import useSteps from '../ToursWrapper/hooks/useSteps'
import {
  resetDataManipulationParams,
  toggleDataManipulationMode
} from '../../containers/NewTimeSeries/redux/actions'
import ActionButton from '../ActionButton'
import { isNotNull } from '../../utils/utils'
import useLimitedAccess from '../../hooks/useLimitedAccess'
import useAppHeaderTour from './hooks/useAppHeaderTour'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    overflow: 'hidden',
    height: MAIN_APPBAR_HEIGHT
  },
  collapsedHead: {
    height: 0
  },
  app: {
    padding: '0 40px',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    background: theme.palette.colors.backgrounds.dp_24,
    height: MAIN_APPBAR_HEIGHT
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '20px',
    color: theme.palette.colors.white
  },
  button: {
    fontWeight: 600,
    color: theme.palette.colors.white,
    textTransform: 'none',
    size: 14,
    padding: theme.spacing(3),
    textDecoration: 'none'
  },
  menuBtn: {
    padding: 0,
    marginRight: 16,
    color: theme.palette.colors.white
  },
  link: {
    textDecoration: 'none'
  },
  handleDarkMode: {
    marginLeft: 10,
    padding: 0
  },
  helpIcon: {
    cursor: 'pointer',
    color: theme.palette.colors.white
  },
  icon: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 30,
    '& svg': {
      fontSize: 18
    }
  },

  //
  closeButton: {
    background: 'transparent'
  },
  undoButton: {
    width: 'auto',
    marginLeft: 16,

    '&, & p': {
      color: theme.palette.colors.neutral[1]
    },
    '&:hover, &:hover p': {
      color: theme.palette.colors.white
    }
  },
  successBtn: {
    width: 'auto',
    marginLeft: 16,

    '& p': {
      textTransform: 'capitalize'
    }
  },
  disabledBtn: () => ({
    '&[disabled]': {
      backgroundColor: theme.palette.colors.neutral.default,
      '& p': {
        opacity: 0.5
      }
    }
  }),
  premiumBtn: {
    '&, & p': {
      color: theme.palette.colors.darkBlue
    },
    '&:hover, &:hover p': {
      backgroundColor: theme.palette.colors.white
    },
    backgroundColor: theme.palette.colors.white,
    width: 'fit-content',
    marginRight: 30,
    minWidth: 119,
    padding: '0px 12px'
  }
}))

const AppHeader = ({ onSetDarkMode, darkMode }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const isTS = pathname.includes('timeseriesForRecording')

  useEffect(() => {
    onSetDarkMode(Storage.getItem('darkMode'))

    //eslint-disable-next-line
  }, [])

  const fullScreenMode = useSelector(
    state => state.timeSeriesLeftPanel.fullScreenMode
  )

  // time serie flag of data manipulation mode
  const dataManipulationMode = useSelector(state => state.dataManipulation.mode)

  return (
    <div
      className={cn(classes.root, {
        [classes.collapsedHead]: fullScreenMode
      })}
    >
      {dataManipulationMode && isTS ? (
        <DataManipulationHeader mode={dataManipulationMode} />
      ) : (
        <DefaultHeader onSetDarkMode={onSetDarkMode} darkMode={darkMode} />
      )}
    </div>
  )
}

const DataManipulationHeader = ({ mode }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()
  const { toggle: toggleModal } = useToggleModal(
    MODALS_ID.DATA_MANIPULATION_MODAL
  )

  const selectSliceCutMode = useCallback(
    mode => dispatch(toggleDataManipulationMode(mode)),
    [dispatch]
  )
  const { SLICE } = DATA_MANIPULATION_MODE
  const title = `${mode === SLICE ? 'Slice' : 'Cut'} Dataset`

  const dataManipiulationParams = useSelector(state => state.dataManipulation)
  const { to, from } = dataManipiulationParams[mode] || {}

  const undo = useCallback(
    () => dispatch(resetDataManipulationParams()),
    [dispatch]
  )

  const isSuccess = isNotNull(from) && to
  const isUndo = mode === SLICE ? isSuccess : isNotNull(from) || to

  return (
    <AppBar position="relative" className={classes.app}>
      <Grid container justify="flex-start" alignItems="center">
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <Grid container justify="flex-end" wrap="nowrap">
        <ActionButton
          label="Close"
          onClick={() => selectSliceCutMode(null)}
          className={classes.closeButton}
          customStyles={{
            width: 'auto'
          }}
        />
        {isUndo && (
          <ActionButton
            label="Undo"
            leftAdornment={<Undo />}
            onClick={undo}
            className={classes.undoButton}
            customStyles={{
              backgroundColor: theme.palette.colors.white
            }}
          />
        )}
        <ActionButton
          label={title}
          onClick={toggleModal}
          className={classes.successBtn}
          disabled={!isSuccess}
          customStyles={{
            backgroundColor: theme.palette.action.secondary
          }}
          overideClasses={{
            disabled: classes.disabledBtn
          }}
        />
      </Grid>
    </AppBar>
  )
}

const DefaultHeader = ({ onSetDarkMode, darkMode }) => {
  const classes = useStyles()
  const { steps } = useSteps()

  const modalID = MODALS_ID.MENU_DRAWER
  const { toggle: toggleDrawer } = useToggleModal(modalID)
  const { toggle: togglePremiumModal } = useToggleModal(
    MODALS_ID.GET_PREMIUM_VERSION_MODAL
  )

  const { handleStartTour } = useAppHeaderTour()
  const handleDarkMode = () => {
    onSetDarkMode()
    Storage.setItem('darkMode', !darkMode)
  }
  const { isLimited } = useLimitedAccess()
  const showSteps = !isLimited && steps
  return (
    <AppBar position="relative" className={classes.app}>
      <MenuDrawer />
      <Grid container justify="flex-start" alignItems="center">
        <IconButton className={classes.menuBtn} onClick={toggleDrawer}>
          <Menu />
        </IconButton>
        <Link to="/files" className={classes.link}>
          <Logo />
        </Link>
      </Grid>
      {isLimited && (
        <ActionButton
          className={classes.premiumBtn}
          label="Get premium"
          onClick={togglePremiumModal}
          id="get_premium"
        />
      )}
      {showSteps && (
        <HelpOutline className={classes.helpIcon} onClick={handleStartTour} />
      )}
      <Checkbox
        checked={darkMode}
        onClick={handleDarkMode}
        disableRipple
        className={classes.handleDarkMode}
        icon={
          <div className={classes.icon}>
            <Brightness3 />
          </div>
        }
        checkedIcon={
          <div className={classes.icon}>
            <WbSunny />
          </div>
        }
      />
    </AppBar>
  )
}

const mapStateToProps = state => ({
  darkMode: state.darkMode
})

const mapDispatchToProps = dispatch => ({
  onSetDarkMode: value => dispatch(setDarkMode(value))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
