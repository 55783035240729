import axios from 'axios'
import config from '../utils/config'
import { resolveError } from '../utils/utils'

export const changePassword = ({ password, old_password }) =>
  axios
    .post(`${config.apiUrl}/change_password`, { password, old_password })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const editProfile = data =>
  axios
    .post(`${config.apiUrl}/user/edit`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const selectUserOrganisation = data =>
  axios
    .post(`${config.apiUrl}/users/organisations/select`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const inviteUsersToOrganisation = data =>
  axios
    .post(`${config.apiUrl}/users/organisations/invite`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const editOrganisation = data =>
  axios
    .put(`${config.apiUrl}/organisations`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getOrganisationMembers = ({
  search,
  page = 1,
  limit = 10,
  organisation_id
}) =>
  axios
    .get(`${config.apiUrl}/users/organisations`, {
      params: { search, page, limit, organisation_id }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const deleteUserInOrganisation = data =>
  axios
    .delete(`${config.apiUrl}/users/organisations`, { data })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const switchOnboardingStatus = data =>
  axios
    .post(`${config.apiUrl}/user/onboarding`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getOnboardingStatus = params =>
  axios
    .get(`${config.apiUrl}/user/onboarding`, { params })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const switchUsersRoles = data =>
  axios
    .post(`${config.apiUrl}/users/organisations/switch`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getAllRecords = ({
  keyword,
  page = 1,
  per_page = 25,
  folder_id = null
}) =>
  axios
    .get(`${config.apiUrl}/folder/getmixed`, {
      params: { keyword, page, per_page, folder_id }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const deleteRecordById = formData =>
  axios
    .delete(`${config.apiUrl}/record/delete`, { data: formData })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const deleteRecordByIdAndType = formData =>
  axios
    .delete(`${config.apiUrl}/folder-file/delete`, { data: formData })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const getApprovedDatasets = ({ page, per_page, keyword }) =>
  axios
    .get(`${config.apiUrl}/records/approved`, {
      params: { page, per_page, keyword }
    })
    .then(res => {
      return res.data
    })

export const getRecordInfo = ({ id }) =>
  axios
    .get(`${config.apiUrl}/record/get`, {
      params: { id }
    })
    .then(res => {
      return res.data
    })

export const getOperationProgress = ({ id }) =>
  axios
    .get(`${config.apiUrl}/record/progress`, {
      params: { id }
    })
    .then(res => {
      return { data: res.data, status: res.status }
    })

export const waveFile = data =>
  axios
    .post(`${config.apiUrl}/record/weave`, data)
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const concatFiles = data =>
  axios
    .post(`${config.apiUrl}/record/concatenate`, data)
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const sliceDataset = data =>
  axios
    .post(`${config.apiUrl}/record/splice`, data)
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const cutDataset = data =>
  axios
    .post(`${config.apiUrl}/record/cut`, data)
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const runSpikeSorting = data =>
  axios
    .post(`${config.apiUrl}/run/create`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const restartSpikeSorting = data =>
  axios
    .post(`${config.apiUrl}/run/restart`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const stopSpikeSorting = ({ id }) => {
  return axios
    .get(`${config.apiUrl}/run/stop`, { params: { id } })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
}

export const editSpikeSorting = data =>
  axios
    .post(`${config.apiUrl}/run/edit`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const deleteSpikeSorting = ({ id }) => {
  return axios
    .delete(`${config.apiUrl}/run/delete`, { params: { id } })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
}

export const getListOfSpikesortings = ({ record_id, page = 1 }) =>
  axios
    .get(`${config.apiUrl}/run/listrecord`, {
      params: { record_id, page }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getSpikesorting = ({ id }) =>
  axios
    .get(`${config.apiUrl}/run/get`, { params: { id } })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const editRecord = data =>
  axios
    .post(`${config.apiUrl}/record/edit`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const editFolder = data =>
  axios
    .post(`${config.apiUrl}/folder/edit`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getSortingComments = ({ run_id, per_page = 25 }) =>
  axios
    .get(`${config.apiUrl}/runcomment/list/run`, {
      params: { run_id, per_page }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const crateComment = data =>
  axios
    .post(`${config.apiUrl}/runcomment/create`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const deleteComment = id =>
  axios
    .delete(`${config.apiUrl}/runcomment/delete`, { data: { id } })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const updateComment = data =>
  axios
    .post(`${config.apiUrl}/runcomment/edit`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getAllProbeFiles = () =>
  axios
    .get(`${config.apiUrl}/prob_files`)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getAllProbeFilesInfo = (params = null) =>
  axios
    .get(`${config.apiUrl}/prob_files_info`, {
      params
    })
    .then(res => {
      return res.data
    })
    .catch(error => {
      throw error
    })

export const uploadProbeFile = data =>
  axios
    .post(`${config.apiUrl}/probpost`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getProbeFile = fileName =>
  axios
    .get(`${config.apiUrl}/prbparser`, {
      params: { file_name: fileName }
    })
    .then(res => {
      return res.data
    })
    .catch(error => {
      throw error
    })

export const getAllSpecies = () =>
  axios
    .get(`${config.apiUrl}/specieslist`)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getAllBrainRegions = () =>
  axios
    .get(`${config.apiUrl}/brainlist`)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getTsInfo = ({ record_id }) =>
  axios
    .get(`${config.apiUrl}/ts/get_info`, {
      params: { record_id }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getNNDTsInfo = ({ record_id }) =>
  axios
    .get(`${config.apiUrl}/nnd/get_dat_info`, {
      params: { record_id }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getTrace = ({
  record_id,
  ds_factor,
  segment_num,
  segment_size,
  cancelTokenSource,
  start = null,
  end = null
}) =>
  axios
    .get(`${config.apiUrl}/ts/get_trace`, {
      cancelToken: cancelTokenSource?.token,
      params: { record_id, ds_factor, segment_num, segment_size, start, end }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getNNDTrace = ({
  record_id,
  ds_factor,
  segment_num,
  segment_size,
  cancelTokenSource
}) =>
  axios
    .get(`${config.apiUrl}/nnd/get_dat_trace`, {
      cancelToken: cancelTokenSource?.token,
      params: { record_id, ds_factor, segment_num, segment_size }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const editNNDChannelName = ({ id, channels_labels }) =>
  axios
    .put(`${config.apiUrl}/nnd/edit`, {
      id,
      channels_labels
    })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const runLog = params =>
  axios
    .get(`${config.apiUrl}/run/logs`, {
      params
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const runLogsList = ({ recordId }) =>
  axios
    .get(`${config.apiUrl}/run/listrecord`, {
      params: { record_id: recordId }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

/// FOLDERS
export const crateFolder = data =>
  axios
    .post(`${config.apiUrl}/folder/create`, data)
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const foldersList = ({ folder_id }) =>
  axios
    .get(`${config.apiUrl}/folder/move-options`, {
      params: { folder_id }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const moveTo = data =>
  axios
    .post(`${config.apiUrl}/folder-file/move`, data)
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const getRasterData = ({
  startRange,
  endRange,
  record_id,
  curation_id,
  startChannels,
  endChannels,
  cancelTokenSource
}) =>
  axios
    .get(`${config.apiUrl}/ts/get_raster_data`, {
      cancelToken: cancelTokenSource?.token,
      params: {
        start_range: startRange,
        end_range: endRange,
        start_channels: startChannels,
        end_channels: endChannels,
        record_id,
        curation_id
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getCorrelograms = ({ unit1, unit2, run_id, curation_id }) =>
  axios
    .get(`${config.apiUrl}/ts/get_correlograms`, {
      params: { unit1, unit2, run_id, curation_id }
    })
    .then(res => res.data)
    .catch(error => resolveError(error))

export const getWaveforms =
  ({ resolved, rejected, cancelToken }) =>
  ({ run_id, unitId, curation_id }) =>
    axios
      .get(`${config.apiUrl}/cluster/waveforms`, {
        cancelToken,
        params: {
          unit_id: unitId,
          run_id,
          curation_id
        }
      })
      .then(res => {
        resolved(res)
        return res.data
      })
      .catch(error => rejected(error))

export const getMeanWaveforms =
  ({ resolved, rejected, cancelToken }) =>
  ({ run_id, unitId, curation_id }) =>
    axios
      .get(`${config.apiUrl}/cluster/mean_waveforms`, {
        cancelToken,
        params: {
          unit_id: unitId,
          run_id,
          curation_id
        }
      })
      .then(res => {
        resolved(res)
        return res.data
      })
      .catch(error => rejected(error))

export const getFeatures =
  ({ resolved, rejected, cancelToken }) =>
  ({ run_id, unitId, curation_id }) =>
    axios
      .get(`${config.apiUrl}/cluster/features`, {
        cancelToken: cancelToken,
        params: {
          unit_id: JSON.stringify(unitId),
          run_id,
          curation_id
        }
      })
      .then(res => {
        resolved(res)
        return res.data
      })
      .catch(error => {
        return rejected(error)
      })

// CURATION
export const getCurations = ({ id, run_id, page = 1, per_page = 10 }) =>
  axios
    .get(`${config.apiUrl}/curation`, {
      params: { id, run_id, page, per_page }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getCurations$ =
  ({ resolved, rejected, cancelToken }) =>
  ({ id, run_id, page = 1, per_page = 10 }) =>
    axios
      .get(`${config.apiUrl}/curation`, {
        cancelToken,
        params: { id, run_id, page, per_page }
      })
      .then(res => {
        resolved(res)
        return res.data
      })
      .catch(error => rejected(error))

export const deleteCuration = formData =>
  axios
    .delete(`${config.apiUrl}/curation`, { data: formData })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const createCuration = run_id =>
  axios
    .post(`${config.apiUrl}/curation`, { run_id })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const editCuration = data =>
  axios
    .put(`${config.apiUrl}/curation`, data)
    .then(res => {
      return { status: res.status }
    })
    .catch(error => resolveError(error))

export const curationMerge = ({
  curation_id,
  clusters = [],
  sort_by = 'n_spikes',
  direction = 'desc',
  extraParams = {}
}) =>
  axios
    .post(`${config.apiUrl}/curation/merge`, {
      curation_id,
      clusters,
      sort_by,
      direction,
      ...extraParams
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const curationLabel = ({ curation_id, clusters = [], label }) =>
  axios
    .post(`${config.apiUrl}/curation/labelling`, {
      curation_id,
      clusters,
      label
    })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const curationSplit = ({
  curation_id,
  cluster_id,
  sort_by = 'n_spikes',
  direction = 'desc',
  extraParams = {}
}) =>
  axios
    .get(`${config.apiUrl}/curation/split_init`, {
      params: { curation_id, cluster_id, sort_by, direction, ...extraParams }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const onSplitSpikes =
  ({ resolved, rejected, cancelToken }) =>
  ({
    curation_id,
    run_id,
    unitId,
    subplot,
    lassoPoints,
    sort_by,
    direction,
    sort_by_second
  }) =>
    axios
      .post(
        `${config.apiUrl}/curation/request_split`,
        {
          curation_id,
          cluster_ids: unitId,
          run_id,
          subplot,
          lassoPoints,
          sort_by,
          direction,
          sort_by_second
        },
        { cancelToken: cancelToken }
      )
      .then(res => {
        resolved(res.data)
        return res.data
      })
      .catch(error => rejected(error))

// Curation Approval
export const setCurationApprovalStatus = ({ id, isApproved }) =>
  axios
    .post(`${config.apiUrl}/curation/approval`, {
      id,
      approved: isApproved
    })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

// AI models
export const createAIModel = ({ name, curations, description }) =>
  axios
    .post(`${config.apiUrl}/ai_model`, {
      name,
      curations,
      description
    })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const getAIModels = ({ id, all, filter, keyword, page, per_page }) =>
  axios
    .get(`${config.apiUrl}/ai_model`, {
      params: {
        id,
        all,
        filter,
        keyword,
        page,
        per_page
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const restartAITrain = ({ id }) =>
  axios
    .post(`${config.apiUrl}/ai_model/restart`, null, { params: { id } })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const editAIModel = ({ id, name, description, isDefault }) =>
  axios
    .put(`${config.apiUrl}/ai_model`, {
      id,
      name,
      description,
      default: isDefault
    })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const deleteAIModel = ({ ids }) =>
  axios
    .delete(`${config.apiUrl}/ai_model`, {
      data: { ids }
    })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const getModelDatasets = ({ id, page, per_page }) =>
  axios
    .get(`${config.apiUrl}/ai_model/records`, {
      params: {
        id,
        page,
        per_page
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

// sorting
export const querySortingExpInputs = ({ method, data, params }) =>
  axios({
    method,
    url: `${config.apiUrl}/experiment/inputs/spikesorting`,
    data,
    params
  })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

// upload
export const queryUploadExpInputs = ({ method, data, params }) =>
  axios({
    method,
    url: `${config.apiUrl}/experiment/inputs/record`,
    data,
    params
  })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

//download
export const downloadRecord = ({ class_type, id }) => {
  return axios
    .request({
      url: `${config.apiUrl}/download/${class_type}`,
      method: 'GET',
      params: { id },
      responseType: 'json'
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
}

export const downloadCuration = ({ class_type, id }) => {
  return fetch(`${config.apiUrl}/download/${class_type}?id=${id}`, {
    method: 'GET',
    headers: {
      Accept:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9',
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/zip',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    }
  })
    .then(res => res)
    .catch(error => resolveError(error))
}

export const downloadSpikeSorting = ({ class_type, id }) => {
  return axios
    .request({
      url: `${config.apiUrl}/download/${class_type}?id=${id}`,
      method: 'GET',
      responseType: 'json'
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
}
// For Events File
export const getPresignedUrl = ({
  key,
  multi_part = false,
  upload_id = null,
  part = null,
  parts_info = null
}) =>
  axios
    .post(`${config.apiUrl}/upload_presigned_url`, {
      key,
      multi_part,
      upload_id,
      part,
      parts_info
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const completeMultipartUpload = ({
  key,
  upload_id = null,
  part_info = null
}) =>
  axios
    .post(`${config.apiUrl}/upload/multipart/complete`, {
      key,
      upload_id,
      part_info
    })
    .then(res => res)
    .catch(error => resolveError(error))

export const createEventFile = ({
  record_id,
  key,
  number_channels = null,
  dtype = null,
  sample_rate = null,
  file_size = null
}) =>
  axios
    .post(`${config.apiUrl}/nnd/create`, {
      record_id,
      s3_file_link: key,
      number_channels,
      dtype,
      sample_rate,
      file_size
    })
    .then(res => {
      return { status: res.status }
    })
    .catch(error => resolveError(error))

export const getEvents = ({ record_id }) =>
  axios
    .get(`${config.apiUrl}/nnd/record_events`, {
      params: { id: record_id }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const editEvents = ({
  id,
  remove = null,
  new_data = null,
  update_data = null
}) =>
  axios
    .post(`${config.apiUrl}/nnd/update_file`, {
      id,
      remove,
      new_data,
      update_data
    })
    .then(res => {
      return { data: res.data, status: res.status }
    })
    .catch(error => resolveError(error))

export const downloadEvent = ({ id }) => {
  return axios
    .request({
      url: `${config.apiUrl}/nnd/download`,
      params: { id },
      method: 'GET'
    })
    .then(res => res.data)
    .catch(error => resolveError(error))
}

export const deleteEvent = ({ id }) => {
  return axios
    .request({
      url: `${config.apiUrl}/nnd/delete`,
      method: 'DELETE',
      params: { id },
      responseType: 'json'
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
}

export const deleteEventItem = ({ id, event }) => {
  return axios
    .delete(`${config.apiUrl}/nnd/remove_event`, {
      data: {
        id,
        event
      }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
}

export const validateNNDFile = ({
  record_id,
  number_channels,
  dtype,
  sample_rate,
  file_size
}) =>
  axios
    .post(`${config.apiUrl}/nnd/dat_validation`, {
      record_id,
      number_channels,
      dtype,
      sample_rate,
      file_size
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const createUploadPart = async (body, url) =>
  fetch(url, {
    method: 'PUT',
    body
  })
    .then(res => res)
    .catch(error => resolveError(error))

//PROBE INVENTORY
export const getAllProbes = ({ page = 1, per_page = 10 }) =>
  axios
    .get(`${config.apiUrl}/user_probes`, {
      params: { page, per_page }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const downloadImpedance = ({ id }) => {
  return axios
    .request({
      url: `${config.apiUrl}/impedance/download`,
      method: 'GET',
      params: { id },
      responseType: 'json'
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
}

export const createProbe = data =>
  axios
    .post(`${config.apiUrl}/probe`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const editProbe = data =>
  axios
    .put(`${config.apiUrl}/probe`, data)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const getProbeOptions = () =>
  axios
    .get(`${config.apiUrl}/probe_options`)
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))

export const downloadProbeMap = params => {
  return axios
    .request({
      url: `${config.apiUrl}/matlab/download`,
      method: 'GET',
      params,
      responseType: 'json'
    })
    .then(res => {
      return res.data
    })
    .catch(error => resolveError(error))
}
