import { useCallback, useEffect } from 'react'
import { getOS } from '../../../../../utils/utils'
import { OS } from '../../../../../utils/consts'

export default function useKeyboardEventListener(
  eventsArray,
  condition = true
) {
  const logKey = useCallback(
    e => {
      const { ctrlKey, metaKey, altKey: isAltPressed } = e || {}
      const isCtrlPressed = getOS() === OS.MAC_OS ? metaKey : ctrlKey
      const keyCode = e.keyCode

      const selected = eventsArray.find(
        ({ keyCode: code, isAlt, isCtrl, disabled = false }) => {
          if (disabled) return false
          if (isAlt) return code === keyCode && isAltPressed
          if (isCtrl) return code === keyCode && isCtrlPressed
          return code === keyCode
        }
      )
      if (selected) {
        e.preventDefault()
        e.stopImmediatePropagation()
      }
      return selected?.onClick()
    },
    [eventsArray]
  )

  useEffect(() => {
    if (condition) {
      document.removeEventListener('keydown', logKey)
    } else document.addEventListener('keydown', logKey)

    return () => document.removeEventListener('keydown', logKey)
    // eslint-disable-next-line
  }, [condition, eventsArray])
}
