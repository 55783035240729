import React, { memo } from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { AddToQueue, Airplay } from '@material-ui/icons'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 24
  },

  rangeButton: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    fontSize: 14,
    fontWeight: 600,
    backgroundColor: theme.palette.colors.customColor(
      theme.palette.colors.neutral[9],
      theme.palette.colors.neutral[90]
    ),
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[4],
      theme.palette.colors.neutral[6]
    ),
    '&:hover': {
      backgroundColor: '#2D9BFA'
    }
  },
  selected: {
    backgroundColor: '#2D9BFA',
    color: theme.palette.colors.white
  },
  actionIcon: {
    cursor: 'pointer',
    height: 16,
    color: theme.palette.colors.neutral[5],
    '&:hover': {
      color: theme.palette.colors.mainColor
    }
  },
  opened: {
    color: theme.palette.colors.mainColor
  }
}))

const Range = ({ item, isSelected, isOpened, onClick, onClickRange }) => {
  const classes = useStyles()

  const [from, to] = item.split(' - ')

  return (
    <div className={classes.root}>
      <Button
        onClick={onClickRange}
        startIcon={<Airplay />}
        className={cn(classes.rangeButton, {
          [classes.selected]: isSelected
        })}
      >
        {`${from} - ${to}`}
      </Button>
      <AddToQueue
        onClick={onClick}
        className={cn(classes.actionIcon, {
          [classes.opened]: isOpened
        })}
      />
    </div>
  )
}

export default memo(Range)
