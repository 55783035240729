import { getSimilarityView } from '../../../../../../../endpoints/curation'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOr, isEmpty, last } from 'lodash/fp'
import useClusterData from '../../ClusterView/hooks/useClusterData'
import { useSafeDispatch } from '../../../../../../../hooks/useSafeDispatch'
import {
  loadSimilarityView,
  SAVE_SIMILARITY_UNITS,
  sortSimilarityView,
  toggleCurationAI
} from '../../../redux/actions'
import debounce from 'lodash/debounce'
import useClusterSimilarityStore from '../../ClusterView/hooks/useClusterSimilarityStore'
import { useReinitClusters } from '../../utils'

function useSimilarityView(props) {
  const { isInit } = props || {}
  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)
  const {
    storedSimilarityData: storedData,
    clusterUnits: selectedUnits,
    sortingId,
    curationId,
    keySimilarityData,
    similaritySortBy: sortBy,
    similaritySortDirection: sortDirection,
    recommendations,
    noiseRecommendations,
    toggleRecommendation,
    isSuggestionVisible,
    hiddenRecommendations,
    isCurationAI
  } = useClusterSimilarityStore()
  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )

  const dataId = last(selectedUnits)

  const similarityRecommendations = useMemo(() => {
    return recommendations?.find(item => item.includes(dataId))
  }, [dataId, recommendations])

  const isSimilaritySuggestionsVisible = useCallback(
    id => isSuggestionVisible(id),
    [isSuggestionVisible]
  )

  const saveData = useCallback(
    payload => {
      if (payload?.data?.clusters)
        safeDispatch(
          loadSimilarityView({
            sortingId: keySimilarityData,
            dataId,
            data: payload
          })
        )
    },
    [dataId, safeDispatch, keySimilarityData]
  )

  const saveSortMethod = useCallback(
    ({ sortBy, sortDirection }) => {
      safeDispatch(
        sortSimilarityView({
          sortBy,
          sortDirection
        })
      )
    },
    [safeDispatch]
  )

  const customParams = {
    cluster_id: last(selectedUnits),
    sort_by: sortBy,
    direction: sortDirection.toLowerCase(),
    ai: isCurationAI
  }

  const { state, loadMore, fetchData, onInit, refetch, onSort } =
    useClusterData({
      promise: getSimilarityView,
      customParams,
      storedData,
      saveSortMethod,
      saveData
    })

  const mayFetch = sortingId && !isEmpty(selectedUnits)

  const debounceFetch = debounce(fetchData, 500)

  useEffect(() => {
    if (mayFetch) {
      onInit()
      debounceFetch(saveData)
    }
    // eslint-disable-next-line
  }, [mayFetch, selectedUnits, curationId, sortBy, sortDirection])

  // if close similarity widget, disable curation AI
  useEffect(() => {
    return () => {
      dispatch(toggleCurationAI(false))
    }
  }, [])

  const similaritySugesstedUnits = useMemo(() => {
    if (!isCurationAI) return null
    return similarityRecommendations?.filter(
      i => i !== dataId && !hiddenRecommendations.get(i)
    )
  }, [dataId, isCurationAI, similarityRecommendations])

  const reinitClusters = useReinitClusters()

  useEffect(() => {
    if (isInit) return
    if (!similaritySugesstedUnits) return

    reinitClusters({
      arr: similaritySugesstedUnits,
      type: SAVE_SIMILARITY_UNITS,
      multiple: true
    })
  }, [dataId, similaritySugesstedUnits])

  return {
    state,
    onSort,
    sortBy,
    sortDirection,
    refetch,
    loadMore,
    storedData,
    selectedUnits,
    selectedSorting,
    isCurationAI,
    recommendations: similarityRecommendations,
    noiseRecommendations,
    isSuggestionVisible: isSimilaritySuggestionsVisible,
    toggleRecommendation
  }
}

export default useSimilarityView
