import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import EmptyWidget from '../components/EmptyWidget'
import { isEmpty } from 'lodash/fp'
import { seedUnitColor } from '../../../../../shared/utils'

const combinatios = arr => {
  const res = []
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr.length; j++) {
      res.push([arr[i], arr[j]])
    }
  }
  return res
}

export const useUnitsGen = () => {
  const clusterUnits = useSelector(state => state.widgetsCache.units.cluster)
  const similarityUnits = useSelector(
    state => state.widgetsCache.units.similarity
  )

  const units = useMemo(
    () => clusterUnits.concat(similarityUnits),
    [clusterUnits, similarityUnits]
  )

  // prevent duplicates
  const uniqueUnits = useMemo(() => [...new Set(units)], [units])

  const mockUnits = useMemo(() => combinatios(uniqueUnits), [uniqueUnits])

  const ndArray = useMemo(
    () => uniqueUnits.map(u => mockUnits.filter(x => x[0] === u)),
    [uniqueUnits, mockUnits]
  )

  const unitsWithSeedColor = useMemo(
    () => new Map(uniqueUnits.map(unit => [unit, seedUnitColor(unit)])),
    [uniqueUnits]
  )

  const render = jsx => {
    if (isEmpty(ndArray)) return <EmptyWidget />

    return jsx()
  }

  return {
    uniqueUnits,
    ndArray,
    render,
    unitsWithSeedColor,
    clusterUnits,
    similarityUnits
  }
}
