import React, { memo } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import cn from 'classnames'
import { Error } from '@material-ui/icons'
import { TRAINING_STATUS } from '../../hooks/consts'

const useStyles = makeStyles(theme => ({
  status: ({ status }) =>
    theme.status({
      height: 20,
      display: 'flex',
      width: 'fit-content',
      alignItems: 'center',
      background: theme.aiTraining[status]?.bg,
      color: theme.aiTraining[status]?.color,
      fontWeight: 600
    }),
  statusError: () => ({
    fontSize: 14,
    background: 'transparent',
    color: theme.aiTraining.failure.color,
    '& svg': {
      marginRight: 8,
      verticalAlign: 'middle'
    }
  })
}))

const TrainingStatus = ({ status }) => {
  const classes = useStyles({
    status: TRAINING_STATUS[status]?.code?.toLowerCase()
  })
  const isError = status === TRAINING_STATUS.FAILURE.code

  return (
    <Typography
      className={cn(classes.status, {
        [classes.statusError]: isError
      })}
      noWrap
    >
      {isError && <Error />}
      {TRAINING_STATUS[status]?.label}
    </Typography>
  )
}

export default memo(TrainingStatus)
