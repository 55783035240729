import {
  generateTimeseriesData,
  mapColors,
  fetchProbeFile,
  groupChannelsInSameProbe
} from './utils'
import { to } from '../../../utils/utils'
import { findProbeDrawing } from '../../../components/TimeseriesView/NewLeftPanel/components/DrawProbe/DrawProbe'

export const getProbeFileData = async (probeFileName, numberChannels = 0) => {
  const { data: probeFileData } = await to(fetchProbeFile, probeFileName)
  const foundDraw = findProbeDrawing(probeFileName)
  const { channel_groups, probe_type, total_nb_channels } = probeFileData || {}

  const multigroup = foundDraw?.multigroup

  const fileData = {
    channelGroups: channel_groups ?? {},
    info: {
      probeName: probeFileName ? probeFileName.replace('_auto.prb', '') : '',
      probeType: probe_type ?? '',
      channels: total_nb_channels ?? 0
    }
  }

  const splitGroup =
    Object.keys(channel_groups ?? {})?.length === 1 &&
    [128, 64].includes(total_nb_channels)

  const numChannels = !numberChannels
    ? probeFileData?.total_nb_channels ?? 0
    : numberChannels
  const { chunks, ...timeSeriesData } = generateTimeseriesData({
    probeFileData: probeFileName ? fileData : null,
    numberChannels: numChannels
  })

  const { channel_map } = groupChannelsInSameProbe(timeSeriesData.channel_ids)

  const map = splitGroup ? channel_map : timeSeriesData.channel_map

  return {
    ...timeSeriesData,
    multigroup,
    chunks,
    splitGroup,
    mapColors: mapColors(map, multigroup),
    probeName: fileData.info.probeName,
    probeType: fileData.infoprobeType
  }
}
