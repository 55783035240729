import useColumnFilters from '../../../../Files/hooks/useColumnFilters'
import React, { useCallback, useMemo, useState } from 'react'
import { getUploadDate } from '../../../../../utils/utils'
import { useSelector } from 'react-redux'
import { initialColumns } from './initialColumns'
import ActionButton from '../../../../ActionButton'
import { CircularProgress, IconButton, makeStyles } from '@material-ui/core'
import ProgressBar from '../../../../ProgressBar/ProgressBar'
import { TRAINING_STATUS } from '../../../hooks/consts'
import TrainingStatus from '../../TrainingStatus'
import { restartAITrain } from '../../../../../endpoints/admin'
import { NOTIFICATION } from '../../../../../utils/consts'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import EditIcon from '@material-ui/icons/Edit'
import useEdit from '../../../hooks/useEdit'
import useGetCurrentUser from '../../../../../hooks/useGetCurrentUser'
import { DeleteRounded } from '@material-ui/icons'
import useDelete from '../../../hooks/useDelete'
import useFilesController from '../../../../FilesTable/hooks/useFilesController'
import { MODALS_ID } from '../../../../../utils/consts'
import useToggleModal from '../../../../../hooks/useToggleModal'
import InfoIcon from '@material-ui/icons/Info'

const useStyles = makeStyles(() => ({
  runAgainBtn: {
    width: 'auto',
    margin: '0 auto'
  }
}))

function useExistingTable({ fetchRecords }) {
  const classes = useStyles()
  const addNotification = useNotification()
  const customStorageName = 'ExistingAIColumns'
  const { filteredColumns: columns } = useColumnFilters({
    customStorageName,
    customInitialColumns: initialColumns
  })
  const { toggleEditModal } = useEdit()
  const { toggleDeleteModal } = useDelete()
  const { user } = useGetCurrentUser()

  const [trainLoading, setTrainLoading] = useState(false)
  const [selectedRowId, setRowID] = useState(null)

  const { records, pages, filter } = useSelector(state => state.aiModels)
  const { handleSelect, selected, isSelected, deselectAll, isBulkSelect, isExistingSelected, handleExsistingSelect } =
    useFilesController()
  const { MODEL_INFO_PANEL } = MODALS_ID
  const { toggle: toggleInfoPanel } = useToggleModal(MODEL_INFO_PANEL)

  const handleRestartTrain = useCallback(
    async id => {
      try {
        setTrainLoading(true)
        setRowID(id)
        const response = await restartAITrain({ id })
        if (response?.created_at) {
          setRowID(null)
          await fetchRecords()
          setTrainLoading(false)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'AI Training was successfully restarted!'
          })
        }
      } catch (e) {
        setTrainLoading(false)
        addNotification({
          type: NOTIFICATION.ERROR,
          title: 'AI Training failed !'
        })
      }
    },
    [fetchRecords]
  )

  const renderProgressBar = useCallback(
    (id, user_id, progress, isQueued, isFailed) => {
      const { id: currentUserId, admin } = user || {}
      const isAuthor = user_id === currentUserId
      if (isFailed) {
        if (!isAuthor && !admin) return null

        return (
          <ActionButton
            label="Run again"
            loading={selectedRowId === id && trainLoading}
            onClick={e => {
              e.stopPropagation()
              handleRestartTrain(id)
            }}
            className={classes.runAgainBtn}
          />
        )
      }

      if (isQueued) return <CircularProgress disableShrink size={18} />

      return <ProgressBar progress={progress || 0} />
    },
    [user?.id, handleRestartTrain, selectedRowId, trainLoading]
  )

  const renderActionButton = useCallback(
    props => {
      const { author } = props
      const { id: currentUserId, admin } = user || {}
      const isAuthor = author?.id === currentUserId
      return (
        <>
          <IconButton>
            <InfoIcon />
          </IconButton>
          {(isAuthor || admin) && (
            <>
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  toggleEditModal(props)
                }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={e => {
                  e.stopPropagation()
                  toggleDeleteModal([props])
                }}
              >
                <DeleteRounded />
              </IconButton>
            </>
          )}
        </>
      )
    },
    [toggleDeleteModal, toggleEditModal, user?.id]
  )

  const checkSelectDisabled = useCallback(
    ({ authorId }) => authorId !== user?.id && !user?.admin,
    [user?.admin, user?.id]
  )

  const rows = useMemo(
    () =>
      records?.length > 0 &&
      records?.map(item => {
        const {
          id,
          name,
          description,
          status,
          user_id,
          user_name,
          progress,
          created_at,
          upload_date
        } = item
        const isFailed = status === TRAINING_STATUS.FAILURE.code
        const isQueued = status === TRAINING_STATUS.QUEUE.code
        const author = { id: user_id, name: user_name }

        return {
          id,
          isModel: true,
          name,
          description,
          trainingStatus: <TrainingStatus status={status} />,
          actions: renderProgressBar(id, user_id, progress, isQueued, isFailed),
          author,
          uploaded_date: getUploadDate(upload_date || created_at)
        }
      }),
    [records, renderProgressBar]
  )

  const onRowClick = useCallback(
    id => toggleInfoPanel({ id }),
    [toggleInfoPanel]
  )

  return {
    rows,
    columns,
    pages,
    filter,
    selected,
    isSelected,
    isBulkSelect,
    onRowClick,
    deselectAll,
    handleSelect,
    checkSelectDisabled,
    toggleDeleteModal,
    renderActionButton,
    isExistingSelected,
    handleExsistingSelect
  }
}

export default useExistingTable
