import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CustomTabs from '../../../CustomTabs/CustomTabs'
import InfoPanelTags from './components/InfoPanelTags'
import useEditTagsAndPublics from './hooks/useEditTagsAndPublics'
import EditTagsPublicationsModal from './components/EditTagsPublicationsModal'
import { connect } from 'react-redux'
import { RECORD_TABS } from '../../../../utils/consts'
import RecordingData from './components/RecordingData'

const useStyles = makeStyles(() => ({
  gridRoot: {
    display: 'block',
    height: '100%',
    padding: '26px 24px',
    width: '100%'
  },
  tabs: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  tabsHeader: {
    marginBottom: 40
  }
}))

const RecordingInfoPanel = ({ tags, publications }) => {
  const classes = useStyles()
  const [selectedTab, setTab] = useState(RECORD_TABS.TAGS)

  const { toggleModal, handleSave, loading } = useEditTagsAndPublics()

  const handleToggle = useCallback(() => {
    toggleModal({
      type: selectedTab
    })
  }, [selectedTab, toggleModal])

  const tabs = [
    {
      key: RECORD_TABS.TAGS,
      label: 'Tags',
      content: (
        <InfoPanelTags title="Tags" tags={tags} toggleModal={handleToggle} />
      )
    },
    {
      key: RECORD_TABS.PUBLICATIONS,
      label: 'Publications',
      content: (
        <InfoPanelTags
          title="Publications"
          tags={publications}
          colorAccent={3}
          toggleModal={handleToggle}
        />
      )
    }
  ]

  const handleChangeTabs = useCallback(
    (event, newValue) => setTab(newValue),
    []
  )

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.gridRoot}
    >
      <RecordingData />
      <div className={classes.tabs}>
        <CustomTabs
          tabs={tabs}
          selectedTab={selectedTab}
          handleChangeTabs={handleChangeTabs}
          customClasses={{
            header: classes.tabsHeader
          }}
        />
      </div>

      <EditTagsPublicationsModal loading={loading} handleSave={handleSave} />
    </Grid>
  )
}

const mapStateToProps = state => ({
  tags: state.recordingStore.tags,
  publications: state.recordingStore.publications
})

export default connect(mapStateToProps)(RecordingInfoPanel)
