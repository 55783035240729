import { useHistory } from 'react-router-dom'
import useQueryParams from './useQueryParams'
import { useCallback } from 'react'

function useSearchQuery() {
  const { query } = useQueryParams()
  const history = useHistory()

  /**
   * Insert multiple queries at the one time
   * single - insertSearchQuery({key: <key>, value: <value>})
   * multiple - insertSearchQuery([ {key: <key>, value: <value>}, {key: <key>, value: <value>} ])
   */
  const insertSearchQuery = useCallback(
    props => {
      if (Array.isArray(props)) {
        props.forEach(({ key, value }) => query.set(key, value))
      } else {
        const { key, value } = props
        query.set(key, value)
      }
      const search = query.toString()
      history.push({ search })
      return search
    },
    [history, query]
  )

  /**
   * Delete multiple queries at the one time
   * single - deleteSearchQuery(<key>)
   * multiple - deleteSearchQuery([<key>, <key>])
   */
  const deleteSearchQuery = useCallback(
    keys => {
      if (Array.isArray(keys)) {
        keys.forEach(key => query.delete(key))
      } else {
        query.delete(keys)
      }
      const search = query.toString()
      history.push({ search })
      return search
    },
    [history, query]
  )

  /**
   * Insert multiple queries and delete useless at the one time
   * single - deleteSearchQueryWithPushing({
   *              key: <key>,
   *              value: <value>,
   *              keyToDelete: [<key>, <key>]
   *          })
   * multiple - deleteSearchQueryWithPushing([
   *              {key: <key>, value: <value>, keyToDelete: [<key>, <key>]},
   *              {key: <key>, value: <value>, keyToDelete: [<key>, <key>]}
   *            ])
   */
  const deleteSearchQueryWithPushing = useCallback(
    props => {
      if (Array.isArray(props)) {
        props.forEach(({ key, value, keyToDelete }) => {
          query.set(key, value)
          deleteSearchQuery(keyToDelete)
        })
      } else {
        const { key, value, keyToDelete } = props
        query.set(key, value)
        deleteSearchQuery(keyToDelete)
      }
      const search = query.toString()
      history.push({ search })
      return search
    },
    [deleteSearchQuery, history, query]
  )

  return {
    insertSearchQuery,
    deleteSearchQuery,
    deleteSearchQueryWithPushing
  }
}

export default useSearchQuery
