import React, { memo, useCallback } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import CustomModal from '../../../CustomModal'
import CustomInput from '../../../FormComponents/CustomInput'
import CurationAIIcon from '../../../Icons/curationAIIcon'
import useEdit from '../../hooks/useEdit'
import ActionButton from '../../../ActionButton'

const useStyles = makeStyles(theme => ({
  header: {
    height: 68
  },
  title: {
    fontSize: 20,
    lineHeight: '36px',
    fontWeight: 600,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[90],
      'white'
    )
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 40
  },
  bodyText: {
    fontSize: 16,
    lineHeight: '24px',
    marginLeft: 4,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      'white'
    )
  },
  actionButton: {
    borderRadius: 8,
    fontWeight: 600,
    fontSize: 14,
    color: 'white',
    padding: '9px 12px'
  }
}))

const EditModal = () => {
  const classes = useStyles()
  const {
    state,
    loading,
    selected,
    onEdit,
    onClose,
    handleChange,
    isEditModalOpen: isOpened,
    toggleEditModal: toggleModal
  } = useEdit()
  const { name, description } = state || {}
  const { id, name: initialName, description: initialDescription } = selected

  const onSaveEdit = useCallback(async () => {
    await onEdit({ id, name, description })
    toggleModal()
  }, [description, id, name, onEdit, toggleModal])

  const SaveButton = memo(() => {
    return (
      <ActionButton
        label={'Save'}
        loading={loading}
        className={classes.actionButton}
        onClick={onSaveEdit}
      />
    )
  })

  return (
    <CustomModal
      title={'Edit Description'}
      open={isOpened}
      onClose={onClose}
      classes={classes}
      maxWidth={603}
      customPaperClass={classes.root}
      renderActionButton={<SaveButton />}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <div className={classes.nameContainer}>
        <CurationAIIcon width={24} height={24} />
        <Typography className={classes.bodyText}>{initialName}</Typography>
      </div>

      <CustomInput
        name={'name'}
        value={name ?? initialName}
        label={'New AI name'}
        onChange={handleChange}
      />
      <CustomInput
        multiline
        rows={5}
        name={'description'}
        value={description ?? initialDescription}
        label={'Description'}
        onChange={handleChange}
      />
    </CustomModal>
  )
}

export default memo(EditModal)
