import { useMemo } from 'react'
import { Edit, Security } from '@material-ui/icons'
import { ROUTES } from '../../../utils/consts'

function useMenuItems() {
  return useMemo(
    () => [
      {
        to: ROUTES.ACCOUNT,
        label: 'Edit Profile',
        exact: true,
        icon: Edit
      },
      {
        to: ROUTES.ACCOUNT_SECURITY,
        label: 'Security',
        exact: true,
        icon: Security
      }
    ],
    []
  )
}

export default useMenuItems
