import React from 'react'

export const svg64_11 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="140"
        height="2114"
        viewBox="0 0 140 2114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 2113.7V0H140V1821.52L0 2113.7Z" fill={color} />
        <path
          d="M5 67C5 65.3431 6.34315 64 8 64H20C21.6569 64 23 65.3431 23 67V78.0769C23 79.7338 21.6569 81.0769 20 81.0769H8C6.34315 81.0769 5 79.7338 5 78.0769V67Z"
          fill="white"
        />
        <path
          d="M5 97.0769C5 95.4201 6.34315 94.0769 8 94.0769H20C21.6569 94.0769 23 95.4201 23 97.0769V108.154C23 109.811 21.6569 111.154 20 111.154H8C6.34315 111.154 5 109.811 5 108.154V97.0769Z"
          fill="white"
        />
        <path
          d="M5 127.154C5 125.497 6.34315 124.154 8 124.154H20C21.6569 124.154 23 125.497 23 127.154V138.231C23 139.888 21.6569 141.231 20 141.231H8C6.34315 141.231 5 139.888 5 138.231V127.154Z"
          fill="white"
        />
        <path
          d="M5 157.231C5 155.574 6.34315 154.231 8 154.231H20C21.6569 154.231 23 155.574 23 157.231V168.308C23 169.964 21.6569 171.308 20 171.308H8C6.34315 171.308 5 169.964 5 168.308V157.231Z"
          fill="white"
        />
        <path
          d="M5 187.308C5 185.651 6.34315 184.308 8 184.308H20C21.6569 184.308 23 185.651 23 187.308V198.385C23 200.041 21.6569 201.385 20 201.385H8C6.34315 201.385 5 200.041 5 198.385V187.308Z"
          fill="white"
        />
        <path
          d="M5 217.385C5 215.728 6.34315 214.385 8 214.385H20C21.6569 214.385 23 215.728 23 217.385V228.461C23 230.118 21.6569 231.461 20 231.461H8C6.34315 231.461 5 230.118 5 228.461V217.385Z"
          fill="white"
        />
        <path
          d="M5 247.461C5 245.805 6.34315 244.461 8 244.461H20C21.6569 244.461 23 245.805 23 247.461V258.538C23 260.195 21.6569 261.538 20 261.538H8C6.34315 261.538 5 260.195 5 258.538V247.461Z"
          fill="white"
        />
        <path
          d="M5 277.538C5 275.881 6.34315 274.538 8 274.538H20C21.6569 274.538 23 275.881 23 277.538V288.615C23 290.272 21.6569 291.615 20 291.615H8C6.34315 291.615 5 290.272 5 288.615V277.538Z"
          fill="white"
        />
        <path
          d="M5 307.615C5 305.958 6.34315 304.615 8 304.615H20C21.6569 304.615 23 305.958 23 307.615V318.692C23 320.349 21.6569 321.692 20 321.692H8C6.34315 321.692 5 320.349 5 318.692V307.615Z"
          fill="white"
        />
        <path
          d="M5 337.692C5 336.036 6.34315 334.692 8 334.692H20C21.6569 334.692 23 336.036 23 337.692V348.769C23 350.426 21.6569 351.769 20 351.769H8C6.34315 351.769 5 350.426 5 348.769V337.692Z"
          fill="white"
        />
        <path
          d="M5 367.769C5 366.112 6.34315 364.769 8 364.769H20C21.6569 364.769 23 366.112 23 367.769V378.846C23 380.503 21.6569 381.846 20 381.846H8C6.34315 381.846 5 380.503 5 378.846V367.769Z"
          fill="white"
        />
        <path
          d="M5 397.846C5 396.189 6.34315 394.846 8 394.846H20C21.6569 394.846 23 396.189 23 397.846V408.923C23 410.58 21.6569 411.923 20 411.923H8C6.34315 411.923 5 410.58 5 408.923V397.846Z"
          fill="white"
        />
        <path
          d="M5 427.923C5 426.266 6.34315 424.923 8 424.923H20C21.6569 424.923 23 426.266 23 427.923V439C23 440.657 21.6569 442 20 442H8C6.34315 442 5 440.657 5 439V427.923Z"
          fill="white"
        />
        <path
          d="M5 458C5 456.343 6.34315 455 8 455H20C21.6569 455 23 456.343 23 458V469.077C23 470.734 21.6569 472.077 20 472.077H8C6.34315 472.077 5 470.734 5 469.077V458Z"
          fill="white"
        />
        <path
          d="M5 488.077C5 486.42 6.34315 485.077 8 485.077H20C21.6569 485.077 23 486.42 23 488.077V499.154C23 500.81 21.6569 502.154 20 502.154H8C6.34315 502.154 5 500.81 5 499.154V488.077Z"
          fill="white"
        />
        <path
          d="M5 518.154C5 516.497 6.34315 515.154 8 515.154H20C21.6569 515.154 23 516.497 23 518.154V529.231C23 530.888 21.6569 532.231 20 532.231H8C6.34315 532.231 5 530.888 5 529.231V518.154Z"
          fill="white"
        />
        <path
          d="M5 548.23C5 546.574 6.34315 545.23 8 545.23H20C21.6569 545.23 23 546.574 23 548.23V559.307C23 560.964 21.6569 562.307 20 562.307H8C6.34315 562.307 5 560.964 5 559.307V548.23Z"
          fill="white"
        />
        <path
          d="M5 578.308C5 576.651 6.34315 575.308 8 575.308H20C21.6569 575.308 23 576.651 23 578.308V589.385C23 591.041 21.6569 592.385 20 592.385H8C6.34315 592.385 5 591.041 5 589.385V578.308Z"
          fill="white"
        />
        <path
          d="M5 608.384C5 606.727 6.34315 605.384 8 605.384H20C21.6569 605.384 23 606.727 23 608.384V619.461C23 621.118 21.6569 622.461 20 622.461H8C6.34315 622.461 5 621.118 5 619.461V608.384Z"
          fill="white"
        />
        <path
          d="M5 638.461C5 636.805 6.34315 635.461 8 635.461H20C21.6569 635.461 23 636.805 23 638.461V649.538C23 651.195 21.6569 652.538 20 652.538H8C6.34315 652.538 5 651.195 5 649.538V638.461Z"
          fill="white"
        />
        <path
          d="M5 668.538C5 666.881 6.34315 665.538 8 665.538H20C21.6569 665.538 23 666.881 23 668.538V679.615C23 681.272 21.6569 682.615 20 682.615H8C6.34315 682.615 5 681.272 5 679.615V668.538Z"
          fill="white"
        />
        <path
          d="M5 698.615C5 696.958 6.34315 695.615 8 695.615H20C21.6569 695.615 23 696.958 23 698.615V709.692C23 711.349 21.6569 712.692 20 712.692H8C6.34315 712.692 5 711.349 5 709.692V698.615Z"
          fill="white"
        />
        <path
          d="M5 728.692C5 727.035 6.34315 725.692 8 725.692H20C21.6569 725.692 23 727.035 23 728.692V739.769C23 741.426 21.6569 742.769 20 742.769H8C6.34315 742.769 5 741.426 5 739.769V728.692Z"
          fill="white"
        />
        <path
          d="M5 758.769C5 757.112 6.34315 755.769 8 755.769H20C21.6569 755.769 23 757.112 23 758.769V769.846C23 771.503 21.6569 772.846 20 772.846H8C6.34315 772.846 5 771.503 5 769.846V758.769Z"
          fill="white"
        />
        <path
          d="M5 788.846C5 787.189 6.34315 785.846 8 785.846H20C21.6569 785.846 23 787.189 23 788.846V799.923C23 801.579 21.6569 802.923 20 802.923H8C6.34315 802.923 5 801.579 5 799.923V788.846Z"
          fill="white"
        />
        <path
          d="M5 818.923C5 817.266 6.34315 815.923 8 815.923H20C21.6569 815.923 23 817.266 23 818.923V830C23 831.657 21.6569 833 20 833H8C6.34315 833 5 831.657 5 830V818.923Z"
          fill="white"
        />
        <path
          d="M5 849C5 847.343 6.34315 846 8 846H20C21.6569 846 23 847.343 23 849V860.076C23 861.733 21.6569 863.076 20 863.076H8C6.34315 863.076 5 861.733 5 860.076V849Z"
          fill="white"
        />
        <path
          d="M5 879.077C5 877.42 6.34315 876.077 8 876.077H20C21.6569 876.077 23 877.42 23 879.077V890.154C23 891.81 21.6569 893.154 20 893.154H8C6.34315 893.154 5 891.81 5 890.154V879.077Z"
          fill="white"
        />
        <path
          d="M5 909.153C5 907.496 6.34315 906.153 8 906.153H20C21.6569 906.153 23 907.496 23 909.153V920.23C23 921.887 21.6569 923.23 20 923.23H8C6.34315 923.23 5 921.887 5 920.23V909.153Z"
          fill="white"
        />
        <path
          d="M5 939.23C5 937.574 6.34315 936.23 8 936.23H20C21.6569 936.23 23 937.574 23 939.23V950.307C23 951.964 21.6569 953.307 20 953.307H8C6.34315 953.307 5 951.964 5 950.307V939.23Z"
          fill="white"
        />
        <path
          d="M5 969.307C5 967.65 6.34315 966.307 8 966.307H20C21.6569 966.307 23 967.65 23 969.307V980.384C23 982.041 21.6569 983.384 20 983.384H8C6.34315 983.384 5 982.041 5 980.384V969.307Z"
          fill="white"
        />
        <path
          d="M5 999.384C5 997.727 6.34315 996.384 8 996.384H20C21.6569 996.384 23 997.727 23 999.384V1010.46C23 1012.12 21.6569 1013.46 20 1013.46H8C6.34315 1013.46 5 1012.12 5 1010.46V999.384Z"
          fill="white"
        />
        <path
          d="M5 1029.46C5 1027.8 6.34315 1026.46 8 1026.46H20C21.6569 1026.46 23 1027.8 23 1029.46V1040.54C23 1042.19 21.6569 1043.54 20 1043.54H8C6.34315 1043.54 5 1042.19 5 1040.54V1029.46Z"
          fill="white"
        />
        <path
          d="M5 1059.54C5 1057.88 6.34315 1056.54 8 1056.54H20C21.6569 1056.54 23 1057.88 23 1059.54V1070.62C23 1072.27 21.6569 1073.62 20 1073.62H8C6.34315 1073.62 5 1072.27 5 1070.62V1059.54Z"
          fill="white"
        />
        <path
          d="M5 1089.61C5 1087.96 6.34315 1086.61 8 1086.61H20C21.6569 1086.61 23 1087.96 23 1089.61V1100.69C23 1102.35 21.6569 1103.69 20 1103.69H8C6.34315 1103.69 5 1102.35 5 1100.69V1089.61Z"
          fill="white"
        />
        <path
          d="M5 1119.69C5 1118.04 6.34315 1116.69 8 1116.69H20C21.6569 1116.69 23 1118.04 23 1119.69V1130.77C23 1132.43 21.6569 1133.77 20 1133.77H8C6.34315 1133.77 5 1132.43 5 1130.77V1119.69Z"
          fill="white"
        />
        <path
          d="M5 1149.77C5 1148.11 6.34315 1146.77 8 1146.77H20C21.6569 1146.77 23 1148.11 23 1149.77V1160.85C23 1162.5 21.6569 1163.85 20 1163.85H8C6.34315 1163.85 5 1162.5 5 1160.85V1149.77Z"
          fill="white"
        />
        <path
          d="M5 1179.85C5 1178.19 6.34315 1176.85 8 1176.85H20C21.6569 1176.85 23 1178.19 23 1179.85V1190.92C23 1192.58 21.6569 1193.92 20 1193.92H8C6.34315 1193.92 5 1192.58 5 1190.92V1179.85Z"
          fill="white"
        />
        <path
          d="M5 1209.92C5 1208.27 6.34315 1206.92 8 1206.92H20C21.6569 1206.92 23 1208.27 23 1209.92V1221C23 1222.66 21.6569 1224 20 1224H8C6.34315 1224 5 1222.66 5 1221V1209.92Z"
          fill="white"
        />
        <path
          d="M5 1240C5 1238.34 6.34315 1237 8 1237H20C21.6569 1237 23 1238.34 23 1240V1251.08C23 1252.73 21.6569 1254.08 20 1254.08H8C6.34315 1254.08 5 1252.73 5 1251.08V1240Z"
          fill="white"
        />
        <path
          d="M5 1270.08C5 1268.42 6.34315 1267.08 8 1267.08H20C21.6569 1267.08 23 1268.42 23 1270.08V1281.15C23 1282.81 21.6569 1284.15 20 1284.15H8C6.34315 1284.15 5 1282.81 5 1281.15V1270.08Z"
          fill="white"
        />
        <path
          d="M5 1300.15C5 1298.5 6.34315 1297.15 8 1297.15H20C21.6569 1297.15 23 1298.5 23 1300.15V1311.23C23 1312.89 21.6569 1314.23 20 1314.23H8C6.34315 1314.23 5 1312.89 5 1311.23V1300.15Z"
          fill="white"
        />
        <path
          d="M5 1330.23C5 1328.57 6.34315 1327.23 8 1327.23H20C21.6569 1327.23 23 1328.57 23 1330.23V1341.31C23 1342.96 21.6569 1344.31 20 1344.31H8C6.34315 1344.31 5 1342.96 5 1341.31V1330.23Z"
          fill="white"
        />
        <path
          d="M5 1360.31C5 1358.65 6.34315 1357.31 8 1357.31H20C21.6569 1357.31 23 1358.65 23 1360.31V1371.38C23 1373.04 21.6569 1374.38 20 1374.38H8C6.34315 1374.38 5 1373.04 5 1371.38V1360.31Z"
          fill="white"
        />
        <path
          d="M5 1390.38C5 1388.73 6.34315 1387.38 8 1387.38H20C21.6569 1387.38 23 1388.73 23 1390.38V1401.46C23 1403.12 21.6569 1404.46 20 1404.46H8C6.34315 1404.46 5 1403.12 5 1401.46V1390.38Z"
          fill="white"
        />
        <path
          d="M5 1420.46C5 1418.8 6.34315 1417.46 8 1417.46H20C21.6569 1417.46 23 1418.8 23 1420.46V1431.54C23 1433.19 21.6569 1434.54 20 1434.54H8C6.34315 1434.54 5 1433.19 5 1431.54V1420.46Z"
          fill="white"
        />
        <path
          d="M5 1450.54C5 1448.88 6.34315 1447.54 8 1447.54H20C21.6569 1447.54 23 1448.88 23 1450.54V1461.61C23 1463.27 21.6569 1464.61 20 1464.61H8C6.34315 1464.61 5 1463.27 5 1461.61V1450.54Z"
          fill="white"
        />
        <path
          d="M5 1480.61C5 1478.96 6.34315 1477.61 8 1477.61H20C21.6569 1477.61 23 1478.96 23 1480.61V1491.69C23 1493.35 21.6569 1494.69 20 1494.69H8C6.34315 1494.69 5 1493.35 5 1491.69V1480.61Z"
          fill="white"
        />
        <path
          d="M5 1510.69C5 1509.03 6.34315 1507.69 8 1507.69H20C21.6569 1507.69 23 1509.03 23 1510.69V1521.77C23 1523.43 21.6569 1524.77 20 1524.77H8C6.34315 1524.77 5 1523.43 5 1521.77V1510.69Z"
          fill="white"
        />
        <path
          d="M5 1540.77C5 1539.11 6.34315 1537.77 8 1537.77H20C21.6569 1537.77 23 1539.11 23 1540.77V1551.85C23 1553.5 21.6569 1554.85 20 1554.85H8C6.34315 1554.85 5 1553.5 5 1551.85V1540.77Z"
          fill="white"
        />
        <path
          d="M5 1570.85C5 1569.19 6.34315 1567.85 8 1567.85H20C21.6569 1567.85 23 1569.19 23 1570.85V1581.92C23 1583.58 21.6569 1584.92 20 1584.92H8C6.34315 1584.92 5 1583.58 5 1581.92V1570.85Z"
          fill="white"
        />
        <path
          d="M5 1600.92C5 1599.27 6.34315 1597.92 8 1597.92H20C21.6569 1597.92 23 1599.27 23 1600.92V1612C23 1613.66 21.6569 1615 20 1615H8C6.34315 1615 5 1613.66 5 1612V1600.92Z"
          fill="white"
        />
        <path
          d="M5 1631C5 1629.34 6.34315 1628 8 1628H20C21.6569 1628 23 1629.34 23 1631V1642.08C23 1643.73 21.6569 1645.08 20 1645.08H8C6.34315 1645.08 5 1643.73 5 1642.08V1631Z"
          fill="white"
        />
        <path
          d="M5 1661.08C5 1659.42 6.34315 1658.08 8 1658.08H20C21.6569 1658.08 23 1659.42 23 1661.08V1672.15C23 1673.81 21.6569 1675.15 20 1675.15H8C6.34315 1675.15 5 1673.81 5 1672.15V1661.08Z"
          fill="white"
        />
        <path
          d="M5 1691.15C5 1689.5 6.34315 1688.15 8 1688.15H20C21.6569 1688.15 23 1689.5 23 1691.15V1702.23C23 1703.89 21.6569 1705.23 20 1705.23H8C6.34315 1705.23 5 1703.89 5 1702.23V1691.15Z"
          fill="white"
        />
        <path
          d="M5 1721.23C5 1719.57 6.34315 1718.23 8 1718.23H20C21.6569 1718.23 23 1719.57 23 1721.23V1732.31C23 1733.96 21.6569 1735.31 20 1735.31H8C6.34315 1735.31 5 1733.96 5 1732.31V1721.23Z"
          fill="white"
        />
        <path
          d="M5 1751.31C5 1749.65 6.34315 1748.31 8 1748.31H20C21.6569 1748.31 23 1749.65 23 1751.31V1762.38C23 1764.04 21.6569 1765.38 20 1765.38H8C6.34315 1765.38 5 1764.04 5 1762.38V1751.31Z"
          fill="white"
        />
        <path
          d="M5 1781.38C5 1779.73 6.34315 1778.38 8 1778.38H20C21.6569 1778.38 23 1779.73 23 1781.38V1792.46C23 1794.12 21.6569 1795.46 20 1795.46H8C6.34315 1795.46 5 1794.12 5 1792.46V1781.38Z"
          fill="white"
        />
        <path
          d="M5 1811.46C5 1809.8 6.34315 1808.46 8 1808.46H20C21.6569 1808.46 23 1809.8 23 1811.46V1822.54C23 1824.19 21.6569 1825.54 20 1825.54H8C6.34315 1825.54 5 1824.19 5 1822.54V1811.46Z"
          fill="white"
        />
        <path
          d="M5 1841.54C5 1839.88 6.34315 1838.54 8 1838.54H20C21.6569 1838.54 23 1839.88 23 1841.54V1852.61C23 1854.27 21.6569 1855.61 20 1855.61H8C6.34315 1855.61 5 1854.27 5 1852.61V1841.54Z"
          fill="white"
        />
        <path
          d="M5 1871.61C5 1869.96 6.34315 1868.61 8 1868.61H20C21.6569 1868.61 23 1869.96 23 1871.61V1882.69C23 1884.35 21.6569 1885.69 20 1885.69H8C6.34315 1885.69 5 1884.35 5 1882.69V1871.61Z"
          fill="white"
        />
        <path
          d="M5 1901.69C5 1900.03 6.34315 1898.69 8 1898.69H20C21.6569 1898.69 23 1900.03 23 1901.69V1912.77C23 1914.43 21.6569 1915.77 20 1915.77H8C6.34315 1915.77 5 1914.43 5 1912.77V1901.69Z"
          fill="white"
        />
        <path
          d="M5 1931.77C5 1930.11 6.34315 1928.77 8 1928.77H20C21.6569 1928.77 23 1930.11 23 1931.77V1942.84C23 1944.5 21.6569 1945.84 20 1945.84H8C6.34315 1945.84 5 1944.5 5 1942.84V1931.77Z"
          fill="white"
        />
        <path
          d="M5 1961.85C5 1960.19 6.34315 1958.85 8 1958.85H20C21.6569 1958.85 23 1960.19 23 1961.85V1972.92C23 1974.58 21.6569 1975.92 20 1975.92H8C6.34315 1975.92 5 1974.58 5 1972.92V1961.85Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="140"
      height="2114"
      viewBox="0 0 140 2114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M0 2113.7V0H140V1821.52L0 2113.7Z" fill={color} />
    </svg>
  )
}
