import React from 'react'
import Grid from '@material-ui/core/Grid'
import DataSortings from './DataSortings'
import { makeStyles } from '@material-ui/core'
import CustomTable from '../../../CustomTable'
import InfoPanelItem from '../InfoPanelItem'
import { getUploadDate, parseFileSize } from '../../../../utils/utils'
import useFilesModal from './hooks/useFilesModal'
import FilesModal from './components/FilesModal'

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 55,
    flex: 1,
    width: '100%'
  },
  body: {
    marginBottom: 30,
    flex: 1,
    flexDirection: 'column'
  },
  table: {
    paddingBottom: 15
  }
}))

const RecordingBody = () => {
  const classes = useStyles()
  const { toggleModal, columns, rows, ...filesModalProps } = useFilesModal()

  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      className={classes.container}
    >
      <Grid item xs={12} className={classes.body}>
        <DataSortings />
      </Grid>

      <Grid item className={classes.table}>
        <InfoPanelItem title="Files" justify="flex-start" onClick={toggleModal}>
          <CustomTable columns={columns} rows={rows} />
        </InfoPanelItem>
      </Grid>
      <FilesModal
        toggleModal={toggleModal}
        columns={columns}
        rows={rows}
        {...filesModalProps}
      />
    </Grid>
  )
}

export default RecordingBody
