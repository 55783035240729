import { useDispatch, useSelector } from 'react-redux'
import { getOr, last } from 'lodash/fp'
import { useCallback, useMemo } from 'react'
import { toggleSuggestionVisibility } from '../../../redux/actions'

function useClusterSimilarityStore() {
  const dispatch = useDispatch()

  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )
  const { id: sortingId } = selectedSorting || {}

  const { clusters: allClusters, similarity: allSimilarity } = useSelector(
    state => state.widgetsCache
  )
  const { cluster: clusterUnits, similarity: similarityUnits } = useSelector(
    state => state.widgetsCache.units
  )
  const selectedCuration = useSelector(
    state => state.recordingStore.selectedCuration
  )
  const {
    isCurationAI,
    recommendations: recommendationsStore,
    hiddenRecommendations
  } = useSelector(state => state.widgetsCache.curationAI)
  const {
    sortBy: clustersSortBy,
    secondSortBy: clustersSecondSortBy,
    isTwoColumnSort: isClusterColumnLocked,
    sortDirection: clustersSortDirection
  } = allClusters
  const { sortBy: similaritySortBy, sortDirection: similaritySortDirection } =
    allSimilarity

  const curationId = getOr(null, ['id'], selectedCuration)

  const defaultClusterKeys = [
    sortingId,
    curationId,
    clustersSortBy,
    clustersSortDirection
  ]

  const isSecondSortByExists = clustersSecondSortBy ?? false
  const clusterKeys = isSecondSortByExists
    ? [...defaultClusterKeys, clustersSecondSortBy]
    : defaultClusterKeys

  const keyClusterData = clusterKeys.join('-')

  const keySimilarityData = [
    sortingId,
    curationId,
    similaritySortBy,
    similaritySortDirection
  ].join('-')

  const dataId = last(clusterUnits)
  const storedSimilarityData = getOr(
    null,
    [keySimilarityData, dataId],
    allSimilarity
  )
  const storedClustersData = getOr(null, [keyClusterData], allClusters)

  const path = ['data', 'clusters']
  const clustersList = getOr(null, path, storedClustersData)
  const similarityList = getOr(null, path, storedSimilarityData)

  const ID = [sortingId, curationId].join('-')

  const recommendations = useMemo(() => {
    if (!isCurationAI) return {}
    return recommendationsStore.get(ID) ?? {}
  }, [ID, isCurationAI, recommendationsStore])

  const { merge_recommendations, noise_recommendations: noiseRecommendations } =
    recommendations

  const toggleRecommendation = useCallback(
    id => dispatch(toggleSuggestionVisibility(id)),
    [dispatch]
  )

  const isSuggestionVisible = useCallback(
    id => !hiddenRecommendations.get(id),
    [hiddenRecommendations]
  )

  return {
    storedSimilarityData,
    storedClustersData,
    clustersList,
    similarityList,
    clusterUnits,
    similarityUnits,
    curationId,
    sortingId,
    keyClusterData,
    keySimilarityData,
    isClusterColumnLocked,
    clustersSortBy,
    clustersSecondSortBy,
    clustersSortDirection,
    similaritySortBy,
    similaritySortDirection,
    allSimilarity,

    //curation ai
    recommendations: merge_recommendations,
    noiseRecommendations,
    toggleRecommendation,
    isSuggestionVisible,
    hiddenRecommendations,
    isCurationAI
  }
}

export default useClusterSimilarityStore
