import React from 'react'
import { Grid } from '@material-ui/core'

export const CrossCorrelogramXAxis = ({ data, width }) => {
  return (
    <Grid style={{ justifyContent: 'space-evenly' }} container>
      {data.map(u => {
        return (
          <div
            key={`XAxis-${u}`}
            style={{
              width: `${width}px`,
              textAlign: 'center'
            }}
          >
            {u}
          </div>
        )
      })}
    </Grid>
  )
}
