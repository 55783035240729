import React, { memo } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import useSection from '../../hooks/useSection'
import MapData from '../../../../../../../utils/MapData'
import Range from '../Range'
import useNewTimeseriesWindow from '../../../../../../../containers/NewTimeSeries/hooks/useNewTimeseriesWindow'
import { useLocalStorage } from 'usehooks-ts'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerText: {
    fontWeight: 600,
    fontSize: '14',
    marginTop: 9,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      'white'
    )
  },
  children: ({ isAccordionOpen }) => ({
    display: isAccordionOpen ? 'block' : 'none'
  }),
  borderLine: ({ isNeuronal }) => ({
    position: 'absolute',
    height: '100%',
    backgroundColor: isNeuronal
      ? theme.palette.colors.customColor('#071740', 'white')
      : theme.palette.colors.customColor(
          '#828A9F',
          theme.palette.colors.neutral[7]
        ),
    width: 4,
    top: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
  }),
  root: {
    position: 'relative',
    backgroundColor: theme.palette.colors.backgrounds.main,
    padding: '0px 18px',
    marginBottom: 8,
    paddingBottom: 12,
    width: '258px'
  },
  actionIcon: {
    height: 20,
    width: 20,
    color: theme.palette.colors.neutral[5],
    cursor: 'pointer'
  }
}))

const RangeAccordion = ({ title, isNeuronal }) => {
  const {
    ranges,
    isAccordionOpen,
    toggleAccordionOpen,
    mainRange,
    setMainRange
  } = useSection({
    isNeuronal
  })
  const classes = useStyles({ isAccordionOpen, isNeuronal })

  const { handleOpenNewWindow } = useNewTimeseriesWindow()

  const params = useParams()
  const recordingId = params.recordingId

  const [windows] = useLocalStorage(`child-windows-${recordingId}`)
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerText}>{title}</Typography>
        <KeyboardArrowDownIcon
          className={classes.actionIcon}
          onClick={toggleAccordionOpen}
        />
      </div>
      <div className={classes.children}>
        <MapData data={ranges}>
          {({ item }, i) => (
            <Range
              key={i}
              item={item}
              isSelected={item === mainRange}
              isOpened={windows && windows[item]}
              onClickRange={() => setMainRange(item)}
              onClick={() => handleOpenNewWindow(item)}
            />
          )}
        </MapData>
      </div>
      <span className={classes.borderLine} />
    </div>
  )
}

export default memo(RangeAccordion)
