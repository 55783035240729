import { ADD_MODELS, DELETE_MODEL, EDIT_MODEL } from '../actions/aiModels'

const initialState = {
  records: null,
  filter: null,
  pages: 1
}

const aiModels = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_MODELS: {
      const { data, ...res } = payload || {}
      return { ...state, records: data, ...res }
    }
    case EDIT_MODEL: {
      const { id, ...value } = payload || {}
      const allRecords = state.records
      const fileIndex = allRecords.findIndex(item => item.id === id)
      const updatedItem = {
        ...allRecords[fileIndex],
        ...value
      }
      const updatedFiles = [
        ...allRecords.slice(0, fileIndex),
        updatedItem,
        ...allRecords.slice(fileIndex + 1)
      ]

      return { ...state, records: updatedFiles }
    }
    case DELETE_MODEL: {
      const { ids } = payload || {}
      let newRecords = state.records.filter(rec => ids.indexOf(rec.id) === -1)
      return { ...state, records: newRecords }
    }

    default:
      return state
  }
}

export default aiModels
