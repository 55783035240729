import React, { useEffect, useState } from 'react'
import ActionButton from '../../../../../ActionButton'
import CustomModal from '../../../../../CustomModal'
import LabelIcon from '@material-ui/icons/Label'
import CustomTag from '../../../../../CustomTag'
import { makeStyles } from '@material-ui/core/styles'
import { MODALS_ID, RECORD_TABS } from '../../../../../../utils/consts'
import useToggleModal from '../../../../../../hooks/useToggleModal'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  saveBtn: {
    backgroundColor: theme.palette.action.secondary
  }
}))

const EditTagsPublicationsModal = ({
  loading,
  handleSave = () => null,
  isLimited = false
}) => {
  const classes = useStyles()
  const {
    toggle: toggleModal,
    isOpened,
    modalState
  } = useToggleModal(MODALS_ID.EDIT_TAGS_PUBLICATIONS_MODAL)
  const { type, colorAccent } = modalState?.args || {}
  const chips = useSelector(state => state.recordingStore[type])
  const title = type === RECORD_TABS.TAGS ? 'Tags' : 'Publications'
  const label = type === RECORD_TABS.TAGS ? 'Tag' : 'Publications Ref'
  const [localTags, updateTags] = useState(chips)

  useEffect(() => {
    if (isOpened) updateTags(chips)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened])

  return (
    <CustomModal
      open={isOpened}
      title={title}
      onClose={toggleModal}
      maxWidth={555}
      headerIcon={<LabelIcon />}
      renderActionButton={
        <ActionButton
          label="Save"
          loading={loading}
          onClick={() => handleSave(localTags)}
          className={classes.saveBtn}
          disabled={isLimited}
        />
      }
    >
      <CustomTag
        tags={localTags}
        label={label}
        colorAccent={colorAccent}
        updateTags={updateTags}
      />
    </CustomModal>
  )
}

export default EditTagsPublicationsModal
