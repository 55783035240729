import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import SingleCommentCard from './components/SingleCommentCard/SingleCommentCard'
import { connect } from 'react-redux'
import MapData from '../../utils/MapData'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0
  },
  inline: {
    display: 'inline'
  },
  listItem: {
    padding: 0,
    marginBottom: 28,
    '&:last-child': {
      marginBottom: 0
    }
  },
  noData: {
    fontSize: 20,
    lineHeight: '24px',
    color: theme.palette.colors.neutral.black
  }
}))

const CommentsCardList = ({ currentUser, comments, onDelete, onEdit }) => {
  const classes = useStyles()
  const currentUserId = currentUser?.user.id

  if (comments?.length === 0) {
    return <Typography className={classes.noData}>No comments yet</Typography>
  }

  return (
    <List className={classes.root}>
      <MapData data={comments}>
        {({ item: { id, ...comment }, index }) => (
          <ListItem
            key={index}
            alignItems="flex-start"
            className={classes.listItem}
          >
            <SingleCommentCard
              currentUserId={currentUserId}
              onDelete={() => onDelete(id)}
              onEdit={value => onEdit(id, value)}
              {...comment}
            />
          </ListItem>
        )}
      </MapData>
    </List>
  )
}

const mapStateToProps = state => ({
  currentUser: state.login.currentUser
})

export default connect(mapStateToProps)(CommentsCardList)
