import React, { memo, useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { getUploadDate } from '../../../../utils/utils'
import CustomInput from '../../../FormComponents/CustomInput/CustomInput'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    width: '100%'
  },
  cardHeader: {
    padding: 0
  },
  avatar: {
    width: 32,
    height: 32,
    fontSize: 14
  },
  title: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral.black,
      theme.palette.colors.neutral['8']
    )
  },
  subheader: {
    fontSize: 11,
    lineHeight: '16px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['5'],
      theme.palette.colors.neutral['8']
    )
  },
  iconBtn: {
    padding: 8,
    '& svg': {
      color: theme.palette.colors.customColor(
        theme.palette.colors.neutral['5'],
        theme.palette.colors.neutral['8']
      )
    }
  },
  cardContent: {
    padding: '12px 0',
    borderBottom: `1px solid ${theme.palette.colors.neutral['7']}`
  },
  comment: {
    overflowWrap: 'break-word',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['1'],
      theme.palette.colors.neutral['8']
    )
  },

  action: {
    margin: 0
  }
}))

const SingleCommentCard = props => {
  const {
    user_id,
    user_name,
    created_at,
    user_photo,
    currentUserId,
    onDelete,
    onEdit,
    value
  } = props
  const [isEdit, setEditMode] = useState(false)
  const [comment, editOldComments] = useState('')
  const hasPermissions = currentUserId === user_id
  const classes = useStyles()

  useEffect(() => {
    editOldComments(value)
  }, [value])

  const handleEdit = useCallback(() => {
    if (isEdit) {
      onEdit(comment)
      setEditMode(false)
    } else setEditMode(true)
  }, [comment, isEdit, onEdit])

  const renderActions = useCallback(() => {
    return (
      <>
        <IconButton onClick={onDelete} className={classes.iconBtn}>
          <DeleteIcon />
        </IconButton>
        <IconButton onClick={handleEdit} className={classes.iconBtn}>
          <EditIcon />
        </IconButton>
      </>
    )
  }, [classes.iconBtn, handleEdit, onDelete])

  const handleChange = useCallback(e => editOldComments(e.target.value), [])

  const handleKeyPress = useCallback(
    e => {
      if (e.key?.toLowerCase() === 'enter' && comment?.length > 0) {
        handleEdit()
      }
    },
    [comment, handleEdit]
  )

  return (
    <Card className={classes.root} elevation={0}>
      <CardHeader
        avatar={
          <Avatar alt={user_name} src={user_photo} className={classes.avatar} />
        }
        action={hasPermissions && renderActions()}
        title={user_name}
        className={classes.cardHeader}
        subheader={getUploadDate(created_at, true)}
        classes={{
          action: classes.action,
          title: classes.title,
          subheader: classes.subheader
        }}
      />
      <CardContent className={classes.cardContent}>
        {isEdit ? (
          <CustomInput
            fullWidth
            multiline
            value={comment}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            label="Edit the comment"
          />
        ) : (
          <Typography className={classes.comment}>{comment}</Typography>
        )}
      </CardContent>
    </Card>
  )
}

export default memo(SingleCommentCard)
