import React from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.colors.backgrounds.contrast,
    padding: '0 10px 0 16px',
    borderRadius: 8,
    width: 265
  },
  input: () => {
    const color = theme.palette.colors.customColor(
      theme.palette.colors.neutral.black_80,
      theme.palette.colors.white_80
    )
    return {
      height: 36,
      padding: 0,
      fontSize: 13,
      color,
      '&::placeholder': {
        color,
        opacity: 1
      },
      '&::-webkit-input-placeholder': { color },
      '&::-moz-placeholder': { color },
      '&:-ms-input-placeholder': {
        color
      },
      '&:-moz-placeholder': { color }
    }
  },
  endAdornment: {
    display: 'flex',
    marginLeft: 10,
    '& button': {
      padding: 6
    },
    '& svg': {
      color: theme.palette.colors.customColor(
        theme.palette.colors.neutral['5'],
        theme.palette.colors.white
      ),
      fontSize: 20
    }
  }
}))

const SearchInput = props => {
  const {
    className,
    onChange,
    value,
    inputBaseRootClass,
    customInputStyle,
    endAdornment,
    placeholder = 'Search for a file name...',
    ...rest
  } = props || {}
  const classes = useStyles()
  return (
    <div className={cn(classes.wrapper, className)}>
      <InputBase
        type="text"
        placeholder={placeholder}
        name="search"
        className={cn(classes.root, inputBaseRootClass)}
        classes={{
          input: cn(classes.input, customInputStyle)
        }}
        endAdornment={
          endAdornment && (
            <div className={classes.endAdornment}>{endAdornment}</div>
          )
        }
        onChange={onChange}
        value={value}
        {...rest}
      />
    </div>
  )
}

export default SearchInput
