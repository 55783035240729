import React from 'react'
import { SPIKESORTING_STATUS } from '../../../../../utils/consts'
import { makeStyles, Typography } from '@material-ui/core'
import cn from 'classnames'
import { Error } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  status: ({ status }) =>
    theme.status({
      background: theme.spikesorting[status]?.bg,
      color: theme.spikesorting[status]?.color
    }),
  statusError: () => ({
    fontSize: 14,
    background: 'transparent',
    color: theme.palette.colors.status.error,
    '& svg': {
      marginRight: 8,
      verticalAlign: 'middle'
    }
  })
}))

const SortingStatus = ({ status }) => {
  const classes = useStyles({ status: SPIKESORTING_STATUS[status]?.code })
  const isError = status === 'FAILURE'

  return (
    <Typography
      className={cn(classes.status, {
        [classes.statusError]: isError
      })}
      noWrap
    >
      {isError && <Error />}
      {SPIKESORTING_STATUS[status]?.label}
    </Typography>
  )
}

export default SortingStatus
