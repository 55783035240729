import React, { useState } from 'react'
import { Box, Typography, Divider, makeStyles } from '@material-ui/core'
import cn from 'classnames'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles(theme => ({
  root: {
    width: 240
  },
  item: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    padding: '16px 12px',
    cursor: 'pointer',
    background: theme.palette.colors.backgrounds.fileHover
  },
  name: {
    fontSize: 13
  },
  active: {
    height: 60,

    '& $square': {
      background: theme.palette.primary.main
    }
  },
  blocked: {
    opacity: 0.4
  },
  square: {
    width: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.colors.neutral[6],
    borderRadius: 4,
    padding: 8,
    textTransform: 'uppercase',
    color: 'white',
    marginRight: 8
  },
  icon: {
    marginLeft: 'auto'
  },
  iconExpanded: {
    transform: 'rotate(180deg)'
  }
}))

const CompanyBox = ({ name, onClick, children, status = 1 }) => {
  const classes = useStyles()
  const getFirstCharacters = (text = '') => {
    if (!text) return ''
    const result =
      text.split(' ')?.length > 1 ? text.match(/\b(\w)/g).join('') : text
    return result.slice(0, 2)
  }

  const callback = status ? onClick : null

  return (
    <Box
      className={cn(classes.item, {
        [classes.blocked]: !status
      })}
      onClick={callback}
    >
      <span className={classes.square}>{getFirstCharacters(name)}</span>
      <Typography noWrap className={classes.name}>
        {name}
      </Typography>
      {children}
    </Box>
  )
}

export default function OrganizationSwitch({
  selected,
  companies = [],
  onSelect = () => null
}) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const { name = 'Select company', id: companyId } = selected || {}

  const companiesArr = companies?.filter(item => item.id !== companyId) || []

  const handleExpandToggle = () => {
    if (companiesArr.length === 0) return
    setExpanded(prevState => !prevState)
  }

  return (
    <Box className={classes.root}>
      {expanded &&
        companiesArr.map(company => {
          const { id, name, status } = company
          return (
            <CompanyBox
              key={id}
              name={name}
              status={status}
              onClick={() => onSelect(id)}
            />
          )
        })}
      <Divider />
      <CompanyBox
        name={name}
        onClick={handleExpandToggle}
        className={classes.active}
      >
        {companiesArr.length > 0 && (
          <ExpandMoreIcon
            className={cn(classes.icon, {
              [classes.iconExpanded]: expanded
            })}
          />
        )}
      </CompanyBox>
    </Box>
  )
}
