import { useDispatch } from 'react-redux'

export const useReinitClusters = () => {
  const dispatch = useDispatch()

  return ({ arr, type, multiple }) => {
    for (let i = 0; i < arr.length; i++) {
      const unit = arr[i]
      setTimeout(() => {
        dispatch({
          type,
          payload: { multiple: multiple ?? arr.length > 1, unit_id: unit }
        })
      }, 250)
    }
  }
}
