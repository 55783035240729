import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import { useCallback, useState } from 'react'
import {
  MODALS_ID,
  NOTIFICATION,
  PARAMETER_OPTIONS,
  REQUIRED,
  UPLOAD_PRBFILE_METHODS
} from '../../../../../utils/consts'
import useToggleModal from '../../../../../hooks/useToggleModal'
import {
  queryUploadExpInputs,
  uploadProbeFile
} from '../../../../../endpoints/admin'
import { normalizeFormValues, parseXml } from '../../../../../utils/utils'
import { object, string } from 'yup'

function useUploadExperimentsInputModal({ oldValues, onEdit, onSuccess }) {
  const addNotification = useNotification()

  // state
  const [loading, setLoading] = useState(false)
  const [parametersFile, setParamFile] = useState(null)
  const [parametersOption, setParametersOptions] = useState(
    PARAMETER_OPTIONS.MANUALLY
  )
  const [uploadProbeOption, setUploadProbeOption] = useState(
    UPLOAD_PRBFILE_METHODS.DEFAULT
  )

  const { UPLOAD_EXPERIMENTS_INPUT_MODAL } = MODALS_ID
  const { toggle: toggleModal, isOpened } = useToggleModal(
    UPLOAD_EXPERIMENTS_INPUT_MODAL
  )

  const handleSaveUpload = useCallback(
    async (values, actions) => {
      try {
        setLoading(true)
        const { tags: t, prb_file, ...rest } = values
        const data = {
          ...rest,
          prb_file:
            uploadProbeOption === UPLOAD_PRBFILE_METHODS.NONE ? null : prb_file,
          ...(t?.length > 0
            ? { tags: JSON.stringify(t.map(item => item.label)) }
            : oldValues
            ? { tags: '[]' }
            : {})
        }
        if (oldValues) {
          await onEdit({
            data,
            onSuccess: toggleModal
          })
          setLoading(false)
          return
        }

        const response = await queryUploadExpInputs({
          method: 'POST',
          data
        })
        if (response?.data) {
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'New Upload experiment input was successfully created!'
          })
          actions.resetForm()
          toggleModal()
          onSuccess()
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        addNotification({
          type: NOTIFICATION.ERROR,
          title: 'Failed to create upload experiment input !',
          message: e
        })
      }
    },
    [
      uploadProbeOption,
      oldValues,
      onEdit,
      toggleModal,
      addNotification,
      onSuccess
    ]
  )

  const handleUploadProbFile = useCallback(
    async (values, actions) => {
      try {
        setLoading(true)
        const submitValues = normalizeFormValues({
          probe_file: values.prb_file
        })
        const response = await uploadProbeFile(submitValues)
        const pfile = response?.msg
        if (pfile && ['.json', '.prb'].some(item => pfile.includes(item))) {
          handleSaveUpload({ ...values, prb_file: pfile }, actions)
        } else {
          addNotification({
            type: NOTIFICATION.ERROR,
            title: 'Failed to upload probe file'
          })
          setLoading(false)
        }
      } catch (e) {
        setLoading(false)
        addNotification({
          type: NOTIFICATION.ERROR,
          title: 'Failed to upload probe file'
        })
      }
    },
    [addNotification, handleSaveUpload]
  )

  const {
    company_id,
    name,
    sample_rate,
    number_channels,
    prb_file,
    species_id,
    tags,
    dtype,
    probe_id
  } = oldValues || {}

  const initialValues = {
    company_id: company_id || '',
    name: name || '',
    sample_rate: sample_rate || null,
    number_channels: number_channels || null,
    prb_file: prb_file || null,
    species_id: species_id || null,
    dtype: dtype || 'int16',
    probe_id: probe_id || null,
    tags: tags ? tags.map(({ id, value }) => ({ label: value, id })) : []
  }

  const handleSubmit = (values, actions) => {
    const isFile = values?.prb_file?.name
    if (uploadProbeOption === UPLOAD_PRBFILE_METHODS.CUSTOM && isFile) {
      handleUploadProbFile(values, actions)
    } else handleSaveUpload(values, actions)
  }

  const resetParams = (cb = () => null) => {
    cb(values => ({ ...values, number_channels: null, sample_rate: null }))
    setParamFile(null)
  }

  const parseFile = async (file, setValues) => {
    if (!file) {
      resetParams(setValues)
      return
    }
    const json = await parseXml(file)

    if (json?.parameters?.acquisitionSystem) {
      const { nChannels, samplingRate } = json.parameters.acquisitionSystem

      if (nChannels && samplingRate) {
        setValues(values => ({
          ...values,
          number_channels: nChannels,
          sample_rate: samplingRate
        }))
        setParamFile(file)

        return true
      }
    }

    return addNotification({
      type: NOTIFICATION.ERROR,
      title: 'Invalid file '
    })
  }

  const handleChangeOption = useCallback((event, type) => {
    const { value } = event.target
    const fn = type === 'params' ? setParametersOptions : setUploadProbeOption
    if (value) fn(value)
  }, [])

  const validation = object({
    name: string().required(REQUIRED)
  })

  const parametersOptions = [
    {
      value: PARAMETER_OPTIONS.FILE,
      label: 'Take parameters from file'
    },
    {
      value: PARAMETER_OPTIONS.MANUALLY,
      label: 'Add manually'
    }
  ]

  const uploadProbeOptions = [
    {
      value: UPLOAD_PRBFILE_METHODS.DEFAULT,
      label: 'Use default DBC Probe files'
    },
    {
      value: UPLOAD_PRBFILE_METHODS.CUSTOM,
      label: 'Use my probe map'
    },
    {
      value: UPLOAD_PRBFILE_METHODS.NONE,
      label: 'I don’t use any probe file'
    }
  ]

  return {
    loading,
    isOpened,
    toggleModal,
    handleSubmit,
    initialValues,
    validation,

    // parameters
    parametersOption,
    parametersOptions,
    handleChangeOption,

    // probe file
    uploadProbeOption,
    uploadProbeOptions,

    parametersFile,
    parseFile,
    resetParams
  }
}

export default useUploadExperimentsInputModal
