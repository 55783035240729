export const ADD_MODELS = 'ADD_MODELS'
export const EDIT_MODEL = 'EDIT_MODEL'
export const DELETE_MODEL = 'DELETE_MODEL'

export const addModels = data => ({
  type: ADD_MODELS,
  payload: data
})

export const editModel = data => ({
  type: EDIT_MODEL,
  payload: data
})
export const deleteModel = data => ({
  type: DELETE_MODEL,
  payload: data
})
