import { useCallback } from 'react'
import { object, string } from 'yup'
import { editOrganisation } from '../../../endpoints/admin'
import {
  INVALID_EMAIL,
  MAX_120_CHARS,
  NOTIFICATION,
  REQUIRED
} from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { isEqual } from '../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { storeCurrentUser } from '../../../actions/login'

function useEditOrganisation() {
  const addNotification = useNotification()
  const currentUser = useSelector(state => state.login.currentUser)
  const dispatch = useDispatch()
  const storeUserInfo = useCallback(
    user => dispatch(storeCurrentUser(user)),
    [dispatch]
  )

  const { id, name, email, address } = currentUser?.user?.company || {}

  const initialValues = {
    id: id,
    name: name || '',
    email: email || '',
    address: address || ''
  }

  const updateOrganisationInfo = useCallback(
    values => {
      const { user, companies } = currentUser || {}

      const updatedCompanies = companies?.map(company =>
        company.id === values.id ? values : company
      )
      const updated = {
        user: {
          ...user,
          company: {
            ...user?.company,
            ...values
          }
        },
        companies: updatedCompanies
      }
      storeUserInfo(updated)
    },
    [currentUser, storeUserInfo, currentUser?.company]
  )

  const onSubmit = useCallback(
    async values => {
      try {
        const response = await editOrganisation({
          id,
          ...values
        })

        if (response) {
          updateOrganisationInfo(response)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Organisation information successfully edited'
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, updateOrganisationInfo]
  )

  const checkForChanges = useCallback(
    ({ id, ...values }, oldVal) =>
      Object.entries(values).some(([k, v]) => !isEqual(v, oldVal[k])),
    []
  )

  const validation = object({
    name: string().max(120, MAX_120_CHARS).required(REQUIRED),
    email: string()
      .max(120, MAX_120_CHARS)
      .email(INVALID_EMAIL)
      .required(REQUIRED),
    address: string().max(120, MAX_120_CHARS)
  })

  return {
    initialValues,
    onSubmit,
    validation,
    checkForChanges
  }
}

export default useEditOrganisation
