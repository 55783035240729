import React, { memo, useCallback } from 'react'
import {
  CircularProgress,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core'
import CustomCheckBox from '../../../FormComponents/CustomCheckbox/CustomCheckbox'
import {
  Delete,
  Folder,
  TextFields,
  GetApp,
  InsertDriveFile,
  OpenWith
} from '@material-ui/icons'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'
import BodyColumn from '../../../CustomTable/BodyColumn'
import RenameFileModal from '../../../Files/components/NewFolderModal/NewFolderModal'
import useRenameFileModal from '../../hooks/useRenameFileModal'
import { QPARAMS, RECORD_TYPE } from '../../../../utils/consts'
import useSearchQuery from '../../../../hooks/useSearchQuery'
import MoveToModal from '../../../Files/components/MoveToModal'
import useMoveToModal from '../../../Files/hooks/useMoveToModal'
import ProgressBar from '../../../ProgressBar/ProgressBar'
import useProgressHandler from '../../hooks/useProgressHandler'
import { useDownloadFilesModal } from '../../../RecordingViewWithCuration/components/WidgetsLayout/hooks'
import useLimitedAccess from '../../../../hooks/useLimitedAccess'
import CurationAIIcon from '../../../Icons/curationAIIcon'

const BodyTable = ({
  rows,
  isSelected,
  classes,
  onSelect,
  deselectAll,
  onRowClick,
  onDelete,
  checkSelectDisabled,
  isSticky = false,
  customRenderActionButton,
  disableActionButtons = false,
  withRecordType = true,
  isUsingOldLogic,
  isExistingSelected,
  handleExsistingSelect
}) => {
  const history = useHistory()
  const { toggleModal: toggleRenameModal, ...restRenameModal } =
    useRenameFileModal()

  const { toggleModal: toggleMoveToModal, ...restMoveToModal } =
    useMoveToModal()
  const { deleteSearchQueryWithPushing } = useSearchQuery()
  const { handleDownloadRecord, loading } = useDownloadFilesModal()
  const { isLimited } = useLimitedAccess()

  const handleRowClick = useCallback(
    (id, type) => {
      return type === 'folder'
        ? deleteSearchQueryWithPushing({
          key: QPARAMS.FOLDER_ID,
          value: id,
          keyToDelete: [QPARAMS.PER_PAGE, QPARAMS.PAGE, QPARAMS.KEYWORD]
        })
        : history.push(`/recording/${id}`)
    },
    [history, deleteSearchQueryWithPushing]
  )

  const renderActionButton = useCallback(
    props => {
      const { path, id, name, type } = props
      if (customRenderActionButton) return customRenderActionButton(props)

      const isFolder = type === RECORD_TYPE.FOLDER
      return (
        <React.Fragment>
          <IconButton
            className={classes.actionBtn}
            onClick={e => {
              e.stopPropagation()
              return toggleRenameModal({ id, name, type })
            }}
            disabled={isLimited}
          >
            <TextFields />
          </IconButton>
          <IconButton
            className={classes.actionBtn}
            onClick={e => {
              e.stopPropagation()
              return toggleMoveToModal(id, type)
            }}
            disabled={isLimited}
          >
            <OpenWith />
          </IconButton>
          {!isFolder && (
            <IconButton
              className={classes.actionBtn}
              onClick={e => {
                e.stopPropagation()
                return handleDownloadRecord({ id, class_type: 'record' })
              }}
              disabled={isLimited}
            >
              {loading && loading?.index === 0 ? (
                <CircularProgress size={18} />
              ) : (
                <GetApp />
              )}
            </IconButton>
          )}

          <IconButton
            className={classes.actionBtn}
            onClick={e => {
              e.stopPropagation()
              return onDelete(id, type)
            }}
            disabled={isLimited}
          >
            <Delete />
          </IconButton>
        </React.Fragment>
      )
    },
    [
      classes.actionBtn,
      handleDownloadRecord,
      isLimited,
      loading,
      onDelete,
      toggleMoveToModal,
      toggleRenameModal
    ]
  )

  return (
    <TableBody>
      <RenameFileModal
        toggleModal={toggleRenameModal}
        title="Rename"
        action="edit"
        type="file"
        {...restRenameModal}
      />
      <MoveToModal
        deselectAll={deselectAll}
        toggleModal={toggleMoveToModal}
        {...restMoveToModal}
      />
      {rows &&
        rows.map((row, index) => {
          const { id, curation_id_passed, type } = row
          const isItemSelected = isUsingOldLogic ? isExistingSelected(id, type) : isSelected(curation_id_passed, type)
          return (
            <FileRow
              row={row}
              key={index}
              index={index}
              classes={classes}
              deselectAll={deselectAll}
              onSelect={onSelect}
              isSticky={isSticky}
              isItemSelected={isItemSelected}
              checkSelectDisabled={checkSelectDisabled}
              renderActionButton={renderActionButton}
              disableActionButtons={disableActionButtons}
              handleRowClick={onRowClick ?? handleRowClick}
              withRecordType={withRecordType}
              isUsingOldLogic={isUsingOldLogic}
              isExistingSelected={isExistingSelected}
              handleExsistingSelect={handleExsistingSelect}
            />
          )
        })}
    </TableBody>
  )
}

const FileRow = memo(
  ({
    row,
    classes,
    isSticky,
    onSelect,
    isItemSelected,
    handleRowClick,
    renderActionButton,
    checkSelectDisabled,
    disableActionButtons,
    withRecordType = true,
    isUsingOldLogic,
    handleExsistingSelect
  }) => {
    const {
      width,
      id,
      path,
      type,
      isModel,
      operation,
      progress: initial_progress,
      curation_id_passed,
      name_passed,
      ...rest
    } = row || {}
    const { author, name, curation_id } = rest
    const isSelectDisabled =
      checkSelectDisabled && checkSelectDisabled({ authorId: author?.id })

    const { inProgressOperation, inQueue, progress, status, status_int } =
      useProgressHandler({
        operation,
        id,
        initial_progress,
        ...rest
      })

    const renderProgressBar = useCallback(() => {
      return (
        <div>
          <ProgressBar progress={progress || 0} />
          {operation && (
            <Typography className={classes.progressContent}>
              {operation}
            </Typography>
          )}
        </div>
      )
    }, [classes.progressContent, operation, progress])

    const isFolder = type === RECORD_TYPE.FOLDER

    return (
      <TableRow
        onClick={() => handleRowClick(id, type)}
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected}
        className={classes.row}
        classes={{
          root: cn({ [classes.inProgress]: inProgressOperation }),
          selected: classes.activeRow,
          hover: classes.activeRow
        }}
      >
        {inProgressOperation ? (
          <TableCell />
        ) : (
          <TableCell
            className={cn({
              [classes.sticky]: isSticky
            })}
            style={{ left: isSticky ? 0 : 'auto' }}
          >
            <div>
              <IconButton
                className={cn({
                  [classes.iconButtonDisabled]: isSelectDisabled
                })}
                onClick={e => {
                  e.stopPropagation()
                  if (!isSelectDisabled) {
                    isUsingOldLogic ?
                      handleExsistingSelect({ id, type, name, curation_id, curation_id_passed, name_passed }, true) :
                      onSelect({ id, type, name, curation_id, curation_id_passed, name_passed }, true)
                  }
                }}
              >
                <CustomCheckBox
                  size={24}
                  disabled={isSelectDisabled}
                  onChange={() => {
                    isUsingOldLogic ?
                      handleExsistingSelect({ id, type, name, curation_id, curation_id_passed, name_passed }, true) :
                      onSelect({ id, type, name, curation_id, curation_id_passed, name_passed }, true)
                  }}
                  checked={isItemSelected}
                />
              </IconButton>
            </div>
          </TableCell>
        )}

        {withRecordType && <TableCell style={{ padding: 0 }}>
          <div className={classes.recordType}>
            {isModel ? (
              <CurationAIIcon height={24} width={24} />
            ) : isFolder ? (
              <Folder className={classes.folderIcon} />
            ) : (
              <InsertDriveFile className={classes.fileIcon} />
            )}
          </div>
        </TableCell>
        }
        <BodyColumn
          isSticky
          isCheckbox
          classes={classes}
          inProgressOperation={inProgressOperation && !inQueue}
          renderProgressBar={renderProgressBar}
          renderActionButton={() => renderActionButton(row)}
          disableActionButtons={disableActionButtons}
          id={id}
          {...rest}
          // status
          {...(status && { status: status })}
          status_int={status_int}
        />
      </TableRow>
    )
  }
)

/*,
  (...props) =>
    propsAreEqual(...props, [
      'row',
      'isSticky',
      'handleRowClick',
      'isItemSelected',
      'renderActionButton'
    ])
    */

export default memo(BodyTable)
