import React from 'react'

export const svg64_9 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="60"
        height="2000"
        viewBox="0 0 60 2000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1892.64V0H60V1249.15C57.5225 1421.41 50.2824 1600.16 42.1622 1851.64L24.3243 2000L0 1892.64Z"
          fill={color}
        />
        <path
          d="M2 527C2 525.343 3.34315 524 5 524H17C18.6569 524 20 525.343 20 527V538.077C20 539.734 18.6569 541.077 17 541.077H5C3.34315 541.077 2 539.734 2 538.077V527Z"
          fill="white"
        />
        <path
          d="M2 615.077C2 613.42 3.34315 612.077 5 612.077H17C18.6569 612.077 20 613.42 20 615.077V626.154C20 627.811 18.6569 629.154 17 629.154H5C3.34315 629.154 2 627.811 2 626.154V615.077Z"
          fill="white"
        />
        <path
          d="M2 703.154C2 701.497 3.34315 700.154 5 700.154H17C18.6569 700.154 20 701.497 20 703.154V714.231C20 715.888 18.6569 717.231 17 717.231H5C3.34315 717.231 2 715.888 2 714.231V703.154Z"
          fill="white"
        />
        <path
          d="M2 791.231C2 789.574 3.34315 788.231 5 788.231H17C18.6569 788.231 20 789.574 20 791.231V802.308C20 803.964 18.6569 805.308 17 805.308H5C3.34315 805.308 2 803.964 2 802.308V791.231Z"
          fill="white"
        />
        <path
          d="M2 879.308C2 877.651 3.34315 876.308 5 876.308H17C18.6569 876.308 20 877.651 20 879.308V890.385C20 892.041 18.6569 893.385 17 893.385H5C3.34315 893.385 2 892.041 2 890.385V879.308Z"
          fill="white"
        />
        <path
          d="M2 967.385C2 965.728 3.34315 964.385 5 964.385H17C18.6569 964.385 20 965.728 20 967.385V978.462C20 980.119 18.6569 981.462 17 981.462H5C3.34315 981.462 2 980.119 2 978.462V967.385Z"
          fill="white"
        />
        <path
          d="M2 1055.46C2 1053.8 3.34315 1052.46 5 1052.46H17C18.6569 1052.46 20 1053.8 20 1055.46V1066.54C20 1068.2 18.6569 1069.54 17 1069.54H5C3.34315 1069.54 2 1068.2 2 1066.54V1055.46Z"
          fill="white"
        />
        <path
          d="M2 1143.54C2 1141.88 3.34315 1140.54 5 1140.54H17C18.6569 1140.54 20 1141.88 20 1143.54V1154.62C20 1156.27 18.6569 1157.62 17 1157.62H5C3.34315 1157.62 2 1156.27 2 1154.62V1143.54Z"
          fill="white"
        />
        <path
          d="M2 1231.62C2 1229.96 3.34315 1228.62 5 1228.62H17C18.6569 1228.62 20 1229.96 20 1231.62V1242.69C20 1244.35 18.6569 1245.69 17 1245.69H5C3.34315 1245.69 2 1244.35 2 1242.69V1231.62Z"
          fill="white"
        />
        <path
          d="M2 1319.69C2 1318.04 3.34315 1316.69 5 1316.69H17C18.6569 1316.69 20 1318.04 20 1319.69V1330.77C20 1332.43 18.6569 1333.77 17 1333.77H5C3.34315 1333.77 2 1332.43 2 1330.77V1319.69Z"
          fill="white"
        />
        <path
          d="M2 1407.77C2 1406.11 3.34315 1404.77 5 1404.77H17C18.6569 1404.77 20 1406.11 20 1407.77V1418.85C20 1420.5 18.6569 1421.85 17 1421.85H5C3.34315 1421.85 2 1420.5 2 1418.85V1407.77Z"
          fill="white"
        />
        <path
          d="M2 1495.85C2 1494.19 3.34315 1492.85 5 1492.85H17C18.6569 1492.85 20 1494.19 20 1495.85V1506.92C20 1508.58 18.6569 1509.92 17 1509.92H5C3.34315 1509.92 2 1508.58 2 1506.92V1495.85Z"
          fill="white"
        />
        <path
          d="M2 1583.92C2 1582.27 3.34315 1580.92 5 1580.92H17C18.6569 1580.92 20 1582.27 20 1583.92V1595C20 1596.66 18.6569 1598 17 1598H5C3.34315 1598 2 1596.66 2 1595V1583.92Z"
          fill="white"
        />
        <path
          d="M2 1672C2 1670.34 3.34315 1669 5 1669H17C18.6569 1669 20 1670.34 20 1672V1683.08C20 1684.73 18.6569 1686.08 17 1686.08H5C3.34315 1686.08 2 1684.73 2 1683.08V1672Z"
          fill="white"
        />
        <path
          d="M2 1760.08C2 1758.42 3.34315 1757.08 5 1757.08H17C18.6569 1757.08 20 1758.42 20 1760.08V1771.15C20 1772.81 18.6569 1774.15 17 1774.15H5C3.34315 1774.15 2 1772.81 2 1771.15V1760.08Z"
          fill="white"
        />
        <path
          d="M2 1848.15C2 1846.5 3.34315 1845.15 5 1845.15H17C18.6569 1845.15 20 1846.5 20 1848.15V1859.23C20 1860.89 18.6569 1862.23 17 1862.23H5C3.34315 1862.23 2 1860.89 2 1859.23V1848.15Z"
          fill="white"
        />
        <path
          d="M24 477C24 475.343 25.3431 474 27 474H39C40.6569 474 42 475.343 42 477V488.077C42 489.734 40.6569 491.077 39 491.077H27C25.3431 491.077 24 489.734 24 488.077V477Z"
          fill="white"
        />
        <path
          d="M24 565.077C24 563.42 25.3431 562.077 27 562.077H39C40.6569 562.077 42 563.42 42 565.077V576.154C42 577.811 40.6569 579.154 39 579.154H27C25.3431 579.154 24 577.811 24 576.154V565.077Z"
          fill="white"
        />
        <path
          d="M24 653.154C24 651.497 25.3431 650.154 27 650.154H39C40.6569 650.154 42 651.497 42 653.154V664.231C42 665.888 40.6569 667.231 39 667.231H27C25.3431 667.231 24 665.888 24 664.231V653.154Z"
          fill="white"
        />
        <path
          d="M24 741.231C24 739.574 25.3431 738.231 27 738.231H39C40.6569 738.231 42 739.574 42 741.231V752.308C42 753.964 40.6569 755.308 39 755.308H27C25.3431 755.308 24 753.964 24 752.308V741.231Z"
          fill="white"
        />
        <path
          d="M24 829.308C24 827.651 25.3431 826.308 27 826.308H39C40.6569 826.308 42 827.651 42 829.308V840.385C42 842.041 40.6569 843.385 39 843.385H27C25.3431 843.385 24 842.041 24 840.385V829.308Z"
          fill="white"
        />
        <path
          d="M24 917.385C24 915.728 25.3431 914.385 27 914.385H39C40.6569 914.385 42 915.728 42 917.385V928.462C42 930.119 40.6569 931.462 39 931.462H27C25.3431 931.462 24 930.119 24 928.462V917.385Z"
          fill="white"
        />
        <path
          d="M24 1005.46C24 1003.8 25.3431 1002.46 27 1002.46H39C40.6569 1002.46 42 1003.8 42 1005.46V1016.54C42 1018.2 40.6569 1019.54 39 1019.54H27C25.3431 1019.54 24 1018.2 24 1016.54V1005.46Z"
          fill="white"
        />
        <path
          d="M24 1093.54C24 1091.88 25.3431 1090.54 27 1090.54H39C40.6569 1090.54 42 1091.88 42 1093.54V1104.62C42 1106.27 40.6569 1107.62 39 1107.62H27C25.3431 1107.62 24 1106.27 24 1104.62V1093.54Z"
          fill="white"
        />
        <path
          d="M24 1181.62C24 1179.96 25.3431 1178.62 27 1178.62H39C40.6569 1178.62 42 1179.96 42 1181.62V1192.69C42 1194.35 40.6569 1195.69 39 1195.69H27C25.3431 1195.69 24 1194.35 24 1192.69V1181.62Z"
          fill="white"
        />
        <path
          d="M24 1269.69C24 1268.04 25.3431 1266.69 27 1266.69H39C40.6569 1266.69 42 1268.04 42 1269.69V1280.77C42 1282.43 40.6569 1283.77 39 1283.77H27C25.3431 1283.77 24 1282.43 24 1280.77V1269.69Z"
          fill="white"
        />
        <path
          d="M24 1357.77C24 1356.11 25.3431 1354.77 27 1354.77H39C40.6569 1354.77 42 1356.11 42 1357.77V1368.85C42 1370.5 40.6569 1371.85 39 1371.85H27C25.3431 1371.85 24 1370.5 24 1368.85V1357.77Z"
          fill="white"
        />
        <path
          d="M24 1445.85C24 1444.19 25.3431 1442.85 27 1442.85H39C40.6569 1442.85 42 1444.19 42 1445.85V1456.92C42 1458.58 40.6569 1459.92 39 1459.92H27C25.3431 1459.92 24 1458.58 24 1456.92V1445.85Z"
          fill="white"
        />
        <path
          d="M24 1533.92C24 1532.27 25.3431 1530.92 27 1530.92H39C40.6569 1530.92 42 1532.27 42 1533.92V1545C42 1546.66 40.6569 1548 39 1548H27C25.3431 1548 24 1546.66 24 1545V1533.92Z"
          fill="white"
        />
        <path
          d="M24 1622C24 1620.34 25.3431 1619 27 1619H39C40.6569 1619 42 1620.34 42 1622V1633.08C42 1634.73 40.6569 1636.08 39 1636.08H27C25.3431 1636.08 24 1634.73 24 1633.08V1622Z"
          fill="white"
        />
        <path
          d="M24 1710.08C24 1708.42 25.3431 1707.08 27 1707.08H39C40.6569 1707.08 42 1708.42 42 1710.08V1721.15C42 1722.81 40.6569 1724.15 39 1724.15H27C25.3431 1724.15 24 1722.81 24 1721.15V1710.08Z"
          fill="white"
        />
        <path
          d="M24 1798.15C24 1796.5 25.3431 1795.15 27 1795.15H39C40.6569 1795.15 42 1796.5 42 1798.15V1809.23C42 1810.89 40.6569 1812.23 39 1812.23H27C25.3431 1812.23 24 1810.89 24 1809.23V1798.15Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="60"
      height="2000"
      viewBox="0 0 60 2000"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        d="M0 1892.64V0H60V1249.15C57.5225 1421.41 50.2824 1600.16 42.1622 1851.64L24.3243 2000L0 1892.64Z"
        fill={color}
      />
    </svg>
  )
}
