import { useCallback, useMemo } from 'react'
import {
  AccountCircle,
  ExitToApp,
  Folder,
  Settings,
  SettingsBackupRestoreRounded
} from '@material-ui/icons'
import { MODALS_ID, NOTIFICATION, ROUTES } from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { selectUserOrganisation } from '../../../endpoints/admin'
import useLogout from '../../../hooks/useLogout'
import useGetCurrentUser from '../../../hooks/useGetCurrentUser'
import useToggleModal from '../../../hooks/useToggleModal'
import { useDispatch } from 'react-redux'
import { storeCurrentUser } from '../../../actions/login'
import { getCurrentUser } from '../../../endpoints/auth'
import { useHistory } from 'react-router-dom'
import useGetAllRecords from '../../FilesTable/hooks/useGetAllRecords'
import useLimitedAccess from '../../../hooks/useLimitedAccess'
import ProbeInventoryIcon from '../../../imgs/ProbeInventoryIcon'

function useMenuDrawer({ restrictAccess = false }) {
  const handleLogout = useLogout()
  const history = useHistory()
  const dispatch = useDispatch()
  const modalID = MODALS_ID.MENU_DRAWER
  const { toggle: toggleDrawer, isOpened } = useToggleModal(modalID)
  const addNotification = useNotification()
  const { user } = useGetCurrentUser()
  const storeUserInfo = useCallback(
    user => dispatch(storeCurrentUser(user)),
    [dispatch]
  )
  const { fetchRecords } = useGetAllRecords(false)
  const { isLimited, isDefaultOrganization } = useLimitedAccess()

  const { admin, company } = user || {}
  const { manager } = company || {}

  const selectOrganization = useCallback(
    async id => {
      try {
        toggleDrawer()
        await selectUserOrganisation({ company_id: id })
        addNotification({
          type: NOTIFICATION.SUCCESS,
          title: 'Organisation switched successfully '
        })
        history.push('/')
        const userInfo = await getCurrentUser()
        storeUserInfo(userInfo)
      } catch (err) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: err
        })
      }
    },
    [
      addNotification,
      selectUserOrganisation,
      getCurrentUser,
      storeUserInfo,
      fetchRecords,
      toggleDrawer
    ]
  )

  const menuItem = useMemo(
    () =>
      [
        {
          to: ROUTES.FILES,
          label: 'Files',
          icon: Folder,
          disabled: restrictAccess
        },
        {
          to: ROUTES.ACCOUNT,
          label: 'Account',
          icon: AccountCircle,
          disabled: restrictAccess
        },
        (admin || manager) && {
          to: ROUTES.ORGANISATION,
          label: 'Organisation',
          icon: Settings,
          disabled: restrictAccess
        },
        !isLimited && {
          to: ROUTES.EXPERIMENTS_INPUT,
          label: 'Experiment Input',
          icon: SettingsBackupRestoreRounded,
          disabled: restrictAccess
        },
        !isDefaultOrganization && {
          to: ROUTES.PROBE_INVENTORY,
          label: 'Probe Inventory',
          icon: ProbeInventoryIcon
        },
        /*{
          to: '/support',
          label: 'Support',
          icon: HelpOutline
        },*/

        {
          onClick: handleLogout,
          label: 'Logout',
          icon: ExitToApp
        }
      ].filter(Boolean),
    [
      restrictAccess,
      admin,
      manager,
      handleLogout,
      isLimited,
      isDefaultOrganization
    ]
  )

  return { isOpened, toggleDrawer, menuItem, selectOrganization }
}

export default useMenuDrawer
