import React from 'react'

const MappingIcon = props => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.57626 2.86058C2.37859 3.00143 2.13673 3.08428 1.87551 3.08428C1.20786 3.08428 0.666626 2.54304 0.666626 1.87539C0.666626 1.20774 1.20786 0.666504 1.87551 0.666504C2.54317 0.666504 3.0844 1.20774 3.0844 1.87539C3.0844 2.14496 2.99617 2.39393 2.84701 2.59498L4.63948 4.38744C5.00678 4.07721 5.48153 3.89021 5.99996 3.89021C6.20568 3.89021 6.40453 3.91965 6.59251 3.97456L7.03259 2.9094C6.68352 2.69741 6.45033 2.31363 6.45033 1.87539C6.45033 1.20774 6.99157 0.666504 7.65922 0.666504C8.32687 0.666504 8.86811 1.20774 8.86811 1.87539C8.86811 2.54304 8.32687 3.08428 7.65922 3.08428C7.56444 3.08428 7.47221 3.07337 7.38372 3.05275L6.94548 4.11345C7.63583 4.46015 8.10959 5.17467 8.10959 5.99984C8.10959 6.07903 8.10522 6.15721 8.09672 6.23414L8.98964 6.38802C9.15969 5.92623 9.60359 5.59687 10.1244 5.59687C10.7921 5.59687 11.3333 6.13811 11.3333 6.80576C11.3333 7.47341 10.7921 8.01465 10.1244 8.01465C9.45675 8.01465 8.91551 7.47341 8.91551 6.80576C8.91551 6.79052 8.9158 6.77534 8.91636 6.76024L8.02121 6.60598C7.87493 7.09446 7.55642 7.50852 7.13617 7.77766L7.75018 8.96261C7.85677 8.93187 7.9694 8.91539 8.08588 8.91539C8.75354 8.91539 9.29477 9.45663 9.29477 10.1243C9.29477 10.7919 8.75354 11.3332 8.08588 11.3332C7.41823 11.3332 6.877 10.7919 6.877 10.1243C6.877 9.70842 7.08698 9.34161 7.4067 9.12408L6.79968 7.95261C6.55304 8.05372 6.28301 8.10947 5.99996 8.10947C5.40635 8.10947 4.87001 7.8643 4.48666 7.46969L3.02062 8.62455C3.12154 8.80112 3.17922 9.00559 3.17922 9.22354C3.17922 9.89119 2.63798 10.4324 1.97033 10.4324C1.30268 10.4324 0.761441 9.89119 0.761441 9.22354C0.761441 8.55589 1.30268 8.01465 1.97033 8.01465C2.28332 8.01465 2.56853 8.1336 2.78322 8.32876L4.24775 7.17509C4.02204 6.83923 3.89033 6.43492 3.89033 5.99984C3.89033 5.48971 4.07139 5.02187 4.37276 4.65708L2.57626 2.86058Z" />
    </svg>
  )
}

export default MappingIcon
