import { useEffect, useState } from 'react'
import { getCurrentUser } from '../endpoints/auth'
import useLogout from './useLogout'

const useGetCurrentUserWatcher = ({ currentUser, storeCurrentUser }) => {
  const logout = useLogout()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true)
        const res = await getCurrentUser()
        if (res?.user) storeCurrentUser(res)
        setLoading(false)
      } catch (err) {
        setLoading(false)
        logout()
      }
    }
    if (!currentUser) fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loading }
}

export default useGetCurrentUserWatcher
