import React from 'react'
import CustomInput from '../../../../../FormComponents/CustomInput/CustomInput'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import CustomDrawer from '../../../../../CustomDrawer'
import { connect } from 'react-redux'
import CommentsCardList from '../../../../../CommentsCardList'
import FilesLoading from '../../../../../BlurLoader'

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    overflow: 'hidden'
  },
  commentsRoot: {
    width: 'calc(100% + 40px)',
    overflow: 'auto',
    padding: '0 20px',
    position: 'relative',
    left: -20,
    height: 100,
    flex: 1,
    display: 'flex',
    marginBottom: 20
  },
  loading: {
    position: 'absolute',
    backdropFilter: 'blur(2px)',
    left: 0
  }
}))

const CommentsDrawer = props => {
  const {
    isOpened,
    toggleDrawer,
    handleChange,
    handleKeyPress,
    comments,
    newComment,
    loading,
    onDelete,
    onEdit,
    isLimited
  } = props
  const classes = useStyles()

  return (
    <CustomDrawer
      open={isOpened}
      title="Comments"
      onClose={toggleDrawer}
      maxWidth={350}
    >
      <Grid
        container
        wrap="nowrap"
        justify="space-between"
        direction="column"
        className={classes.root}
      >
        <div className={classes.commentsRoot}>
          {loading && <FilesLoading />}
          <CommentsCardList
            comments={comments}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        </div>
        <CustomInput
          fullWidth
          multiline
          disabled={isLimited}
          value={newComment}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          label="Add a comment"
        />
      </Grid>
    </CustomDrawer>
  )
}

const mapStateToProps = state => ({
  comments: state.recordingStore.comments
})

export default connect(mapStateToProps)(CommentsDrawer)
