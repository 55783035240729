import useGetCurrentUser from '../../hooks/useGetCurrentUser'
import { useSelector } from 'react-redux'

function useIsAuthor(authorId) {
  const { user } = useGetCurrentUser()
  const { author_id: curationAuthorId } =
    useSelector(state => state.recordingStore.selectedCuration) || {}

  const currentAuthor = authorId || curationAuthorId

  const isAuthor = currentAuthor === user?.id
  const isAuthorOrAdmin = isAuthor || user?.admin

  return { isAuthor, isAuthorOrAdmin }
}

export default useIsAuthor
