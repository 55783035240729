import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Edit } from '@material-ui/icons'
import { CircularProgress, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 13,
    marginBottom: 8,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  },
  imageRoot: {
    display: 'block',
    position: 'relative',
    width: 60,
    height: 60,
    borderRadius: 60,
    border: `1px solid ${theme.palette.colors.neutral['9']}`
  },
  image: {
    position: 'relative',
    objectFit: 'cover',
    width: 60,
    height: 60,
    borderRadius: 60
  },
  input: {
    cursor: 'pointer',
    position: 'absolute',
    zIndex: 2,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    margin: 0,
    opacity: 0
  },
  edit: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 24,
    height: 24,
    borderRadius: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.colors.backgrounds.main,
    backgroundColor: theme.palette.primary.contrast,
    '& svg': {
      fontSize: 18
    }
  },
  loading: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }
}))

const UploadImage = ({ value, name, onUpload, loading = false }) => {
  const classes = useStyles()
  const src = value || require('../../../imgs/nopreview.png')
  return (
    <div className={classes.root}>
      <Typography className={classes.label}>Avatar</Typography>
      <label htmlFor={name} className={classes.imageRoot}>
        {loading ? (
          <CircularProgress
            size={60}
            color="primary"
            className={classes.loading}
          />
        ) : (
          <img className={classes.image} src={src} alt="Upload" />
        )}
        <input
          id={name}
          type="file"
          name={name}
          required
          className={classes.input}
          onChange={event => onUpload(event.target.files)}
        />
        <div className={classes.edit}>
          <Edit />
        </div>
      </label>
    </div>
  )
}

export default memo(UploadImage)
