import React from 'react'
import SpikesortingsDrawer from '../SpikesortingsDrawer'
import LogsModal from '../../../NewRecordingView/components/LogsView/LogsModal'
import FilesModal from '../../../NewRecordingView/components/RecordingBody/components/FilesModal'
import useFilesModal from '../../../NewRecordingView/components/RecordingBody/hooks/useFilesModal'
import CommentsDrawer from '../../../NewRecordingView/components/RecordingHeader/components/CommentsDrawer/CommentsDrawer'
import useComments from '../../../NewRecordingView/components/RecordingHeader/hooks/useComments'
import NewSpikesortingModal from '../../../NewRecordingView/components/RecordingHeader/components/NewSpikesortingModal'
import ConfirmActionsModal from '../../../NewRecordingView/components/SortingsView/components/ConfirmActionsModal'
import EditTagsPublicationsModal from '../../../NewRecordingView/components/RecordingInfoPanel/components/EditTagsPublicationsModal'
import useEditTagsAndPublics from '../../../NewRecordingView/components/RecordingInfoPanel/hooks/useEditTagsAndPublics'
import CurationsDrawer from '../CurationsDrawer'
import useSpikesortingController from '../../../NewRecordingView/hooks/useSpikesortingController'
import DownloadFilesModal from '../WidgetsLayout/components/DownloadFilesModal'
import EditDataModal from '../../../NewRecordingView/components/RecordingInfoPanel/components/EditDataModal/EditDataModal'
import useLimitedAccess from '../../../../hooks/useLimitedAccess'

// here would be all drawers, modals of recording view
const SideComponents = () => {
  const filesModal = useFilesModal()
  const commentsDrawer = useComments()
  const editTags = useEditTagsAndPublics()
  const { runSpikesorting, loading } = useSpikesortingController()
  const { isLimited } = useLimitedAccess()

  return (
    <>
      <SpikesortingsDrawer isLimited={isLimited} />
      <CurationsDrawer isLimited={isLimited} />
      <LogsModal />
      <ConfirmActionsModal />
      <FilesModal {...filesModal} isLimited={isLimited} />
      <CommentsDrawer {...commentsDrawer} isLimited={isLimited} />
      <NewSpikesortingModal onSubmit={runSpikesorting} loading={loading} />
      <EditTagsPublicationsModal {...editTags} isLimited={isLimited} />
      <DownloadFilesModal isLimited={isLimited} />

      <EditDataModal isLimited={isLimited} />
    </>
  )
}

export default SideComponents
