import { useCallback, useMemo, useState } from 'react'
import useToggleModal from '../../../../../hooks/useToggleModal'

import useRecordingInfo from '../../../../NewRecordingView/hooks/useRecordingInfo'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteEvent,
  downloadCuration,
  downloadEvent,
  downloadProbeMap,
  downloadRecord,
  downloadSpikeSorting
} from '../../../../../endpoints/admin'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import { MODALS_ID, NOTIFICATION } from '../../../../../utils/consts'
import { deleteEventFile } from '../../../../../actions/recording'
import { findProbeDrawing } from '../../../../TimeseriesView/NewLeftPanel/components/DrawProbe/DrawProbe'
import { createFileDownloadStream } from '../../../../../utils/utils'

export const useDownloadFilesModal = () => {
  const dispatch = useDispatch()
  const { isOpened, onClose, onOpen, modalState } = useToggleModal(
    MODALS_ID.DOWNLOAD_FILES_MODAL
  )

  const [loading, setLoading] = useState({ index: null })

  const addNotification = useNotification()
  const {
    id: recordId,
    name: fileName,
    probe_id,
    prb_file
  } = useRecordingInfo().recording || {}
  const events = useRecordingInfo().eventFiles || new Map()
  const {
    name: sortingName,
    id: sortingId,
    zip_link
  } = useSelector(state => state.recordingStore.selectedSorting) || {}
  const { id: curationId } =
    useSelector(state => state.recordingStore.selectedCuration) || {}

  const handleDownload = useCallback(
    async ({ id, class_type, index = 0 }) => {
      setLoading({ index: index })
      let downloadFunction, key, downloadParams
      switch (class_type) {
        case 'event':
          downloadFunction = downloadEvent
          downloadParams = { id }
          key = 'url'
          break
        case 'run':
          downloadFunction = downloadSpikeSorting
          key = 'run_url'
          downloadParams = { class_type, id }
          break
        case 'record':
          downloadFunction = downloadRecord
          key = 'record_url'
          downloadParams = { class_type: 'record', id }
          break
        default:
          downloadFunction = () => {}
          break
      }
      try {
        let file = await downloadFunction(downloadParams)
        if (file[key]) window.open(file[key])
        setLoading({ index: null })
      } catch (e) {
        setLoading({ index: null })
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification]
  )

  const handleMapDownload = useCallback(
    async ({ index = 0 }) => {
      setLoading({ index: index })
      try {
        const downloadParams = { probe_id, record_id: recordId }
        let file = await downloadProbeMap(downloadParams)
        if (file?.url) window.open(file?.url)
        setLoading({ index: null })
      } catch (e) {
        setLoading({ index: null })
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, probe_id, recordId]
  )

  const handleStreamDownload = useCallback(
    async ({ class_type, id, index }) => {
      setLoading({ index: index })
      try {
        await downloadCuration({ class_type, id })
          .then(res => {
            if (res?.status === 200) {
              const name = `record-${recordId}-${class_type}-${id}`

              createFileDownloadStream(res, name, () =>
                addNotification({
                  type: NOTIFICATION.ERROR,
                  title:
                    "Please don't refresh the page or close the app while download is in progress!"
                })
              )
            } else {
              throw Error(`Request rejected with status ${res?.status}`)
            }
          })
          .catch(error => console.error(error))
        setLoading({ index: null })
      } catch (e) {
        setLoading({ index: null })
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [recordId]
  )

  const handleDelete = useCallback(async (id, key) => {
    try {
      const result = await deleteEvent({ id })
      if (result) {
        dispatch(deleteEventFile(key))
        addNotification({
          type: NOTIFICATION.SUCCESS,
          title: 'File Deleted Successfully'
        })
      }
    } catch (e) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [])

  const modalContent = useMemo(() => {
    let nndFileCounter = 1
    let eventFileCounter = 1
    const NUM_CONST_FILES = 4
    const eventsInfo = Array.from(events.entries()).map(
      ([eventKey, eventValue], index) => {
        let title = `Event ${eventFileCounter}`
        if (eventValue.isNND) {
          title = `NND ${nndFileCounter}`
          nndFileCounter++
        } else eventFileCounter++
        return {
          title,
          subtitle: '',
          content: eventKey,
          canDelete: true,
          downloadFunction: () =>
            handleDownload({
              id: eventValue.id,
              index: index + NUM_CONST_FILES,
              class_type: 'event'
            }),
          deleteFunction: () => {
            handleDelete(eventValue.id, eventKey)
          }
        }
      }
    )

    const probeMap = [
      {
        content: 'Mapping file (.mat)',
        downloadFunction: () => handleMapDownload({ index: 3 })
      }
    ]

    return [
      {
        title: 'Raw data file .(dat)',
        subtitle: '',
        content: fileName,
        downloadFunction: () =>
          handleDownload({ id: recordId, class_type: 'record', index: 0 })
      },
      {
        title: 'Spikesorting output',
        subtitle: '(selected spikesorting  session without curation)',
        content: sortingName,
        disabled: !zip_link,
        downloadFunction: () =>
          handleDownload({ class_type: 'run', id: sortingId, index: 1 })
      },
      {
        title: 'Curated results',
        subtitle: '(selected spikesorting and curation session)',
        content: curationId,
        disabled: !curationId,
        downloadFunction: () =>
          handleStreamDownload({
            class_type: 'curation',
            id: curationId,
            index: 2
          })
      },
      ...probeMap,
      ...eventsInfo
    ]
  }, [
    events,
    fileName,
    sortingName,
    curationId,
    handleDownload,
    recordId,
    handleStreamDownload,
    sortingId,
    handleDelete,
    probe_id,
    prb_file,
    zip_link,
    handleMapDownload
  ])

  return {
    isOpened,
    onClose,
    onOpen,
    modalState,
    modalContent,
    loading,
    handleStreamDownload,
    handleDownloadRecord: handleDownload
  }
}
