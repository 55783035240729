import { useEffect, useState, useCallback } from 'react'

import useToggleModal from '../../../../../../../hooks/useToggleModal'
import { useDispatch } from 'react-redux'
import useRecordingInfo from '../../../../../../NewRecordingView/hooks/useRecordingInfo'
import { addProbeFileDataActionCreator } from '../../../../../../../containers/NewTimeSeries/redux/actions'
import { getProbeFileData } from '../../../../../../../containers/NewTimeSeries/utils'
import useGetBiochipsData from '../../../../../../TimeseriesView/NewLeftPanel/components/MappingTab/hooks/useGetBiochipsData'
import { MODALS_ID, NOTIFICATION } from '../../../../../../../utils/consts'
import { findProbeDrawing } from '../../../../../../TimeseriesView/NewLeftPanel/components/DrawProbe/DrawProbe'
import { downloadProbeMap } from '../../../../../../../endpoints/admin'
import useNotification from '../../../../../../SnackbarManager/hooks/useNotification'

export const useProbeDesignModal = (probeDesign = null) => {
  const { isOpened, onClose, onOpen, modalState } = useToggleModal(
    MODALS_ID.PROBE_DESIGN_MODAL
  )
  const addNotification = useNotification()
  const [loading, setLoading] = useState(true)
  const [isDownloading, setDownloading] = useState(false)

  const { recording } = useRecordingInfo()
  const dispatch = useDispatch()
  const [probeFileName, setProbeFileName] = useState('')

  const addProbeFileData = useCallback(
    fileData => dispatch(addProbeFileDataActionCreator(fileData)),
    [dispatch]
  )

  const probeName = probeDesign?.name ?? recording?.prb_file
  const showMaping = findProbeDrawing(probeName)

  const bData = useGetBiochipsData()
  const { disabled_channels, probe_id, id, number_channels, probe_file_type } =
    recording || {}

  const canDownloadMatFile = probeDesign?.id ?? probe_id

  useEffect(() => {
    async function getProbe() {
      !loading && setLoading(true)
      const channels_num = probeDesign ? null : number_channels
      const probeFileData = await getProbeFileData(probeName, channels_num)
      if (probeFileData) {
        addProbeFileData({
          ...probeFileData,
          draw: probeName,
          type: probe_file_type,
          diabled_channels: disabled_channels?.map(Number) || []
        })
      }
      setLoading(false)
      setProbeFileName(probeFileData.probeName)
    }

    if (showMaping) getProbe()
  }, [
    addProbeFileData,
    recording,
    probeName,
    showMaping,
    isOpened,
    probeDesign
  ])

  const handleDownloadMapFile = useCallback(async () => {
    try {
      setDownloading(true)
      const prbId = probeDesign?.id ?? probe_id
      const params = !probeDesign
        ? { record_id: id, probe_id: prbId }
        : { probe_id: prbId }

      const response = await downloadProbeMap(params)
      if (response?.url) window.open(response?.url)
      setDownloading(false)
    } catch (e) {
      setDownloading(false)
      addNotification({
        type: NOTIFICATION.ERROR,
        title: `Download Failed: ${e}`
      })
    }
  }, [id, addNotification, probe_id, probeDesign])

  return {
    isOpened,
    onClose,
    onOpen,
    modalState,
    probeFileName,
    bData,
    loading,
    showMaping,
    handleDownloadMapFile,
    isDownloading,
    canDownloadMatFile
  }
}
