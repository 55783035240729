import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import { PROBE_STATUS } from '../../../../utils/consts'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  statusContainer: ({ isUnused }) => ({
    backgroundColor: isUnused
      ? theme.probes.status.unused
      : theme.probes.status.consumed,
    borderRadius: 4,
    padding: '2px 8px',
    width: 'fit-content'
  }),
  statusText: { fontSize: 12, fontWeight: 600, color: 'white' }
}))

const ProbeStatus = ({ status = '' }) => {
  const isUnused = [PROBE_STATUS.UNCONSUMED, PROBE_STATUS.UNUSED].includes(
    status
  )

  const classes = useStyles({ isUnused })
  return (
    <div className={classes.root}>
      <div className={classes.statusContainer}>
        <Typography className={classes.statusText}>
          {isUnused ? PROBE_STATUS.UNUSED : status}
        </Typography>
      </div>
    </div>
  )
}

export default ProbeStatus
