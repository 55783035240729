import { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import useClusterSimilarityStore from './useClusterSimilarityStore'

import { StandardKeyEvents } from '../../../../../../TimeseriesView/CaptureKeyEvent/helpers'
import { usePrevious } from '../../../../../../../common'

export default function useKeyboardShortcuts({
  saveClusterUnits,
  saveSimilarityUnit,
  loading
}) {
  const {
    clustersList: clusters,
    similarityList: similarityClusters,
    clusterUnits,
    similarityUnits
  } = useClusterSimilarityStore()

  const modals = useSelector(state => state.modals)
  const isModals = modals?.size > 0

  const widgets = useMemo(
    () => JSON.parse(localStorage.getItem('widgets-layout-DS-2')) ?? [],
    []
  )

  const isClusterView = useMemo(
    () => widgets.some(widget => widget[1].widgetId === 'cluster_view'),
    [widgets]
  )

  const isSimilarityView = useMemo(
    () => widgets.some(widget => widget[1].widgetId === 'similarity_view'),
    [widgets]
  )

  const nextOrPrevRow = ({ clusters, selectedRows, cb, isPrev = false }) => {
    if (!clusters) return

    const lastSelectedUnit = selectedRows[selectedRows?.length - 1]

    const lastSelectedUnitIndex = clusters.findIndex(
      ({ id }) => id == lastSelectedUnit
    )

    const idx = isPrev
      ? lastSelectedUnitIndex > 0
        ? lastSelectedUnitIndex - 1
        : clusters.length - 1
      : lastSelectedUnitIndex === clusters.length - 1
      ? 0
      : lastSelectedUnitIndex + 1

    cb({
      unit_id: Number(clusters[idx].id),
      multiple: false
    })
  }

  const prevC = usePrevious(clusters)
  const prevS = usePrevious(clusterUnits)

  const prevSimilarityClusters = usePrevious(clusterUnits)
  const prevSimilarityUnits = usePrevious(clusterUnits)

  useEffect(() => {
    if (isClusterView) {
      StandardKeyEvents.registerWithPreventDefault(
        [40],
        e => {
          nextOrPrevRow({
            selectedRows: clusterUnits,
            clusters,
            cb: saveClusterUnits,
            isPrev: false
          })
        },
        { overrideEvent: clusters !== prevC || clusterUnits !== prevS }
      )

      StandardKeyEvents.registerWithPreventDefault(
        [38],
        e => {
          nextOrPrevRow({
            selectedRows: clusterUnits,
            clusters,
            cb: saveClusterUnits,
            isPrev: true
          })
        },
        { overrideEvent: clusters !== prevC || clusterUnits !== prevS }
      )
    }
  }, [clusters, clusterUnits, isClusterView])

  useEffect(() => {
    isSimilarityView &&
      StandardKeyEvents.registerWithPreventDefault(
        [32, 40],
        e => {
          nextOrPrevRow({
            clusters: similarityClusters,
            selectedRows: similarityUnits,
            cb: saveSimilarityUnit,
            isPrev: false
          })
        },
        {
          overrideEvent:
            similarityClusters !== prevSimilarityClusters ||
            similarityUnits !== prevSimilarityUnits
        }
      )

    isSimilarityView &&
      StandardKeyEvents.registerWithPreventDefault(
        [32, 38],
        e => {
          nextOrPrevRow({
            clusters: similarityClusters,
            selectedRows: similarityUnits,
            cb: saveSimilarityUnit,
            isPrev: true
          })
        },
        {
          overrideEvent:
            similarityClusters !== prevSimilarityClusters ||
            similarityUnits !== prevSimilarityUnits
        }
      )
  }, [similarityClusters, similarityUnits, isSimilarityView])
}
