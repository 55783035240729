import React from 'react'
import { makeStyles, ClickAwayListener } from '@material-ui/core'
import FilterIcon from '../../../../imgs/Filter'
import useFilterButton from './hooks/useFilterButton'
import PopperMenu from '../../../SelectMenu/PopperMenu'

const useStyles = makeStyles(theme => ({
  filterIconContainer: {
    position: 'absolute',
    zIndex: 1000,
    right: 0,
    cursor: 'pointer',
    marginRight: 24
  },
  listRoot: {
    padding: 0
  },
  listItem: {
    padding: '4px 12px'
  },
  paperRoot: {
    padding: 0,
    borderRadius: 8,
    gap: 8
  },
  itemLabel: {
    fontSize: '10px !important',
    lineHeight: '16px !important'
  },
  first_item_color: {
    color: theme.palette.colors.lighterGreen
  },
  second_item_color: {
    color: theme.palette.colors.accent[8]
  },
  n_item_color: { color: theme.palette.primary.disabled2 },
  checkboxRoot: {
    borderColor: theme.palette.action.disabled2
  }
}))

const FilterButton = ({ onFilterItemClick, filters }) => {
  const classes = useStyles()
  const { iconStyle, onIconClick, handleClickAway, anchorEl, items } =
    useFilterButton(classes, onFilterItemClick, filters)

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.filterIconContainer}>
        <FilterIcon onClick={onIconClick} style={iconStyle} />
        <PopperMenu
          handleClose={onIconClick}
          selectValue={onFilterItemClick}
          anchorEl={anchorEl}
          items={items}
          customClasses={{
            paper: classes.paperRoot,
            listRoot: classes.listRoot,
            listItem: classes.listItem
          }}
          customWidth={101}
          placement="bottom-end"
        />
      </div>
    </ClickAwayListener>
  )
}

export default FilterButton
