import { from, of, merge } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { loadWaveform, startLoadWaveform } from '../../actions'
import { PlotWaveforms } from '../../../widgets/WaveFormView'
import { getAsObservable } from '../../../../../../../utils/rxjs.utils'
import {
  getWaveforms,
  getMeanWaveforms
} from '../../../../../../../endpoints/admin'
import { NOTIFICATION } from '../../../../../../../utils/consts'

export function fetchWaveformsEpic(action$, state) {
  return merge(
    of(startLoadWaveform(true)),
    fetchWaveforms(getWaveforms, action$, state)
  )
}

export function fetchMeanWaveformsEpic(action$, state) {
  return merge(
    of(startLoadWaveform(true)),
    fetchWaveforms(getMeanWaveforms, action$, state)
  )
}

function fetchWaveforms(request, action$, state) {
  const waveformsView = state.widgetsCache.waveforms.view
  const unit = action$.payload.unit_id
  const sortingId = state.recordingStore.selectedSorting?.id
  const record_id = state.recordingStore.recording.id
  const curationId = state.recordingStore.selectedCuration?.id
  const units = state.widgetsCache.units.cluster
  const similarityUnits = state.widgetsCache.units.similarity
  const waveformGeometry = state.widgetsCache.waveforms.geometry
  const waveformOverlapping = state.widgetsCache.waveforms.overlap

  const unitIdx = units.concat(similarityUnits).indexOf(unit)
  const persist = units.concat(similarityUnits).includes(unit)

  const waves =
    state.widgetsCache.waveforms.data?.[waveformsView]?.[record_id]?.[
      sortingId
    ]?.[unit]

  if (waves) {
    return checkWaveformsCacheEpic({
      waves,
      unit,
      unitIdx,
      waveformGeometry,
      waveformOverlapping
    })
  }

  return from(
    getAsObservable(request, {
      run_id: sortingId,
      curation_id: curationId,
      unitId: unit
    }).pipe(
      map(
        action =>
          persist &&
          loadWaveform({
            data: { [unit]: action.data },
            record_id,
            sortingId,
            unit,
            loading: false
          })
      ),
      tap(action => {
        if (!action.payload.data[unit]?.nsw)
          throw new TypeError(
            'Cannot read waveform, please run new spikesorting to view waveform data'
          )
        if (!persist) throw new TypeError(`Cannot read ${unit}`)

        if (PlotWaveforms.renderPlot !== null)
          PlotWaveforms.updatePlotData(
            unit,
            action.payload.data[unit],
            unitIdx,
            waveformGeometry,
            waveformOverlapping
          )

        return action
      }),
      catchError(error =>
        merge(
          of(startLoadWaveform(false)),
          of({
            type: 'ADD_NOTIFICATION',
            payload: {
              title: `Waveform view: ${error.message ?? error}`,
              type: NOTIFICATION.ERROR
            }
          })
        )
      )
    )
  )
}

function checkWaveformsCacheEpic({
  waves,
  unit,
  unitIdx,
  waveformGeometry,
  waveformOverlapping
}) {
  return of(waves).pipe(
    tap(res => {
      PlotWaveforms.updatePlotData(
        unit,
        res,
        unitIdx,
        waveformGeometry,
        waveformOverlapping
      )

      return res
    }),
    map(_action => startLoadWaveform(false))
  )
}
