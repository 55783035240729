import React, { Component } from 'react'
import { Toolbar } from '@material-ui/core'
import TimeRangeControl from './components/TimeRangeControl'
import CurrentTimeControl from './components/CurrentTimeControl'

export class BottomToolbar extends Component {
  render() {
    const { info, onTimeRangeChanged, onCurrentTimeChanged } = this.props

    return (
      <Toolbar style={{ minHeight: this.props.height, paddingLeft: 60 }}>
        <CurrentTimeControl
          width="auto"
          currentTime={info.currentTime}
          samplerate={info.samplerate}
          onChange={onCurrentTimeChanged}
        />

        <TimeRangeControl
          width="auto"
          timeRange={info.timeRange}
          samplerate={info.samplerate}
          onChange={onTimeRangeChanged}
        />
        <span>{info.statusText}</span>
      </Toolbar>
    )
  }
}
