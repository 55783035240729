import React, { memo, useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { Table, Typography } from '@material-ui/core'
import HeadTable from '../CustomTable/HeadTable'
import EmptyFilesTable from './components/EmptyFilesTable'
import FilesSnackbar from './components/FilesSnackbar'
import useFilesController from './hooks/useFilesController'
import FilesPagination from './components/FilesPagination'
import DeleteConfirmationModal from './components/DeleteConfirmationModal'
import BodyTable from './components/BodyTable'
import { getUploadDate, mapSortByKey, parseFileSize } from '../../utils/utils'
import useStyles from '../CustomTable/style'
import TableShadows from './components/TableShadows'
import useTableShadowsHandler from './components/TableShadows/hooks/useTableShadowsHandler'
import { MODALS_ID, RECORD_TYPE } from '../../utils/consts'
import FilesLoading from '../BlurLoader/BlurLoader'
import ActionsMenu from './components/ActionsMenu'
import ConcatFilesModal from './components/ConcatFilesModal'
import SuccessModal from './components/SuccessModal'
import WeaveFilesModal from './components/WeaveFilesModal'
import useToggleModal from '../../hooks/useToggleModal'

const FilesTable = ({ records, pages, columns, loading }) => {
  const classes = useStyles({ withPagination: true })

  const {
    selected,
    isSelected,
    setSelected,
    isBulkSelect,
    deselectAll,
    handleSelect,
    handleDeleteRecord,
    isExistingSelected,
    handleExsistingSelect
  } = useFilesController()

  const {
    DELETE_FILES_CONFIRMATION_MODAL,
    CONCAT_FILES_MODAL,
    WAVE_FILES_MODAL
  } = MODALS_ID
  const { toggle: toggleDeleteModal } = useToggleModal(
    DELETE_FILES_CONFIRMATION_MODAL
  )
  const { toggle: toggleConcatFilesModal } = useToggleModal(CONCAT_FILES_MODAL)
  const { toggle: toggleWeaveFilesModal } = useToggleModal(WAVE_FILES_MODAL)

  const onSingleDelete = useCallback(
    (id, type) => {
      setSelected({ id, type })
      toggleDeleteModal()
    },
    [setSelected, toggleDeleteModal]
  )

  const onDelete = useCallback(() => {
    handleDeleteRecord(selected)
  }, [selected])

  const rows = useMemo(
    () =>
      records?.length > 0 &&
      records?.map(item => {
        const {
          id,
          path,
          status_int,
          created_at,
          updated_at,
          upload_date,
          file_size,
          content_type = RECORD_TYPE.FILE,
          isolated_units,
          good_units = null,
          author,
          progress,
          operation,
          ...rest
        } = item || {}
        const fileFields = {
          file_size: file_size && parseFileSize(file_size),
          upload_date: getUploadDate(upload_date || created_at),
          isolated_units:
            item.default_curation?.good_units || good_units,
          author: { name: author?.username },
          ...rest
        }
        const folderFields = {
          ...Object.assign(...columns.map(item => ({ [item.id]: null }))),
          ...rest,
          author: { name: rest?.user_name },
          upload_date: getUploadDate(updated_at || created_at)
        }

        const fields =
          content_type === RECORD_TYPE.FOLDER ? folderFields : fileFields
        const activeColumns = Object.entries(fields).filter(([k, _]) =>
          columns.some(a => a.active && a.id === k)
        )

        let sortedByKey = mapSortByKey(activeColumns, columns, 'id')

        return {
          ...Object.assign(
            {},
            ...Array.from(sortedByKey || [], ([k, v]) => ({ [k]: v }))
          ),
          id,
          path,
          progress,
          operation,
          type: content_type,
          status_int
        }
      }),
    [columns, records]
  )

  return (
    <div className={classes.root}>
      <TableModals
        onDelete={onDelete}
        selected={selected}
        deselectAll={deselectAll}
      />
      <TableRoot
        rows={rows}
        columns={columns}
        loading={loading}
        onDelete={onSingleDelete}
        isSelected={isSelected}
        handleSelect={handleSelect}
        deselectAll={deselectAll}
        isUsingOldLogic={true}
        isExistingSelected={isExistingSelected}
        handleExsistingSelect={handleExsistingSelect}
      />
      <FilesPagination pages={pages} withPerPageSelector savePage />
      {isBulkSelect && (
        <FilesSnackbar
          length={selected.length}
          deselectAll={deselectAll}
          onDelete={toggleDeleteModal}
        >
          <ActionsMenu
            selected={selected}
            onConcat={toggleConcatFilesModal}
            onWeave={toggleWeaveFilesModal}
            onDelete={toggleDeleteModal}
          />
        </FilesSnackbar>
      )}
    </div>
  )
}

const TableModals = ({ onDelete, selected, deselectAll }) => {
  const { toggle: toggleDeleteModal, isOpened } = useToggleModal(
    MODALS_ID.DELETE_FILES_CONFIRMATION_MODAL
  )

  return (
    <>
      <DeleteConfirmationModal
        isOpened={isOpened}
        toggleDeleteModal={toggleDeleteModal}
        onDelete={onDelete}
        selected={selected}
      />

      <ConcatFilesModal selected={selected} />

      <WeaveFilesModal selected={selected} />

      <SuccessModal
        title="Operation in progress"
        message="The operation will be executed in background, you can see the progress in the status column."
        deselectAll={deselectAll}
      />
    </>
  )
}

export const TableRoot = ({
  rows,
  columns,
  loading,
  onRowClick,
  deselectAll,
  onDelete,
  isSelected,
  handleSelect,
  checkSelectDisabled,
  disableActionButtons,
  renderActionButton,
  withRecordType = true,
  isUsingOldLogic,
  isExistingSelected,
  handleExsistingSelect
}) => {
  const { onScroll, isRightShadow, isLeftShadow } = useTableShadowsHandler()
  const classes = useStyles({ withPagination: true })
  const isEmpty = !loading && (!rows || rows.length === 0)
  return (
    <>
      {!isEmpty && (
        <TableShadows
          classes={classes}
          isLeftShadow={isLeftShadow}
          isRightShadow={isRightShadow}
        />
      )}
      {isEmpty && <EmptyFilesTable />}
      {loading && <FilesLoading />}
      <div className={classes.tableRoot} onScroll={onScroll}>
        <Table className={classes.table} stickyHeader aria-label="sticky table">
          <HeadTable
            classes={classes}
            columns={columns}
            isSticky
            withCheckbox={true}
            withRecordType={withRecordType}
          />
          <BodyTable
            isSticky
            loading={loading}
            rows={rows}
            classes={classes}
            onRowClick={onRowClick}
            deselectAll={deselectAll}
            isSelected={isSelected}
            onSelect={handleSelect}
            onDelete={onDelete}
            checkSelectDisabled={checkSelectDisabled}
            disableActionButtons={disableActionButtons}
            customRenderActionButton={renderActionButton}
            withRecordType={withRecordType}
            isUsingOldLogic={isUsingOldLogic}
            isExistingSelected={isExistingSelected}
            handleExsistingSelect={handleExsistingSelect}
          />
        </Table>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  records: state.filesStore.files,
  pages: state.filesStore.pages
})

export default memo(connect(mapStateToProps)(FilesTable))
