import useUploadPicture from '../../../../../../hooks/useUploadPicture'
import React, { useCallback } from 'react'
import cn from 'classnames'
import { Backup, CloudDone } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  uploadRoot: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  uploadLabel: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.colors.accent[5],
    textDecoration: 'underline'
  },
  uploaded: {
    color: theme.palette.secondary[100],
    textDecoration: 'none'
  },
  uploadText: {
    fontSize: 13,
    lineHeight: '15px',
    fontWeight: 600,
    marginLeft: 8
  },
  uploadInput: {
    display: 'none'
  },
  btnRemove: {
    marginLeft: 40,
    padding: '3px 8px',
    textTransform: 'initial',
    textDecoration: 'underline',
    color: theme.palette.colors.status.error,
    '&:hover': {
      color: theme.palette.colors.status.error
    }
  }
}))

const UploadFile = ({
  onUpload = () => null,
  name,
  preview = false,
  file,
  accept = ['.json']
}) => {
  const classes = useStyles()

  const { onUpload: UPLOAD } = useUploadPicture()
  const handleUpload = useCallback(
    file =>
      UPLOAD({
        files: [file],
        loadingLabel: false,
        acceptedFormat: accept,
        onSuccess: async result => {
          const { file } = result
          onUpload(file)
        }
      }),
    [UPLOAD, onUpload, accept]
  )

  return (
    <div className={classes.uploadRoot}>
      <label
        htmlFor={name}
        className={cn(classes.uploadLabel, {
          [classes.uploaded]: file
        })}
      >
        {file ? <CloudDone /> : <Backup />}{' '}
        <Typography className={classes.uploadText}>
          {file?.name || file || 'Upload File'}
        </Typography>
      </label>
      {file && (
        <Button
          onClick={e => {
            e.stopPropagation()
            onUpload(null)
          }}
          className={classes.btnRemove}
        >
          Remove
        </Button>
      )}
      {!preview && (
        <input
          id={name}
          type="file"
          required
          accept={accept}
          onChange={event => handleUpload(event.currentTarget.files[0])}
          className={classes.uploadInput}
        />
      )}
    </div>
  )
}

export default UploadFile
