import React from 'react'
import cn from 'classnames'
import { Grid, makeStyles, Typography, IconButton } from '@material-ui/core'
import { Close, Delete } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  snackbar: {
    position: 'fixed',
    left: 0,
    right: 0,
    zIndex: 3,
    opacity: 0,
    bottom: 24,
    padding: 16,
    maxWidth: 360,
    borderRadius: 8,
    margin: '0 auto',
    transform: 'translateY(10px)',
    color: theme.palette.primary.contrast,
    background: theme.palette.primary.main,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.22)',
    transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out'
  },
  showSnackbar: {
    transform: 'translateY(0)',
    opacity: 1
  },
  snackbarText: {
    flex: 1,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    color: theme.palette.colors.white
  },
  actionBtn: {
    padding: 5,
    '& svg': {
      fontSize: 20,
      color: theme.palette.colors.white
    }
  }
}))

const FilesSnackbar = ({
  length,
  deselectAll,
  onDelete,
  children,
  _classes = {}
}) => {
  const classes = useStyles()

  if (length === 0) {
    return null
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      wrap="nowrap"
      className={cn(
        classes.snackbar,
        {
          [classes.showSnackbar]: length > 0
        },
        _classes.snackbar
      )}
    >
      <Typography className={cn(classes.snackbarText, _classes.snackbarText)}>
        {length} {length === 1 ? 'Item' : 'Items'} selected
      </Typography>
      <div className={_classes.children}>
        {children || (
          <IconButton className={classes.actionBtn} onClick={onDelete}>
            <Delete />
          </IconButton>
        )}
        <IconButton className={classes.actionBtn} onClick={deselectAll}>
          <Close />
        </IconButton>
      </div>
    </Grid>
  )
}

export default FilesSnackbar
