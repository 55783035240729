import React, { memo, useMemo } from 'react'
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core'
import CustomModal from '../../../../../CustomModal'
import { InsertDriveFile } from '@material-ui/icons'
import CustomInput from '../../../../../FormComponents/CustomInput'
import ActionButton from '../../../../../../components/ActionButton'
import { TABLE_SCROLLBAR_SIZE } from '../../../../../../utils/styles/CONSTANTS'

const useStyles = makeStyles(theme => ({
  header: {
    height: 68
  },
  title: {
    fontSize: 20,
    lineHeight: '36px',
    fontWeight: 600,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[90],
      'white'
    )
  },
  bodyText: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600
  },
  file: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    fontSize: 16
  },
  fileIcon: {
    color: theme.palette.colors.accent[5],
    marginRight: 5
  },
  selectedItems: {
    marginTop: 8,
    marginBottom: 24,
    maxHeight: 200,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: TABLE_SCROLLBAR_SIZE,
      height: TABLE_SCROLLBAR_SIZE,
      zIndex: 1
    },
    '&::-webkit-scrollbar-track': { background: 'transparent' },
    '&::-webkit-scrollbar-corner': { background: 'transparent' },
    '&::-webkit-scrollbar-thumb': { background: theme.palette.primary.main }
  },
  actionButton: {
    fontWeight: 600,
    fontSize: 14,
    color: 'white',
    textTransform: 'none',
    padding: '9px 12px',
    backgroundColor: theme.palette.colors.lighterGreen,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.colors.lighterGreen
    }
  }
}))

const TrainAiModal = ({
  selected,
  isOpened,
  toggleModal,
  state,
  onSubmit,
  handleChange
}) => {
  const theme = useTheme()
  const { name, description } = state || {}
  const disabled = name.length === 0
  const classes = useStyles({ disabled })


  const actionButtonStyles = useMemo(
    () => ({
      width: 'auto',
      borderRadius: 8,
      marginRight: 7,
      marginLeft: 18,
      padding: '6px 16px',
      backgroundColor: theme.palette.colors.lighterGreen,
      color: disabled ? '#777f96' : 'white'
    }),
    [disabled]
  )


  const TrainButton = memo(() => {
    return (
      <ActionButton
        label="Train AI model"
        onClick={() => {
          onSubmit()
          toggleModal()
        }}
        disabled={disabled}
        overideClasses={classes}
        className={classes.trainAiButton}
        customStyles={actionButtonStyles}
      />
    )
  })

  return (
    <CustomModal
      title={'Train AI'}
      open={isOpened}
      onClose={toggleModal}
      classes={classes}
      maxWidth={603}
      customPaperClass={classes.root}
      renderActionButton={<TrainButton />}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Typography className={classes.bodyText}>
        You are going to train AI model based on these curation sessions:
      </Typography>
      <div className={classes.selectedItems}>
        {selected.map((selectedObject, index) => (
          <Box className={classes.file} key={index}>
            <Typography noWrap> {selectedObject?.curation_id_passed ?? "Error on getting the Curation ID"} - {selectedObject.name_passed}</Typography>
          </Box>
        ))}
      </div>
      <CustomInput
        name={'name'}
        value={name}
        label={'New AI name'}
        onChange={handleChange}
        required
      />
      <CustomInput
        multiline
        rows={5}
        name={'description'}
        value={description}
        label={'Description'}
        onChange={handleChange}
      />
    </CustomModal>
  )
}

export default TrainAiModal
