import { useCallback } from 'react'
import { object, string } from 'yup'
import { editProfile } from '../../../endpoints/admin'
import {
  INVALID_EMAIL,
  MAX_120_CHARS,
  MAX_5_CHARS,
  NOTIFICATION,
  REQUIRED,
  REQUIRE_NUMBER
} from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { isEqual, normalizeFormValues } from '../../../utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { storeCurrentUser } from '../../../actions/login'
import useUploadPicture from '../../../hooks/useUploadPicture'
import useLogout from '../../../hooks/useLogout'
import { number } from 'yup'

function useEditProfile() {
  const addNotification = useNotification()
  const { onUpload, loading: loadingUploadPicture } = useUploadPicture()
  const currentUser = useSelector(state => state.login.currentUser)
  const dispatch = useDispatch()
  const logout = useLogout()
  const storeUserInfo = useCallback(
    user => dispatch(storeCurrentUser(user)),
    [dispatch]
  )

  const {
    address,
    city,
    country,
    email,
    lastname,
    name,
    phone,
    title,
    username,
    state,
    image,
    zip_code
  } = currentUser?.user || {}

  const initialValues = {
    address: address || '',
    city: city || '',
    country: country || '',
    email: email || '',
    lastname: lastname || '',
    state: state || '',
    name: name || '',
    phone: phone || '',
    title: title || '',
    username: username || '',
    zip_code: zip_code || '',
    image: image
  }

  const handleUploadImage = (key, acceptedFiles, setFieldValue) => {
    onUpload({
      files: acceptedFiles,
      loadingLabel: key,
      onSuccess: async result => {
        const { file, src } = result
        await onSubmit({ image: file })
        // update current picture for optimistic ui
        setFieldValue(key, src)
      }
    })
  }

  const updateUserInfo = useCallback(
    values => {
      const updated = {
        ...currentUser,
        user: {
          ...currentUser?.user,
          ...values
        }
      }
      storeUserInfo(updated)
    },
    [currentUser, storeUserInfo]
  )

  const onSubmit = useCallback(
    async values => {
      try {
        const submitValues = normalizeFormValues(values, false, true)
        const response = await editProfile(submitValues)
        const { data, msg } = response || {}
        if (msg === 'edit success') {
          if (!isEqual(data?.username, initialValues.username)) {
            addNotification({
              type: NOTIFICATION.SUCCESS,
              title: 'Username was successfully changed!'
            })
            return logout()
          }
          updateUserInfo(data)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Account profile successfully edited'
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, initialValues.username, logout, updateUserInfo]
  )

  const checkForChanges = useCallback(
    ({ image, ...values }, oldVal) =>
      Object.entries(values).some(([k, v]) => !isEqual(v, oldVal[k])),
    []
  )

  const validation = object({
    address: string().max(120, MAX_120_CHARS),
    city: string().max(120, MAX_120_CHARS),
    state: string().max(120, MAX_120_CHARS),
    country: string().max(120, MAX_120_CHARS).required(REQUIRED),
    email: string()
      .max(120, MAX_120_CHARS)
      .email(INVALID_EMAIL)
      .required(REQUIRED),
    lastname: string().max(120, MAX_120_CHARS).required(REQUIRED),
    name: string().max(120, MAX_120_CHARS).required(REQUIRED),
    phone: number().positive().integer().typeError(REQUIRE_NUMBER),
    title: string().max(5, MAX_5_CHARS).required(REQUIRED),
    username: string().max(120, MAX_120_CHARS).required(REQUIRED),
    zip_code: string().max(120, MAX_120_CHARS).required(REQUIRED)
  })

  return {
    initialValues,
    onSubmit,
    validation,
    checkForChanges,
    onUpload: handleUploadImage,
    loadingUploadPicture
  }
}

export default useEditProfile
