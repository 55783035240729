import { useCallback, useState } from 'react'
import { object, string } from 'yup'
import { REQUIRED, ROUTES } from '../../../../../../../utils/consts'
import { resetPassword } from '../../../../../../../endpoints/auth'
import { useHistory } from 'react-router-dom'
import { storeCurrentUserEmail } from '../../../../../../../actions/login'
import { useDispatch } from 'react-redux'

function useResetPasswordForm() {
  const history = useHistory()
  const dispatch = useDispatch()
  const [responseError, setResponseError] = useState('')

  const initialValues = {
    email: ''
  }

  const validation = object({
    email: string().email().required(REQUIRED).trim()
  })

  const storeEmail = useCallback(
    user => dispatch(storeCurrentUserEmail(user)),
    [dispatch]
  )

  const onSubmit = useCallback(
    async values => {
      try {
        const res = await resetPassword(values)
        if (res?.status === 200) {
          storeEmail(values.email)
          history.push(ROUTES.RESET_PASSWORD_SENT)
        }
      } catch (err) {
        setResponseError(err)
      }
    },
    [history, storeEmail]
  )

  return {
    initialValues,
    validation,
    onSubmit,
    responseError,
    setResponseError
  }
}

export default useResetPasswordForm
