import { useRef, useEffect, useCallback } from 'react'

export const useSafeDispatch = dispatch => {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])

  return useCallback(args => mounted.current && dispatch(args), [dispatch])
}
