import { useCallback } from 'react'
import useEditRecord from '../../../hooks/useEditRecord'
import { useParams } from 'react-router-dom'
import useToggleModal from '../../../../../hooks/useToggleModal'
import { MODALS_ID } from '../../../../../utils/consts'

function useEditTagsAndPublics() {
  const {
    toggle: toggleModal,
    isOpened,
    modalState
  } = useToggleModal(MODALS_ID.EDIT_TAGS_PUBLICATIONS_MODAL)
  const { type } = modalState?.args || {}
  const { onEdit, loading } = useEditRecord()
  const { recordingId: id } = useParams()

  const handleSave = useCallback(
    async chips => {
      const mapChips = chips.length > 0 ? chips.map(item => item.label) : []
      const data = { id, [type]: JSON.stringify(mapChips) }
      onEdit({
        data,
        onSuccess: updateStore => {
          updateStore({ [type]: chips })
          toggleModal()
        }
      })
    },
    [id, type, onEdit, toggleModal]
  )

  return { isOpened, toggleModal, handleSave, loading }
}

export default useEditTagsAndPublics
