import { checkAndCallFunction } from '../../utils'

export class ClickEvent {
  constructor(keyboardKeys, events) {
    this.events = events
    this.keyboardKeys = keyboardKeys
    this.instance = events.clearZoom
  }

  mouseDown = (e, { isSelectChannels = false, isSelectTimestamps }) => {
    var rect = e.target.getBoundingClientRect()

    var posx = e.clientX - rect.x
    var posy = e.clientY - rect.y

    if (isSelectChannels || this.keyboardKeys.get(17)) {
      checkAndCallFunction(this.events.selectChannel.mouseDown, {
        pos: [posx, posy],
        ctx: e.target.getContext('2d')
      })
    } else if (this.keyboardKeys.get(16)) {
      checkAndCallFunction(this.events.clearZoom.mouseDown)
    } else if (isSelectTimestamps) {
      checkAndCallFunction(this.events?.selectTimestamp.mouseDown, {
        pos: [posx, posy],
        ctx: e.target.getContext('2d')
      })
    }
  }
}
