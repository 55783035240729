import { combineEpics } from 'redux-observable'
import {
  saveClusterEpic,
  splitSpikesEpic,
  saveWaveformsEpic,
  switchWaveformEpic,
  saveMultipleSelectionWaveforms,
  saveSingleSelectionWaveforms
} from '../components/RecordingViewWithCuration/components/WidgetsLayout/redux/epics'
import {
  resetWidgetsEpic,
  hideRecommendationsEpic
} from '../components/RecordingViewWithCuration/components/WidgetsLayout/redux/epics'

export default combineEpics(
  saveClusterEpic,
  splitSpikesEpic,
  saveWaveformsEpic,
  switchWaveformEpic,
  saveMultipleSelectionWaveforms,
  saveSingleSelectionWaveforms,
  resetWidgetsEpic,
  hideRecommendationsEpic
)
