import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  plotWrapper: ({ PADDING }) => ({
    padding: PADDING,
    boxSizing: 'border-box',
    height: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  plottingRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly'
  }
})

export const Row = ({ data, children, width, height, PADDING }) => {
  const classes = useStyles({ PADDING })

  return (
    <div className={classes.plottingRow}>
      {data.map(([unit1, unit2]) => {
        return (
          <div
            className={classes.plotWrapper}
            style={{ width: width, height }}
            key={`${unit1}-${unit2}`}
          >
            {React.cloneElement(children, {
              unitID1: unit1,
              unitID2: unit2
            })}
          </div>
        )
      })}
    </div>
  )
}
