import React from 'react'
import CustomTable from '../../../CustomTable'
import InfoPanelItem from '../InfoPanelItem'
import useSortingsView from '../../hooks/useSortingsView'
import { makeStyles } from '@material-ui/core'
import ConfirmActionsModal from './components/ConfirmActionsModal'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  table: {
    height: 300
  }
}))

const SortingsView = () => {
  const classes = useStyles()
  const { columns, rows, pages, handleClickRow, renderActionButton, loading } =
    useSortingsView()

  return (
    <div className={classes.root}>
      <InfoPanelItem title="Spikesorting">
        <CustomTable
          rows={rows}
          pages={pages}
          rowHeight={65}
          cellWidth={120}
          withPagination
          isSticky
          loading={loading}
          columns={columns}
          className={classes.table}
          onClickRow={handleClickRow}
          renderActionButton={renderActionButton}
        />
        <ConfirmActionsModal />
      </InfoPanelItem>
    </div>
  )
}

export default SortingsView
