import React from 'react'
import useGetSpikesortings from '../../../hooks/useGetSpikesortings'
import FullScreenLoader from '../../../../FullScreenLoader'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '../../../../../utils/consts'
import SortingsView from '../../SortingsView'
import SortingInfo from '../components/SortingInfo'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  loading: {
    position: 'relative'
  }
}))

const DataSortings = () => {
  const classes = useStyles()
  const { loading } = useGetSpikesortings()

  if (loading) {
    return <FullScreenLoader className={classes.loading} />
  }

  return (
    <Switch>
      <Route exact path={ROUTES.RECORDING_VIEW} component={SortingInfo} />
      <Route
        exact
        path={ROUTES.RECORDING_VIEW_SORTINGS}
        component={SortingsView}
      />
    </Switch>
  )
}

export default DataSortings
