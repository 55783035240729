import { makeStyles } from '@material-ui/core/styles'
import React, { useCallback } from 'react'
import CustomDrawer from '../../../CustomDrawer'
import CustomTable from '../../../CustomTable'
import DrawerHeader from '../DrawerHeader'
import EmptySortings from '../EmptySortings'
import useToggleModal from '../../../../hooks/useToggleModal'
import { MODALS_ID, QPARAMS } from '../../../../utils/consts'
import { useSelector } from 'react-redux'
import useCurationList from './hooks/useCurationList'
import useGetCurations from './hooks/useGetCurations'
import DeleteConfirmationModal from '../../../FilesTable/components/DeleteConfirmationModal'
import useConfirmationModal from '../../../FilesTable/hooks/useConfirmationModal'

const useStyles = makeStyles(theme => ({
  drawer: {
    background: theme.palette.colors.backgrounds.contrast
  },
  modalRoot: {
    padding: 24
  }
}))

const CurationsDrawer = ({ isLimited }) => {
  const classes = useStyles()
  const { CURATIONS_DRAWER } = MODALS_ID
  const { toggle: toggleDrawer, isOpened } = useToggleModal(CURATIONS_DRAWER)
  const { toggleDeleteModal, isOpened: isDeleteModalOpened } =
    useConfirmationModal()

  const { loading: loadingList, fetchCurations } = useGetCurations()
  const {
    columns,
    rows,
    pages,
    isCurations,
    handleClickRow,
    handleCreate,
    handleDelete,
    itemToDelete,
    renderActionButton,
    loading: loadingActions
  } = useCurationList({ fetchCurations, toggleDeleteModal, isLimited })
  const loading = loadingList || loadingActions

  const selectedCuration = useSelector(
    state => state.recordingStore.selectedCuration
  )

  const isSelected = useCallback(
    id => id === selectedCuration?.id,
    [selectedCuration]
  )

  const onClickRow = useCallback(
    id => {
      handleClickRow(id)
      toggleDrawer()
    },
    [handleClickRow, toggleDrawer]
  )

  const labelBtn = 'New Curation'

  return (
    <CustomDrawer
      open={isOpened}
      onClose={toggleDrawer}
      maxWidth="80%"
      anchor="left"
      customPaperClass={classes.drawer}
      classNames={classes.modalRoot}
    >
      <DrawerHeader
        title="Curation Lists"
        labelBtn={labelBtn}
        onClick={handleCreate}
        isLimited={isLimited}
        loading={loadingActions?.create}
      />
      {!isCurations && !loading ? (
        <EmptySortings
          topic="curation"
          labelBtn={labelBtn}
          onClick={handleCreate}
          isLimited={isLimited}
        />
      ) : (
        <CustomTable
          rows={rows}
          pages={pages}
          rowHeight={65}
          cellWidth={150}
          withPagination
          isSticky
          isSelected={isSelected}
          loading={loading}
          columns={columns}
          onClickRow={onClickRow}
          statusMinWidth={'fit-content'}
          renderActionButton={!isLimited ? renderActionButton : null}
          customQparams={{
            page: QPARAMS.CURATIONS_PAGE
          }}
        />
      )}
      <DeleteConfirmationModal
        isOpened={isDeleteModalOpened}
        toggleDeleteModal={toggleDeleteModal}
        onDelete={() => handleDelete(itemToDelete)}
        selected={[itemToDelete]}
      />
    </CustomDrawer>
  )
}

export default CurationsDrawer
