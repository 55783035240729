import React, { memo } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import Stagnate from '../../../../imgs/Stagnante'
import useRunSpikeSortingPrompt from './hooks/useRunSpikeSortingPrompt'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    direction: 'row',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    gap: 10,
    left: 0,
    right: 0,
    bottom: 30,
    zIndex: 3,
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderRadius: 8,
    hyphens: 'auto',
    padding: '16px 20px',
    lineHeight: 1.43,
    fontsize: 16,
    height: 72,
    backgroundColor: theme.palette.colors.white,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.15)'
  },
  actionButton: {
    backgroundColor: theme.palette.action.secondary,
    color: theme.palette.colors.white,
    textTransform: 'none',
    borderRadius: 8,
    fontWeight: 600,
    height: 36,
    fontSize: 14,
    lineHeight: '18px',
    display: 'inline',
    '&:hover': {
      backgroundColor: theme.palette.secondary[80]
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    }
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    display: 'flex',
    flexGrow: 1,
    width: 357,
    color: theme.palette.colors.neutral[1],
    [theme.breakpoints.down('xs')]: {
      width: 'auto'
    }
  }
}))

const RunSpikeSortingPrompt = () => {
  const classes = useStyles()
  const { show, toggleSortingModal } = useRunSpikeSortingPrompt()
  if (!show) return
  return (
    <Grid className={classes.container}>
      <Stagnate />
      <Typography className={classes.title}>
        Before using the curation widgets, you must
      </Typography>
      <Button
        onClick={toggleSortingModal}
        variant={'contained'}
        className={classes.actionButton}
      >
        Run Spikesorting now
      </Button>
    </Grid>
  )
}

export default memo(RunSpikeSortingPrompt)
