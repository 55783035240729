import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CloudUpload, CheckCircleOutline } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import cn from 'classnames'
import BlurLoader from '../../BlurLoader'

const useStyles = makeStyles(theme => ({
  dropZone: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '45px 25px',
    borderRadius: 8,
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width: '100%',
    backgroundColor: 'transparent',
    border: `3px dashed ${theme.palette.colors.neutral['6']}`,
    justifyContent: 'center',
    boxSizing: 'border-box'
  },
  activeDropZone: {
    backgroundColor: theme.palette.secondary['10'],
    borderColor: theme.palette.action.secondary
  },
  input: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    margin: 0,
    padding: 0,
    opacity: 0,
    cursor: 'pointer'
  },
  icon: ({ isAlreadyUploaded }) => ({
    color: isAlreadyUploaded
      ? theme.palette.secondary[100]
      : theme.palette.colors.neutral['5'],
    fontSize: 50
  }),
  text: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    margin: '13px 0 2px',
    color: theme.palette.colors.neutral.black
  },
  subtext: {
    fontSize: 14,
    color: theme.probes.status.unused,
    textDecoration: 'underline'
  },
  accept: {
    fontWeight: 400,
    margin: 0
  }
}))

const CustomDropzone = ({
  onUpload,
  accept = ['.json'],
  multiple = false,
  file,
  loading,
  isAlreadyUploaded = false
}) => {
  const uploadedFile = file?.name ?? file
  const classes = useStyles({ isAlreadyUploaded })

  const title = isAlreadyUploaded
    ? `File ${uploadedFile} is uploaded`
    : uploadedFile || 'Drag the file or click to browse the directory'

  const Icon = isAlreadyUploaded ? CheckCircleOutline : CloudUpload

  let subtitle = uploadedFile ? 'Selected' : accept.join('/ ')
  if (isAlreadyUploaded) subtitle = 'Click here to upload another file'

  return (
    <div
      className={cn(classes.dropZone, {
        [classes.activeDropZone]: uploadedFile && !isAlreadyUploaded
      })}
    >
      {loading && <BlurLoader size={25} />}
      <Icon className={classes.icon} />
      <Typography className={classes.text}>{title}</Typography>
      <Typography
        className={cn(classes.text, {
          [classes.subtext]: isAlreadyUploaded,
          [classes.accept]: !isAlreadyUploaded
        })}
      >
        {subtitle}
      </Typography>
      <input
        type="file"
        required
        multiple={multiple}
        className={classes.input}
        accept={accept}
        onChange={event => onUpload(event.target.files, event)}
      />
    </div>
  )
}

export default CustomDropzone
