import React, { useCallback, useState } from 'react'
import { IMPEDANCE_STATUS } from '../../../../../utils/consts'
import Button from '@material-ui/core/Button'
import cn from 'classnames'
import { isOffsetException } from '../DrawProbe/DrawProbe'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  channel: {
    position: 'relative',
    zIndex: 2,
    height: 24,
    width: 33,
    padding: 0,
    marginLeft: 6,
    borderRadius: 3,
    marginBottom: 12,
    minWidth: 'auto',
    color: theme.palette.colors.neutral[4],
    backgroundColor: theme.palette.colors.white,
    opacity: 0.5,
    '&:hover': {
      backgroundColor: theme.palette.colors.white,
      color: theme.palette.colors.neutral[4],
      opacity: 0.9
    }
  },
  drawChannel: {
    position: 'absolute',
    height: 18,
    width: 18,
    margin: 0,
    color: theme.palette.colors.black,
    opacity: 0.5
  },
  channelBtnText: {
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 600
  },
  drawChannelBtnText: {
    fontSize: 9,
    lineHeight: '12px',
    fontWeight: 600
  },
  selectedChannel: () => ({
    opacity: 1
  }),
  disabledChannel: {
    color: `${theme.palette.colors.neutral[4]} !important`
  },
  impedanceValue: ({ isOpenChannel }) => ({
    width: 'fit-content',
    height: 20,
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 600,
    backgroundColor: isOpenChannel
      ? theme.palette.colors.accent[8]
      : theme.palette.secondary[100],

    position: 'absolute',
    color: 'white',
    padding: '3px 6px',
    bottom: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  drawImpedanceValue: () => ({
    height: 15,
    fontSize: 10
  }),
  openChannelIndicator: {
    width: 9,
    height: 9,
    borderRadius: '50%',
    backgroundColor: theme.palette.colors.accent[6],
    position: 'absolute',
    left: 28,
    top: 20
  },
  drawOpenChannelIndicator: {
    width: 6,
    height: 6,
    left: 16,
    top: 13
  }
}))

const Channel = ({
  channelKey,
  index: i,
  label,
  x,
  y,
  draw,
  readOnly,
  multigroup,
  selectedChannels,
  toggleChannel
}) => {
  const [showImpedance, setShowImpedance] = useState(null)

  const channelsImpedance = useSelector(
    state => state.timeSeriesLeftPanel.channelsImpedance
  )

  const isSelected = selectedChannels.has(channelKey)
  const channelImpedance = channelsImpedance?.get(channelKey)
  const showImpedanceValue = showImpedance && showImpedance.chIndex === i + 1
  const isOpenChannel =
    channelImpedance && channelImpedance.status === IMPEDANCE_STATUS.OPEN

  const classes = useStyles({ draw, isOpenChannel })

  const toggleImpedanceVisibility = useCallback(
    ({ chIndex, isOpenChannel }, channelImpedance) => {
      if (showImpedance) setShowImpedance(null)

      channelImpedance && setShowImpedance({ chIndex, isOpenChannel })
    },

    [showImpedance]
  )

  const Impedance = React.memo(({ showImpedanceValue, channelImpedance }) => {
    if (!showImpedanceValue) return

    return (
      <div
        className={cn(classes.impedanceValue, {
          [classes.drawImpedanceValue]: !!draw
        })}
      >
        {channelImpedance?.impedance}
      </div>
    )
  })

  const OpenChannelIndicator = React.memo(({ isOpenChannel }) => {
    if (!isOpenChannel) return

    return (
      <div
        className={cn(classes.openChannelIndicator, {
          [classes.drawOpenChannelIndicator]: !!draw
        })}
      />
    )
  })

  return (
    <Button
      key={i}
      onMouseOver={e =>
        toggleImpedanceVisibility(
          { chIndex: i + 1, isOpenChannel },
          channelImpedance
        )
      }
      onMouseOut={toggleImpedanceVisibility}
      disabled={readOnly}
      onClick={() => toggleChannel(channelKey)}
      className={cn(classes.channel, {
        [classes.selectedChannel]: isSelected,
        [classes.drawChannel]: !!draw
      })}
      classes={{ disabled: classes.disabledChannel }}
      style={{
        ...(draw && {
          left: x,
          bottom: multigroup && !isOffsetException(draw) ? y - 10 : y,
          transform: multigroup && 'scale(-1,1)'
        })
      }}
    >
      <Impedance
        showImpedanceValue={showImpedanceValue}
        channelImpedance={channelImpedance}
      />
      <Typography
        className={cn(classes.channelBtnText, {
          [classes.drawChannelBtnText]: !!draw
        })}
      >
        {label}
      </Typography>
      <OpenChannelIndicator isOpenChannel={isOpenChannel} />
    </Button>
  )
}

export default Channel
