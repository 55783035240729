import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useMemo, useState } from 'react'
import { selectSpikesorting } from '../../../actions/recording'
import { getUploadDate } from '../../../utils/utils'
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core'
import {
  MODALS_ID,
  QPARAMS,
  ROUTES,
  SPIKESORTING_STATUS
} from '../../../utils/consts'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import useQueryParams from '../../../hooks/useQueryParams'
import SortingStatus from '../components/SortingsView/SortingStatus'
import ProgressBar from '../../ProgressBar'
import ActionButton from '../../ActionButton'
import useSpikesortingController from './useSpikesortingController'
import useGetSpikesortings from './useGetSpikesortings'
import {
  DeleteRounded,
  GetAppRounded,
  StopRounded,
  VisibilityRounded
} from '@material-ui/icons'
import LogsView from '../components/LogsView'
import useToggleModal from '../../../hooks/useToggleModal'
import TableCheckbox from '../../CustomTable/components/TableCheckbox'
import { useDownloadFilesModal } from '../../../components/RecordingViewWithCuration/components/WidgetsLayout/hooks/useDownloadFilesModal'

const useStyles = makeStyles(theme => ({
  rootBtn: {
    padding: 5,
    '&:hover svg': {
      color: 'initial'
    }
  },
  runAgainBtn: {
    width: 'auto',
    margin: '0 auto'
  },
  phaseContent: {
    fontSize: 11,
    margin: 0
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  loadingRoot: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: '100%'
  }
}))

function useSortingsView({ isLimited }) {
  const classes = useStyles()
  const history = useHistory()
  const { recordingId } = useParams()
  const [selectedRow, setRowID] = useState(null)
  const { handleDownloadRecord, loading: isSortingDownloading } =
    useDownloadFilesModal()
  const { fetchSortings, loading: loadingSortings } = useGetSpikesortings(false)
  const {
    restartSpikesorting,
    deleteSpikesorting,
    stopSpikesorting,
    editSpikesorting,
    loading,
    setLoading
  } = useSpikesortingController()
  const pages = useSelector(state => state.recordingStore.sortings_pages)
  const sortings = useSelector(state => state.recordingStore.sortings)
  const query = useQueryParams()
  const page = query.getByName(QPARAMS.SORTINGS_PAGE)
  const { pathname } = useLocation()
  const isCuration = pathname.includes('curation')
  const isSortings = sortings?.length > 0

  const modalID = MODALS_ID.SPIKESORTING_ACTION_CONFIRM_MODAL
  const { toggle: toggleModal } = useToggleModal(modalID)

  const dispatch = useDispatch()
  const store = useCallback(
    data => dispatch(selectSpikesorting(data)),
    [dispatch]
  )

  const selectSorting = useCallback(
    async (id, type) => {
      await store({ id, type })
      const withQuery = page ? `?${QPARAMS.SORTINGS_PAGE}=${page}` : ''
      if (!isCuration)
        history.push(
          ROUTES.RECORDING_VIEW.replace(
            ':recordingId',
            `${recordingId}${withQuery}`
          )
        )
    },
    [history, isCuration, page, recordingId, store]
  )

  const handleRestartSpikesorting = useCallback(
    ID => {
      setRowID(ID)
      const data = { run_id: ID }
      restartSpikesorting({
        data,
        onSuccess: () => {
          fetchSortings()
          setRowID(null)
        }
      })
    },
    [fetchSortings, restartSpikesorting]
  )

  const handleStopSpikesorting = useCallback(
    ID => {
      const data = { id: ID }
      toggleModal({
        type: 'stop',
        onConfirm: () => {
          setLoading(true)
          stopSpikesorting({
            data,
            onSuccess: () => {
              fetchSortings()
              setLoading(false)
            }
          })
        }
      })
    },
    [fetchSortings, setLoading, stopSpikesorting, toggleModal]
  )

  const handleEditSpikesorting = useCallback(
    ID => {
      const data = { id: ID, default: 1 }
      setLoading(true)
      editSpikesorting({
        data,
        showMessage: false,
        onSuccess: () => {
          fetchSortings()
          selectSorting(ID, 'default')
        }
      })
    },
    [editSpikesorting, fetchSortings, selectSorting, setLoading]
  )

  const onDelete = useCallback(
    ID => {
      const data = { id: ID }
      toggleModal({
        type: 'delete',
        onConfirm: () => {
          setLoading(true)
          deleteSpikesorting({
            data,
            onSuccess: () => {
              fetchSortings()
              setLoading(false)
            }
          })
        }
      })
    },
    [deleteSpikesorting, fetchSortings, setLoading, toggleModal]
  )

  const onDownload = useCallback(
    (id, sortingIndex) =>
      handleDownloadRecord({ class_type: 'run', id, index: sortingIndex }),
    [handleDownloadRecord]
  )
  const columns = [
    {
      id: 0,
      label: 'Name',
      active: true
    },
    {
      id: 1,
      label: 'Type',
      active: true
    },
    {
      id: 2,
      label: 'Status',
      active: true
    },
    {
      id: 3,
      label: 'Stop',
      active: true
    },
    {
      id: 4,
      label: 'Progress',
      active: true
    },
    {
      id: 5,
      label: 'Default',
      active: true
    },
    {
      id: 1,
      label: 'Upload Date',
      active: true
    }
  ]

  const renderProgressBar = useCallback(
    (progress, isFailed, id, output) => {
      if (isFailed) {
        return (
          <ActionButton
            label="Run again"
            loading={selectedRow === id && loading}
            onClick={e => {
              e.stopPropagation()
              handleRestartSpikesorting(id)
            }}
            disabled={isLimited}
            className={classes.runAgainBtn}
          />
        )
      }
      return (
        <div>
          {output && (
            <Typography className={classes.phaseContent}>{output}</Typography>
          )}
          <ProgressBar progress={progress || 0} />
        </div>
      )
    },
    [
      classes.phaseContent,
      classes.runAgainBtn,
      handleRestartSpikesorting,
      loading,
      isLimited,
      selectedRow
    ]
  )

  const renderActionButton = useCallback(
    id => {
      const selected = sortings.find(item => item.id === id)
      const sortingIndex = sortings.findIndex(item => item.id === id)
      const { zip_link } = selected || {}
      const isLoading =
        (selectedRow === id && loading) ||
        isSortingDownloading.index === sortingIndex
      if (isLoading) {
        return <CircularProgress disableShrink size={18} />
      }
      return (
        <React.Fragment>
          <IconButton>
            <VisibilityRounded />
          </IconButton>
          <LogsView id={id} />
          <IconButton
            onClick={e => {
              e.stopPropagation()
              return onDelete(id)
            }}
          >
            <DeleteRounded />
          </IconButton>
          {zip_link && (
            <IconButton
              onClick={e => {
                e.stopPropagation()
                return onDownload(id, sortingIndex)
              }}
            >
              <GetAppRounded />
            </IconButton>
          )}
        </React.Fragment>
      )
    },
    [loading, onDelete, onDownload, selectedRow, sortings]
  )

  const renderStopButton = useCallback(
    (progress, status, id) => {
      const stoppedStatus = SPIKESORTING_STATUS.STOPPED.label.toUpperCase()
      return (
        status !== stoppedStatus &&
        progress > 0 &&
        progress < 100 && (
          <IconButton
            onClick={e => {
              e.stopPropagation()
              handleStopSpikesorting(id)
            }}
            className={classes.rootBtn}
            disabled={isLimited}
          >
            <StopRounded />
          </IconButton>
        )
      )
    },
    [classes.rootBtn, handleStopSpikesorting, isLimited]
  )

  const renderCheckbox = useCallback(
    (isDefault, id) => (
      <TableCheckbox
        isDefault={isDefault}
        onClick={e => {
          e.stopPropagation()
          if (!isDefault && !isLimited) handleEditSpikesorting(id)
        }}
      />
    ),
    [handleEditSpikesorting, isLimited]
  )

  const rows = useMemo(
    () =>
      sortings?.map(item => {
        const {
          name,
          algo,
          status: st,
          progress,
          created_at,
          id,
          default: isDefault,
          output
        } = item
        const date = getUploadDate(created_at)
        const isFailed = st?.toLowerCase() === 'failure'
        const status = st?.toUpperCase()
        return {
          id,
          name,
          algo,
          status_code: <SortingStatus status={status} />,
          stop: renderStopButton(progress, status, id),
          actions: renderProgressBar(progress, isFailed, id, output),
          default: renderCheckbox(isDefault, id),
          date
        }
      }),
    [sortings, renderStopButton, renderProgressBar, renderCheckbox]
  )

  return {
    rows,
    pages,
    columns,
    loading,
    isSortings,
    renderActionButton,
    loadingSortings,
    handleClickRow: selectSorting
  }
}

export default useSortingsView
