import { useCallback, useState } from 'react'
import {
  deleteSpikeSorting,
  restartSpikeSorting,
  runSpikeSorting,
  stopSpikeSorting,
  editSpikeSorting
} from '../../../endpoints/admin'
import {
  FILE_STATUS,
  NOTIFICATION,
  SPIKESORTING_STATUS
} from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import useRecordingInfo from './useRecordingInfo'
import { useDispatch } from 'react-redux'
import { deleteDefaultRun } from '../../../actions/recording'

function useSpikesortingController() {
  const addNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const { recording } = useRecordingInfo()
  const dispatch = useDispatch()
  const resetDefaultRun = useCallback(
    () => dispatch(deleteDefaultRun()),
    [dispatch]
  )

  const onCatch = useCallback(
    e => {
      setLoading(false)
      let message
      switch (FILE_STATUS[recording?.status_int]) {
        case FILE_STATUS[0]: {
          message = 'File upload failed. Please try to upload the file again.'
          break
        }
        case FILE_STATUS[1]: {
          message =
            'The file is still in the process of uploading. Please try again when the upload will be completed.'
          break
        }
        default:
          message = typeof e === 'string' ? e : e?.message
      }

      addNotification({
        type: NOTIFICATION.ERROR,
        title: 'Spikesorting failed !',
        message
      })
    },
    [addNotification, recording?.status_int]
  )

  const outputMessage = useCallback(
    (output = null) => {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: 'Spikesorting failed !',
        message: output
      })
    },
    [addNotification]
  )

  const handleRunSpikesorting = useCallback(
    async ({ data, onSuccess, onError }) => {
      try {
        setLoading(true)
        const response = await runSpikeSorting(data)
        if (response?.created_at) {
          await onSuccess()
          setLoading(false)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'New Spikesorting was successfully created!'
          })
          if (
            response?.status.toLowerCase() ===
            SPIKESORTING_STATUS.FAILURE.label.toLowerCase()
          ) {
            outputMessage(response?.output)
          }
        }
      } catch (e) {
        await onError()
        onCatch(e)
      }
    },
    [addNotification, onCatch, outputMessage]
  )

  const handleRestartSpikesorting = useCallback(
    async ({ data, onSuccess = () => null }) => {
      try {
        setLoading(true)
        const response = await restartSpikeSorting(data)
        if (response?.created_at) {
          await onSuccess()
          setLoading(false)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'This Spikesorting was successfully restarted!'
          })
          if (
            response?.status.toLowerCase() ===
            SPIKESORTING_STATUS.FAILURE.label.toLowerCase()
          ) {
            outputMessage(response?.output)
          }
        }
      } catch (e) {
        onCatch(e)
      }
    },
    [addNotification, onCatch, outputMessage]
  )

  const handleStopSpikesorting = useCallback(
    async ({ data, onSuccess = () => null }) => {
      try {
        const response = await stopSpikeSorting(data)
        if (response?.message) {
          await onSuccess()
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: response?.message
          })
        }
      } catch (e) {
        onCatch(e)
      }
    },
    [addNotification, onCatch]
  )

  const handleDeleteSpikesorting = useCallback(
    async ({ data, onSuccess = () => null }) => {
      try {
        setLoading(true)
        const response = await deleteSpikeSorting(data)
        if (response === 'done') {
          const isDefaultRun = data.id === recording.default_run?.id
          if (isDefaultRun) resetDefaultRun()
          await onSuccess()
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Row deleted successfully'
          })
        }
        setLoading(false)
      } catch (e) {
        onCatch(e)
      }
    },
    [addNotification, onCatch, recording.default_run?.id, resetDefaultRun]
  )

  const handleEditSpikesorting = useCallback(
    async ({
      data,
      onSuccess = () => null,
      onError = () => null,
      showMessage = true
    }) => {
      editSpikeSorting(data)
        .then(() => {
          onSuccess()
          setLoading(false)
          if (showMessage)
            addNotification({
              type: NOTIFICATION.SUCCESS,
              title: 'Row edited successfully'
            })
        })
        .catch(err => {
          onError()
          setLoading(false)
          addNotification({
            type: NOTIFICATION.ERROR,
            title: err
          })
        })
    },
    [addNotification]
  )

  return {
    loading,
    setLoading,
    runSpikesorting: handleRunSpikesorting,
    restartSpikesorting: handleRestartSpikesorting,
    stopSpikesorting: handleStopSpikesorting,
    deleteSpikesorting: handleDeleteSpikesorting,
    editSpikesorting: handleEditSpikesorting
  }
}

export default useSpikesortingController
