import { object, string } from 'yup'
import { useCallback } from 'react'
import { signIn } from '../../../endpoints/auth'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { MIN_5_CHARS, NOTIFICATION, REQUIRED } from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'

function useSignInUser() {
  const history = useHistory()
  const addNotification = useNotification()
  const initialValues = {
    username: '',
    password: ''
  }

  const onSubmit = useCallback(
    async values => {
      try {
        const response = await signIn(values)
        if (response) {
          const {
            message,
            access_token: token,
            isTermsChecked
          } = response || {}
          if (!token) {
            addNotification({
              type: NOTIFICATION.ERROR,
              title: message
            })
            return
          }
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          localStorage.setItem('access_token', token)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            duration: 5000,
            title: 'Successfully logged in !'
          })
          return !isTermsChecked ? history.push('/terms') : history.goBack()
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, history]
  )

  const validation = object({
    username: string().required(REQUIRED).trim(),
    password: string().required(REQUIRED).min(5, MIN_5_CHARS).trim()
  })

  return { onSubmit, initialValues, validation }
}

export default useSignInUser
