import { useCallback, useEffect, useState } from 'react'
import useToggleModal from '../../../../../hooks/useToggleModal'
import { MODALS_ID } from '../../../../../utils/consts'
import useGetCurrentUser from '../../../../../hooks/useGetCurrentUser'

function useAIOnboardingModal() {
  const [currentStep, setCurrentStep] = useState(0)
  const { isOpened, toggle: toggleTourModal } = useToggleModal(
    MODALS_ID.FILES_CURATION_AI_ONBOARDING
  )
  const { user } = useGetCurrentUser()
  const { id: userId, company } = user || {}
  const { id: companyId } = company || {}

  const incrementStep = useCallback(() => {
    setCurrentStep(prev => prev + 1)
  }, [])

  const key = `curation_ai_onboarding-${userId}-${companyId}`

  const endTour = useCallback(() => {
    localStorage.setItem(key, true)
    toggleTourModal()
  }, [toggleTourModal, key])

  const checkOnboardingStatus = useCallback(async () => {
    try {
      const isFilesTourComplete = JSON.parse(localStorage.getItem(key))

      if (!isFilesTourComplete) toggleTourModal()
    } catch (e) {
      toggleTourModal()
    }
  }, [toggleTourModal, key])

  useEffect(() => {
    checkOnboardingStatus()
  }, [])

  return {
    isOpened,
    currentStep,
    incrementStep,
    setCurrentStep,
    endTour
  }
}

export default useAIOnboardingModal
