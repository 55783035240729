import useNotification from '../SnackbarManager/hooks/useNotification'
import { NOTIFICATION } from '../../utils/consts'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.colors.backgrounds.main,
    height: '100%'
  }
}))

function Snackbar() {
  const addNotification = useNotification()
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div>
        <button
          onClick={() => {
            addNotification({
              title: 'New update version V.10',
              message:
                'New app version available, please refresh page to get all updates!'
            })
          }}
        >
          SIMPLE MESSAGE
        </button>
        <button
          onClick={() => {
            addNotification({
              type: NOTIFICATION.ERROR,
              title:
                "It's not possible to open the file now, please try again later."
            })
          }}
        >
          ERROR
        </button>
        <button
          onClick={() => {
            addNotification({
              type: NOTIFICATION.WARNING,
              title: 'File in pause',
              body: () => (
                <>
                  The file: <span>savings_account...</span> paused at the
                  moment, if you would resume download please click the button.
                </>
              ),
              action: {
                label: 'Resume',
                onClick: () => alert('resume')
              }
            })
          }}
        >
          WARNING
        </button>
        <button
          onClick={() => {
            addNotification({
              type: NOTIFICATION.SUCCESS,
              title: 'Download completed'
            })
          }}
        >
          SUCCESS
        </button>
        <button
          onClick={() => {
            addNotification({
              type: NOTIFICATION.INFO,
              title: 'Info Snackbar'
            })
          }}
        >
          INFO
        </button>
      </div>
    </div>
  )
}

export default Snackbar
