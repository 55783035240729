import { useCallback, useEffect, useState } from 'react'
import { getRecordInfo, getEvents } from '../../../endpoints/admin'
import { useParams } from 'react-router-dom'
import {
  storeRecord,
  storeEventFiles,
  resetRecord
} from '../../../actions/recording'
import { useDispatch } from 'react-redux'

function useRecordingView() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const { recordingId } = useParams()

  const dispatch = useDispatch()
  const store = useCallback(data => dispatch(storeRecord(data)), [dispatch])
  const reset = useCallback(() => dispatch(resetRecord()), [dispatch])
  const storeEventsData = useCallback(
    data => dispatch(storeEventFiles(data)),
    [dispatch]
  )

  const fetchRecord = useCallback(async () => {
    try {
      setLoading(true)
      reset()
      const data = await getRecordInfo({ id: recordingId })
      const eventsData = await getEvents({ record_id: recordingId })
      storeEventsData(eventsData)
      store({ data })
      setLoading(false)
      setError(null)
    } catch (err) {
      const { status } = err?.response || {}
      setError(status || 404)
      setLoading(false)
    }
  }, [recordingId, store, reset])

  useEffect(() => {
    fetchRecord()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loading, error }
}

export default useRecordingView
