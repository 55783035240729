import React, { useState } from 'react'
import {
  Box,
  makeStyles,
  Typography,
  Checkbox,
  FormControlLabel,
  useTheme
} from '@material-ui/core'
import CustomModal from '../../../CustomModal'
import ActionButton from '../../../ActionButton'
import CustomInput from '../../../FormComponents/CustomInput/CustomInput'

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    marginTop: 30
  },
  checkboxLabel: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  },
  input: {
    marginTop: 35
  }
}))

export default function ActionsModalWrapper({
  isOpened,
  toggleModal,
  handleSubmit,
  isSubmitting,
  selected,
  title,
  children,
  order = null
}) {
  const theme = useTheme()
  const classes = useStyles()

  const [fileName, setFileName] = useState('')
  const [remove, setRemoveDatasets] = useState(false)

  const onSubmit = () => {
    let data = { selected, new_file_name: fileName, remove }
    if (order) data = { ...data, order }
    handleSubmit(data)
    setFileName('')
  }

  return (
    <CustomModal
      open={isOpened}
      title={<Box className={classes.title}>{title}</Box>}
      onClose={toggleModal}
      maxWidth={650}
      renderActionButton={
        <ActionButton
          label="Execute"
          disabled={fileName?.length < 1}
          loading={isSubmitting}
          onClick={onSubmit}
          customStyles={{
            backgroundColor: theme.palette.action.secondary
          }}
        />
      }
    >
      {children}

      <CustomInput
        label="New dataset name"
        name="New dataset name"
        value={fileName}
        onChange={e => setFileName(e.target.value)}
        className={classes.input}
        required
        fullWidth
      />
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={remove}
            onChange={() => setRemoveDatasets(v => !v)}
            size="small"
            color="primary"
          />
        }
        label={
          <Typography className={classes.checkboxLabel}>
            Delete old datasets
          </Typography>
        }
      />
    </CustomModal>
  )
}
