import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from '../actions/login'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../utils/consts'

function useLogout() {
  const dispatch = useDispatch()
  let history = useHistory()
  const logoutUser = useCallback(() => dispatch(logout()), [dispatch])
  return useCallback(() => {
    localStorage.removeItem('access_token')
    logoutUser()
    history.push(ROUTES.LOGIN)
  }, [history, logoutUser])
}

export default useLogout
