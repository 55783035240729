import { useState, useCallback, useEffect, useMemo } from 'react'
import { MODALS_ID, NOTIFICATION } from '../../../../../utils/consts'
import useToggleModal from '../../../../../hooks/useToggleModal'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import useGetCurrentUser from '../../../../../hooks/useGetCurrentUser'
import { normalizeFormValues } from '../../../../../utils/utils'
import { createProbe, editProbe } from '../../../../../endpoints/admin'
import { isEmpty } from 'lodash/fp'
import useGetAllProbes from '../../../hooks/useGetAllProbes'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setCurrentProbe } from '../../../../../actions/probes'

const initialParams = {
  probe: '',
  threshold: '',
  impedanceName: ''
}

const useProbeUploadModal = () => {
  const { toggle, isOpened } = useToggleModal(MODALS_ID.PROBE_UPLOAD_MODAL)
  const addNotification = useNotification()
  const dispatch = useDispatch()

  const [params, setParams] = useState(initialParams)
  const [impedanceFile, setImpedanceFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const { user } = useGetCurrentUser()
  const { fetchProbes } = useGetAllProbes()
  const { currentProbe } = useSelector(state => state.probesStore)
  const [isEditMode, setEditMode] = useState(false)
  const { company_id: default_company_id } = user
  const {
    id,
    probe,
    serial,
    threshold,
    company_id,
    impedan,
    shipping_status,
    shipping_info
  } = currentProbe ?? {}

  useEffect(() => {
    if (currentProbe) {
      const impedanceName = impedan?.slice(impedan.lastIndexOf('/') + 1)
      setEditMode(true)
      setParams(current => ({
        ...current,
        serial,
        probe,
        threshold,
        company_id,
        impedanceName
      }))
    }
  }, [currentProbe])

  const toggleModal = useCallback(() => {
    toggle()
    setParams(initialParams)
    setImpedanceFile(null)
    isEditMode && setEditMode(false)
    currentProbe && dispatch(setCurrentProbe())
  }, [toggle, dispatch, currentProbe, isEditMode])

  const isDisabled = useMemo(
    () =>
      Object.keys(params).some(param => {
        if (param === 'impedanceName') {
          const isThresholdEmpty = isEmpty(params['threshold']?.toString())
          const isImpedanceSelected = !isEmpty(params[param]?.toString())
          return isImpedanceSelected && isThresholdEmpty
        }

        const exclusions = !['impedanceName', 'threshold', 'serial'].includes(
          param
        )

        return isEmpty(params[param]?.toString()) && exclusions
      }),
    [params]
  )

  const handleChange = useCallback(event => {
    const { value, name } = event?.target || event
    if (!name) return

    setParams(p => ({ ...p, [name]: value }))
  }, [])

  const handleFileValidation = useCallback(
    async (file, event) => {
      const ACCEPTED_FILE_TYPES = ['text/csv', 'text/plain']
      const { name, type } = file[0] ?? {}
      if (name) {
        if (!ACCEPTED_FILE_TYPES.includes(type.toLowerCase())) {
          event.target.value = null
          return addNotification({
            type: NOTIFICATION.ERROR,
            title: 'Invalid file type'
          })
        }

        setParams(p => ({ ...p, impedanceName: name }))
        setImpedanceFile(file[0])
      }
    },
    [addNotification]
  )

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true)
      const probeValues = {
        ...params,
        ...(isEditMode ? { id } : { company_id: default_company_id }),
        shipping_status: shipping_status ?? 'Delivered',
        shipping_info: shipping_info ?? ' ',
        web_app: 1,
        ...(!isEmpty(params.impedanceName)
          ? {
              impedan: impedanceFile ?? impedan,
              threshold: Number(params.threshold)
            }
          : {})
      }
      const query = isEditMode ? editProbe : createProbe
      await query(normalizeFormValues(probeValues))
      addNotification({
        type: NOTIFICATION.SUCCESS,
        title: isEditMode
          ? 'Probe successfully edited'
          : 'Probe successfully created'
      })
      fetchProbes()
      toggleModal()

      setLoading(false)
    } catch (e) {
      setLoading(false)
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [
    addNotification,
    impedanceFile,
    params,
    fetchProbes,
    impedan,
    isEditMode,
    shipping_info,
    shipping_status,
    id,
    default_company_id,
    toggleModal
  ])

  return {
    isOpened,
    toggleModal,
    params,
    handleChange,
    handleFileValidation,
    isDisabled,
    impedanceFile,
    loading,
    onSubmit,
    isEditMode
  }
}

export default useProbeUploadModal
