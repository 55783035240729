import React from 'react'
import { Typography, makeStyles } from '@material-ui/core'
import ActionButton from '../../../ActionButton'
import { Add } from '@material-ui/icons'
import useProbeUploadModal from '../ProbeUploadModal/hooks/useProbeUploadModal'

const useStyles = makeStyles(theme => ({
  headerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 24,
    marginBottom: 25
  },
  title: {
    fontSize: 24,
    fontWeight: 600
  },
  action: {
    width: 'auto',
    minWidth: 'auto',
    padding: '0px 12px',
    borderRadius: 8,
    marginLeft: 25
  }
}))

const Header = () => {
  const classes = useStyles()
  const { toggleModal } = useProbeUploadModal()
  return (
    <div className={classes.headerRoot}>
      <Typography variant="h2" className={classes.title}>
        Probe inventory
      </Typography>
      <ActionButton
        className={classes.action}
        startIcon={<Add />}
        label="Add Probe"
        onClick={toggleModal}
      />
    </div>
  )
}

export default Header
