import React, { useState } from 'react'
import cn from 'classnames'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { InsertDriveFile } from '@material-ui/icons'
import TableCheckbox from '../../../CustomTable/components/TableCheckbox'
import ActionsModalWrapper from '../ActionsModalWrapper'
import useConcatFilesModal from '../../hooks/useConcatFilesModal'

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  filesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '12px 0'
  },
  file: {
    marginRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    fontSize: 16
  },
  fileIcon: {
    color: theme.palette.colors.accent[5],
    marginRight: 5
  },
  reversed: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  tableCheckbox: {
    width: 40
  },

  wrapper: {
    display: 'flex'
  }
}))

export default function ConcatFilesModal(props) {
  const { isOpened, loading, onConcat, toggleModal } = useConcatFilesModal()

  const [order, setOrder] = useState(1)

  if (!Array.isArray(props?.selected)) return null

  return (
    <ActionsModalWrapper
      title="Concat Files"
      order={order}
      isOpened={isOpened}
      toggleModal={toggleModal}
      handleSubmit={onConcat}
      isSubmitting={loading}
      {...props}
    >
      <Typography>Select the order of the files to be concatenated</Typography>

      {[1, 2].map(el => (
        <FileRow
          key={el}
          selected={props.selected}
          isActive={order === el}
          handleClick={() => setOrder(el)}
          isReversed={el === 2}
        />
      ))}
    </ActionsModalWrapper>
  )
}

const FileRow = ({ isActive, handleClick, selected, isReversed = false }) => {
  const classes = useStyles()
  return (
    <Box className={classes.filesContainer}>
      <div className={classes.tableCheckbox}>
        <TableCheckbox size={24} isDefault={isActive} onClick={handleClick} />
      </div>
      <Box
        className={cn(classes.wrapper, {
          [classes.reversed]: isReversed
        })}
      >
        {selected?.map(({ name }, index) => (
          <span className={classes.file} key={index}>
            <InsertDriveFile className={classes.fileIcon} />
            <Typography noWrap>
              {name.length > 25 ? `${name.slice(0, 25)}...` : name}
              {isReversed ? index === 1 && ' ,' : index === 0 && ' ,'}
            </Typography>
          </span>
        ))}
      </Box>
    </Box>
  )
}
