import { useSelector } from 'react-redux'

function useRecordingInfo() {
  const { recording, eventFiles } = useSelector(state => state.recordingStore)
  const isFileUploaded = recording?.status_int === 2

  return {
    recording,
    eventFiles,
    isFileUploaded
  }
}

export default useRecordingInfo
