import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'

const useStyles = makeStyles(theme => ({
  chip: ({ color }) => ({
    margin: '0 5px 15px',
    background: theme.palette.colors.accent[color],
    color: theme.palette.colors.white,
    borderRadius: 4,
    fontSize: 14,
    maxWidth: '50%'
  }),
  label: {
    padding: '0 8px'
  },
  deleteIcon: {
    fill: theme.palette.colors.white
  }
}))

const Tag = ({ onDelete, tag, color }) => {
  const classes = useStyles({ color })

  return (
    <Chip
      onDelete={onDelete}
      title={tag.label}
      label={tag.label}
      className={classes.chip}
      classes={{
        label: classes.label
      }}
      deleteIcon={<ClearOutlinedIcon className={classes.deleteIcon} />}
    />
  )
}

export default Tag
