import React from 'react'
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect
} from 'react-router-dom'
import Login from './components/Login'
import AdminPanel from './components/AdminPanel'
import AuthRoute from './components/AuthRoute'
import { ROUTES } from './utils/consts'

const Routes = () => {
  return (
    <div>
      <Router>
        <Switch>
          <AuthRoute path={'/auth'} component={Login} />
          <Route path="/" component={AdminPanel} />
          <Redirect from={'/login'} to={ROUTES.LOGIN} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </div>
  )
}

const NotFound = () => {
  return <div>Not found</div>
}

export default Routes
