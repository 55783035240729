import React from 'react'
import BaseWidget from '../BaseWidget'
import { makeStyles } from '@material-ui/core'
import { MODALS_ID, RECORD_TABS } from '../../../../../../utils/consts'
import { useSelector } from 'react-redux'
import TagsBody from '../TagsView/components/TagsBody'
import useToggleModal from '../../../../../../hooks/useToggleModal'

const useStyles = makeStyles(() => ({
  root: {
    padding: '16px 9px'
  }
}))

export const PublicationsView = () => {
  const classes = useStyles()
  const publications = useSelector(state => state.recordingStore.publications)
  const { toggle: toggleModal } = useEditPublications()

  return (
    <BaseWidget overrideClassName={classes.root}>
      <TagsBody onBodyClick={toggleModal} colorAccent={3} tags={publications} />
    </BaseWidget>
  )
}

export function useEditPublications() {
  return useToggleModal(MODALS_ID.EDIT_TAGS_PUBLICATIONS_MODAL, {
    type: RECORD_TABS.PUBLICATIONS,
    colorAccent: 3
  })
}
