import { ofType } from 'redux-observable'
import {
  mergeMap,
  switchMap,
  map,
  catchError,
  endWith,
  startWith,
  withLatestFrom,
  delay,
  tap
} from 'rxjs/operators'
import { from, merge, of, throwError } from 'rxjs'
import {
  initCuration,
  updateSingleCuration
} from '../../../../../../actions/curations'
import { onSplitSpikes, getCurations$ } from '../../../../../../endpoints/admin'
import { getAsObservable } from '../../../../../../utils/rxjs.utils'
import { NOTIFICATION } from '../../../../../../utils/consts'
import {
  SPLIT_SPIKES,
  setLoadingClusterview,
  saveClusterUnits,
  loadClusterview,
  selectSpikesActionCreator
} from '../actions'
import { PlotWaveforms } from '../../widgets/WaveFormView'
import { PlotFeatures } from '../../widgets/FeatureView'

export const splitSpikesEpic = (action$, state$) =>
  action$.pipe(
    ofType(SPLIT_SPIKES),
    withLatestFrom(state$),
    tap(([action, state]) => {
      if (!action.payload.warn) {
        PlotWaveforms.clearAllTraces()
        PlotFeatures.clearScatterTraces()
      }

      return [action, state]
    }),
    switchMap(([action, state]) => {
      if (action.payload.warn) {
        return splitSpikesErrorEpic(
          `You can select only spikes from one subplot`
        )
      }
      return splitSpikesReqEpic(action, state)
    })
  )

const splitSpikesReqEpic = (action, state) => {
  const units = state.widgetsCache.units.cluster
  const sortingId = state.recordingStore.selectedSorting?.id
  const curationId = state.recordingStore.selectedCuration?.id
  const { sortBy, sortDirection, secondSortBy } = state.widgetsCache.clusters

  return from(
    getAsObservable(onSplitSpikes, {
      run_id: sortingId,
      curation_id: curationId,
      unitId: units,
      spikes: action.payload.spikes,
      lassoPoints: action.payload.lassoPoints,
      subplot: action.payload.subplot,
      sort_by: sortBy,
      direction: sortDirection.toLowerCase(),
      sort_by_second: secondSortBy
    })
  ).pipe(
    mergeMap(action => {
      const keys = [sortingId, curationId, sortBy, sortDirection]
      const isSecondSortBy = Boolean(secondSortBy)
      const clusterKeys = isSecondSortBy ? [...keys, secondSortBy] : keys

      const key = clusterKeys.join('-')

      const { clusters } = action

      const highlited = clusters.filter(item => item?.highlited) || []
      const units = highlited.map(item => Number(item?.id)).filter(Boolean)

      return merge(
        fetchSingleCurationEpic(state),
        loadClusterviewEpic(action, key),
        saveUnitsEpic(units),
        saveClustersEpic()
      )
    }),
    startWith(initCuration(), setLoadingClusterview(true)),
    endWith(
      selectSpikesActionCreator({
        spikes: [],
        subplot: [],
        warn: false,
        lassoPoints: {}
      })
    ),
    catchError(error => {
      return of(
        {
          type: 'ADD_NOTIFICATION',
          payload: {
            title: error.message ?? error,
            type: NOTIFICATION.ERROR
          }
        },
        setLoadingClusterview(false)
      )
    })
  )
}

const saveClustersEpic = () => {
  return of(saveClusterUnits(null), setLoadingClusterview(false), {
    type: 'ADD_NOTIFICATION',
    payload: {
      title: 'Successfully splitted',
      type: NOTIFICATION.SUCCESS
    }
  })
}

const saveUnitsEpic = units => {
  return merge(
    ...units.map(unit =>
      of(unit).pipe(
        delay(250),
        map(_x =>
          saveClusterUnits({ multiple: units.length > 1, unit_id: unit })
        )
      )
    )
  )
}

const fetchSingleCurationEpic = state => {
  const curationId = state.recordingStore.selectedCuration?.id

  return from(
    getAsObservable(getCurations$, {
      id: curationId
    })
  ).pipe(
    map(action => {
      return updateSingleCuration(action.data)
    })
  )
}

const loadClusterviewEpic = (action, key) =>
  of(
    loadClusterview({
      sortingId: key,
      data: { data: action }
    })
  )

const splitSpikesErrorEpic = msg =>
  throwError(() => msg).pipe(
    catchError(error => {
      return of({
        type: 'ADD_NOTIFICATION',
        payload: {
          title: error,
          type: NOTIFICATION.ERROR
        }
      })
    })
  )
