import React from 'react'
import { makeStyles, CircularProgress, Grid } from '@material-ui/core'

const useStyles = makeStyles({
  spinnerWrapper: {
    height: 100
  }
})

export const LoadingSpinner = ({}) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.spinnerWrapper}
    >
      <CircularProgress size={15} />
    </Grid>
  )
}
