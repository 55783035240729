import { startTour } from '../../../actions/tours'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { storeCurrentUser } from '../../../actions/login'

function UseAppHeaderTour() {
  const dispatch = useDispatch()
  const location = useLocation()
  const pathname = location.pathname.split('/')[1]
  const isRecordingPage = pathname === 'recording'
  const currentUser = useSelector(state => state.login?.currentUser) || {}
  const onboardings = currentUser?.user?.onboardings || []
  const storeUserInfo = useCallback(
    user => dispatch(storeCurrentUser(user)),
    [dispatch]
  )
  const recordingTours = ['recording', 'curation_AI']

  const filtredOnboardings = onboardings.filter(
    ({ step }) => !recordingTours.includes(step)
  )

  const resetRecordingTours = useCallback(() => {
    storeUserInfo({
      ...currentUser,
      user: {
        ...currentUser.user,
        onboardings: [
          ...filtredOnboardings,
          ...recordingTours.map(tour => ({ step: tour, status: false }))
        ]
      }
    })
  }, [currentUser, filtredOnboardings, storeUserInfo])

  const handleStartTour = useCallback(() => {
    if (isRecordingPage) return resetRecordingTours()
    dispatch(startTour())
  }, [dispatch, isRecordingPage, resetRecordingTours])

  return { handleStartTour }
}

export default UseAppHeaderTour
