import React from 'react'

export const svg128_6 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        width="60"
        height="1009"
        viewBox="0 0 60 1009"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0H60V15.1529V15.1533L54 62.8859H6L0 15.1533V15.1529V0ZM6 62.8865H54L53 535.743V1009L6 984.415V535.743V62.8865Z"
          fill={color}
        />
        <path
          d="M8 219C8 217.343 9.34315 216 11 216H23C24.6569 216 26 217.343 26 219V230.077C26 231.734 24.6569 233.077 23 233.077H11C9.34315 233.077 8 231.734 8 230.077V219Z"
          fill="white"
        />
        <path
          d="M8 244.077C8 242.42 9.34315 241.077 11 241.077H23C24.6569 241.077 26 242.42 26 244.077V255.154C26 256.811 24.6569 258.154 23 258.154H11C9.34315 258.154 8 256.811 8 255.154V244.077Z"
          fill="white"
        />
        <path
          d="M8 269.154C8 267.497 9.34315 266.154 11 266.154H23C24.6569 266.154 26 267.497 26 269.154V280.231C26 281.888 24.6569 283.231 23 283.231H11C9.34315 283.231 8 281.888 8 280.231V269.154Z"
          fill="white"
        />
        <path
          d="M8 294.231C8 292.574 9.34315 291.231 11 291.231H23C24.6569 291.231 26 292.574 26 294.231V305.308C26 306.965 24.6569 308.308 23 308.308H11C9.34315 308.308 8 306.965 8 305.308V294.231Z"
          fill="white"
        />
        <path
          d="M8 319.308C8 317.651 9.34315 316.308 11 316.308H23C24.6569 316.308 26 317.651 26 319.308V330.385C26 332.041 24.6569 333.385 23 333.385H11C9.34315 333.385 8 332.041 8 330.385V319.308Z"
          fill="white"
        />
        <path
          d="M8 344.385C8 342.728 9.34315 341.385 11 341.385H23C24.6569 341.385 26 342.728 26 344.385V355.462C26 357.119 24.6569 358.462 23 358.462H11C9.34315 358.462 8 357.119 8 355.462V344.385Z"
          fill="white"
        />
        <path
          d="M8 369.461C8 367.805 9.34315 366.461 11 366.461H23C24.6569 366.461 26 367.805 26 369.461V380.538C26 382.195 24.6569 383.538 23 383.538H11C9.34315 383.538 8 382.195 8 380.538V369.461Z"
          fill="white"
        />
        <path
          d="M8 394.539C8 392.882 9.34315 391.539 11 391.539H23C24.6569 391.539 26 392.882 26 394.539V405.615C26 407.272 24.6569 408.615 23 408.615H11C9.34315 408.615 8 407.272 8 405.615V394.539Z"
          fill="white"
        />
        <path
          d="M8 419.615C8 417.958 9.34315 416.615 11 416.615H23C24.6569 416.615 26 417.958 26 419.615V430.692C26 432.349 24.6569 433.692 23 433.692H11C9.34315 433.692 8 432.349 8 430.692V419.615Z"
          fill="white"
        />
        <path
          d="M8 444.692C8 443.036 9.34315 441.692 11 441.692H23C24.6569 441.692 26 443.036 26 444.692V455.769C26 457.426 24.6569 458.769 23 458.769H11C9.34315 458.769 8 457.426 8 455.769V444.692Z"
          fill="white"
        />
        <path
          d="M8 469.769C8 468.112 9.34315 466.769 11 466.769H23C24.6569 466.769 26 468.112 26 469.769V480.846C26 482.503 24.6569 483.846 23 483.846H11C9.34315 483.846 8 482.503 8 480.846V469.769Z"
          fill="white"
        />
        <path
          d="M8 494.846C8 493.189 9.34315 491.846 11 491.846H23C24.6569 491.846 26 493.189 26 494.846V505.923C26 507.58 24.6569 508.923 23 508.923H11C9.34315 508.923 8 507.58 8 505.923V494.846Z"
          fill="white"
        />
        <path
          d="M8 519.923C8 518.266 9.34315 516.923 11 516.923H23C24.6569 516.923 26 518.266 26 519.923V531C26 532.657 24.6569 534 23 534H11C9.34315 534 8 532.657 8 531V519.923Z"
          fill="white"
        />
        <path
          d="M8 545C8 543.343 9.34315 542 11 542H23C24.6569 542 26 543.343 26 545V556.077C26 557.734 24.6569 559.077 23 559.077H11C9.34315 559.077 8 557.734 8 556.077V545Z"
          fill="white"
        />
        <path
          d="M8 570.077C8 568.42 9.34315 567.077 11 567.077H23C24.6569 567.077 26 568.42 26 570.077V581.154C26 582.81 24.6569 584.154 23 584.154H11C9.34315 584.154 8 582.81 8 581.154V570.077Z"
          fill="white"
        />
        <path
          d="M8 595.154C8 593.497 9.34315 592.154 11 592.154H23C24.6569 592.154 26 593.497 26 595.154V606.231C26 607.888 24.6569 609.231 23 609.231H11C9.34315 609.231 8 607.888 8 606.231V595.154Z"
          fill="white"
        />
        <path
          d="M8 620.23C8 618.574 9.34315 617.23 11 617.23H23C24.6569 617.23 26 618.574 26 620.23V631.307C26 632.964 24.6569 634.307 23 634.307H11C9.34315 634.307 8 632.964 8 631.307V620.23Z"
          fill="white"
        />
        <path
          d="M8 645.308C8 643.651 9.34315 642.308 11 642.308H23C24.6569 642.308 26 643.651 26 645.308V656.385C26 658.041 24.6569 659.385 23 659.385H11C9.34315 659.385 8 658.041 8 656.385V645.308Z"
          fill="white"
        />
        <path
          d="M8 670.384C8 668.727 9.34315 667.384 11 667.384H23C24.6569 667.384 26 668.727 26 670.384V681.461C26 683.118 24.6569 684.461 23 684.461H11C9.34315 684.461 8 683.118 8 681.461V670.384Z"
          fill="white"
        />
        <path
          d="M8 695.461C8 693.805 9.34315 692.461 11 692.461H23C24.6569 692.461 26 693.805 26 695.461V706.538C26 708.195 24.6569 709.538 23 709.538H11C9.34315 709.538 8 708.195 8 706.538V695.461Z"
          fill="white"
        />
        <path
          d="M8 720.538C8 718.881 9.34315 717.538 11 717.538H23C24.6569 717.538 26 718.881 26 720.538V731.615C26 733.272 24.6569 734.615 23 734.615H11C9.34315 734.615 8 733.272 8 731.615V720.538Z"
          fill="white"
        />
        <path
          d="M8 745.615C8 743.958 9.34315 742.615 11 742.615H23C24.6569 742.615 26 743.958 26 745.615V756.692C26 758.349 24.6569 759.692 23 759.692H11C9.34315 759.692 8 758.349 8 756.692V745.615Z"
          fill="white"
        />
        <path
          d="M8 770.692C8 769.035 9.34315 767.692 11 767.692H23C24.6569 767.692 26 769.035 26 770.692V781.769C26 783.426 24.6569 784.769 23 784.769H11C9.34315 784.769 8 783.426 8 781.769V770.692Z"
          fill="white"
        />
        <path
          d="M8 795.769C8 794.112 9.34315 792.769 11 792.769H23C24.6569 792.769 26 794.112 26 795.769V806.846C26 808.503 24.6569 809.846 23 809.846H11C9.34315 809.846 8 808.503 8 806.846V795.769Z"
          fill="white"
        />
        <path
          d="M8 820.846C8 819.189 9.34315 817.846 11 817.846H23C24.6569 817.846 26 819.189 26 820.846V831.923C26 833.579 24.6569 834.923 23 834.923H11C9.34315 834.923 8 833.579 8 831.923V820.846Z"
          fill="white"
        />
        <path
          d="M8 845.923C8 844.266 9.34315 842.923 11 842.923H23C24.6569 842.923 26 844.266 26 845.923V857C26 858.657 24.6569 860 23 860H11C9.34315 860 8 858.657 8 857V845.923Z"
          fill="white"
        />
        <path
          d="M8 871C8 869.343 9.34315 868 11 868H23C24.6569 868 26 869.343 26 871V882.076C26 883.733 24.6569 885.076 23 885.076H11C9.34315 885.076 8 883.733 8 882.076V871Z"
          fill="white"
        />
        <path
          d="M8 896.077C8 894.42 9.34315 893.077 11 893.077H23C24.6569 893.077 26 894.42 26 896.077V907.154C26 908.81 24.6569 910.154 23 910.154H11C9.34315 910.154 8 908.81 8 907.154V896.077Z"
          fill="white"
        />
        <path
          d="M8 921.153C8 919.496 9.34315 918.153 11 918.153H23C24.6569 918.153 26 919.496 26 921.153V932.23C26 933.887 24.6569 935.23 23 935.23H11C9.34315 935.23 8 933.887 8 932.23V921.153Z"
          fill="white"
        />
        <path
          d="M8 946.23C8 944.574 9.34315 943.23 11 943.23H23C24.6569 943.23 26 944.574 26 946.23V957.307C26 958.964 24.6569 960.307 23 960.307H11C9.34315 960.307 8 958.964 8 957.307V946.23Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="60"
      height="1009"
      viewBox="0 0 60 1009"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 0H60V15.1529V15.1533L54 62.8859H6L0 15.1533V15.1529V0ZM6 62.8865H54L53 535.743V1009L6 984.415V535.743V62.8865Z"
        fill={color}
      />
    </svg>
  )
}
