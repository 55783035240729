import React, { memo } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { FILE_STATUS } from '../../../../utils/consts'

const useStyles = makeStyles(theme => ({
  status: ({ status, minWidth }) =>
    theme.status({
      background: theme.notifications[status],
      color: theme.palette.colors.status[status],
      minWidth
    })
}))

const FileStatus = ({ status, statusId, index, minWidth }) => {
  const classes = useStyles({ status: FILE_STATUS[statusId], minWidth })
  return (
    <Typography
      className={classes.status}
      style={index === 0 ? { marginLeft: 0 } : {}}
      noWrap
    >
      {status || '-'}
    </Typography>
  )
}

export default memo(FileStatus)
