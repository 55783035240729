import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CustomInput from '../../../../../FormComponents/CustomInput/CustomInput'
import CustomButton from '../../../../../CustomButton'
import usePasswordType from '../../../../hooks/usePasswordType'
import useNewPasswordForm from './hooks/useNewPasswordForm'
import { Formik } from 'formik'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: 382
  },
  title: {
    fontSize: 24,
    lineHeight: '36px',
    fontWeight: 600,
    color: theme.palette.colors.mainColor
  },
  subtitle: {
    marginTop: 12,
    marginBottom: 50,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: theme.palette.colors.neutral[4]
  },
  saveButton: {
    marginTop: 24
  },
  strongText: {
    color: theme.palette.secondary[100],
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '18px',
    textAlign: 'left',
    marginTop: 8
  },
  formInput: {
    marginTop: 24
  },
  formInputError: {
    marginBottom: 50
  }
}))

const NewPassword = () => {
  const theme = useTheme()
  const classes = useStyles()
  const { passwordType, IconType } = usePasswordType()
  const {
    initialValues,
    validation,
    onSubmit,
    isPasswordStrong,
    handlePasswordChange
  } = useNewPasswordForm()
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Create a new password</Typography>
      <Typography className={classes.subtitle}>
        Did you know that a cybersecurity attack occurs every 39 seconds? Create
        a strong password using different symbols and numbers.
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={values => onSubmit(values)}
      >
        {({
          isSubmitting,
          values: { password, confirmPassword },
          handleChange,
          errors,
          handleSubmit,
          touched,
          handleBlur
        }) => {
          const handleKeyPress = e => {
            if (e.key.toLowerCase() === 'enter') {
              handleSubmit()
            }
          }
          return (
            <form>
              <CustomInput
                label="New password"
                name="password"
                type={passwordType}
                value={password}
                withBottomMargin={false}
                onChange={e => {
                  handlePasswordChange(e)
                  handleChange(e)
                }}
                error={errors}
                required
                fullWidth
                variant="outlined"
                endAdornment={IconType}
                touched={touched}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                className={cn({
                  [classes.formInputError]:
                    errors['password'] && touched['password']
                })}
              />
              {isPasswordStrong && (
                <Typography className={classes.strongText}>Strong</Typography>
              )}

              <CustomInput
                label="Confirm your password"
                name="confirmPassword"
                type={passwordType}
                value={confirmPassword}
                onChange={handleChange}
                error={errors}
                required
                fullWidth
                variant="outlined"
                endAdornment={IconType}
                touched={touched}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                className={classes.formInput}
              />
              <CustomButton
                label="Save password"
                loading={isSubmitting}
                onClick={handleSubmit}
                loadingColor={theme.palette.colors.white}
                className={classes.saveButton}
              />
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default NewPassword
