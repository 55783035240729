import React, { memo, useCallback } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { MAIN_APPBAR_HEIGHT } from '../../../utils/styles'
import IconButton from '@material-ui/core/IconButton'
import { CloseRounded, VerticalAlignBottom, Airplay } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import Drawer from '@material-ui/core/Drawer'
import cn from 'classnames'
import useGetBiochipsData from './components/MappingTab/hooks/useGetBiochipsData'
import useLeftPanelController from './hooks/useLeftPanelController'
import { useDispatch, useSelector } from 'react-redux'
import { resetData } from './redux/actions'
import Button from '@material-ui/core/Button'
import CustomTabs from '../../CustomTabs'
import useSaveDisabledChannels from './hooks/useSaveDisabledChannels'

import MappingIcon from '../../../imgs/RasterSection/MappingIcon'
import ChannelIcon from '../../../imgs/RasterSection/ChannelIcon'
import EventIcon from '../../../imgs/RasterSection/EventIcon'
import MultiScreenTab from './components/MultiScreenTab'
import { propsAreEqual } from '../../../utils/utils'
import useLimitedAccess from '../../../hooks/useLimitedAccess'

const FiltersTab = React.lazy(() => import('./components/FiltersTab'))
const MappingTab = React.lazy(() => import('./components/MappingTab'))
const EventsTab = React.lazy(() => import('./components/EventsTab'))

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    alignItems: 'flex-start',
    padding: 15,
    flexDirection: 'column',
    background: theme.palette.colors.backgrounds.pannel,
    overflow: 'hidden'
  },
  drawerOpened: {
    opacity: 1
  },

  drawer: ({ leftPanelWidth }) => ({
    width: leftPanelWidth,
    flexShrink: 0,
    transition: 'width 0.1s ease-in-out'
  }),
  drawerPaper: ({ leftPanelWidth, fullScreenMode }) => {
    const offset = fullScreenMode ? 0 : MAIN_APPBAR_HEIGHT
    return {
      width: leftPanelWidth,
      top: offset,
      border: 'none',
      height: `calc(100vh - ${offset}px)`,
      transition: 'width 0.1s ease-in-out'
    }
  },
  collapsed: () => ({
    position: 'relative',
    padding: '10px 0',
    width: 72,
    minWidth: 72,
    background: theme.palette.colors.customColor(
      theme.palette.colors.neutral.bg,
      theme.palette.colors.secongBG
    )
  }),
  showBtn: () => {
    const color = theme.palette.colors.customColor(
      theme.palette.colors.neutral['5'],
      theme.palette.colors.neutral['9']
    )
    return {
      padding: 5,
      marginBottom: 5,
      color,
      '&:hover': {
        '& svg': {
          color
        }
      }
    }
  },
  showIcon: {
    fontSize: 24,
    transform: 'rotate(-90deg)'
  },
  selectedTab: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  probeType: {
    fontSize: 23,
    fontWeight: 600,
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    transform: 'rotate(-90deg)',
    color: theme.palette.colors.neutral.black
  },
  labelShowBtn: {
    flexWrap: 'wrap'
  },

  // toggleDrawer
  toggleDrawer: {
    marginBottom: 5
  },
  btnLabel: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px'
  },
  toggleLeftPanelBtn: () => {
    const color = theme.palette.colors.customColor(
      theme.palette.colors.neutral['4'],
      theme.palette.colors.neutral['7']
    )
    return {
      textTransform: 'initial',
      color,
      '& svg': {
        color: theme.palette.colors.neutral['5'],
        fontSize: 24,
        transform: 'rotate(90deg)',
        marginRight: 12
      },
      '&:hover': {
        color
      }
    }
  },

  removeFilterBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: theme.palette.colors.status.error,
    textTransform: 'initial',
    '&:hover': {
      color: theme.palette.colors.status.error
    }
  },
  removeFilterLabel: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px'
  },
  removeFilterIcon: {
    fontSize: 20,
    marginRight: 8
  }
}))

export class LeftPanelMaxWidth {
  static maxWidth = 0

  static update(newWidth) {
    this.maxWidth = newWidth
  }
}

export const useLeftPanelTabs = ({
  handleSetMaxWidth,
  loading,
  probeFileName,
  reducer,
  selectedTab
}) => {
  const theme = useTheme()
  const biochipData = useGetBiochipsData(reducer)
  useSaveDisabledChannels()
  const { isLimited } = useLimitedAccess()

  if (loading) return []

  const defaultIconStyle = { fill: theme.palette.colors.neutral[5] }
  const selectedIconStyle = { fill: theme.palette.primary.main }
  const tabOffset = Number(!biochipData.showMaping)

  const mapping = {
    icon: (
      <MappingIcon
        style={selectedTab === 0 ? selectedIconStyle : defaultIconStyle}
      />
    ),
    label: 'Mapping',
    content: <MappingTab {...biochipData} probeFileName={probeFileName} />
  }

  const channels = {
    icon: (
      <ChannelIcon
        style={
          selectedTab === 1 - tabOffset ? selectedIconStyle : defaultIconStyle
        }
      />
    ),
    label: 'Channels',
    onClick: () => handleSetMaxWidth(290),
    content: <FiltersTab {...biochipData} probeFileName={probeFileName} />
  }

  const events = {
    icon: (
      <EventIcon
        style={
          selectedTab === 2 - tabOffset ? selectedIconStyle : defaultIconStyle
        }
      />
    ),
    label: 'Events',
    onClick: () => handleSetMaxWidth(290),
    content: <EventsTab />
  }
  const multiScreen = {
    icon: (
      <Airplay
        style={{
          ...(selectedTab === 3 - tabOffset
            ? selectedIconStyle
            : defaultIconStyle),
          height: 16
        }}
      />
    ),
    label: 'MultiScreen',
    onClick: () => handleSetMaxWidth(290),
    content: <MultiScreenTab />
  }

  let tabs = [channels, events]
  if (biochipData.showMaping) tabs = [mapping, ...tabs]
  if (!isLimited) tabs = [...tabs, multiScreen]

  return tabs
}

const LeftPanel = ({
  leftPanelMode,
  toggleLeftPanelMode,
  loading,
  Loader,
  probeFileName
}) => {
  const {
    selectedTab,
    leftPanelWidth,
    handleChangeTabs,
    handleSetMaxWidth,
    toggleDrawer
  } = useLeftPanelController({ toggleLeftPanelMode })
  const fullScreenMode = useSelector(
    state => state.timeSeriesLeftPanel.fullScreenMode
  )
  const classes = useStyles({ leftPanelWidth, fullScreenMode })

  const tabs = useLeftPanelTabs({
    handleSetMaxWidth,
    loading,
    probeFileName,
    selectedTab
  })

  return (
    <React.Fragment>
      <CollapsedDrawer
        probeFileName={probeFileName}
        toggleDrawer={toggleDrawer}
        classes={classes}
      />

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={leftPanelMode}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Grid
          container
          className={cn(classes.root, {
            [classes.drawerOpened]: leftPanelMode
          })}
        >
          <ToggleDrawer toggle={toggleDrawer} selectedTab={selectedTab} />
          {loading ? (
            <Loader />
          ) : (
            <CustomTabs
              tabs={tabs}
              selectedTab={selectedTab}
              handleChangeTabs={handleChangeTabs}
            />
          )}
        </Grid>
      </Drawer>
    </React.Fragment>
  )
}

const CollapsedDrawer = memo(({ toggleDrawer, probeFileName, classes }) => {
  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.collapsed}
    >
      <IconButton onClick={toggleDrawer} className={classes.showBtn}>
        <VerticalAlignBottom className={classes.showIcon} />
      </IconButton>

      <div className={classes.selectedTab}>
        <Typography className={classes.probeType}>
          Probe Type: {probeFileName}
        </Typography>
      </div>
    </Grid>
  )
})

const ToggleDrawer = memo(
  ({ toggle, selectedTab }) => {
    const classes = useStyles()

    const dispatch = useDispatch()

    const reset = useCallback(
      options => {
        dispatch(resetData(options))
      },
      [dispatch]
    )

    return (
      <Grid
        className={classes.toggleDrawer}
        container
        wrap="nowrap"
        alignItems="center"
        justify="space-between"
      >
        <Button className={classes.toggleLeftPanelBtn} onClick={toggle}>
          <VerticalAlignBottom />
          <Typography className={classes.btnLabel}>Hide</Typography>
        </Button>
        {selectedTab === 'filter' && (
          <Button className={classes.removeFilterBtn} onClick={reset}>
            <CloseRounded className={classes.removeFilterIcon} />
            <Typography className={classes.btnLabel}>
              Reset to Default
            </Typography>
          </Button>
        )}
      </Grid>
    )
  },
  (...props) => propsAreEqual(...props, ['toggle', 'selectedTab'])
)

export default memo(LeftPanel)
