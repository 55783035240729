export const ADD_PROBES = 'ADD_PROBES'
export const LOADING_PROBES = 'LOADING_PROBES'
export const SET_CURRENT_PROBE = 'SET_CURRENT_PROBE'
export const SET_PROBE_OPTIONS = 'SET_PROBE_OPTIONS'

export const addProbes = data => ({
  type: ADD_PROBES,
  payload: data
})

export const loadingProbes = () => ({ type: LOADING_PROBES })

export const setCurrentProbe = id => ({ type: SET_CURRENT_PROBE, payload: id })

export const setProbeOptions = options => ({
  type: SET_PROBE_OPTIONS,
  payload: options
})
