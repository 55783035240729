import { MODALS_ID, NOTIFICATION } from '../../../utils/consts'
import useToggleModal from '../../../hooks/useToggleModal'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { deleteModel } from '../../../actions/aiModels'
import { deleteAIModel } from '../../../endpoints/admin'
import { last } from 'lodash/fp'

function useDelete() {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const addNotification = useNotification()

  const modalID = MODALS_ID.DELETE_AI_MODAL
  const modalState = useSelector(state => state.modals?.get(modalID))
  const selected = modalState?.args || {}
  const { ids: selectedIds } = selected || {}
  const selectedList = Object.entries(selected)
  const isBulk = selectedList?.length > 1
  const name = !isBulk && selectedList[0] && selectedList[0][1]?.name

  const { isOpened: isDeleteModalOpen, toggle: toggleDeleteModal } =
    useToggleModal(modalID)

  const updateFile = useCallback(
    data => dispatch(deleteModel(data)),
    [dispatch]
  )

  const onDelete = useCallback(
    async ({ onSuccess }) => {
      try {
        setLoading(true)
        const ids = selectedIds ?? selectedList.map(record => last(record)?.id)
        const res = await deleteAIModel({ ids })
        if (res.status === 200) {
          updateFile({ ids })
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Model successfully deleted'
          })
        }
        if (onSuccess) onSuccess()
        setLoading(false)
      } catch (e) {
        setLoading(false)
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [selectedIds, selectedList, updateFile]
  )

  return {
    name,
    isBulk,
    loading,
    onDelete,
    isDeleteModalOpen,
    toggleDeleteModal
  }
}

export default useDelete
