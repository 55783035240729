import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    color: '#071740',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.colors.customColor(
      'rgba(7, 23, 64, 0.1)',
      theme.palette.colors.white
    )
  }
}))

const HoveredChild = ({ selected }) => {
  const classes = useStyles()

  return (
    <div data-id={selected} className={classes.root}>
      Widget Here
    </div>
  )
}

export default HoveredChild
