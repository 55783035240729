import React from 'react'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Edit } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 19,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  },
  actionBtn: {
    padding: 5,
    marginLeft: 20,
    '& svg': {
      color: theme.palette.colors.neutral['5']
    }
  },
  header: {
    marginBottom: 22
  }
}))

const InfoPanelItem = ({
  title,
  children,
  Icon = <Edit />,
  onClick = null,
  justify = 'space-between'
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid
        container
        justify={justify}
        alignItems="center"
        className={classes.header}
      >
        <Typography className={classes.title}>{title}</Typography>
        {onClick && (
          <IconButton onClick={onClick} className={classes.actionBtn}>
            {Icon}
          </IconButton>
        )}
      </Grid>
      {children}
    </>
  )
}

export default InfoPanelItem
