import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Drawer, Grid } from '@material-ui/core'
import cn from 'classnames'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiBackdrop-root': {
      background: theme.palette.colors.backdrop
    }
  },
  paper: ({
    maxWidth,
    background = theme.palette.colors.backgrounds.pannel
  }) => ({
    maxWidth: maxWidth || 350,
    width: '100%',
    background
  }),
  modalRoot: {
    height: '100%',
    minHeight: 'auto',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    position: 'relative',
    flexDirection: 'column',
    padding: '40px 24px 15px',
    justifyContent: 'flex-start'
  },
  title: {
    fontSize: 23,
    fontWeight: 600,
    marginBottom: 45,
    lineHeight: '24px',
    color: theme.palette.colors.neutral.black
  }
}))

function CustomDrawer(props) {
  const {
    onClose = () => null,
    open,
    maxWidth,
    classNames,
    title = null,
    anchor = 'right',
    customPaperClass,
    children
  } = props

  const classes = useStyles({ maxWidth })

  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      classes={{
        root: classes.root,
        paper: cn(customPaperClass, classes.paper)
      }}
    >
      <Grid container className={cn(classNames, classes.modalRoot)}>
        {title && <Typography className={classes.title}>{title}</Typography>}
        {children}
      </Grid>
    </Drawer>
  )
}

export default CustomDrawer
