import { memo } from 'react'

function MapData(props) {
  const { data, children } = props

  if (!data) {
    return null
  }

  if (Object.prototype.toString.call(data) === '[object Array]') {
    return data.map((item, index) => children({ item, index }))
  }

  if (Object.prototype.toString.call(data) === '[object Object]') {
    return (
      Object.entries(data).map <
      Object >
      (([k, v], index) => children({ item: [k, v], index: index }))
    )
  }

  return null
}

export default memo(MapData)
