import { useDispatch } from 'react-redux'
import { useSafeDispatch } from '../../../hooks/useSafeDispatch'
import { useCallback } from 'react'
import { addNotification } from '../../../actions/notifications'

function useNotification() {
  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)

  return useCallback(
    config => {
      safeDispatch(addNotification(config))
    },
    [safeDispatch]
  )
}

export default useNotification
