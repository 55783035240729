import React from 'react'
import { fade } from '@material-ui/core'

export const svg64_1 = ({ color, svgRef, flipped, preview = false }) => {
  if (preview) {
    return (
      <svg
        width="84"
        height="400"
        viewBox="0 0 84 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H83V34.9107H83.0468L70.1716 324.394H12.8752L0.038208 35.7697H0V34.9107V0ZM12.8754 324.395L41.5236 400L70.1719 324.395H12.8754Z"
          fill={color}
        />
        <path
          d="M7 88C7 86.3431 8.34315 85 10 85H22C23.6569 85 25 86.3431 25 88V99.0769C25 100.734 23.6569 102.077 22 102.077H10C8.34315 102.077 7 100.734 7 99.0769V88Z"
          fill="white"
        />
        <path
          d="M10 121.077C10 119.42 11.3431 118.077 13 118.077H25C26.6569 118.077 28 119.42 28 121.077V132.154C28 133.811 26.6569 135.154 25 135.154H13C11.3431 135.154 10 133.811 10 132.154V121.077Z"
          fill="white"
        />
        <path
          d="M13 154.154C13 152.497 14.3431 151.154 16 151.154H28C29.6569 151.154 31 152.497 31 154.154V165.231C31 166.888 29.6569 168.231 28 168.231H16C14.3431 168.231 13 166.888 13 165.231V154.154Z"
          fill="white"
        />
        <path
          d="M15 187.231C15 185.574 16.3431 184.231 18 184.231H30C31.6569 184.231 33 185.574 33 187.231V198.308C33 199.964 31.6569 201.308 30 201.308H18C16.3431 201.308 15 199.964 15 198.308V187.231Z"
          fill="white"
        />
        <path
          d="M17 220.308C17 218.651 18.3431 217.308 20 217.308H32C33.6569 217.308 35 218.651 35 220.308V231.385C35 233.041 33.6569 234.385 32 234.385H20C18.3431 234.385 17 233.041 17 231.385V220.308Z"
          fill="white"
        />
        <path
          d="M19 253.385C19 251.728 20.3431 250.385 22 250.385H34C35.6569 250.385 37 251.728 37 253.385V264.461C37 266.118 35.6569 267.461 34 267.461H22C20.3431 267.461 19 266.118 19 264.461V253.385Z"
          fill="white"
        />
        <path
          d="M21 286.461C21 284.805 22.3431 283.461 24 283.461H36C37.6569 283.461 39 284.805 39 286.461V297.538C39 299.195 37.6569 300.538 36 300.538H24C22.3431 300.538 21 299.195 21 297.538V286.461Z"
          fill="white"
        />
        <path
          d="M23 319.538C23 317.881 24.3431 316.538 26 316.538H38C39.6569 316.538 41 317.881 41 319.538V330.615C41 332.272 39.6569 333.615 38 333.615H26C24.3431 333.615 23 332.272 23 330.615V319.538Z"
          fill="white"
        />
        <path
          d="M73 108C73 106.343 71.6569 105 70 105H58C56.3431 105 55 106.343 55 108V119.077C55 120.734 56.3431 122.077 58 122.077H70C71.6569 122.077 73 120.734 73 119.077V108Z"
          fill="white"
        />
        <path
          d="M70 141.077C70 139.42 68.6569 138.077 67 138.077H55C53.3431 138.077 52 139.42 52 141.077V152.154C52 153.811 53.3431 155.154 55 155.154H67C68.6569 155.154 70 153.811 70 152.154V141.077Z"
          fill="white"
        />
        <path
          d="M67 174.154C67 172.497 65.6569 171.154 64 171.154H52C50.3431 171.154 49 172.497 49 174.154V185.231C49 186.888 50.3431 188.231 52 188.231H64C65.6569 188.231 67 186.888 67 185.231V174.154Z"
          fill="white"
        />
        <path
          d="M65 207.231C65 205.574 63.6569 204.231 62 204.231H50C48.3431 204.231 47 205.574 47 207.231V218.308C47 219.964 48.3431 221.308 50 221.308H62C63.6569 221.308 65 219.964 65 218.308V207.231Z"
          fill="white"
        />
        <path
          d="M63 240.308C63 238.651 61.6569 237.308 60 237.308H48C46.3431 237.308 45 238.651 45 240.308V251.385C45 253.041 46.3431 254.385 48 254.385H60C61.6569 254.385 63 253.041 63 251.385V240.308Z"
          fill="white"
        />
        <path
          d="M61 273.385C61 271.728 59.6569 270.385 58 270.385H46C44.3431 270.385 43 271.728 43 273.385V284.461C43 286.118 44.3431 287.461 46 287.461H58C59.6569 287.461 61 286.118 61 284.461V273.385Z"
          fill="white"
        />
        <path
          d="M59 306.461C59 304.805 57.6569 303.461 56 303.461H44C42.3431 303.461 41 304.805 41 306.461V317.538C41 319.195 42.3431 320.538 44 320.538H56C57.6569 320.538 59 319.195 59 317.538V306.461Z"
          fill="white"
        />
        <path
          d="M57 339.538C57 337.881 55.6569 336.538 54 336.538H42C40.3431 336.538 39 337.881 39 339.538V350.615C39 352.272 40.3431 353.615 42 353.615H54C55.6569 353.615 57 352.272 57 350.615V339.538Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="84"
      height="400"
      viewBox="0 0 84 400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H83V34.9107H83.0468L70.1716 324.394H12.8752L0.038208 35.7697H0V34.9107V0ZM12.8754 324.395L41.5236 400L70.1719 324.395H12.8754Z"
        fill={flipped ? fade(color, 0.5) : color}
      />
    </svg>
  )
}
