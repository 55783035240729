import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Done } from '@material-ui/icons'
import { setChannelsRange } from '../../../../../redux/actions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CHANNELS_RANGES } from '../../../../../redux/reducer'
import { useParams } from 'react-router-dom'

function UseSettingsModal(props) {
  const { handleClose } = props || {}
  const dispatch = useDispatch()
  const { selectedRange, childWindows, numChannels } = useSelector(
    state => state.timeSeriesLeftPanel,
    shallowEqual
  )
  const [selectedItem, setSelectedItem] = useState(selectedRange)

  useEffect(() => {
    setSelectedItem(selectedRange)
  }, [selectedRange])

  const params = useParams()
  const recordingId = params.recordingId

  const handleCloseAllChildWindows = useCallback(async () => {
    if (!childWindows || childWindows?.size === 0) return

    for (let item of childWindows) {
      const [_, win] = item || []
      await setTimeout(() => win.close(), 500)
    }
  }, [childWindows, recordingId])

  const splitChannels = useCallback(() => {
    if (selectedRange !== selectedItem) handleCloseAllChildWindows()

    dispatch(setChannelsRange(selectedItem))
    handleClose()
  }, [
    dispatch,
    handleClose,
    handleCloseAllChildWindows,
    selectedItem,
    selectedRange
  ])

  const items = useMemo(() => {
    return CHANNELS_RANGES.filter(r => r <= numChannels).map(option => ({
      value: option,
      label: `${option} Channels`,
      onClick: () => setSelectedItem(option),
      endAdornment:
        selectedItem === option ? (
          <Done style={{ height: 24, color: '#21B784' }} />
        ) : null
    }))
  }, [selectedItem, numChannels])

  return {
    items,
    splitChannels,
    selectedItem,
    handleCloseAllChildWindows
  }
}

export default UseSettingsModal
