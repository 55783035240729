import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useRecordingInfo from '../../../../NewRecordingView/hooks/useRecordingInfo'
import useLimitedAccess from '../../../../../hooks/useLimitedAccess'
import { MODALS_ID } from '../../../../../utils/consts'
import useToggleModal from '../../../../../hooks/useToggleModal'

function useRunSpikeSortingPrompt() {
  const { pathname } = useLocation()
  const isRecording = pathname.includes('recording')
  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )
  const { isFileUploaded } = useRecordingInfo()
  const { isLimited } = useLimitedAccess()

  const modalID = MODALS_ID.NEW_SPIKESORTING_MODAL
  const { toggle: toggleSortingModal, isOpened } = useToggleModal(modalID)
  const loading = useSelector(state => state.recordingStore.loadingSortings)
  const show =
    !isLimited &&
    !selectedSorting &&
    isRecording &&
    !isOpened &&
    !loading &&
    isFileUploaded

  return { show, toggleSortingModal }
}

export default useRunSpikeSortingPrompt
