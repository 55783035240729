import { useCallback, useEffect, useState } from 'react'
import { crateFolder } from '../../../endpoints/admin'
import { MODALS_ID, NOTIFICATION } from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import { addModal, closeModal } from '../../../actions/modals'
import useFilesQueryParams from '../../FilesTable/hooks/useFilesQueryParams'

function useNewFolderModal() {
  const dispatch = useDispatch()
  const modalID = MODALS_ID.CREATE_NEW_FOLDER
  const modalState = useSelector(state => state.modals?.get(modalID))
  const {
    onSuccess = () => null,
    validateName = () => null,
    data = {}
  } = modalState?.args || {}
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const addNotification = useNotification()
  const { folder_id } = useFilesQueryParams()
  const toggleModal = useCallback(
    (args = {}) => {
      if (modalState) return dispatch(closeModal({ id: modalID }))
      dispatch(
        addModal({
          id: modalID,
          args
        })
      )
    },
    [dispatch, modalID, modalState]
  )

  useEffect(() => {
    return () => setName('')
  }, [modalState])

  const handleChange = useCallback(e => setName(e.target.value), [])

  const handleSubmit = useCallback(async () => {
    try {
      setLoading(true)
      const value = await validateName(name)
      const { status } = await crateFolder({
        name: value,
        parent_id: folder_id ? Number(folder_id) : null,
        ...data
      })
      if (status === 200) {
        await onSuccess()
        addNotification({
          type: NOTIFICATION.SUCCESS,
          title: 'Folder successfully created!'
        })
      }
      setLoading(false)
      toggleModal()
    } catch (e) {
      setLoading(false)
      toggleModal()
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [
    validateName,
    name,
    folder_id,
    data,
    toggleModal,
    onSuccess,
    addNotification
  ])

  const handleKeyPress = useCallback(
    e => {
      if (e.key?.toLowerCase() === 'enter' && name) {
        handleSubmit()
      }
    },
    [name, handleSubmit]
  )

  return {
    toggleModal,
    isOpened: Boolean(modalState),
    onChange: handleChange,
    onSubmit: handleSubmit,
    value: name,
    onKeyPress: handleKeyPress,
    loading
  }
}

export default useNewFolderModal
