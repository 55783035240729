import React, { memo, useCallback, useEffect } from 'react'
import ActionButton from '../../../../../ActionButton'
import CustomModal from '../../../../../CustomModal'
import { makeStyles } from '@material-ui/core/styles'
import CustomInput from '../../../../../FormComponents/CustomInput/CustomInput'
import SelectMenu from '../../../../../SelectMenu'
import useProbeFiles from '../../../../hooks/useProbeFiles'
import useEditData from '../../hooks/useEditData'
import { DTYPES } from '../../../../../../utils/consts'

const useStyles = makeStyles(theme => ({
  saveBtn: {
    backgroundColor: theme.palette.action.secondary
  },
  body: {
    minHeight: 400
  },
  inputsContainer: {
    margin: '24px 0'
  }
}))

const EditDataModal = ({ isLimited }) => {
  const classes = useStyles()
  const { toggleModal, isOpened, formik } = useEditData()

  const {
    isSubmitting,
    values,
    touched,
    handleChange,
    setFieldValue,
    handleSubmit,
    handleBlur,
    resetForm,
    errors
  } = formik || {}

  const { sample_rate, number_channels, prb_file, dtype } = values || {}

  const inputProps = {
    errors,
    touched,
    handleBlur,
    handleChange,
    required: true
  }

  useEffect(() => {
    if (isOpened) resetForm()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened])

  const Input = useCallback(
    ({ errors, handleBlur, handleChange, ...props }) => (
      <CustomInput
        fullWidth
        error={errors}
        onBlur={handleBlur}
        onChange={handleChange}
        {...props}
      />
    ),
    []
  )

  return (
    <form onSubmit={handleSubmit}>
      <CustomModal
        open={isOpened}
        title="Data"
        onClose={toggleModal}
        maxWidth={555}
        renderActionButton={
          <ActionButton
            label="Save"
            loading={isSubmitting}
            onClick={handleSubmit}
            className={classes.saveBtn}
            disabled={isLimited}
          />
        }
      >
        <div className={classes.body}>
          <Input
            label="Sample rate"
            name="sample_rate"
            value={sample_rate}
            {...inputProps}
          />

          <Input
            label="Channel Count"
            name="number_channels"
            value={number_channels}
            {...inputProps}
          />
          <SelectMenu
            onChangeValue={value => setFieldValue('prb_file', value)}
            asyncData={useProbeFiles}
            value={prb_file}
            name="prb_file"
            classes={{
              container: classes.inputsContainer
            }}
            textFieldProps={{
              variant: 'outlined',
              label: 'Probe Design'
            }}
          />
          <SelectMenu
            onChangeValue={val => setFieldValue('dtype', val)}
            items={DTYPES}
            value={dtype}
            classes={{
              container: classes.inputsContainer
            }}
            textFieldProps={{
              variant: 'outlined',
              label: 'Dtype'
            }}
          />
        </div>
      </CustomModal>
    </form>
  )
}

export default memo(EditDataModal)
