import React, { memo } from 'react'
import { TableRoot } from '../../../FilesTable/FilesTable'
import FilesPagination from '../../../FilesTable/components/FilesPagination/FilesPagination'
import { makeStyles } from '@material-ui/core'
import useExistingTable from './hooks/useExistingTable'
import useGetAIModels, { existingTableParams } from '../../hooks/useGetAIModels'
import { isEmpty } from 'lodash/fp'
import EmptyComponent from '../EmptyComponent'
import EditModal from '../EditModal'
import DeleteModal from '../DeleteModal'
import FilesSnackbar from '../../../FilesTable/components/FilesSnackbar'
import CustomDropdown from '../../../CustomDropdown'
import { AI_MODELS_FILTERS } from '../../../../utils/consts'
import { Done } from '@material-ui/icons'
import PageTabs from '../../../PageTabs/PageTabs'
import { tabs } from '../../hooks/tabs'
import SearchBar from '../../../Files/components/Header/components/SearchBar'
import InfoPanel from '../InfoPanel'
import useSearchQuery from '../../../../hooks/useSearchQuery'

const useStyles = makeStyles(theme => ({
  root: () => ({
    flex: 1,
    borderRadius: 4,
    margin: '24px 0px',
    overflow: 'hidden',
    position: 'relative',
    background: theme.palette.colors.backgrounds.contrast,
    border: `1px solid  ${theme.palette.colors.neutral[7]}`
  }),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filter: {
    height: 20,
    marginLeft: 24
  },
  popupButton: {
    height: 24,
    '& p': {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '24px',
      minWidth:"100px",
      color: theme.palette.colors.customColor(
        theme.palette.colors.neutral[4],
        theme.palette.colors.white
      )
    },
    '& svg': {
      fontSize: 34,
      color: theme.palette.colors.customColor(
        theme.palette.colors.neutral[4],
        theme.palette.colors.white
      )
    }
  },
  menuItem: {
    height: 40,
    padding: '8px 16px',
    '& p': {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '18px'
    }
  },
  done: {
    color: theme.palette.secondary[100]
  }
}))

const ExistingAiTable = () => {
  const classes = useStyles()
  const { loading, fetchRecords } = useGetAIModels()
  const {
    rows,
    columns,
    pages,
    selected,
    filter,
    onRowClick,
    isSelected,
    deselectAll,
    isBulkSelect,
    handleSelect,
    checkSelectDisabled,
    toggleDeleteModal,
    renderActionButton,
    isExistingSelected,
    handleExsistingSelect
  } = useExistingTable({ fetchRecords })
  const { insertSearchQuery } = useSearchQuery()

  const { ALL, AUTHOR, COMPANY } = AI_MODELS_FILTERS
  const filterLabels = {
    [ALL]: 'All models',
    [COMPANY]: 'Organisation models',
    [AUTHOR]: 'Published by me'
  }

  const filterOptions = Object.entries(filterLabels).map(([k, v]) => {
    return {
      label: v,
      endAdornment: filter === k ? <Done className={classes.done} /> : null,
      onClick: () => {
        insertSearchQuery([
          { key: 'filter', value: k },
          { key: existingTableParams.page, value: 1 }
        ])
      }
    }
  })

  const isRowsEmpty = !loading && isEmpty(rows)

  return (
    <>
      <div className={classes.header}>
        <PageTabs tabs={tabs} />
        <div className={classes.filtersContainer}>
          <SearchBar />
          <CustomDropdown
            _classes={classes}
            className={classes.filter}
            label={filterLabels[filter] || ''}
            items={filterOptions}
            width={275}
          />
        </div>
      </div>
      {isRowsEmpty ? (
        <EmptyComponent
          title={'There are no trained AI models yet'}
          message={'To create AI model go to Train new AI model tab'}
        />
      ) : (
        <div className={classes.root}>
          <TableRoot
            rows={rows}
            columns={columns}
            loading={loading}
            onRowClick={onRowClick}
            deselectAll={deselectAll}
            handleSelect={handleSelect}
            checkSelectDisabled={checkSelectDisabled}
            isSelected={isSelected}
            renderActionButton={renderActionButton}
            isUsingOldLogic={true}
            isExistingSelected={isExistingSelected}
            handleExsistingSelect={handleExsistingSelect}
          />
          <EditModal />
          <InfoPanel />
          <DeleteModal deselectAll={deselectAll} />
          <FilesPagination
            withPerPageSelector
            pages={pages}
            customQparams={existingTableParams}
          />
          {isBulkSelect && (
            <FilesSnackbar
              length={selected?.length}
              deselectAll={deselectAll}
              onDelete={() => toggleDeleteModal(selected)}
            />
          )}
        </div>
      )}
    </>
  )
}

export default memo(ExistingAiTable)
