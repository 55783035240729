import { combineReducers } from 'redux'
import serverConnection from './serverConnection'
import databaseConfig from './databaseConfig'
import recordings from './recordings'
import sortings from './sortings'
import sortingJobs from './sortingJobs'
import jobHandlers from './jobHandlers'
import persisting from './persisting'
import hitherJobs from './hitherJobs'
import documentInfo from './documentInfo'
import serverInfo from './serverInfo'
import extensionsConfig from '../extensions/reducers'
import darkMode from './darkMode'
import loginReducer from './loginReducer'
import virtualGridState from '../components/NewHome/components/VirtualGrid/redux/reducer'
import timeSeriesLeftPanel from '../components/TimeseriesView/NewLeftPanel/redux/reducer'
import {
  biochipData,
  dataManipulation
} from '../containers/NewTimeSeries/redux/reducers'
import notifications from './notifications'
import filesStore from './filesStore'
import recordingStore from './recordingStore'
import membersStore from './membersStore'
import toursStore from './toursReducer'
import modalsReducer from './modals'
import widgetsCache from '../components/RecordingViewWithCuration/components/WidgetsLayout/redux'
import experimentsInputStore from './experimentsInputStore'
import probesStore from './probes'
import aiModels from './aiModels'

export default combineReducers({
  databaseConfig,
  recordings,
  serverInfo,
  sortings,
  sortingJobs,
  jobHandlers,
  persisting,
  serverConnection,
  hitherJobs,
  extensionsConfig,
  documentInfo,
  darkMode,
  login: loginReducer,
  virtualGridState,
  timeSeriesLeftPanel,
  biochipData,
  notifications,
  filesStore,
  recordingStore,
  membersStore,
  toursStore,
  modals: modalsReducer,
  widgetsCache,
  dataManipulation,
  experimentsInputStore,
  probesStore,
  aiModels
})
