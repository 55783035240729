import React, { memo, useEffect } from 'react'
import cn from 'classnames'
import useNotification from '../../../../../../../SnackbarManager/hooks/useNotification'
import { NOTIFICATION } from '../../../../../../../../utils/consts'
import { removeNotification } from '../../../../../../../../actions/notifications'
import { useDispatch } from 'react-redux'

const ApproveTag = ({ classes, curationId, isApproved, showNotification }) => {
  const addNotification = useNotification()
  const dispatch = useDispatch()
  useEffect(() => {
    if (isApproved && showNotification)
      addNotification({
        persist: true,
        type: NOTIFICATION.WARNING,
        title: `This curation session is approved. You can’t make any changes to ${curationId} curation`
      })
    return () => {
      if (isApproved && showNotification) dispatch(removeNotification())
    }
  }, [curationId, isApproved, showNotification])

  return (
    <div
      className={cn(classes.buttonRoot, {
        [classes.approved]: isApproved
      })}
    >
      {isApproved ? 'Approved' : 'Edit Session'}
    </div>
  )
}

export default memo(ApproveTag)
