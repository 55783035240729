import { useCallback, useState } from 'react'
import { getAllProbeFiles, getProbeOptions } from '../../../endpoints/admin'
import { useDispatch } from 'react-redux'
import { setProbeOptions } from '../../../actions/probes'
import { useLocation } from 'react-router-dom'
import { ROUTES } from '../../../utils/consts'

function useProbeFiles() {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    loading: false,
    data: null,
    subData: null,
    error: null
  })
  const { pathname } = useLocation()
  const isProbeScreen = pathname === ROUTES.PROBE_INVENTORY

  const startFetch = useCallback(async () => {
    try {
      setState({ ...state, loading: true })
      const query = isProbeScreen ? getAllProbeFiles : getProbeOptions
      const result = await query()
      let data = isProbeScreen ? result : null
      let subData = null
      if (result?.data && !isProbeScreen) {
        const probes = Object.values(result?.data)
        const probeOptions = probes.reduce(
          (prevVal, { probe_file, probe_id }, index) => {
            return prevVal.set(index, { probe_id, probe_file })
          },
          new Map()
        )
        dispatch(setProbeOptions(probeOptions))
        data = probes.map(({ probe_file }) => probe_file)
        subData = probes.map(({ serial }) => serial)
      }

      setState({ ...state, loading: false, data, subData })
    } catch (err) {
      setState({ ...state, loading: false, data: err })
    }
  }, [state, dispatch, isProbeScreen])

  return { state, startFetch }
}

export default useProbeFiles
