import React from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import ErrorPageLayout from '../ErrorPageLayout'

const useStyles = makeStyles(theme => ({
  actions: {
    marginTop: 40
  },
  button: {
    fontWeight: 600,
    textTransform: 'none',
    marginRight: 20,
    color: theme.palette.colors.white,
    border: `2px ${theme.palette.primary} solid`,
    borderRadius: 8
  }
}))

function NotFoundPage() {
  const classes = useStyles()
  const history = useHistory()

  const toFiles = () => history.push('/files')

  return (
    <ErrorPageLayout
      title="Sorry..."
      subTitle="We couldn’t find this page"
      content={
        <>
          It might have been removed, renamed, <br />
          or did not exist in first place.
        </>
      }
    >
      <Box className={classes.actions}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
          onClick={toFiles}
        >
          Go to files
        </Button>
      </Box>
    </ErrorPageLayout>
  )
}

export default NotFoundPage
