import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import CustomModal from '../../../../../../../CustomModal'
import ActionButton from '../../../../../../../ActionButton'
import { alpha } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 20,
    lineHeight: '36px',
    fontWeight: 600,
    height: 68,
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[90],
      'white'
    )
  },
  body: {
    height: 111
  },
  message: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[100],
      theme.palette.colors.neutral[8]
    )
  },
  curationId: {
    fontWeight: 600,
    display: 'inline'
  },
  actionButton: {
    backgroundColor: '#34D182',
    '&:hover': {
      backgroundColor: alpha('#34D182', 0.8)
    }
  }
}))

const ApproveConfirmationModal = ({
  loading,
  isOpened,
  toggleModal,
  onApprove,
  curationId
}) => {
  const classes = useStyles()
  return (
    <CustomModal
      maxWidth={603}
      headerHeight={68}
      open={isOpened}
      classes={classes}
      onClose={toggleModal}
      title={'Approve Curation'}
      customBodyClass={classes.body}
      renderActionButton={
        <ActionButton
          loading={loading}
          label="Approve"
          className={classes.actionButton}
          onClick={() => {
            onApprove()
            toggleModal()
          }}
        />
      }
    >
      <Typography className={classes.message}>
        Are you sure you would like to approve{' '}
        <Typography className={classes.curationId}>{curationId}</Typography>{' '}
        session?
      </Typography>
    </CustomModal>
  )
}

export default ApproveConfirmationModal
