import { useState } from 'react'
import useToggleModal from '../../../../../hooks/useToggleModal'

export const useSelectPositionModal = () => {
  const { isOpened, onClose, onOpen, modalState } = useToggleModal(
    'SelectPositionModal'
  )

  const [mouseClickedElement, setMouseClickedElement] = useState()

  return {
    isOpened,
    onClose,
    onOpen,
    modalState,
    mouseClickedElement,
    setMouseClickedElement
  }
}
