const WIDTH = window.innerWidth / 2
const HEIGHT = window.innerHeight / 2

export const _coordXRange = [-1, 1]
export const _coordYRange = [-1, 1]

export const coordYRangee = () => {
  const W = WIDTH
  const H = HEIGHT
  const xSpan = _coordXRange[1] - _coordXRange[0]
  const ySpan = _coordYRange[1] - _coordYRange[0]

  let newYSpan = ySpan
  if (W * ySpan < H * xSpan) {
    newYSpan = (H * xSpan) / W
  }

  const mid = (_coordYRange[0] + _coordYRange[1]) / 2

  return [mid - newYSpan / 2, mid + newYSpan / 2]
}

export const pixYToCoords = (pix, H) => {
  const coordYRange = coordYRangee(H)

  const ypct = pix / H
  const y = coordYRange[0] + (1 - ypct) * (coordYRange[1] - coordYRange[0])

  return y
}
