export const REQUIRED = 'Required'
export const REQUIRE_NUMBER = 'Must be a number'
export const REQUIRE_BOOLEAN = 'Must be the "true" or "false"'
export const MAX_5_CHARS = 'Maximum 5 characters'
export const MAX_120_CHARS = 'Maximum 120 characters'
export const MIN_5_CHARS = 'Minimum 5 characters'
export const MIN_8_CHARS = 'Minimum 8 characters'
export const INVALID_EMAIL = 'Invalid email'
export const ROUTES = {
  LOGIN: '/auth/login',
  SIGNUP: '/auth/signup',
  RESET_PASSWORD: '/auth/reset-password',
  RESET_PASSWORD_SENT: '/auth/reset-password/sent',
  RESET_PASSWORD_NEW: '/auth/reset-password/new',
  TERMS: '/terms',
  FILES: '/files',
  ACCOUNT: '/account',
  ACCOUNT_SECURITY: '/account/security',
  RECORDING_VIEW: '/recording/:recordingId',
  OLD_RECORDING_VIEW: '/old-recording/:recordingId',
  CURATION_RECORDING_VIEW: '/curation-recording/:recordingId',
  RECORDING_VIEW_SORTINGS: '/recording/:recordingId/sortings',
  TIMESERIES: '/timeseriesForRecording/:recordingId',
  TIMESERIES_CHILD: '/timeseries-child/:recordingId',
  ORGANISATION: '/organisation',
  ORGANISATION_MEMBERS: '/organisation/members',
  EXPERIMENTS_INPUT: '/experiments-input',
  EXPERIMENTS_INPUT_SPIKESRTING: '/experiments-input/spikesorting',
  PROBE_INVENTORY: '/probe-inventory',
  TRAIN_AI: '/train-AI',
  TRAIN_AI_EXISTING: '/train-AI/existing'
}
export const NOTIFICATION = {
  PLAIN: 'plain',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info'
}
export const QPARAMS = {
  KEYWORD: 'keyword',
  PAGE: 'page',
  FILTER: 'filter',
  CURATIONS_PAGE: 'curations_page',
  SORTINGS_PAGE: 'sortings_page',
  PER_PAGE: 'per_page',
  FOLDER_ID: 'folder_id',
  MODEL_DATASETS: 'model_datasets'
}
export const FILE_STATUS = {
  0: 'error',
  1: 'in_progress',
  2: 'success',
  4: 'in_queue'
}
export const UNITS_LABELS = {
  GOOD: 'good',
  MULTIPLE: 'mua',
  NOISE: 'noise'
}

export const SPIKESORTING_STATUS = {
  QUEUE: { code: 'queue', label: 'Queue' },
  STOPPED: { code: 'stopped', label: 'Stopped' },
  FAILURE: { code: 'error', label: 'Failure' },
  PENDING: { code: 'pending', label: 'Spikesorting' },
  SUCCESS: { code: 'success', label: 'Spikesorted' }
}
export const SPIKESORTING_ALGORITHMS = {
  KLUSTA: 'Klusta',
  KILOSORT: 'Kilosort'
}
export const MODAL_TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning'
}
export const RECORD_TABS = {
  TAGS: 'tags',
  PUBLICATIONS: 'publications'
}
export const RECORD_TYPE = {
  FOLDER: 'folder',
  FILE: 'file'
}
export const UPLOAD_PRBFILE_METHODS = {
  DEFAULT: 'defaultProbeFile',
  CUSTOM: 'customProbeFile',
  NONE: 'none'
}
export const PARAMETER_OPTIONS = {
  FILE: 'file',
  MANUALLY: 'manually'
}
export const EXPERIMENTS_INPUT = {
  UPLOADS: 'uploads',
  SPIKESORTINGS: 'spikesortings'
}
export const DOWN_SAMPLE_FACTOR = 0.3 // this is a tradeoff between rendering speed and appearance
export const MODALS_ID = {
  PROBE_DESIGN_MODAL: 'probe_design_modal',
  RENAME_FILE_NAME: 'rename_file_name_modal',
  CREATE_NEW_FOLDER: 'create_new_folder_modal',
  MOVE_TO_MODAL: 'move_to_modal',
  FILES_MODAL: 'files_modal',
  SPIKESORTING_DRAWER: 'spikesorting_drawer',
  COMMENTS_DRAWER: 'comments_drawer',
  NEW_SPIKESORTING_MODAL: 'new_spikesorting_modal',
  SPIKESORTING_ACTION_CONFIRM_MODAL: 'spikesorting_action_confirm_modal',
  EDIT_DATA_MODAL: 'edit_data_modal',
  EDIT_TAGS_PUBLICATIONS_MODAL: 'edit_tags_publications_modal',
  INVITE_USERS_MODAL: 'invite_users_modal',
  MENU_DRAWER: 'menu_drawer',
  CURATIONS_DRAWER: 'curations_drawer',
  DELETE_FILES_CONFIRMATION_MODAL: 'delete_files_confirmation_modal',
  DELETE_TIMESTAMP_CONFIRMATION_MODAL: 'delete_timestamp_confirmation_modal',
  CONCAT_FILES_MODAL: 'concat_files_modal',
  WAVE_FILES_MODAL: 'wave_files_modal',
  DATA_MANIPULATION_MODAL: 'data_manipulation_modal',
  DATA_MANIPULATION_SUCCESS_MODAL: 'data_manipulation_success_modal',
  UPLOAD_EXPERIMENTS_INPUT_MODAL: 'UPLOAD_EXPERIMENTS_INPUT_MODAL',
  SELECT_DEFAULT_PRB_FILE_MODAL: 'SELECT_DEFAULT_PRB_FILE_MODAL',
  DOWNLOAD_FILES_MODAL: 'download_files_modal',
  EVENTS_FILE_UPLOAD_MODAL: 'events_file_upload_modal',
  PROBE_UPLOAD_MODAL: 'probe_upload_modal',
  GET_PREMIUM_VERSION_MODAL: 'get_premium_version_modal',
  RANGE_SETTINGS_MODAL: 'range_settings_modal',
  APPROVE_CURATION_MODAL: 'approve_curation_modal',
  TRAIN_AI_MODAL: 'train_ai_modal',
  TRAIN_AI_RESULT_MODAL: 'train_ai_result_modal',
  EDIT_AI_MODEL: 'edit_AI_modal',
  DELETE_AI_MODAL: 'delete_AI_modal',
  MODEL_INFO_PANEL: 'model_info_panel'
}
export const DATA_MANIPULATION_MODE = {
  CUT: 'cut',
  SLICE: 'slice'
}
export const OS = {
  MAC_OS: 'Mac OS',
  IOS: 'iOS',
  WINDOWS: 'Windows',
  ANDROID: 'Android',
  LINUX: 'Linux'
}
export const TS_CURSOR_TYPE = {
  DEFAULT: 'default',
  SELECT_CHANNELS: 'select_channel',
  SELECT_TIMESTAMPS: 'select_timestamp'
}
export const EVENT_FILE_TYPE = {
  TIMESTAMP: 'timestamp',
  DIGITAL: 'digital',
  ANALOGIC: 'analogic'
}
export const DTYPES = [
  'byte',
  'float16',
  'float32',
  'float64',
  'int8',
  'int16',
  'int32',
  'int64',
  'uint8',
  'uint16',
  'uint32',
  'uint64'
].map(type => ({
  label: type,
  value: type
}))
export const PROBE_STATUS = {
  UNCONSUMED: 'Unconsumed',
  UNUSED: 'Unused'
}
export const IMPEDANCE_STATUS = {
  OPEN: 'Open',
  SHORT: 'Short'
}

export const REQUIRED_TS_FIELDS = ['number_channels', 'sample_rate']

export const CUSTOM_PROBES = new Set([
  'DA128-1',
  'DA128-1-O',
  'DA128-2',
  'DA128-2-O',
  'int128-DS64-1dbc',
  'int128-DS64-7dbc',
  'int128-DS64-13dbc',
  'DA128-3',
  'DA64-3-KH',
  'DA64-3-KH0',
  'DA31-1',
  'DA31-2',
  'DA31-3',
  'DA64-3',
  'ASSY156-P64-7dbc',
  'ASSY156-Intan-P64-7dbc'
])

export const PROBE_OFFEST_EXCEPTIONS = [
  'int128-DS64-1dbc.prb',
  'int128-DS64-13dbc.prb'
]

export const RASTER_UNITS = {
  single: 'good',
  multi: 'mua',
  noise: 'noise',
  uncurated: 'unc'
}

export const AI_MODELS_FILTERS = {
  ALL: 'all',
  AUTHOR: 'author',
  PUBLIC: 'public',
  COMPANY: 'company',
  ORG_AND_PUBLIC: 'all-public'
}
