import { ofType } from 'redux-observable'
import { delay, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import { merge, of } from 'rxjs'
import {
  saveSimilarityUnits,
  storeSuggestionVisibility,
  TOGGLE_RECOMMENDATIONS
} from '../actions'
import Storage from '../../../../../AppHeader/components/Storage'

export const hideRecommendationsEpic = (action$, state$) =>
  action$.pipe(
    ofType(TOGGLE_RECOMMENDATIONS),
    withLatestFrom(state$),
    mergeMap(([_action, state]) => {
      const id = _action.payload

      const { hiddenRecommendations, recommendations } =
        state.widgetsCache.curationAI || {}

      const sortingId = state.recordingStore.selectedSorting?.id
      const curationId = state.recordingStore.selectedCuration?.id
      const ID = [sortingId, curationId].join('-')

      const s = recommendations.get(ID)?.merge_recommendations || []

      const similarRecommendations = s?.find(item => item.includes(id))
      const hasRelations = similarRecommendations?.length > 0

      let newValues = new Map(hiddenRecommendations)
      const hasKey = newValues.has(id)
      if (hasKey) {
        if (hasRelations)
          for (let i of similarRecommendations) {
            newValues.set(i, !newValues.get(i))
          }
        else newValues.set(id, !newValues.get(id))
      } else {
        if (hasRelations)
          for (let i of similarRecommendations) {
            newValues.set(i, true)
          }
        else newValues.set(id, true)
      }

      Storage.setItem(`${ID}-hidden-units`, JSON.stringify([...newValues]))

      let actions = []

      /*TODO: DON'T REMOVE
      const { cluster, similarity } = state.widgetsCache.units || {}
      const is = cluster.concat(similarity).includes(id)
      if (is) {
        actions = [saveUnitsEpic(similarRecommendations.filter(i => i !== id))]
      }*/

      return merge(of(storeSuggestionVisibility(newValues)), ...actions)
    })
  )

/*const saveUnitsEpic = units => {
  return merge(
    ...units.map(unit =>
      of(unit).pipe(
        delay(250),
        map(_x => saveSimilarityUnits({ multiple: true, unit_id: unit }))
      )
    )
  )
}*/
