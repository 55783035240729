import { getRecordInfo, getProbeFile } from '../../../endpoints/admin'
import theme from '../../../theme'
import { fade } from '@material-ui/core'

export async function fetchRecord(recordId, cb) {
  const recordData = await getRecordInfo({ id: recordId })

  cb(recordData)
}

export async function fetchProbeFile(fileName, cb) {
  const probeFileData = await getProbeFile(fileName)
  return probeFileData
}

export function concatChannels(channelGroups) {
  return Object.values(channelGroups)
    .map(({ channels }) => channels)
    .flat()
}

export function concatPositions(channelGroups) {
  return Object.values(channelGroups).reduce(
    (acc, v) => ({ ...acc, ...v.geometry }),
    {}
  )
}

export function chunk(channelGroups, channelsArr) {
  const channelsSet = new Set(channelsArr)
  const orderedChannels = Object.keys(channelGroups)
    .map(key => {
      const filterChannels = channelGroups[key].channels.filter(channel =>
        channelsSet.has(channel)
      )
      return [key, filterChannels]
    })
    .filter(tupple => tupple[1].length > 0)

  const res = orderedChannels.reduce((acc, v, idx) => {
    return {
      ...acc,
      [v[0]]: v[1]
    }
  }, {})

  return {
    ...res,
    orderedChannels: orderedChannels.reduce((acc, [_, v]) => [...v, ...acc], [])
  }
}

export function channelMapGroup(channelGroups) {
  const gg = Object.keys(channelGroups).reduce((acc, group) => {
    return {
      ...acc,
      [group]: channelGroups[group].channels.map(channel => [channel, +group])
    }
  }, {})

  return mapGroup(gg)
}

function mapGroup(arr = []) {
  const mm = {}

  for (let k in arr) {
    if (Array.isArray(arr[k])) {
      for (let [channel, group] of arr[k]) {
        mm[channel] = group
      }
    }
  }

  return mm
}

export function mapColors(mapGroups, multigroup = false) {
  const defaultColors = mv_default_channel_colors()
  return Object.keys(mapGroups).reduce((acc, v) => {
    const length = defaultColors.length,
      gr = mapGroups[v],
      color = multigroup
        ? defaultColors[(gr % 2 ? gr - 1 : gr) % length]
        : defaultColors[gr % length]

    return {
      ...acc,
      [v]: multigroup ? (gr % 2 ? fade(color, 0.5) : color) : color
    }
  }, {})
}

export function mv_default_channel_colors() {
  return Object.values(theme().palette.colors.accent)
}

export function generateTimeseriesData(data) {
  if (!data.probeFileData) {
    return channelsDataWithoutProbeFile(data.numberChannels)
  } else {
    return channelsDataWithProbeFile(data.probeFileData, data.numberChannels)
  }
}

function channelsDataWithoutProbeFile(numberChannels, by) {
  const channels = Array.from(Array(numberChannels + 1).keys())

  return {
    firstGroupChannels: [],
    channel_ids: channels,
    channel_map: channels.reduce(
      (acc, channel, idx) => ({ ...acc, [channel]: Math.floor(idx / by || 0) }),
      {}
    ),
    channel_positions: channels.reduce(
      (acc, v) => ({ ...acc, [v]: [0, 0] }),
      {}
    ),
    chunks: chunkGroups(arrFromNumber(numberChannels), by),
    raw_num_channels: numberChannels + 1
  }
}

export function groupChannelsInSameProbe(channelsArr) {
  const arr = chunkGroups(channelsArr, 32)

  const ch = Object.keys(arr).reduce((acc, group) => {
    return {
      ...acc,
      [group]: arr[group].map(channel => [channel, +group])
    }
  }, {})

  return { chunks: chunkGroups(channelsArr, 32), channel_map: mapGroup(ch) }
}

function channelsDataWithProbeFile(fileData, numberChannels) {
  const { channelGroups, info } = fileData
  const channelsArr = concatChannels(channelGroups)
    .reverse()
    .slice(0, numberChannels)

  const { orderedChannels, ...chunks } = chunk(channelGroups, channelsArr)

  return {
    firstGroupChannels: channelGroups[Object.keys(channelGroups)[0]]?.channels,
    channel_ids: [...orderedChannels].reverse(),
    channel_map: channelMapGroup(channelGroups),
    channel_positions: concatPositions(channelGroups),
    chunks: chunks,
    raw_num_channels: info.channels
  }
}

export function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n)
  }
}

export function chunkGroups(arr, by = 0) {
  return [...chunks(arr, by || arr.length)].reduce((acc, channels, idx) => {
    return {
      ...acc,
      [idx]: channels
    }
  }, {})
}

function arrFromNumber(n) {
  return Array.from(Array(n).keys())
}

export const getInitialTimeRange = props => {
  let numChannels = props?.num_channels
  let numTimepoints = props?.num_timepoints

  // determine initial time range
  let mb_per_timepoint = (numChannels * 2) / 1e6
  let max_mb_to_load = 1
  let max_timepoints = Math.min(20000, max_mb_to_load / mb_per_timepoint)
  let t1 = Math.floor(numTimepoints / 2 - max_timepoints / 2)
  let t2 = Math.floor(numTimepoints / 2 + max_timepoints / 2)
  if (t1 < 0) t1 = 0
  if (t2 > numTimepoints - 1) t2 = numTimepoints - 1

  return [t1, t2]
}
