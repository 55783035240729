import React, { memo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { ListItem, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  link: {
    display: 'block',
    textDecoration: 'none',
    '&:last-child': {
      marginBottom: 0
    }
  },
  listItem: {
    position: 'relative',
    overflow: 'hidden',
    color: theme.palette.colors.neutral['5'],
    padding: '4px 20px',
    marginBottom: 24,
    '& span': {
      position: 'absolute',
      display: 'block',
      width: 4,
      height: 24,
      left: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
      backgroundColor: theme.palette.primary.contrast,
      transform: 'translateX(-4px)'
    },
    '& svg': {
      fill: theme.palette.colors.neutral['5']
    }
  },
  linkActive: {
    color: theme.palette.primary.contrast,
    '& span': {
      transform: 'translateX(0)'
    },
    '& svg': {
      fill: theme.palette.primary.contrast
    }
  },
  label: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '24px',
    marginLeft: 16
  }
}))

const MenuItem = ({
  to,
  icon: Icon,
  label,
  onClick,
  className,
  exact,
  disabled,
  callback = () => null
}) => {
  const classes = useStyles()
  const location = useLocation()
  const match = exact
    ? location.pathname === to
    : location.pathname.includes(to) || to?.includes(location.pathname)

  const Item = memo(({ onClick = null }) => (
    <ListItem
      button
      disableRipple
      disabled={disabled}
      className={cn(classes.listItem, className, {
        [classes.linkActive]: match && !disabled
      })}
      onClick={onClick}
    >
      <span />
      <Icon />
      <Typography className={classes.label}>{label}</Typography>
    </ListItem>
  ))

  if (onClick || disabled) {
    return <Item onClick={onClick} />
  }

  return (
    <Link to={to} className={cn(classes.link, className)} onClick={callback}>
      <Item />
    </Link>
  )
}

export default memo(MenuItem)
