import { useMemo } from 'react'
import { AssignmentRounded, SwapVertRounded } from '@material-ui/icons'
import { ROUTES } from '../../../utils/consts'

function useMenuItems() {
  return useMemo(
    () => [
      {
        to: ROUTES.EXPERIMENTS_INPUT,
        label: 'Upload',
        exact: true,
        icon: AssignmentRounded
      },
      {
        to: ROUTES.EXPERIMENTS_INPUT_SPIKESRTING,
        label: 'Spikesorting',
        exact: true,
        icon: SwapVertRounded
      }
    ],
    []
  )
}

export default useMenuItems
