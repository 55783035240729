import { SET_DARK_MODE } from '../actions'

const darkMode = (state = false, { type, payload }) => {
  switch (type) {
    case SET_DARK_MODE:
      return payload.value ?? !state
    default:
      return state
  }
}

export default darkMode
