import {
  ADD_APPROVED_DATASETS,
  ADD_FILES,
  DELETE_FILE,
  EDIT_FILE,
  RESET_FILES
} from '../actions/files'

const initialState = {
  files: null,
  pages: 1,
  approvedDatasets: null,
  approvedDatasetsPage: null,
  approvedDatasetsPages: null
}

const filesStore = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_FILES: {
      const { data, ...res } = payload || {}
      return { ...state, files: data, ...res }
    }
    case DELETE_FILE: {
      const { ids } = payload || {}
      let files = state.files.filter(rec => ids.indexOf(rec.id) === -1)
      return { ...state, files }
    }
    case EDIT_FILE: {
      const { id, ...value } = payload || {}
      const allFiles = state.files
      const fileIndex = allFiles.findIndex(item => item.id === id)
      const updatedItem = {
        ...allFiles[fileIndex],
        ...value
      }
      const updatedFiles = [
        ...allFiles.slice(0, fileIndex),
        updatedItem,
        ...allFiles.slice(fileIndex + 1)
      ]

      return { ...state, files: updatedFiles }
    }

    case ADD_APPROVED_DATASETS: {
      const { records, pages, page, ...res } = payload || {}
      return {
        ...state,
        approvedDatasets: records,
        approvedDatasetsPage: page,
        approvedDatasetsPages: pages,
        ...res
      }
    }

    case RESET_FILES: {
      return initialState
    }

    default:
      return state
  }
}

export default filesStore
