// import React from 'react'

// const HomeDBC = ({ documentId }) => {
//     return <div>Home page DBC:  {documentId}</div>;
// }

// export default HomeDBC;

import React from 'react'
import './Home.css'
import { Typography, Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import RecordingsTableDBC from '../containers/RecordingsTableDBC'
import { connect } from 'react-redux'
import { getPathQuery } from '../kachery'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    padding: 40
  }
}))

function HomeDBC({ documentInfo }) {
  const classes = useStyles()
  const { documentId, feedUri, readOnly } = documentInfo
  return (
    <div className={classes.root}>
      <Typography component="p">
        Analysis and visualization of neurophysiology recordings and spike
        sorting results (DBC).
      </Typography>
      <p />
      <div>
        <Button
          component={Link}
          to={`/${documentId}/importRecordings${getPathQuery({ feedUri })}`}
        >
          Import recordings
        </Button>
      </div>
      <RecordingsTableDBC />
    </div>
  )
}

const mapStateToProps = state => ({
  documentInfo: state.documentInfo
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(HomeDBC)
