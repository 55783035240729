import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import useEditPassword from './hooks/useEditProfile'
import { Formik } from 'formik'
import CustomInput from '../FormComponents/CustomInput/CustomInput'
import WatcherChanges from '../FormComponents/WatcherChanges'
import UploadImage from '../FormComponents/UploadImage'

const useStyles = makeStyles(() => ({
  form: {
    width: '100%'
  },
  formContainer: {
    '& > div.MuiGrid-item': {
      paddingBottom: 30
    }
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  }
}))

const EditProfile = () => {
  const classes = useStyles()
  const {
    onSubmit,
    initialValues,
    validation,
    checkForChanges,
    onUpload,
    loadingUploadPicture
  } = useEditPassword()

  const Input = useCallback(
    ({ errors, handleBlur, handleChange, ...props }) => (
      <CustomInput
        fullWidth
        error={errors}
        onBlur={handleBlur}
        onChange={handleChange}
        {...props}
      />
    ),
    []
  )

  return (
    <Grid container className={classes.root}>
      <Formik
        validateOnChange
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values, actions) => onSubmit(values, actions)}
      >
        {({
          initialValues,
          isSubmitting,
          values,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          resetForm,
          setFieldValue
        }) => {
          const inputProps = {
            errors,
            touched,
            handleBlur,
            handleChange
          }
          const hasChanges = checkForChanges(values, initialValues)
          const {
            address,
            city,
            country,
            email,
            lastname,
            name,
            phone,
            title,
            state,
            username,
            image,
            zip_code
          } = values
          return (
            <form className={classes.form}>
              <Grid container spacing={2} className={classes.formContainer}>
                <Grid xs={12} item>
                  <UploadImage
                    onUpload={files => onUpload('image', files, setFieldValue)}
                    name="image"
                    value={image}
                    loading={loadingUploadPicture}
                  />
                </Grid>

                {/*row*/}
                <Grid item md={2}>
                  <Input
                    label="Title"
                    name="title"
                    value={title}
                    required
                    {...inputProps}
                  />
                </Grid>
                <Grid item md={5}>
                  <Input
                    label="First Name"
                    name="name"
                    value={name}
                    required
                    {...inputProps}
                  />
                </Grid>
                <Grid item md={5}>
                  <Input
                    label="Last Name"
                    name="lastname"
                    value={lastname}
                    required
                    {...inputProps}
                  />
                </Grid>

                {/*row*/}
                <Grid item md={6}>
                  <Input
                    label="Email"
                    name="email"
                    value={email}
                    required
                    {...inputProps}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Username"
                    name="username"
                    value={username}
                    required
                    {...inputProps}
                  />
                </Grid>

                {/*row*/}
                <Grid item md={12}>
                  <Input
                    label="Mobile Number"
                    name="phone"
                    className={classes.input}
                    value={phone}
                    {...inputProps}
                  />
                </Grid>

                {/*row*/}
                <Grid item md={12}>
                  <Input
                    label="Address"
                    name="address"
                    value={address}
                    {...inputProps}
                  />
                </Grid>

                {/*row*/}
                <Grid item md={6}>
                  <Input
                    label="City"
                    name="city"
                    value={city}
                    {...inputProps}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="State"
                    name="state"
                    value={state}
                    {...inputProps}
                  />
                </Grid>

                {/*row*/}
                <Grid item md={6}>
                  <Input
                    label="Zip Code"
                    name="zip_code"
                    value={zip_code}
                    required
                    {...inputProps}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Country"
                    name="country"
                    required
                    value={country}
                    {...inputProps}
                  />
                </Grid>
              </Grid>
              {hasChanges && (
                <WatcherChanges
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                  resetForm={resetForm}
                />
              )}
            </form>
          )
        }}
      </Formik>
    </Grid>
  )
}

export default EditProfile
