import React from 'react'
import { MODAL_TYPE } from '../../../../utils/consts'
import { Error } from '@material-ui/icons'
import ActionButton from '../../../ActionButton'
import { makeStyles, Typography } from '@material-ui/core'
import CustomModal from '../../../CustomModal'

const useStyles = makeStyles(theme => ({
  message: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.colors.neutral.black
  }
}))

const DeleteConfirmationModal = ({
  isOpened,
  toggleDeleteModal,
  onDelete,
  selected,
  children = null
}) => {
  const classes = useStyles()
  return (
    <CustomModal
      open={isOpened}
      title="Delete Files"
      type={MODAL_TYPE.ERROR}
      onClose={toggleDeleteModal}
      maxWidth={600}
      headerIcon={<Error />}
      renderActionButton={
        <ActionButton
          label="Delete"
          onClick={() => {
            toggleDeleteModal()
            return onDelete()
          }}
        />
      }
    >
      {children ?? (
        <Typography className={classes.message}>
          Are you sure you want to delete{' '}
          {selected?.length > 1 ? 'these items' : 'this item'}?
        </Typography>
      )}
    </CustomModal>
  )
}

export default DeleteConfirmationModal
