import { useSelector } from 'react-redux'
import { useCallback } from 'react'
import { useFetchResult } from '../../../../../../../hooks/useFetchResult'
import { to } from '../../../../../../../utils/utils'
import { getOr } from 'lodash/fp'

function useClusterData({
  promise,
  customParams = {},
  storedData = null,
  saveData = () => null,
  saveSortMethod = () => null
}) {
  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )
  const selectedCuration = useSelector(
    state => state.recordingStore.selectedCuration
  )
  const { id: run_id } = selectedSorting || {}
  const curation_id = getOr(null, ['id'], selectedCuration)

  const params = {
    run_id,
    curation_id,
    ...customParams
  }

  const { state, fetchData, onSuccess, onError, onInit } = useFetchResult(
    promise,
    { ...params, cache: storedData }
  )

  const { data, error } = state || {}
  const { page, total_pages, clusters } = storedData?.data || data || {}
  const hasMore = page < total_pages

  const handleSort = useCallback(
    (sortBy, sortDirection) => saveSortMethod({ sortBy, sortDirection }),
    [saveSortMethod]
  )

  const handleLoadMore = useCallback(async () => {
    if (!hasMore) return
    try {
      onInit()
      const args = {
        ...params,
        page: page + 1
      }
      const { err, data: nextChunk } = await to(promise, args)
      if (err) {
        onError(err)
      } else {
        if (clusters) {
          const data = {
            ...nextChunk,
            clusters: clusters.concat(nextChunk?.clusters || [])
          }
          saveData({ data })
          onSuccess(data)
        }
      }
    } catch (e) {
      onError(e)
    }
  }, [
    clusters,
    hasMore,
    onError,
    onInit,
    onSuccess,
    page,
    params,
    promise,
    saveData
  ])

  const handleRefetch = useCallback(() => {
    if (!error) return
    if (clusters) handleLoadMore()
    else fetchData(saveData)

    // eslint-disable-next-line
  }, [clusters, error, fetchData, handleLoadMore])

  return {
    onSort: handleSort,
    loadMore: handleLoadMore,
    refetch: handleRefetch,
    state,
    fetchData,
    onInit,
    selectedSorting,
    hasMore
  }
}

export default useClusterData
