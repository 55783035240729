import React from 'react'

const ProbeInventoryIcon = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M6.86445 7.29112C6.56795 7.5024 6.20515 7.62667 5.81333 7.62667C4.81186 7.62667 4 6.81481 4 5.81333C4 4.81186 4.81186 4 5.81333 4C6.81481 4 7.62667 4.81186 7.62667 5.81333C7.62667 6.21769 7.49432 6.59113 7.27057 6.89271L9.95927 9.58141C10.5102 9.11606 11.2224 8.83556 12 8.83556C12.3086 8.83556 12.6069 8.87973 12.8888 8.96209L13.5489 7.36435C13.0253 7.04635 12.6756 6.47069 12.6756 5.81333C12.6756 4.81186 13.4874 4 14.4889 4C15.4904 4 16.3022 4.81186 16.3022 5.81333C16.3022 6.81481 15.4904 7.62667 14.4889 7.62667C14.3467 7.62667 14.2084 7.61031 14.0756 7.57937L13.4183 9.17042C14.4538 9.69047 15.1644 10.7622 15.1644 12C15.1644 12.1188 15.1579 12.2361 15.1451 12.3515L16.4845 12.5823C16.7396 11.8896 17.4054 11.3956 18.1867 11.3956C19.1881 11.3956 20 12.2074 20 13.2089C20 14.2104 19.1881 15.0222 18.1867 15.0222C17.1852 15.0222 16.3733 14.2104 16.3733 13.2089C16.3733 13.186 16.3738 13.1633 16.3746 13.1406L15.0319 12.9092C14.8125 13.6419 14.3347 14.263 13.7043 14.6667L14.6253 16.4442C14.7852 16.398 14.9542 16.3733 15.1289 16.3733C16.1304 16.3733 16.9422 17.1852 16.9422 18.1867C16.9422 19.1881 16.1304 20 15.1289 20C14.1274 20 13.3156 19.1881 13.3156 18.1867C13.3156 17.5629 13.6305 17.0127 14.1101 16.6864L13.1996 14.9292C12.8296 15.0808 12.4246 15.1644 12 15.1644C11.1096 15.1644 10.3051 14.7967 9.73005 14.2048L7.531 15.9371C7.68238 16.2019 7.76889 16.5086 7.76889 16.8356C7.76889 17.837 6.95703 18.6489 5.95556 18.6489C4.95408 18.6489 4.14222 17.837 4.14222 16.8356C4.14222 15.8341 4.95408 15.0222 5.95556 15.0222C6.42504 15.0222 6.85286 15.2006 7.1749 15.4934L9.37169 13.7629C9.03311 13.2591 8.83556 12.6526 8.83556 12C8.83556 11.2348 9.10715 10.533 9.5592 9.98586L6.86445 7.29112Z" />
    </svg>
  )
}

export default ProbeInventoryIcon
