function groupBy(data, by, map) {
  const groups = new Set(data.map(x => x[by]))
  const dataGrouped = new Map()

  for (let k of groups) {
    const ns = data.filter(x => x[by] === k).map(x => x[0])
    dataGrouped.set(k, map ? map(ns) : ns)
  }

  return dataGrouped
}

const split = str => {
  const idx = str.indexOf('-')

  return {
    channel: str.slice(0, idx),
    unit: str.slice(idx + 1)
  }
}

export const buildKey = (channel, unit) => {
  return `${channel}-${unit}`
}

const groupChannelsByUnit = data => {
  const parsedDataObjectToTuple = Object.entries(data).map(([k, v]) => [
    v,
    split(k).unit
  ])

  const parserData = value =>
    value.reduce(
      (acc, v) => {
        /*
          Find the maximum index for the unit from all channels positions and get also the parentNode from the maximum number found
        */
        const maxIdx = Math.max(acc.idx, v.idx)
        const parentNode = maxIdx === acc.idx ? acc.parentNode : v.parentNode

        return {
          idx: maxIdx,
          parentNode
        }
      },
      {
        idx: 0,
        parentNode: ''
      }
    )

  return groupBy(parsedDataObjectToTuple, '1', parserData)
}

export const factoryColumnBuckets = () => {
  let flattenedChannels = {}
  let groups = new Map()

  let lastChunk = 0

  return {
    // case I: same channels
    // case II: same coordonates, different channels

    mutateBuckets(channels, unit) {
      /*KEEP TARCE OF THE LONGEST CHAIN
        -because each parentNodes link chain is calculated for each channel,
        we must keep trace only to the biggest link chain,
        otherwise we can end up in the end with a parent node that is not correct
      */
      function findLongestChain() {
        let biggestTree = []

        for (let ch in channels) {
          const linkChain = Object.keys(flattenedChannels).filter(
            x => ch == split(x).channel
          )

          if (biggestTree.length < linkChain.length) {
            biggestTree = linkChain
          }
        }

        return biggestTree
      }

      /*
        this fn (findLongestChain) is searching for at least one similar channel,if yes,
        all that group(from similarity view) would belong to first one (from cluster view)
      */
      const longestChain = findLongestChain()
      //the parent is always the last column
      const parentKey = longestChain[longestChain.length - 1]
      //check if the channel is already included
      for (let channel in channels) {
        //find the maximum position on the current channel
        //if was found will be maximum value + 1,otherwise 0
        const parentNode = split(parentKey ?? '').unit
        const parsed = longestChain.map(x => flattenedChannels[x]?.idx ?? 0)
        const maxIdx = parsed.reduce((acc, v) => Math.max(acc, v), 0)

        flattenedChannels[buildKey(channel, unit)] = {
          idx: longestChain.length ? maxIdx + 1 : 0,
          parentNode: parentNode
        }
      }

      //because the number position is calculated individually for each channel we must make sure that all channels from same unit have the same value(the bigger)

      const columns = groupChannelsByUnit(flattenedChannels)

      for (let [k, v] of columns) {
        if (!v.parentNode && !groups.get(k)) {
          lastChunk += 1
          groups.set(k, lastChunk)
        } else if (v.parentNode && groups.get(v.parentNode)) {
          groups.set(k, groups.get(v.parentNode))
        }
      }

      for (let k in flattenedChannels) {
        const unit = split(k).unit

        flattenedChannels[k] = columns.get(unit)
      }

      return flattenedChannels
    },

    clearBucket(removedUnit) {
      const columns = groupChannelsByUnit(flattenedChannels)
      const copyColumns = new Map(columns.entries())

      //recursively suppose that all columns from a chain are removed
      function reorderPositions(u) {
        //find a group that has as parentNode the removed unit
        const [unit, value] =
          [...columns.entries()].find(([k, v]) => v.parentNode === u) ?? []

        //we hit the base case when is not found a node which has as parenNode the unit to be removed
        if (!unit) return

        //only the data of the unit dispatched to be removed will be canceled and update the parentNode only to the column next to the removed column
        //on the next iterations it must be updated only the index position and not the parentNode because it wasn't removed but only switched to left one position
        if (u === value.parentNode) {
          copyColumns.set(unit, {
            idx: value.idx - 1,
            parentNode:
              u === removedUnit
                ? columns.get(removedUnit).parentNode
                : value.parentNode
          })
        } else {
          copyColumns.set(unit, value)
        }

        return reorderPositions(unit)
      }

      reorderPositions(removedUnit)

      //set to null the value of the channels from removed unit to be removed later
      for (let k in flattenedChannels) {
        const unit = split(k).unit

        flattenedChannels[k] = copyColumns.get(unit)

        //removed channels of the removed unit
        if (unit === String(removedUnit)) {
          flattenedChannels[k] = null
        }
      }

      flattenedChannels = Object.entries(flattenedChannels)
        .filter(([k, v]) => v)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    },

    clearAllBuckets() {
      flattenedChannels = {}
    },

    getBucketIndices(channel, unit) {
      return flattenedChannels[buildKey(channel, unit)]?.idx
    },

    getYAxisBucket(unit) {
      return groups.get(String(unit)) ?? 0
    }
  }
}
