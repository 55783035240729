import { ofType } from 'redux-observable'
import { mergeMap, withLatestFrom } from 'rxjs/operators'
import { of } from 'rxjs'
import { SELECT_CURATION } from '../../../../../../actions/curations'
import {
  STORE_RECORD,
  STORE_SPIKESORTING,
  SELECT_SPIKESORTING,
  resetRecord
} from '../../../../../../actions/recording'
import {
  loadClusterview,
  saveClusterUnits,
  saveSimilarityUnits,
  loadCorrelogram,
  loadWaveform
} from '../actions'
import { resetProbeFileData } from '../../../../../../containers/NewTimeSeries/redux/actions'
import { resetTimeseries } from '../../../../../TimeseriesView/NewLeftPanel/redux/actions'

export const resetWidgetsEpic = (action$, state$) =>
  action$.pipe(
    ofType(
      SELECT_CURATION,
      STORE_RECORD,
      STORE_SPIKESORTING,
      SELECT_SPIKESORTING
    ),
    withLatestFrom(state$),
    mergeMap(([_action, state]) => {
      const sortingId = state.recordingStore.selectedSorting?.id

      let isResetCluster = true

      const selectParam = [
        { param: SELECT_SPIKESORTING, value: 'selectedSorting' },
        { param: SELECT_CURATION, value: 'selectedCuration' }
      ].find(values => values.param === _action.type)

      const isSelectedSortingORCuration =
        selectParam &&
        _action.payload?.id &&
        _action.payload?.id === state.recordingStore[selectParam.value]?.id

      if (_action.type === STORE_SPIKESORTING || isSelectedSortingORCuration) {
        isResetCluster = false
      }

      let resetActions = [
        saveClusterUnits(null),
        saveSimilarityUnits(null),
        loadCorrelogram({}),
        loadWaveform({}),
        { type: 'CLEAR_WAVEFORMS_CACHE' },

        // timeseries refresh
        resetProbeFileData(),
        resetTimeseries()
      ]

      if (isResetCluster)
        resetActions = [
          ...resetActions,
          loadClusterview({ sortingId, data: {} })
        ]

      return of(...resetActions)
    })
  )
