import React from 'react'
import PrivateRoute from '../PrivateRoute/PrivateRoute'
import About from '../About'
import Prototypes from '../Prototypes'
import ConfigurationPage from '../ConfigurationPage/ConfigurationPage'
import HitherJobMonitor from '../../containers/HitherJobMonitor'
import ImportRecordings from '../../containers/ImportRecordings'
import ImportSortings from '../../containers/ImportSortings'
import RecordingView from '../NewRecordingView'
import RecordingViewWithCuration from '../RecordingViewWithCuration'
import SortingView from '../../containers/SortingView'
import SortingUnitView from '../../containers/SortingUnitView'
import TimeseriesForRecordingView from '../../containers/TimeseriesForRecordingView'
import RunSpikeSortingForRecording from '../../containers/RunSpikeSortingForRecording'
import SortingJobView from '../../containers/SortingJobView'
import HomeDBC from '../HomeDBC'
import { Redirect, Switch } from 'react-router-dom'
import AdminLayout from '../AdminLayout'
import AccountPage from '../AccountPage'
import OrganisationPage from '../OrganisationPage'
import { ROUTES } from '../../utils/consts'
import AuthMiddleware from '../AuthMiddleware'
import TermsMiddleware from '../TermsMiddleware'
import TermsConditions from '../TermsConditions'
import Files from '../Files'
import Snackbar from '../Testing/Snackbar'
import Modals from '../Testing/Modals'
import NewTimeSeries from '../../containers/NewTimeSeries'
import ExperimentsInput from '../ExperimentsInput'
import ProbeInventory from '../ProbeInventory'
import TimeSeriesChild from '../../containers/TimeSeriesChild'
import TrainAI from '../TrainAI'

const TestPage = () => {
  return <div>{`Test page`}</div>
}

const AdminPanel = () => {
  return (
    <AuthMiddleware>
      <PrivateRoute path={ROUTES.TERMS} component={TermsConditions} />
      <TermsMiddleware>
        <AdminLayout>
          <Switch>
            <PrivateRoute path="/snackbar" component={Snackbar} />
            <PrivateRoute path="/modals" component={Modals} />
            <PrivateRoute path="/about">
              <About />
            </PrivateRoute>

            <PrivateRoute path={ROUTES.ACCOUNT}>
              <AccountPage />
            </PrivateRoute>
            <PrivateRoute path={ROUTES.PROBE_INVENTORY}>
              <ProbeInventory />
            </PrivateRoute>
            <PrivateRoute path={ROUTES.EXPERIMENTS_INPUT}>
              <ExperimentsInput />
            </PrivateRoute>
            <PrivateRoute path={ROUTES.ORGANISATION}>
              <OrganisationPage />
            </PrivateRoute>
            <PrivateRoute path={ROUTES.TRAIN_AI}>
              <TrainAI />
            </PrivateRoute>
            <PrivateRoute path={ROUTES.FILES} component={Files} />
            <PrivateRoute path="/prototypes">
              <Prototypes />
            </PrivateRoute>
            <PrivateRoute path="/test">
              <TestPage />
            </PrivateRoute>
            <PrivateRoute
              path={ROUTES.OLD_RECORDING_VIEW}
              component={RecordingView}
            />

            <PrivateRoute
              path={ROUTES.RECORDING_VIEW}
              component={RecordingViewWithCuration}
            />
            <PrivateRoute
              path={ROUTES.TIMESERIES}
              render={({ match }) => (
                <NewTimeSeries recordingId={match.params.recordingId} />
              )}
            />
            <PrivateRoute
              path={ROUTES.TIMESERIES_CHILD}
              component={TimeSeriesChild}
            />
            <PrivateRoute
              path="/:documentId/config"
              render={({ match }) => (
                //<Config />
                <ConfigurationPage />
              )}
            />
            <PrivateRoute
              path="/:documentId/hitherJobMonitor"
              render={({ match }) => <HitherJobMonitor />}
            />
            <PrivateRoute
              path="/:documentId/importRecordings"
              render={({ match }) => <ImportRecordings />}
            />
            <PrivateRoute
              path="/:documentId/importSortingsForRecording/:recordingId*"
              render={({ match }) => (
                <ImportSortings recordingId={match.params.recordingId} />
              )}
            />
            <PrivateRoute
              path="/:documentId/sorting/:sortingId*"
              render={({ match }) => (
                <SortingView sortingId={match.params.sortingId} />
              )}
            />
            <PrivateRoute
              path="/:documentId/sortingUnit/:sortingId/:unitId"
              render={({ match }) => (
                <SortingUnitView
                  sortingId={match.params.sortingId}
                  unitId={parseInt(match.params.unitId)}
                />
              )}
            />
            <PrivateRoute
              path="/:documentId/timeseriesForRecording/:recordingId*"
              render={({ match }) => (
                <TimeseriesForRecordingView
                  recordingId={match.params.recordingId}
                />
              )}
            />
            <PrivateRoute
              path="/:documentId/runSpikeSortingForRecording/:recordingId*"
              render={({ match }) => (
                <RunSpikeSortingForRecording
                  recordingId={match.params.recordingId}
                />
              )}
            />
            <PrivateRoute
              path="/:documentId/sortingJob/:sortingJobId*"
              render={({ match }) => (
                <SortingJobView sortingJobId={match.params.sortingJobId} />
              )}
            />
            <PrivateRoute
              path="/:documentId/HomeDBC"
              render={({ match }) => (
                <HomeDBC documentId={match.params.sortingJobId} />
              )}
            />
            <Redirect noThrow to="/files" />
          </Switch>
        </AdminLayout>
      </TermsMiddleware>
    </AuthMiddleware>
  )
}

export default AdminPanel
