import React from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MapData from '../../../../../../../utils/MapData/MapData'
import Channels from '../Channels'
import FileName from './components/ProbeFileName'

const useStyles = makeStyles(() => ({
  body: ({ multigroup }) => ({
    flex: 1,
    width: 'auto',
    margin: '0 -15px',
    paddingTop: multigroup ? 10 : 0,
    overflowY: 'auto',
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center'
  })
}))

const ChannelsView = ({ probeFileName, data, multigroup, ...rest }) => {
  const classes = useStyles({ multigroup })
  return (
    <>
      <FileName probeFileName={probeFileName} />
      <Grid className={classes.body} container id="timeseries-mapping">
        <MapData data={data}>
          {({ item: { channels, color }, index }) => (
            <Channels
              key={index}
              multigroup={multigroup && index % 2}
              channels={channels}
              color={color}
              {...rest}
            />
          )}
        </MapData>
      </Grid>
    </>
  )
}

export default ChannelsView
