import { useState } from 'react'
import { signPolicy } from '../../../endpoints/auth'
import { useHistory } from 'react-router-dom'

function useSubmitTerms({ acceptTerms }) {
  const history = useHistory()
  const [isSubmitting, setSubmitting] = useState('')

  const onSubmit = async () => {
    try {
      setSubmitting(true)
      const response = await signPolicy()
      if (response?.isTermsChecked) {
        acceptTerms()
        history.push('/files')
      }
      setSubmitting(false)
    } catch (e) {
      setSubmitting(false)
    }
  }

  return { onSubmit, isSubmitting }
}

export default useSubmitTerms
