import { makeStyles, Tooltip } from '@material-ui/core'
import cn from 'classnames'
import React from 'react'

const useStyles2 = makeStyles(theme => ({
  //tooltip
  tooltip: ({ width = 200 }) => ({
    width,
    padding: 12,
    borderRadius: 8,
    color: theme.palette.colors.black,
    backgroundColor: theme.palette.colors.white,
    border: `1px solid ${theme.palette.colors.neutral['5']}`,
    boxShadow: `0px 1.25px 4px ${theme.palette.colors.grey_25}`
  }),
  tooltipArrow: {
    fontSize: 22,
    '&::before': {
      backgroundColor: theme.palette.colors.white,
      border: `1px solid ${theme.palette.colors.neutral['5']}`
    }
  }
}))

const CustomTooltip = ({
  children,
  tooltip,
  placement = 'top',
  width,
  _classes = {}
}) => {
  const classes = useStyles2({ width })
  return (
    <Tooltip
      title={tooltip}
      arrow
      placement={placement}
      classes={{
        tooltip: cn(classes.tooltip, _classes?.tooltip),
        arrow: cn(classes.tooltipArrow, _classes.tooltipArrow),
        popper: _classes.popper
      }}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
