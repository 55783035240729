import { useCallback, useEffect, useState } from 'react'
import { getOS } from '../utils/utils'
import { OS } from '../utils/consts'

function useTriggerKeyEvent() {
  const [isCtrl, setCtrl] = useState(false)
  const [keyKode, setKeyCode] = useState(null)

  const triggerCtrl = useCallback(e => {
    const { ctrlKey, metaKey } = e || {}
    const isCtrlPressed = getOS() === OS.MAC_OS ? metaKey : ctrlKey
    setCtrl(isCtrlPressed)
    setKeyCode(e?.keyCode)
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', triggerCtrl)
    document.addEventListener('keyup', triggerCtrl)

    return () => {
      document.removeEventListener('keydown', triggerCtrl)
      document.addEventListener('keyup', triggerCtrl)
    }
    // eslint-disable-next-line
  }, [])

  return { isCtrl, keyKode }
}

export default useTriggerKeyEvent
