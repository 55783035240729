import React from 'react'

export const svg64_14 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        width="78"
        height="1370"
        viewBox="0 0 78 1370"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
      >
        <path
          d="M0 1338.68V0H75.075L78 202.8L18.525 1369.88L0 1338.68Z"
          fill={color}
        />
        <path
          d="M2 119C2 117.343 3.34315 116 5 116H17C18.6569 116 20 117.343 20 119V130.077C20 131.734 18.6569 133.077 17 133.077H5C3.34315 133.077 2 131.734 2 130.077V119Z"
          fill="white"
        />
        <path
          d="M2 138.077C2 136.42 3.34315 135.077 5 135.077H17C18.6569 135.077 20 136.42 20 138.077V149.154C20 150.811 18.6569 152.154 17 152.154H5C3.34315 152.154 2 150.811 2 149.154V138.077Z"
          fill="white"
        />
        <path
          d="M2 157.154C2 155.497 3.34315 154.154 5 154.154H17C18.6569 154.154 20 155.497 20 157.154V168.231C20 169.888 18.6569 171.231 17 171.231H5C3.34315 171.231 2 169.888 2 168.231V157.154Z"
          fill="white"
        />
        <path
          d="M2 176.231C2 174.574 3.34315 173.231 5 173.231H17C18.6569 173.231 20 174.574 20 176.231V187.308C20 188.964 18.6569 190.308 17 190.308H5C3.34315 190.308 2 188.964 2 187.308V176.231Z"
          fill="white"
        />
        <path
          d="M2 195.308C2 193.651 3.34315 192.308 5 192.308H17C18.6569 192.308 20 193.651 20 195.308V206.385C20 208.041 18.6569 209.385 17 209.385H5C3.34315 209.385 2 208.041 2 206.385V195.308Z"
          fill="white"
        />
        <path
          d="M2 214.385C2 212.728 3.34315 211.385 5 211.385H17C18.6569 211.385 20 212.728 20 214.385V225.461C20 227.118 18.6569 228.461 17 228.461H5C3.34315 228.461 2 227.118 2 225.461V214.385Z"
          fill="white"
        />
        <path
          d="M2 233.461C2 231.805 3.34315 230.461 5 230.461H17C18.6569 230.461 20 231.805 20 233.461V244.538C20 246.195 18.6569 247.538 17 247.538H5C3.34315 247.538 2 246.195 2 244.538V233.461Z"
          fill="white"
        />
        <path
          d="M2 252.538C2 250.881 3.34315 249.538 5 249.538H17C18.6569 249.538 20 250.881 20 252.538V263.615C20 265.272 18.6569 266.615 17 266.615H5C3.34315 266.615 2 265.272 2 263.615V252.538Z"
          fill="white"
        />
        <path
          d="M2 271.615C2 269.958 3.34315 268.615 5 268.615H17C18.6569 268.615 20 269.958 20 271.615V282.692C20 284.349 18.6569 285.692 17 285.692H5C3.34315 285.692 2 284.349 2 282.692V271.615Z"
          fill="white"
        />
        <path
          d="M2 290.692C2 289.036 3.34315 287.692 5 287.692H17C18.6569 287.692 20 289.036 20 290.692V301.769C20 303.426 18.6569 304.769 17 304.769H5C3.34315 304.769 2 303.426 2 301.769V290.692Z"
          fill="white"
        />
        <path
          d="M2 309.769C2 308.112 3.34315 306.769 5 306.769H17C18.6569 306.769 20 308.112 20 309.769V320.846C20 322.503 18.6569 323.846 17 323.846H5C3.34315 323.846 2 322.503 2 320.846V309.769Z"
          fill="white"
        />
        <path
          d="M2 328.846C2 327.189 3.34315 325.846 5 325.846H17C18.6569 325.846 20 327.189 20 328.846V339.923C20 341.58 18.6569 342.923 17 342.923H5C3.34315 342.923 2 341.58 2 339.923V328.846Z"
          fill="white"
        />
        <path
          d="M2 347.923C2 346.266 3.34315 344.923 5 344.923H17C18.6569 344.923 20 346.266 20 347.923V359C20 360.657 18.6569 362 17 362H5C3.34315 362 2 360.657 2 359V347.923Z"
          fill="white"
        />
        <path
          d="M2 367C2 365.343 3.34315 364 5 364H17C18.6569 364 20 365.343 20 367V378.077C20 379.734 18.6569 381.077 17 381.077H5C3.34315 381.077 2 379.734 2 378.077V367Z"
          fill="white"
        />
        <path
          d="M2 386.077C2 384.42 3.34315 383.077 5 383.077H17C18.6569 383.077 20 384.42 20 386.077V397.154C20 398.811 18.6569 400.154 17 400.154H5C3.34315 400.154 2 398.811 2 397.154V386.077Z"
          fill="white"
        />
        <path
          d="M2 405.154C2 403.497 3.34315 402.154 5 402.154H17C18.6569 402.154 20 403.497 20 405.154V416.231C20 417.888 18.6569 419.231 17 419.231H5C3.34315 419.231 2 417.888 2 416.231V405.154Z"
          fill="white"
        />
        <path
          d="M2 424.231C2 422.574 3.34315 421.231 5 421.231H17C18.6569 421.231 20 422.574 20 424.231V435.308C20 436.964 18.6569 438.308 17 438.308H5C3.34315 438.308 2 436.964 2 435.308V424.231Z"
          fill="white"
        />
        <path
          d="M2 443.308C2 441.651 3.34315 440.308 5 440.308H17C18.6569 440.308 20 441.651 20 443.308V454.385C20 456.041 18.6569 457.385 17 457.385H5C3.34315 457.385 2 456.041 2 454.385V443.308Z"
          fill="white"
        />
        <path
          d="M2 462.385C2 460.728 3.34315 459.385 5 459.385H17C18.6569 459.385 20 460.728 20 462.385V473.461C20 475.118 18.6569 476.461 17 476.461H5C3.34315 476.461 2 475.118 2 473.461V462.385Z"
          fill="white"
        />
        <path
          d="M2 481.461C2 479.805 3.34315 478.461 5 478.461H17C18.6569 478.461 20 479.805 20 481.461V492.538C20 494.195 18.6569 495.538 17 495.538H5C3.34315 495.538 2 494.195 2 492.538V481.461Z"
          fill="white"
        />
        <path
          d="M2 500.538C2 498.881 3.34315 497.538 5 497.538H17C18.6569 497.538 20 498.881 20 500.538V511.615C20 513.272 18.6569 514.615 17 514.615H5C3.34315 514.615 2 513.272 2 511.615V500.538Z"
          fill="white"
        />
        <path
          d="M2 519.615C2 517.958 3.34315 516.615 5 516.615H17C18.6569 516.615 20 517.958 20 519.615V530.692C20 532.349 18.6569 533.692 17 533.692H5C3.34315 533.692 2 532.349 2 530.692V519.615Z"
          fill="white"
        />
        <path
          d="M2 538.692C2 537.035 3.34315 535.692 5 535.692H17C18.6569 535.692 20 537.035 20 538.692V549.769C20 551.426 18.6569 552.769 17 552.769H5C3.34315 552.769 2 551.426 2 549.769V538.692Z"
          fill="white"
        />
        <path
          d="M2 557.769C2 556.112 3.34315 554.769 5 554.769H17C18.6569 554.769 20 556.112 20 557.769V568.846C20 570.503 18.6569 571.846 17 571.846H5C3.34315 571.846 2 570.503 2 568.846V557.769Z"
          fill="white"
        />
        <path
          d="M2 576.846C2 575.189 3.34315 573.846 5 573.846H17C18.6569 573.846 20 575.189 20 576.846V587.923C20 589.58 18.6569 590.923 17 590.923H5C3.34315 590.923 2 589.58 2 587.923V576.846Z"
          fill="white"
        />
        <path
          d="M2 595.923C2 594.266 3.34315 592.923 5 592.923H17C18.6569 592.923 20 594.266 20 595.923V607C20 608.657 18.6569 610 17 610H5C3.34315 610 2 608.657 2 607V595.923Z"
          fill="white"
        />
        <path
          d="M2 615C2 613.343 3.34315 612 5 612H17C18.6569 612 20 613.343 20 615V626.077C20 627.734 18.6569 629.077 17 629.077H5C3.34315 629.077 2 627.734 2 626.077V615Z"
          fill="white"
        />
        <path
          d="M2 634.077C2 632.42 3.34315 631.077 5 631.077H17C18.6569 631.077 20 632.42 20 634.077V645.154C20 646.81 18.6569 648.154 17 648.154H5C3.34315 648.154 2 646.81 2 645.154V634.077Z"
          fill="white"
        />
        <path
          d="M2 653.154C2 651.497 3.34315 650.154 5 650.154H17C18.6569 650.154 20 651.497 20 653.154V664.23C20 665.887 18.6569 667.23 17 667.23H5C3.34315 667.23 2 665.887 2 664.23V653.154Z"
          fill="white"
        />
        <path
          d="M2 672.23C2 670.574 3.34315 669.23 5 669.23H17C18.6569 669.23 20 670.574 20 672.23V683.307C20 684.964 18.6569 686.307 17 686.307H5C3.34315 686.307 2 684.964 2 683.307V672.23Z"
          fill="white"
        />
        <path
          d="M2 691.307C2 689.651 3.34315 688.307 5 688.307H17C18.6569 688.307 20 689.651 20 691.307V702.384C20 704.041 18.6569 705.384 17 705.384H5C3.34315 705.384 2 704.041 2 702.384V691.307Z"
          fill="white"
        />
        <path
          d="M2 710.384C2 708.727 3.34315 707.384 5 707.384H17C18.6569 707.384 20 708.727 20 710.384V721.461C20 723.118 18.6569 724.461 17 724.461H5C3.34315 724.461 2 723.118 2 721.461V710.384Z"
          fill="white"
        />
        <path
          d="M2 729.461C2 727.804 3.34315 726.461 5 726.461H17C18.6569 726.461 20 727.804 20 729.461V740.538C20 742.195 18.6569 743.538 17 743.538H5C3.34315 743.538 2 742.195 2 740.538V729.461Z"
          fill="white"
        />
        <path
          d="M2 748.538C2 746.881 3.34315 745.538 5 745.538H17C18.6569 745.538 20 746.881 20 748.538V759.615C20 761.272 18.6569 762.615 17 762.615H5C3.34315 762.615 2 761.272 2 759.615V748.538Z"
          fill="white"
        />
        <path
          d="M2 767.615C2 765.958 3.34315 764.615 5 764.615H17C18.6569 764.615 20 765.958 20 767.615V778.692C20 780.349 18.6569 781.692 17 781.692H5C3.34315 781.692 2 780.349 2 778.692V767.615Z"
          fill="white"
        />
        <path
          d="M2 786.692C2 785.035 3.34315 783.692 5 783.692H17C18.6569 783.692 20 785.035 20 786.692V797.769C20 799.426 18.6569 800.769 17 800.769H5C3.34315 800.769 2 799.426 2 797.769V786.692Z"
          fill="white"
        />
        <path
          d="M2 805.769C2 804.112 3.34315 802.769 5 802.769H17C18.6569 802.769 20 804.112 20 805.769V816.846C20 818.503 18.6569 819.846 17 819.846H5C3.34315 819.846 2 818.503 2 816.846V805.769Z"
          fill="white"
        />
        <path
          d="M2 824.846C2 823.189 3.34315 821.846 5 821.846H17C18.6569 821.846 20 823.189 20 824.846V835.923C20 837.579 18.6569 838.923 17 838.923H5C3.34315 838.923 2 837.579 2 835.923V824.846Z"
          fill="white"
        />
        <path
          d="M2 843.923C2 842.266 3.34315 840.923 5 840.923H17C18.6569 840.923 20 842.266 20 843.923V855C20 856.656 18.6569 858 17 858H5C3.34315 858 2 856.656 2 855V843.923Z"
          fill="white"
        />
        <path
          d="M2 863C2 861.343 3.34315 860 5 860H17C18.6569 860 20 861.343 20 863V874.076C20 875.733 18.6569 877.076 17 877.076H5C3.34315 877.076 2 875.733 2 874.076V863Z"
          fill="white"
        />
        <path
          d="M2 882.076C2 880.42 3.34315 879.076 5 879.076H17C18.6569 879.076 20 880.42 20 882.076V893.153C20 894.81 18.6569 896.153 17 896.153H5C3.34315 896.153 2 894.81 2 893.153V882.076Z"
          fill="white"
        />
        <path
          d="M2 901.153C2 899.496 3.34315 898.153 5 898.153H17C18.6569 898.153 20 899.496 20 901.153V912.23C20 913.887 18.6569 915.23 17 915.23H5C3.34315 915.23 2 913.887 2 912.23V901.153Z"
          fill="white"
        />
        <path
          d="M2 920.23C2 918.574 3.34315 917.23 5 917.23H17C18.6569 917.23 20 918.574 20 920.23V931.307C20 932.964 18.6569 934.307 17 934.307H5C3.34315 934.307 2 932.964 2 931.307V920.23Z"
          fill="white"
        />
        <path
          d="M2 939.307C2 937.65 3.34315 936.307 5 936.307H17C18.6569 936.307 20 937.65 20 939.307V950.384C20 952.041 18.6569 953.384 17 953.384H5C3.34315 953.384 2 952.041 2 950.384V939.307Z"
          fill="white"
        />
        <path
          d="M2 958.384C2 956.727 3.34315 955.384 5 955.384H17C18.6569 955.384 20 956.727 20 958.384V969.461C20 971.118 18.6569 972.461 17 972.461H5C3.34315 972.461 2 971.118 2 969.461V958.384Z"
          fill="white"
        />
        <path
          d="M2 977.461C2 975.804 3.34315 974.461 5 974.461H17C18.6569 974.461 20 975.804 20 977.461V988.538C20 990.195 18.6569 991.538 17 991.538H5C3.34315 991.538 2 990.195 2 988.538V977.461Z"
          fill="white"
        />
        <path
          d="M2 996.538C2 994.881 3.34315 993.538 5 993.538H17C18.6569 993.538 20 994.881 20 996.538V1007.62C20 1009.27 18.6569 1010.62 17 1010.62H5C3.34315 1010.62 2 1009.27 2 1007.62V996.538Z"
          fill="white"
        />
        <path
          d="M2 1015.61C2 1013.96 3.34315 1012.61 5 1012.61H17C18.6569 1012.61 20 1013.96 20 1015.61V1026.69C20 1028.35 18.6569 1029.69 17 1029.69H5C3.34315 1029.69 2 1028.35 2 1026.69V1015.61Z"
          fill="white"
        />
        <path
          d="M2 1034.69C2 1033.03 3.34315 1031.69 5 1031.69H17C18.6569 1031.69 20 1033.03 20 1034.69V1045.77C20 1047.43 18.6569 1048.77 17 1048.77H5C3.34315 1048.77 2 1047.43 2 1045.77V1034.69Z"
          fill="white"
        />
        <path
          d="M2 1053.77C2 1052.11 3.34315 1050.77 5 1050.77H17C18.6569 1050.77 20 1052.11 20 1053.77V1064.85C20 1066.5 18.6569 1067.85 17 1067.85H5C3.34315 1067.85 2 1066.5 2 1064.85V1053.77Z"
          fill="white"
        />
        <path
          d="M2 1072.85C2 1071.19 3.34315 1069.85 5 1069.85H17C18.6569 1069.85 20 1071.19 20 1072.85V1083.92C20 1085.58 18.6569 1086.92 17 1086.92H5C3.34315 1086.92 2 1085.58 2 1083.92V1072.85Z"
          fill="white"
        />
        <path
          d="M2 1091.92C2 1090.27 3.34315 1088.92 5 1088.92H17C18.6569 1088.92 20 1090.27 20 1091.92V1103C20 1104.66 18.6569 1106 17 1106H5C3.34315 1106 2 1104.66 2 1103V1091.92Z"
          fill="white"
        />
        <path
          d="M2 1111C2 1109.34 3.34315 1108 5 1108H17C18.6569 1108 20 1109.34 20 1111V1122.08C20 1123.73 18.6569 1125.08 17 1125.08H5C3.34315 1125.08 2 1123.73 2 1122.08V1111Z"
          fill="white"
        />
        <path
          d="M2 1130.08C2 1128.42 3.34315 1127.08 5 1127.08H17C18.6569 1127.08 20 1128.42 20 1130.08V1141.15C20 1142.81 18.6569 1144.15 17 1144.15H5C3.34315 1144.15 2 1142.81 2 1141.15V1130.08Z"
          fill="white"
        />
        <path
          d="M2 1149.15C2 1147.5 3.34315 1146.15 5 1146.15H17C18.6569 1146.15 20 1147.5 20 1149.15V1160.23C20 1161.89 18.6569 1163.23 17 1163.23H5C3.34315 1163.23 2 1161.89 2 1160.23V1149.15Z"
          fill="white"
        />
        <path
          d="M2 1168.23C2 1166.57 3.34315 1165.23 5 1165.23H17C18.6569 1165.23 20 1166.57 20 1168.23V1179.31C20 1180.96 18.6569 1182.31 17 1182.31H5C3.34315 1182.31 2 1180.96 2 1179.31V1168.23Z"
          fill="white"
        />
        <path
          d="M2 1187.31C2 1185.65 3.34315 1184.31 5 1184.31H17C18.6569 1184.31 20 1185.65 20 1187.31V1198.38C20 1200.04 18.6569 1201.38 17 1201.38H5C3.34315 1201.38 2 1200.04 2 1198.38V1187.31Z"
          fill="white"
        />
        <path
          d="M2 1206.38C2 1204.73 3.34315 1203.38 5 1203.38H17C18.6569 1203.38 20 1204.73 20 1206.38V1217.46C20 1219.12 18.6569 1220.46 17 1220.46H5C3.34315 1220.46 2 1219.12 2 1217.46V1206.38Z"
          fill="white"
        />
        <path
          d="M2 1225.46C2 1223.8 3.34315 1222.46 5 1222.46H17C18.6569 1222.46 20 1223.8 20 1225.46V1236.54C20 1238.19 18.6569 1239.54 17 1239.54H5C3.34315 1239.54 2 1238.19 2 1236.54V1225.46Z"
          fill="white"
        />
        <path
          d="M2 1244.54C2 1242.88 3.34315 1241.54 5 1241.54H17C18.6569 1241.54 20 1242.88 20 1244.54V1255.61C20 1257.27 18.6569 1258.61 17 1258.61H5C3.34315 1258.61 2 1257.27 2 1255.61V1244.54Z"
          fill="white"
        />
        <path
          d="M2 1263.61C2 1261.96 3.34315 1260.61 5 1260.61H17C18.6569 1260.61 20 1261.96 20 1263.61V1274.69C20 1276.35 18.6569 1277.69 17 1277.69H5C3.34315 1277.69 2 1276.35 2 1274.69V1263.61Z"
          fill="white"
        />
        <path
          d="M2 1282.69C2 1281.03 3.34315 1279.69 5 1279.69H17C18.6569 1279.69 20 1281.03 20 1282.69V1293.77C20 1295.43 18.6569 1296.77 17 1296.77H5C3.34315 1296.77 2 1295.43 2 1293.77V1282.69Z"
          fill="white"
        />
        <path
          d="M2 1301.77C2 1300.11 3.34315 1298.77 5 1298.77H17C18.6569 1298.77 20 1300.11 20 1301.77V1312.85C20 1314.5 18.6569 1315.85 17 1315.85H5C3.34315 1315.85 2 1314.5 2 1312.85V1301.77Z"
          fill="white"
        />
        <path
          d="M2 1320.85C2 1319.19 3.34315 1317.85 5 1317.85H17C18.6569 1317.85 20 1319.19 20 1320.85V1331.92C20 1333.58 18.6569 1334.92 17 1334.92H5C3.34315 1334.92 2 1333.58 2 1331.92V1320.85Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="78"
      height="3400"
      viewBox="0 0 78 3400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        d="M0 3322.56V0H75.075L78 503.345L18.525 3400L0 3322.56Z"
        fill={color}
      />
    </svg>
  )
}
