import { useCallback, useState } from 'react'

function useDownloadAppModal() {
  const [isOpened, setOpen] = useState(false)
  const toggleModal = useCallback(() => setOpen(open => !open), [])

  return { toggleModal, isOpened }
}

export default useDownloadAppModal
