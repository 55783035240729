import React, { memo } from 'react'
import { Grid, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import Breadcrumb from '../../../Breadcrumb/Breadcrumb'
import useQueryParams from '../../../../hooks/useQueryParams'
import { QPARAMS, ROUTES } from '../../../../utils/consts'
import Storage from '../../../AppHeader/components/Storage'
import CustomDropdown from '../../../CustomDropdown'
import useActionButtons from './hooks/useActionButtons'
import { useSelectPositionModal } from '../WidgetsLayout/hooks'
import useStorageKeys from '../../../../hooks/useStorageKeys'
import { useSelector } from 'react-redux'
import { propsAreEqual } from '../../../../utils/utils'

const useStyles = makeStyles(theme => ({
  root: {
    height: 57,
    padding: '0 40px'
  },
  breadcrumb: {
    flex: 2,
    width: 100
  },
  actionBtns: {
    flex: 1
  },
  dropdown: {
    marginLeft: 16,
    zIndex: 2
  },
  tooltip: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '18px',
    backgroundColor: '#5A5869',
    borderRadius: 8,
    padding: '6px 12px'
  },
  button: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '20px',
    textTransform: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.colors.error,
    '&:hover': {
      color: theme.palette.colors.error
    }
  }
}))

const RecordingHeader = props => {
  const { fileName, selectedSorting, layoutDS, clearAllWidgets, folderId } =
    props
  const classes = useStyles()
  const { setQueryParams } = useQueryParams()
  const selectedCuration = useSelector(
    state => state.recordingStore.selectedCuration
  )
  const { name: sortingName } = selectedSorting || {}
  const { id: curationId } = selectedCuration || {}
  const STORAGE_KEY = useStorageKeys()

  const filesPage = Storage.getItem(STORAGE_KEY.PAGE)
  const keyword = Storage.getItem(STORAGE_KEY.KEYWORD)
  const toFiles = `${ROUTES.FILES}${setQueryParams([
    { key: QPARAMS.FOLDER_ID, value: folderId },
    { key: QPARAMS.PAGE, value: filesPage },
    { key: QPARAMS.KEYWORD, value: keyword?.trim() }
  ])}
  `
  const breadcrumb = [
    {
      label: 'Data',
      to: toFiles
    },
    {
      label: [fileName, sortingName, curationId].filter(Boolean).join(' - '),
      current: true
    }
  ]
  const ClearAllBtn = memo(() => {
    const isEmpty = layoutDS.size === 0

    if (isEmpty) return null

    return (
      <Button
        color="secondary"
        className={classes.button}
        startIcon={<CloseIcon />}
        onClick={clearAllWidgets}
      >
        Clear All
      </Button>
    )
  }, [layoutDS])

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      wrap="nowrap"
      className={classes.root}
    >
      <div className={classes.breadcrumb}>
        <Breadcrumb steps={breadcrumb} />
      </div>

      <Grid
        container
        justify="flex-end"
        alignItems="center"
        wrap="nowrap"
        className={classes.actionBtns}
      >
        <ClearAllBtn />
        <ActionBtns layoutDS={layoutDS} />
      </Grid>
    </Grid>
  )
}

const ActionBtns = ({ layoutDS }) => {
  const classes = useStyles()
  const { actions, curation, widgets, isBlockedByApproval } =
    useActionButtons(layoutDS)
  const { onOpen } = useSelectPositionModal()

  return (
    <>
      <CustomDropdown
        label="Select Widgets"
        items={widgets}
        width={250}
        className={classes.dropdown}
        onSelect={onOpen}
        id="widgets-menu"
      />
      {/* IN DEVELOPMENT */}
      <CustomDropdown
        label="Curation"
        items={curation}
        className={classes.dropdown}
        _classes={classes}
        tooltip={
          isBlockedByApproval
            ? "The curation session has Approved status. You can't make any curation operations."
            : ''
        }
      />
      <CustomDropdown
        label="Actions"
        items={actions}
        width={190}
        className={classes.dropdown}
        id="actions-menu"
      />
    </>
  )
}

export default RecordingHeader
