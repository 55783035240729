import React, { memo } from 'react'
import CustomModal from '../../../CustomModal'
import { makeStyles } from '@material-ui/core'
import { Folder, InsertDriveFile } from '@material-ui/icons'
import ActionButton from '../../../ActionButton'
import CustomInput from '../../../FormComponents/CustomInput/CustomInput'

const useStyles = makeStyles(() => ({
  body: {
    padding: '25px 0',
    overflow: 'hidden'
  }
}))

const NewFolderModal = ({
  isOpened,
  toggleModal,
  title = 'New Folder',
  onSubmit = () => null,
  onKeyPress = () => null,
  onChange,
  value,
  loading,
  type = 'folder',
  action = 'create'
}) => {
  const classes = useStyles()
  const buttonLabel = action === 'create' ? 'Create' : 'Save'
  const icon = type === 'folder' ? <Folder /> : <InsertDriveFile />
  const isEmptyValue = !value.trim()
  return (
    <CustomModal
      maxWidth={555}
      open={isOpened}
      title={title}
      onClose={toggleModal}
      headerIcon={icon}
      renderActionButton={
        <ActionButton
          disabled={isEmptyValue}
          label={buttonLabel}
          onClick={onSubmit}
          loading={loading}
        />
      }
    >
      <div className={classes.body}>
        <CustomInput
          fullWidth
          value={value}
          label={type === 'folder' ? 'Folder Name' : 'File Name'}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    </CustomModal>
  )
}

export default memo(NewFolderModal)
