import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { getUploadDate, mapSortByKey } from '../../../../../utils/utils'
import useFilesController from '../../../../FilesTable/hooks/useFilesController'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import { MODALS_ID, NOTIFICATION } from '../../../../../utils/consts'
import { removeNotification } from '../../../../../actions/notifications'
import useToggleModal from '../../../../../hooks/useToggleModal'
import { createAIModel } from '../../../../../endpoints/admin'
import { MIN_TRAINING_DATASETS } from '../../../hooks/consts'

function useTrainAiFilesTable({ columns }) {
  const initialState = { name: '', description: '' }
  const [state, setState] = useState(initialState)

  const dispatch = useDispatch()
  const addNotification = useNotification()
  const { handleSelect, selected, isSelected, deselectAll, isBulkSelect } =
    useFilesController()
  const { isOpened: isResultModalOpened, toggle: toggleResultModal } =
    useToggleModal(MODALS_ID.TRAIN_AI_RESULT_MODAL)
  const { isOpened, toggle: toggleTrainModal } = useToggleModal(
    MODALS_ID.TRAIN_AI_MODAL
  )
  const { approvedDatasets: records, approvedDatasetsPages: pages } =
    useSelector(state => state.filesStore)
  const rows = useMemo(
    () =>
      records?.length > 0 &&
      records?.map(item => {
        const {
          id,
          name,
          tags,
          author,
          isolated_units,
          duration,
          created_at,
          upload_date,
          curation_id,
          spikesorting_name
        } = item
        const fields = {
          id,
          name,
          author,
          approvalStatus: true,
          duration,
          isolated_units:
            item.default_run?.isolated_single_units || isolated_units,
          tags,
          upload_date: getUploadDate(upload_date || created_at),
          curation_id,
          spikesorting_name
        }
        const activeColumns = Object.entries(fields).filter(([k, _]) =>
          columns.some(a => a.active && a.id === k)
        )
        let sortedByKey = mapSortByKey(activeColumns, columns, 'id')
        return {
          id,
          ...Object.assign(
            {},
            ...Array.from(sortedByKey || [], ([k, v]) => ({ [k]: v }))
          ),
          curation_id_passed: curation_id,
          name_passed: name
        }
      }),
    [columns, records]
  )

  const handleChange = useCallback(
    e => {
      const { name, value } = e.target
      setState({
        ...state,
        [name]: value
      })
    },
    [state]
  )

  const onSubmit = useCallback(async () => {
    try {
      const { name, description } = state
      const formattedName = name
        .replaceAll(/[@%&*!#$~+={}()|?^<>:,;]/g, '')
        .replaceAll(/[\s]/g, '_')
      const data = {
        name: formattedName,
        description,
        curations: selected.map(record => record.curation_id_passed)
      }
      const res = await createAIModel(data)
      if (res) {
        toggleResultModal()
        setState(initialState)
      }
    } catch (e) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [addNotification, selected, state, toggleResultModal])

  useEffect(() => {
    if (selected.length > 0 && selected.length < MIN_TRAINING_DATASETS)
      addNotification({
        persist: true,
        type: NOTIFICATION.WARNING,
        title: `Choose at least ${MIN_TRAINING_DATASETS} curation sessions to train AI model`
      })

    return () => {
      dispatch(removeNotification())
    }
  }, [selected.length, addNotification, dispatch])

  return {
    rows,
    pages,
    state,
    selected,
    isOpened,
    onSubmit,
    isSelected,
    isBulkSelect,
    isResultModalOpened,
    handleSelect,
    deselectAll,
    handleChange,
    toggleTrainModal,
    toggleResultModal
  }
}

export default useTrainAiFilesTable
