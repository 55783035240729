import useToggleModal from '../../../../../../../../../hooks/useToggleModal'
import {
  MODALS_ID,
  NOTIFICATION
} from '../../../../../../../../../utils/consts'
import { useCallback, useState } from 'react'
import { setCurationApprovalStatus } from '../../../../../../../../../endpoints/admin'
import useNotification from '../../../../../../../../SnackbarManager/hooks/useNotification'
import useGetCurations from '../../../../../../CurationsDrawer/hooks/useGetCurations'

function UseApproveCuration({ curationId }) {
  const addNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const { fetchSingleCuration } = useGetCurations(false)
  const { isOpened, toggle: toggleApproveCurationModal } = useToggleModal(
    MODALS_ID.APPROVE_CURATION_MODAL
  )

  const setApprovalStatus = useCallback(
    async option => {
      try {
        setLoading(true)
        const data = { id: curationId, isApproved: option }
        const result = await setCurationApprovalStatus(data)
        if (result) {
          if (option)
            addNotification({
              type: NOTIFICATION.SUCCESS,
              title: 'Curation Approved Successfully'
            })
          else {
            addNotification({
              type: NOTIFICATION.SUCCESS,
              title: 'Curation Status Successfully Changed'
            })
          }
          await fetchSingleCuration(curationId)
        }
        setLoading(false)
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
        setLoading(false)
      }
    },
    [addNotification, curationId, fetchSingleCuration]
  )

  const onApprove = useCallback(
    () => setApprovalStatus(true),
    [setApprovalStatus]
  )

  return {
    loading,
    onApprove,
    setApprovalStatus,
    isApproveModalOpened: isOpened,
    toggleApproveCurationModal
  }
}

export default UseApproveCuration
