import { useReducer } from 'react'

const initialState = {
  loading: false,
  data: [],
  error: null
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'INIT':
      return { ...state, loading: true }
    case 'SUCCESS':
      return { ...state, loading: false, data: payload }
    case 'ERROR':
      return { ...state, loading: false, error: payload }
    default:
      return state
  }
}

const useApiResult = request => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const startFetch = () => {
    dispatch({ type: 'INIT' })
    const fetch = async () => {
      try {
        const res = await request()
        dispatch({ type: 'SUCCESS', payload: res })
      } catch (err) {
        dispatch({ type: 'ERROR', payload: err })
      }
    }

    fetch()
  }

  return { state, startFetch }
}

export default useApiResult
