import React from 'react'
import Empty from '../../../../../imgs/Empty'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  noData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    cursor: 'pointer'
  },
  title: {
    width: '100%',
    textAlign: 'center',
    padding: '0 10px'
  }
}))

const EmptyWidget = ({
  message = 'Select units to display widgets',
  onBodyClick = () => null,
  children
}) => {
  const classes = useStyles()

  return (
    <div onClick={onBodyClick ?? null} className={classes.noData}>
      <Empty />
      <Typography noWrap title={message} className={classes.title}>
        {message}
      </Typography>
      {children}
    </div>
  )
}

export default EmptyWidget
