import React, { useMemo } from 'react'
import { Box, makeStyles, Typography, Button } from '@material-ui/core'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import useLogout from '../../hooks/useLogout'
import MenuDrawer from '../AppHeader/components/MenuDrawer'
import { MENU_DRAWER_WIDTH } from '../../utils/styles/CONSTANTS'

const useStyles = makeStyles(theme => ({
  root: ({ noCompany }) => ({
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: noCompany ? `${MENU_DRAWER_WIDTH + 30}px` : 30,
    paddingRight: 30,
    background: theme.palette.colors.backgrounds.files
  }),
  icon: {
    width: 200,
    height: 200,
    color: theme.palette.colors.neutral[5]
  },
  title: {
    fontWeight: 600,
    fontSize: 28,
    textAlign: 'center',
    color: theme.palette.colors.neutral.black,
    marginTop: 20,
    marginBottom: 10
  },
  text: {
    fontSize: 23,
    fontWeight: 400,
    marginTop: 5,
    marginBottom: 20,
    textAlign: 'center',
    color: theme.palette.colors.neutral[2]
  },
  button: {
    textTransform: 'none',
    fontWeight: 600
  }
}))

export default function NoActiveOrganization({
  hasActiveCompanies,
  selectedCompanyActive,
  currentCompany
}) {
  const noActive = Boolean(currentCompany) && !selectedCompanyActive
  const noCompanySelected = (noActive || !currentCompany) && hasActiveCompanies
  const classes = useStyles({ noCompany: noCompanySelected })
  const logout = useLogout()

  const commonMessage =
    'Please switch organization or contact the system administrator for further explanation'

  const content = [
    {
      condition: !hasActiveCompanies,
      title: 'You don’t have any active organization',
      text: 'Please contact the system administrator for further explanation'
    },
    {
      condition: noActive,
      title: `This organization is not active`,
      text: commonMessage
    },
    {
      condition: noCompanySelected,
      title: `You are no longer a member of this organization or it's not active`,
      text: commonMessage
    }
  ]

  const { title, text } = content.find(item => item.condition)

  return (
    <React.Fragment>
      {noCompanySelected && <MenuDrawer variant="persistent" isPersistent />}
      <Box className={classes.root}>
        <SentimentVeryDissatisfiedIcon
          className={classes.icon}
          fontSize="large"
        />
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.text}>{text}</Typography>
        <Button
          variant="contained"
          size="large"
          color="primary"
          className={classes.button}
          onClick={logout}
        >
          Logout
        </Button>
      </Box>
    </React.Fragment>
  )
}
