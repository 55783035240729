import { useCallback } from 'react'
import { object, string, ref } from 'yup'
import {
  MIN_5_CHARS,
  MIN_8_CHARS,
  NOTIFICATION,
  REQUIRED
} from '../../../utils/consts'
import { changePassword } from '../../../endpoints/admin'
import useNotification from '../../SnackbarManager/hooks/useNotification'

function useChangePassword() {
  const addNotification = useNotification()
  const initialValues = {
    old_password: '',
    new_password: '',
    confirm_password: ''
  }

  const onSubmit = useCallback(
    async (values, actions) => {
      try {
        const { old_password, confirm_password } = values
        const response = await changePassword({
          old_password,
          password: confirm_password
        })
        if (response === 'change_success') {
          actions.resetForm()
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Password successfully changed'
          })
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification]
  )

  const validation = object({
    old_password: string().required(REQUIRED).min(5, MIN_5_CHARS).trim(),
    new_password: string()
      .required(REQUIRED)
      .matches(
        new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'),
        'New Password should containt at least one number and one symbol (like !@#$%^)'
      )
      .min(8, MIN_8_CHARS)
      .trim(),
    confirm_password: string()
      .oneOf([ref('new_password')], 'Does not match with new password')
      .required(REQUIRED)
      .min(8, MIN_8_CHARS)
      .trim()
  })

  return { initialValues, onSubmit, validation }
}

export default useChangePassword
