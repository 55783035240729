import React from 'react'

export const svg128_4 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="76"
        height="3195"
        viewBox="0 0 76 3195"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M76 0H0V3179.36L10.5 3195L21 3179.36L76 0Z" fill={color} />
        <path
          d="M2 38C2 36.3431 3.34315 35 5 35H17C18.6569 35 20 36.3431 20 38V49.0769C20 50.7338 18.6569 52.0769 17 52.0769H5C3.34315 52.0769 2 50.7338 2 49.0769V38Z"
          fill="white"
        />
        <path
          d="M2 87.0769C2 85.4201 3.34315 84.0769 5 84.0769H17C18.6569 84.0769 20 85.4201 20 87.0769V98.1538C20 99.8107 18.6569 101.154 17 101.154H5C3.34315 101.154 2 99.8107 2 98.1538V87.0769Z"
          fill="white"
        />
        <path
          d="M2 136.154C2 134.497 3.34315 133.154 5 133.154H17C18.6569 133.154 20 134.497 20 136.154V147.231C20 148.888 18.6569 150.231 17 150.231H5C3.34315 150.231 2 148.888 2 147.231V136.154Z"
          fill="white"
        />
        <path
          d="M2 185.231C2 183.574 3.34315 182.231 5 182.231H17C18.6569 182.231 20 183.574 20 185.231V196.308C20 197.964 18.6569 199.308 17 199.308H5C3.34315 199.308 2 197.964 2 196.308V185.231Z"
          fill="white"
        />
        <path
          d="M2 234.308C2 232.651 3.34315 231.308 5 231.308H17C18.6569 231.308 20 232.651 20 234.308V245.385C20 247.041 18.6569 248.385 17 248.385H5C3.34315 248.385 2 247.041 2 245.385V234.308Z"
          fill="white"
        />
        <path
          d="M2 283.385C2 281.728 3.34315 280.385 5 280.385H17C18.6569 280.385 20 281.728 20 283.385V294.462C20 296.118 18.6569 297.462 17 297.462H5C3.34315 297.462 2 296.118 2 294.462V283.385Z"
          fill="white"
        />
        <path
          d="M2 332.462C2 330.805 3.34315 329.462 5 329.462H17C18.6569 329.462 20 330.805 20 332.462V343.538C20 345.195 18.6569 346.538 17 346.538H5C3.34315 346.538 2 345.195 2 343.538V332.462Z"
          fill="white"
        />
        <path
          d="M2 381.538C2 379.882 3.34315 378.538 5 378.538H17C18.6569 378.538 20 379.882 20 381.538V392.615C20 394.272 18.6569 395.615 17 395.615H5C3.34315 395.615 2 394.272 2 392.615V381.538Z"
          fill="white"
        />
        <path
          d="M2 430.615C2 428.959 3.34315 427.615 5 427.615H17C18.6569 427.615 20 428.959 20 430.615V441.692C20 443.349 18.6569 444.692 17 444.692H5C3.34315 444.692 2 443.349 2 441.692V430.615Z"
          fill="white"
        />
        <path
          d="M2 479.692C2 478.035 3.34315 476.692 5 476.692H17C18.6569 476.692 20 478.035 20 479.692V490.769C20 492.426 18.6569 493.769 17 493.769H5C3.34315 493.769 2 492.426 2 490.769V479.692Z"
          fill="white"
        />
        <path
          d="M2 528.769C2 527.112 3.34315 525.769 5 525.769H17C18.6569 525.769 20 527.112 20 528.769V539.846C20 541.503 18.6569 542.846 17 542.846H5C3.34315 542.846 2 541.503 2 539.846V528.769Z"
          fill="white"
        />
        <path
          d="M2 577.846C2 576.189 3.34315 574.846 5 574.846H17C18.6569 574.846 20 576.189 20 577.846V588.923C20 590.58 18.6569 591.923 17 591.923H5C3.34315 591.923 2 590.58 2 588.923V577.846Z"
          fill="white"
        />
        <path
          d="M2 626.923C2 625.266 3.34315 623.923 5 623.923H17C18.6569 623.923 20 625.266 20 626.923V638C20 639.657 18.6569 641 17 641H5C3.34315 641 2 639.657 2 638V626.923Z"
          fill="white"
        />
        <path
          d="M2 676C2 674.343 3.34315 673 5 673H17C18.6569 673 20 674.343 20 676V687.077C20 688.734 18.6569 690.077 17 690.077H5C3.34315 690.077 2 688.734 2 687.077V676Z"
          fill="white"
        />
        <path
          d="M2 725.077C2 723.42 3.34315 722.077 5 722.077H17C18.6569 722.077 20 723.42 20 725.077V736.154C20 737.811 18.6569 739.154 17 739.154H5C3.34315 739.154 2 737.811 2 736.154V725.077Z"
          fill="white"
        />
        <path
          d="M2 774.154C2 772.497 3.34315 771.154 5 771.154H17C18.6569 771.154 20 772.497 20 774.154V785.231C20 786.887 18.6569 788.231 17 788.231H5C3.34315 788.231 2 786.887 2 785.231V774.154Z"
          fill="white"
        />
        <path
          d="M2 823.231C2 821.574 3.34315 820.231 5 820.231H17C18.6569 820.231 20 821.574 20 823.231V834.308C20 835.964 18.6569 837.308 17 837.308H5C3.34315 837.308 2 835.964 2 834.308V823.231Z"
          fill="white"
        />
        <path
          d="M2 872.307C2 870.651 3.34315 869.307 5 869.307H17C18.6569 869.307 20 870.651 20 872.307V883.384C20 885.041 18.6569 886.384 17 886.384H5C3.34315 886.384 2 885.041 2 883.384V872.307Z"
          fill="white"
        />
        <path
          d="M2 921.384C2 919.728 3.34315 918.384 5 918.384H17C18.6569 918.384 20 919.728 20 921.384V932.461C20 934.118 18.6569 935.461 17 935.461H5C3.34315 935.461 2 934.118 2 932.461V921.384Z"
          fill="white"
        />
        <path
          d="M2 970.461C2 968.804 3.34315 967.461 5 967.461H17C18.6569 967.461 20 968.804 20 970.461V981.538C20 983.195 18.6569 984.538 17 984.538H5C3.34315 984.538 2 983.195 2 981.538V970.461Z"
          fill="white"
        />
        <path
          d="M2 1019.54C2 1017.88 3.34315 1016.54 5 1016.54H17C18.6569 1016.54 20 1017.88 20 1019.54V1030.62C20 1032.27 18.6569 1033.62 17 1033.62H5C3.34315 1033.62 2 1032.27 2 1030.62V1019.54Z"
          fill="white"
        />
        <path
          d="M2 1068.62C2 1066.96 3.34315 1065.62 5 1065.62H17C18.6569 1065.62 20 1066.96 20 1068.62V1079.69C20 1081.35 18.6569 1082.69 17 1082.69H5C3.34315 1082.69 2 1081.35 2 1079.69V1068.62Z"
          fill="white"
        />
        <path
          d="M2 1117.69C2 1116.04 3.34315 1114.69 5 1114.69H17C18.6569 1114.69 20 1116.04 20 1117.69V1128.77C20 1130.43 18.6569 1131.77 17 1131.77H5C3.34315 1131.77 2 1130.43 2 1128.77V1117.69Z"
          fill="white"
        />
        <path
          d="M2 1166.77C2 1165.11 3.34315 1163.77 5 1163.77H17C18.6569 1163.77 20 1165.11 20 1166.77V1177.85C20 1179.5 18.6569 1180.85 17 1180.85H5C3.34315 1180.85 2 1179.5 2 1177.85V1166.77Z"
          fill="white"
        />
        <path
          d="M2 1215.85C2 1214.19 3.34315 1212.85 5 1212.85H17C18.6569 1212.85 20 1214.19 20 1215.85V1226.92C20 1228.58 18.6569 1229.92 17 1229.92H5C3.34315 1229.92 2 1228.58 2 1226.92V1215.85Z"
          fill="white"
        />
        <path
          d="M2 1264.92C2 1263.27 3.34315 1261.92 5 1261.92H17C18.6569 1261.92 20 1263.27 20 1264.92V1276C20 1277.66 18.6569 1279 17 1279H5C3.34315 1279 2 1277.66 2 1276V1264.92Z"
          fill="white"
        />
        <path
          d="M2 1314C2 1312.34 3.34315 1311 5 1311H17C18.6569 1311 20 1312.34 20 1314V1325.08C20 1326.73 18.6569 1328.08 17 1328.08H5C3.34315 1328.08 2 1326.73 2 1325.08V1314Z"
          fill="white"
        />
        <path
          d="M2 1363.08C2 1361.42 3.34315 1360.08 5 1360.08H17C18.6569 1360.08 20 1361.42 20 1363.08V1374.15C20 1375.81 18.6569 1377.15 17 1377.15H5C3.34315 1377.15 2 1375.81 2 1374.15V1363.08Z"
          fill="white"
        />
        <path
          d="M2 1412.15C2 1410.5 3.34315 1409.15 5 1409.15H17C18.6569 1409.15 20 1410.5 20 1412.15V1423.23C20 1424.89 18.6569 1426.23 17 1426.23H5C3.34315 1426.23 2 1424.89 2 1423.23V1412.15Z"
          fill="white"
        />
        <path
          d="M2 1461.23C2 1459.57 3.34315 1458.23 5 1458.23H17C18.6569 1458.23 20 1459.57 20 1461.23V1472.31C20 1473.96 18.6569 1475.31 17 1475.31H5C3.34315 1475.31 2 1473.96 2 1472.31V1461.23Z"
          fill="white"
        />
        <path
          d="M2 1510.31C2 1508.65 3.34315 1507.31 5 1507.31H17C18.6569 1507.31 20 1508.65 20 1510.31V1521.38C20 1523.04 18.6569 1524.38 17 1524.38H5C3.34315 1524.38 2 1523.04 2 1521.38V1510.31Z"
          fill="white"
        />
        <path
          d="M2 1559.38C2 1557.73 3.34315 1556.38 5 1556.38H17C18.6569 1556.38 20 1557.73 20 1559.38V1570.46C20 1572.12 18.6569 1573.46 17 1573.46H5C3.34315 1573.46 2 1572.12 2 1570.46V1559.38Z"
          fill="white"
        />
        <path
          d="M2 1608.46C2 1606.8 3.34315 1605.46 5 1605.46H17C18.6569 1605.46 20 1606.8 20 1608.46V1619.54C20 1621.19 18.6569 1622.54 17 1622.54H5C3.34315 1622.54 2 1621.19 2 1619.54V1608.46Z"
          fill="white"
        />
        <path
          d="M2 1657.54C2 1655.88 3.34315 1654.54 5 1654.54H17C18.6569 1654.54 20 1655.88 20 1657.54V1668.61C20 1670.27 18.6569 1671.61 17 1671.61H5C3.34315 1671.61 2 1670.27 2 1668.61V1657.54Z"
          fill="white"
        />
        <path
          d="M2 1706.61C2 1704.96 3.34315 1703.61 5 1703.61H17C18.6569 1703.61 20 1704.96 20 1706.61V1717.69C20 1719.35 18.6569 1720.69 17 1720.69H5C3.34315 1720.69 2 1719.35 2 1717.69V1706.61Z"
          fill="white"
        />
        <path
          d="M2 1755.69C2 1754.03 3.34315 1752.69 5 1752.69H17C18.6569 1752.69 20 1754.03 20 1755.69V1766.77C20 1768.43 18.6569 1769.77 17 1769.77H5C3.34315 1769.77 2 1768.43 2 1766.77V1755.69Z"
          fill="white"
        />
        <path
          d="M2 1804.77C2 1803.11 3.34315 1801.77 5 1801.77H17C18.6569 1801.77 20 1803.11 20 1804.77V1815.85C20 1817.5 18.6569 1818.85 17 1818.85H5C3.34315 1818.85 2 1817.5 2 1815.85V1804.77Z"
          fill="white"
        />
        <path
          d="M2 1853.85C2 1852.19 3.34315 1850.85 5 1850.85H17C18.6569 1850.85 20 1852.19 20 1853.85V1864.92C20 1866.58 18.6569 1867.92 17 1867.92H5C3.34315 1867.92 2 1866.58 2 1864.92V1853.85Z"
          fill="white"
        />
        <path
          d="M2 1902.92C2 1901.27 3.34315 1899.92 5 1899.92H17C18.6569 1899.92 20 1901.27 20 1902.92V1914C20 1915.66 18.6569 1917 17 1917H5C3.34315 1917 2 1915.66 2 1914V1902.92Z"
          fill="white"
        />
        <path
          d="M2 1952C2 1950.34 3.34315 1949 5 1949H17C18.6569 1949 20 1950.34 20 1952V1963.08C20 1964.73 18.6569 1966.08 17 1966.08H5C3.34315 1966.08 2 1964.73 2 1963.08V1952Z"
          fill="white"
        />
        <path
          d="M2 2001.08C2 1999.42 3.34315 1998.08 5 1998.08H17C18.6569 1998.08 20 1999.42 20 2001.08V2012.15C20 2013.81 18.6569 2015.15 17 2015.15H5C3.34315 2015.15 2 2013.81 2 2012.15V2001.08Z"
          fill="white"
        />
        <path
          d="M2 2050.15C2 2048.5 3.34315 2047.15 5 2047.15H17C18.6569 2047.15 20 2048.5 20 2050.15V2061.23C20 2062.89 18.6569 2064.23 17 2064.23H5C3.34315 2064.23 2 2062.89 2 2061.23V2050.15Z"
          fill="white"
        />
        <path
          d="M2 2099.23C2 2097.57 3.34315 2096.23 5 2096.23H17C18.6569 2096.23 20 2097.57 20 2099.23V2110.31C20 2111.96 18.6569 2113.31 17 2113.31H5C3.34315 2113.31 2 2111.96 2 2110.31V2099.23Z"
          fill="white"
        />
        <path
          d="M2 2148.31C2 2146.65 3.34315 2145.31 5 2145.31H17C18.6569 2145.31 20 2146.65 20 2148.31V2159.38C20 2161.04 18.6569 2162.38 17 2162.38H5C3.34315 2162.38 2 2161.04 2 2159.38V2148.31Z"
          fill="white"
        />
        <path
          d="M2 2197.38C2 2195.73 3.34315 2194.38 5 2194.38H17C18.6569 2194.38 20 2195.73 20 2197.38V2208.46C20 2210.12 18.6569 2211.46 17 2211.46H5C3.34315 2211.46 2 2210.12 2 2208.46V2197.38Z"
          fill="white"
        />
        <path
          d="M2 2246.46C2 2244.8 3.34315 2243.46 5 2243.46H17C18.6569 2243.46 20 2244.8 20 2246.46V2257.54C20 2259.19 18.6569 2260.54 17 2260.54H5C3.34315 2260.54 2 2259.19 2 2257.54V2246.46Z"
          fill="white"
        />
        <path
          d="M2 2295.54C2 2293.88 3.34315 2292.54 5 2292.54H17C18.6569 2292.54 20 2293.88 20 2295.54V2306.61C20 2308.27 18.6569 2309.61 17 2309.61H5C3.34315 2309.61 2 2308.27 2 2306.61V2295.54Z"
          fill="white"
        />
        <path
          d="M2 2344.61C2 2342.96 3.34315 2341.61 5 2341.61H17C18.6569 2341.61 20 2342.96 20 2344.61V2355.69C20 2357.35 18.6569 2358.69 17 2358.69H5C3.34315 2358.69 2 2357.35 2 2355.69V2344.61Z"
          fill="white"
        />
        <path
          d="M2 2393.69C2 2392.03 3.34315 2390.69 5 2390.69H17C18.6569 2390.69 20 2392.03 20 2393.69V2404.77C20 2406.43 18.6569 2407.77 17 2407.77H5C3.34315 2407.77 2 2406.43 2 2404.77V2393.69Z"
          fill="white"
        />
        <path
          d="M2 2442.77C2 2441.11 3.34315 2439.77 5 2439.77H17C18.6569 2439.77 20 2441.11 20 2442.77V2453.85C20 2455.5 18.6569 2456.85 17 2456.85H5C3.34315 2456.85 2 2455.5 2 2453.85V2442.77Z"
          fill="white"
        />
        <path
          d="M2 2491.85C2 2490.19 3.34315 2488.85 5 2488.85H17C18.6569 2488.85 20 2490.19 20 2491.85V2502.92C20 2504.58 18.6569 2505.92 17 2505.92H5C3.34315 2505.92 2 2504.58 2 2502.92V2491.85Z"
          fill="white"
        />
        <path
          d="M2 2540.92C2 2539.27 3.34315 2537.92 5 2537.92H17C18.6569 2537.92 20 2539.27 20 2540.92V2552C20 2553.66 18.6569 2555 17 2555H5C3.34315 2555 2 2553.66 2 2552V2540.92Z"
          fill="white"
        />
        <path
          d="M2 2590C2 2588.34 3.34315 2587 5 2587H17C18.6569 2587 20 2588.34 20 2590V2601.08C20 2602.73 18.6569 2604.08 17 2604.08H5C3.34315 2604.08 2 2602.73 2 2601.08V2590Z"
          fill="white"
        />
        <path
          d="M2 2639.08C2 2637.42 3.34315 2636.08 5 2636.08H17C18.6569 2636.08 20 2637.42 20 2639.08V2650.15C20 2651.81 18.6569 2653.15 17 2653.15H5C3.34315 2653.15 2 2651.81 2 2650.15V2639.08Z"
          fill="white"
        />
        <path
          d="M2 2688.15C2 2686.5 3.34315 2685.15 5 2685.15H17C18.6569 2685.15 20 2686.5 20 2688.15V2699.23C20 2700.89 18.6569 2702.23 17 2702.23H5C3.34315 2702.23 2 2700.89 2 2699.23V2688.15Z"
          fill="white"
        />
        <path
          d="M2 2737.23C2 2735.57 3.34315 2734.23 5 2734.23H17C18.6569 2734.23 20 2735.57 20 2737.23V2748.31C20 2749.96 18.6569 2751.31 17 2751.31H5C3.34315 2751.31 2 2749.96 2 2748.31V2737.23Z"
          fill="white"
        />
        <path
          d="M2 2786.31C2 2784.65 3.34315 2783.31 5 2783.31H17C18.6569 2783.31 20 2784.65 20 2786.31V2797.38C20 2799.04 18.6569 2800.38 17 2800.38H5C3.34315 2800.38 2 2799.04 2 2797.38V2786.31Z"
          fill="white"
        />
        <path
          d="M2 2835.38C2 2833.73 3.34315 2832.38 5 2832.38H17C18.6569 2832.38 20 2833.73 20 2835.38V2846.46C20 2848.12 18.6569 2849.46 17 2849.46H5C3.34315 2849.46 2 2848.12 2 2846.46V2835.38Z"
          fill="white"
        />
        <path
          d="M2 2884.46C2 2882.8 3.34315 2881.46 5 2881.46H17C18.6569 2881.46 20 2882.8 20 2884.46V2895.54C20 2897.19 18.6569 2898.54 17 2898.54H5C3.34315 2898.54 2 2897.19 2 2895.54V2884.46Z"
          fill="white"
        />
        <path
          d="M2 2933.54C2 2931.88 3.34315 2930.54 5 2930.54H17C18.6569 2930.54 20 2931.88 20 2933.54V2944.61C20 2946.27 18.6569 2947.61 17 2947.61H5C3.34315 2947.61 2 2946.27 2 2944.61V2933.54Z"
          fill="white"
        />
        <path
          d="M2 2982.61C2 2980.96 3.34315 2979.61 5 2979.61H17C18.6569 2979.61 20 2980.96 20 2982.61V2993.69C20 2995.35 18.6569 2996.69 17 2996.69H5C3.34315 2996.69 2 2995.35 2 2993.69V2982.61Z"
          fill="white"
        />
        <path
          d="M2 3031.69C2 3030.03 3.34315 3028.69 5 3028.69H17C18.6569 3028.69 20 3030.03 20 3031.69V3042.77C20 3044.43 18.6569 3045.77 17 3045.77H5C3.34315 3045.77 2 3044.43 2 3042.77V3031.69Z"
          fill="white"
        />
        <path
          d="M2 3080.77C2 3079.11 3.34315 3077.77 5 3077.77H17C18.6569 3077.77 20 3079.11 20 3080.77V3091.85C20 3093.5 18.6569 3094.85 17 3094.85H5C3.34315 3094.85 2 3093.5 2 3091.85V3080.77Z"
          fill="white"
        />
        <path
          d="M2 3129.85C2 3128.19 3.34315 3126.85 5 3126.85H17C18.6569 3126.85 20 3128.19 20 3129.85V3140.92C20 3142.58 18.6569 3143.92 17 3143.92H5C3.34315 3143.92 2 3142.58 2 3140.92V3129.85Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="76"
      height="3195"
      viewBox="0 0 76 3195"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M76 0H0V3179.36L10.5 3195L21 3179.36L76 0Z" fill={color} />
    </svg>
  )
}
