import {
  EDIT_EXPERIMENTS_INPUT,
  STORE_EXPERIMENTS_INPUT,
  SET_EXP_INPUT_TO_EDIT
} from '../actions/experimentsInput'

const initialState = {
  uploads: [],
  spikesortings: [],
  editInput: null
}

const experimentsInputStore = (state = initialState, { type, payload }) => {
  switch (type) {
    case STORE_EXPERIMENTS_INPUT: {
      const { key, data } = payload || {}
      return { ...state, [key]: data }
    }

    case SET_EXP_INPUT_TO_EDIT: {
      return { ...state, editInput: payload }
    }

    case EDIT_EXPERIMENTS_INPUT: {
      const { key, data } = payload || {}
      const { id } = data || {}
      const inputsData = state[key]
      const allItems = inputsData?.data
      const index = allItems.findIndex(item => item.id === id)
      const updated = [
        ...allItems.slice(0, index),
        data,
        ...allItems.slice(index + 1)
      ]

      return {
        ...state,
        [key]: { ...inputsData, data: updated },
        editInput: null
      }
    }

    default:
      return state
  }
}

export default experimentsInputStore
