import React, { useCallback, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import CustomModal from '../../../CustomModal'
import { makeStyles, Tooltip } from '@material-ui/core'
import { Error, ErrorOutline } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'
import AppleIcon from '../../../Icons/AppleIcon'
import ActionButton from '../../../ActionButton'
import WindowsIcon from '../../../Icons/WindowsIcon'
import LinuxIcon from '../../../Icons/LinuxIcon'
import cn from 'classnames'
import CustomTabs from '../../../CustomTabs/CustomTabs'

const useStyles = makeStyles(theme => ({
  body: {
    padding: '25px 0',
    overflow: 'hidden'
  },
  indicator: {
    height: 3
  },
  header: {
    maxWidth: 640,
    margin: '0 auto 25px'
  },
  title: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 10,
    lineHeight: '28px',
    textAlign: 'center',
    color: theme.palette.colors.neutral.black
  },
  cards: {
    width: `calc(100% + 50px)`,
    margin: '0 -24px',
    '& .MuiGrid-item': {
      padding: '0 25px'
    }
  },
  icon: {
    display: 'block',
    margin: '0 auto'
  },
  appleIcon: {
    '& path': {
      fill: theme.palette.colors.neutral.black
    }
  },
  downloadBtn: {
    margin: '40px 0 0'
  },
  note: {
    fontSize: 13,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black,
    '& a': {
      textDecoration: 'none',
      color: theme.palette.colors.darkMain
    }
  },
  tooltipRef: {
    fontSize: 16,
    display: 'flex',
    marginBottom: 40,
    cursor: 'pointer',
    lineHeight: '24px',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral['2'],
      theme.palette.colors.neutral['8']
    ),
    '& svg': {
      marginRight: 8
    }
  },
  tooltip: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: theme.palette.colors.white,
    border: `1px solid ${theme.palette.colors.neutral['5']}`
  },
  tooltipArrow: {
    fontSize: 22,
    '&::before': {
      backgroundColor: theme.palette.colors.white,
      border: `1px solid ${theme.palette.colors.neutral['5']}`
    }
  },
  text: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.colors.neutral['2']
  }
}))

const DownloadAppModal = ({ isOpened, toggleModal }) => {
  const classes = useStyles()

  const tabs = [
    {
      key: 0,
      label: 'Automated pipeline and spikesorting',
      content: <Body isV2 />
    },
    {
      key: 1,
      label: 'Manual single file upload',
      content: <Body />
    }
  ]

  const [selectedTab, setTab] = useState(0)

  const handleChangeTabs = useCallback((event, newValue) => {
    setTab(newValue)
  }, [])

  return (
    <CustomModal
      maxWidth={865}
      open={isOpened}
      withFooter={false}
      title="Download App"
      onClose={toggleModal}
      headerIcon={<Error />}
      id="download-app-modal"
    >
      <CustomTabs
        tabs={tabs}
        selectedTab={selectedTab}
        handleChangeTabs={handleChangeTabs}
        customClasses={{
          indicator: classes.indicator,
          header: classes.header
        }}
      />
    </CustomModal>
  )
}

const Body = ({ disabled, isV2 }) => {
  const classes = useStyles()

  const tooltip = (
    <div className={classes.tooltipRoot}>
      <Typography className={classes.text}>MacOS</Typography>
      <Typography className={classes.text}>- 10.10 (Yosemite) +</Typography>
      <Typography className={classes.text}>Windows</Typography>
      <Typography className={classes.text}>- 7 , 8 , 10</Typography>
      <Typography className={classes.text}>Linux</Typography>
      <Typography className={classes.text}>- Ubuntu 14.04 +</Typography>
      <Typography className={classes.text}>- Debian 8 +</Typography>
    </div>
  )

  const s3url =
    process.env.REACT_APP_ENV === 'production'
      ? 'https://download-application.s3.amazonaws.com/production/'
      : 'https://download-application.s3.amazonaws.com/staging/'

  const url = `${s3url}${isV2 ? 'v2/' : ''}`

  const commingSoon = 'Coming soon'
  const isAuto = isV2 ? "_auto" : "_manual";

  return (
    <div className={classes.body}>
      <Typography className={classes.title}>
        Download the desktop app
      </Typography>
      <Tooltip
        title={tooltip}
        arrow
        classes={{
          tooltip: classes.tooltip,
          arrow: classes.tooltipArrow
        }}
      >
        <Typography className={classes.tooltipRef}>
          <ErrorOutline /> Check your version
        </Typography>
      </Tooltip>
      <Grid container className={classes.cards}>
        <Grid item sm={4}>
          <AppleIcon className={cn(classes.icon, classes.appleIcon)} />
          <ActionButton
            disabled={disabled}
            label={disabled ? commingSoon : 'Download for MacOS 10.10+'}
            className={classes.downloadBtn}
            href={`${url}dbcloud_mac${isAuto}.dmg`}
            component="a"
            target="_blank"
          />
        </Grid>
        <Grid item sm={4}>
          <WindowsIcon className={classes.icon} />
          <ActionButton
            disabled={disabled}
            label={disabled ? commingSoon : 'Download for Windows 7,8,10'}
            className={classes.downloadBtn}
            href={`${url}dbcloud_win${isAuto}.exe`}
            component="a"
            target="_blank"
          />
        </Grid>
        <Grid item sm={4}>
          <LinuxIcon className={classes.icon} />
          <ActionButton
            disabled={disabled}
            label={disabled ? commingSoon : 'Download for Linux [v.*]'}
            className={classes.downloadBtn}
            href={`${url}dbcloud_linux${isAuto}.deb`}
            component="a"
            target="_blank"
          />
        </Grid>
      </Grid>

      {/*<Typography className={classes.note}>*/}
      {/*  By downloading Labbox, you agree to its{' '}*/}
      {/*  <a href="/">license and privacy statement.</a>*/}
      {/*</Typography>*/}
    </div>
  )
}

export default DownloadAppModal
