export const ADD_MODAL = 'ADD_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const addModal = modal => ({
  type: ADD_MODAL,
  payload: modal
})

export const closeModal = modal => ({
  type: CLOSE_MODAL,
  payload: modal
})
