import React, { memo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ApproveCurationButton from '../ApproveCurationButton'
import EditApproveDropdown from '../EditApproveDropdown'
import ApproveTag from '../ApproveTag'

const useStyles = makeStyles(theme => ({
  buttonRoot: ({ large }) => ({
    height: large ? 20 : 16,
    padding: large ? 8 : 4,
    fontSize: large ? 12 : 8,
    fontWeight: 600,
    marginRight: 4,
    borderRadius: 4,
    color: 'white',
    lineHeight: '16px',
    alignItems: 'center',
    textTransform: 'none',
    display: 'inline-flex',
    verticalAlign: 'middle',
    justifyContent: 'center',
    backgroundColor: '#828A9F',
    '& p': {
      color: 'white',
      fontSize: large ? 12 : 8,
      fontWeight: 600,
      lineHeight: '16px'
    },
    '& svg': { color: 'white' },
    '&:hover': { backgroundColor: '#828A9F' },
    '&.Mui-disabled': {
      cursor: 'pointer',
      pointerEvents: 'auto',
      color: theme.palette.colors.customColor(
        theme.palette.colors.neutral[6],
        theme.palette.colors.neutral[4]
      ),
      backgroundColor: theme.palette.colors.customColor(
        theme.palette.colors.neutral[8],
        theme.palette.colors.neutral[2]
      ),
      '&:hover': {
        backgroundColor: theme.palette.colors.customColor(
          theme.palette.colors.neutral[8],
          theme.palette.colors.neutral[2]
        )
      }
    }
  }),
  approved: () => ({
    color: '#21B784',
    backgroundColor: '#D6FCDA',
    '& p': { color: '#21B784' },
    '& svg': { color: '#A5ABB3' },
    '&:hover': { backgroundColor: '#D6FCDA' }
  }),
  done: {
    color: theme.palette.secondary[100]
  },
  dropdown: ({ large }) => ({
    height: large ? 20 : 16,
    marginRight: 4,
    verticalAlign: 'middle',
    display: 'inline-flex'
  }),
  label: {
    margin: 0,
    fontSize: 14,
    fontWeight: 400,
    paddingLeft: 8,
    lineHeight: '16px'
  }
}))

const ApproveComponent = ({
  large,
  isAuthor,
  isApproved,
  curationId,
  uncuratedUnits,
  showNotification
}) => {
  const classes = useStyles({ large })
  if (!isAuthor)
    return (
      <ApproveTag
        classes={classes}
        curationId={curationId}
        isApproved={isApproved}
        showNotification={showNotification}
      />
    )
  if (isApproved === null)
    return (
      <ApproveCurationButton
        classes={classes}
        uncuratedUnits={uncuratedUnits}
        curationId={curationId}
      />
    )
  return (
    <EditApproveDropdown
      classes={classes}
      isApproved={isApproved}
      curationId={curationId}
    />
  )
}

export default memo(ApproveComponent)
