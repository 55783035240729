import { useCallback } from 'react'
import { MAIN_APPBAR_HEIGHT } from '../../../utils/styles'
import { useParams } from 'react-router-dom'
import { ROUTES } from '../../../utils/consts'
import { setTimeSeriesChildWindow } from '../../../components/TimeseriesView/NewLeftPanel/redux/actions'
import { useDispatch } from 'react-redux'
import { useLocalStorage } from 'usehooks-ts'

const useNewTimeseriesWindow = () => {
  const params = useParams()
  const recordingId = params.recordingId

  const dispatch = useDispatch()
  const setChildWindow = useCallback(
    (range, window, type) =>
      dispatch(setTimeSeriesChildWindow({ range, window, type })),
    [dispatch]
  )

  const [_, storeWindow] = useLocalStorage(`child-windows-${recordingId}`)

  const handleOpenNewWindow = useCallback(
    range => {
      const screenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screenX
      const screenTop =
        window.screenTop !== undefined ? window.screenTop : window.screenY

      let newWindow = window.open(
        `${window.location.origin}${ROUTES.TIMESERIES_CHILD.replace(
          ':recordingId',
          recordingId
        )}?range=${range.replaceAll(' ', '')}`,
        '_blank',
        `left=${screenLeft + window.outerWidth},top=${
          screenTop + MAIN_APPBAR_HEIGHT
        },width=${1},height=${1},location=yes,status=no,popup=yes`
      )

      setChildWindow(range, newWindow, 'add')
    },
    [recordingId, setChildWindow, storeWindow]
  )

  return { handleOpenNewWindow }
}

export default useNewTimeseriesWindow
