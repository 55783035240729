import React, { createContext, useContext, useState } from 'react'

const DragCtx = createContext({
  source: null,
  target: null
})

export const DragProvider = ({ children }) => {
  const [source, setSource] = useState(null)
  const [destination, setDestination] = useState(null)

  return (
    <DragCtx.Provider
      value={{
        source,
        setSource,
        destination,
        setDestination
      }}
    >
      {children}
    </DragCtx.Provider>
  )
}

export const useDrag = () => useContext(DragCtx)
