import React, { useCallback, useMemo, useRef } from 'react'
import { lighten } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import Tag from './Tag'
import CustomInput from '../FormComponents/CustomInput'
import Typography from '@material-ui/core/Typography'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: ({ isPreview = false }) => ({
    margin: '0 -5px',
    overflow: 'auto',
    paddingTop: !isPreview ? 24 : 0,
    borderTop: !isPreview
      ? `1px solid ${theme.palette.colors.neutral['7']}`
      : 'none'
  }),
  addIconButton: {
    padding: 0
  },
  addIcon: {
    fill: lighten(theme.palette.primary.main, 0.6),
    fontSize: 24
  },
  noData: {
    fontSize: 14,
    margin: '10px 0',
    opacity: 0.7,
    color: theme.palette.colors.neutral.black
  }
}))

const CustomTag = ({
  classes: overrideClasses = {},
  tags,
  textField,
  textFieldProps,
  updateTags = () => null,
  mode = 'edit',
  colorAccent = 6,
  label = 'Tag',
  required = true
}) => {
  const isPreview = mode === 'preview'
  const classes = useStyles({ isPreview })
  const inputRef = useRef(null)

  const handleDelete = useCallback(
    id => updateTags(tags.filter(tag => tag.id !== id)),
    [tags, updateTags]
  )

  const addTag = useCallback(
    value => {
      if (!value) return
      updateTags(tags.concat({ id: tags.length + 1, label: value }))

      if (inputRef?.current) {
        inputRef.current.value = ''
      }
    },
    [tags, updateTags]
  )

  const onAddTag = useCallback(
    e => {
      const { key } = e
      if (key === 'Enter') {
        addTag(inputRef.current?.value ?? '')
      }
    },
    [addTag]
  )

  const textInputProps = useMemo(
    () => ({
      endAdornment: (
        <IconButton
          className={classes.addIconButton}
          onClick={() => addTag(inputRef?.current?.value ?? '')}
        >
          <AddOutlinedIcon className={classes.addIcon} />
        </IconButton>
      ),
      inputRef: inputRef,
      label: label,
      onKeyDown: onAddTag,
      fullWidth: true,
      required,
      ...textFieldProps
    }),
    [
      label,
      required,
      addTag,
      classes.addIcon,
      classes.addIconButton,
      onAddTag,
      textFieldProps
    ]
  )

  const Input = useMemo(
    () =>
      textField
        ? React.cloneElement(textField, textInputProps)
        : React.cloneElement(CustomInput, textInputProps),
    [textField, textInputProps]
  )

  return (
    <Grid className={overrideClasses.container}>
      {!isPreview && <Input.type {...Input.props} />}
      {tags && tags.length > 0 ? (
        <div className={cn(classes.root, overrideClasses.root)}>
          {tags.map((tag, i) => (
            <Tag
              key={i}
              onDelete={!isPreview ? () => handleDelete(tag.id) : null}
              tag={tag}
              color={colorAccent}
            />
          ))}
        </div>
      ) : (
        <Typography className={classes.noData}>There is no data</Typography>
      )}
    </Grid>
  )
}

export default CustomTag
