import { useEffect } from 'react'

export const useDarkModeEffect = (element, renderPlot, darkMode) => {
  useEffect(() => {
    if (element) {
      try {
        renderPlot.restyle(element, { darkMode })
      } catch (err) {
        renderPlot.render(element, [])
      }
    }
  }, [darkMode, element])
}
