import React from 'react'

export const svg64_6 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="76"
        height="3000"
        viewBox="0 0 76 3000"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2912.16V0H76V521.449L38.9744 2867.97L19.4872 3000L0 2912.16Z"
          fill={color}
        />
        <path
          d="M3 70C3 68.3431 4.34315 67 6 67H18C19.6569 67 21 68.3431 21 70V81.0769C21 82.7338 19.6569 84.0769 18 84.0769H6C4.34315 84.0769 3 82.7338 3 81.0769V70Z"
          fill="white"
        />
        <path
          d="M3 167.077C3 165.42 4.34315 164.077 6 164.077H18C19.6569 164.077 21 165.42 21 167.077V178.154C21 179.811 19.6569 181.154 18 181.154H6C4.34315 181.154 3 179.811 3 178.154V167.077Z"
          fill="white"
        />
        <path
          d="M3 264.154C3 262.497 4.34315 261.154 6 261.154H18C19.6569 261.154 21 262.497 21 264.154V275.231C21 276.888 19.6569 278.231 18 278.231H6C4.34315 278.231 3 276.888 3 275.231V264.154Z"
          fill="white"
        />
        <path
          d="M3 361.231C3 359.574 4.34315 358.231 6 358.231H18C19.6569 358.231 21 359.574 21 361.231V372.308C21 373.964 19.6569 375.308 18 375.308H6C4.34315 375.308 3 373.964 3 372.308V361.231Z"
          fill="white"
        />
        <path
          d="M3 458.308C3 456.651 4.34315 455.308 6 455.308H18C19.6569 455.308 21 456.651 21 458.308V469.385C21 471.041 19.6569 472.385 18 472.385H6C4.34315 472.385 3 471.041 3 469.385V458.308Z"
          fill="white"
        />
        <path
          d="M3 555.385C3 553.728 4.34315 552.385 6 552.385H18C19.6569 552.385 21 553.728 21 555.385V566.461C21 568.118 19.6569 569.461 18 569.461H6C4.34315 569.461 3 568.118 3 566.461V555.385Z"
          fill="white"
        />
        <path
          d="M3 652.461C3 650.805 4.34315 649.461 6 649.461H18C19.6569 649.461 21 650.805 21 652.461V663.538C21 665.195 19.6569 666.538 18 666.538H6C4.34315 666.538 3 665.195 3 663.538V652.461Z"
          fill="white"
        />
        <path
          d="M3 749.538C3 747.881 4.34315 746.538 6 746.538H18C19.6569 746.538 21 747.881 21 749.538V760.615C21 762.272 19.6569 763.615 18 763.615H6C4.34315 763.615 3 762.272 3 760.615V749.538Z"
          fill="white"
        />
        <path
          d="M3 846.615C3 844.958 4.34315 843.615 6 843.615H18C19.6569 843.615 21 844.958 21 846.615V857.692C21 859.349 19.6569 860.692 18 860.692H6C4.34315 860.692 3 859.349 3 857.692V846.615Z"
          fill="white"
        />
        <path
          d="M3 943.692C3 942.036 4.34315 940.692 6 940.692H18C19.6569 940.692 21 942.036 21 943.692V954.769C21 956.426 19.6569 957.769 18 957.769H6C4.34315 957.769 3 956.426 3 954.769V943.692Z"
          fill="white"
        />
        <path
          d="M3 1040.77C3 1039.11 4.34315 1037.77 6 1037.77H18C19.6569 1037.77 21 1039.11 21 1040.77V1051.85C21 1053.5 19.6569 1054.85 18 1054.85H6C4.34315 1054.85 3 1053.5 3 1051.85V1040.77Z"
          fill="white"
        />
        <path
          d="M3 1137.85C3 1136.19 4.34315 1134.85 6 1134.85H18C19.6569 1134.85 21 1136.19 21 1137.85V1148.92C21 1150.58 19.6569 1151.92 18 1151.92H6C4.34315 1151.92 3 1150.58 3 1148.92V1137.85Z"
          fill="white"
        />
        <path
          d="M3 1234.92C3 1233.27 4.34315 1231.92 6 1231.92H18C19.6569 1231.92 21 1233.27 21 1234.92V1246C21 1247.66 19.6569 1249 18 1249H6C4.34315 1249 3 1247.66 3 1246V1234.92Z"
          fill="white"
        />
        <path
          d="M3 1332C3 1330.34 4.34315 1329 6 1329H18C19.6569 1329 21 1330.34 21 1332V1343.08C21 1344.73 19.6569 1346.08 18 1346.08H6C4.34315 1346.08 3 1344.73 3 1343.08V1332Z"
          fill="white"
        />
        <path
          d="M3 1429.08C3 1427.42 4.34315 1426.08 6 1426.08H18C19.6569 1426.08 21 1427.42 21 1429.08V1440.15C21 1441.81 19.6569 1443.15 18 1443.15H6C4.34315 1443.15 3 1441.81 3 1440.15V1429.08Z"
          fill="white"
        />
        <path
          d="M3 1526.15C3 1524.5 4.34315 1523.15 6 1523.15H18C19.6569 1523.15 21 1524.5 21 1526.15V1537.23C21 1538.89 19.6569 1540.23 18 1540.23H6C4.34315 1540.23 3 1538.89 3 1537.23V1526.15Z"
          fill="white"
        />
        <path
          d="M3 1623.23C3 1621.57 4.34315 1620.23 6 1620.23H18C19.6569 1620.23 21 1621.57 21 1623.23V1634.31C21 1635.96 19.6569 1637.31 18 1637.31H6C4.34315 1637.31 3 1635.96 3 1634.31V1623.23Z"
          fill="white"
        />
        <path
          d="M3 1720.31C3 1718.65 4.34315 1717.31 6 1717.31H18C19.6569 1717.31 21 1718.65 21 1720.31V1731.38C21 1733.04 19.6569 1734.38 18 1734.38H6C4.34315 1734.38 3 1733.04 3 1731.38V1720.31Z"
          fill="white"
        />
        <path
          d="M3 1817.38C3 1815.73 4.34315 1814.38 6 1814.38H18C19.6569 1814.38 21 1815.73 21 1817.38V1828.46C21 1830.12 19.6569 1831.46 18 1831.46H6C4.34315 1831.46 3 1830.12 3 1828.46V1817.38Z"
          fill="white"
        />
        <path
          d="M3 1914.46C3 1912.8 4.34315 1911.46 6 1911.46H18C19.6569 1911.46 21 1912.8 21 1914.46V1925.54C21 1927.2 19.6569 1928.54 18 1928.54H6C4.34315 1928.54 3 1927.2 3 1925.54V1914.46Z"
          fill="white"
        />
        <path
          d="M3 2011.54C3 2009.88 4.34315 2008.54 6 2008.54H18C19.6569 2008.54 21 2009.88 21 2011.54V2022.62C21 2024.27 19.6569 2025.62 18 2025.62H6C4.34315 2025.62 3 2024.27 3 2022.62V2011.54Z"
          fill="white"
        />
        <path
          d="M3 2108.62C3 2106.96 4.34315 2105.62 6 2105.62H18C19.6569 2105.62 21 2106.96 21 2108.62V2119.69C21 2121.35 19.6569 2122.69 18 2122.69H6C4.34315 2122.69 3 2121.35 3 2119.69V2108.62Z"
          fill="white"
        />
        <path
          d="M3 2205.69C3 2204.04 4.34315 2202.69 6 2202.69H18C19.6569 2202.69 21 2204.04 21 2205.69V2216.77C21 2218.43 19.6569 2219.77 18 2219.77H6C4.34315 2219.77 3 2218.43 3 2216.77V2205.69Z"
          fill="white"
        />
        <path
          d="M3 2302.77C3 2301.11 4.34315 2299.77 6 2299.77H18C19.6569 2299.77 21 2301.11 21 2302.77V2313.85C21 2315.5 19.6569 2316.85 18 2316.85H6C4.34315 2316.85 3 2315.5 3 2313.85V2302.77Z"
          fill="white"
        />
        <path
          d="M3 2399.85C3 2398.19 4.34315 2396.85 6 2396.85H18C19.6569 2396.85 21 2398.19 21 2399.85V2410.92C21 2412.58 19.6569 2413.92 18 2413.92H6C4.34315 2413.92 3 2412.58 3 2410.92V2399.85Z"
          fill="white"
        />
        <path
          d="M3 2496.92C3 2495.27 4.34315 2493.92 6 2493.92H18C19.6569 2493.92 21 2495.27 21 2496.92V2508C21 2509.66 19.6569 2511 18 2511H6C4.34315 2511 3 2509.66 3 2508V2496.92Z"
          fill="white"
        />
        <path
          d="M3 2594C3 2592.34 4.34315 2591 6 2591H18C19.6569 2591 21 2592.34 21 2594V2605.08C21 2606.73 19.6569 2608.08 18 2608.08H6C4.34315 2608.08 3 2606.73 3 2605.08V2594Z"
          fill="white"
        />
        <path
          d="M3 2691.08C3 2689.42 4.34315 2688.08 6 2688.08H18C19.6569 2688.08 21 2689.42 21 2691.08V2702.15C21 2703.81 19.6569 2705.15 18 2705.15H6C4.34315 2705.15 3 2703.81 3 2702.15V2691.08Z"
          fill="white"
        />
        <path
          d="M3 2788.15C3 2786.5 4.34315 2785.15 6 2785.15H18C19.6569 2785.15 21 2786.5 21 2788.15V2799.23C21 2800.89 19.6569 2802.23 18 2802.23H6C4.34315 2802.23 3 2800.89 3 2799.23V2788.15Z"
          fill="white"
        />
        <path
          d="M3 2885.23C3 2883.57 4.34315 2882.23 6 2882.23H18C19.6569 2882.23 21 2883.57 21 2885.23V2896.31C21 2897.96 19.6569 2899.31 18 2899.31H6C4.34315 2899.31 3 2897.96 3 2896.31V2885.23Z"
          fill="white"
        />
        <path
          d="M20 18C20 16.3431 21.3431 15 23 15H35C36.6569 15 38 16.3431 38 18V29.0769C38 30.7338 36.6569 32.0769 35 32.0769H23C21.3431 32.0769 20 30.7338 20 29.0769V18Z"
          fill="white"
        />
        <path
          d="M20 115.077C20 113.42 21.3431 112.077 23 112.077H35C36.6569 112.077 38 113.42 38 115.077V126.154C38 127.811 36.6569 129.154 35 129.154H23C21.3431 129.154 20 127.811 20 126.154V115.077Z"
          fill="white"
        />
        <path
          d="M20 212.154C20 210.497 21.3431 209.154 23 209.154H35C36.6569 209.154 38 210.497 38 212.154V223.231C38 224.888 36.6569 226.231 35 226.231H23C21.3431 226.231 20 224.888 20 223.231V212.154Z"
          fill="white"
        />
        <path
          d="M20 309.231C20 307.574 21.3431 306.231 23 306.231H35C36.6569 306.231 38 307.574 38 309.231V320.308C38 321.964 36.6569 323.308 35 323.308H23C21.3431 323.308 20 321.964 20 320.308V309.231Z"
          fill="white"
        />
        <path
          d="M20 406.308C20 404.651 21.3431 403.308 23 403.308H35C36.6569 403.308 38 404.651 38 406.308V417.385C38 419.041 36.6569 420.385 35 420.385H23C21.3431 420.385 20 419.041 20 417.385V406.308Z"
          fill="white"
        />
        <path
          d="M20 503.385C20 501.728 21.3431 500.385 23 500.385H35C36.6569 500.385 38 501.728 38 503.385V514.461C38 516.118 36.6569 517.461 35 517.461H23C21.3431 517.461 20 516.118 20 514.461V503.385Z"
          fill="white"
        />
        <path
          d="M20 600.461C20 598.805 21.3431 597.461 23 597.461H35C36.6569 597.461 38 598.805 38 600.461V611.538C38 613.195 36.6569 614.538 35 614.538H23C21.3431 614.538 20 613.195 20 611.538V600.461Z"
          fill="white"
        />
        <path
          d="M20 697.538C20 695.881 21.3431 694.538 23 694.538H35C36.6569 694.538 38 695.881 38 697.538V708.615C38 710.272 36.6569 711.615 35 711.615H23C21.3431 711.615 20 710.272 20 708.615V697.538Z"
          fill="white"
        />
        <path
          d="M20 794.615C20 792.958 21.3431 791.615 23 791.615H35C36.6569 791.615 38 792.958 38 794.615V805.692C38 807.349 36.6569 808.692 35 808.692H23C21.3431 808.692 20 807.349 20 805.692V794.615Z"
          fill="white"
        />
        <path
          d="M20 891.692C20 890.035 21.3431 888.692 23 888.692H35C36.6569 888.692 38 890.035 38 891.692V902.769C38 904.426 36.6569 905.769 35 905.769H23C21.3431 905.769 20 904.426 20 902.769V891.692Z"
          fill="white"
        />
        <path
          d="M20 988.769C20 987.112 21.3431 985.769 23 985.769H35C36.6569 985.769 38 987.112 38 988.769V999.846C38 1001.5 36.6569 1002.85 35 1002.85H23C21.3431 1002.85 20 1001.5 20 999.846V988.769Z"
          fill="white"
        />
        <path
          d="M20 1085.85C20 1084.19 21.3431 1082.85 23 1082.85H35C36.6569 1082.85 38 1084.19 38 1085.85V1096.92C38 1098.58 36.6569 1099.92 35 1099.92H23C21.3431 1099.92 20 1098.58 20 1096.92V1085.85Z"
          fill="white"
        />
        <path
          d="M20 1182.92C20 1181.27 21.3431 1179.92 23 1179.92H35C36.6569 1179.92 38 1181.27 38 1182.92V1194C38 1195.66 36.6569 1197 35 1197H23C21.3431 1197 20 1195.66 20 1194V1182.92Z"
          fill="white"
        />
        <path
          d="M20 1280C20 1278.34 21.3431 1277 23 1277H35C36.6569 1277 38 1278.34 38 1280V1291.08C38 1292.73 36.6569 1294.08 35 1294.08H23C21.3431 1294.08 20 1292.73 20 1291.08V1280Z"
          fill="white"
        />
        <path
          d="M20 1377.08C20 1375.42 21.3431 1374.08 23 1374.08H35C36.6569 1374.08 38 1375.42 38 1377.08V1388.15C38 1389.81 36.6569 1391.15 35 1391.15H23C21.3431 1391.15 20 1389.81 20 1388.15V1377.08Z"
          fill="white"
        />
        <path
          d="M20 1474.15C20 1472.5 21.3431 1471.15 23 1471.15H35C36.6569 1471.15 38 1472.5 38 1474.15V1485.23C38 1486.89 36.6569 1488.23 35 1488.23H23C21.3431 1488.23 20 1486.89 20 1485.23V1474.15Z"
          fill="white"
        />
        <path
          d="M20 1571.23C20 1569.57 21.3431 1568.23 23 1568.23H35C36.6569 1568.23 38 1569.57 38 1571.23V1582.31C38 1583.96 36.6569 1585.31 35 1585.31H23C21.3431 1585.31 20 1583.96 20 1582.31V1571.23Z"
          fill="white"
        />
        <path
          d="M20 1668.31C20 1666.65 21.3431 1665.31 23 1665.31H35C36.6569 1665.31 38 1666.65 38 1668.31V1679.38C38 1681.04 36.6569 1682.38 35 1682.38H23C21.3431 1682.38 20 1681.04 20 1679.38V1668.31Z"
          fill="white"
        />
        <path
          d="M20 1765.38C20 1763.73 21.3431 1762.38 23 1762.38H35C36.6569 1762.38 38 1763.73 38 1765.38V1776.46C38 1778.12 36.6569 1779.46 35 1779.46H23C21.3431 1779.46 20 1778.12 20 1776.46V1765.38Z"
          fill="white"
        />
        <path
          d="M20 1862.46C20 1860.8 21.3431 1859.46 23 1859.46H35C36.6569 1859.46 38 1860.8 38 1862.46V1873.54C38 1875.2 36.6569 1876.54 35 1876.54H23C21.3431 1876.54 20 1875.2 20 1873.54V1862.46Z"
          fill="white"
        />
        <path
          d="M20 1959.54C20 1957.88 21.3431 1956.54 23 1956.54H35C36.6569 1956.54 38 1957.88 38 1959.54V1970.62C38 1972.27 36.6569 1973.62 35 1973.62H23C21.3431 1973.62 20 1972.27 20 1970.62V1959.54Z"
          fill="white"
        />
        <path
          d="M20 2056.62C20 2054.96 21.3431 2053.62 23 2053.62H35C36.6569 2053.62 38 2054.96 38 2056.62V2067.69C38 2069.35 36.6569 2070.69 35 2070.69H23C21.3431 2070.69 20 2069.35 20 2067.69V2056.62Z"
          fill="white"
        />
        <path
          d="M20 2153.69C20 2152.04 21.3431 2150.69 23 2150.69H35C36.6569 2150.69 38 2152.04 38 2153.69V2164.77C38 2166.43 36.6569 2167.77 35 2167.77H23C21.3431 2167.77 20 2166.43 20 2164.77V2153.69Z"
          fill="white"
        />
        <path
          d="M20 2250.77C20 2249.11 21.3431 2247.77 23 2247.77H35C36.6569 2247.77 38 2249.11 38 2250.77V2261.85C38 2263.5 36.6569 2264.85 35 2264.85H23C21.3431 2264.85 20 2263.5 20 2261.85V2250.77Z"
          fill="white"
        />
        <path
          d="M20 2347.85C20 2346.19 21.3431 2344.85 23 2344.85H35C36.6569 2344.85 38 2346.19 38 2347.85V2358.92C38 2360.58 36.6569 2361.92 35 2361.92H23C21.3431 2361.92 20 2360.58 20 2358.92V2347.85Z"
          fill="white"
        />
        <path
          d="M20 2444.92C20 2443.27 21.3431 2441.92 23 2441.92H35C36.6569 2441.92 38 2443.27 38 2444.92V2456C38 2457.66 36.6569 2459 35 2459H23C21.3431 2459 20 2457.66 20 2456V2444.92Z"
          fill="white"
        />
        <path
          d="M20 2542C20 2540.34 21.3431 2539 23 2539H35C36.6569 2539 38 2540.34 38 2542V2553.08C38 2554.73 36.6569 2556.08 35 2556.08H23C21.3431 2556.08 20 2554.73 20 2553.08V2542Z"
          fill="white"
        />
        <path
          d="M20 2639.08C20 2637.42 21.3431 2636.08 23 2636.08H35C36.6569 2636.08 38 2637.42 38 2639.08V2650.15C38 2651.81 36.6569 2653.15 35 2653.15H23C21.3431 2653.15 20 2651.81 20 2650.15V2639.08Z"
          fill="white"
        />
        <path
          d="M20 2736.15C20 2734.5 21.3431 2733.15 23 2733.15H35C36.6569 2733.15 38 2734.5 38 2736.15V2747.23C38 2748.89 36.6569 2750.23 35 2750.23H23C21.3431 2750.23 20 2748.89 20 2747.23V2736.15Z"
          fill="white"
        />
        <path
          d="M20 2833.23C20 2831.57 21.3431 2830.23 23 2830.23H35C36.6569 2830.23 38 2831.57 38 2833.23V2844.31C38 2845.96 36.6569 2847.31 35 2847.31H23C21.3431 2847.31 20 2845.96 20 2844.31V2833.23Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="76"
      height="3000"
      viewBox="0 0 76 3000"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        d="M0 2912.16V0H76V521.449L38.9744 2867.97L19.4872 3000L0 2912.16Z"
        fill={color}
      />
    </svg>
  )
}
