import React from 'react'
import InfoPanelItem from '../../../InfoPanelItem'
import { Edit } from '@material-ui/icons'
import CardContainer from '../../../../../CardContainer'
import useEditData from '../../hooks/useEditData'
import EditDataModal from '../EditDataModal'

const RecordingData = () => {
  const { toggleModal, cards, ...props } = useEditData()

  return (
    <React.Fragment>
      <EditDataModal toggleModal={toggleModal} {...props} />
      <InfoPanelItem title="Data" Icon={<Edit />} onClick={toggleModal}>
        <CardContainer cards={cards} />
      </InfoPanelItem>
    </React.Fragment>
  )
}

export default RecordingData
