import React from 'react'
import { Grid, makeStyles, Typography, useTheme } from '@material-ui/core'
import useProbeUploadModal from './hooks/useProbeUploadModal'
import CustomModal from '../../../CustomModal'
import ActionButton from '../../../ActionButton'
import CustomInput from '../../../FormComponents/CustomInput'
import SelectMenu from '../../../SelectMenu'
import useProbeFiles from '../../../NewRecordingView/hooks/useProbeFiles'
import CustomDropzone from '../../../FormComponents/CustomDropzone'
import cn from 'classnames'
import { isEmpty } from 'lodash/fp'

const useStyles = makeStyles(theme => ({
  impedanceText: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 8,
    color: theme.palette.colors.neutral.contrast
  },
  inputBaseRoot: {
    '& label': {
      color: `${theme.palette.colors.neutral.contrast}!important`
    },
    '& > div': {
      '& fieldset': {
        borderColor: theme.palette.colors.neutral[7]
      }
    },
    '&:hover': {
      '& fieldset': {
        borderColor: theme.palette.colors.neutral[7]
      }
    }
  },
  inputBaseRootExtra: {
    opacity: 0.5
  },
  customInputStyle: {
    fontWeight: 400,
    color: theme.palette.colors.neutral.contrast
  },
  customInputDisabledStyle: {
    borderColor: theme.palette.colors.neutral[7],
    '& fieldset': {
      borderColor: `${theme.palette.colors.neutral[7]} !important`,
      backgroundColor: 'inherit !important',
      color: `${theme.palette.colors.neutral.contrast} !important`
    }
  }
}))

const ProbeUploadModal = () => {
  const classes = useStyles()
  const {
    isOpened,
    toggleModal,
    params,
    handleChange,
    handleFileValidation,
    isDisabled,
    impedanceFile,
    loading,
    onSubmit,
    isEditMode
  } = useProbeUploadModal()
  const theme = useTheme()
  const { serial, impedanceName, threshold, probe } = params

  return (
    <CustomModal
      open={isOpened}
      title={isEditMode ? 'Edit Probe' : 'Add Probe'}
      onClose={toggleModal}
      maxWidth={700}
      renderActionButton={
        <ActionButton
          label="Upload"
          disabled={isDisabled}
          loading={loading}
          onClick={onSubmit}
          customStyles={{
            backgroundColor: theme.palette.action.secondary
          }}
        />
      }
    >
      <Grid container spacing={3} className={classes.inputsRoot}>
        {isEditMode && !isEmpty(serial) && (
          <Grid item xs={12}>
            <CustomInput
              required
              type="text"
              name="serial_number"
              disabled
              value={serial}
              label="Serial number"
              inputBaseRootClass={cn(
                classes.inputBaseRoot,
                classes.inputBaseRootExtra
              )}
              customInputStyle={classes.customInputStyle}
              customInputDisabledStyle={classes.customInputDisabledStyle}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <SelectMenu
            onChangeValue={value => handleChange({ name: 'probe', value })}
            asyncData={useProbeFiles}
            value={probe}
            name="probe"
            textFieldProps={{
              variant: 'outlined',
              label: 'Probe Design',
              required: true,
              inputBaseRootClass: classes.inputBaseRoot,
              customInputStyle: classes.customInputStyle
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography className={classes.impedanceText}>
            Upload Impedance
          </Typography>
          <CustomDropzone
            accept={['.csv', '.txt']}
            onUpload={handleFileValidation}
            loading={false}
            file={impedanceFile ?? impedanceName}
            selectedText="Selected"
            isAlreadyUploaded={isEditMode && impedanceName && !impedanceFile}
          />
        </Grid>

        {!isEmpty(impedanceName) && (
          <Grid item xs={12}>
            <CustomInput
              required
              type="number"
              name="threshold"
              value={threshold}
              onChange={handleChange}
              label="Threshold (Mohm)"
              inputBaseRootClass={classes.inputBaseRoot}
              customInputStyle={classes.customInputStyle}
            />
          </Grid>
        )}
      </Grid>
    </CustomModal>
  )
}

export default ProbeUploadModal
