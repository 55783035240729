import React, { useState, useCallback, memo } from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Settings } from '@material-ui/icons'
import RangeAccordion from './components/RangeAccordion'
import CustomTooltip from '../../../../CustomTooltip'
import SettingsModal from './components/SettingsModal'

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: theme.palette.colors.darkMain,
    marginBottom: 8
  },
  headerText: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.mainColor,
      'white'
    )
  },
  bodyText: {
    color: theme.palette.colors.customColor(
      theme.palette.colors.neutral[3],
      theme.palette.colors.neutral[7]
    ),
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px'
  },
  section: {
    marginTop: 32
  },
  icon: ({ isOpened }) => ({
    height: 20,
    cursor: 'pointer',
    color: isOpened
      ? theme.palette.colors.darkMain
      : theme.palette.colors.neutral[5],
    '&:hover': {
      color: theme.palette.colors.darkMain
    }
  }),
  tooltip: {
    fontSize: 10,
    fontWeight: 600,
    width: 'fit-content',
    lineHeight: '16px',
    border: '0px',
    borderRadius: '8px',
    padding: '6px 12px',
    filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))'
  },
  tooltipArrow: {
    fontSize: 12,
    '&::before': {
      backgroundColor: theme.palette.colors.white,
      border: `0px`,
      filter: 'drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1))'
    }
  }
}))

const MultiScreenTab = () => {
  const [isOpened, setOpen] = useState(false)
  const toggleSettingsModal = useCallback(() => setOpen(prev => !prev), [])
  const classes = useStyles({ isOpened })

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.headerText}>Multi Screen</Typography>
        <CustomTooltip
          placement={'bottom'}
          _classes={classes}
          tooltip={'Edit the range of channels'}
        >
          <Settings className={classes.icon} onClick={toggleSettingsModal} />
        </CustomTooltip>
      </div>
      <Typography className={classes.bodyText}>
        View channels into multiple screens. Note the maximum amount is 128
        channels per screen.
      </Typography>
      <div className={classes.section}>
        <RangeAccordion title={'Neuronal'} isNeuronal />
        {/*<RangeAccordion title={'Non Neuronal'} />*/}
      </div>
      <SettingsModal isOpened={isOpened} handleClose={toggleSettingsModal} />
    </div>
  )
}

export default memo(MultiScreenTab)
