import React, { memo } from 'react'
import { Button, IconButton, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { KeyboardArrowUp } from '@material-ui/icons'
import cn from 'classnames'
import { propsAreEqual } from '../../../../../../../../../utils/utils'

const Section = ({
  title,
  models,
  search,
  classes,
  handleSelect,
  isOpen = false,
  setOpen = () => {}
}) => {
  return (
    <>
      <div className={classes.header}>
        <Typography className={classes.headerText}>{title}</Typography>
        <IconButton
          disableRipple
          className={classes.icon}
          onClick={() => setOpen(prev => !prev)}
        >
          {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </div>

      <div className={cn(classes.modelsRoot, { [classes.hidden]: !isOpen })}>
        {models?.map(model => {
          const { name } = model || {}
          if (!name?.toLowerCase().includes(search.toLowerCase())) return <></>
          return (
            <Button
              onClick={() => handleSelect(model)}
              className={classes.model}
            >
              {name}
            </Button>
          )
        })}
      </div>
    </>
  )
}

export default memo(Section, (...props) =>
  propsAreEqual(...props, ['title', 'search'])
)
