import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tabRoot: {
    flex: 1,
    overflowY: 'auto',
    width: '100%',
    height: '100%',
    marginTop: 25
  }
}))

function TabBox({ children, value, index, ...other }) {
  const classes = useStyles()
  if (value !== index) return null
  return (
    <div className={classes.tabRoot} {...other}>
      {children}
    </div>
  )
}

export default TabBox
