import React, { memo } from 'react'
import {
  Box,
  makeStyles,
  Typography,
  Checkbox,
  FormControlLabel,
  useTheme
} from '@material-ui/core'
import CustomModal from '../../../CustomModal'
import ActionButton from '../../../ActionButton'
import { InsertDriveFile } from '@material-ui/icons'
import { DATA_MANIPULATION_MODE } from '../../../../utils/consts'
import CustomInput from '../../../FormComponents/CustomInput/CustomInput'
import useDataManipulation from './hooks/useDataManipulation'

const useStyles = makeStyles(theme => ({
  description: {
    color: theme.palette.colors.neutral.black,
    '& span': {
      fontWeight: 600
    }
  },
  checkboxLabel: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  },

  file: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    fontSize: 16
  },
  fileIcon: {
    color: theme.palette.colors.accent[5],
    marginRight: 5
  },
  input: {
    marginTop: 20
  }
}))

function DataManipulationModal() {
  const theme = useTheme()
  const classes = useStyles()
  const {
    onSubmit,
    loading,
    fileName,
    toggleModal,
    isOpened,
    direction,
    mode,
    edit,
    setEditDatasets,

    newFileName,
    setNewFileName
  } = useDataManipulation()
  const { CUT } = DATA_MANIPULATION_MODE

  const title = `${mode === CUT ? 'Cut' : 'Slice'} Dataset`
  const description =
    mode === CUT ? (
      'You are removing data between pointers'
    ) : (
      <>
        You are removing all data
        <span> {direction === 'ASC' ? 'before' : 'after'}</span> the pointer
      </>
    )

  const isDisabledBtn = !edit && !newFileName

  return (
    <CustomModal
      open={isOpened}
      title={title}
      onClose={toggleModal}
      maxWidth={650}
      renderActionButton={
        <ActionButton
          label="Execute"
          disabled={isDisabledBtn}
          loading={loading}
          onClick={onSubmit}
          customStyles={{
            backgroundColor: theme.palette.action.secondary
          }}
        />
      }
    >
      <Typography className={classes.description}>{description}</Typography>

      <Box className={classes.file}>
        <InsertDriveFile className={classes.fileIcon} />

        <Typography noWrap>{fileName}</Typography>
      </Box>

      <FormControlLabel
        control={
          <Checkbox
            checked={edit}
            onChange={() => setEditDatasets(v => !v)}
            size="small"
            color="primary"
          />
        }
        label={
          <Typography className={classes.checkboxLabel}>
            Edit this dataset
          </Typography>
        }
      />

      {!edit && (
        <Input
          edit={edit}
          classes={classes}
          newFileName={newFileName}
          setNewFileName={setNewFileName}
        />
      )}
    </CustomModal>
  )
}

const Input = memo(({ edit, newFileName, setNewFileName, classes }) => {
  return (
    <CustomInput
      label="New dataset name"
      name="New dataset name"
      value={newFileName}
      onChange={e => setNewFileName(e.target.value)}
      className={classes.input}
      required={!edit}
      fullWidth
    />
  )
})

export default DataManipulationModal
