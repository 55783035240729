import { useSelector } from 'react-redux'
import { SPIKESORTING_STATUS } from '../../../utils/consts'

function useSpikeSortingInfo() {
  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )
  const run_id = selectedSorting?.id
  const isSorted =
    selectedSorting?.status?.toLowerCase() === SPIKESORTING_STATUS.SUCCESS.code
  const default_curation = selectedSorting?.default_curation

  return { run_id, isSorted, default_curation, selectedSorting }
}

export default useSpikeSortingInfo
