import React, { memo } from 'react'
import EmptyFilesTable from '../../../../../FilesTable/components/EmptyFilesTable'
import BlurLoader from '../../../../../BlurLoader'
import {
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Typography
} from '@material-ui/core'
import { ArrowBack, ArrowForwardIos, Folder } from '@material-ui/icons'
import cn from 'classnames'
import MapData from '../../../../../../utils/MapData/MapData'
import { MODALS_ID } from '../../../../../../utils/consts'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  body: {
    height: 350,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  list: {
    position: 'relative',
    flex: 1
  },
  head: {
    marginBottom: 5,
    zIndex: 2
  },
  backBtn: {
    padding: 10,
    marginRight: 6,
    '&:hover': {
      color: 'initial'
    }
  },
  folderIcon: {
    color: theme.palette.colors.accent[3],
    marginRight: 24
  },
  page: {
    fontWeight: 600
  },
  title: {
    color: theme.palette.primary.contrast,
    lineHeight: '24px',
    fontSize: 16,
    transition: 'font-weight 0.1s ease-in-out'
  },
  arrow: {
    marginLeft: 'auto',
    color: theme.palette.colors.neutral[5],
    fontSize: 22
  },
  listItem: {
    padding: '10px 12px',
    borderRadius: 8,
    '&:hover': {
      backgroundColor: theme.palette.background.fileHover,
      '& p': {
        fontWeight: 600
      }
    }
  }
}))

const FoldersList = ({ state, onSelect }) => {
  const classes = useStyles()
  const { data: result, loading, error } = state || {}
  const { data, folder } = result || {}
  const { parent_id, name } = folder || {}
  const modalState = useSelector(state =>
    state.modals?.get(MODALS_ID.MOVE_TO_MODAL)
  )
  const { recordId } = modalState?.args || {}
  const folders = data?.filter(item => item.id !== recordId)

  if (error) {
    return (
      <div className={classes.body}>
        <EmptyFilesTable />
      </div>
    )
  }

  return (
    <div className={classes.body}>
      {loading && <BlurLoader />}
      {name && (
        <Grid container alignItems="center" className={classes.head}>
          <IconButton
            onClick={() => onSelect(parent_id)}
            className={classes.backBtn}
          >
            <ArrowBack />
          </IconButton>
          <Typography className={cn(classes.title, classes.page)}>
            {name}
          </Typography>
        </Grid>
      )}

      <div className={classes.list}>
        {folders?.length > 0 ? (
          <List disablePadding>
            <MapData data={folders}>
              {({ item, index }) => {
                const { name, id } = item
                return (
                  <ListItem
                    button
                    key={index}
                    onClick={() => onSelect(id)}
                    classes={{ root: classes.listItem }}
                  >
                    <Folder className={classes.folderIcon} />
                    <Typography className={classes.title}>{name}</Typography>
                    <ArrowForwardIos className={classes.arrow} />
                  </ListItem>
                )
              }}
            </MapData>
          </List>
        ) : (
          <EmptyFilesTable size={16} />
        )}
      </div>
    </div>
  )
}

export default memo(FoldersList)
