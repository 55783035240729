import React from 'react'

export const svg64_2 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="70"
        height="270"
        viewBox="0 0 70 270"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 230.294V0H70V230.294L35 270L0 230.294Z" fill={color} />
        <path
          d="M26 40C26 38.3431 24.6569 37 23 37H11C9.34315 37 8 38.3431 8 40V51.0769C8 52.7338 9.34315 54.0769 11 54.0769H23C24.6569 54.0769 26 52.7338 26 51.0769V40Z"
          fill="white"
        />
        <path
          d="M26 65.0769C26 63.4201 24.6569 62.0769 23 62.0769H11C9.34315 62.0769 8 63.4201 8 65.0769V76.1538C8 77.8107 9.34315 79.1538 11 79.1538H23C24.6569 79.1538 26 77.8107 26 76.1538V65.0769Z"
          fill="white"
        />
        <path
          d="M26 90.1538C26 88.497 24.6569 87.1538 23 87.1538H11C9.34315 87.1538 8 88.497 8 90.1538V101.231C8 102.888 9.34315 104.231 11 104.231H23C24.6569 104.231 26 102.888 26 101.231V90.1538Z"
          fill="white"
        />
        <path
          d="M26 115.231C26 113.574 24.6569 112.231 23 112.231H11C9.34315 112.231 8 113.574 8 115.231V126.308C8 127.964 9.34315 129.308 11 129.308H23C24.6569 129.308 26 127.964 26 126.308V115.231Z"
          fill="white"
        />
        <path
          d="M26 140.308C26 138.651 24.6569 137.308 23 137.308H11C9.34315 137.308 8 138.651 8 140.308V151.385C8 153.041 9.34315 154.385 11 154.385H23C24.6569 154.385 26 153.041 26 151.385V140.308Z"
          fill="white"
        />
        <path
          d="M26 165.385C26 163.728 24.6569 162.385 23 162.385H11C9.34315 162.385 8 163.728 8 165.385V176.461C8 178.118 9.34315 179.461 11 179.461H23C24.6569 179.461 26 178.118 26 176.461V165.385Z"
          fill="white"
        />
        <path
          d="M26 190.461C26 188.805 24.6569 187.461 23 187.461H11C9.34315 187.461 8 188.805 8 190.461V201.538C8 203.195 9.34315 204.538 11 204.538H23C24.6569 204.538 26 203.195 26 201.538V190.461Z"
          fill="white"
        />
        <path
          d="M26 215.538C26 213.881 24.6569 212.538 23 212.538H11C9.34315 212.538 8 213.881 8 215.538V226.615C8 228.272 9.34315 229.615 11 229.615H23C24.6569 229.615 26 228.272 26 226.615V215.538Z"
          fill="white"
        />
        <path
          d="M48 25C48 23.3431 46.6569 22 45 22H33C31.3431 22 30 23.3431 30 25V36.0769C30 37.7338 31.3431 39.0769 33 39.0769H45C46.6569 39.0769 48 37.7338 48 36.0769V25Z"
          fill="white"
        />
        <path
          d="M48 50.0769C48 48.4201 46.6569 47.0769 45 47.0769H33C31.3431 47.0769 30 48.4201 30 50.0769V61.1538C30 62.8107 31.3431 64.1538 33 64.1538H45C46.6569 64.1538 48 62.8107 48 61.1538V50.0769Z"
          fill="white"
        />
        <path
          d="M48 75.1538C48 73.497 46.6569 72.1538 45 72.1538H33C31.3431 72.1538 30 73.497 30 75.1538V86.2307C30 87.8876 31.3431 89.2307 33 89.2307H45C46.6569 89.2307 48 87.8876 48 86.2307V75.1538Z"
          fill="white"
        />
        <path
          d="M48 100.231C48 98.5739 46.6569 97.2307 45 97.2307H33C31.3431 97.2307 30 98.5739 30 100.231V111.308C30 112.964 31.3431 114.308 33 114.308H45C46.6569 114.308 48 112.964 48 111.308V100.231Z"
          fill="white"
        />
        <path
          d="M48 125.308C48 123.651 46.6569 122.308 45 122.308H33C31.3431 122.308 30 123.651 30 125.308V136.385C30 138.041 31.3431 139.385 33 139.385H45C46.6569 139.385 48 138.041 48 136.385V125.308Z"
          fill="white"
        />
        <path
          d="M48 150.385C48 148.728 46.6569 147.385 45 147.385H33C31.3431 147.385 30 148.728 30 150.385V161.461C30 163.118 31.3431 164.461 33 164.461H45C46.6569 164.461 48 163.118 48 161.461V150.385Z"
          fill="white"
        />
        <path
          d="M48 175.461C48 173.805 46.6569 172.461 45 172.461H33C31.3431 172.461 30 173.805 30 175.461V186.538C30 188.195 31.3431 189.538 33 189.538H45C46.6569 189.538 48 188.195 48 186.538V175.461Z"
          fill="white"
        />
        <path
          d="M48 200.538C48 198.881 46.6569 197.538 45 197.538H33C31.3431 197.538 30 198.881 30 200.538V211.615C30 213.272 31.3431 214.615 33 214.615H45C46.6569 214.615 48 213.272 48 211.615V200.538Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="70"
      height="270"
      viewBox="0 0 70 270"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M0 230.294V0H70V230.294L35 270L0 230.294Z" fill={color} />
    </svg>
  )
}
