import React from 'react'

export const svg128_1 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        width="140"
        height="2638"
        viewBox="0 0 140 2638"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M140 0H0V293.659V2604.88L30.5 2638L140 293.659V0Z"
          fill={color}
        />
        <path
          d="M9 105C9 103.343 10.3431 102 12 102H24C25.6569 102 27 103.343 27 105V116.077C27 117.734 25.6569 119.077 24 119.077H12C10.3431 119.077 9 117.734 9 116.077V105Z"
          fill="white"
        />
        <path
          d="M9 124.577C9 122.92 10.3431 121.577 12 121.577H24C25.6569 121.577 27 122.92 27 124.577V135.654C27 137.311 25.6569 138.654 24 138.654H12C10.3431 138.654 9 137.311 9 135.654V124.577Z"
          fill="white"
        />
        <path
          d="M9 144.154C9 142.497 10.3431 141.154 12 141.154H24C25.6569 141.154 27 142.497 27 144.154V155.231C27 156.888 25.6569 158.231 24 158.231H12C10.3431 158.231 9 156.888 9 155.231V144.154Z"
          fill="white"
        />
        <path
          d="M9 163.731C9 162.074 10.3431 160.731 12 160.731H24C25.6569 160.731 27 162.074 27 163.731V174.808C27 176.464 25.6569 177.808 24 177.808H12C10.3431 177.808 9 176.464 9 174.808V163.731Z"
          fill="white"
        />
        <path
          d="M9 183.308C9 181.651 10.3431 180.308 12 180.308H24C25.6569 180.308 27 181.651 27 183.308V194.385C27 196.041 25.6569 197.385 24 197.385H12C10.3431 197.385 9 196.041 9 194.385V183.308Z"
          fill="white"
        />
        <path
          d="M9 202.885C9 201.228 10.3431 199.885 12 199.885H24C25.6569 199.885 27 201.228 27 202.885V213.962C27 215.618 25.6569 216.962 24 216.962H12C10.3431 216.962 9 215.618 9 213.962V202.885Z"
          fill="white"
        />
        <path
          d="M9 222.462C9 220.805 10.3431 219.462 12 219.462H24C25.6569 219.462 27 220.805 27 222.462V233.538C27 235.195 25.6569 236.538 24 236.538H12C10.3431 236.538 9 235.195 9 233.538V222.462Z"
          fill="white"
        />
        <path
          d="M9 242.038C9 240.382 10.3431 239.038 12 239.038H24C25.6569 239.038 27 240.382 27 242.038V253.115C27 254.772 25.6569 256.115 24 256.115H12C10.3431 256.115 9 254.772 9 253.115V242.038Z"
          fill="white"
        />
        <path
          d="M9 261.615C9 259.959 10.3431 258.615 12 258.615H24C25.6569 258.615 27 259.959 27 261.615V272.692C27 274.349 25.6569 275.692 24 275.692H12C10.3431 275.692 9 274.349 9 272.692V261.615Z"
          fill="white"
        />
        <path
          d="M9 281.192C9 279.535 10.3431 278.192 12 278.192H24C25.6569 278.192 27 279.535 27 281.192V292.269C27 293.926 25.6569 295.269 24 295.269H12C10.3431 295.269 9 293.926 9 292.269V281.192Z"
          fill="white"
        />
        <path
          d="M9 300.769C9 299.112 10.3431 297.769 12 297.769H24C25.6569 297.769 27 299.112 27 300.769V311.846C27 313.503 25.6569 314.846 24 314.846H12C10.3431 314.846 9 313.503 9 311.846V300.769Z"
          fill="white"
        />
        <path
          d="M9 320.346C9 318.689 10.3431 317.346 12 317.346H24C25.6569 317.346 27 318.689 27 320.346V331.423C27 333.08 25.6569 334.423 24 334.423H12C10.3431 334.423 9 333.08 9 331.423V320.346Z"
          fill="white"
        />
        <path
          d="M9 339.923C9 338.266 10.3431 336.923 12 336.923H24C25.6569 336.923 27 338.266 27 339.923V351C27 352.657 25.6569 354 24 354H12C10.3431 354 9 352.657 9 351V339.923Z"
          fill="white"
        />
        <path
          d="M9 359.5C9 357.843 10.3431 356.5 12 356.5H24C25.6569 356.5 27 357.843 27 359.5V370.577C27 372.234 25.6569 373.577 24 373.577H12C10.3431 373.577 9 372.234 9 370.577V359.5Z"
          fill="white"
        />
        <path
          d="M9 379.077C9 377.42 10.3431 376.077 12 376.077H24C25.6569 376.077 27 377.42 27 379.077V390.154C27 391.811 25.6569 393.154 24 393.154H12C10.3431 393.154 9 391.811 9 390.154V379.077Z"
          fill="white"
        />
        <path
          d="M9 398.654C9 396.997 10.3431 395.654 12 395.654H24C25.6569 395.654 27 396.997 27 398.654V409.731C27 411.388 25.6569 412.731 24 412.731H12C10.3431 412.731 9 411.388 9 409.731V398.654Z"
          fill="white"
        />
        <path
          d="M9 418.231C9 416.574 10.3431 415.231 12 415.231H24C25.6569 415.231 27 416.574 27 418.231V429.308C27 430.964 25.6569 432.308 24 432.308H12C10.3431 432.308 9 430.964 9 429.308V418.231Z"
          fill="white"
        />
        <path
          d="M9 437.808C9 436.151 10.3431 434.808 12 434.808H24C25.6569 434.808 27 436.151 27 437.808V448.885C27 450.541 25.6569 451.885 24 451.885H12C10.3431 451.885 9 450.541 9 448.885V437.808Z"
          fill="white"
        />
        <path
          d="M9 457.385C9 455.728 10.3431 454.385 12 454.385H24C25.6569 454.385 27 455.728 27 457.385V468.461C27 470.118 25.6569 471.461 24 471.461H12C10.3431 471.461 9 470.118 9 468.461V457.385Z"
          fill="white"
        />
        <path
          d="M9 476.961C9 475.305 10.3431 473.961 12 473.961H24C25.6569 473.961 27 475.305 27 476.961V488.038C27 489.695 25.6569 491.038 24 491.038H12C10.3431 491.038 9 489.695 9 488.038V476.961Z"
          fill="white"
        />
        <path
          d="M9 496.538C9 494.881 10.3431 493.538 12 493.538H24C25.6569 493.538 27 494.881 27 496.538V507.615C27 509.272 25.6569 510.615 24 510.615H12C10.3431 510.615 9 509.272 9 507.615V496.538Z"
          fill="white"
        />
        <path
          d="M9 516.115C9 514.458 10.3431 513.115 12 513.115H24C25.6569 513.115 27 514.458 27 516.115V527.192C27 528.849 25.6569 530.192 24 530.192H12C10.3431 530.192 9 528.849 9 527.192V516.115Z"
          fill="white"
        />
        <path
          d="M9 535.692C9 534.035 10.3431 532.692 12 532.692H24C25.6569 532.692 27 534.035 27 535.692V546.769C27 548.426 25.6569 549.769 24 549.769H12C10.3431 549.769 9 548.426 9 546.769V535.692Z"
          fill="white"
        />
        <path
          d="M9 555.269C9 553.612 10.3431 552.269 12 552.269H24C25.6569 552.269 27 553.612 27 555.269V566.346C27 568.003 25.6569 569.346 24 569.346H12C10.3431 569.346 9 568.003 9 566.346V555.269Z"
          fill="white"
        />
        <path
          d="M9 574.846C9 573.189 10.3431 571.846 12 571.846H24C25.6569 571.846 27 573.189 27 574.846V585.923C27 587.58 25.6569 588.923 24 588.923H12C10.3431 588.923 9 587.58 9 585.923V574.846Z"
          fill="white"
        />
        <path
          d="M9 594.423C9 592.766 10.3431 591.423 12 591.423H24C25.6569 591.423 27 592.766 27 594.423V605.5C27 607.157 25.6569 608.5 24 608.5H12C10.3431 608.5 9 607.157 9 605.5V594.423Z"
          fill="white"
        />
        <path
          d="M9 614C9 612.343 10.3431 611 12 611H24C25.6569 611 27 612.343 27 614V625.077C27 626.734 25.6569 628.077 24 628.077H12C10.3431 628.077 9 626.734 9 625.077V614Z"
          fill="white"
        />
        <path
          d="M9 633.577C9 631.92 10.3431 630.577 12 630.577H24C25.6569 630.577 27 631.92 27 633.577V644.654C27 646.31 25.6569 647.654 24 647.654H12C10.3431 647.654 9 646.31 9 644.654V633.577Z"
          fill="white"
        />
        <path
          d="M9 653.154C9 651.497 10.3431 650.154 12 650.154H24C25.6569 650.154 27 651.497 27 653.154V664.23C27 665.887 25.6569 667.23 24 667.23H12C10.3431 667.23 9 665.887 9 664.23V653.154Z"
          fill="white"
        />
        <path
          d="M9 672.73C9 671.074 10.3431 669.73 12 669.73H24C25.6569 669.73 27 671.074 27 672.73V683.807C27 685.464 25.6569 686.807 24 686.807H12C10.3431 686.807 9 685.464 9 683.807V672.73Z"
          fill="white"
        />
        <path
          d="M9 692.307C9 690.651 10.3431 689.307 12 689.307H24C25.6569 689.307 27 690.651 27 692.307V703.384C27 705.041 25.6569 706.384 24 706.384H12C10.3431 706.384 9 705.041 9 703.384V692.307Z"
          fill="white"
        />
        <path
          d="M9 711.884C9 710.227 10.3431 708.884 12 708.884H24C25.6569 708.884 27 710.227 27 711.884V722.961C27 724.618 25.6569 725.961 24 725.961H12C10.3431 725.961 9 724.618 9 722.961V711.884Z"
          fill="white"
        />
        <path
          d="M9 731.461C9 729.804 10.3431 728.461 12 728.461H24C25.6569 728.461 27 729.804 27 731.461V742.538C27 744.195 25.6569 745.538 24 745.538H12C10.3431 745.538 9 744.195 9 742.538V731.461Z"
          fill="white"
        />
        <path
          d="M9 751.038C9 749.381 10.3431 748.038 12 748.038H24C25.6569 748.038 27 749.381 27 751.038V762.115C27 763.772 25.6569 765.115 24 765.115H12C10.3431 765.115 9 763.772 9 762.115V751.038Z"
          fill="white"
        />
        <path
          d="M9 770.615C9 768.958 10.3431 767.615 12 767.615H24C25.6569 767.615 27 768.958 27 770.615V781.692C27 783.349 25.6569 784.692 24 784.692H12C10.3431 784.692 9 783.349 9 781.692V770.615Z"
          fill="white"
        />
        <path
          d="M9 790.192C9 788.535 10.3431 787.192 12 787.192H24C25.6569 787.192 27 788.535 27 790.192V801.269C27 802.926 25.6569 804.269 24 804.269H12C10.3431 804.269 9 802.926 9 801.269V790.192Z"
          fill="white"
        />
        <path
          d="M9 809.769C9 808.112 10.3431 806.769 12 806.769H24C25.6569 806.769 27 808.112 27 809.769V820.846C27 822.503 25.6569 823.846 24 823.846H12C10.3431 823.846 9 822.503 9 820.846V809.769Z"
          fill="white"
        />
        <path
          d="M9 829.346C9 827.689 10.3431 826.346 12 826.346H24C25.6569 826.346 27 827.689 27 829.346V840.423C27 842.079 25.6569 843.423 24 843.423H12C10.3431 843.423 9 842.079 9 840.423V829.346Z"
          fill="white"
        />
        <path
          d="M9 848.923C9 847.266 10.3431 845.923 12 845.923H24C25.6569 845.923 27 847.266 27 848.923V860C27 861.656 25.6569 863 24 863H12C10.3431 863 9 861.656 9 860V848.923Z"
          fill="white"
        />
        <path
          d="M9 868.5C9 866.843 10.3431 865.5 12 865.5H24C25.6569 865.5 27 866.843 27 868.5V879.576C27 881.233 25.6569 882.576 24 882.576H12C10.3431 882.576 9 881.233 9 879.576V868.5Z"
          fill="white"
        />
        <path
          d="M9 888.076C9 886.42 10.3431 885.076 12 885.076H24C25.6569 885.076 27 886.42 27 888.076V899.153C27 900.81 25.6569 902.153 24 902.153H12C10.3431 902.153 9 900.81 9 899.153V888.076Z"
          fill="white"
        />
        <path
          d="M9 907.653C9 905.996 10.3431 904.653 12 904.653H24C25.6569 904.653 27 905.996 27 907.653V918.73C27 920.387 25.6569 921.73 24 921.73H12C10.3431 921.73 9 920.387 9 918.73V907.653Z"
          fill="white"
        />
        <path
          d="M9 927.23C9 925.573 10.3431 924.23 12 924.23H24C25.6569 924.23 27 925.573 27 927.23V938.307C27 939.964 25.6569 941.307 24 941.307H12C10.3431 941.307 9 939.964 9 938.307V927.23Z"
          fill="white"
        />
        <path
          d="M9 946.807C9 945.15 10.3431 943.807 12 943.807H24C25.6569 943.807 27 945.15 27 946.807V957.884C27 959.541 25.6569 960.884 24 960.884H12C10.3431 960.884 9 959.541 9 957.884V946.807Z"
          fill="white"
        />
        <path
          d="M9 966.384C9 964.727 10.3431 963.384 12 963.384H24C25.6569 963.384 27 964.727 27 966.384V977.461C27 979.118 25.6569 980.461 24 980.461H12C10.3431 980.461 9 979.118 9 977.461V966.384Z"
          fill="white"
        />
        <path
          d="M9 985.961C9 984.304 10.3431 982.961 12 982.961H24C25.6569 982.961 27 984.304 27 985.961V997.038C27 998.695 25.6569 1000.04 24 1000.04H12C10.3431 1000.04 9 998.695 9 997.038V985.961Z"
          fill="white"
        />
        <path
          d="M9 1005.54C9 1003.88 10.3431 1002.54 12 1002.54H24C25.6569 1002.54 27 1003.88 27 1005.54V1016.61C27 1018.27 25.6569 1019.61 24 1019.61H12C10.3431 1019.61 9 1018.27 9 1016.61V1005.54Z"
          fill="white"
        />
        <path
          d="M9 1025.11C9 1023.46 10.3431 1022.11 12 1022.11H24C25.6569 1022.11 27 1023.46 27 1025.11V1036.19C27 1037.85 25.6569 1039.19 24 1039.19H12C10.3431 1039.19 9 1037.85 9 1036.19V1025.11Z"
          fill="white"
        />
        <path
          d="M9 1044.69C9 1043.03 10.3431 1041.69 12 1041.69H24C25.6569 1041.69 27 1043.03 27 1044.69V1055.77C27 1057.43 25.6569 1058.77 24 1058.77H12C10.3431 1058.77 9 1057.43 9 1055.77V1044.69Z"
          fill="white"
        />
        <path
          d="M9 1064.27C9 1062.61 10.3431 1061.27 12 1061.27H24C25.6569 1061.27 27 1062.61 27 1064.27V1075.35C27 1077 25.6569 1078.35 24 1078.35H12C10.3431 1078.35 9 1077 9 1075.35V1064.27Z"
          fill="white"
        />
        <path
          d="M9 1083.85C9 1082.19 10.3431 1080.85 12 1080.85H24C25.6569 1080.85 27 1082.19 27 1083.85V1094.92C27 1096.58 25.6569 1097.92 24 1097.92H12C10.3431 1097.92 9 1096.58 9 1094.92V1083.85Z"
          fill="white"
        />
        <path
          d="M9 1103.42C9 1101.77 10.3431 1100.42 12 1100.42H24C25.6569 1100.42 27 1101.77 27 1103.42V1114.5C27 1116.16 25.6569 1117.5 24 1117.5H12C10.3431 1117.5 9 1116.16 9 1114.5V1103.42Z"
          fill="white"
        />
        <path
          d="M9 1123C9 1121.34 10.3431 1120 12 1120H24C25.6569 1120 27 1121.34 27 1123V1134.08C27 1135.73 25.6569 1137.08 24 1137.08H12C10.3431 1137.08 9 1135.73 9 1134.08V1123Z"
          fill="white"
        />
        <path
          d="M9 1142.58C9 1140.92 10.3431 1139.58 12 1139.58H24C25.6569 1139.58 27 1140.92 27 1142.58V1153.65C27 1155.31 25.6569 1156.65 24 1156.65H12C10.3431 1156.65 9 1155.31 9 1153.65V1142.58Z"
          fill="white"
        />
        <path
          d="M9 1162.15C9 1160.5 10.3431 1159.15 12 1159.15H24C25.6569 1159.15 27 1160.5 27 1162.15V1173.23C27 1174.89 25.6569 1176.23 24 1176.23H12C10.3431 1176.23 9 1174.89 9 1173.23V1162.15Z"
          fill="white"
        />
        <path
          d="M9 1181.73C9 1180.07 10.3431 1178.73 12 1178.73H24C25.6569 1178.73 27 1180.07 27 1181.73V1192.81C27 1194.46 25.6569 1195.81 24 1195.81H12C10.3431 1195.81 9 1194.46 9 1192.81V1181.73Z"
          fill="white"
        />
        <path
          d="M9 1201.31C9 1199.65 10.3431 1198.31 12 1198.31H24C25.6569 1198.31 27 1199.65 27 1201.31V1212.38C27 1214.04 25.6569 1215.38 24 1215.38H12C10.3431 1215.38 9 1214.04 9 1212.38V1201.31Z"
          fill="white"
        />
        <path
          d="M9 1220.88C9 1219.23 10.3431 1217.88 12 1217.88H24C25.6569 1217.88 27 1219.23 27 1220.88V1231.96C27 1233.62 25.6569 1234.96 24 1234.96H12C10.3431 1234.96 9 1233.62 9 1231.96V1220.88Z"
          fill="white"
        />
        <path
          d="M9 1240.46C9 1238.8 10.3431 1237.46 12 1237.46H24C25.6569 1237.46 27 1238.8 27 1240.46V1251.54C27 1253.19 25.6569 1254.54 24 1254.54H12C10.3431 1254.54 9 1253.19 9 1251.54V1240.46Z"
          fill="white"
        />
        <path
          d="M9 1260.04C9 1258.38 10.3431 1257.04 12 1257.04H24C25.6569 1257.04 27 1258.38 27 1260.04V1271.11C27 1272.77 25.6569 1274.11 24 1274.11H12C10.3431 1274.11 9 1272.77 9 1271.11V1260.04Z"
          fill="white"
        />
        <path
          d="M9 1279.61C9 1277.96 10.3431 1276.61 12 1276.61H24C25.6569 1276.61 27 1277.96 27 1279.61V1290.69C27 1292.35 25.6569 1293.69 24 1293.69H12C10.3431 1293.69 9 1292.35 9 1290.69V1279.61Z"
          fill="white"
        />
        <path
          d="M9 1299.19C9 1297.53 10.3431 1296.19 12 1296.19H24C25.6569 1296.19 27 1297.53 27 1299.19V1310.27C27 1311.93 25.6569 1313.27 24 1313.27H12C10.3431 1313.27 9 1311.93 9 1310.27V1299.19Z"
          fill="white"
        />
        <path
          d="M9 1318.77C9 1317.11 10.3431 1315.77 12 1315.77H24C25.6569 1315.77 27 1317.11 27 1318.77V1329.85C27 1331.5 25.6569 1332.85 24 1332.85H12C10.3431 1332.85 9 1331.5 9 1329.85V1318.77Z"
          fill="white"
        />
        <path
          d="M9 1338.35C9 1336.69 10.3431 1335.35 12 1335.35H24C25.6569 1335.35 27 1336.69 27 1338.35V1349.42C27 1351.08 25.6569 1352.42 24 1352.42H12C10.3431 1352.42 9 1351.08 9 1349.42V1338.35Z"
          fill="white"
        />
        <path
          d="M9 1357.92C9 1356.27 10.3431 1354.92 12 1354.92H24C25.6569 1354.92 27 1356.27 27 1357.92V1369C27 1370.66 25.6569 1372 24 1372H12C10.3431 1372 9 1370.66 9 1369V1357.92Z"
          fill="white"
        />
        <path
          d="M9 1377.5C9 1375.84 10.3431 1374.5 12 1374.5H24C25.6569 1374.5 27 1375.84 27 1377.5V1388.58C27 1390.23 25.6569 1391.58 24 1391.58H12C10.3431 1391.58 9 1390.23 9 1388.58V1377.5Z"
          fill="white"
        />
        <path
          d="M9 1397.08C9 1395.42 10.3431 1394.08 12 1394.08H24C25.6569 1394.08 27 1395.42 27 1397.08V1408.15C27 1409.81 25.6569 1411.15 24 1411.15H12C10.3431 1411.15 9 1409.81 9 1408.15V1397.08Z"
          fill="white"
        />
        <path
          d="M9 1416.65C9 1415 10.3431 1413.65 12 1413.65H24C25.6569 1413.65 27 1415 27 1416.65V1427.73C27 1429.39 25.6569 1430.73 24 1430.73H12C10.3431 1430.73 9 1429.39 9 1427.73V1416.65Z"
          fill="white"
        />
        <path
          d="M9 1436.23C9 1434.57 10.3431 1433.23 12 1433.23H24C25.6569 1433.23 27 1434.57 27 1436.23V1447.31C27 1448.96 25.6569 1450.31 24 1450.31H12C10.3431 1450.31 9 1448.96 9 1447.31V1436.23Z"
          fill="white"
        />
        <path
          d="M9 1455.81C9 1454.15 10.3431 1452.81 12 1452.81H24C25.6569 1452.81 27 1454.15 27 1455.81V1466.88C27 1468.54 25.6569 1469.88 24 1469.88H12C10.3431 1469.88 9 1468.54 9 1466.88V1455.81Z"
          fill="white"
        />
        <path
          d="M9 1475.38C9 1473.73 10.3431 1472.38 12 1472.38H24C25.6569 1472.38 27 1473.73 27 1475.38V1486.46C27 1488.12 25.6569 1489.46 24 1489.46H12C10.3431 1489.46 9 1488.12 9 1486.46V1475.38Z"
          fill="white"
        />
        <path
          d="M9 1494.96C9 1493.3 10.3431 1491.96 12 1491.96H24C25.6569 1491.96 27 1493.3 27 1494.96V1506.04C27 1507.69 25.6569 1509.04 24 1509.04H12C10.3431 1509.04 9 1507.69 9 1506.04V1494.96Z"
          fill="white"
        />
        <path
          d="M9 1514.54C9 1512.88 10.3431 1511.54 12 1511.54H24C25.6569 1511.54 27 1512.88 27 1514.54V1525.61C27 1527.27 25.6569 1528.61 24 1528.61H12C10.3431 1528.61 9 1527.27 9 1525.61V1514.54Z"
          fill="white"
        />
        <path
          d="M9 1534.11C9 1532.46 10.3431 1531.11 12 1531.11H24C25.6569 1531.11 27 1532.46 27 1534.11V1545.19C27 1546.85 25.6569 1548.19 24 1548.19H12C10.3431 1548.19 9 1546.85 9 1545.19V1534.11Z"
          fill="white"
        />
        <path
          d="M9 1553.69C9 1552.03 10.3431 1550.69 12 1550.69H24C25.6569 1550.69 27 1552.03 27 1553.69V1564.77C27 1566.42 25.6569 1567.77 24 1567.77H12C10.3431 1567.77 9 1566.42 9 1564.77V1553.69Z"
          fill="white"
        />
        <path
          d="M9 1573.27C9 1571.61 10.3431 1570.27 12 1570.27H24C25.6569 1570.27 27 1571.61 27 1573.27V1584.34C27 1586 25.6569 1587.34 24 1587.34H12C10.3431 1587.34 9 1586 9 1584.34V1573.27Z"
          fill="white"
        />
        <path
          d="M9 1592.84C9 1591.19 10.3431 1589.84 12 1589.84H24C25.6569 1589.84 27 1591.19 27 1592.84V1603.92C27 1605.58 25.6569 1606.92 24 1606.92H12C10.3431 1606.92 9 1605.58 9 1603.92V1592.84Z"
          fill="white"
        />
        <path
          d="M9 1612.42C9 1610.77 10.3431 1609.42 12 1609.42H24C25.6569 1609.42 27 1610.77 27 1612.42V1623.5C27 1625.16 25.6569 1626.5 24 1626.5H12C10.3431 1626.5 9 1625.16 9 1623.5V1612.42Z"
          fill="white"
        />
        <path
          d="M9 1632C9 1630.34 10.3431 1629 12 1629H24C25.6569 1629 27 1630.34 27 1632V1643.08C27 1644.73 25.6569 1646.08 24 1646.08H12C10.3431 1646.08 9 1644.73 9 1643.08V1632Z"
          fill="white"
        />
        <path
          d="M9 1651.58C9 1649.92 10.3431 1648.58 12 1648.58H24C25.6569 1648.58 27 1649.92 27 1651.58V1662.65C27 1664.31 25.6569 1665.65 24 1665.65H12C10.3431 1665.65 9 1664.31 9 1662.65V1651.58Z"
          fill="white"
        />
        <path
          d="M9 1671.15C9 1669.5 10.3431 1668.15 12 1668.15H24C25.6569 1668.15 27 1669.5 27 1671.15V1682.23C27 1683.89 25.6569 1685.23 24 1685.23H12C10.3431 1685.23 9 1683.89 9 1682.23V1671.15Z"
          fill="white"
        />
        <path
          d="M9 1690.73C9 1689.07 10.3431 1687.73 12 1687.73H24C25.6569 1687.73 27 1689.07 27 1690.73V1701.81C27 1703.46 25.6569 1704.81 24 1704.81H12C10.3431 1704.81 9 1703.46 9 1701.81V1690.73Z"
          fill="white"
        />
        <path
          d="M9 1710.31C9 1708.65 10.3431 1707.31 12 1707.31H24C25.6569 1707.31 27 1708.65 27 1710.31V1721.38C27 1723.04 25.6569 1724.38 24 1724.38H12C10.3431 1724.38 9 1723.04 9 1721.38V1710.31Z"
          fill="white"
        />
        <path
          d="M9 1729.88C9 1728.23 10.3431 1726.88 12 1726.88H24C25.6569 1726.88 27 1728.23 27 1729.88V1740.96C27 1742.62 25.6569 1743.96 24 1743.96H12C10.3431 1743.96 9 1742.62 9 1740.96V1729.88Z"
          fill="white"
        />
        <path
          d="M9 1749.46C9 1747.8 10.3431 1746.46 12 1746.46H24C25.6569 1746.46 27 1747.8 27 1749.46V1760.54C27 1762.19 25.6569 1763.54 24 1763.54H12C10.3431 1763.54 9 1762.19 9 1760.54V1749.46Z"
          fill="white"
        />
        <path
          d="M9 1769.04C9 1767.38 10.3431 1766.04 12 1766.04H24C25.6569 1766.04 27 1767.38 27 1769.04V1780.11C27 1781.77 25.6569 1783.11 24 1783.11H12C10.3431 1783.11 9 1781.77 9 1780.11V1769.04Z"
          fill="white"
        />
        <path
          d="M9 1788.61C9 1786.96 10.3431 1785.61 12 1785.61H24C25.6569 1785.61 27 1786.96 27 1788.61V1799.69C27 1801.35 25.6569 1802.69 24 1802.69H12C10.3431 1802.69 9 1801.35 9 1799.69V1788.61Z"
          fill="white"
        />
        <path
          d="M9 1808.19C9 1806.53 10.3431 1805.19 12 1805.19H24C25.6569 1805.19 27 1806.53 27 1808.19V1819.27C27 1820.92 25.6569 1822.27 24 1822.27H12C10.3431 1822.27 9 1820.92 9 1819.27V1808.19Z"
          fill="white"
        />
        <path
          d="M9 1827.77C9 1826.11 10.3431 1824.77 12 1824.77H24C25.6569 1824.77 27 1826.11 27 1827.77V1838.84C27 1840.5 25.6569 1841.84 24 1841.84H12C10.3431 1841.84 9 1840.5 9 1838.84V1827.77Z"
          fill="white"
        />
        <path
          d="M9 1847.34C9 1845.69 10.3431 1844.34 12 1844.34H24C25.6569 1844.34 27 1845.69 27 1847.34V1858.42C27 1860.08 25.6569 1861.42 24 1861.42H12C10.3431 1861.42 9 1860.08 9 1858.42V1847.34Z"
          fill="white"
        />
        <path
          d="M9 1866.92C9 1865.26 10.3431 1863.92 12 1863.92H24C25.6569 1863.92 27 1865.26 27 1866.92V1878C27 1879.66 25.6569 1881 24 1881H12C10.3431 1881 9 1879.66 9 1878V1866.92Z"
          fill="white"
        />
        <path
          d="M9 1886.5C9 1884.84 10.3431 1883.5 12 1883.5H24C25.6569 1883.5 27 1884.84 27 1886.5V1897.58C27 1899.23 25.6569 1900.58 24 1900.58H12C10.3431 1900.58 9 1899.23 9 1897.58V1886.5Z"
          fill="white"
        />
        <path
          d="M9 1906.08C9 1904.42 10.3431 1903.08 12 1903.08H24C25.6569 1903.08 27 1904.42 27 1906.08V1917.15C27 1918.81 25.6569 1920.15 24 1920.15H12C10.3431 1920.15 9 1918.81 9 1917.15V1906.08Z"
          fill="white"
        />
        <path
          d="M9 1925.65C9 1924 10.3431 1922.65 12 1922.65H24C25.6569 1922.65 27 1924 27 1925.65V1936.73C27 1938.39 25.6569 1939.73 24 1939.73H12C10.3431 1939.73 9 1938.39 9 1936.73V1925.65Z"
          fill="white"
        />
        <path
          d="M9 1945.23C9 1943.57 10.3431 1942.23 12 1942.23H24C25.6569 1942.23 27 1943.57 27 1945.23V1956.31C27 1957.96 25.6569 1959.31 24 1959.31H12C10.3431 1959.31 9 1957.96 9 1956.31V1945.23Z"
          fill="white"
        />
        <path
          d="M9 1964.81C9 1963.15 10.3431 1961.81 12 1961.81H24C25.6569 1961.81 27 1963.15 27 1964.81V1975.88C27 1977.54 25.6569 1978.88 24 1978.88H12C10.3431 1978.88 9 1977.54 9 1975.88V1964.81Z"
          fill="white"
        />
        <path
          d="M9 1984.38C9 1982.73 10.3431 1981.38 12 1981.38H24C25.6569 1981.38 27 1982.73 27 1984.38V1995.46C27 1997.12 25.6569 1998.46 24 1998.46H12C10.3431 1998.46 9 1997.12 9 1995.46V1984.38Z"
          fill="white"
        />
        <path
          d="M9 2003.96C9 2002.3 10.3431 2000.96 12 2000.96H24C25.6569 2000.96 27 2002.3 27 2003.96V2015.04C27 2016.69 25.6569 2018.04 24 2018.04H12C10.3431 2018.04 9 2016.69 9 2015.04V2003.96Z"
          fill="white"
        />
        <path
          d="M9 2023.54C9 2021.88 10.3431 2020.54 12 2020.54H24C25.6569 2020.54 27 2021.88 27 2023.54V2034.61C27 2036.27 25.6569 2037.61 24 2037.61H12C10.3431 2037.61 9 2036.27 9 2034.61V2023.54Z"
          fill="white"
        />
        <path
          d="M9 2043.11C9 2041.46 10.3431 2040.11 12 2040.11H24C25.6569 2040.11 27 2041.46 27 2043.11V2054.19C27 2055.85 25.6569 2057.19 24 2057.19H12C10.3431 2057.19 9 2055.85 9 2054.19V2043.11Z"
          fill="white"
        />
        <path
          d="M9 2062.69C9 2061.03 10.3431 2059.69 12 2059.69H24C25.6569 2059.69 27 2061.03 27 2062.69V2073.77C27 2075.42 25.6569 2076.77 24 2076.77H12C10.3431 2076.77 9 2075.42 9 2073.77V2062.69Z"
          fill="white"
        />
        <path
          d="M9 2082.27C9 2080.61 10.3431 2079.27 12 2079.27H24C25.6569 2079.27 27 2080.61 27 2082.27V2093.34C27 2095 25.6569 2096.34 24 2096.34H12C10.3431 2096.34 9 2095 9 2093.34V2082.27Z"
          fill="white"
        />
        <path
          d="M9 2101.84C9 2100.19 10.3431 2098.84 12 2098.84H24C25.6569 2098.84 27 2100.19 27 2101.84V2112.92C27 2114.58 25.6569 2115.92 24 2115.92H12C10.3431 2115.92 9 2114.58 9 2112.92V2101.84Z"
          fill="white"
        />
        <path
          d="M9 2121.42C9 2119.76 10.3431 2118.42 12 2118.42H24C25.6569 2118.42 27 2119.76 27 2121.42V2132.5C27 2134.16 25.6569 2135.5 24 2135.5H12C10.3431 2135.5 9 2134.16 9 2132.5V2121.42Z"
          fill="white"
        />
        <path
          d="M9 2141C9 2139.34 10.3431 2138 12 2138H24C25.6569 2138 27 2139.34 27 2141V2152.08C27 2153.73 25.6569 2155.08 24 2155.08H12C10.3431 2155.08 9 2153.73 9 2152.08V2141Z"
          fill="white"
        />
        <path
          d="M9 2160.58C9 2158.92 10.3431 2157.58 12 2157.58H24C25.6569 2157.58 27 2158.92 27 2160.58V2171.65C27 2173.31 25.6569 2174.65 24 2174.65H12C10.3431 2174.65 9 2173.31 9 2171.65V2160.58Z"
          fill="white"
        />
        <path
          d="M9 2180.15C9 2178.5 10.3431 2177.15 12 2177.15H24C25.6569 2177.15 27 2178.5 27 2180.15V2191.23C27 2192.89 25.6569 2194.23 24 2194.23H12C10.3431 2194.23 9 2192.89 9 2191.23V2180.15Z"
          fill="white"
        />
        <path
          d="M9 2199.73C9 2198.07 10.3431 2196.73 12 2196.73H24C25.6569 2196.73 27 2198.07 27 2199.73V2210.81C27 2212.46 25.6569 2213.81 24 2213.81H12C10.3431 2213.81 9 2212.46 9 2210.81V2199.73Z"
          fill="white"
        />
        <path
          d="M9 2219.31C9 2217.65 10.3431 2216.31 12 2216.31H24C25.6569 2216.31 27 2217.65 27 2219.31V2230.38C27 2232.04 25.6569 2233.38 24 2233.38H12C10.3431 2233.38 9 2232.04 9 2230.38V2219.31Z"
          fill="white"
        />
        <path
          d="M9 2238.88C9 2237.23 10.3431 2235.88 12 2235.88H24C25.6569 2235.88 27 2237.23 27 2238.88V2249.96C27 2251.62 25.6569 2252.96 24 2252.96H12C10.3431 2252.96 9 2251.62 9 2249.96V2238.88Z"
          fill="white"
        />
        <path
          d="M9 2258.46C9 2256.8 10.3431 2255.46 12 2255.46H24C25.6569 2255.46 27 2256.8 27 2258.46V2269.54C27 2271.19 25.6569 2272.54 24 2272.54H12C10.3431 2272.54 9 2271.19 9 2269.54V2258.46Z"
          fill="white"
        />
        <path
          d="M9 2278.04C9 2276.38 10.3431 2275.04 12 2275.04H24C25.6569 2275.04 27 2276.38 27 2278.04V2289.11C27 2290.77 25.6569 2292.11 24 2292.11H12C10.3431 2292.11 9 2290.77 9 2289.11V2278.04Z"
          fill="white"
        />
        <path
          d="M9 2297.61C9 2295.96 10.3431 2294.61 12 2294.61H24C25.6569 2294.61 27 2295.96 27 2297.61V2308.69C27 2310.35 25.6569 2311.69 24 2311.69H12C10.3431 2311.69 9 2310.35 9 2308.69V2297.61Z"
          fill="white"
        />
        <path
          d="M9 2317.19C9 2315.53 10.3431 2314.19 12 2314.19H24C25.6569 2314.19 27 2315.53 27 2317.19V2328.27C27 2329.92 25.6569 2331.27 24 2331.27H12C10.3431 2331.27 9 2329.92 9 2328.27V2317.19Z"
          fill="white"
        />
        <path
          d="M9 2336.77C9 2335.11 10.3431 2333.77 12 2333.77H24C25.6569 2333.77 27 2335.11 27 2336.77V2347.84C27 2349.5 25.6569 2350.84 24 2350.84H12C10.3431 2350.84 9 2349.5 9 2347.84V2336.77Z"
          fill="white"
        />
        <path
          d="M9 2356.34C9 2354.69 10.3431 2353.34 12 2353.34H24C25.6569 2353.34 27 2354.69 27 2356.34V2367.42C27 2369.08 25.6569 2370.42 24 2370.42H12C10.3431 2370.42 9 2369.08 9 2367.42V2356.34Z"
          fill="white"
        />
        <path
          d="M9 2375.92C9 2374.26 10.3431 2372.92 12 2372.92H24C25.6569 2372.92 27 2374.26 27 2375.92V2387C27 2388.65 25.6569 2390 24 2390H12C10.3431 2390 9 2388.65 9 2387V2375.92Z"
          fill="white"
        />
        <path
          d="M9 2395.5C9 2393.84 10.3431 2392.5 12 2392.5H24C25.6569 2392.5 27 2393.84 27 2395.5V2406.57C27 2408.23 25.6569 2409.57 24 2409.57H12C10.3431 2409.57 9 2408.23 9 2406.57V2395.5Z"
          fill="white"
        />
        <path
          d="M9 2415.07C9 2413.42 10.3431 2412.07 12 2412.07H24C25.6569 2412.07 27 2413.42 27 2415.07V2426.15C27 2427.81 25.6569 2429.15 24 2429.15H12C10.3431 2429.15 9 2427.81 9 2426.15V2415.07Z"
          fill="white"
        />
        <path
          d="M9 2434.65C9 2433 10.3431 2431.65 12 2431.65H24C25.6569 2431.65 27 2433 27 2434.65V2445.73C27 2447.39 25.6569 2448.73 24 2448.73H12C10.3431 2448.73 9 2447.39 9 2445.73V2434.65Z"
          fill="white"
        />
        <path
          d="M9 2454.23C9 2452.57 10.3431 2451.23 12 2451.23H24C25.6569 2451.23 27 2452.57 27 2454.23V2465.31C27 2466.96 25.6569 2468.31 24 2468.31H12C10.3431 2468.31 9 2466.96 9 2465.31V2454.23Z"
          fill="white"
        />
        <path
          d="M9 2473.81C9 2472.15 10.3431 2470.81 12 2470.81H24C25.6569 2470.81 27 2472.15 27 2473.81V2484.88C27 2486.54 25.6569 2487.88 24 2487.88H12C10.3431 2487.88 9 2486.54 9 2484.88V2473.81Z"
          fill="white"
        />
        <path
          d="M9 2493.38C9 2491.73 10.3431 2490.38 12 2490.38H24C25.6569 2490.38 27 2491.73 27 2493.38V2504.46C27 2506.12 25.6569 2507.46 24 2507.46H12C10.3431 2507.46 9 2506.12 9 2504.46V2493.38Z"
          fill="white"
        />
        <path
          d="M9 2512.96C9 2511.3 10.3431 2509.96 12 2509.96H24C25.6569 2509.96 27 2511.3 27 2512.96V2524.04C27 2525.69 25.6569 2527.04 24 2527.04H12C10.3431 2527.04 9 2525.69 9 2524.04V2512.96Z"
          fill="white"
        />
        <path
          d="M9 2532.54C9 2530.88 10.3431 2529.54 12 2529.54H24C25.6569 2529.54 27 2530.88 27 2532.54V2543.61C27 2545.27 25.6569 2546.61 24 2546.61H12C10.3431 2546.61 9 2545.27 9 2543.61V2532.54Z"
          fill="white"
        />
        <path
          d="M9 2552.11C9 2550.46 10.3431 2549.11 12 2549.11H24C25.6569 2549.11 27 2550.46 27 2552.11V2563.19C27 2564.85 25.6569 2566.19 24 2566.19H12C10.3431 2566.19 9 2564.85 9 2563.19V2552.11Z"
          fill="white"
        />
        <path
          d="M9 2571.69C9 2570.03 10.3431 2568.69 12 2568.69H24C25.6569 2568.69 27 2570.03 27 2571.69V2582.77C27 2584.42 25.6569 2585.77 24 2585.77H12C10.3431 2585.77 9 2584.42 9 2582.77V2571.69Z"
          fill="white"
        />
        <path
          d="M9 2591.27C9 2589.61 10.3431 2588.27 12 2588.27H24C25.6569 2588.27 27 2589.61 27 2591.27V2602.34C27 2604 25.6569 2605.34 24 2605.34H12C10.3431 2605.34 9 2604 9 2602.34V2591.27Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="140"
      height="2638"
      viewBox="0 0 140 2638"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140 0H0V293.659V2604.88L30.5 2638L140 293.659V0Z"
        fill={color}
      />
    </svg>
  )
}
