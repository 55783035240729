import ActionButton from '../../../ActionButton'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import cn from 'classnames'
import { NavigateBefore, NavigateNext } from '@material-ui/icons'
import { useTheme } from '@material-ui/core'

const useStyle = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 502,
    display: 'flex',
    flexWrap: 'nowrap'
  },
  btn: {
    minWidth: 'auto',
    width: 105,
    borderRadius: 4,
    minHeight: 'auto',
    height: 24,
    padding: '4px 8px',

    '&, & p': {
      fontSize: 11,
      fontWeight: 400,
      color: theme.palette.colors.neutral.black
    },
    '&:hover, &:hover p': {
      color: theme.palette.colors.neutral.black
    }
  },
  selected: {
    width: 'fit-content',
    backgroundColor: theme.palette.secondary[100],
    '&, & p': {
      fontSize: 11,
      fontWeight: 600,
      color: theme.palette.colors.white
    },
    '&:hover, &:hover p': {
      color: theme.palette.colors.neutral.black
    }
  },
  icon: {
    margin: 0
  },
  before: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    paddingLeft: 0
  },
  after: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    paddingRight: 0
  }
}))

const SliceButtons = ({ pos, setDirection, direction }) => {
  const classes = useStyle({ direction })
  const theme = useTheme()
  const backgroundColor = theme.palette.colors.customColor(
    theme.palette.colors.neutral[7],
    theme.palette.colors.neutral[5]
  )

  const handleOnCLick = e => type => {
    e.stopPropagation()
    setDirection(type)
  }

  const extraLabelInfo = '(this portion will be removed)'

  const actionButtonLabelASC =
    direction === 'ASC' ? `SLICE BEFORE ${extraLabelInfo}` : 'SLICE BEFORE'
  const actionButtonLabelDESC =
    direction === 'DESC' ? `SLICE AFTER ${extraLabelInfo}` : 'SLICE AFTER'

  const directionPercentage = direction === 'ASC' ? '70.7%' : '29.7%'
  return (
    <div
      className={classes.root}
      style={{
        transform: `translateX(calc(${pos[0]}px - ${directionPercentage} + 1px))`
      }}
    >
      <ActionButton
        label={actionButtonLabelASC}
        leftAdornment={<NavigateBefore />}
        className={cn(classes.btn, classes.before, {
          [classes.selected]: direction === 'ASC'
        })}
        customStyles={{
          backgroundColor
        }}
        iconClassName={classes.icon}
        onClick={e => handleOnCLick(e)('ASC')}
      />
      <div style={{ width: 14 }} />
      <ActionButton
        label={actionButtonLabelDESC}
        rightAdornment={<NavigateNext />}
        className={cn(classes.btn, classes.after, {
          [classes.selected]: direction === 'DESC'
        })}
        customStyles={{
          backgroundColor
        }}
        iconClassName={classes.icon}
        onClick={e => handleOnCLick(e)('DESC')}
      />
    </div>
  )
}

export default SliceButtons
