import { useCallback, useState } from 'react'

function useTableShadowsHandler() {
  const [isLeftShadow, setLeftShadow] = useState(0)
  const [isRightShadow, setRightShadow] = useState(0)
  const onScroll = useCallback(e => {
    if (!e?.currentTarget) return null
    const table = e.currentTarget.getElementsByTagName('table')[0]
    const thead = table
      .querySelector('tr:first-child')
      .getElementsByTagName('th')
    const firstColWidth = thead[0].offsetWidth
    const lastColWidth = thead[thead.length - 1].offsetWidth

    const showRightShadow =
      table?.offsetWidth -
        e.currentTarget.offsetWidth -
        e.currentTarget.scrollLeft >
      10

    const showLeftShadow = e.currentTarget.scrollLeft > 5

    if (showLeftShadow) setLeftShadow(firstColWidth)
    else setLeftShadow(0)

    if (showRightShadow) setRightShadow(lastColWidth)
    else setRightShadow(0)
  }, [])

  return { onScroll, isLeftShadow, isRightShadow }
}

export default useTableShadowsHandler
