import React from 'react'
import './About.css'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    padding: 40
  }
}))

function Home() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography component="p">
        Analysis and visualization of neurophysiology recordings and spike
        sorting results.
      </Typography>
      <p />
      <Typography component="p">
        Project home:{' '}
        <a
          href="https://github.com/laboratorybox/labbox-ephys"
          target="_blank"
          rel="noopener noreferrer"
        >
          DBCloud-ephys
        </a>
      </Typography>
      <p />
      <Typography component="p">
        Authors: Jeremy Magland and Jeff Soules, Center for Computational
        Mathematics, Flatiron Institute
      </Typography>
    </div>
  )
}

export default Home
