import React from 'react'

export const svg128_5 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="60"
        height="1555"
        viewBox="0 0 60 1555"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M60 0H0V1492.53L30 1555L60 1492.53V0Z" fill={color} />
        <path
          d="M1 1274C1 1272.34 2.34315 1271 4 1271H16C17.6569 1271 19 1272.34 19 1274V1285.08C19 1286.73 17.6569 1288.08 16 1288.08H4C2.34315 1288.08 1 1286.73 1 1285.08V1274Z"
          fill="white"
        />
        <path
          d="M1 1295.08C1 1293.42 2.34315 1292.08 4 1292.08H16C17.6569 1292.08 19 1293.42 19 1295.08V1306.15C19 1307.81 17.6569 1309.15 16 1309.15H4C2.34315 1309.15 1 1307.81 1 1306.15V1295.08Z"
          fill="white"
        />
        <path
          d="M1 1316.15C1 1314.5 2.34315 1313.15 4 1313.15H16C17.6569 1313.15 19 1314.5 19 1316.15V1327.23C19 1328.89 17.6569 1330.23 16 1330.23H4C2.34315 1330.23 1 1328.89 1 1327.23V1316.15Z"
          fill="white"
        />
        <path
          d="M1 1337.23C1 1335.57 2.34315 1334.23 4 1334.23H16C17.6569 1334.23 19 1335.57 19 1337.23V1348.31C19 1349.96 17.6569 1351.31 16 1351.31H4C2.34315 1351.31 1 1349.96 1 1348.31V1337.23Z"
          fill="white"
        />
        <path
          d="M1 1358.31C1 1356.65 2.34315 1355.31 4 1355.31H16C17.6569 1355.31 19 1356.65 19 1358.31V1369.38C19 1371.04 17.6569 1372.38 16 1372.38H4C2.34315 1372.38 1 1371.04 1 1369.38V1358.31Z"
          fill="white"
        />
        <path
          d="M1 1379.38C1 1377.73 2.34315 1376.38 4 1376.38H16C17.6569 1376.38 19 1377.73 19 1379.38V1390.46C19 1392.12 17.6569 1393.46 16 1393.46H4C2.34315 1393.46 1 1392.12 1 1390.46V1379.38Z"
          fill="white"
        />
        <path
          d="M1 1400.46C1 1398.8 2.34315 1397.46 4 1397.46H16C17.6569 1397.46 19 1398.8 19 1400.46V1411.54C19 1413.2 17.6569 1414.54 16 1414.54H4C2.34315 1414.54 1 1413.2 1 1411.54V1400.46Z"
          fill="white"
        />
        <path
          d="M1 1421.54C1 1419.88 2.34315 1418.54 4 1418.54H16C17.6569 1418.54 19 1419.88 19 1421.54V1432.62C19 1434.27 17.6569 1435.62 16 1435.62H4C2.34315 1435.62 1 1434.27 1 1432.62V1421.54Z"
          fill="white"
        />
        <path
          d="M1 1442.62C1 1440.96 2.34315 1439.62 4 1439.62H16C17.6569 1439.62 19 1440.96 19 1442.62V1453.69C19 1455.35 17.6569 1456.69 16 1456.69H4C2.34315 1456.69 1 1455.35 1 1453.69V1442.62Z"
          fill="white"
        />
        <path
          d="M1 1463.69C1 1462.04 2.34315 1460.69 4 1460.69H16C17.6569 1460.69 19 1462.04 19 1463.69V1474.77C19 1476.43 17.6569 1477.77 16 1477.77H4C2.34315 1477.77 1 1476.43 1 1474.77V1463.69Z"
          fill="white"
        />
        <path
          d="M41 1274C41 1272.34 42.3431 1271 44 1271H56C57.6569 1271 59 1272.34 59 1274V1285.08C59 1286.73 57.6569 1288.08 56 1288.08H44C42.3431 1288.08 41 1286.73 41 1285.08V1274Z"
          fill="white"
        />
        <path
          d="M41 1295.08C41 1293.42 42.3431 1292.08 44 1292.08H56C57.6569 1292.08 59 1293.42 59 1295.08V1306.15C59 1307.81 57.6569 1309.15 56 1309.15H44C42.3431 1309.15 41 1307.81 41 1306.15V1295.08Z"
          fill="white"
        />
        <path
          d="M41 1316.15C41 1314.5 42.3431 1313.15 44 1313.15H56C57.6569 1313.15 59 1314.5 59 1316.15V1327.23C59 1328.89 57.6569 1330.23 56 1330.23H44C42.3431 1330.23 41 1328.89 41 1327.23V1316.15Z"
          fill="white"
        />
        <path
          d="M41 1337.23C41 1335.57 42.3431 1334.23 44 1334.23H56C57.6569 1334.23 59 1335.57 59 1337.23V1348.31C59 1349.96 57.6569 1351.31 56 1351.31H44C42.3431 1351.31 41 1349.96 41 1348.31V1337.23Z"
          fill="white"
        />
        <path
          d="M41 1358.31C41 1356.65 42.3431 1355.31 44 1355.31H56C57.6569 1355.31 59 1356.65 59 1358.31V1369.38C59 1371.04 57.6569 1372.38 56 1372.38H44C42.3431 1372.38 41 1371.04 41 1369.38V1358.31Z"
          fill="white"
        />
        <path
          d="M41 1379.38C41 1377.73 42.3431 1376.38 44 1376.38H56C57.6569 1376.38 59 1377.73 59 1379.38V1390.46C59 1392.12 57.6569 1393.46 56 1393.46H44C42.3431 1393.46 41 1392.12 41 1390.46V1379.38Z"
          fill="white"
        />
        <path
          d="M41 1400.46C41 1398.8 42.3431 1397.46 44 1397.46H56C57.6569 1397.46 59 1398.8 59 1400.46V1411.54C59 1413.2 57.6569 1414.54 56 1414.54H44C42.3431 1414.54 41 1413.2 41 1411.54V1400.46Z"
          fill="white"
        />
        <path
          d="M41 1421.54C41 1419.88 42.3431 1418.54 44 1418.54H56C57.6569 1418.54 59 1419.88 59 1421.54V1432.62C59 1434.27 57.6569 1435.62 56 1435.62H44C42.3431 1435.62 41 1434.27 41 1432.62V1421.54Z"
          fill="white"
        />
        <path
          d="M41 1442.62C41 1440.96 42.3431 1439.62 44 1439.62H56C57.6569 1439.62 59 1440.96 59 1442.62V1453.69C59 1455.35 57.6569 1456.69 56 1456.69H44C42.3431 1456.69 41 1455.35 41 1453.69V1442.62Z"
          fill="white"
        />
        <path
          d="M41 1463.69C41 1462.04 42.3431 1460.69 44 1460.69H56C57.6569 1460.69 59 1462.04 59 1463.69V1474.77C59 1476.43 57.6569 1477.77 56 1477.77H44C42.3431 1477.77 41 1476.43 41 1474.77V1463.69Z"
          fill="white"
        />
        <path
          d="M23 118C23 116.343 24.3431 115 26 115H38C39.6569 115 41 116.343 41 118V129.077C41 130.734 39.6569 132.077 38 132.077H26C24.3431 132.077 23 130.734 23 129.077V118Z"
          fill="white"
        />
        <path
          d="M21 1288C21 1286.34 22.3431 1285 24 1285H36C37.6569 1285 39 1286.34 39 1288V1299.08C39 1300.73 37.6569 1302.08 36 1302.08H24C22.3431 1302.08 21 1300.73 21 1299.08V1288Z"
          fill="white"
        />
        <path
          d="M21 1309.08C21 1307.42 22.3431 1306.08 24 1306.08H36C37.6569 1306.08 39 1307.42 39 1309.08V1320.15C39 1321.81 37.6569 1323.15 36 1323.15H24C22.3431 1323.15 21 1321.81 21 1320.15V1309.08Z"
          fill="white"
        />
        <path
          d="M21 1330.15C21 1328.5 22.3431 1327.15 24 1327.15H36C37.6569 1327.15 39 1328.5 39 1330.15V1341.23C39 1342.89 37.6569 1344.23 36 1344.23H24C22.3431 1344.23 21 1342.89 21 1341.23V1330.15Z"
          fill="white"
        />
        <path
          d="M21 1351.23C21 1349.57 22.3431 1348.23 24 1348.23H36C37.6569 1348.23 39 1349.57 39 1351.23V1362.31C39 1363.96 37.6569 1365.31 36 1365.31H24C22.3431 1365.31 21 1363.96 21 1362.31V1351.23Z"
          fill="white"
        />
        <path
          d="M21 1372.31C21 1370.65 22.3431 1369.31 24 1369.31H36C37.6569 1369.31 39 1370.65 39 1372.31V1383.38C39 1385.04 37.6569 1386.38 36 1386.38H24C22.3431 1386.38 21 1385.04 21 1383.38V1372.31Z"
          fill="white"
        />
        <path
          d="M21 1393.38C21 1391.73 22.3431 1390.38 24 1390.38H36C37.6569 1390.38 39 1391.73 39 1393.38V1404.46C39 1406.12 37.6569 1407.46 36 1407.46H24C22.3431 1407.46 21 1406.12 21 1404.46V1393.38Z"
          fill="white"
        />
        <path
          d="M21 1414.46C21 1412.8 22.3431 1411.46 24 1411.46H36C37.6569 1411.46 39 1412.8 39 1414.46V1425.54C39 1427.2 37.6569 1428.54 36 1428.54H24C22.3431 1428.54 21 1427.2 21 1425.54V1414.46Z"
          fill="white"
        />
        <path
          d="M21 1435.54C21 1433.88 22.3431 1432.54 24 1432.54H36C37.6569 1432.54 39 1433.88 39 1435.54V1446.62C39 1448.27 37.6569 1449.62 36 1449.62H24C22.3431 1449.62 21 1448.27 21 1446.62V1435.54Z"
          fill="white"
        />
        <path
          d="M21 1456.62C21 1454.96 22.3431 1453.62 24 1453.62H36C37.6569 1453.62 39 1454.96 39 1456.62V1467.69C39 1469.35 37.6569 1470.69 36 1470.69H24C22.3431 1470.69 21 1469.35 21 1467.69V1456.62Z"
          fill="white"
        />
        <path
          d="M21 1477.69C21 1476.04 22.3431 1474.69 24 1474.69H36C37.6569 1474.69 39 1476.04 39 1477.69V1488.77C39 1490.43 37.6569 1491.77 36 1491.77H24C22.3431 1491.77 21 1490.43 21 1488.77V1477.69Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="60"
      height="1555"
      viewBox="0 0 60 1555"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M60 0H0V1492.53L30 1555L60 1492.53V0Z" fill={color} />
    </svg>
  )
}
