import React from 'react'

export const svg128_10 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="68"
        height="3400"
        viewBox="0 0 68 3400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M68 0H0V3263.41L34 3400L68 3263.41V0Z" fill={color} />
        <path
          d="M8 66C8 64.3431 9.34315 63 11 63H23C24.6569 63 26 64.3431 26 66V77.0769C26 78.7338 24.6569 80.0769 23 80.0769H11C9.34315 80.0769 8 78.7338 8 77.0769V66Z"
          fill="white"
        />
        <path
          d="M8 116.077C8 114.42 9.34315 113.077 11 113.077H23C24.6569 113.077 26 114.42 26 116.077V127.154C26 128.811 24.6569 130.154 23 130.154H11C9.34315 130.154 8 128.811 8 127.154V116.077Z"
          fill="white"
        />
        <path
          d="M8 166.154C8 164.497 9.34315 163.154 11 163.154H23C24.6569 163.154 26 164.497 26 166.154V177.231C26 178.888 24.6569 180.231 23 180.231H11C9.34315 180.231 8 178.888 8 177.231V166.154Z"
          fill="white"
        />
        <path
          d="M8 216.231C8 214.574 9.34315 213.231 11 213.231H23C24.6569 213.231 26 214.574 26 216.231V227.308C26 228.964 24.6569 230.308 23 230.308H11C9.34315 230.308 8 228.964 8 227.308V216.231Z"
          fill="white"
        />
        <path
          d="M8 266.308C8 264.651 9.34315 263.308 11 263.308H23C24.6569 263.308 26 264.651 26 266.308V277.385C26 279.041 24.6569 280.385 23 280.385H11C9.34315 280.385 8 279.041 8 277.385V266.308Z"
          fill="white"
        />
        <path
          d="M8 316.385C8 314.728 9.34315 313.385 11 313.385H23C24.6569 313.385 26 314.728 26 316.385V327.462C26 329.118 24.6569 330.462 23 330.462H11C9.34315 330.462 8 329.118 8 327.462V316.385Z"
          fill="white"
        />
        <path
          d="M8 366.462C8 364.805 9.34315 363.462 11 363.462H23C24.6569 363.462 26 364.805 26 366.462V377.538C26 379.195 24.6569 380.538 23 380.538H11C9.34315 380.538 8 379.195 8 377.538V366.462Z"
          fill="white"
        />
        <path
          d="M8 416.538C8 414.882 9.34315 413.538 11 413.538H23C24.6569 413.538 26 414.882 26 416.538V427.615C26 429.272 24.6569 430.615 23 430.615H11C9.34315 430.615 8 429.272 8 427.615V416.538Z"
          fill="white"
        />
        <path
          d="M8 466.615C8 464.959 9.34315 463.615 11 463.615H23C24.6569 463.615 26 464.959 26 466.615V477.692C26 479.349 24.6569 480.692 23 480.692H11C9.34315 480.692 8 479.349 8 477.692V466.615Z"
          fill="white"
        />
        <path
          d="M8 516.692C8 515.035 9.34315 513.692 11 513.692H23C24.6569 513.692 26 515.035 26 516.692V527.769C26 529.426 24.6569 530.769 23 530.769H11C9.34315 530.769 8 529.426 8 527.769V516.692Z"
          fill="white"
        />
        <path
          d="M8 566.769C8 565.112 9.34315 563.769 11 563.769H23C24.6569 563.769 26 565.112 26 566.769V577.846C26 579.503 24.6569 580.846 23 580.846H11C9.34315 580.846 8 579.503 8 577.846V566.769Z"
          fill="white"
        />
        <path
          d="M8 616.846C8 615.189 9.34315 613.846 11 613.846H23C24.6569 613.846 26 615.189 26 616.846V627.923C26 629.58 24.6569 630.923 23 630.923H11C9.34315 630.923 8 629.58 8 627.923V616.846Z"
          fill="white"
        />
        <path
          d="M8 666.923C8 665.266 9.34315 663.923 11 663.923H23C24.6569 663.923 26 665.266 26 666.923V678C26 679.657 24.6569 681 23 681H11C9.34315 681 8 679.657 8 678V666.923Z"
          fill="white"
        />
        <path
          d="M8 717C8 715.343 9.34315 714 11 714H23C24.6569 714 26 715.343 26 717V728.077C26 729.734 24.6569 731.077 23 731.077H11C9.34315 731.077 8 729.734 8 728.077V717Z"
          fill="white"
        />
        <path
          d="M8 767.077C8 765.42 9.34315 764.077 11 764.077H23C24.6569 764.077 26 765.42 26 767.077V778.154C26 779.811 24.6569 781.154 23 781.154H11C9.34315 781.154 8 779.811 8 778.154V767.077Z"
          fill="white"
        />
        <path
          d="M8 817.154C8 815.497 9.34315 814.154 11 814.154H23C24.6569 814.154 26 815.497 26 817.154V828.231C26 829.887 24.6569 831.231 23 831.231H11C9.34315 831.231 8 829.887 8 828.231V817.154Z"
          fill="white"
        />
        <path
          d="M8 867.231C8 865.574 9.34315 864.231 11 864.231H23C24.6569 864.231 26 865.574 26 867.231V878.308C26 879.964 24.6569 881.308 23 881.308H11C9.34315 881.308 8 879.964 8 878.308V867.231Z"
          fill="white"
        />
        <path
          d="M8 917.307C8 915.651 9.34315 914.307 11 914.307H23C24.6569 914.307 26 915.651 26 917.307V928.384C26 930.041 24.6569 931.384 23 931.384H11C9.34315 931.384 8 930.041 8 928.384V917.307Z"
          fill="white"
        />
        <path
          d="M8 967.384C8 965.728 9.34315 964.384 11 964.384H23C24.6569 964.384 26 965.728 26 967.384V978.461C26 980.118 24.6569 981.461 23 981.461H11C9.34315 981.461 8 980.118 8 978.461V967.384Z"
          fill="white"
        />
        <path
          d="M8 1017.46C8 1015.8 9.34315 1014.46 11 1014.46H23C24.6569 1014.46 26 1015.8 26 1017.46V1028.54C26 1030.2 24.6569 1031.54 23 1031.54H11C9.34315 1031.54 8 1030.2 8 1028.54V1017.46Z"
          fill="white"
        />
        <path
          d="M8 1067.54C8 1065.88 9.34315 1064.54 11 1064.54H23C24.6569 1064.54 26 1065.88 26 1067.54V1078.62C26 1080.27 24.6569 1081.62 23 1081.62H11C9.34315 1081.62 8 1080.27 8 1078.62V1067.54Z"
          fill="white"
        />
        <path
          d="M8 1117.62C8 1115.96 9.34315 1114.62 11 1114.62H23C24.6569 1114.62 26 1115.96 26 1117.62V1128.69C26 1130.35 24.6569 1131.69 23 1131.69H11C9.34315 1131.69 8 1130.35 8 1128.69V1117.62Z"
          fill="white"
        />
        <path
          d="M8 1167.69C8 1166.04 9.34315 1164.69 11 1164.69H23C24.6569 1164.69 26 1166.04 26 1167.69V1178.77C26 1180.43 24.6569 1181.77 23 1181.77H11C9.34315 1181.77 8 1180.43 8 1178.77V1167.69Z"
          fill="white"
        />
        <path
          d="M8 1217.77C8 1216.11 9.34315 1214.77 11 1214.77H23C24.6569 1214.77 26 1216.11 26 1217.77V1228.85C26 1230.5 24.6569 1231.85 23 1231.85H11C9.34315 1231.85 8 1230.5 8 1228.85V1217.77Z"
          fill="white"
        />
        <path
          d="M8 1267.85C8 1266.19 9.34315 1264.85 11 1264.85H23C24.6569 1264.85 26 1266.19 26 1267.85V1278.92C26 1280.58 24.6569 1281.92 23 1281.92H11C9.34315 1281.92 8 1280.58 8 1278.92V1267.85Z"
          fill="white"
        />
        <path
          d="M8 1317.92C8 1316.27 9.34315 1314.92 11 1314.92H23C24.6569 1314.92 26 1316.27 26 1317.92V1329C26 1330.66 24.6569 1332 23 1332H11C9.34315 1332 8 1330.66 8 1329V1317.92Z"
          fill="white"
        />
        <path
          d="M8 1368C8 1366.34 9.34315 1365 11 1365H23C24.6569 1365 26 1366.34 26 1368V1379.08C26 1380.73 24.6569 1382.08 23 1382.08H11C9.34315 1382.08 8 1380.73 8 1379.08V1368Z"
          fill="white"
        />
        <path
          d="M8 1418.08C8 1416.42 9.34315 1415.08 11 1415.08H23C24.6569 1415.08 26 1416.42 26 1418.08V1429.15C26 1430.81 24.6569 1432.15 23 1432.15H11C9.34315 1432.15 8 1430.81 8 1429.15V1418.08Z"
          fill="white"
        />
        <path
          d="M8 1468.15C8 1466.5 9.34315 1465.15 11 1465.15H23C24.6569 1465.15 26 1466.5 26 1468.15V1479.23C26 1480.89 24.6569 1482.23 23 1482.23H11C9.34315 1482.23 8 1480.89 8 1479.23V1468.15Z"
          fill="white"
        />
        <path
          d="M8 1518.23C8 1516.57 9.34315 1515.23 11 1515.23H23C24.6569 1515.23 26 1516.57 26 1518.23V1529.31C26 1530.96 24.6569 1532.31 23 1532.31H11C9.34315 1532.31 8 1530.96 8 1529.31V1518.23Z"
          fill="white"
        />
        <path
          d="M8 1568.31C8 1566.65 9.34315 1565.31 11 1565.31H23C24.6569 1565.31 26 1566.65 26 1568.31V1579.38C26 1581.04 24.6569 1582.38 23 1582.38H11C9.34315 1582.38 8 1581.04 8 1579.38V1568.31Z"
          fill="white"
        />
        <path
          d="M8 1618.38C8 1616.73 9.34315 1615.38 11 1615.38H23C24.6569 1615.38 26 1616.73 26 1618.38V1629.46C26 1631.12 24.6569 1632.46 23 1632.46H11C9.34315 1632.46 8 1631.12 8 1629.46V1618.38Z"
          fill="white"
        />
        <path
          d="M8 1668.46C8 1666.8 9.34315 1665.46 11 1665.46H23C24.6569 1665.46 26 1666.8 26 1668.46V1679.54C26 1681.19 24.6569 1682.54 23 1682.54H11C9.34315 1682.54 8 1681.19 8 1679.54V1668.46Z"
          fill="white"
        />
        <path
          d="M8 1718.54C8 1716.88 9.34315 1715.54 11 1715.54H23C24.6569 1715.54 26 1716.88 26 1718.54V1729.61C26 1731.27 24.6569 1732.61 23 1732.61H11C9.34315 1732.61 8 1731.27 8 1729.61V1718.54Z"
          fill="white"
        />
        <path
          d="M8 1768.61C8 1766.96 9.34315 1765.61 11 1765.61H23C24.6569 1765.61 26 1766.96 26 1768.61V1779.69C26 1781.35 24.6569 1782.69 23 1782.69H11C9.34315 1782.69 8 1781.35 8 1779.69V1768.61Z"
          fill="white"
        />
        <path
          d="M8 1818.69C8 1817.03 9.34315 1815.69 11 1815.69H23C24.6569 1815.69 26 1817.03 26 1818.69V1829.77C26 1831.43 24.6569 1832.77 23 1832.77H11C9.34315 1832.77 8 1831.43 8 1829.77V1818.69Z"
          fill="white"
        />
        <path
          d="M8 1868.77C8 1867.11 9.34315 1865.77 11 1865.77H23C24.6569 1865.77 26 1867.11 26 1868.77V1879.85C26 1881.5 24.6569 1882.85 23 1882.85H11C9.34315 1882.85 8 1881.5 8 1879.85V1868.77Z"
          fill="white"
        />
        <path
          d="M8 1918.85C8 1917.19 9.34315 1915.85 11 1915.85H23C24.6569 1915.85 26 1917.19 26 1918.85V1929.92C26 1931.58 24.6569 1932.92 23 1932.92H11C9.34315 1932.92 8 1931.58 8 1929.92V1918.85Z"
          fill="white"
        />
        <path
          d="M8 1968.92C8 1967.27 9.34315 1965.92 11 1965.92H23C24.6569 1965.92 26 1967.27 26 1968.92V1980C26 1981.66 24.6569 1983 23 1983H11C9.34315 1983 8 1981.66 8 1980V1968.92Z"
          fill="white"
        />
        <path
          d="M8 2019C8 2017.34 9.34315 2016 11 2016H23C24.6569 2016 26 2017.34 26 2019V2030.08C26 2031.73 24.6569 2033.08 23 2033.08H11C9.34315 2033.08 8 2031.73 8 2030.08V2019Z"
          fill="white"
        />
        <path
          d="M8 2069.08C8 2067.42 9.34315 2066.08 11 2066.08H23C24.6569 2066.08 26 2067.42 26 2069.08V2080.15C26 2081.81 24.6569 2083.15 23 2083.15H11C9.34315 2083.15 8 2081.81 8 2080.15V2069.08Z"
          fill="white"
        />
        <path
          d="M8 2119.15C8 2117.5 9.34315 2116.15 11 2116.15H23C24.6569 2116.15 26 2117.5 26 2119.15V2130.23C26 2131.89 24.6569 2133.23 23 2133.23H11C9.34315 2133.23 8 2131.89 8 2130.23V2119.15Z"
          fill="white"
        />
        <path
          d="M8 2169.23C8 2167.57 9.34315 2166.23 11 2166.23H23C24.6569 2166.23 26 2167.57 26 2169.23V2180.31C26 2181.96 24.6569 2183.31 23 2183.31H11C9.34315 2183.31 8 2181.96 8 2180.31V2169.23Z"
          fill="white"
        />
        <path
          d="M8 2219.31C8 2217.65 9.34315 2216.31 11 2216.31H23C24.6569 2216.31 26 2217.65 26 2219.31V2230.38C26 2232.04 24.6569 2233.38 23 2233.38H11C9.34315 2233.38 8 2232.04 8 2230.38V2219.31Z"
          fill="white"
        />
        <path
          d="M8 2269.38C8 2267.73 9.34315 2266.38 11 2266.38H23C24.6569 2266.38 26 2267.73 26 2269.38V2280.46C26 2282.12 24.6569 2283.46 23 2283.46H11C9.34315 2283.46 8 2282.12 8 2280.46V2269.38Z"
          fill="white"
        />
        <path
          d="M8 2319.46C8 2317.8 9.34315 2316.46 11 2316.46H23C24.6569 2316.46 26 2317.8 26 2319.46V2330.54C26 2332.19 24.6569 2333.54 23 2333.54H11C9.34315 2333.54 8 2332.19 8 2330.54V2319.46Z"
          fill="white"
        />
        <path
          d="M8 2369.54C8 2367.88 9.34315 2366.54 11 2366.54H23C24.6569 2366.54 26 2367.88 26 2369.54V2380.61C26 2382.27 24.6569 2383.61 23 2383.61H11C9.34315 2383.61 8 2382.27 8 2380.61V2369.54Z"
          fill="white"
        />
        <path
          d="M8 2419.61C8 2417.96 9.34315 2416.61 11 2416.61H23C24.6569 2416.61 26 2417.96 26 2419.61V2430.69C26 2432.35 24.6569 2433.69 23 2433.69H11C9.34315 2433.69 8 2432.35 8 2430.69V2419.61Z"
          fill="white"
        />
        <path
          d="M8 2469.69C8 2468.03 9.34315 2466.69 11 2466.69H23C24.6569 2466.69 26 2468.03 26 2469.69V2480.77C26 2482.43 24.6569 2483.77 23 2483.77H11C9.34315 2483.77 8 2482.43 8 2480.77V2469.69Z"
          fill="white"
        />
        <path
          d="M8 2519.77C8 2518.11 9.34315 2516.77 11 2516.77H23C24.6569 2516.77 26 2518.11 26 2519.77V2530.85C26 2532.5 24.6569 2533.85 23 2533.85H11C9.34315 2533.85 8 2532.5 8 2530.85V2519.77Z"
          fill="white"
        />
        <path
          d="M8 2569.85C8 2568.19 9.34315 2566.85 11 2566.85H23C24.6569 2566.85 26 2568.19 26 2569.85V2580.92C26 2582.58 24.6569 2583.92 23 2583.92H11C9.34315 2583.92 8 2582.58 8 2580.92V2569.85Z"
          fill="white"
        />
        <path
          d="M8 2619.92C8 2618.27 9.34315 2616.92 11 2616.92H23C24.6569 2616.92 26 2618.27 26 2619.92V2631C26 2632.66 24.6569 2634 23 2634H11C9.34315 2634 8 2632.66 8 2631V2619.92Z"
          fill="white"
        />
        <path
          d="M8 2670C8 2668.34 9.34315 2667 11 2667H23C24.6569 2667 26 2668.34 26 2670V2681.08C26 2682.73 24.6569 2684.08 23 2684.08H11C9.34315 2684.08 8 2682.73 8 2681.08V2670Z"
          fill="white"
        />
        <path
          d="M8 2720.08C8 2718.42 9.34315 2717.08 11 2717.08H23C24.6569 2717.08 26 2718.42 26 2720.08V2731.15C26 2732.81 24.6569 2734.15 23 2734.15H11C9.34315 2734.15 8 2732.81 8 2731.15V2720.08Z"
          fill="white"
        />
        <path
          d="M8 2770.15C8 2768.5 9.34315 2767.15 11 2767.15H23C24.6569 2767.15 26 2768.5 26 2770.15V2781.23C26 2782.89 24.6569 2784.23 23 2784.23H11C9.34315 2784.23 8 2782.89 8 2781.23V2770.15Z"
          fill="white"
        />
        <path
          d="M8 2820.23C8 2818.57 9.34315 2817.23 11 2817.23H23C24.6569 2817.23 26 2818.57 26 2820.23V2831.31C26 2832.96 24.6569 2834.31 23 2834.31H11C9.34315 2834.31 8 2832.96 8 2831.31V2820.23Z"
          fill="white"
        />
        <path
          d="M8 2870.31C8 2868.65 9.34315 2867.31 11 2867.31H23C24.6569 2867.31 26 2868.65 26 2870.31V2881.38C26 2883.04 24.6569 2884.38 23 2884.38H11C9.34315 2884.38 8 2883.04 8 2881.38V2870.31Z"
          fill="white"
        />
        <path
          d="M8 2920.38C8 2918.73 9.34315 2917.38 11 2917.38H23C24.6569 2917.38 26 2918.73 26 2920.38V2931.46C26 2933.12 24.6569 2934.46 23 2934.46H11C9.34315 2934.46 8 2933.12 8 2931.46V2920.38Z"
          fill="white"
        />
        <path
          d="M8 2970.46C8 2968.8 9.34315 2967.46 11 2967.46H23C24.6569 2967.46 26 2968.8 26 2970.46V2981.54C26 2983.19 24.6569 2984.54 23 2984.54H11C9.34315 2984.54 8 2983.19 8 2981.54V2970.46Z"
          fill="white"
        />
        <path
          d="M8 3020.54C8 3018.88 9.34315 3017.54 11 3017.54H23C24.6569 3017.54 26 3018.88 26 3020.54V3031.61C26 3033.27 24.6569 3034.61 23 3034.61H11C9.34315 3034.61 8 3033.27 8 3031.61V3020.54Z"
          fill="white"
        />
        <path
          d="M8 3070.61C8 3068.96 9.34315 3067.61 11 3067.61H23C24.6569 3067.61 26 3068.96 26 3070.61V3081.69C26 3083.35 24.6569 3084.69 23 3084.69H11C9.34315 3084.69 8 3083.35 8 3081.69V3070.61Z"
          fill="white"
        />
        <path
          d="M8 3120.69C8 3119.03 9.34315 3117.69 11 3117.69H23C24.6569 3117.69 26 3119.03 26 3120.69V3131.77C26 3133.43 24.6569 3134.77 23 3134.77H11C9.34315 3134.77 8 3133.43 8 3131.77V3120.69Z"
          fill="white"
        />
        <path
          d="M8 3170.77C8 3169.11 9.34315 3167.77 11 3167.77H23C24.6569 3167.77 26 3169.11 26 3170.77V3181.85C26 3183.5 24.6569 3184.85 23 3184.85H11C9.34315 3184.85 8 3183.5 8 3181.85V3170.77Z"
          fill="white"
        />
        <path
          d="M8 3220.85C8 3219.19 9.34315 3217.85 11 3217.85H23C24.6569 3217.85 26 3219.19 26 3220.85V3231.92C26 3233.58 24.6569 3234.92 23 3234.92H11C9.34315 3234.92 8 3233.58 8 3231.92V3220.85Z"
          fill="white"
        />
        <path
          d="M36 39C36 37.3431 37.3431 36 39 36H51C52.6569 36 54 37.3431 54 39V50.0769C54 51.7338 52.6569 53.0769 51 53.0769H39C37.3431 53.0769 36 51.7338 36 50.0769V39Z"
          fill="white"
        />
        <path
          d="M36 89.0769C36 87.4201 37.3431 86.0769 39 86.0769H51C52.6569 86.0769 54 87.4201 54 89.0769V100.154C54 101.811 52.6569 103.154 51 103.154H39C37.3431 103.154 36 101.811 36 100.154V89.0769Z"
          fill="white"
        />
        <path
          d="M36 139.154C36 137.497 37.3431 136.154 39 136.154H51C52.6569 136.154 54 137.497 54 139.154V150.231C54 151.888 52.6569 153.231 51 153.231H39C37.3431 153.231 36 151.888 36 150.231V139.154Z"
          fill="white"
        />
        <path
          d="M36 189.231C36 187.574 37.3431 186.231 39 186.231H51C52.6569 186.231 54 187.574 54 189.231V200.308C54 201.964 52.6569 203.308 51 203.308H39C37.3431 203.308 36 201.964 36 200.308V189.231Z"
          fill="white"
        />
        <path
          d="M36 239.308C36 237.651 37.3431 236.308 39 236.308H51C52.6569 236.308 54 237.651 54 239.308V250.385C54 252.041 52.6569 253.385 51 253.385H39C37.3431 253.385 36 252.041 36 250.385V239.308Z"
          fill="white"
        />
        <path
          d="M36 289.385C36 287.728 37.3431 286.385 39 286.385H51C52.6569 286.385 54 287.728 54 289.385V300.462C54 302.118 52.6569 303.462 51 303.462H39C37.3431 303.462 36 302.118 36 300.462V289.385Z"
          fill="white"
        />
        <path
          d="M36 339.462C36 337.805 37.3431 336.462 39 336.462H51C52.6569 336.462 54 337.805 54 339.462V350.538C54 352.195 52.6569 353.538 51 353.538H39C37.3431 353.538 36 352.195 36 350.538V339.462Z"
          fill="white"
        />
        <path
          d="M36 389.538C36 387.882 37.3431 386.538 39 386.538H51C52.6569 386.538 54 387.882 54 389.538V400.615C54 402.272 52.6569 403.615 51 403.615H39C37.3431 403.615 36 402.272 36 400.615V389.538Z"
          fill="white"
        />
        <path
          d="M36 439.615C36 437.959 37.3431 436.615 39 436.615H51C52.6569 436.615 54 437.959 54 439.615V450.692C54 452.349 52.6569 453.692 51 453.692H39C37.3431 453.692 36 452.349 36 450.692V439.615Z"
          fill="white"
        />
        <path
          d="M36 489.692C36 488.035 37.3431 486.692 39 486.692H51C52.6569 486.692 54 488.035 54 489.692V500.769C54 502.426 52.6569 503.769 51 503.769H39C37.3431 503.769 36 502.426 36 500.769V489.692Z"
          fill="white"
        />
        <path
          d="M36 539.769C36 538.112 37.3431 536.769 39 536.769H51C52.6569 536.769 54 538.112 54 539.769V550.846C54 552.503 52.6569 553.846 51 553.846H39C37.3431 553.846 36 552.503 36 550.846V539.769Z"
          fill="white"
        />
        <path
          d="M36 589.846C36 588.189 37.3431 586.846 39 586.846H51C52.6569 586.846 54 588.189 54 589.846V600.923C54 602.58 52.6569 603.923 51 603.923H39C37.3431 603.923 36 602.58 36 600.923V589.846Z"
          fill="white"
        />
        <path
          d="M36 639.923C36 638.266 37.3431 636.923 39 636.923H51C52.6569 636.923 54 638.266 54 639.923V651C54 652.657 52.6569 654 51 654H39C37.3431 654 36 652.657 36 651V639.923Z"
          fill="white"
        />
        <path
          d="M36 690C36 688.343 37.3431 687 39 687H51C52.6569 687 54 688.343 54 690V701.077C54 702.734 52.6569 704.077 51 704.077H39C37.3431 704.077 36 702.734 36 701.077V690Z"
          fill="white"
        />
        <path
          d="M36 740.077C36 738.42 37.3431 737.077 39 737.077H51C52.6569 737.077 54 738.42 54 740.077V751.154C54 752.811 52.6569 754.154 51 754.154H39C37.3431 754.154 36 752.811 36 751.154V740.077Z"
          fill="white"
        />
        <path
          d="M36 790.154C36 788.497 37.3431 787.154 39 787.154H51C52.6569 787.154 54 788.497 54 790.154V801.231C54 802.887 52.6569 804.231 51 804.231H39C37.3431 804.231 36 802.887 36 801.231V790.154Z"
          fill="white"
        />
        <path
          d="M36 840.231C36 838.574 37.3431 837.231 39 837.231H51C52.6569 837.231 54 838.574 54 840.231V851.308C54 852.964 52.6569 854.308 51 854.308H39C37.3431 854.308 36 852.964 36 851.308V840.231Z"
          fill="white"
        />
        <path
          d="M36 890.307C36 888.651 37.3431 887.307 39 887.307H51C52.6569 887.307 54 888.651 54 890.307V901.384C54 903.041 52.6569 904.384 51 904.384H39C37.3431 904.384 36 903.041 36 901.384V890.307Z"
          fill="white"
        />
        <path
          d="M36 940.384C36 938.728 37.3431 937.384 39 937.384H51C52.6569 937.384 54 938.728 54 940.384V951.461C54 953.118 52.6569 954.461 51 954.461H39C37.3431 954.461 36 953.118 36 951.461V940.384Z"
          fill="white"
        />
        <path
          d="M36 990.461C36 988.804 37.3431 987.461 39 987.461H51C52.6569 987.461 54 988.804 54 990.461V1001.54C54 1003.2 52.6569 1004.54 51 1004.54H39C37.3431 1004.54 36 1003.2 36 1001.54V990.461Z"
          fill="white"
        />
        <path
          d="M36 1040.54C36 1038.88 37.3431 1037.54 39 1037.54H51C52.6569 1037.54 54 1038.88 54 1040.54V1051.62C54 1053.27 52.6569 1054.62 51 1054.62H39C37.3431 1054.62 36 1053.27 36 1051.62V1040.54Z"
          fill="white"
        />
        <path
          d="M36 1090.62C36 1088.96 37.3431 1087.62 39 1087.62H51C52.6569 1087.62 54 1088.96 54 1090.62V1101.69C54 1103.35 52.6569 1104.69 51 1104.69H39C37.3431 1104.69 36 1103.35 36 1101.69V1090.62Z"
          fill="white"
        />
        <path
          d="M36 1140.69C36 1139.04 37.3431 1137.69 39 1137.69H51C52.6569 1137.69 54 1139.04 54 1140.69V1151.77C54 1153.43 52.6569 1154.77 51 1154.77H39C37.3431 1154.77 36 1153.43 36 1151.77V1140.69Z"
          fill="white"
        />
        <path
          d="M36 1190.77C36 1189.11 37.3431 1187.77 39 1187.77H51C52.6569 1187.77 54 1189.11 54 1190.77V1201.85C54 1203.5 52.6569 1204.85 51 1204.85H39C37.3431 1204.85 36 1203.5 36 1201.85V1190.77Z"
          fill="white"
        />
        <path
          d="M36 1240.85C36 1239.19 37.3431 1237.85 39 1237.85H51C52.6569 1237.85 54 1239.19 54 1240.85V1251.92C54 1253.58 52.6569 1254.92 51 1254.92H39C37.3431 1254.92 36 1253.58 36 1251.92V1240.85Z"
          fill="white"
        />
        <path
          d="M36 1290.92C36 1289.27 37.3431 1287.92 39 1287.92H51C52.6569 1287.92 54 1289.27 54 1290.92V1302C54 1303.66 52.6569 1305 51 1305H39C37.3431 1305 36 1303.66 36 1302V1290.92Z"
          fill="white"
        />
        <path
          d="M36 1341C36 1339.34 37.3431 1338 39 1338H51C52.6569 1338 54 1339.34 54 1341V1352.08C54 1353.73 52.6569 1355.08 51 1355.08H39C37.3431 1355.08 36 1353.73 36 1352.08V1341Z"
          fill="white"
        />
        <path
          d="M36 1391.08C36 1389.42 37.3431 1388.08 39 1388.08H51C52.6569 1388.08 54 1389.42 54 1391.08V1402.15C54 1403.81 52.6569 1405.15 51 1405.15H39C37.3431 1405.15 36 1403.81 36 1402.15V1391.08Z"
          fill="white"
        />
        <path
          d="M36 1441.15C36 1439.5 37.3431 1438.15 39 1438.15H51C52.6569 1438.15 54 1439.5 54 1441.15V1452.23C54 1453.89 52.6569 1455.23 51 1455.23H39C37.3431 1455.23 36 1453.89 36 1452.23V1441.15Z"
          fill="white"
        />
        <path
          d="M36 1491.23C36 1489.57 37.3431 1488.23 39 1488.23H51C52.6569 1488.23 54 1489.57 54 1491.23V1502.31C54 1503.96 52.6569 1505.31 51 1505.31H39C37.3431 1505.31 36 1503.96 36 1502.31V1491.23Z"
          fill="white"
        />
        <path
          d="M36 1541.31C36 1539.65 37.3431 1538.31 39 1538.31H51C52.6569 1538.31 54 1539.65 54 1541.31V1552.38C54 1554.04 52.6569 1555.38 51 1555.38H39C37.3431 1555.38 36 1554.04 36 1552.38V1541.31Z"
          fill="white"
        />
        <path
          d="M36 1591.38C36 1589.73 37.3431 1588.38 39 1588.38H51C52.6569 1588.38 54 1589.73 54 1591.38V1602.46C54 1604.12 52.6569 1605.46 51 1605.46H39C37.3431 1605.46 36 1604.12 36 1602.46V1591.38Z"
          fill="white"
        />
        <path
          d="M36 1641.46C36 1639.8 37.3431 1638.46 39 1638.46H51C52.6569 1638.46 54 1639.8 54 1641.46V1652.54C54 1654.19 52.6569 1655.54 51 1655.54H39C37.3431 1655.54 36 1654.19 36 1652.54V1641.46Z"
          fill="white"
        />
        <path
          d="M36 1691.54C36 1689.88 37.3431 1688.54 39 1688.54H51C52.6569 1688.54 54 1689.88 54 1691.54V1702.61C54 1704.27 52.6569 1705.61 51 1705.61H39C37.3431 1705.61 36 1704.27 36 1702.61V1691.54Z"
          fill="white"
        />
        <path
          d="M36 1741.61C36 1739.96 37.3431 1738.61 39 1738.61H51C52.6569 1738.61 54 1739.96 54 1741.61V1752.69C54 1754.35 52.6569 1755.69 51 1755.69H39C37.3431 1755.69 36 1754.35 36 1752.69V1741.61Z"
          fill="white"
        />
        <path
          d="M36 1791.69C36 1790.03 37.3431 1788.69 39 1788.69H51C52.6569 1788.69 54 1790.03 54 1791.69V1802.77C54 1804.43 52.6569 1805.77 51 1805.77H39C37.3431 1805.77 36 1804.43 36 1802.77V1791.69Z"
          fill="white"
        />
        <path
          d="M36 1841.77C36 1840.11 37.3431 1838.77 39 1838.77H51C52.6569 1838.77 54 1840.11 54 1841.77V1852.85C54 1854.5 52.6569 1855.85 51 1855.85H39C37.3431 1855.85 36 1854.5 36 1852.85V1841.77Z"
          fill="white"
        />
        <path
          d="M36 1891.85C36 1890.19 37.3431 1888.85 39 1888.85H51C52.6569 1888.85 54 1890.19 54 1891.85V1902.92C54 1904.58 52.6569 1905.92 51 1905.92H39C37.3431 1905.92 36 1904.58 36 1902.92V1891.85Z"
          fill="white"
        />
        <path
          d="M36 1941.92C36 1940.27 37.3431 1938.92 39 1938.92H51C52.6569 1938.92 54 1940.27 54 1941.92V1953C54 1954.66 52.6569 1956 51 1956H39C37.3431 1956 36 1954.66 36 1953V1941.92Z"
          fill="white"
        />
        <path
          d="M36 1992C36 1990.34 37.3431 1989 39 1989H51C52.6569 1989 54 1990.34 54 1992V2003.08C54 2004.73 52.6569 2006.08 51 2006.08H39C37.3431 2006.08 36 2004.73 36 2003.08V1992Z"
          fill="white"
        />
        <path
          d="M36 2042.08C36 2040.42 37.3431 2039.08 39 2039.08H51C52.6569 2039.08 54 2040.42 54 2042.08V2053.15C54 2054.81 52.6569 2056.15 51 2056.15H39C37.3431 2056.15 36 2054.81 36 2053.15V2042.08Z"
          fill="white"
        />
        <path
          d="M36 2092.15C36 2090.5 37.3431 2089.15 39 2089.15H51C52.6569 2089.15 54 2090.5 54 2092.15V2103.23C54 2104.89 52.6569 2106.23 51 2106.23H39C37.3431 2106.23 36 2104.89 36 2103.23V2092.15Z"
          fill="white"
        />
        <path
          d="M36 2142.23C36 2140.57 37.3431 2139.23 39 2139.23H51C52.6569 2139.23 54 2140.57 54 2142.23V2153.31C54 2154.96 52.6569 2156.31 51 2156.31H39C37.3431 2156.31 36 2154.96 36 2153.31V2142.23Z"
          fill="white"
        />
        <path
          d="M36 2192.31C36 2190.65 37.3431 2189.31 39 2189.31H51C52.6569 2189.31 54 2190.65 54 2192.31V2203.38C54 2205.04 52.6569 2206.38 51 2206.38H39C37.3431 2206.38 36 2205.04 36 2203.38V2192.31Z"
          fill="white"
        />
        <path
          d="M36 2242.38C36 2240.73 37.3431 2239.38 39 2239.38H51C52.6569 2239.38 54 2240.73 54 2242.38V2253.46C54 2255.12 52.6569 2256.46 51 2256.46H39C37.3431 2256.46 36 2255.12 36 2253.46V2242.38Z"
          fill="white"
        />
        <path
          d="M36 2292.46C36 2290.8 37.3431 2289.46 39 2289.46H51C52.6569 2289.46 54 2290.8 54 2292.46V2303.54C54 2305.19 52.6569 2306.54 51 2306.54H39C37.3431 2306.54 36 2305.19 36 2303.54V2292.46Z"
          fill="white"
        />
        <path
          d="M36 2342.54C36 2340.88 37.3431 2339.54 39 2339.54H51C52.6569 2339.54 54 2340.88 54 2342.54V2353.61C54 2355.27 52.6569 2356.61 51 2356.61H39C37.3431 2356.61 36 2355.27 36 2353.61V2342.54Z"
          fill="white"
        />
        <path
          d="M36 2392.61C36 2390.96 37.3431 2389.61 39 2389.61H51C52.6569 2389.61 54 2390.96 54 2392.61V2403.69C54 2405.35 52.6569 2406.69 51 2406.69H39C37.3431 2406.69 36 2405.35 36 2403.69V2392.61Z"
          fill="white"
        />
        <path
          d="M36 2442.69C36 2441.03 37.3431 2439.69 39 2439.69H51C52.6569 2439.69 54 2441.03 54 2442.69V2453.77C54 2455.43 52.6569 2456.77 51 2456.77H39C37.3431 2456.77 36 2455.43 36 2453.77V2442.69Z"
          fill="white"
        />
        <path
          d="M36 2492.77C36 2491.11 37.3431 2489.77 39 2489.77H51C52.6569 2489.77 54 2491.11 54 2492.77V2503.85C54 2505.5 52.6569 2506.85 51 2506.85H39C37.3431 2506.85 36 2505.5 36 2503.85V2492.77Z"
          fill="white"
        />
        <path
          d="M36 2542.85C36 2541.19 37.3431 2539.85 39 2539.85H51C52.6569 2539.85 54 2541.19 54 2542.85V2553.92C54 2555.58 52.6569 2556.92 51 2556.92H39C37.3431 2556.92 36 2555.58 36 2553.92V2542.85Z"
          fill="white"
        />
        <path
          d="M36 2592.92C36 2591.27 37.3431 2589.92 39 2589.92H51C52.6569 2589.92 54 2591.27 54 2592.92V2604C54 2605.66 52.6569 2607 51 2607H39C37.3431 2607 36 2605.66 36 2604V2592.92Z"
          fill="white"
        />
        <path
          d="M36 2643C36 2641.34 37.3431 2640 39 2640H51C52.6569 2640 54 2641.34 54 2643V2654.08C54 2655.73 52.6569 2657.08 51 2657.08H39C37.3431 2657.08 36 2655.73 36 2654.08V2643Z"
          fill="white"
        />
        <path
          d="M36 2693.08C36 2691.42 37.3431 2690.08 39 2690.08H51C52.6569 2690.08 54 2691.42 54 2693.08V2704.15C54 2705.81 52.6569 2707.15 51 2707.15H39C37.3431 2707.15 36 2705.81 36 2704.15V2693.08Z"
          fill="white"
        />
        <path
          d="M36 2743.15C36 2741.5 37.3431 2740.15 39 2740.15H51C52.6569 2740.15 54 2741.5 54 2743.15V2754.23C54 2755.89 52.6569 2757.23 51 2757.23H39C37.3431 2757.23 36 2755.89 36 2754.23V2743.15Z"
          fill="white"
        />
        <path
          d="M36 2793.23C36 2791.57 37.3431 2790.23 39 2790.23H51C52.6569 2790.23 54 2791.57 54 2793.23V2804.31C54 2805.96 52.6569 2807.31 51 2807.31H39C37.3431 2807.31 36 2805.96 36 2804.31V2793.23Z"
          fill="white"
        />
        <path
          d="M36 2843.31C36 2841.65 37.3431 2840.31 39 2840.31H51C52.6569 2840.31 54 2841.65 54 2843.31V2854.38C54 2856.04 52.6569 2857.38 51 2857.38H39C37.3431 2857.38 36 2856.04 36 2854.38V2843.31Z"
          fill="white"
        />
        <path
          d="M36 2893.38C36 2891.73 37.3431 2890.38 39 2890.38H51C52.6569 2890.38 54 2891.73 54 2893.38V2904.46C54 2906.12 52.6569 2907.46 51 2907.46H39C37.3431 2907.46 36 2906.12 36 2904.46V2893.38Z"
          fill="white"
        />
        <path
          d="M36 2943.46C36 2941.8 37.3431 2940.46 39 2940.46H51C52.6569 2940.46 54 2941.8 54 2943.46V2954.54C54 2956.19 52.6569 2957.54 51 2957.54H39C37.3431 2957.54 36 2956.19 36 2954.54V2943.46Z"
          fill="white"
        />
        <path
          d="M36 2993.54C36 2991.88 37.3431 2990.54 39 2990.54H51C52.6569 2990.54 54 2991.88 54 2993.54V3004.61C54 3006.27 52.6569 3007.61 51 3007.61H39C37.3431 3007.61 36 3006.27 36 3004.61V2993.54Z"
          fill="white"
        />
        <path
          d="M36 3043.61C36 3041.96 37.3431 3040.61 39 3040.61H51C52.6569 3040.61 54 3041.96 54 3043.61V3054.69C54 3056.35 52.6569 3057.69 51 3057.69H39C37.3431 3057.69 36 3056.35 36 3054.69V3043.61Z"
          fill="white"
        />
        <path
          d="M36 3093.69C36 3092.03 37.3431 3090.69 39 3090.69H51C52.6569 3090.69 54 3092.03 54 3093.69V3104.77C54 3106.43 52.6569 3107.77 51 3107.77H39C37.3431 3107.77 36 3106.43 36 3104.77V3093.69Z"
          fill="white"
        />
        <path
          d="M36 3143.77C36 3142.11 37.3431 3140.77 39 3140.77H51C52.6569 3140.77 54 3142.11 54 3143.77V3154.85C54 3156.5 52.6569 3157.85 51 3157.85H39C37.3431 3157.85 36 3156.5 36 3154.85V3143.77Z"
          fill="white"
        />
        <path
          d="M36 3193.85C36 3192.19 37.3431 3190.85 39 3190.85H51C52.6569 3190.85 54 3192.19 54 3193.85V3204.92C54 3206.58 52.6569 3207.92 51 3207.92H39C37.3431 3207.92 36 3206.58 36 3204.92V3193.85Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="68"
      height="3400"
      viewBox="0 0 68 3400"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M68 0H0V3263.41L34 3400L68 3263.41V0Z" fill={color} />
    </svg>
  )
}
