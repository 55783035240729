import { DragZoom } from '../../DragZoom'
import { checkAndCallFunction } from '../../utils'
export class DraggingEvent {
  constructor(props, events) {
    this.props = props
    this.instance = events.rangeUpdate
    this.events = events
  }

  setInstance(instance) {
    this.instance = instance
  }

  mouseDown = X => {
    const { darkMode, keyboardKeys } = this.props
    if (keyboardKeys.get(17)) {
      this.setInstance(new DragZoom(X.ctx, X.rect, this.events.zoom, darkMode))
    }

    checkAndCallFunction(this.instance.mouseDown, X)
  }

  mouseMove = X => {
    checkAndCallFunction(this.instance.mouseMove, X)
  }

  mouseRelease = X => {
    checkAndCallFunction(this.instance.mouseRelease, X)
  }
}
