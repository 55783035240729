import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import BaseWidget from '../BaseWidget'
import { CrossCorrelogram } from './CrossCorrelogram'
import { CrossCorrelogramXAxis, CrossCorrelogramYAxis } from './components'
import {
  MAIN_APPBAR_HEIGHT,
  WIDGET_WRAPPER_HEADER
} from '../../../../../../utils/styles'
import { useUnitsGen } from '../../hooks'

const useStyles = makeStyles(theme => ({
  baseWidget: {
    padding: 10,
    justifyContent: 'space-between',
    width: '100%'
  },
  crossCorrelogramWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  crossCorelogramPlot: {
    display: 'flex',
    width: '100%'
  },
  plotsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  binSize: {
    color: '#d3595d'
  }
}))

const WH = level => {
  const widthPercentage = level === 0 ? 50 : 25
  const heightPercentage = level > 2 ? 50 : 100

  const absoluteWidth = (window.innerWidth * widthPercentage) / 100
  const absoluteHeight =
    ((window.innerHeight - 57 - MAIN_APPBAR_HEIGHT - WIDGET_WRAPPER_HEADER) *
      heightPercentage) /
    100

  const PADDING = 8

  return {
    W: absoluteWidth,
    H: absoluteHeight,
    PADDING
  }
}

const HeaderCorrelogram = () => {
  const classes = useStyles()

  const binSize = useSelector(state => state.widgetsCache.correlograms.binSize)

  if (!binSize) return null

  return <p className={classes.binSize}>{binSize} ms bins</p>
}

export const correlogramPlugins = {
  header: HeaderCorrelogram
}

export const CorrelogramView = ({ recordingId, level }) => {
  const classes = useStyles()

  const { W, H, PADDING } = useMemo(() => WH(level), [level])

  const { uniqueUnits, ndArray, render } = useUnitsGen()

  return render(() => (
    <BaseWidget overrideClassName={classes.baseWidget}>
      <div className={classes.crossCorelogramPlot}>
        <CrossCorrelogramYAxis
          data={uniqueUnits}
          height={H / (uniqueUnits.length + 1)}
        />
        <div className={classes.plotsWrapper}>
          <div className={classes.crossCorrelogramWrapper}>
            <CrossCorrelogram
              data={ndArray}
              width={W / ndArray.length - 20}
              height={H / (ndArray.length + 1)}
              PADDING={PADDING}
              recordingId={recordingId}
            />
          </div>
          <CrossCorrelogramXAxis
            width={W / ndArray.length - 20}
            data={uniqueUnits}
          />
        </div>
      </div>
    </BaseWidget>
  ))
}
