import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Logo from '../../imgs/Logo'
import { Grid, Typography } from '@material-ui/core'
import CustomButton from '../CustomButton'
import useSubmitTerms from './hooks/useSubmitTerms'
import MapData from '../../utils/MapData'
import cn from 'classnames'
import useTermsConditions from './hooks/useTermsConditions'
import { connect } from 'react-redux'
import { acceptTerms } from '../../actions/login'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 66,
    padding: '0 40px',
    background: theme.palette.colors.mainColor
  },
  container: {
    height: 'calc(100vh - 66px)',
    maxWidth: 565,
    padding: '40px 0',
    margin: '0 auto'
  },
  body: {
    border: `1px solid ${theme.palette.colors.neutral['8']}`,
    padding: 40,
    flex: 1,
    overflowY: 'auto',
    width: '100%',
    borderRadius: 10,
    marginBottom: 16,
    '&::-webkit-scrollbar': {
      width: 13
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.colors.neutral['8'],
      border: '4px solid #fff',
      borderRadius: 11
    }
  },
  title: {
    fontSize: 19,
    lineHeight: '20px',
    fontWeight: 600,
    color: theme.palette.colors.neutral.black,
    marginBottom: 24
  },
  termsBlock: {
    marginBottom: 20
  },
  contentText: {
    fontSize: 12,
    lineHeight: '24px',
    letterSpacing: 0.1,
    color: theme.palette.colors.neutral['2']
  },
  subtitle: {
    fontWeight: 600
  }
}))

const TermsConditions = ({ acceptTerms }) => {
  const classes = useStyles()
  const { onSubmit, isSubmitting } = useSubmitTerms({
    acceptTerms
  })
  const terms = useTermsConditions()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Logo />
      </div>
      <Grid
        container
        alignItems="flex-end"
        justify="center"
        direction="column"
        className={classes.container}
      >
        <div className={classes.body}>
          <Typography className={classes.title} variant="h2">
            Terms and Conditions
          </Typography>
          <MapData data={terms}>
            {({ item: { title, items }, index }) => (
              <div className={classes.termsBlock} key={index}>
                <Typography
                  className={cn(classes.contentText, classes.subtitle)}
                  variant="h3"
                >
                  {title}
                </Typography>
                <MapData data={items}>
                  {({ item, index }) => (
                    <Typography className={classes.contentText} key={index}>
                      {item}
                    </Typography>
                  )}
                </MapData>
              </div>
            )}
          </MapData>
        </div>
        <CustomButton
          label="Accept Terms and Conditions"
          width="auto"
          onClick={onSubmit}
          loading={isSubmitting}
        />
      </Grid>
    </div>
  )
}

const mapStateToProps = state => ({
  currentUser: state.login.currentUser
})

const mapDispatchToProps = dispatch => ({
  acceptTerms: () => dispatch(acceptTerms())
})

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions)
