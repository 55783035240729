import { useCallback, useState } from 'react'
import { MODALS_ID, NOTIFICATION } from '../../../utils/consts'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import { addModal, closeModal } from '../../../actions/modals'
import { moveTo } from '../../../endpoints/admin'
import useGetAllRecords from '../../FilesTable/hooks/useGetAllRecords'

function useMoveToModal() {
  const dispatch = useDispatch()
  const modalID = MODALS_ID.MOVE_TO_MODAL
  const modalState = useSelector(state => state.modals?.get(modalID))
  const { recordType, recordId } = modalState?.args || {}
  const [loading, setLoading] = useState(false)
  const addNotification = useNotification()
  const { fetchRecords } = useGetAllRecords(false)
  const toggleModal = useCallback(
    (selectedRecordId, type) => {
      if (modalState) return dispatch(closeModal({ id: modalID }))
      dispatch(
        addModal({
          id: modalID,
          args: {
            recordId: selectedRecordId,
            recordType: type
          }
        })
      )
    },
    [dispatch, modalID, modalState]
  )

  const handleSubmit = useCallback(
    async ({ data = {} }) => {
      try {
        setLoading(true)
        const { status } = await moveTo({
          object_id: recordId,
          object_type: recordType,
          create_folder: 0,
          ...data
        })
        if (status === 200) {
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: `The ${recordType} was successfully moved!`
          })
          fetchRecords()
          toggleModal()
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        toggleModal()
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [recordId, recordType, addNotification, fetchRecords, toggleModal]
  )

  return {
    toggleModal,
    isOpened: Boolean(modalState),
    onSubmit: handleSubmit,
    loading
  }
}

export default useMoveToModal
