import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ActionButton from '../../../ActionButton'
import SearchBar from '../../../Files/components/Header/components/SearchBar'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  root: {},
  input: {
    background: theme.palette.colors.backgrounds.fileHover
  },
  action: {
    width: 'auto',
    minWidth: 'auto',
    borderRadius: 8
  }
}))

export default function Header({ className, onAddUsers }) {
  const classes = useStyles()
  return (
    <Grid
      container
      wrap="nowrap"
      justify="space-between"
      alignItems="center"
      className={cn(className, classes.root)}
    >
      <SearchBar placeholder="Search" inputBaseRootClass={classes.input} />
      <ActionButton
        className={classes.action}
        startIcon={<AddIcon />}
        label="Add users"
        onClick={onAddUsers}
      />
    </Grid>
  )
}
