import React, { memo } from 'react'
import ActionButton from '../../../ActionButton'
import CustomModal from '../../../CustomModal'
import { DTYPES, EVENT_FILE_TYPE } from '../../../../utils/consts'
import { IconButton, makeStyles, Typography, useTheme } from '@material-ui/core'
import CustomDropzone from '../../../FormComponents/CustomDropzone'
import MapData from '../../../../utils/MapData'
import { DeleteRounded, InsertDriveFileRounded } from '@material-ui/icons'
import cn from 'classnames'
import CheckboxesGroup from '../../../CheckboxesGroup'
import Grid from '@material-ui/core/Grid'
import CustomInput from '../../../FormComponents/CustomInput/CustomInput'
import ProgressBar from '../../../ProgressBar/ProgressBar'
import SelectMenu from '../../../SelectMenu'
import useEventsFileUpload from './hooks/useEventsFileUpload'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 24,
    lineHeight: '18px',
    color: theme.palette.colors.neutral.black
  },
  removeBtn: {
    marginRight: 0,
    display: 'block',
    marginLeft: 'auto',
    padding: 0,
    '& svg': {
      color: theme.palette.colors.neutral[5]
    }
  },
  filesTable: {
    width: '100%',
    marginTop: 24
  },
  fileIcon: {
    color: theme.palette.colors.accent[5],
    marginRight: 5
  },
  fileName: {
    marginBottom: 0
  },
  description: {
    color: theme.palette.colors.neutral.black,
    '& span': {
      fontWeight: 600
    }
  },
  checkboxesGroup: {
    marginBottom: 20
  },
  checkboxLabel: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  },

  file: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    fontSize: 16
  },
  progressBar: {
    minWidth: 110,
    justifyContent: 'flex-end'
  },
  inputsRoot: {
    marginBottom: 12,
    paddingTop: 12,
    '& hr': {
      border: 'none',
      margin: '0 12px 12px',
      width: 'calc(100% - 24px)',
      backgroundColor: theme.palette.colors.neutral[7],
      borderTop: `1px solid ${theme.palette.colors.neutral[7]}`
    }
  }
}))

const EventsFileUploadModal = () => {
  const theme = useTheme()
  const classes = useStyles()
  const {
    isUpload,
    loading,
    toggleModal,
    isOpened,
    onUpload,
    onSubmit,
    onDelete,
    eventFiles,
    multipartFiles,
    uploadProgress,
    loadingPresignedUr,

    view,
    params,
    handleChange,
    handleChangeView,
    views,
    isMultipart
  } = useEventsFileUpload()
  const { number_channels, sample_rate, dtype } = params || {}

  return (
    <CustomModal
      open={isOpened}
      title="Upload file event"
      onClose={toggleModal}
      maxWidth={700}
      renderActionButton={
        <ActionButton
          label="Upload"
          disabled={isUpload}
          loading={loading}
          onClick={onSubmit}
          customStyles={{
            backgroundColor: theme.palette.action.secondary
          }}
        />
      }
    >
      <Typography className={classes.label}>Upload file events</Typography>

      <CheckboxesGroup
        value={view}
        handleChange={handleChangeView}
        radios={views}
        className={classes.checkboxesGroup}
      />

      {isMultipart && (
        <Grid container spacing={3} className={classes.inputsRoot}>
          <hr />
          <Grid item xs={12}>
            <CustomInput
              required
              type="number"
              name="number_channels"
              value={number_channels}
              onChange={handleChange}
              label="Number of Channels"
            />
          </Grid>
          <Grid item xs={6}>
            <CustomInput
              required
              type="number"
              name="sample_rate"
              value={sample_rate}
              onChange={handleChange}
              label="Sample Rate"
            />
          </Grid>
          <Grid item xs={6}>
            <SelectMenu
              onChangeValue={value => handleChange({ name: 'dtype', value })}
              items={DTYPES}
              value={dtype}
              textFieldProps={{
                variant: 'outlined',
                label: 'Dtype'
              }}
            />
          </Grid>
        </Grid>
      )}

      <CustomDropzone
        accept={isMultipart ? ['.dat'] : ['.evt']}
        onUpload={onUpload}
        loading={loadingPresignedUr}
      />
      <FilesTable
        classes={classes}
        isMultipart={isMultipart}
        multipartFiles={multipartFiles}
        eventFiles={eventFiles}
        uploadProgress={uploadProgress}
        onDelete={onDelete}
      />
    </CustomModal>
  )
}

const FilesTable = memo(
  ({
    classes,
    isMultipart,
    multipartFiles,
    eventFiles,
    onDelete,
    uploadProgress
  }) => {
    return (
      <table className={classes.filesTable}>
        <tbody>
          <MapData data={isMultipart ? multipartFiles : eventFiles}>
            {({ item }) => {
              const { file, id, _isUploading } = item
              return (
                <tr className={classes.row}>
                  <td style={{ width: 30 }}>
                    <InsertDriveFileRounded className={classes.fileIcon} />
                  </td>
                  <td>
                    <Typography className={cn(classes.label, classes.fileName)}>
                      {file.name}
                    </Typography>
                  </td>
                  {isMultipart && _isUploading && (
                    <td>
                      <ProgressBar
                        overrideClasses={{
                          root: classes.progressBar
                        }}
                        progress={uploadProgress.toFixed() || 0}
                      />
                    </td>
                  )}
                  <td>
                    <IconButton
                      className={classes.removeBtn}
                      onClick={() => onDelete(id)}
                    >
                      <DeleteRounded />
                    </IconButton>
                  </td>
                </tr>
              )
            }}
          </MapData>
        </tbody>
      </table>
    )
  }
)

export default EventsFileUploadModal
