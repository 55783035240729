import React, { useState, useEffect } from 'react'
import { getOS } from '../../../utils/utils'

const ctrlKey = keyCode => {
  return getOS() === 'Mac OS' && keyCode === 91 ? 17 : keyCode
}

export const useCaptureKeys = ({ keyDownEvent, keyUpEvent } = {}) => {
  const [keyboardKeys, setKeys] = useState(new Map())
  const [keyEvent, setKeyEvent] = useState(null)
  const onKeyDown = e => {
    const { keyCode, repeat, path } = e
    const isInput = path?.some(item => item?.tagName?.toLowerCase() === 'input')

    if (repeat || isInput) return

    setKeyEvent(e)
    setKeys(current => {
      const keys = new Map(
        Array.from(current).concat([[ctrlKey(keyCode), true]])
      )

      if (keyDownEvent) {
        keyDownEvent(keys)
      }

      return keys
    })
  }

  const onKeyUp = e => {
    const { keyCode, path } = e
    const isInput = path?.some(item => item?.tagName?.toLowerCase() === 'input')

    if (isInput) return

    setKeys(
      current =>
        new Map(Array.from(current).concat([[ctrlKey(keyCode), false]]))
    )
  }

  const registerEventListener = keyDownEvent ? [keyDownEvent] : []

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, registerEventListener)

  return { keyboardKeys, onKeyDown, onKeyUp, keyEvent }
}

const CaptureKeyEvent = ({ children }) => {
  const { keyboardKeys, keyEvent } = useCaptureKeys()

  return React.cloneElement(children, {
    keyboardKeys,
    keyEvent
  })
}

export default CaptureKeyEvent
