import { ADD, EDIT, RESET } from '../actions'

const initialState = {
  chunks: {},
  draw: {
    x: 0,
    y: 0
  }
}

export const biochipData = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD:
      return payload
    case EDIT:
      return { ...state, ...payload }
    case RESET:
      return initialState
    default:
      return state
  }
}
