import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import CardItem from '../CardItem'
import cn from 'classnames'
import MapData from '../../utils/MapData/MapData'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: 30
  },
  gridRoot: {
    width: 'auto',
    margin: '0 -5px'
  },
  card: {
    width: '100%',
    padding: '0 5px 16px'
  }
}))

const CardContainer = props => {
  const classes = useStyles()
  const { cards, className } = props

  return (
    <div className={classes.root}>
      <Grid container className={cn(className, classes.gridRoot)}>
        <MapData data={cards}>
          {({ item: { title, content }, index }) => (
            <Grid item xs={6} key={index} className={classes.card}>
              <CardItem title={title} content={content} {...props} />
            </Grid>
          )}
        </MapData>
      </Grid>
    </div>
  )
}

export default CardContainer
