import { useCallback, useMemo, useReducer, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { mv_default_channel_colors } from '../../../../../../containers/NewTimeSeries/utils'
import { toggleChannel } from '../../../redux/actions'
import { useSafeDispatch } from '../../../../../../hooks/useSafeDispatch'
import { findProbeDrawing } from '../../DrawProbe/DrawProbe'
import { chunkGroups } from '../../../utils'

function useGetBiochipsData(reducer = {}) {
  const defaultColors = mv_default_channel_colors()
  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)

  const [IOCState, IOCDispatch] = useReducer(
    reducer.reducer,
    reducer.initialState
  )

  const { selectedGroups, allChannels: selectedChannels } = useSelector(
    state => state.timeSeriesLeftPanel,
    shallowEqual
  )

  const biochipData = useSelector(state => state.biochipData)

  const {
    chunks,
    draw,
    channel_positions,
    firstGroupChannels,
    multigroup,
    splitGroup
  } = biochipData || {}

  const foundDraw = findProbeDrawing(draw)

  const addX = foundDraw?.x ?? 0
  const addY = foundDraw?.y ?? 0

  const getSubgroups = ch => {
    const gr = chunkGroups(ch, 32)
    return gr.map((channels, index) => {
      const length = defaultColors.length
      const color = defaultColors[index % length]
      return { channels, color }
    })
  }

  const getBiochipsData = useMemo(
    () =>
      Object.keys(chunks).map(g => {
        const length = defaultColors.length
        // if is multigroup mode then every 2 shanks sould have the same color
        const color = multigroup
          ? defaultColors[(+g % 2 ? +g - 1 : +g) % length]
          : defaultColors[+g % length]
        const ch = chunks[g]
          .map((channel, idx) => ({
            key: channel,
            label: channel,
            x: channel_positions[firstGroupChannels[idx]]
              ? channel_positions[firstGroupChannels[idx]][0] + addX
              : addX || 0,
            y: channel_positions[channel]
              ? channel_positions[channel][1] + addY
              : addY || 0
          }))
          .reverse()

        return {
          channels: ch,
          ...(splitGroup ? { subGroups: getSubgroups(ch) } : {}),
          color,
          group: g
        }
      }),
    [chunks, splitGroup]
  )

  const addData = useCallback(
    ({ channel = null, group = null }) =>
      safeDispatch(toggleChannel(channel, group)),
    [safeDispatch]
  )

  const handleToggleChannel = useCallback(
    channel => {
      addData({ channel })
    },
    [addData]
  )

  const handleToggleGroup = useCallback(
    group => {
      addData({ group })
    },
    [addData]
  )

  useEffect(() => {
    if (IOCState) {
      IOCDispatch({
        type: 'ADD_DEFAULT_CHANNELS',
        payload: { channels: biochipData }
      })
    }
  }, [biochipData])

  if (IOCState) {
    return {
      biochipsData: getBiochipsData,
      selectedChannels: IOCState.channels,
      selectedGroups: IOCState.selectedGroups,
      disabledChannels: IOCState.disabledChannels,
      toggleChannel: channel => IOCDispatch(toggleChannel(channel, null)),
      handleToggleGroup: group => IOCDispatch(toggleChannel(null, group))
    }
  }

  return {
    multigroup,
    toggleChannel: handleToggleChannel,
    selectedChannels,
    biochipsData: getBiochipsData,
    handleToggleGroup,
    selectedGroups,
    showMaping: Boolean(foundDraw), // type === 'default' // Why here we are checking here the probe file type ?
    draw,
    splitGroup
  }
}

export default useGetBiochipsData
