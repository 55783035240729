import React, { memo, useCallback, useMemo } from 'react'
import BaseWidget from '../BaseWidget'
import WidgetLoader from '../../components/WidgetLoader'
import { getOr, isEmpty } from 'lodash/fp'
import InfiniteList from '../../components/InfiniteList'
import useSimilarityView from './hooks/useSimilarityView'
import EmptyWidget from '../../components/EmptyWidget'
import { useDispatch, useSelector } from 'react-redux'
import { saveSimilarityUnits } from '../../redux/actions'
import useRowsSelection from '../../components/InfiniteList/hooks/useRowsSelection'
import { HideRecommandation } from '../ClusterView'
import CurationAIIcon from '../../../../../Icons/curationAIIcon'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core'

const columns = [
  { id: 'id', active: true, label: 'ID' },
  { id: 'similarity', active: true, label: 'Similarity' },
  { id: 'ch', active: true, label: 'Ch.' },
  { id: 'sh', active: true, label: 'Group' },
  { id: 'depth', active: true, label: 'Depth' },
  { id: 'fr', active: true, label: 'Firing R. (Hz)' },
  { id: 'amp', active: true, label: 'Amplitude' },
  { id: 'n_spikes', active: true, label: 'Spikes' }
]

export const SimilarityView = () => {
  const {
    selectedSorting,
    state,
    loadMore,
    storedData,
    selectedUnits,
    refetch,
    isCurationAI,
    onSort,
    sortBy,
    sortDirection,
    recommendations,
    noiseRecommendations,
    isSuggestionVisible,
    toggleRecommendation
  } = useSimilarityView()
  const { loading, error } = state || {}
  const data = getOr(null, ['data'], storedData)
  const { to_merge, clusters, total } = data || {}

  const dispatch = useDispatch()

  const selectedRows = useSelector(state => state.widgetsCache.units.similarity)

  const saveUnits = useCallback(
    data => {
      return dispatch(saveSimilarityUnits(data))
    },
    [dispatch]
  )

  const getSortedClusters = useMemo(() => {
    let filteredClusters = [],
      mergeSuggestions = to_merge

    if (clusters)
      clusters.forEach(cluster => {
        if (recommendations?.includes(Number(cluster.id))) {
          mergeSuggestions = [...mergeSuggestions, cluster]
        } else filteredClusters = [...filteredClusters, cluster]
      })
    return { mergeSuggestions, filteredClusters }
  }, [clusters, recommendations, to_merge])

  const handleRowSelect = useRowsSelection(saveUnits)

  const renderActionButton = useCallback(
    id => {
      const isVisible = isSuggestionVisible(id)
      const isNoiseUnit = noiseRecommendations?.includes(id)

      return (
        <HideRecommandation
          id={'similarity-view-base-merge-visibility-icon'}
          isVisible={isVisible}
          isNoiseUnit={isNoiseUnit}
          toggleRecommendation={() => toggleRecommendation(id)}
        />
      )
    },
    [isSuggestionVisible, noiseRecommendations, toggleRecommendation]
  )

  const { mergeSuggestions, filteredClusters } = getSortedClusters
  const hasSuggestions = isCurationAI && !isEmpty(mergeSuggestions)

  const list = useMemo(() => {
    if (!hasSuggestions) return clusters
    return [
      { Component: AIBanner },
      ...(mergeSuggestions || []),
      { Component: Divider },
      ...(filteredClusters || [])
    ]
  }, [clusters, filteredClusters, hasSuggestions, mergeSuggestions])

  const isListEmpty = isCurationAI
    ? isEmpty(clusters) && isEmpty(mergeSuggestions)
    : isEmpty(clusters)

  const isError = !selectedSorting || error || isListEmpty

  const componentsOffset = hasSuggestions ? 2 : 0 // to account for divider and AIBanner

  if (isEmpty(selectedUnits)) return <EmptyWidget />

  return (
    <BaseWidget>
      <WidgetLoader
        refetch={refetch}
        requestFailed={error}
        error={isError}
        message="Failed to read similarity"
        loading={loading && isEmpty(clusters)}
        isLoadMore={loading && !isEmpty(clusters)}
      >
        <InfiniteList
          parentId={'similarity-view-base'}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSort={onSort}
          columns={columns}
          list={list}
          loadMore={loadMore}
          total={total + componentsOffset}
          handleRowSelect={handleRowSelect}
          selectedRows={selectedRows}
          recommendations={recommendations}
          isSuggestionVisible={isSuggestionVisible}
          renderActionButton={renderActionButton}
          noiseRecommendations={noiseRecommendations}
        />
      </WidgetLoader>
    </BaseWidget>
  )
}

const AIBanner = memo(() => {
  const theme = useTheme()
  return (
    <span
      style={{
        width: 100000,
        height: 24,
        display: 'flex',
        paddingLeft: 8,
        backgroundColor: theme.palette.colors.customColor(
          theme.palette.colors.neutral[9],
          '#232323'
        ),
        alignItems: 'center',
        position: 'absolute',
        borderWidth: ' 0.835777px 0px 0px  0px',
        borderColor: theme.palette.colors.customColor(
          theme.palette.colors.neutral[7],
          theme.palette.colors.neutral[3]
        ),
        borderStyle: 'solid'
      }}
    >
      <CurationAIIcon
        style={{ height: 13, width: 13, verticalAlign: 'middle' }}
      />
      <Typography
        style={{
          fontSize: 9,
          fontWeight: 600,
          lineHeight: '13px',
          marginLeft: 4,
          display: 'inline',
          position: 'sticky',
          top: 3,
          color: theme.palette.colors.customColor(
            theme.palette.colors.neutral[100],
            'white'
          )
        }}
      >
        AI suggestions
      </Typography>
    </span>
  )
})

const Divider = memo(() => {
  const theme = useTheme()
  return (
    <span
      style={{
        width: '-webkit-fill-available',
        height: '-webkit-fill-available',
        backgroundColor: theme.palette.colors.customColor(
          theme.palette.colors.neutral[9],
          '#232323'
        ),
        position: 'absolute',
        borderWidth: ' 0px 0px 0.835777px 0px',
        borderColor: theme.palette.colors.customColor(
          theme.palette.colors.neutral[7],
          theme.palette.colors.neutral[3]
        ),
        borderStyle: 'solid'
      }}
    />
  )
})
