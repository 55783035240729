import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Typography, Button } from '@material-ui/core'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  button: ({
    boxShadow = 'none',
    backgroundColor = theme.palette.colors.mainColor,
    height = 48,
    width = '100%',
    borderRadius = 8,
    hoverColor = null,
    color = 'white'
  }) => ({
    position: 'relative',
    backgroundColor,
    width,
    fontWeight: 600,
    borderWidth: 1,
    borderColor: color,
    borderStyle: 'solid',
    borderRadius: borderRadius || 8,
    height,
    minHeight: height,
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    color: color,
    boxShadow,
    '&:hover': {
      backgroundColor: hoverColor ?? theme.palette.colors.mainHover,
      boxShadow
    }
  }),
  customLabel: {
    pointerEvents: 'none'
  },
  label: props => ({
    pointerEvents: 'none',
    textTransform: 'none',
    fontSize: props.fontSize ? props.fontSize : 16,
    fontWeight: 600,
    lineHeight: '20px',
    padding: '5px 0'
  }),
  icon: {
    display: 'flex',
    marginRight: 20
  },
  hidden: {
    visibility: 'hidden'
  },
  loadingWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const CustomButton = props => {
  const {
    onClick,
    loading,
    loadingColor = null,
    label,
    className,
    leftAdornment,
    disabled = false,
    tooltip,
    endIcon,
    ...others
  } = props
  const classes = useStyles(props)

  return (
    <Button
      onClick={loading ? () => undefined : onClick}
      variant="contained"
      className={cn(classes.button, className)}
      classes={{
        label: classes.customLabel
      }}
      disabled={disabled}
      {...others}
      endIcon={endIcon}
    >
      {Boolean(leftAdornment) && (
        <div
          className={cn(classes.icon, {
            [classes.hidden]: loading
          })}
        >
          {leftAdornment}
        </div>
      )}
      <Typography
        noWrap
        className={cn(classes.label, {
          [classes.hidden]: loading
        })}
      >
        {label}
      </Typography>
      {loading && (
        <div className={classes.loadingWrapper}>
          <CircularProgress
            disableShrink
            color={loadingColor ?? 'primary'}
            className={cn(classes.label)}
            size={18}
          />
        </div>
      )}
    </Button>
  )
}

export default CustomButton
