import React, { Component } from 'react'
import CanvasWidget, { CanvasWidgetLayer } from '../jscommon/CanvasWidget'
import Grid from '@material-ui/core/Grid'
import TimeWidgetToolBar from './TimeWidgetToolBar'
import SpanWidget from './SpanWidget'
import { BottomToolbar, InnerContainer } from './components'
import { TimeSeriesEventTemplate } from './plugins/TimeSeriesEvent'
import {
  BOTTOM_TOOLBAR_HEIGHT,
  TIME_AXIS_LAYER_HEIGHT,
  TOTAL_HEIGHT_MULTI_VIEW,
  TOTAL_HEIGHT_SINGLE_VIEW
} from '../../utils/styles/CONSTANTS'
import RasterWidget from './RasterWidget'
import ResizableWidgets from './ResizableWidgets'
import SliceButtons from './components/SliceButtons'
import CutLabels from './components/CutLabels'
import { DATA_MANIPULATION_MODE, TS_CURSOR_TYPE } from '../../utils/consts'
import { hexToRgba } from '../../utils/styles'
import { colors } from '../../theme'
import { isEmpty, isEqual } from 'lodash/fp'
import { toHumanTimeMs } from './fns'

export { PainterPath } from '../jscommon/CanvasWidget'

const flatArr = arr => arr.flat()
const flatPanels = panels => flatArr(panels)

export default class TimeWidget extends Component {
  constructor(props) {
    super(props)
    this._anchorTimeRange = null
    this._dragging = false
    this._toolbarWidth = 50
    this._paintPanelCode = 0
    this._statusText = ''
    this._timeRange = [0, 10000]
    this._currentTime = null

    // this._timeAxisLayer = new CanvasWidgetLayer(this.paintTimeAxisLayer)
    this._cursorLayer = new CanvasWidgetLayer(this.paintCursorLayer, {
      zIndex: 501
    })
    this._drawBoxLayer = new CanvasWidgetLayer(this.painboxLayer, {
      zIndex: 500
    })
    this._dataManipulationLayer = new CanvasWidgetLayer(
      this.paintCutSliceLayer,
      {
        zIndex: 501
      }
    )

    this._graphLayers = []

    this.state = {
      bottomBarInfo: { show: true },
      spanWidgetInfo: {},
      allLayers: [],
      timeseriesEvents: {},
      deltaResize: 0,
      heights: {
        timeseries: 0,
        rasterPlot: 0,
        maxHeight: 0
      },
      rasterPlotState: false,

      isDragging: false,
      zoomParams: null,
      originalPanels: null,

      // slice buttons position
      pos: null,

      // events
      selectedEvent: null
    }

    this._cursorLayer.setMargins(
      this.props.timeSeriesView === 'multiple' ? 10 : 50,
      10,
      15,
      25
    )
  }

  componentDidMount() {
    this._repaintTimeSeriesLayers()
    this.setState({
      timeseriesEvents: this.timeseriesEvents(
        this.props.keyboardKeys,
        this.props.darkMode
      ),
      heights: {
        timeseries: this.height,
        rasterPlot: this.props.rasterData.layerHeight,
        maxHeight: this.maxHeight
      }
    })
    this._updateInfo()
    if (this.props.currentTime !== undefined) {
      this._currentTime = this.props.currentTime
    }
    if (this.props.timeRange) {
      this.setTimeRange(this.props.timeRange)
    }
    this.updateLayout()
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.keyEvent && prevProps.keyEvent !== this.props.keyEvent) {
      this.handle_key_press(this.props.keyEvent)
    }
    if (prevProps.dataManipulationMode !== this.props.dataManipulationMode) {
      this.props.getInitialZoom()
      this.clearHighlightedChannels()
      this.clearDataManipulationArea()
    }
    if (prevProps.keyboardKeys !== this.props.keyboardKeys) {
      this.setState({
        timeseriesEvents: this.timeseriesEvents(
          this.props.keyboardKeys,
          this.props.darkMode
        )
      })
    }

    if (isEmpty(this.state.originalPanels) && !isEmpty(this.props.panels)) {
      this.setState({ originalPanels: this.props.panels })
    }
    const timestamp = this.props.selectedEvent?.timestamp
    const isSameEventProp = prevProps.selectedEvent?.timestamp === timestamp
    if (this.props.selectedEvent && !isSameEventProp && timestamp !== 0) {
      const _timestamp = Number(timestamp)
      this.setCurrentTime(_timestamp)
      this.ensureCurrentTimeVisibleByChangingTimeRange()
    }
    if (this.props.selectedEvent !== prevProps.selectedEvent) {
      this.setState({
        selectedEvent: this.props.selectedEvent
      })
    }
    if (!isEqual(this.props.eventsTimestamps, prevProps.eventsTimestamps)) {
      this._cursorLayer.repaint()
    }
    if (this.props.timeSeriesView !== prevProps.timeSeriesView) {
      this.onChangeView(this.props.timeSeriesView)
    }
    if (this.props.currentTime !== prevProps.currentTime) {
      this.setCurrentTime(this.props.currentTime)
    }
    if (this.props.timeRange !== prevProps.timeRange) {
      this.setTimeRange(this.props.timeRange)
    }
    if (this.props.panels !== prevProps.panels) {
      this._repaintTimeSeriesLayers()
      // const isReapplyZoom =
      //   this.props.isZoomActive &&
      //   this.originalPanelsWithRange.length !== this.props.zoomedChannels?.size
      // if (isReapplyZoom) {
      //   const zoomedChannels = new Set(
      //     this._zoomedPanels({
      //       X: this.state.zoomParams,
      //       isWithOriginalPanels: true
      //     })
      //   )
      //   this.props.zoomDrag(zoomedChannels)
      // }
    }
    if (
      prevProps.rasterData.spikes === undefined &&
      this.props.rasterData.spikes
    ) {
      this.setState({
        heights: {
          ...this.state.heights,
          timeseries: this.height,
          rasterPlot: this.props.rasterData.layerHeight
        }
      })
    }
    if (
      this.props.timeSeriesView !== prevProps.timeSeriesView ||
      this.props.fullScreenMode !== prevProps.fullScreenMode
    ) {
      this.setState({
        heights: {
          ...this.state.heights,
          maxHeight: this.maxHeight
        }
      })
    }
    if (prevState.rasterPlotState !== this.state.rasterPlotState) {
      this.setState({
        heights: {
          timeseries: this.height,
          rasterPlot: this.props.rasterData.layerHeight,
          maxHeight: this.maxHeight
        }
      })
    }

    this._updateInfo()
    this.updateLayout()
  }

  get isSingleColumnView() {
    return this.props.timeSeriesView === 'single'
  }

  get _initialLayers() {
    const baseLayers = {
      cursorLayer: this._cursorLayer,
      drawBoxLayer: this._drawBoxLayer,
      dataManipulationLayer: this._dataManipulationLayer
    }

    return this.isSingleColumnView
      ? {
          ...baseLayers
        }
      : baseLayers
  }

  get maxHeight() {
    const { height, fullScreenMode } = this.props

    if (!this.isSingleColumnView) {
      return fullScreenMode
        ? height - BOTTOM_TOOLBAR_HEIGHT
        : height - TOTAL_HEIGHT_MULTI_VIEW
    }

    const H0 = fullScreenMode
      ? height - BOTTOM_TOOLBAR_HEIGHT - 20
      : height - TOTAL_HEIGHT_SINGLE_VIEW

    return this.state.rasterPlotState
      ? H0 - this.props.rasterData.layerHeight
      : H0
  }

  get height() {
    const { height, fullScreenMode, rasterData } = this.props
    const { rasterPlotState } = this.state

    if (!this.isSingleColumnView) {
      return fullScreenMode
        ? height - BOTTOM_TOOLBAR_HEIGHT
        : height - TOTAL_HEIGHT_MULTI_VIEW
    }

    const H0 = fullScreenMode
      ? height - BOTTOM_TOOLBAR_HEIGHT - 20
      : height - TOTAL_HEIGHT_SINGLE_VIEW

    const rasterHeight = this.state.heights.rasterPlot || rasterData.layerHeight

    const H1 = rasterPlotState ? H0 - rasterHeight : H0

    return H1
  }

  get panels() {
    const { panels } = this.props

    return this.isSingleColumnView
      ? [flatPanels(panels)]
      : panels.filter(x => x.length > 0)
  }

  get originalPanels() {
    const { originalPanels: panels } = this.state

    return this.isSingleColumnView
      ? [flatPanels(panels)]
      : panels.filter(x => x.length > 0)
  }

  get originalPanelsWithRange() {
    return flatPanels(this.originalPanels).slice(
      this.state?.zoomRange[0],
      this.state?.zoomRange[1] + 1
    )
  }

  get firstChannelOnGroup() {
    const { panels } = this.props
    return new Set(
      panels
        .filter(gr => gr.length > 0)
        .map(gr => gr[gr.length - 1])
        .filter(x => x)
        .map(panel => panel._id)
    )
  }

  timeseriesEvents(keyboardKeys, darkMode) {
    return new TimeSeriesEventTemplate(
      { keyboardKeys, darkMode },
      {
        drag: {
          rangeUpdate: {
            mouseDown: this.handle_mouse_press,
            mouseMove: this.handle_mouse_drag,
            mouseRelease: this.handle_mouse_release
          },
          zoom: {
            mouseDown: () => (this._dragging = false),
            mouseMove: () => (this._dragging = true),
            mouseRelease: this.onZoomRelease,
            disabled:
              !this.isSingleColumnView || this.props.dataManipulationMode
          }
        },
        click: {
          clearZoom: {
            mouseDown: () => {
              this.props.getInitialZoom()
            }
          },
          selectChannel: {
            mouseDown: e => {
              if (this.props.dataManipulationMode) return
              this.selectChannel(e)
            }
          },
          // IN DEVELOPMENT
          selectTimestamp: {
            mouseDown: e => {
              this.selectTimestamp(e)
            }
          }
        }
      }
    )
  }

  _repaintTimeSeriesLayers = () => {
    this._graphLayers = []
    //the number of columns should be the number of groups witch have at least one channel active
    this.panels.forEach((_, i) => {
      this._graphLayers.push(
        new CanvasWidgetLayer(this.paintLayer, {
          index: i,
          graph: true
        })
      )
    })

    this.props.registerRepainter &&
      this.props.registerRepainter(() => {
        this._graphLayers.forEach(L => {
          L.repaint()
        })
      })
    this.setState(c => ({
      ...c,
      allLayers: Object.values(this._initialLayers).concat(...this._graphLayers)
    }))
  }

  onChangeView = () => {
    this._graphLayers = []
    this._repaintTimeSeriesLayers()
  }

  paintLayer = (painter, layer) => {
    this._paintPanelCode++
    painter.useCoords()
    layer.setCoordXRange(0, 1)
    layer.setCoordYRange(0, 1)
    layer.setMargins(50, 10, 15, 50)
    // painter.setPen({ color: 'gray' })
    // painter.drawLine(0, 0, 0, 1)
    this._paintPanels(painter, this._paintPanelCode, layer)
  }

  setVisibleLabels(step) {
    const { panels } = this.props

    const filterEmptyGroups = panels.filter(gr => gr.length > 0)
    const groupEdges = filterEmptyGroups.map(gr => {
      const first = gr[0]._id
      const last = gr[gr.length - 1]._id
      const filterEdges = gr
        .map(panel => panel._id)
        .filter(panelId => panelId !== first && panelId !== last)
      return [first, ...filterEdges, last].filter((_, i) => i % step === 0)
    })

    return new Set(flatArr(groupEdges))
  }

  get visibleLabels() {
    const step = Math.ceil(flatArr(this.panels).length / 32)
    return this.setVisibleLabels(step)
  }

  getLayerBoundaries = layer => {
    return this._graphLayers[layer]?._ref?.current?.getBoundingClientRect()
  }

  drawLabels = (panel, painter) => {
    var isNND = panel._opts.isNND
    const { darkMode } = this.props
    let color = darkMode ? '#DADEE3' : '#545D69'

    if (this.isSingleColumnView) {
      this.visibleLabels.has(panel._id) &&
        panel.drawText(painter, {
          x: isNND ? 1 : 10,
          color,
          fontSize: 10
        })
    }
  }

  _paintPanels = (painter, paintPanelCode, layer) => {
    const { leftPanelWidth } = this.props
    if (!layer) return
    if (!layer._isGraph) return
    if (paintPanelCode !== this._paintPanelCode) {
      // we have started a new painting
      return
    }

    const panels = this.panels[layer._layerIndex]

    if (!panels) return

    const rect = this.getLayerBoundaries(layer._layerIndex)

    let timer = new Date()
    for (let panel of panels) {
      layer.setCoordXRange(this._timeRange[0], this._timeRange[1])

      layer.setMargins(
        !this.isSingleColumnView ? 10 : 50,
        0,
        panel._yRange[0],
        layer.height() - panel._yRange[1]
      )
      // v1=-1 => y1
      //  v2=1 => y2
      // (v1-a) / (b-a) * H = y1
      // (v2-a) / (b-a) * H = y2
      // v1 - a = y1/H * (b-a)
      // v2 - a = y2/H * (b-a)
      // (v2 - v1) = (y2-y1)/H * (b-a)
      // b-a = (v2-v1) / (y2-y1) * H
      // a = v1 - y1/H*(b-a)

      // let v1 = panel._coordYRange[0];
      // let v2 = panel._coordYRange[1];
      // let b_minus_a = (v2 - v1) / (panel._yRange[1] - panel._yRange[0]) * (this.props.height - this._spanWidgetHeight - this._bottomBarHeight);
      // let a = v1 - panel._yRange[0] * b_minus_a / (this.props.height - this._spanWidgetHeight - this._bottomBarHeight);
      // let b = a + b_minus_a;

      //this._mainLayer.setCoordYRange(a, b);
      layer.setCoordYRange(panel._coordYRange[0], panel._coordYRange[1])
      panel.paint(
        { ...painter, index: layer._layerIndex },
        clone(this._timeRange)
      )
      rect &&
        panel.setExtremes(
          rect.left - leftPanelWidth,
          rect.right - leftPanelWidth
        )
      this.drawLabels(panel, painter)

      let elapsed = new Date() - timer
      if (elapsed > 200 && !painter.exportingFigure()) {
        setTimeout(() => {
          this._paintPanels(painter, paintPanelCode)
        }, 100)
        return
      }
    }
  }

  painboxLayer = painter => {
    this._drawBoxLayer.setCoordXRange(0, 0)
    this._drawBoxLayer.setCoordYRange(0, 0)
    this._drawBoxLayer.setMargins(0, 0, 0, 0)
  }

  paintCutSliceLayer = painter => {
    const {
      dataManipulationMode: mode,
      dataManipulationParams,
      darkMode
    } = this.props || {}
    if (!mode) return
    this._dataManipulationLayer.setCoordXRange(
      this._timeRange[0],
      this._timeRange[1]
    )
    this._dataManipulationLayer.setCoordYRange(0, 1)
    this._dataManipulationLayer.setMargins(50, 0, 0, 0)
    painter.useCoords()

    const { to, from } = dataManipulationParams || {}
    const { CUT, SLICE } = DATA_MANIPULATION_MODE
    //area
    const main = darkMode ? colors.darkRed : colors.error
    if ((mode === SLICE && to) || (mode === CUT && to && from)) {
      painter.fillRect(
        [from ? to : 0, 0, from ? from - to : to, 200],
        hexToRgba(main, 0.1)
      )
    }

    const color = darkMode ? main : '#545D69'
    if (to) painter.setDataManipulationPen([to, 0, 10, 10], color)
    if (from) painter.setDataManipulationPen([from, 0, 10, 10], color)
  }

  paintCursorLayer = painter => {
    const { eventsTimestamps, dataManipulationMode, cursorType } = this.props
    const { selectedEvent } = this.state
    if (dataManipulationMode) return
    if (painter.exportingFigure()) return
    this._cursorLayer.setCoordXRange(this._timeRange[0], this._timeRange[1])
    this._cursorLayer.setCoordYRange(0, 1)
    this._cursorLayer.setMargins(this.isSingleColumnView ? 50 : 10, 0, 15, 0)
    painter.useCoords()

    const { timestamp: selectedTime, id: selectedTimeId } = selectedEvent || {}

    let selected

    for (let t of eventsTimestamps) {
      const { timestamp: time, name, color, id, isVisible } = t || {}
      if (!isVisible) continue
      if (selectedTime === time && selectedTimeId === id) {
        selected = { time, color, name }
        continue
      } else painter.setPen({ width: 3, color, dashed: true })

      painter.drawLine(time, -5, time, 1)
    }

    if (selected) {
      const { color, time, name } = selected || {}
      painter.setPen({ width: 5, color, dashed: true })
      painter.setEventLabel([time, 0, 10, 10], color, name)
      painter.drawLine(time, -5, time, 1)
    }

    if (cursorType !== TS_CURSOR_TYPE.DEFAULT) return

    if (this._currentTime !== null) {
      if (
        this._timeRange[0] <= this._currentTime &&
        this._currentTime <= this._timeRange[1]
      ) {
        for (let i = 0; i < this.panels.length; i++) {
          const gap2 =
            i === 0
              ? 0
              : ((this._timeRange[1] - this._timeRange[0]) /
                  this.panels.length) *
                  i +
                10 * i
          painter.setPen({ width: 2, color: 'blue' })
          painter.drawLine(
            this._currentTime - gap2,
            0,
            this._currentTime - gap2,
            1
          )
        }
      }
    }
  }
  paintTimeAxisLayer = painter => {
    const { isColumnView } = this.props
    if (isColumnView) return
    let W = this._timeAxisLayer.width()
    let H = this._timeAxisLayer.height()
    this._timeAxisLayer.setMargins(50, 10, H, 0)
    this._timeAxisLayer.setCoordXRange(this._timeRange[0], this._timeRange[1])
    this._timeAxisLayer.setCoordYRange(0, 1)
    painter.useCoords()
    this.setColors(painter, { dark: 'rgb(22, 22, 22)' }).setBrush()
    this.setColors(painter, { dark: 'rgb(22, 22, 22)' }).setPen()

    painter.drawLine(this._timeRange[0], 1, this._timeRange[1], 1)
  }

  setColors(painter, { light = 'white', dark = 'black' } = {}) {
    const { darkMode } = this.props
    return {
      setBrush: () => {
        painter.setBrush({ color: darkMode ? light : dark })
      },
      setPen: () => {
        painter.setPen({ color: darkMode ? light : dark })
      }
    }
  }

  numTimepoints() {
    return this.props.numTimepoints
  }
  setCurrentTime(t) {
    if (t < 0) t = 0
    if (t >= this.numTimepoints()) t = this.numTimepoints() - 1
    if (this._currentTime === t) return
    this._currentTime = t
    this._cursorLayer.repaint()
    this._updateInfo()
    const {
      onCurrentTimeChanged,
      dataManipulationMode,
      dataManipulationParams
    } = this.props || {}
    const { from, to } = dataManipulationParams || {}
    onCurrentTimeChanged && onCurrentTimeChanged(t)

    if (
      dataManipulationMode === DATA_MANIPULATION_MODE.SLICE &&
      to &&
      !this._dragging
    ) {
      const left = this.timeToPix(to, 0)
      if (left)
        this.setState({
          pos: [left, 0]
        })
    }

    if (
      dataManipulationMode === DATA_MANIPULATION_MODE.CUT &&
      from &&
      to &&
      !this._dragging
    ) {
      this.setState({
        isDragging: false
      })
    }
  }
  setStatusText(txt) {
    if (txt !== this._statusText) {
      this._statusText = txt
      this._updateInfo()
    }
  }
  setTimeRange(trange, isZoomRelease = false) {
    if (!trange) return
    let tr = clone(trange)

    if (tr[1] >= this.numTimepoints()) {
      let delta = this.numTimepoints() - 1 - tr[1]
      tr[0] += delta
      tr[1] += delta
    }
    if (tr[0] < 0) {
      let delta = -tr[0]
      tr[0] += delta
      tr[1] += delta
    }
    if (tr[1] >= this.numTimepoints()) {
      tr[1] = this.numTimepoints() - 1
    }
    if (this.props.maxTimeSpan) {
      if (
        tr[1] - tr[0] > this._timeRange[1] - this._timeRange[0] &&
        tr[1] - tr[0] > this.props.maxTimeSpan
      ) {
        return
        // tr[0] = Math.max(0, Math.floor((tr[0] + tr[1]) / 2 - this.props.maxTimeSpan / 2));
        // tr[1] = tr[0] + this.props.maxTimeSpan;
      }
    }
    if (this._timeRange[0] === tr[0] && this._timeRange[1] === tr[1]) {
      return
    }

    this._timeRange = tr
    this.setCurrentTime(tr[0])
    // this._timeAxisLayer.repaintImmediate();
    this._repaintAllLayers()
    this._updateInfo()
    this.props.onTimeRangeChanged &&
      this.props.onTimeRangeChanged(tr, isZoomRelease)
    this.props.rasterData.update(tr)
  }

  updateLayout() {
    const groupsGaps = this.firstChannelOnGroup.size - 1

    const singleViewHeight =
      this.height - TIME_AXIS_LAYER_HEIGHT - groupsGaps * 10
    //because multiple view dont have time axis layer,wee substract 30 to add a gap on the bottom
    const multipleColumnsView = this.height - 30
    const H0 = this.isSingleColumnView
      ? singleViewHeight + this.state.deltaResize
      : multipleColumnsView

    for (let panels of this.panels) {
      const panelsLen = panels.length
      const panelHeight = H0 / panelsLen
      let y0 = 10
      //if panelsLen is 0 we can skip the all logic
      if (panelHeight === Infinity) return

      for (let panel of panels) {
        panel.setYRange(y0, y0 + panelHeight)
        if (
          this.firstChannelOnGroup.has(panel._id) &&
          this.isSingleColumnView
        ) {
          y0 += panelHeight + 10
        } else {
          y0 += panelHeight
        }
      }
    }
    this._updateInfo()
    this._repaintAllLayers()
  }
  pixToTime(pix) {
    if (!this._graphLayers[0]) return 0
    let coords = this._graphLayers[0].pixToCoords(pix)
    return coords[0]
  }
  timeToPix(time) {
    if (!this._graphLayers[0]) return 0
    return this._graphLayers[0].coordsToPix(time)
  }
  _repaintAllLayers = () => {
    for (let L of this.state.allLayers) {
      L.repaint()
    }
  }
  _updateSpanWidgetInfo() {
    let info = {
      numTimepoints: this.numTimepoints(),
      currentTime: this._currentTime,
      timeRange: clone(this._timeRange),
      samplerate: this.props.samplerate,
      statusText: this._statusText
    }
    this._setSpanWidgetInfo(info)
  }
  _setSpanWidgetInfo(info) {
    if (JSON.stringify(info) !== JSON.stringify(this.state.spanWidgetInfo)) {
      this.setState({
        spanWidgetInfo: info
      })
    }
  }
  _updateInfo() {
    this._updateBottomBarInfo()
    this._updateSpanWidgetInfo()
  }
  _updateBottomBarInfo() {
    let info = {
      show: true,
      currentTime: this._currentTime,
      timeRange: clone(this._timeRange),
      samplerate: this.props.samplerate,
      statusText: this._statusText
    }
    this._setBottomBarInfo(info)
  }
  _setBottomBarInfo(info) {
    if (JSON.stringify(info) !== JSON.stringify(this.state.bottomBarInfo)) {
      this.setState({
        bottomBarInfo: info
      })
    }
  }
  handle_mouse_press = X => {
    if (this.props.dataManipulationMode) {
      this.setState({
        pos: null
      })
    }
    this._anchorTimeRange = clone(this._timeRange)
    this._dragging = false
    this.props.downsampleOnScroll(true)
  }

  handle_set_slice_direction = direction => {
    const { setDataManipulationParams, numTimepoints } = this.props || {}
    if (direction === 'ASC') {
      setDataManipulationParams({
        from: 0,
        direction
      })
    } else if (direction === 'DESC') {
      setDataManipulationParams({
        from: numTimepoints,
        direction
      })
    }
  }

  handle_mouse_release = X => {
    const {
      dataManipulationMode,
      setDataManipulationParams,
      dataManipulationParams,
      downsampleOnScroll,
      cursorType
    } = this.props || {}
    const { to, from } = dataManipulationParams || {}

    if (!dataManipulationMode && cursorType !== TS_CURSOR_TYPE.DEFAULT) return

    if (!this._dragging) {
      const t = this.pixToTime(X?.pos)
      if (dataManipulationMode) {
        if (dataManipulationMode === DATA_MANIPULATION_MODE.SLICE) {
          setDataManipulationParams({ to: t })
          this.setState({ pos: X?.pos })
        } else {
          if (dataManipulationMode === DATA_MANIPULATION_MODE.CUT) {
            this.setState({
              isDragging: false
            })
          }
          if (!from) {
            setDataManipulationParams({ from: t })
          } else {
            setDataManipulationParams({ to: t })
          }
          if (from && to) {
            setDataManipulationParams({
              from: t,
              to: null
            })
          }
        }
        return this._dataManipulationLayer.repaint()
      }

      this.setCurrentTime(t)
    }
    downsampleOnScroll(false)
  }

  handle_mouse_drag = X => {
    const isInCutMode =
      this.props.dataManipulationMode === DATA_MANIPULATION_MODE.CUT &&
      !this.state.isDragging
    if (isInCutMode) {
      this.setState({
        isDragging: true
      })
    }

    if (!this._anchorTimeRange) return
    this._dragging = true
    let t1 = this.pixToTime(X.anchor)
    let t2 = this.pixToTime(X.pos)
    let tr = clone(this._anchorTimeRange)
    tr[0] += t1 - t2
    tr[1] += t1 - t2
    this.setTimeRange(tr)
  }

  handle_mouse_drag_release = X => {
    //we need to delay the release of the dragging
    //to differentiate if it was an click event or dragg
    setTimeout(() => {
      this._dragging = false
    }, 10)

    const { dataManipulationMode, downsampleOnScroll, dataManipulationParams } =
      this.props
    if (dataManipulationParams?.to) {
      //we need to delay the re-calculation of buttons position
      //  otherwise it would not be so accurate
      setTimeout(() => {
        const left = this.timeToPix(dataManipulationParams?.to, 0)
        if (dataManipulationMode === DATA_MANIPULATION_MODE.SLICE && left) {
          this.setState({
            pos: [left, 0]
          })
        }
      }, 0)
    }
    if (dataManipulationMode === DATA_MANIPULATION_MODE.CUT) {
      setTimeout(
        () =>
          this.setState({
            isDragging: false
          }),
        0
      )
    }
    downsampleOnScroll(false)
  }

  handle_key_press = event => {
    for (let a of this.props.actions || []) {
      if (a.key === event.keyCode) {
        a.callback()
        event.preventDefault()
        return false
      }
    }
    const {
      fullScreenMode,
      handleFullScreenMode,
      timeSeriesView,
      setDataManipulationMode,
      eventsUploadModal,
      eventsController,
      isLimited,
      isDisabledShortCuts,
      isEditMode
    } = this.props
    const isMulticolumn = timeSeriesView === 'multiple'

    if (event.ctrlKey || event.metaKey || isDisabledShortCuts || isEditMode)
      return

    switch (event.keyCode) {
      case 37:
        this.handle_key_left(event)
        event.preventDefault()
        return false
      case 39:
        this.handle_key_right(event)
        event.preventDefault()
        return false
      case 187:
        this.zoomTime(1.15)
        event.preventDefault()
        return false
      case 189:
        this.zoomTime(1 / 1.15)
        event.preventDefault()
        return false
      case 90: {
        if (isMulticolumn || isLimited) return false
        setDataManipulationMode(DATA_MANIPULATION_MODE.SLICE)
        event.preventDefault()
        return false
      }
      case 88: {
        if (isMulticolumn || isLimited) return false
        setDataManipulationMode(DATA_MANIPULATION_MODE.CUT)
        event.preventDefault()
        return false
      }
      case 82: {
        const { spikes, rawData } = this.props.rasterData || {}
        const disabled = !spikes || rawData?.filterd_channels?.length === 0
        if (disabled) return false
        this.toggleRasterPlot()
        event.preventDefault()
        return false
      }
      case 72:
        this.removeChannelOnClick()
        event.preventDefault()
        return false
      case 89:
        this.clearHighlightedChannels()
        event.preventDefault()
        return false
      case 70:
        handleFullScreenMode(!fullScreenMode)
        event.preventDefault()
        return false
      case 35 /*end*/:
        this.handle_end(event)
        event.preventDefault()
        return false
      case 36 /*end*/:
        this.handle_home(event)
        event.preventDefault()
        return false
      case 85:
        !isLimited && eventsUploadModal.toggle()
        event.preventDefault()
        return false

      case 69:
        const canDelete = !isLimited && eventsController.selectedEvent
        canDelete && eventsController.toggleTimestampDeleteModal()
        event.preventDefault()
        return false
      case 81:
        const canGoToPrev = !isLimited && eventsController.hasPrev
        canGoToPrev && eventsController.prev()
        event.preventDefault()
        return false
      case 87:
        const canGoToNext = !isLimited && eventsController.hasNext
        canGoToNext && eventsController.next()
        event.preventDefault()
        return false
      default:
        console.info('key: ' + event.keyCode)
    }
  }

  handle_key_left = X => {
    let span = this._timeRange[1] - this._timeRange[0]
    this.translateTime(-span * 0.2)
  }
  handle_key_right = X => {
    let span = this._timeRange[1] - this._timeRange[0]
    this.translateTime(span * 0.2)
  }
  handle_home = X => {
    this.translateTime(-this._currentTime)
  }
  handle_end = X => {
    this.translateTime(this.numTimepoints() - this._currentTime)
  }
  translateTime = delta_t => {
    let tr = clone(this._timeRange)
    tr[0] += delta_t
    tr[1] += delta_t
    let t0 = this._currentTime + delta_t
    this.setCurrentTime(t0)
    this.setTimeRange(tr)
  }
  zoomTime = factor => {
    if (this.props.dataManipulationMode) return
    let anchor_time = this._currentTime

    let tr = clone(this._timeRange)
    if (anchor_time < tr[0] || anchor_time > tr[1]) anchor_time = tr[0]
    let old_t1 = tr[0]
    let old_t2 = tr[1]
    let t1 = anchor_time + (old_t1 - anchor_time) / factor
    let t2 = anchor_time + (old_t2 - anchor_time) / factor

    this.setTimeRange([t1, t2])
  }
  ensureCurrentTimeVisibleByChangingTimeRange = () => {
    if (this._currentTime === null) return
    if (
      this._timeRange[0] < this._currentTime &&
      this._currentTime < this._timeRange[1]
    )
      return
    const span = this._timeRange[1] - this._timeRange[0]
    let tr = [this._currentTime - span / 2, this._currentTime + span / 2]
    this.setTimeRange(tr)
  }

  ensureCurrentTimeVisibleByChangingCurrentTime = () => {
    if (this._currentTime === null) return
    if (
      this._timeRange[0] < this._currentTime &&
      this._currentTime < this._timeRange[1]
    )
      return
    let t = (this._timeRange[0] + this._timeRange[1]) / 2
    this.setCurrentTime(t)
  }

  onMouseWeel = e => {
    if (this.props.dataManipulationMode) return
    const delta = Math.sign(e.deltaY)

    if (delta > 0) {
      this.onZoomIn()
    } else {
      this.onZoomOut()
    }
  }

  onZoomIn = () => this.zoomTime(1.15)
  onZoomOut = () => this.zoomTime(1 / 1.15)
  onShiftTimeLeft = () => this.handle_key_left()
  onShiftTimeRight = () => this.handle_key_right()
  onViewColumns = view => () => {
    const { updateView } = this.props

    updateView(view)
  }

  onZoomRelease = X => {
    let t1 = this.pixToTime([X.locA.x, X.locA.y])
    let t2 = this.pixToTime([X.locB.x, X.locB.y])
    let tr = [t1, t2]
    //if the mouse is move from right to left
    if (t1 > t2) {
      tr = [t2, t1]
    }
    //y positions are kind of wrong
    // const c = flatPanels(this.panels).filter(p => {
    //   const [min, max] = p._minMax
    //   return (
    //     (min <= X.locA.y && max >= X.locA.y) ||
    //     (min <= X.locB.y && max >= X.locB.y)
    //   )
    // })
    // const max = c[0]?._id
    // const min = c[1]?._id
    // const pp = flatPanels(this.panels).map(p => p._id)
    // const first = Math.max(0, pp.indexOf(min))
    // const last = Math.max(pp.indexOf(max), 1)
    // console.log(first, last)

    const zoomedPanels = this._zoomedPanels({ X })
    const originalPanelIds = flatPanels(this.originalPanels).map(p => p._id)
    const firstRangeIndex = originalPanelIds.indexOf(zoomedPanels[0])
    const lastRangeIndex = originalPanelIds.lastIndexOf(
      zoomedPanels[zoomedPanels.length - 1]
    )
    const zoomRange = [firstRangeIndex, lastRangeIndex]
    this.setState({ zoomParams: X, zoomRange })

    this.props.zoomDrag(new Set(zoomedPanels))
    this.setTimeRange(tr, true)
  }

  _zoomedPanels({ X, isWithOriginalPanels = false }) {
    const panels = isWithOriginalPanels
      ? this.originalPanelsWithRange
      : flatPanels(this.panels)
    return panels
      .filter(p => {
        const { x } = p._minMax
        const checkX = x[0] <= X.locA.x && x[1] >= X.locA.x
        const bottomToTop = X.locA.y > X.locB.y
        const bottomToTopFilter =
          p._yRange[0] >= X.locB.y && p._yRange[1] <= X.locA.y
        const topToBottomFilter =
          p._yRange[0] >= X.locA.y - 10 && p._yRange[1] <= X.locB.y + 10
        const filter = bottomToTop ? bottomToTopFilter : topToBottomFilter
        return this.isSingleColumnView ? filter : filter && checkX
      })
      .map(p => p._id)
  }

  _onClickCanvas = e => {
    const { timeseriesEvents } = this.state
    const { cursorType, dataManipulationMode } = this.props
    const { SELECT_CHANNELS, SELECT_TIMESTAMPS } = TS_CURSOR_TYPE

    if (!this._dragging) {
      if (timeseriesEvents.click) {
        timeseriesEvents.click.mouseDown(e, {
          isSelectChannels:
            !dataManipulationMode && cursorType === SELECT_CHANNELS,
          isSelectTimestamps:
            !dataManipulationMode && cursorType === SELECT_TIMESTAMPS
        })
      }
    }
  }

  selectChannel = e => {
    const { selectedPaths } = this.props
    const [x, y] = e.pos
    const range = [y, y - 5, y + 5]

    for (let [k, v] of this.props.paths) {
      const is = range.some(
        dy =>
          e.ctx.isPointInPath(v.path, x, dy) ||
          e.ctx.isPointInStroke(v.path, x, dy)
      )
      if (is) {
        this.selectedPath = k

        if (selectedPaths.has(k)) {
          selectedPaths.delete(k)
        } else {
          selectedPaths.add(k)
        }
        this._repaintAllLayers()
      }
    }
  }

  selectTimestamp = e => {
    const {
      eventsController,
      cursorType,
      eventsTimestamps,
      dataManipulationMode,
      samplerate
    } = this.props || {}
    const { selectedEvent } = this.state || {}

    if (dataManipulationMode) return

    const [cursorX] = e.pos
    const width = 5
    const new_time = this.pixToTime(e?.pos)

    const selected = eventsTimestamps.find(t => {
      const timeX = this.timeToPix(t?.timestamp)
      return cursorX - width < timeX && cursorX + width > timeX
    })

    if (selected) eventsController.select(selected)
    else if (cursorType === TS_CURSOR_TYPE.SELECT_TIMESTAMPS && selectedEvent) {
      eventsController.editTimestamp(toHumanTimeMs(new_time, samplerate))
    }
  }

  clearDataManipulationArea = () => {
    // HANDLE RESET PARAMS
    this.setState({
      pos: null,
      isDragging: false
    })
  }

  clearHighlightedChannels = () => {
    const { selectedPaths } = this.props
    selectedPaths.clear()
    this._repaintAllLayers()
  }

  removeChannelOnClick = () => {
    const { selectedPaths, removeChannelOnClick } = this.props
    if (selectedPaths.size > 0) {
      removeChannelOnClick(selectedPaths)
    }
  }

  toggleRasterPlot = () => {
    this.setState(({ rasterPlotState }) => ({
      rasterPlotState: !rasterPlotState
    }))
  }

  get rasterPlotOn() {
    const {
      rasterData: { spikes },
      timeSeriesView
    } = this.props
    const { rasterPlotState } = this.state

    return spikes && timeSeriesView === 'single' && rasterPlotState
  }

  resetZoom = () => {
    this.props.getInitialZoom()
  }

  render() {
    const {
      fullScreenMode,
      width,
      leftPanelMode,
      groups,
      actions,
      rasterData,
      timeSeriesView,
      dataManipulationMode,
      dataManipulationParams,
      setDataManipulationMode,
      eventsController
    } = this.props
    const { timeseriesEvents, allLayers, pos, isDragging } = this.state

    const realWidth = fullScreenMode ? width - 50 : width
    const isSliceButtons =
      dataManipulationMode &&
      dataManipulationMode === DATA_MANIPULATION_MODE.SLICE &&
      dataManipulationParams.to &&
      pos

    const isCutOperation =
      dataManipulationMode &&
      dataManipulationMode === DATA_MANIPULATION_MODE.CUT &&
      dataManipulationParams.from &&
      dataManipulationParams.to &&
      !isDragging

    let cutPositions = []

    if (isCutOperation) {
      cutPositions = [
        Math.round(this.timeToPix(dataManipulationParams?.from)),
        Math.round(this.timeToPix(dataManipulationParams?.to))
      ]
    }

    let innerContainer = (
      <InnerContainer
        key="time-widget-inner-container"
        width={width}
        leftPanelMode={leftPanelMode}
        fullScreenMode={fullScreenMode}
        loading={eventsController.loading}
      >
        <TimeWidgetToolBar
          setDataManipulationMode={setDataManipulationMode}
          onZoomIn={this.onZoomIn}
          onZoomOut={this.onZoomOut}
          onShiftTimeLeft={this.onShiftTimeLeft}
          onShiftTimeRight={this.onShiftTimeRight}
          onViewColumns={this.onViewColumns}
          customActions={actions}
          fullScreenMode={fullScreenMode}
          selectedView={timeSeriesView}
          groups={groups}
          leftPanelMode={leftPanelMode}
          removeChannelsOnClick={this.removeChannelOnClick}
          clearHighlightedChannels={this.clearHighlightedChannels}
          toggleRasterPlot={this.toggleRasterPlot}
          hideRasterToggler={{
            hide: rasterData.spikes === undefined,
            disable: rasterData.rawData?.filterd_channels?.length === 0
          }}
          resetZoom={this.resetZoom}
        />
        <div
          id="timeseries-widget"
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: 'flex-end'
          }}
        >
          <ResizableWidgets
            child4={
              isCutOperation ? <CutLabels positions={cutPositions} /> : null
            }
            child3={
              isSliceButtons ? (
                <SliceButtons
                  pos={pos}
                  direction={dataManipulationParams?.direction}
                  setDirection={this.handle_set_slice_direction}
                />
              ) : null
            }
            child1={
              <CanvasWidget
                key="canvas"
                layers={allLayers}
                width={realWidth}
                height={this.height + this.state.deltaResize}
                onMousePress={timeseriesEvents.dragging?.mouseDown}
                onMouseRelease={timeseriesEvents.dragging?.mouseRelease}
                onMouseDrag={timeseriesEvents.dragging?.mouseMove}
                onMouseDragRelease={this.handle_mouse_drag_release}
                onMouseLeave={this.handle_mouse_leave}
                onMouseWeel={this.onMouseWeel}
                onClick={this._onClickCanvas}
                menuOpts={{ exportSvg: true }}
              />
            }
            child2={
              <RasterWidget
                height={
                  (this.state.heights.rasterPlot ?? rasterData.layerHeight) -
                  this.state.deltaResize
                }
                timeRange={this.props.timeRange}
                width={width}
                rasterData={rasterData}
                drag={this.handle_mouse_drag}
                onMousePress={timeseriesEvents.dragging?.mouseDown}
                onMouseDrag={timeseriesEvents.dragging?.mouseMove}
                onMouseDragRelease={this.handle_mouse_drag_release}
              />
            }
            height={this.height}
            maxHeight={this.state.heights.maxHeight}
            onResize={(event, direction, refToElement, delta) =>
              this.setState({ deltaResize: delta.height })
            }
            onResizeStop={() =>
              this.setState(
                {
                  deltaResize: 0,
                  heights: {
                    ...this.state.heights,
                    timeseries:
                      this.state.heights.timeseries + this.state.deltaResize,
                    rasterPlot:
                      this.state.heights.rasterPlot - this.state.deltaResize
                  }
                },
                () => {
                  this.updateLayout()
                }
              )
            }
            renderChild2={this.rasterPlotOn}
            width={realWidth}
          />

          <div style={{ display: 'flex' }}>
            <BottomToolbar
              key="bottom"
              width={this.props.width}
              height={BOTTOM_TOOLBAR_HEIGHT}
              info={this.state.bottomBarInfo || {}}
              onCurrentTimeChanged={t => {
                this.setCurrentTime(t)
                this.ensureCurrentTimeVisibleByChangingTimeRange()
              }}
              onTimeRangeChanged={tr => {
                if (tr) {
                  this.setTimeRange(tr)
                  this.ensureCurrentTimeVisibleByChangingCurrentTime()
                }
              }}
            />

            <SpanWidget
              info={this.state.spanWidgetInfo || {}}
              onCurrentTimeChanged={t => {
                this.setCurrentTime(t)
                this.ensureCurrentTimeVisibleByChangingTimeRange()
              }}
              onTimeRangeChanged={tr => {
                if (tr) {
                  this.setTimeRange(tr)
                  this.ensureCurrentTimeVisibleByChangingCurrentTime()
                }
              }}
            />
          </div>
        </div>
      </InnerContainer>
    )

    return (
      <Grid container wrap="nowrap">
        {innerContainer}
      </Grid>
    )
  }
}

function get_ticks(t1, t2, width, samplerate) {
  let W = width

  // adapted from MountainView
  const min_pixel_spacing_between_ticks = 15
  const tickinfo = [
    {
      name: '1 ms',
      interval: 1e-3 * samplerate
    },
    {
      name: '10 ms',
      interval: 10 * 1e-3 * samplerate
    },
    {
      name: '100 ms',
      interval: 100 * 1e-3 * samplerate
    },
    {
      name: '1 s',
      interval: 1 * samplerate
    },
    {
      name: '10 s',
      interval: 10 * samplerate
    },
    {
      name: '1 m',
      interval: 60 * samplerate
    },
    {
      name: '10 m',
      interval: 10 * 60 * samplerate
    },
    {
      name: '1 h',
      interval: 60 * 60 * samplerate
    },
    {
      name: '1 day',
      interval: 24 * 60 * 60 * samplerate
    }
  ]

  let ticks = []
  let first_scale_shown = true
  let height = 0.2
  for (let info of tickinfo) {
    const scale_pixel_width = (W / (t2 - t1)) * info.interval
    let show_scale = false
    if (scale_pixel_width >= min_pixel_spacing_between_ticks) {
      show_scale = true
    }
    if (show_scale) {
      // msec
      let u1 = Math.floor(t1 / info.interval)
      let u2 = Math.ceil(t2 / info.interval)
      let first_tick = true
      for (let u = u1; u <= u2; u++) {
        let t = u * info.interval
        if (t1 <= t && t <= t2) {
          let tick = {
            t: t,
            height: height
          }
          if (first_scale_shown) {
            if (first_tick) {
              ticks.push({
                scale_info: true,
                t1: t1,
                t2: t1 + info.interval,
                label: info.name
              })
              first_tick = false
            }
            first_scale_shown = false
          }
          ticks.push(tick)
        }
      }
      height += 0.1
      height = Math.min(height, 0.45)
    }
  }
  // remove duplicates
  let ticks2 = []
  for (let i = 0; i < ticks.length; i++) {
    let tick = ticks[i]
    let duplicate = false
    if (!tick.scale_info) {
      for (let j = i + 1; j < ticks.length; j++) {
        if (Math.abs(ticks[j].t - tick.t) < 1) {
          duplicate = true
          break
        }
      }
    }
    if (!duplicate) {
      ticks2.push(tick)
    }
  }
  return ticks2
}

export class TimeWidgetPanel {
  constructor(onPaint, opts, id) {
    this.onPaint = onPaint
    this._opts = opts
    this.timeRange = null
    this._yRange = [0, 1]
    this._coordYRange = [-1, 1]
    this._id = id
    this._minMax = {
      x: [0, 0],
      y: [0, 0]
    }
    this.painter = null
  }
  setExtremes(left, right) {
    this._minMax.x = [left, right]
  }
  selected() {
    return this._opts.selected ? true : false
  }
  label() {
    return this._opts.label
  }
  setYRange(y1, y2) {
    this._yRange = [y1, y2]
  }
  setCoordYRange(y1, y2) {
    this._coordYRange = [y1, y2]
  }
  paint(painter, timeRange) {
    this.onPaint(painter, timeRange, this._id)
  }

  drawText(painter, options) {
    let isNND = this._opts.isNND
    let text = isNND ? this._opts.label : this._id

    painter.drawText2(text, {
      x: options.x,
      y: Math.round((this._yRange[1] + this._yRange[0]) / 2) + 5,
      ...options
    })
  }
}

//what is the porpose of this.I know that this will not render the chanels in order of ariving,but following this algorithm
function _getIndexPermutation(N) {
  let ret = []
  let indices = []
  for (let i = 0; i < N; i++) indices.push(i)
  let used = indices.map(() => false)
  let cur = 0
  let increment = Math.floor(N / 2)
  if (increment < 1) increment = 1
  while (ret.length < N) {
    if (!used[cur]) {
      ret.push(cur)
      used[cur] = true
    }
    cur += increment
    if (cur >= N) {
      cur = 0
      if (increment <= 1) {
        increment = 1
      } else {
        increment = Math.floor(increment / 2)
      }
    }
  }
  return ret
}

function clone(obj) {
  return JSON.parse(JSON.stringify(obj))
}
