export const pairs = n => {
  let x = 1
  let y = 1

  let d = []

  for (let i = 1; i < n + 1; i++) {
    d.push([x, y])
    if (i % 4 === 0) {
      x += 1
      y = 1
    } else {
      y += 1
    }
  }

  return d
}

export const typeScatter = {
  mode: 'markers',
  type: 'scattergl',
  marker: { size: 4, color: '#636efa' }
}

export const axis = () => ({
  showline: false,
  showgrid: false,
  zeroline: false,
  showticklabels: false
})

export const M = 2
export const OFFSET = 0.2
export const SUBPLOT_OFFSET = 1

export const buildBounds = (x, y) => ({
  mode: 'lines',
  type: 'scattergl',
  line: { color: '#CACACA' },
  x,
  y,
})

export const getBounds = (m, n) => ({
  x0: M * (m - 1) + OFFSET * (m - 1),
  x1: M * m + OFFSET * (m - 1),
  y0: M * (n - 1) + OFFSET * (n - 1),
  y1: M * n + OFFSET * (n - 1)
})

export const incrementAxes = m => M * (m - 1) + OFFSET * (m - 1)
