import { object, ref, string } from 'yup'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useQueryParams from '../../../../../../../hooks/useQueryParams'
import { setNewPassword } from '../../../../../../../endpoints/auth'
import axios from 'axios'
import useNotification from '../../../../../../SnackbarManager/hooks/useNotification'
import {
  MIN_8_CHARS,
  NOTIFICATION,
  REQUIRED,
  ROUTES
} from '../../../../../../../utils/consts'

function UseNewPasswordForm() {
  const history = useHistory()
  const addNotification = useNotification()
  const { getByName } = useQueryParams()
  const [isPasswordStrong, setIsPasswordStrong] = useState(false)
  const token = getByName('token')

  const initialValues = {
    password: '',
    confirmPassword: ''
  }

  const handlePasswordChange = useCallback(e => {
    const strongPattern = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    )
    setIsPasswordStrong(strongPattern.test(e.target.value))
  }, [])

  const validation = object({
    password: string()
      .required(REQUIRED)
      .matches(
        new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'),
        'Password should contain at least one number and one symbol (like !@#$%^)'
      )
      .min(8, MIN_8_CHARS)
      .trim(),
    confirmPassword: string().oneOf(
      [ref('password'), null],
      'Passwords must match'
    )
  })

  const onSubmit = useCallback(
    async values => {
      try {
        const res = await setNewPassword({
          reset_token: token,
          password: values.password
        })
        if (res) {
          const { message, access_token: token } = res || {}
          if (!token) {
            return addNotification({
              type: NOTIFICATION.ERROR,
              title: message
            })
          }
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          localStorage.setItem('access_token', token)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            duration: 5000,
            title: 'Successfully reset password !'
          })
          return history.push(ROUTES.FILES)
        }
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, history, token]
  )

  useEffect(() => {
    if (!token) history.push(ROUTES.RESET_PASSWORD)
  }, [history, token])

  return {
    initialValues,
    validation,
    onSubmit,
    isPasswordStrong,
    handlePasswordChange
  }
}

export default UseNewPasswordForm
