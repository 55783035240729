import { useCallback, useEffect, useMemo, useState } from 'react'
import { boolean, number, object, string, array } from 'yup'
import {
  MODALS_ID,
  NOTIFICATION,
  REQUIRE_BOOLEAN,
  REQUIRE_NUMBER,
  REQUIRED,
  SPIKESORTING_ALGORITHMS
} from '../../../../../utils/consts'
import useGetSpikesortings from '../../../hooks/useGetSpikesortings'
import useToggleModal from '../../../../../hooks/useToggleModal'
import useRecordingInfo from '../../../hooks/useRecordingInfo'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'

function useNewSpikesortingModal({
  onSubmit,
  onEdit,
  oldValues,
  isExperimentInput
}) {
  const defaultModel = 'Default Model (Stark Lab)'
  const { fetchSortings } = useGetSpikesortings(false)
  const addNotification = useNotification()
  const algorithms = Object.values(SPIKESORTING_ALGORITHMS).map(value => ({
    value,
    label: value
  }))

  const initialAlgo = SPIKESORTING_ALGORITHMS.KLUSTA
  const [selectedAlgorithm, setAlgorithm] = useState(initialAlgo)
  const [AIModel, setAIModel] = useState(defaultModel)
  const [name, setName] = useState('')
  const options = {
    car: [
      { value: true, label: 'True' },
      { value: false, label: 'False' }
    ]
  }
  const isSelectors = ['car']
  const { recording } = useRecordingInfo()
  const { id: record_id, disabled_channels } = recording || {}

  const modalID = MODALS_ID.NEW_SPIKESORTING_MODAL
  const { isOpened: isProbeModalOpen, onClose: closeProbeModal } =
    useToggleModal(MODALS_ID.PROBE_DESIGN_MODAL)
  const { toggle: toggleModal, isOpened } = useToggleModal(modalID)

  const [withMuteChannels, setMuteChannels] = useState(true)
  const handleToggleMuteChannels = event =>
    setMuteChannels(event.target.checked)

  const { params_input, name: oldName, algo: oldAlgo } = oldValues || {}
  const isRemoveSpikes = oldValues
    ? Boolean(params_input?.remove_unit_less_100_spikes)
    : true

  const [withLessSpikes, setLessSpikes] = useState(isRemoveSpikes)

  const handleToggleLessSpikes = event => setLessSpikes(event.target.checked)

  const handleChangeName = useCallback(e => setName(e.target.value), [])

  const handleSelectAIModel = useCallback(e => setAIModel(e?.name), [])

  const handleRunSpikesorting = useCallback(
    async (values, actions) => {
      if (!name) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: 'Name is required'
        })
        return
      }
      const getSpecificFields = () => {
        const isKilosort =
          selectedAlgorithm === SPIKESORTING_ALGORITHMS.KILOSORT
        return {
          ...(withMuteChannels && !isExperimentInput
            ? { disabled_channels }
            : {}),
          ...(isKilosort ? { remove_unit_less_100_spikes: withLessSpikes } : {})
        }
      }
      const data = {
        record_id,
        algo: selectedAlgorithm,
        name: name,
        params: {
          ai_model: AIModel,
          ...values,
          ...getSpecificFields()
        }
      }

      if (oldValues) {
        await onEdit({
          data,
          onSuccess: () => {
            actions.resetForm()
            toggleModal()
          }
        })
        return
      }

      await onSubmit({
        data,
        onSuccess: () => {
          actions.resetForm()
          toggleModal()
          isProbeModalOpen && closeProbeModal()
          if (record_id) fetchSortings()
        },
        onError: () => {
          toggleModal()
        }
      })
    },
    [
      isProbeModalOpen,
      closeProbeModal,
      oldValues,
      onSubmit,
      onEdit,
      name,
      AIModel,
      record_id,
      selectedAlgorithm,
      withMuteChannels,
      withLessSpikes,
      isExperimentInput,
      disabled_channels
    ]
  )

  const handleSelectAlgorithm = useCallback(value => setAlgorithm(value), [])

  useEffect(() => {
    if (oldName) setName(oldName)
    if (oldAlgo) setAlgorithm(oldAlgo)
    setLessSpikes(isRemoveSpikes)

    return () => {
      setMuteChannels(true)
      setLessSpikes(true)
      setAlgorithm(initialAlgo)
      setName(null)
      setAIModel(defaultModel)
    }
  }, [isOpened, oldName, oldAlgo, isRemoveSpikes])

  const klustaValuesArr = [
    ['adjacency_radius', 'none'],
    ['detect_sign', -1],
    ['threshold_strong_std_factor', 5],
    ['threshold_weak_std_factor', 2],
    ['extract_s_before', 16],
    ['extract_s_after', 32],
    ['n_features_per_channel', 3],
    ['pca_n_waveforms_max', 10000],
    ['num_starting_clusters', 50],
    ['chunk_mb', 500],
    ['n_jobs_bin', 1]
  ]

  const kilosortValuesArr = [
    ['car', options.car[0].value],
    ['criterion_noise_channels', 0.2],
    ['band_pass_filter_min', 300],
    ['band_pass_filter_max', 8000],
    ['template_multiplier', 4],
    ['detect_threshold', 6],
    ['th', [6, 12, 12]],
    ['lam', [10, 30, 30]]
  ]

  const chunkValues = arr =>
    arr.reduce(
      (acc, [key, value]) => ({ ...acc, [key]: params_input?.[key] || value }),
      {}
    )

  const klustaValues = chunkValues(klustaValuesArr)

  const kilosortValues = chunkValues(kilosortValuesArr)

  // 12 fields
  const validationKlusta = object({
    adjacency_radius: string().required(REQUIRED),

    detect_sign: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    threshold_strong_std_factor: number()
      .typeError(REQUIRE_NUMBER)
      .required(REQUIRED),
    threshold_weak_std_factor: number()
      .typeError(REQUIRE_NUMBER)
      .required(REQUIRED),
    extract_s_before: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    extract_s_after: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    n_features_per_channel: number()
      .typeError(REQUIRE_NUMBER)
      .required(REQUIRED),
    pca_n_waveforms_max: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    num_starting_clusters: number()
      .typeError(REQUIRE_NUMBER)
      .required(REQUIRED),
    chunk_mb: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    n_jobs_bin: number().typeError(REQUIRE_NUMBER).required(REQUIRED)
  })

  const validationKilosort = object({
    car: boolean().typeError(REQUIRE_BOOLEAN).required(REQUIRED),
    criterion_noise_channels: number()
      .typeError(REQUIRE_NUMBER)
      .required(REQUIRED),
    band_pass_filter_min: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    band_pass_filter_max: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    template_multiplier: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    detect_threshold: number().typeError(REQUIRE_NUMBER).required(REQUIRED),
    th: array().of(number()).typeError(REQUIRE_NUMBER).required(REQUIRED),
    lam: array().of(number()).typeError(REQUIRE_NUMBER).required(REQUIRED)
  })

  const getFields = useMemo(() => {
    switch (selectedAlgorithm) {
      case 'Klusta':
        return { validation: validationKlusta, values: klustaValues }
      case 'Kilosort':
        return { validation: validationKilosort, values: kilosortValues }
      default:
        return { validation: validationKlusta, values: klustaValues }
    }
  }, [
    kilosortValues,
    klustaValues,
    selectedAlgorithm,
    validationKilosort,
    validationKlusta
  ])

  const initialValues = getFields.values

  const validation = getFields.validation

  return {
    isOpened,
    toggleModal,
    handleRunSpikesorting,
    initialValues,
    validation,

    //name
    handleChangeName,
    name,

    // AI model
    AIModel,
    handleSelectAIModel,

    // options
    options,
    isSelectors,

    //algorithms
    algorithms,
    selectedAlgorithm,
    handleSelectAlgorithm,

    // disabled channels
    withMuteChannels,
    handleToggleMuteChannels,

    // less spikes
    withLessSpikes,
    handleToggleLessSpikes
  }
}

export default useNewSpikesortingModal
