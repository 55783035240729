import { useCallback, useState } from 'react'
import useNotification from '../../SnackbarManager/hooks/useNotification'
import { useLocation } from 'react-router-dom'
import {
  EXPERIMENTS_INPUT,
  MODALS_ID,
  NOTIFICATION
} from '../../../utils/consts'
import useToggleModal from '../../../hooks/useToggleModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  editExpInput,
  setEditExoInput,
  storeSortingsInputs,
  storeUploadInputs
} from '../../../actions/experimentsInput'
import {
  querySortingExpInputs,
  queryUploadExpInputs
} from '../../../endpoints/admin'
import { useFetchExpInputs } from './useExpInputsActions'

function useExperimentsInputController() {
  const [loading, setLoading] = useState(false)
  const addNotification = useNotification()
  const { pathname } = useLocation()
  const isSpikesorting = pathname.includes('spikesorting')

  const { fetchInputs: refetchInputs } = useFetchExpInputs({
    fetchOnRender: false,
    query: isSpikesorting ? querySortingExpInputs : queryUploadExpInputs,
    storeAction: isSpikesorting ? storeSortingsInputs : storeUploadInputs
  })

  const { NEW_SPIKESORTING_MODAL, UPLOAD_EXPERIMENTS_INPUT_MODAL } = MODALS_ID
  const { toggle: toggleUploadsModal } = useToggleModal(
    UPLOAD_EXPERIMENTS_INPUT_MODAL
  )
  const { toggle: toggleSpikesortingModal } = useToggleModal(
    NEW_SPIKESORTING_MODAL
  )

  const dispatch = useDispatch()
  const setForEdit = useCallback(
    data => dispatch(setEditExoInput(data)),
    [dispatch]
  )
  const edit = useCallback(
    (key, data) => dispatch(editExpInput(key, data)),
    [dispatch]
  )
  const oldValues = useSelector(state => state.experimentsInputStore.editInput)

  const createSpikesorting = useCallback(
    async ({ data = {}, onSuccess = () => null, onError = () => null }) => {
      const { record_id, ...rest } = data
      try {
        setLoading(true)
        const response = await querySortingExpInputs({
          method: 'POST',
          data: rest
        })
        if (response?.data) {
          await onSuccess()
          setLoading(false)
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'New Spikesorting experiment input was successfully created!'
          })
          refetchInputs()
        }
      } catch (e) {
        await onError()
        addNotification({
          type: NOTIFICATION.ERROR,
          title: 'Failed to create spikesorting experiment input !',
          message: e
        })
      }
    },
    [addNotification, refetchInputs]
  )

  const handleClose = () => {
    if (oldValues) setForEdit(null)
  }

  const handleEdit = useCallback(
    async (
      type,
      { data: values = {}, onSuccess = () => null, onError = () => null }
    ) => {
      try {
        setLoading(true)
        const { record_id, ...rest } = values
        const data = {
          id: oldValues.id,
          ...rest
        }
        const query =
          type === EXPERIMENTS_INPUT.UPLOADS
            ? queryUploadExpInputs
            : querySortingExpInputs
        const response = await query({
          method: 'PATCH',
          data
        })
        if (response?.data) {
          await onSuccess()
          addNotification({
            type: NOTIFICATION.SUCCESS,
            title: 'Experiment input was successfully edited !'
          })
          edit(type, response.data)
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        await onError()
        addNotification({
          type: NOTIFICATION.ERROR,
          title: 'Failed to edit experiment input !',
          message: e
        })
      }
    },
    [addNotification, refetchInputs, oldValues?.id]
  )

  const addValue = isSpikesorting ? toggleSpikesortingModal : toggleUploadsModal

  return {
    createSpikesorting,
    loading,
    addValue,
    handleClose,
    handleEdit,
    oldValues,
    refetchInputs
  }
}

export default useExperimentsInputController
