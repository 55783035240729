import { useCallback, useEffect, useState } from 'react'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import {
  crateComment,
  deleteComment,
  getSortingComments,
  updateComment
} from '../../../../../endpoints/admin'
import { MODALS_ID, NOTIFICATION } from '../../../../../utils/consts'
import {
  editComment,
  removeComment,
  storeComments
} from '../../../../../actions/recording'
import useToggleModal from '../../../../../hooks/useToggleModal'

function useComments() {
  const addNotification = useNotification()
  const [newComment, setCommentValue] = useState('')
  const [loading, setLoading] = useState(false)
  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )
  const comments = useSelector(state => state.recordingStore.comments)
  const sortingId = selectedSorting?.id

  const dispatch = useDispatch()
  const store = useCallback(data => dispatch(storeComments(data)), [dispatch])
  const remove = useCallback(id => dispatch(removeComment(id)), [dispatch])
  const edit = useCallback(data => dispatch(editComment(data)), [dispatch])

  const modalID = MODALS_ID.COMMENTS_DRAWER
  const { toggle: toggleDrawer, isOpened } = useToggleModal(modalID)

  const handleCreateComment = useCallback(async () => {
    try {
      setLoading(true)
      const response = await crateComment({
        run_id: sortingId,
        value: newComment
      })
      if (response) {
        store([...comments, response])
        setCommentValue('')
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [addNotification, comments, newComment, sortingId, store])

  const handleEditComment = useCallback(
    async (id, value) => {
      try {
        edit({ id, value })
        await updateComment({
          id,
          value
        })
      } catch (e) {
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, edit]
  )

  const handleDeleteComment = useCallback(
    async id => {
      try {
        setLoading(true)
        await deleteComment(id)
        remove(id)
        setLoading(false)
      } catch (e) {
        setLoading(false)
        addNotification({
          type: NOTIFICATION.ERROR,
          title: e
        })
      }
    },
    [addNotification, remove]
  )

  const handleChange = useCallback(e => setCommentValue(e.target.value), [])

  const handleKeyPress = useCallback(
    e => {
      if (e.key?.toLowerCase() === 'enter' && newComment?.trim().length > 0) {
        handleCreateComment()
      }
    },
    [handleCreateComment, newComment]
  )

  const fetchComments = useCallback(async () => {
    try {
      setLoading(true)
      const response = await getSortingComments({ run_id: sortingId })
      const { data } = response || {}
      store(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
  }, [addNotification, sortingId, store])

  useEffect(() => {
    if (isOpened && sortingId) fetchComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened, sortingId])

  return {
    handleChange,
    handleKeyPress,
    toggleDrawer,
    isOpened,
    loading,
    newComment,
    onDelete: handleDeleteComment,
    onEdit: handleEditComment
  }
}

export default useComments
