import { useMemo, useCallback, useState } from 'react'
import React from 'react'
import { useSelector } from 'react-redux'
import { mapSortByKey } from '../../../utils/utils'
import ProbeStatus from '../components/ProbeStatus'
import ProbeActions from '../components/ProbeActions'
import ProbeName from '../components/ProbeName'

const useProbeInventoryTable = ({ columns }) => {
  const probes = useSelector(state => state.probesStore.probes)
  const [prbFile, setPrbFile] = useState()

  const setProbeDesign = useCallback(prb => setPrbFile(prb), [])

  const rows = useMemo(
    () =>
      probes?.length > 0
        ? probes?.map(item => {
            const { id, shipping_status, shipping_info, status } = item
            const info = shipping_info?.trim()
              ? `${shipping_status} - ${shipping_info}`
              : shipping_status
            const fields = {
              ...item,
              probe_status: status,
              shipping_info: info
            }

            const activeColumns = Object.entries(fields).filter(([k, _]) =>
              columns?.some(a => a.active && a.id === k)
            )

            let sortedByKey = mapSortByKey(activeColumns, columns, 'id')

            let probeItem = {
              id,
              ...Object.assign(
                {},
                ...Array.from(sortedByKey || [], ([k, v]) => ({ [k]: v }))
              )
            }
            probeItem = {
              ...probeItem,
              probe: (
                <ProbeName
                  id={id}
                  setProbeDesign={setProbeDesign}
                  name={probeItem.probe}
                />
              ),
              probe_status: <ProbeStatus status={probeItem.probe_status} />,
              impedan: <ProbeActions id={id} />
            }

            return probeItem
          })
        : [],
    [columns, probes]
  )
  return { rows, prbFile }
}

export default useProbeInventoryTable
