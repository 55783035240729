export const ADD = 'ADD_PROBE_FILE_DATA'
export const EDIT = 'EDIT_PROBE_FILE_DATA'
export const RESET = 'RESET_PROBE_FILE_DATA'
export const GET_DRAW = 'GET_DRAW'
export const TOGGLE_DATA_MANIPULATION_MODE = 'TOGGLE_DATA_MANIPULATION_MODE'
export const RESET_ALL_DATA_MANIPULATION_PARAMS =
  'RESET_ALL_DATA_MANIPULATION_PARAMS'
export const RESET_DATA_MANIPULATION_PARAMS = 'RESET_DATA_MANIPULATION_PARAMS'
export const HANDLE_SET_DATA_MANIPULATION_PARAMS =
  'HANDLE_SET_DATA_MANIPULATION_PARAMS'

export const addProbeFileDataActionCreator = fileData => ({
  type: ADD,
  payload: fileData
})

export const resetProbeFileData = () => ({
  type: RESET
})

export const editProbeFileDataActionCreator = fileData => ({
  type: EDIT,
  payload: fileData
})

export const toggleDataManipulationMode = payload => ({
  type: TOGGLE_DATA_MANIPULATION_MODE,
  payload: payload
})

export const setDataManipulationParams = payload => ({
  type: HANDLE_SET_DATA_MANIPULATION_PARAMS,
  payload: payload
})

export const resetDataManipulationParams = () => ({
  type: RESET_DATA_MANIPULATION_PARAMS
})

export const resetAllDataManipulation = () => ({
  type: RESET_ALL_DATA_MANIPULATION_PARAMS
})
