import React, { useEffect, useState, useCallback } from 'react'
import Mda from './Mda'
import TimeseriesWidget from './TimeseriesWidget'
import TimeseriesModel from './TimeseriesModel'
import CalculationPool from '../../pluginComponents/common/CalculationPool'
import Loader from '../../containers/NewTimeSeries/components/Loader'
import { useSafeDispatch } from '../../hooks/useSafeDispatch'
import { getTrace, getNNDTrace } from '../../endpoints/admin'
import { useFetchRasterData } from './hooks'
import useToggleModal from '../../hooks/useToggleModal'
import { MODALS_ID } from '../../utils/consts'
import useEventsController from './NewLeftPanel/components/EventsTab/hooks/useEventsController'
import useLimitedAccess from '../../hooks/useLimitedAccess'
import { shallowEqual, useSelector } from 'react-redux'
import useQueryParams from '../../hooks/useQueryParams'
import { useLocalStorage } from 'usehooks-ts'
import { getInitialTimeRange } from '../../containers/NewTimeSeries/utils/utils'
import axios from 'axios'

const timeseriesCalculationPool = new CalculationPool({
  maxSimultaneous: 4,
  method: 'stack'
})

const TimeseriesView = ({
  width,
  height,
  maxWidth,
  maxHeight,
  recordingId,
  status,
  childWindow,
  statusMessage,
  timeseriesInfo,
  nndTimeseriesInfo
}) => {
  const eventsUploadModal = useToggleModal(MODALS_ID.EVENTS_FILE_UPLOAD_MODAL)
  const [timeseriesModel, setTimeseriesModel] = useState(null)
  const [nndTimeseriesModel, setNNDTimeseriesModel] = useState([])
  const rasterData = useFetchRasterData(recordingId)
  const eventsController = useEventsController()

  const safeSetTimeseriesModel = useSafeDispatch(setTimeseriesModel)
  const safeSetNNDTimeseriesModel = useSafeDispatch(setNNDTimeseriesModel)
  const { isLimited } = useLimitedAccess()
  const query = useQueryParams()
  const range = query.getByName('range')

  const { mainRange } = useSelector(
    state => state.timeSeriesLeftPanel,
    shallowEqual
  )
  let split = str => str?.split('-')

  const createModel = useCallback(
    ({ info, isNND = false, nndRecordId = null }) => {
      let [start, end] = split(range) || []
      const model = new TimeseriesModel({
        samplerate: info.samplerate,
        num_channels: range ? end - start : info.num_channels,
        num_timepoints: info.num_timepoints,
        segment_size: info.segment_size
      })

      model.onRequestDataSegment(async (ds_factor, segment_num) => {
        let result
        const slot = await timeseriesCalculationPool.requestSlot()
        const getRange = () => {
          if (range) {
            return { start, end: end + 1 }
          }
          if (!window.opener && mainRange) {
            // let [start, end] = split(mainRange)
            let [start, end] = []
            return { start, end }
          }
          return {}
        }
        try {
          const traceApi = isNND ? getNNDTrace : getTrace

          const source = axios.CancelToken.source()
          // cancel the first request if the queue has more than 5 requests already
          if (model.requestQueue().length > 5) model.popRequestQueue()
          model.addRequest(ds_factor, segment_num, source)

          result = await traceApi({
            record_id: isNND ? nndRecordId : recordingId,
            ds_factor,
            segment_num,
            segment_size: info.segment_size,
            cancelTokenSource: source,
            // shrink: true,
            ...getRange()
          })
        } catch (err) {
          console.error(
            'Error getting timeseries segment',
            ds_factor,
            segment_num
          )
          return
        } finally {
          slot.complete()
        }
        let X = new Mda()

        result && X.setFromBase64(result.data_b64)
        model.setDataSegment(ds_factor, segment_num, X)
      })
      return model
    },
    [range, mainRange, recordingId]
  )

  const effect = useCallback(
    ({ info, isNND = false }) => {
      if (isNND) {
        const nndInfo = new Map(info)
        const models = []
        nndInfo.forEach((value, key) => {
          const model = createModel({
            info: value,
            isNND: true,
            nndRecordId: key
          })
          models.push(model)
        })
        safeSetNNDTimeseriesModel(models)
      } else {
        const model = createModel({ info })
        safeSetTimeseriesModel(model)
      }
    },
    [createModel, safeSetNNDTimeseriesModel, safeSetTimeseriesModel]
  )

  const [timeRange, setTimeRange] = useLocalStorage(`timeRange-${recordingId}`)
  const [y_scale_factor, setYScaleFactor] = useLocalStorage(
    `YScaleFactor-${recordingId}`
  )

  useEffect(() => {
    if (timeseriesInfo && !timeseriesModel) {
      let initTimeRange = getInitialTimeRange(timeseriesInfo)

      effect({ info: timeseriesInfo })
      if (!childWindow) {
        setTimeRange(initTimeRange)
        setYScaleFactor(
          timeseriesInfo.y_scale_factor *
            (timeseriesInfo.initial_y_scale_factor || 1)
        )
      }
    }
  }, [timeseriesInfo, timeseriesModel])

  useEffect(() => {
    if (nndTimeseriesInfo?.size !== 0) {
      effect({ info: nndTimeseriesInfo, isNND: true })
    }
  }, [nndTimeseriesInfo])

  // render
  if (status === 'pending') {
    return <Loader />
  } else if (status === 'calculating') {
    return (
      <Loader>
        <p>{`${statusMessage}`}</p>
      </Loader>
    )
  } else if (status === 'error') {
    return <div>{`Error: ${statusMessage}`}</div>
  }

  if (status !== 'finished') {
    return <div>{`Unexpected status: ${status}`}</div>
  }

  if (!timeseriesModel) return <div>{`No timeseries model`}</div>

  const width0 = Math.min(width, maxWidth || 99999)
  const height0 = Math.min(height || 800, maxHeight || 99999)

  return (
    <div>
      <TimeseriesWidget
        timeRange={timeRange}
        setTimeRange={setTimeRange}
        setYScaleFactor={setYScaleFactor}
        childWindow={childWindow}
        eventsUploadModal={eventsUploadModal}
        timeseriesModel={timeseriesModel}
        nndTimeseriesModel={nndTimeseriesModel}
        nndTimeseriesInfo={nndTimeseriesInfo}
        num_channels={timeseriesInfo.num_channels}
        channel_ids={timeseriesInfo.channel_ids}
        channel_locations={timeseriesInfo.channel_locations}
        num_timepoints={timeseriesInfo.num_timepoints}
        y_offsets={timeseriesInfo.y_offsets}
        y_scale_factor={y_scale_factor}
        width={width0}
        height={height0}
        rasterData={rasterData}
        eventsController={eventsController}
        isLimited={isLimited}
      />
    </div>
  )
}

// class OldTimeseriesViewFO extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             filterOpts: props.filterOpts || { type: 'none', freq_min: 300, freq_max: 6000, freq_wid: 1000 }
//         };
//     }
//     render() {
//         // let leftPanels = [
//         //     {
//         //         key: 'filter-options',
//         //         title: "Filter options",
//         //         icon: <FilterOptsIcon />,
//         //         render: () => (
//         //             <FilterOpts
//         //                 filterOpts={this.state.filterOpts}
//         //                 onChange={(newOpts) => {this.setState({filterOpts: newOpts})}}
//         //             />
//         //         )
//         //     }
//         // ];
//         let leftPanels = [];
//         // let fo = {type: 'none', freq_min: 300, freq_max: 6000, freq_wid: 1000};
//         return (
//             <TimeseriesViewInner
//                 {...this.props}
//                 key={keyFromObject(this.state.filterOpts)}
//                 filterOpts={this.state.filterOpts}
//                 // filterOpts={fo}
//                 leftPanels={leftPanels}
//             />
//         );
//     }
// }

// function keyFromObject(obj) {
//     return JSON.stringify(obj);
// }

// const OldTimeseriesViewInner = ({ timeseriesModel, timeseriesInfo, leftPanels, width, height, maxWidth, maxHeight }) => {
//     // useEffect(() => {
//     //     if (!timeseriesModel) return;
//     //     if (!this.state.num_channels) return;
//     //     if (!this.timeseriesModel) {
//     //         if (!this.state.samplerate) {
//     //             return;
//     //         }
//     //         const params = {
//     //             samplerate: this.state.samplerate,
//     //             num_channels: this.state.num_channels,
//     //             num_timepoints: this.state.num_timepoints,
//     //             segment_size: this.state.segment_size
//     //         };
//     //         this.timeseriesModel = new TimeseriesModel(params);
//     //         this.timeseriesModel.onRequestDataSegment((ds_factor, segment_num) => {
//     //             this.pythonInterface.sendMessage({
//     //                 command: 'requestSegment',
//     //                 ds_factor: ds_factor,
//     //                 segment_num: segment_num
//     //             });
//     //         });
//     //         this.setState({
//     //             timeseriesModelSet: this.state.timeseriesModelSet + 1
//     //         });
//     //     }
//     // })
//     if (timeseriesModel) {
//         const width0 = Math.min(width, maxWidth || 99999);
//         const height0 = Math.min(height || 800, maxHeight || 99999);
//         return (
//             <div>
//                 <TimeseriesWidget
//                     timeseriesModel={timeseriesModel}
//                     num_channels={timeseriesInfo.num_channels}
//                     channel_ids={timeseriesInfo.channel_ids}
//                     channel_locations={timeseriesInfo.channel_locations}
//                     num_timepoints={timeseriesInfo.num_timepoints}
//                     y_offsets={timeseriesInfo.y_offsets}
//                     y_scale_factor={timeseriesInfo.y_scale_factor * (timeseriesInfo.initial_y_scale_factor || 1)}
//                     width={width0}
//                     height={height0}
//                     leftPanels={leftPanels}
//                 />
//             </div>
//         )
//     }
//     else {
//         return (
//             <div>TimeseriesView</div>
//         );
//     }
// }

/*
class TimeseriesViewInnerOld extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // javascript state
            recording: null,

            // python state
            num_channels: null,
            channel_locations: null,
            channel_ids: null,
            num_timepoints: null,
            channel_ids: null,
            samplerate: null,
            y_offsets: null,
            y_scale_factor: null,
            segment_size: null,
            status_message: '',

            // other
            timeseriesModelSet: 0 // to trigger re-render
        }
        this.timeseriesModel = null;
    }

    componentDidMount() {
        this.pythonInterface = new PythonInterface(this, config);
        this.pythonInterface.onMessage((msg) => {
            if (msg.command == 'setSegment') {
                let X = new Mda();
                X.setFromBase64(msg.data);
                this.timeseriesModel.setDataSegment(msg.ds_factor, msg.segment_num, X);
            }
        })
        let recording = this.props.recording;
        if (this.props.filterOpts.type === 'bandpass_filter') {
            recording = {
                recording: recording,
                filters: [
                    this.props.filterOpts
                ]
            };
        }
        this.pythonInterface.setState({
            recording: recording
        });

        this.pythonInterface.start();
        this.updateData();
    }
    componentDidUpdate(prevProps, prevState) {
        this.updateData();
    }
    componentWillUnmount() {
        this.pythonInterface.stop();
    }

    updateData() {
        if (!this.state.num_channels) return;
        if (!this.timeseriesModel) {
            if (!this.state.samplerate) {
                return;
            }
            const params = {
                samplerate: this.state.samplerate,
                num_channels: this.state.num_channels,
                num_timepoints: this.state.num_timepoints,
                segment_size: this.state.segment_size
            };
            this.timeseriesModel = new TimeseriesModel(params);
            this.timeseriesModel.onRequestDataSegment((ds_factor, segment_num) => {
                this.pythonInterface.sendMessage({
                    command: 'requestSegment',
                    ds_factor: ds_factor,
                    segment_num: segment_num
                });
            });
            this.setState({
                timeseriesModelSet: this.state.timeseriesModelSet + 1
            });
        }
    }
    render() {
        if (this.timeseriesModel) {
            let leftPanels = [];
            for (let lp of this.props.leftPanels)
                leftPanels.push(lp);
            let width = Math.min(this.props.width, this.props.maxWidth || 99999);
            let height = Math.min(this.props.height || 800, this.props.maxHeight || 99999);
            return (
                <div>
                    <TimeseriesWidget
                        timeseriesModel={this.timeseriesModel}
                        num_channels={this.state.num_channels}
                        channel_ids={this.state.channel_ids}
                        channel_locations={this.state.channel_locations}
                        num_timepoints={this.state.num_timepoints}
                        y_offsets={this.state.y_offsets}
                        y_scale_factor={this.state.y_scale_factor * (this.props.initial_y_scale_factor || 1)}
                        width={width}
                        height={height}
                        leftPanels={leftPanels}
                    />
                </div>
            )
        }
        else {
            return (
                <div>Loading timeseries... {this.state.status_message}</div>
            );
        }
    }
}
*/

export default TimeseriesView
