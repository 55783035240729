import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { InsertDriveFile } from '@material-ui/icons'
import ActionsModalWrapper from '../ActionsModalWrapper'
import useWeaveFilesModal from '../../hooks/useWeaveFilesModal'

const useStyles = makeStyles(theme => ({
  file: {
    margin: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    fontSize: 16
  },
  fileIcon: {
    color: theme.palette.colors.accent[5],
    marginRight: 5
  }
}))

export default function WavingFilesModal(props) {
  const { isOpened, onWaving, toggleModal, loading } = useWeaveFilesModal()
  const classes = useStyles()

  if (!Array.isArray(props?.selected)) return null

  return (
    <ActionsModalWrapper
      title="Weave Files"
      isOpened={isOpened}
      toggleModal={toggleModal}
      handleSubmit={onWaving}
      isSubmitting={loading}
      {...props}
    >
      <Typography>
        You are creating a new file by weaving following files:
      </Typography>

      {props.selected.map(({ name }, index) => (
        <Box className={classes.file} key={index}>
          <InsertDriveFile className={classes.fileIcon} />

          <Typography noWrap>{name}</Typography>
        </Box>
      ))}
    </ActionsModalWrapper>
  )
}
