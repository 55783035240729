import React from 'react'
import { Box, makeStyles, Typography, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import cn from 'classnames'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  container: ({ transform }) => {
    return {
      padding: 15,
      borderRadius: 5,
      backgroundColor: theme.palette.colors.backgrounds.main,
      '& ~ .__floater__arrow': {
        transform
      }
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontWeight: 600,
    fontSize: 18
  },
  step: {
    color: theme.palette.colors.neutral[5],
    fontSize: 13,
    fontWeight: 400
  },
  icon: {
    color: theme.palette.colors.neutral[5],
    cursor: 'pointer',
    marginTop: 3,
    fontWeight: 400
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    padding: '12px 0',
    paddingRight: 25,
    color: theme.palette.colors.neutral.contrast
  },
  footer: {
    marginTop: 5,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse'
  },

  button: {
    fontWeight: 600,
    fontSize: 14,
    textTransform: 'none',
    color: theme.palette.colors.white,
    border: `2px ${theme.palette.primary} solid`,
    borderRadius: 8,
    boxShadow: 'none'
  },
  outlindButton: {
    marginRight: 8,
    color: theme.palette.primary.text,
    borderColor: theme.palette.primary.text,
    '&:hover': {
      color: 'inherit',
      borderColor: 'inherit'
    }
  },
  next: {
    backgroundColor: theme.palette.secondary[100],
    '&:hover': {
      backgroundColor: theme.palette.secondary[90],
      borderColor: '#0062cc'
    }
  },
  skip: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.colors.neutral.contrast,
    width: 'fit-content',
    paddingLeft: 0,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'inherit',
      color: 'inherit',
      borderColor: 'inherit'
    }
  }
}))

export default function CustomTooltip({
  continuous,
  index,
  size,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  skipProps
}) {
  const {
    title,
    target,
    placement,
    content,
    hideBackButton,
    hideNextButton,
    hideSkipButton
  } = step || {}

  let transform = null,
    containerStyles = null

  const targetX = document.querySelector(target).getBoundingClientRect().x

  if (placement.startsWith('left')) transform = 'translateX(-2px)'
  if (placement.startsWith('right') && window.innerWidth - targetX < 50) {
    transform = 'translateX(-40px)'
    containerStyles = { marginRight: 40 }
  }
  const classes = useStyles({ transform })
  const dispatch = useDispatch()

  return (
    <Box
      className={classes.container}
      style={containerStyles}
      {...tooltipProps}
    >
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Box>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.step}>
              Step {index + 1} of {size}
            </Typography>
          </Box>
          <CloseIcon
            {...closeProps}
            onClick={e => {
              closeProps.onClick(e)
              dispatch({ type: 'STOP' })
            }}
            className={classes.icon}
          />
        </Box>
        <Typography className={classes.text}>{content}</Typography>
      </Box>

      <Box className={classes.footer}>
        <Box className={classes.rightFooter}>
          {index !== 0 && !hideBackButton && (
            <Button
              disableElevation
              variant="outlined"
              size="small"
              className={cn(classes.button, classes.outlindButton)}
              {...backProps}
            >
              {backProps['aria-label']}
            </Button>
          )}
          {!hideNextButton && continuous && (
            <Button
              disableElevation
              variant="contained"
              size="small"
              className={cn(classes.button, classes.next)}
              {...primaryProps}
            >
              {primaryProps['aria-label']}
            </Button>
          )}
        </Box>
        {!hideSkipButton && (
          <Typography
            className={cn(classes.button, classes.skip)}
            {...skipProps}
          >
            {skipProps['aria-label']}
          </Typography>
        )}
      </Box>
    </Box>
  )
}
