import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    height: '100%',
    background: theme.palette.colors.backgrounds.main
  },
  title: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.primary.contrast,
    marginBottom: 24
  }
}))

const EmptyPage = () => {
  const classes = useStyles()
  return (
    <Grid
      direction="column"
      justify="center"
      alignItems="center"
      container
      className={classes.root}
    >
      <Typography className={classes.title}>No data found</Typography>
    </Grid>
  )
}

export default EmptyPage
