import React, { useState, useMemo } from 'react'
import IconButton from '@material-ui/core/IconButton'
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined'

const usePasswordType = () => {
  const [passwordType, setPasswordType] = useState('password')

  const setPassType = () => {
    setPasswordType(current => (current === 'password' ? 'text' : 'password'))
  }
  const IconType = useMemo(
    () => (
      <IconButton onClick={setPassType}>
        {passwordType === 'password' ? (
          <VisibilityIcon />
        ) : (
          <VisibilityOffIcon />
        )}
      </IconButton>
    ),
    [passwordType]
  )
  return { passwordType, IconType }
}

export default usePasswordType
