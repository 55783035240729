import React, { useMemo } from 'react'
import { drawings } from '../../../../../utils/mapDraws'
import {
  CUSTOM_PROBES,
  PROBE_OFFEST_EXCEPTIONS
} from '../../../../../utils/consts'

export const isOffsetException = probe =>
  PROBE_OFFEST_EXCEPTIONS.includes(probe)

export const getDraw = probeFile =>
  Object.keys(drawings).find(k => `int${k}dbc.prb` === probeFile)

export const getCustomDraw = probeFile =>
  Object.keys(drawings).find(
    k => `${k}.prb` === probeFile && CUSTOM_PROBES.has(k)
  )

export const findProbeDrawing = probeFile => {
  const findDraw = getDraw(probeFile)
  const findCustomDraw = getCustomDraw(probeFile)

  return drawings[findDraw] || drawings[findCustomDraw]
}

const DrawProbe = React.forwardRef(
  ({ multigroup, probeFile, preview = false, color }, ref) => {
    const draw = useMemo(() => findProbeDrawing(probeFile), [probeFile])

    if (draw?.svg) {
      return (
        <draw.svg
          svgRef={ref}
          color={color}
          preview={preview}
          flipped={multigroup}
        />
      )
    }

    return null
  }
)

export default DrawProbe
