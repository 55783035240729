import EmptyWidget from './EmptyWidget'
import React from 'react'
import BlurLoader from '../../../../BlurLoader'
import ActionButton from '../../../../ActionButton'
import { Cached } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  icon: {
    verticalAlign: 'middle',
    fontSize: 20
  }
}))

function WidgetLoader({
  error,
  loading,
  refetch,
  children,
  isLoadMore,
  requestFailed,
  message
}) {
  const classes = useStyles()

  const Loader = () => <BlurLoader size={25} />

  if (loading) return <Loader />

  if (requestFailed) {
    return (
      <EmptyWidget message={message}>
        <ActionButton
          onClick={refetch}
          label="Reload"
          leftAdornment={<Cached className={classes.icon} />}
          customStyles={{
            width: 'auto',
            marginTop: 15
          }}
        />
      </EmptyWidget>
    )
  }

  if (error) return <EmptyWidget />

  return (
    <>
      {isLoadMore && <Loader />} {children}
    </>
  )
}

export default WidgetLoader
