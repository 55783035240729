import React from 'react'

export const svg128_3_auto = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="68"
        height="5250"
        viewBox="0 0 68 5250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M68 0H0V5039.1L34 5250L68 5039.1V0Z" fill={color} />
        <path
          d="M9 163C9 161.343 10.3431 160 12 160H24C25.6569 160 27 161.343 27 163V174.077C27 175.734 25.6569 177.077 24 177.077H12C10.3431 177.077 9 175.734 9 174.077V163Z"
          fill="white"
        />
        <path
          d="M9 201.077C9 199.42 10.3431 198.077 12 198.077H24C25.6569 198.077 27 199.42 27 201.077V212.154C27 213.811 25.6569 215.154 24 215.154H12C10.3431 215.154 9 213.811 9 212.154V201.077Z"
          fill="white"
        />
        <path
          d="M9 239.154C9 237.497 10.3431 236.154 12 236.154H24C25.6569 236.154 27 237.497 27 239.154V250.231C27 251.888 25.6569 253.231 24 253.231H12C10.3431 253.231 9 251.888 9 250.231V239.154Z"
          fill="white"
        />
        <path
          d="M9 277.231C9 275.574 10.3431 274.231 12 274.231H24C25.6569 274.231 27 275.574 27 277.231V288.308C27 289.964 25.6569 291.308 24 291.308H12C10.3431 291.308 9 289.964 9 288.308V277.231Z"
          fill="white"
        />
        <path
          d="M9 315.308C9 313.651 10.3431 312.308 12 312.308H24C25.6569 312.308 27 313.651 27 315.308V326.385C27 328.041 25.6569 329.385 24 329.385H12C10.3431 329.385 9 328.041 9 326.385V315.308Z"
          fill="white"
        />
        <path
          d="M9 353.385C9 351.728 10.3431 350.385 12 350.385H24C25.6569 350.385 27 351.728 27 353.385V364.462C27 366.118 25.6569 367.462 24 367.462H12C10.3431 367.462 9 366.118 9 364.462V353.385Z"
          fill="white"
        />
        <path
          d="M9 391.462C9 389.805 10.3431 388.462 12 388.462H24C25.6569 388.462 27 389.805 27 391.462V402.538C27 404.195 25.6569 405.538 24 405.538H12C10.3431 405.538 9 404.195 9 402.538V391.462Z"
          fill="white"
        />
        <path
          d="M9 429.538C9 427.882 10.3431 426.538 12 426.538H24C25.6569 426.538 27 427.882 27 429.538V440.615C27 442.272 25.6569 443.615 24 443.615H12C10.3431 443.615 9 442.272 9 440.615V429.538Z"
          fill="white"
        />
        <path
          d="M9 467.615C9 465.959 10.3431 464.615 12 464.615H24C25.6569 464.615 27 465.959 27 467.615V478.692C27 480.349 25.6569 481.692 24 481.692H12C10.3431 481.692 9 480.349 9 478.692V467.615Z"
          fill="white"
        />
        <path
          d="M9 505.692C9 504.035 10.3431 502.692 12 502.692H24C25.6569 502.692 27 504.035 27 505.692V516.769C27 518.426 25.6569 519.769 24 519.769H12C10.3431 519.769 9 518.426 9 516.769V505.692Z"
          fill="white"
        />
        <path
          d="M9 543.769C9 542.112 10.3431 540.769 12 540.769H24C25.6569 540.769 27 542.112 27 543.769V554.846C27 556.503 25.6569 557.846 24 557.846H12C10.3431 557.846 9 556.503 9 554.846V543.769Z"
          fill="white"
        />
        <path
          d="M9 581.846C9 580.189 10.3431 578.846 12 578.846H24C25.6569 578.846 27 580.189 27 581.846V592.923C27 594.58 25.6569 595.923 24 595.923H12C10.3431 595.923 9 594.58 9 592.923V581.846Z"
          fill="white"
        />
        <path
          d="M9 619.923C9 618.266 10.3431 616.923 12 616.923H24C25.6569 616.923 27 618.266 27 619.923V631C27 632.657 25.6569 634 24 634H12C10.3431 634 9 632.657 9 631V619.923Z"
          fill="white"
        />
        <path
          d="M9 658C9 656.343 10.3431 655 12 655H24C25.6569 655 27 656.343 27 658V669.077C27 670.734 25.6569 672.077 24 672.077H12C10.3431 672.077 9 670.734 9 669.077V658Z"
          fill="white"
        />
        <path
          d="M9 696.077C9 694.42 10.3431 693.077 12 693.077H24C25.6569 693.077 27 694.42 27 696.077V707.154C27 708.811 25.6569 710.154 24 710.154H12C10.3431 710.154 9 708.811 9 707.154V696.077Z"
          fill="white"
        />
        <path
          d="M9 734.154C9 732.497 10.3431 731.154 12 731.154H24C25.6569 731.154 27 732.497 27 734.154V745.231C27 746.887 25.6569 748.231 24 748.231H12C10.3431 748.231 9 746.887 9 745.231V734.154Z"
          fill="white"
        />
        <path
          d="M9 772.231C9 770.574 10.3431 769.231 12 769.231H24C25.6569 769.231 27 770.574 27 772.231V783.308C27 784.964 25.6569 786.308 24 786.308H12C10.3431 786.308 9 784.964 9 783.308V772.231Z"
          fill="white"
        />
        <path
          d="M9 810.307C9 808.651 10.3431 807.307 12 807.307H24C25.6569 807.307 27 808.651 27 810.307V821.384C27 823.041 25.6569 824.384 24 824.384H12C10.3431 824.384 9 823.041 9 821.384V810.307Z"
          fill="white"
        />
        <path
          d="M9 848.384C9 846.728 10.3431 845.384 12 845.384H24C25.6569 845.384 27 846.728 27 848.384V859.461C27 861.118 25.6569 862.461 24 862.461H12C10.3431 862.461 9 861.118 9 859.461V848.384Z"
          fill="white"
        />
        <path
          d="M9 886.461C9 884.804 10.3431 883.461 12 883.461H24C25.6569 883.461 27 884.804 27 886.461V897.538C27 899.195 25.6569 900.538 24 900.538H12C10.3431 900.538 9 899.195 9 897.538V886.461Z"
          fill="white"
        />
        <path
          d="M9 924.538C9 922.881 10.3431 921.538 12 921.538H24C25.6569 921.538 27 922.881 27 924.538V935.615C27 937.272 25.6569 938.615 24 938.615H12C10.3431 938.615 9 937.272 9 935.615V924.538Z"
          fill="white"
        />
        <path
          d="M9 962.615C9 960.958 10.3431 959.615 12 959.615H24C25.6569 959.615 27 960.958 27 962.615V973.692C27 975.349 25.6569 976.692 24 976.692H12C10.3431 976.692 9 975.349 9 973.692V962.615Z"
          fill="white"
        />
        <path
          d="M9 1000.69C9 999.035 10.3431 997.692 12 997.692H24C25.6569 997.692 27 999.035 27 1000.69V1011.77C27 1013.43 25.6569 1014.77 24 1014.77H12C10.3431 1014.77 9 1013.43 9 1011.77V1000.69Z"
          fill="white"
        />
        <path
          d="M9 1038.77C9 1037.11 10.3431 1035.77 12 1035.77H24C25.6569 1035.77 27 1037.11 27 1038.77V1049.85C27 1051.5 25.6569 1052.85 24 1052.85H12C10.3431 1052.85 9 1051.5 9 1049.85V1038.77Z"
          fill="white"
        />
        <path
          d="M9 1076.85C9 1075.19 10.3431 1073.85 12 1073.85H24C25.6569 1073.85 27 1075.19 27 1076.85V1087.92C27 1089.58 25.6569 1090.92 24 1090.92H12C10.3431 1090.92 9 1089.58 9 1087.92V1076.85Z"
          fill="white"
        />
        <path
          d="M9 1114.92C9 1113.27 10.3431 1111.92 12 1111.92H24C25.6569 1111.92 27 1113.27 27 1114.92V1126C27 1127.66 25.6569 1129 24 1129H12C10.3431 1129 9 1127.66 9 1126V1114.92Z"
          fill="white"
        />
        <path
          d="M9 1153C9 1151.34 10.3431 1150 12 1150H24C25.6569 1150 27 1151.34 27 1153V1164.08C27 1165.73 25.6569 1167.08 24 1167.08H12C10.3431 1167.08 9 1165.73 9 1164.08V1153Z"
          fill="white"
        />
        <path
          d="M9 1191.08C9 1189.42 10.3431 1188.08 12 1188.08H24C25.6569 1188.08 27 1189.42 27 1191.08V1202.15C27 1203.81 25.6569 1205.15 24 1205.15H12C10.3431 1205.15 9 1203.81 9 1202.15V1191.08Z"
          fill="white"
        />
        <path
          d="M9 1229.15C9 1227.5 10.3431 1226.15 12 1226.15H24C25.6569 1226.15 27 1227.5 27 1229.15V1240.23C27 1241.89 25.6569 1243.23 24 1243.23H12C10.3431 1243.23 9 1241.89 9 1240.23V1229.15Z"
          fill="white"
        />
        <path
          d="M9 1267.23C9 1265.57 10.3431 1264.23 12 1264.23H24C25.6569 1264.23 27 1265.57 27 1267.23V1278.31C27 1279.96 25.6569 1281.31 24 1281.31H12C10.3431 1281.31 9 1279.96 9 1278.31V1267.23Z"
          fill="white"
        />
        <path
          d="M9 1305.31C9 1303.65 10.3431 1302.31 12 1302.31H24C25.6569 1302.31 27 1303.65 27 1305.31V1316.38C27 1318.04 25.6569 1319.38 24 1319.38H12C10.3431 1319.38 9 1318.04 9 1316.38V1305.31Z"
          fill="white"
        />
        <path
          d="M9 1343.38C9 1341.73 10.3431 1340.38 12 1340.38H24C25.6569 1340.38 27 1341.73 27 1343.38V1354.46C27 1356.12 25.6569 1357.46 24 1357.46H12C10.3431 1357.46 9 1356.12 9 1354.46V1343.38Z"
          fill="white"
        />
        <path
          d="M9 1381.46C9 1379.8 10.3431 1378.46 12 1378.46H24C25.6569 1378.46 27 1379.8 27 1381.46V1392.54C27 1394.19 25.6569 1395.54 24 1395.54H12C10.3431 1395.54 9 1394.19 9 1392.54V1381.46Z"
          fill="white"
        />
        <path
          d="M9 1419.54C9 1417.88 10.3431 1416.54 12 1416.54H24C25.6569 1416.54 27 1417.88 27 1419.54V1430.61C27 1432.27 25.6569 1433.61 24 1433.61H12C10.3431 1433.61 9 1432.27 9 1430.61V1419.54Z"
          fill="white"
        />
        <path
          d="M9 1457.61C9 1455.96 10.3431 1454.61 12 1454.61H24C25.6569 1454.61 27 1455.96 27 1457.61V1468.69C27 1470.35 25.6569 1471.69 24 1471.69H12C10.3431 1471.69 9 1470.35 9 1468.69V1457.61Z"
          fill="white"
        />
        <path
          d="M9 1495.69C9 1494.03 10.3431 1492.69 12 1492.69H24C25.6569 1492.69 27 1494.03 27 1495.69V1506.77C27 1508.43 25.6569 1509.77 24 1509.77H12C10.3431 1509.77 9 1508.43 9 1506.77V1495.69Z"
          fill="white"
        />
        <path
          d="M9 1533.77C9 1532.11 10.3431 1530.77 12 1530.77H24C25.6569 1530.77 27 1532.11 27 1533.77V1544.85C27 1546.5 25.6569 1547.85 24 1547.85H12C10.3431 1547.85 9 1546.5 9 1544.85V1533.77Z"
          fill="white"
        />
        <path
          d="M9 1571.85C9 1570.19 10.3431 1568.85 12 1568.85H24C25.6569 1568.85 27 1570.19 27 1571.85V1582.92C27 1584.58 25.6569 1585.92 24 1585.92H12C10.3431 1585.92 9 1584.58 9 1582.92V1571.85Z"
          fill="white"
        />
        <path
          d="M9 1609.92C9 1608.27 10.3431 1606.92 12 1606.92H24C25.6569 1606.92 27 1608.27 27 1609.92V1621C27 1622.66 25.6569 1624 24 1624H12C10.3431 1624 9 1622.66 9 1621V1609.92Z"
          fill="white"
        />
        <path
          d="M9 1648C9 1646.34 10.3431 1645 12 1645H24C25.6569 1645 27 1646.34 27 1648V1659.08C27 1660.73 25.6569 1662.08 24 1662.08H12C10.3431 1662.08 9 1660.73 9 1659.08V1648Z"
          fill="white"
        />
        <path
          d="M9 1686.08C9 1684.42 10.3431 1683.08 12 1683.08H24C25.6569 1683.08 27 1684.42 27 1686.08V1697.15C27 1698.81 25.6569 1700.15 24 1700.15H12C10.3431 1700.15 9 1698.81 9 1697.15V1686.08Z"
          fill="white"
        />
        <path
          d="M9 1724.15C9 1722.5 10.3431 1721.15 12 1721.15H24C25.6569 1721.15 27 1722.5 27 1724.15V1735.23C27 1736.89 25.6569 1738.23 24 1738.23H12C10.3431 1738.23 9 1736.89 9 1735.23V1724.15Z"
          fill="white"
        />
        <path
          d="M9 1762.23C9 1760.57 10.3431 1759.23 12 1759.23H24C25.6569 1759.23 27 1760.57 27 1762.23V1773.31C27 1774.96 25.6569 1776.31 24 1776.31H12C10.3431 1776.31 9 1774.96 9 1773.31V1762.23Z"
          fill="white"
        />
        <path
          d="M9 1800.31C9 1798.65 10.3431 1797.31 12 1797.31H24C25.6569 1797.31 27 1798.65 27 1800.31V1811.38C27 1813.04 25.6569 1814.38 24 1814.38H12C10.3431 1814.38 9 1813.04 9 1811.38V1800.31Z"
          fill="white"
        />
        <path
          d="M9 1838.38C9 1836.73 10.3431 1835.38 12 1835.38H24C25.6569 1835.38 27 1836.73 27 1838.38V1849.46C27 1851.12 25.6569 1852.46 24 1852.46H12C10.3431 1852.46 9 1851.12 9 1849.46V1838.38Z"
          fill="white"
        />
        <path
          d="M9 1876.46C9 1874.8 10.3431 1873.46 12 1873.46H24C25.6569 1873.46 27 1874.8 27 1876.46V1887.54C27 1889.19 25.6569 1890.54 24 1890.54H12C10.3431 1890.54 9 1889.19 9 1887.54V1876.46Z"
          fill="white"
        />
        <path
          d="M9 1914.54C9 1912.88 10.3431 1911.54 12 1911.54H24C25.6569 1911.54 27 1912.88 27 1914.54V1925.61C27 1927.27 25.6569 1928.61 24 1928.61H12C10.3431 1928.61 9 1927.27 9 1925.61V1914.54Z"
          fill="white"
        />
        <path
          d="M9 1952.61C9 1950.96 10.3431 1949.61 12 1949.61H24C25.6569 1949.61 27 1950.96 27 1952.61V1963.69C27 1965.35 25.6569 1966.69 24 1966.69H12C10.3431 1966.69 9 1965.35 9 1963.69V1952.61Z"
          fill="white"
        />
        <path
          d="M9 1990.69C9 1989.03 10.3431 1987.69 12 1987.69H24C25.6569 1987.69 27 1989.03 27 1990.69V2001.77C27 2003.43 25.6569 2004.77 24 2004.77H12C10.3431 2004.77 9 2003.43 9 2001.77V1990.69Z"
          fill="white"
        />
        <path
          d="M9 2028.77C9 2027.11 10.3431 2025.77 12 2025.77H24C25.6569 2025.77 27 2027.11 27 2028.77V2039.85C27 2041.5 25.6569 2042.85 24 2042.85H12C10.3431 2042.85 9 2041.5 9 2039.85V2028.77Z"
          fill="white"
        />
        <path
          d="M9 2066.85C9 2065.19 10.3431 2063.85 12 2063.85H24C25.6569 2063.85 27 2065.19 27 2066.85V2077.92C27 2079.58 25.6569 2080.92 24 2080.92H12C10.3431 2080.92 9 2079.58 9 2077.92V2066.85Z"
          fill="white"
        />
        <path
          d="M9 2104.92C9 2103.27 10.3431 2101.92 12 2101.92H24C25.6569 2101.92 27 2103.27 27 2104.92V2116C27 2117.66 25.6569 2119 24 2119H12C10.3431 2119 9 2117.66 9 2116V2104.92Z"
          fill="white"
        />
        <path
          d="M9 2143C9 2141.34 10.3431 2140 12 2140H24C25.6569 2140 27 2141.34 27 2143V2154.08C27 2155.73 25.6569 2157.08 24 2157.08H12C10.3431 2157.08 9 2155.73 9 2154.08V2143Z"
          fill="white"
        />
        <path
          d="M9 2181.08C9 2179.42 10.3431 2178.08 12 2178.08H24C25.6569 2178.08 27 2179.42 27 2181.08V2192.15C27 2193.81 25.6569 2195.15 24 2195.15H12C10.3431 2195.15 9 2193.81 9 2192.15V2181.08Z"
          fill="white"
        />
        <path
          d="M9 2219.15C9 2217.5 10.3431 2216.15 12 2216.15H24C25.6569 2216.15 27 2217.5 27 2219.15V2230.23C27 2231.89 25.6569 2233.23 24 2233.23H12C10.3431 2233.23 9 2231.89 9 2230.23V2219.15Z"
          fill="white"
        />
        <path
          d="M9 2257.23C9 2255.57 10.3431 2254.23 12 2254.23H24C25.6569 2254.23 27 2255.57 27 2257.23V2268.31C27 2269.96 25.6569 2271.31 24 2271.31H12C10.3431 2271.31 9 2269.96 9 2268.31V2257.23Z"
          fill="white"
        />
        <path
          d="M9 2295.31C9 2293.65 10.3431 2292.31 12 2292.31H24C25.6569 2292.31 27 2293.65 27 2295.31V2306.38C27 2308.04 25.6569 2309.38 24 2309.38H12C10.3431 2309.38 9 2308.04 9 2306.38V2295.31Z"
          fill="white"
        />
        <path
          d="M9 2333.38C9 2331.73 10.3431 2330.38 12 2330.38H24C25.6569 2330.38 27 2331.73 27 2333.38V2344.46C27 2346.12 25.6569 2347.46 24 2347.46H12C10.3431 2347.46 9 2346.12 9 2344.46V2333.38Z"
          fill="white"
        />
        <path
          d="M9 2371.46C9 2369.8 10.3431 2368.46 12 2368.46H24C25.6569 2368.46 27 2369.8 27 2371.46V2382.54C27 2384.19 25.6569 2385.54 24 2385.54H12C10.3431 2385.54 9 2384.19 9 2382.54V2371.46Z"
          fill="white"
        />
        <path
          d="M9 2409.54C9 2407.88 10.3431 2406.54 12 2406.54H24C25.6569 2406.54 27 2407.88 27 2409.54V2420.61C27 2422.27 25.6569 2423.61 24 2423.61H12C10.3431 2423.61 9 2422.27 9 2420.61V2409.54Z"
          fill="white"
        />
        <path
          d="M9 2447.61C9 2445.96 10.3431 2444.61 12 2444.61H24C25.6569 2444.61 27 2445.96 27 2447.61V2458.69C27 2460.35 25.6569 2461.69 24 2461.69H12C10.3431 2461.69 9 2460.35 9 2458.69V2447.61Z"
          fill="white"
        />
        <path
          d="M9 2485.69C9 2484.03 10.3431 2482.69 12 2482.69H24C25.6569 2482.69 27 2484.03 27 2485.69V2496.77C27 2498.43 25.6569 2499.77 24 2499.77H12C10.3431 2499.77 9 2498.43 9 2496.77V2485.69Z"
          fill="white"
        />
        <path
          d="M9 2523.77C9 2522.11 10.3431 2520.77 12 2520.77H24C25.6569 2520.77 27 2522.11 27 2523.77V2534.85C27 2536.5 25.6569 2537.85 24 2537.85H12C10.3431 2537.85 9 2536.5 9 2534.85V2523.77Z"
          fill="white"
        />
        <path
          d="M9 2561.85C9 2560.19 10.3431 2558.85 12 2558.85H24C25.6569 2558.85 27 2560.19 27 2561.85V2572.92C27 2574.58 25.6569 2575.92 24 2575.92H12C10.3431 2575.92 9 2574.58 9 2572.92V2561.85Z"
          fill="white"
        />
        <path
          d="M9 2599.92C9 2598.27 10.3431 2596.92 12 2596.92H24C25.6569 2596.92 27 2598.27 27 2599.92V2611C27 2612.66 25.6569 2614 24 2614H12C10.3431 2614 9 2612.66 9 2611V2599.92Z"
          fill="white"
        />
        <path
          d="M9 2638C9 2636.34 10.3431 2635 12 2635H24C25.6569 2635 27 2636.34 27 2638V2649.08C27 2650.73 25.6569 2652.08 24 2652.08H12C10.3431 2652.08 9 2650.73 9 2649.08V2638Z"
          fill="white"
        />
        <path
          d="M9 2676.08C9 2674.42 10.3431 2673.08 12 2673.08H24C25.6569 2673.08 27 2674.42 27 2676.08V2687.15C27 2688.81 25.6569 2690.15 24 2690.15H12C10.3431 2690.15 9 2688.81 9 2687.15V2676.08Z"
          fill="white"
        />
        <path
          d="M9 2714.15C9 2712.5 10.3431 2711.15 12 2711.15H24C25.6569 2711.15 27 2712.5 27 2714.15V2725.23C27 2726.89 25.6569 2728.23 24 2728.23H12C10.3431 2728.23 9 2726.89 9 2725.23V2714.15Z"
          fill="white"
        />
        <path
          d="M9 2752.23C9 2750.57 10.3431 2749.23 12 2749.23H24C25.6569 2749.23 27 2750.57 27 2752.23V2763.31C27 2764.96 25.6569 2766.31 24 2766.31H12C10.3431 2766.31 9 2764.96 9 2763.31V2752.23Z"
          fill="white"
        />
        <path
          d="M9 2790.31C9 2788.65 10.3431 2787.31 12 2787.31H24C25.6569 2787.31 27 2788.65 27 2790.31V2801.38C27 2803.04 25.6569 2804.38 24 2804.38H12C10.3431 2804.38 9 2803.04 9 2801.38V2790.31Z"
          fill="white"
        />
        <path
          d="M9 2828.38C9 2826.73 10.3431 2825.38 12 2825.38H24C25.6569 2825.38 27 2826.73 27 2828.38V2839.46C27 2841.12 25.6569 2842.46 24 2842.46H12C10.3431 2842.46 9 2841.12 9 2839.46V2828.38Z"
          fill="white"
        />
        <path
          d="M9 2866.46C9 2864.8 10.3431 2863.46 12 2863.46H24C25.6569 2863.46 27 2864.8 27 2866.46V2877.54C27 2879.19 25.6569 2880.54 24 2880.54H12C10.3431 2880.54 9 2879.19 9 2877.54V2866.46Z"
          fill="white"
        />
        <path
          d="M9 2904.54C9 2902.88 10.3431 2901.54 12 2901.54H24C25.6569 2901.54 27 2902.88 27 2904.54V2915.61C27 2917.27 25.6569 2918.61 24 2918.61H12C10.3431 2918.61 9 2917.27 9 2915.61V2904.54Z"
          fill="white"
        />
        <path
          d="M9 2942.61C9 2940.96 10.3431 2939.61 12 2939.61H24C25.6569 2939.61 27 2940.96 27 2942.61V2953.69C27 2955.35 25.6569 2956.69 24 2956.69H12C10.3431 2956.69 9 2955.35 9 2953.69V2942.61Z"
          fill="white"
        />
        <path
          d="M9 2980.69C9 2979.03 10.3431 2977.69 12 2977.69H24C25.6569 2977.69 27 2979.03 27 2980.69V2991.77C27 2993.42 25.6569 2994.77 24 2994.77H12C10.3431 2994.77 9 2993.42 9 2991.77V2980.69Z"
          fill="white"
        />
        <path
          d="M9 3018.77C9 3017.11 10.3431 3015.77 12 3015.77H24C25.6569 3015.77 27 3017.11 27 3018.77V3029.84C27 3031.5 25.6569 3032.84 24 3032.84H12C10.3431 3032.84 9 3031.5 9 3029.84V3018.77Z"
          fill="white"
        />
        <path
          d="M9 3056.84C9 3055.19 10.3431 3053.84 12 3053.84H24C25.6569 3053.84 27 3055.19 27 3056.84V3067.92C27 3069.58 25.6569 3070.92 24 3070.92H12C10.3431 3070.92 9 3069.58 9 3067.92V3056.84Z"
          fill="white"
        />
        <path
          d="M9 3094.92C9 3093.27 10.3431 3091.92 12 3091.92H24C25.6569 3091.92 27 3093.27 27 3094.92V3106C27 3107.66 25.6569 3109 24 3109H12C10.3431 3109 9 3107.66 9 3106V3094.92Z"
          fill="white"
        />
        <path
          d="M9 3133C9 3131.34 10.3431 3130 12 3130H24C25.6569 3130 27 3131.34 27 3133V3144.08C27 3145.73 25.6569 3147.08 24 3147.08H12C10.3431 3147.08 9 3145.73 9 3144.08V3133Z"
          fill="white"
        />
        <path
          d="M9 3171.08C9 3169.42 10.3431 3168.08 12 3168.08H24C25.6569 3168.08 27 3169.42 27 3171.08V3182.15C27 3183.81 25.6569 3185.15 24 3185.15H12C10.3431 3185.15 9 3183.81 9 3182.15V3171.08Z"
          fill="white"
        />
        <path
          d="M9 3209.15C9 3207.5 10.3431 3206.15 12 3206.15H24C25.6569 3206.15 27 3207.5 27 3209.15V3220.23C27 3221.89 25.6569 3223.23 24 3223.23H12C10.3431 3223.23 9 3221.89 9 3220.23V3209.15Z"
          fill="white"
        />
        <path
          d="M9 3247.23C9 3245.57 10.3431 3244.23 12 3244.23H24C25.6569 3244.23 27 3245.57 27 3247.23V3258.31C27 3259.96 25.6569 3261.31 24 3261.31H12C10.3431 3261.31 9 3259.96 9 3258.31V3247.23Z"
          fill="white"
        />
        <path
          d="M9 3285.31C9 3283.65 10.3431 3282.31 12 3282.31H24C25.6569 3282.31 27 3283.65 27 3285.31V3296.38C27 3298.04 25.6569 3299.38 24 3299.38H12C10.3431 3299.38 9 3298.04 9 3296.38V3285.31Z"
          fill="white"
        />
        <path
          d="M9 3323.38C9 3321.73 10.3431 3320.38 12 3320.38H24C25.6569 3320.38 27 3321.73 27 3323.38V3334.46C27 3336.12 25.6569 3337.46 24 3337.46H12C10.3431 3337.46 9 3336.12 9 3334.46V3323.38Z"
          fill="white"
        />
        <path
          d="M9 3361.46C9 3359.8 10.3431 3358.46 12 3358.46H24C25.6569 3358.46 27 3359.8 27 3361.46V3372.54C27 3374.19 25.6569 3375.54 24 3375.54H12C10.3431 3375.54 9 3374.19 9 3372.54V3361.46Z"
          fill="white"
        />
        <path
          d="M9 3399.54C9 3397.88 10.3431 3396.54 12 3396.54H24C25.6569 3396.54 27 3397.88 27 3399.54V3410.61C27 3412.27 25.6569 3413.61 24 3413.61H12C10.3431 3413.61 9 3412.27 9 3410.61V3399.54Z"
          fill="white"
        />
        <path
          d="M9 3437.61C9 3435.96 10.3431 3434.61 12 3434.61H24C25.6569 3434.61 27 3435.96 27 3437.61V3448.69C27 3450.35 25.6569 3451.69 24 3451.69H12C10.3431 3451.69 9 3450.35 9 3448.69V3437.61Z"
          fill="white"
        />
        <path
          d="M9 3475.69C9 3474.03 10.3431 3472.69 12 3472.69H24C25.6569 3472.69 27 3474.03 27 3475.69V3486.77C27 3488.42 25.6569 3489.77 24 3489.77H12C10.3431 3489.77 9 3488.42 9 3486.77V3475.69Z"
          fill="white"
        />
        <path
          d="M9 3513.77C9 3512.11 10.3431 3510.77 12 3510.77H24C25.6569 3510.77 27 3512.11 27 3513.77V3524.84C27 3526.5 25.6569 3527.84 24 3527.84H12C10.3431 3527.84 9 3526.5 9 3524.84V3513.77Z"
          fill="white"
        />
        <path
          d="M9 3551.84C9 3550.19 10.3431 3548.84 12 3548.84H24C25.6569 3548.84 27 3550.19 27 3551.84V3562.92C27 3564.58 25.6569 3565.92 24 3565.92H12C10.3431 3565.92 9 3564.58 9 3562.92V3551.84Z"
          fill="white"
        />
        <path
          d="M9 3589.92C9 3588.26 10.3431 3586.92 12 3586.92H24C25.6569 3586.92 27 3588.26 27 3589.92V3601C27 3602.66 25.6569 3604 24 3604H12C10.3431 3604 9 3602.66 9 3601V3589.92Z"
          fill="white"
        />
        <path
          d="M9 3628C9 3626.34 10.3431 3625 12 3625H24C25.6569 3625 27 3626.34 27 3628V3639.08C27 3640.73 25.6569 3642.08 24 3642.08H12C10.3431 3642.08 9 3640.73 9 3639.08V3628Z"
          fill="white"
        />
        <path
          d="M9 3666.08C9 3664.42 10.3431 3663.08 12 3663.08H24C25.6569 3663.08 27 3664.42 27 3666.08V3677.15C27 3678.81 25.6569 3680.15 24 3680.15H12C10.3431 3680.15 9 3678.81 9 3677.15V3666.08Z"
          fill="white"
        />
        <path
          d="M9 3704.15C9 3702.5 10.3431 3701.15 12 3701.15H24C25.6569 3701.15 27 3702.5 27 3704.15V3715.23C27 3716.89 25.6569 3718.23 24 3718.23H12C10.3431 3718.23 9 3716.89 9 3715.23V3704.15Z"
          fill="white"
        />
        <path
          d="M9 3742.23C9 3740.57 10.3431 3739.23 12 3739.23H24C25.6569 3739.23 27 3740.57 27 3742.23V3753.31C27 3754.96 25.6569 3756.31 24 3756.31H12C10.3431 3756.31 9 3754.96 9 3753.31V3742.23Z"
          fill="white"
        />
        <path
          d="M9 3780.31C9 3778.65 10.3431 3777.31 12 3777.31H24C25.6569 3777.31 27 3778.65 27 3780.31V3791.38C27 3793.04 25.6569 3794.38 24 3794.38H12C10.3431 3794.38 9 3793.04 9 3791.38V3780.31Z"
          fill="white"
        />
        <path
          d="M9 3818.38C9 3816.73 10.3431 3815.38 12 3815.38H24C25.6569 3815.38 27 3816.73 27 3818.38V3829.46C27 3831.12 25.6569 3832.46 24 3832.46H12C10.3431 3832.46 9 3831.12 9 3829.46V3818.38Z"
          fill="white"
        />
        <path
          d="M9 3856.46C9 3854.8 10.3431 3853.46 12 3853.46H24C25.6569 3853.46 27 3854.8 27 3856.46V3867.54C27 3869.19 25.6569 3870.54 24 3870.54H12C10.3431 3870.54 9 3869.19 9 3867.54V3856.46Z"
          fill="white"
        />
        <path
          d="M9 3894.54C9 3892.88 10.3431 3891.54 12 3891.54H24C25.6569 3891.54 27 3892.88 27 3894.54V3905.61C27 3907.27 25.6569 3908.61 24 3908.61H12C10.3431 3908.61 9 3907.27 9 3905.61V3894.54Z"
          fill="white"
        />
        <path
          d="M9 3932.61C9 3930.96 10.3431 3929.61 12 3929.61H24C25.6569 3929.61 27 3930.96 27 3932.61V3943.69C27 3945.35 25.6569 3946.69 24 3946.69H12C10.3431 3946.69 9 3945.35 9 3943.69V3932.61Z"
          fill="white"
        />
        <path
          d="M9 3970.69C9 3969.03 10.3431 3967.69 12 3967.69H24C25.6569 3967.69 27 3969.03 27 3970.69V3981.77C27 3983.42 25.6569 3984.77 24 3984.77H12C10.3431 3984.77 9 3983.42 9 3981.77V3970.69Z"
          fill="white"
        />
        <path
          d="M9 4008.77C9 4007.11 10.3431 4005.77 12 4005.77H24C25.6569 4005.77 27 4007.11 27 4008.77V4019.84C27 4021.5 25.6569 4022.84 24 4022.84H12C10.3431 4022.84 9 4021.5 9 4019.84V4008.77Z"
          fill="white"
        />
        <path
          d="M9 4046.84C9 4045.19 10.3431 4043.84 12 4043.84H24C25.6569 4043.84 27 4045.19 27 4046.84V4057.92C27 4059.58 25.6569 4060.92 24 4060.92H12C10.3431 4060.92 9 4059.58 9 4057.92V4046.84Z"
          fill="white"
        />
        <path
          d="M9 4084.92C9 4083.26 10.3431 4081.92 12 4081.92H24C25.6569 4081.92 27 4083.26 27 4084.92V4096C27 4097.66 25.6569 4099 24 4099H12C10.3431 4099 9 4097.66 9 4096V4084.92Z"
          fill="white"
        />
        <path
          d="M9 4123C9 4121.34 10.3431 4120 12 4120H24C25.6569 4120 27 4121.34 27 4123V4134.08C27 4135.73 25.6569 4137.08 24 4137.08H12C10.3431 4137.08 9 4135.73 9 4134.08V4123Z"
          fill="white"
        />
        <path
          d="M9 4161.08C9 4159.42 10.3431 4158.08 12 4158.08H24C25.6569 4158.08 27 4159.42 27 4161.08V4172.15C27 4173.81 25.6569 4175.15 24 4175.15H12C10.3431 4175.15 9 4173.81 9 4172.15V4161.08Z"
          fill="white"
        />
        <path
          d="M9 4199.15C9 4197.5 10.3431 4196.15 12 4196.15H24C25.6569 4196.15 27 4197.5 27 4199.15V4210.23C27 4211.89 25.6569 4213.23 24 4213.23H12C10.3431 4213.23 9 4211.89 9 4210.23V4199.15Z"
          fill="white"
        />
        <path
          d="M9 4237.23C9 4235.57 10.3431 4234.23 12 4234.23H24C25.6569 4234.23 27 4235.57 27 4237.23V4248.31C27 4249.96 25.6569 4251.31 24 4251.31H12C10.3431 4251.31 9 4249.96 9 4248.31V4237.23Z"
          fill="white"
        />
        <path
          d="M9 4275.31C9 4273.65 10.3431 4272.31 12 4272.31H24C25.6569 4272.31 27 4273.65 27 4275.31V4286.38C27 4288.04 25.6569 4289.38 24 4289.38H12C10.3431 4289.38 9 4288.04 9 4286.38V4275.31Z"
          fill="white"
        />
        <path
          d="M9 4313.38C9 4311.73 10.3431 4310.38 12 4310.38H24C25.6569 4310.38 27 4311.73 27 4313.38V4324.46C27 4326.12 25.6569 4327.46 24 4327.46H12C10.3431 4327.46 9 4326.12 9 4324.46V4313.38Z"
          fill="white"
        />
        <path
          d="M9 4351.46C9 4349.8 10.3431 4348.46 12 4348.46H24C25.6569 4348.46 27 4349.8 27 4351.46V4362.54C27 4364.19 25.6569 4365.54 24 4365.54H12C10.3431 4365.54 9 4364.19 9 4362.54V4351.46Z"
          fill="white"
        />
        <path
          d="M9 4389.54C9 4387.88 10.3431 4386.54 12 4386.54H24C25.6569 4386.54 27 4387.88 27 4389.54V4400.61C27 4402.27 25.6569 4403.61 24 4403.61H12C10.3431 4403.61 9 4402.27 9 4400.61V4389.54Z"
          fill="white"
        />
        <path
          d="M9 4427.61C9 4425.96 10.3431 4424.61 12 4424.61H24C25.6569 4424.61 27 4425.96 27 4427.61V4438.69C27 4440.35 25.6569 4441.69 24 4441.69H12C10.3431 4441.69 9 4440.35 9 4438.69V4427.61Z"
          fill="white"
        />
        <path
          d="M9 4465.69C9 4464.04 10.3431 4462.69 12 4462.69H24C25.6569 4462.69 27 4464.04 27 4465.69V4476.77C27 4478.43 25.6569 4479.77 24 4479.77H12C10.3431 4479.77 9 4478.43 9 4476.77V4465.69Z"
          fill="white"
        />
        <path
          d="M9 4503.77C9 4502.11 10.3431 4500.77 12 4500.77H24C25.6569 4500.77 27 4502.11 27 4503.77V4514.85C27 4516.5 25.6569 4517.85 24 4517.85H12C10.3431 4517.85 9 4516.5 9 4514.85V4503.77Z"
          fill="white"
        />
        <path
          d="M9 4541.85C9 4540.19 10.3431 4538.85 12 4538.85H24C25.6569 4538.85 27 4540.19 27 4541.85V4552.92C27 4554.58 25.6569 4555.92 24 4555.92H12C10.3431 4555.92 9 4554.58 9 4552.92V4541.85Z"
          fill="white"
        />
        <path
          d="M9 4579.92C9 4578.27 10.3431 4576.92 12 4576.92H24C25.6569 4576.92 27 4578.27 27 4579.92V4591C27 4592.66 25.6569 4594 24 4594H12C10.3431 4594 9 4592.66 9 4591V4579.92Z"
          fill="white"
        />
        <path
          d="M9 4618C9 4616.34 10.3431 4615 12 4615H24C25.6569 4615 27 4616.34 27 4618V4629.08C27 4630.73 25.6569 4632.08 24 4632.08H12C10.3431 4632.08 9 4630.73 9 4629.08V4618Z"
          fill="white"
        />
        <path
          d="M9 4656.08C9 4654.42 10.3431 4653.08 12 4653.08H24C25.6569 4653.08 27 4654.42 27 4656.08V4667.15C27 4668.81 25.6569 4670.15 24 4670.15H12C10.3431 4670.15 9 4668.81 9 4667.15V4656.08Z"
          fill="white"
        />
        <path
          d="M9 4694.15C9 4692.5 10.3431 4691.15 12 4691.15H24C25.6569 4691.15 27 4692.5 27 4694.15V4705.23C27 4706.89 25.6569 4708.23 24 4708.23H12C10.3431 4708.23 9 4706.89 9 4705.23V4694.15Z"
          fill="white"
        />
        <path
          d="M9 4732.23C9 4730.58 10.3431 4729.23 12 4729.23H24C25.6569 4729.23 27 4730.58 27 4732.23V4743.31C27 4744.97 25.6569 4746.31 24 4746.31H12C10.3431 4746.31 9 4744.97 9 4743.31V4732.23Z"
          fill="white"
        />
        <path
          d="M9 4770.31C9 4768.65 10.3431 4767.31 12 4767.31H24C25.6569 4767.31 27 4768.65 27 4770.31V4781.39C27 4783.04 25.6569 4784.39 24 4784.39H12C10.3431 4784.39 9 4783.04 9 4781.39V4770.31Z"
          fill="white"
        />
        <path
          d="M9 4808.39C9 4806.73 10.3431 4805.39 12 4805.39H24C25.6569 4805.39 27 4806.73 27 4808.39V4819.46C27 4821.12 25.6569 4822.46 24 4822.46H12C10.3431 4822.46 9 4821.12 9 4819.46V4808.39Z"
          fill="white"
        />
        <path
          d="M9 4846.46C9 4844.81 10.3431 4843.46 12 4843.46H24C25.6569 4843.46 27 4844.81 27 4846.46V4857.54C27 4859.2 25.6569 4860.54 24 4860.54H12C10.3431 4860.54 9 4859.2 9 4857.54V4846.46Z"
          fill="white"
        />
        <path
          d="M9 4884.54C9 4882.88 10.3431 4881.54 12 4881.54H24C25.6569 4881.54 27 4882.88 27 4884.54V4895.62C27 4897.27 25.6569 4898.62 24 4898.62H12C10.3431 4898.62 9 4897.27 9 4895.62V4884.54Z"
          fill="white"
        />
        <path
          d="M9 4922.62C9 4920.96 10.3431 4919.62 12 4919.62H24C25.6569 4919.62 27 4920.96 27 4922.62V4933.69C27 4935.35 25.6569 4936.69 24 4936.69H12C10.3431 4936.69 9 4935.35 9 4933.69V4922.62Z"
          fill="white"
        />
        <path
          d="M9 4960.69C9 4959.04 10.3431 4957.69 12 4957.69H24C25.6569 4957.69 27 4959.04 27 4960.69V4971.77C27 4973.43 25.6569 4974.77 24 4974.77H12C10.3431 4974.77 9 4973.43 9 4971.77V4960.69Z"
          fill="white"
        />
        <path
          d="M9 4998.77C9 4997.12 10.3431 4995.77 12 4995.77H24C25.6569 4995.77 27 4997.12 27 4998.77V5009.85C27 5011.51 25.6569 5012.85 24 5012.85H12C10.3431 5012.85 9 5011.51 9 5009.85V4998.77Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="68"
      height="5250"
      viewBox="0 0 68 5250"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M68 0H0V5093.41L34 5250L68 5093.41V0Z" fill={color} />
    </svg>
  )
}
