import React from 'react'

export const svg64_3 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="50"
        height="984"
        viewBox="0 0 50 984"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 948.389V0H50V179.931L20 984L0 948.389Z" fill={color} />
        <path
          d="M2 88C2 86.3431 3.34315 85 5 85H17C18.6569 85 20 86.3431 20 88V99.0769C20 100.734 18.6569 102.077 17 102.077H5C3.34315 102.077 2 100.734 2 99.0769V88Z"
          fill="white"
        />
        <path
          d="M2 117.077C2 115.42 3.34315 114.077 5 114.077H17C18.6569 114.077 20 115.42 20 117.077V128.154C20 129.811 18.6569 131.154 17 131.154H5C3.34315 131.154 2 129.811 2 128.154V117.077Z"
          fill="white"
        />
        <path
          d="M2 146.154C2 144.497 3.34315 143.154 5 143.154H17C18.6569 143.154 20 144.497 20 146.154V157.231C20 158.888 18.6569 160.231 17 160.231H5C3.34315 160.231 2 158.888 2 157.231V146.154Z"
          fill="white"
        />
        <path
          d="M2 175.231C2 173.574 3.34315 172.231 5 172.231H17C18.6569 172.231 20 173.574 20 175.231V186.308C20 187.964 18.6569 189.308 17 189.308H5C3.34315 189.308 2 187.964 2 186.308V175.231Z"
          fill="white"
        />
        <path
          d="M2 204.308C2 202.651 3.34315 201.308 5 201.308H17C18.6569 201.308 20 202.651 20 204.308V215.385C20 217.041 18.6569 218.385 17 218.385H5C3.34315 218.385 2 217.041 2 215.385V204.308Z"
          fill="white"
        />
        <path
          d="M2 233.385C2 231.728 3.34315 230.385 5 230.385H17C18.6569 230.385 20 231.728 20 233.385V244.461C20 246.118 18.6569 247.461 17 247.461H5C3.34315 247.461 2 246.118 2 244.461V233.385Z"
          fill="white"
        />
        <path
          d="M2 262.461C2 260.805 3.34315 259.461 5 259.461H17C18.6569 259.461 20 260.805 20 262.461V273.538C20 275.195 18.6569 276.538 17 276.538H5C3.34315 276.538 2 275.195 2 273.538V262.461Z"
          fill="white"
        />
        <path
          d="M2 291.538C2 289.881 3.34315 288.538 5 288.538H17C18.6569 288.538 20 289.881 20 291.538V302.615C20 304.272 18.6569 305.615 17 305.615H5C3.34315 305.615 2 304.272 2 302.615V291.538Z"
          fill="white"
        />
        <path
          d="M2 320.615C2 318.958 3.34315 317.615 5 317.615H17C18.6569 317.615 20 318.958 20 320.615V331.692C20 333.349 18.6569 334.692 17 334.692H5C3.34315 334.692 2 333.349 2 331.692V320.615Z"
          fill="white"
        />
        <path
          d="M2 349.692C2 348.036 3.34315 346.692 5 346.692H17C18.6569 346.692 20 348.036 20 349.692V360.769C20 362.426 18.6569 363.769 17 363.769H5C3.34315 363.769 2 362.426 2 360.769V349.692Z"
          fill="white"
        />
        <path
          d="M2 378.769C2 377.112 3.34315 375.769 5 375.769H17C18.6569 375.769 20 377.112 20 378.769V389.846C20 391.503 18.6569 392.846 17 392.846H5C3.34315 392.846 2 391.503 2 389.846V378.769Z"
          fill="white"
        />
        <path
          d="M2 407.846C2 406.189 3.34315 404.846 5 404.846H17C18.6569 404.846 20 406.189 20 407.846V418.923C20 420.58 18.6569 421.923 17 421.923H5C3.34315 421.923 2 420.58 2 418.923V407.846Z"
          fill="white"
        />
        <path
          d="M2 436.923C2 435.266 3.34315 433.923 5 433.923H17C18.6569 433.923 20 435.266 20 436.923V448C20 449.657 18.6569 451 17 451H5C3.34315 451 2 449.657 2 448V436.923Z"
          fill="white"
        />
        <path
          d="M2 466C2 464.343 3.34315 463 5 463H17C18.6569 463 20 464.343 20 466V477.077C20 478.734 18.6569 480.077 17 480.077H5C3.34315 480.077 2 478.734 2 477.077V466Z"
          fill="white"
        />
        <path
          d="M2 495.077C2 493.42 3.34315 492.077 5 492.077H17C18.6569 492.077 20 493.42 20 495.077V506.154C20 507.81 18.6569 509.154 17 509.154H5C3.34315 509.154 2 507.81 2 506.154V495.077Z"
          fill="white"
        />
        <path
          d="M2 524.154C2 522.497 3.34315 521.154 5 521.154H17C18.6569 521.154 20 522.497 20 524.154V535.231C20 536.888 18.6569 538.231 17 538.231H5C3.34315 538.231 2 536.888 2 535.231V524.154Z"
          fill="white"
        />
        <path
          d="M2 553.23C2 551.574 3.34315 550.23 5 550.23H17C18.6569 550.23 20 551.574 20 553.23V564.307C20 565.964 18.6569 567.307 17 567.307H5C3.34315 567.307 2 565.964 2 564.307V553.23Z"
          fill="white"
        />
        <path
          d="M2 582.308C2 580.651 3.34315 579.308 5 579.308H17C18.6569 579.308 20 580.651 20 582.308V593.385C20 595.041 18.6569 596.385 17 596.385H5C3.34315 596.385 2 595.041 2 593.385V582.308Z"
          fill="white"
        />
        <path
          d="M2 611.384C2 609.727 3.34315 608.384 5 608.384H17C18.6569 608.384 20 609.727 20 611.384V622.461C20 624.118 18.6569 625.461 17 625.461H5C3.34315 625.461 2 624.118 2 622.461V611.384Z"
          fill="white"
        />
        <path
          d="M2 640.461C2 638.805 3.34315 637.461 5 637.461H17C18.6569 637.461 20 638.805 20 640.461V651.538C20 653.195 18.6569 654.538 17 654.538H5C3.34315 654.538 2 653.195 2 651.538V640.461Z"
          fill="white"
        />
        <path
          d="M2 669.538C2 667.881 3.34315 666.538 5 666.538H17C18.6569 666.538 20 667.881 20 669.538V680.615C20 682.272 18.6569 683.615 17 683.615H5C3.34315 683.615 2 682.272 2 680.615V669.538Z"
          fill="white"
        />
        <path
          d="M2 698.615C2 696.958 3.34315 695.615 5 695.615H17C18.6569 695.615 20 696.958 20 698.615V709.692C20 711.349 18.6569 712.692 17 712.692H5C3.34315 712.692 2 711.349 2 709.692V698.615Z"
          fill="white"
        />
        <path
          d="M2 727.692C2 726.035 3.34315 724.692 5 724.692H17C18.6569 724.692 20 726.035 20 727.692V738.769C20 740.426 18.6569 741.769 17 741.769H5C3.34315 741.769 2 740.426 2 738.769V727.692Z"
          fill="white"
        />
        <path
          d="M2 756.769C2 755.112 3.34315 753.769 5 753.769H17C18.6569 753.769 20 755.112 20 756.769V767.846C20 769.503 18.6569 770.846 17 770.846H5C3.34315 770.846 2 769.503 2 767.846V756.769Z"
          fill="white"
        />
        <path
          d="M2 785.846C2 784.189 3.34315 782.846 5 782.846H17C18.6569 782.846 20 784.189 20 785.846V796.923C20 798.579 18.6569 799.923 17 799.923H5C3.34315 799.923 2 798.579 2 796.923V785.846Z"
          fill="white"
        />
        <path
          d="M2 814.923C2 813.266 3.34315 811.923 5 811.923H17C18.6569 811.923 20 813.266 20 814.923V826C20 827.657 18.6569 829 17 829H5C3.34315 829 2 827.657 2 826V814.923Z"
          fill="white"
        />
        <path
          d="M2 844C2 842.343 3.34315 841 5 841H17C18.6569 841 20 842.343 20 844V855.076C20 856.733 18.6569 858.076 17 858.076H5C3.34315 858.076 2 856.733 2 855.076V844Z"
          fill="white"
        />
        <path
          d="M2 873.077C2 871.42 3.34315 870.077 5 870.077H17C18.6569 870.077 20 871.42 20 873.077V884.154C20 885.81 18.6569 887.154 17 887.154H5C3.34315 887.154 2 885.81 2 884.154V873.077Z"
          fill="white"
        />
        <path
          d="M2 902.153C2 900.496 3.34315 899.153 5 899.153H17C18.6569 899.153 20 900.496 20 902.153V913.23C20 914.887 18.6569 916.23 17 916.23H5C3.34315 916.23 2 914.887 2 913.23V902.153Z"
          fill="white"
        />
        <path
          d="M2 931.23C2 929.574 3.34315 928.23 5 928.23H17C18.6569 928.23 20 929.574 20 931.23V942.307C20 943.964 18.6569 945.307 17 945.307H5C3.34315 945.307 2 943.964 2 942.307V931.23Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width="50"
      height="984"
      viewBox="0 0 50 984"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M0 948.389V0H50V179.931L20 984L0 948.389Z" fill={color} />
    </svg>
  )
}
