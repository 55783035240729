import React, { useEffect } from 'react'
import RecordNotFound from '../NewTimeSeries/components/RecordNotFound'
import { SizeMe } from 'react-sizeme'
import TimeseriesView from '../../components/TimeseriesView'
import Loader from '../NewTimeSeries/components/Loader'
import useInitChildWindow from './hooks/useInitChildWindow'

const TimeSeriesChild = () => {
  const {
    timeseriesInfo,
    recording,
    channelIds,
    channels: defaultChannels,
    recordingId
  } = useInitChildWindow()

  useEffect(() => {
    const windowSize = JSON.parse(localStorage.getItem('childWindow'))
    const { outerWidth, outerHeight } = windowSize
    window.resizeTo(outerWidth, outerHeight)
  }, [])

  if (!recordingId) return <RecordNotFound recordingId={recordingId} />

  const is =
    timeseriesInfo && recording && channelIds && defaultChannels?.size > 0

  if (!is) {
    return <Loader />
  }

  return (
    <SizeMe>
      {({ size }) => {
        const { width } = size
        const childWindow = JSON.parse(localStorage.getItem('childWindow'))
        const height = childWindow?.height

        return (
          <TimeseriesView
            childWindow
            recordingId={recordingId}
            width={width}
            height={height}
            status="finished"
            // statusMessage={statusMessage}
            timeseriesInfo={timeseriesInfo}
            // nndTimeseriesInfo={nndTimeseriesInfo}
          />
        )
      }}
    </SizeMe>
  )
}

export default TimeSeriesChild
