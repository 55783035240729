import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  title: ({ size = 20 }) => ({
    fontSize: size,
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.primary.text,
    marginBottom: 24
  })
}))

const EmptyFilesTable = ({ size }) => {
  const classes = useStyles({ size })
  return (
    <Grid
      direction="column"
      justify="center"
      alignItems="center"
      container
      className={classes.root}
    >
      <Typography className={classes.title}>No data found</Typography>
    </Grid>
  )
}

export default EmptyFilesTable
