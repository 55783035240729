import React, { memo, useCallback } from 'react'
import CheckboxesGroup from '../../../../../../../CheckboxesGroup'
import { makeStyles } from '@material-ui/core/styles'
import useProbeFiles from '../../../../../../hooks/useProbeFiles'
import SelectMenu from '../../../../../../../SelectMenu'
import CustomDropzone from '../../../../../../../FormComponents/CustomDropzone'
import useUploadPicture from '../../../../../../../../hooks/useUploadPicture'
import BlurLoader from '../../../../../../../BlurLoader'
import { UPLOAD_PRBFILE_METHODS } from '../../../../../../../../utils/consts'

const useStyles = makeStyles(() => ({
  viewContainer: {
    marginTop: 15
  }
}))

const UploadModeView = ({
  view,
  selectedFile,
  handleChangeView,
  setPrbFile,
  loading
}) => {
  const classes = useStyles()
  const { onUpload } = useUploadPicture()
  const handleUploadFile = useCallback(
    file => {
      onUpload({
        files: file,
        loadingLabel: false,
        acceptedFormat: ['json', 'prb'],
        onSuccess: async result => {
          const { file } = result
          setPrbFile(file)
        }
      })
    },
    [onUpload, setPrbFile]
  )
  const radios = [
    {
      value: UPLOAD_PRBFILE_METHODS.DEFAULT,
      label: 'Use default DBC Probe files'
    },
    {
      value: UPLOAD_PRBFILE_METHODS.CUSTOM,
      label: 'Use my probe map'
    }
  ]

  return (
    <div>
      {loading && <BlurLoader />}
      <CheckboxesGroup
        value={view}
        handleChange={handleChangeView}
        radios={radios}
      />
      <div className={classes.viewContainer}>
        <UploadView
          view={view}
          selectedFile={selectedFile}
          setPrbFile={setPrbFile}
          handleUploadFile={handleUploadFile}
        />
      </div>
    </div>
  )
}

const UploadView = memo(
  ({ view, selectedFile, setPrbFile, handleUploadFile }) => {
    if (view === UPLOAD_PRBFILE_METHODS.DEFAULT) {
      return (
        <SelectMenu
          onChangeValue={value => setPrbFile(value)}
          asyncData={useProbeFiles}
          value={selectedFile}
          name="prb_file"
          textFieldProps={{
            variant: 'outlined',
            label: 'Probe Design',
            required: true
          }}
        />
      )
    }
    return (
      <CustomDropzone
        file={selectedFile}
        accept={['.json', '.prb']}
        onUpload={handleUploadFile}
      />
    )
  }
)

export default memo(UploadModeView)
