import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import useProbeName from './hooks/useProbeName'

const useStyles = makeStyles(theme => ({
  nameContainer: { display: 'flex', alignItems: 'center' },
  name: {
    width: '85%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 14,
    lineHeight: '24px',
    color: theme.palette.colors.customColor(
      theme.palette.colors.black_27,
      theme.palette.colors.white
    )
  },
  icon: {
    marginLeft: 4,
    color: theme.palette.colors.neutral['5'],
    '&:hover': {
      cursor: 'pointer'
    }
  }
}))

const ProbeName = ({ name, id, setProbeDesign }) => {
  const classes = useStyles()
  const { hasMapping, openMapping } = useProbeName({ id, name, setProbeDesign })
  return (
    <div className={classes.nameContainer}>
      <Typography className={classes.name}>{name}</Typography>
      {hasMapping && (
        <VisibilityIcon onClick={openMapping} className={classes.icon} />
      )}
    </div>
  )
}

export default ProbeName
