import React from 'react'
import { useTheme } from '@material-ui/core'

const CommandIcon = props => {
  const theme = useTheme()
  const color = theme.palette.colors.customColor(
    theme.palette.colors.neutral[2],
    theme.palette.colors.white
  )
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.08599 7.02885H8.05742V4.97171H9.08599C10.222 4.97171 11.1431 4.05062 11.1431 2.91456C11.1431 1.77851 10.222 0.857422 9.08599 0.857422C7.94981 0.857422 7.02885 1.77851 7.02885 2.91456V3.94314H4.97171V2.91456C4.97171 1.77851 4.05062 0.857422 2.91456 0.857422C1.77851 0.857422 0.857422 1.77851 0.857422 2.91456C0.857422 4.05062 1.77851 4.97171 2.91456 4.97171H3.94314V7.02885H2.91456C1.77851 7.02885 0.857422 7.94994 0.857422 9.08599C0.857422 10.222 1.77851 11.1431 2.91456 11.1431C4.05075 11.1431 4.97171 10.222 4.97171 9.08599V8.05742H7.02885V9.08342C7.02885 9.08445 7.02872 9.08522 7.02872 9.08599C7.02872 10.222 7.94981 11.1431 9.08586 11.1431C10.2219 11.1431 11.143 10.222 11.143 9.08599C11.143 7.94994 10.222 7.02911 9.08599 7.02885ZM9.08599 1.88599C9.65402 1.88599 10.1146 2.34654 10.1146 2.91456C10.1146 3.48259 9.65402 3.94314 9.08599 3.94314H8.05742V2.91456C8.05742 2.34654 8.51796 1.88599 9.08599 1.88599ZM1.88599 2.91456C1.88599 2.34654 2.34654 1.88599 2.91456 1.88599C3.48259 1.88599 3.94314 2.34654 3.94314 2.91456V3.94314H2.91456C2.34654 3.94314 1.88599 3.48246 1.88599 2.91456ZM2.91456 10.1146C2.34654 10.1146 1.88599 9.65402 1.88599 9.08599C1.88599 8.51796 2.34654 8.05742 2.91456 8.05742H3.94314V9.08599C3.94314 9.65402 3.48259 10.1146 2.91456 10.1146ZM4.97171 7.02885V4.97171H7.02885V7.02885H4.97171ZM9.08599 10.1146C8.51796 10.1146 8.05742 9.65402 8.05742 9.08599V8.05742H9.08586C9.65389 8.05742 10.1144 8.51796 10.1144 9.08599C10.1144 9.65402 9.65402 10.1146 9.08599 10.1146Z"
        fill={color}
      />
    </svg>
  )
}

export default CommandIcon
