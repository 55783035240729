import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Header from './components/Header'
import MembersList from './components/MembersList'
import BlurLoader from '../BlurLoader'
import InviteUsersModal from './components/InviteUsersModal'
import useGetAllMembers from './hooks/useGetAllMembers'
import useMembersController from './hooks/useMembersController'
import WatcherChanges from '../FormComponents/WatcherChanges'
import useInviteUsers from './hooks/useInviteUsers'

const useStyles = makeStyles(() => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  header: {
    marginBottom: 30
  }
}))

export default function OrganisationMembers() {
  const classes = useStyles()
  const { loading: membersLoading, fetchMembers } = useGetAllMembers()

  const {
    onDelete,
    selected,
    onSelect,
    deselectAll,
    isBulkSelect,
    setSelected,
    switchRole,
    onUpdate,
    cancelUpdate,
    membersToUpdate,
    loading
  } = useMembersController({ fetchMembers })

  const {
    toggleModal,
    isOpened,
    handleSave,
    loading: invitationsLoading
  } = useInviteUsers({
    fetchMembers
  })
  return (
    <Box className={classes.root}>
      <InviteUsersModal
        isOpened={isOpened}
        toggleModal={toggleModal}
        handleSubmit={handleSave}
        isSubmitting={invitationsLoading}
      />
      <Header className={classes.header} onAddUsers={toggleModal} />
      {membersLoading ? (
        <BlurLoader />
      ) : (
        <MembersList
          selected={selected}
          onSelect={onSelect}
          onDelete={onDelete}
          deselectAll={deselectAll}
          isBulkSelect={isBulkSelect}
          setSelected={setSelected}
          switchRole={switchRole}
          membersToUpdate={membersToUpdate}
        />
      )}

      {membersToUpdate?.length !== 0 && (
        <WatcherChanges
          isSubmitting={loading}
          handleSubmit={onUpdate}
          resetForm={cancelUpdate}
        />
      )}
    </Box>
  )
}
