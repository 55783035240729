export const START_LOGIN = 'START_LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_USER = 'LOGOUT_USER'
export const ACCEPT_TERMS = 'ACCEPT_TERMS'
export const STORE_USER_EMAIL = 'STORE_USER_EMAIL'

export const initLogin = () => ({ type: START_LOGIN })
export const storeCurrentUser = user => ({ type: LOGIN_SUCCESS, payload: user })
export const storeCurrentUserEmail = email => ({
  type: STORE_USER_EMAIL,
  payload: email
})
export const acceptTerms = () => ({ type: ACCEPT_TERMS })
export const logout = () => ({ type: LOGOUT })
export const loginError = () => ({ type: LOGIN_ERROR, error: {} })
