import { useHistory } from 'react-router-dom'
import useNotification from '../../../../SnackbarManager/hooks/useNotification'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOr, min, max } from 'lodash/fp'
import useToggleModal from '../../../../../hooks/useToggleModal'
import {
  DATA_MANIPULATION_MODE,
  MODALS_ID,
  NOTIFICATION,
  ROUTES
} from '../../../../../utils/consts'
import { cutDataset, sliceDataset } from '../../../../../endpoints/admin'
import { resetAllDataManipulation } from '../../../../../containers/NewTimeSeries/redux/actions'

function useDataManipulation() {
  const history = useHistory()
  const dispatch = useDispatch()
  const addNotification = useNotification()
  const [edit, setEditDatasets] = useState(false)
  const [loading, setLoading] = useState(false)
  const [new_file_name, setNewFileName] = useState(null)
  const recording = useSelector(state => state.recordingStore.recording)
  const ID = getOr(null, ['id'], recording)
  const fileName = getOr(null, ['name'], recording)
  const samplerate = getOr(null, ['sample_rate'], recording)
  const { toggle: toggleModal, isOpened } = useToggleModal(
    MODALS_ID.DATA_MANIPULATION_MODAL
  )
  const { toggle: toggleSuccessModal } = useToggleModal(
    MODALS_ID.DATA_MANIPULATION_SUCCESS_MODAL
  )
  const { CUT } = DATA_MANIPULATION_MODE

  const dataManipulation = useSelector(state => state.dataManipulation)
  const { mode } = dataManipulation
  const { direction, to, from } = dataManipulation[mode] || {}

  useEffect(() => {
    return () => {
      setEditDatasets(false)
      setNewFileName(null)
    }
    //eslint-disable-next-line
  }, [isOpened])

  const resetData = useCallback(
    () => dispatch(resetAllDataManipulation()),
    [dispatch]
  )

  const toSeconds = useCallback(
    nr => (nr && samplerate ? Math.floor(nr / samplerate, 0) : 0),
    [samplerate]
  )

  const handleSubmit = useCallback(async () => {
    if (!edit && !new_file_name) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: 'File name is required !'
      })
    }
    try {
      setLoading(true)
      const isCutMode = mode === CUT
      const slice = {
        seconds: toSeconds(to),
        after: direction === 'DESC'
      }

      const start = toSeconds(min([from, to])),
        end = toSeconds(max([from, to]))
      const cut = {
        start,
        end: start === end ? end + 1 : end
      }

      const formattedNewFileName = new_file_name
        .replaceAll(/[@%&*!#$~+={}()|?^<>:,;]/g, '')
        .replaceAll(/[\s]/g, '_')
      const fields = {
        id: ID,
        edit,
        new_file_name: !edit ? formattedNewFileName : null,
        ...(isCutMode ? cut : slice)
      }

      const query = isCutMode ? cutDataset : sliceDataset
      const { status, data } = await query(fields)
      if (status === 200) {
        const { status_int } = data?.record || {}
        toggleSuccessModal({
          onClose: () => {
            resetData()
            history.push(ROUTES.FILES)
          },
          status_int
        })
        toggleModal()
      }
    } catch (e) {
      addNotification({
        type: NOTIFICATION.ERROR,
        title: e
      })
    }
    setLoading(false)
    //eslint-disable-next-line
  }, [ID, toSeconds, direction, edit, new_file_name, from, mode, to])

  return {
    onSubmit: handleSubmit,
    loading,
    fileName,
    toggleModal,
    isOpened,
    direction,
    mode,
    edit,
    setEditDatasets,

    newFileName: new_file_name,
    setNewFileName
  }
}

export default useDataManipulation
