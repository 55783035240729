import React, { memo } from 'react'
import EmptyComponent from '../EmptyComponent'
import FilesPagination from '../../../FilesTable/components/FilesPagination/FilesPagination'
import { makeStyles } from '@material-ui/core'
import { TableRoot } from '../../../FilesTable/FilesTable'
import useTrainAiFilesTable from './hooks/useTrainAIFilesTable'
import useGetApprovedDatasets from '../../hooks/useGetApprovedDatasets'
import { isEmpty } from 'lodash/fp'
import ModelsSnackbar from './components/ModelsSnackbar'
import TrainAiModal from './components/TrainAIModal'
import TrainResultModal from './components/TrainResultModal'
import PageTabs from '../../../PageTabs/PageTabs'
import { tabs } from '../../hooks/tabs'
import SearchBar from '../../../Files/components/Header/components/SearchBar'
import { ViewWeek } from '@material-ui/icons'
import ActionButton from '../../../ActionButton'
import ColumnFiltersModal from '../../../Files/components/ColumnFiltersModal'
import useColumnFilters from '../../../Files/hooks/useColumnFilters'
import { initialColumns } from './hooks/initialColumns'

const useStyles = makeStyles(theme => ({
  root: () => ({
    flex: 1,
    borderRadius: 4,
    margin: '24px 0px',
    overflow: 'hidden',
    position: 'relative',
    background: theme.palette.colors.backgrounds.contrast,
    border: `1px solid  ${theme.palette.colors.neutral[7]}`
  }),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filtersContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  action: {
    width: 'auto',
    minWidth: 'auto',
    padding: '6px 8px',
    borderRadius: 8,
    marginLeft: 24
  },
  filterIcon: {
    margin: 0,
    fontSize: 20
  }
}))

const TrainAiFilesTable = () => {
  const classes = useStyles()
  const { loading } = useGetApprovedDatasets()
  const customStorageName = 'trainAIColumns'
  const {
    allColumns,
    handleApply,
    handleToggleCheckbox,
    filteredColumns: columns,
    isOpened: isOpenedColumnModal,
    toggleModal: toggleColumnsModal
  } = useColumnFilters({
    customStorageName,
    customInitialColumns: initialColumns
  })

  const {
    rows,
    pages,
    state,
    selected,
    isOpened,
    isResultModalOpened,
    onSubmit,
    isSelected,
    handleSelect,
    deselectAll,
    isBulkSelect,
    handleChange,
    toggleTrainModal,
    toggleResultModal
  } = useTrainAiFilesTable({ columns })

  const customQparams = {
    page: 'approvedDatasetsPage',
    per_page: 'approvedDatasetsPerPage'
  }

  const isRowsEmpty = !loading && isEmpty(rows)
/**
 * withRecordType: boolen
 * props needed to render or not the icon type into the table
 */
  return (
    <>
      <div className={classes.header}>
        <PageTabs tabs={tabs} />
        <div className={classes.filtersContainer}>
          <SearchBar />
          <ActionButton
            className={classes.action}
            startIcon={<ViewWeek />}
            startIconCustomClass={classes.filterIcon}
            onClick={toggleColumnsModal}
          />
        </div>
      </div>
      {isRowsEmpty ? (
        <EmptyComponent
          title={'There are no datasets with approved curation sessions.'}
          message={'Choose a dataset and approve curation to train AI model.'}
        />
      ) : (
        <div className={classes.root}>
          <TableRoot
            rows={rows}
            columns={columns}
            loading={loading}
            disableActionButtons
            deselectAll={deselectAll}
            handleSelect={handleSelect}
            isSelected={isSelected}
            withRecordType={false}
            isUsingOldLogic={false}
          />
          <FilesPagination
            withPerPageSelector
            pages={pages}
            customQparams={customQparams}
          />
          <TrainAiModal
            state={state}
            isOpened={isOpened}
            selected={selected}
            onSubmit={onSubmit}
            handleChange={handleChange}
            toggleModal={toggleTrainModal}
          />
          <TrainResultModal
            isOpened={isResultModalOpened}
            deselectAll={deselectAll}
            toggleModal={toggleResultModal}
          />
          <ColumnFiltersModal
            isOpened={isOpenedColumnModal}
            allColumns={allColumns}
            toggleModal={toggleColumnsModal}
            handleApply={handleApply}
            handleToggleCheckbox={handleToggleCheckbox}
          />
          {isBulkSelect && (
            <ModelsSnackbar
              selected={selected}
              deselectAll={deselectAll}
              toggleTrainModal={toggleTrainModal}
            />
          )}
        </div>
      )}
    </>
  )
}

export default memo(TrainAiFilesTable)
