import { useCallback, useEffect, useState } from 'react'
import { getApprovedDatasets } from '../../../endpoints/admin'
import { useDispatch } from 'react-redux'
import { addApprovedDatasets } from '../../../actions/files'
import useFilesQueryParams from '../../FilesTable/hooks/useFilesQueryParams'

function useGetApprovedDatasets(fetchOnRender = true) {
  const [loading, setLoading] = useState(true)

  const dispatch = useDispatch()
  const { page, per_page, keyword } = useFilesQueryParams({
    page: 'approvedDatasetsPage',
    per_page: 'approvedDatasetsPerPage'
  })

  const storeFiles = useCallback(
    data => dispatch(addApprovedDatasets(data)),
    [dispatch]
  )

  const fetchRecords = useCallback(async () => {
    try {
      setLoading(true)
      const params = { page, per_page, keyword }
      const data = await getApprovedDatasets(params)
      storeFiles(data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }, [page, per_page, keyword, storeFiles])

  useEffect(() => {
    if (fetchOnRender) fetchRecords()
  }, [page, per_page, keyword, fetchOnRender, fetchRecords])

  return { loading, fetchRecords }
}

export default useGetApprovedDatasets
