import React, { useMemo } from 'react'

export const OuterContainer = ({ children, height }) => {
  const style0 = useMemo(
    () => ({
      position: 'relative',
      left: 0,
      top: 0,
      width: '100%',
      height
    }),
    [height]
  )

  return <div style={style0}>{children}</div>
}
