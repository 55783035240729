import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '../../../../utils/consts'
import ResetPasswordForm from './components/ResetPasswordForm'
import EmailSent from './components/EmailSent'
import NewPassword from './components/NewPassword'

const ResetPassword = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.RESET_PASSWORD} component={ResetPasswordForm} />
      <Route exact path={ROUTES.RESET_PASSWORD_SENT} component={EmailSent} />
      <Route exact path={ROUTES.RESET_PASSWORD_NEW} component={NewPassword} />
    </Switch>
  )
}

export default ResetPassword
