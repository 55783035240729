import React from 'react'
import { useTheme } from '@material-ui/core'
import { VerticalBarSeries, XYPlot } from 'react-vis'
import { seedUnitColor } from '../../../../../../shared/utils'
import { LoadingSpinner } from './components'
import { usePlotData } from './usePlotData'

export default function CorrelogramPlot({
  width,
  height,
  unitID1,
  unitID2,
  recordingId,
  sortingId
}) {
  const theme = useTheme()
  const plotDataState = usePlotData(recordingId, sortingId, unitID1, unitID2)

  const crossColor = theme.palette.colors.customColor(
    theme.palette.colors.grey,
    theme.palette.colors.white
  )

  const plotColor =
    unitID2 && unitID2 === unitID1 ? seedUnitColor(unitID1) : crossColor

  if (plotDataState.loading) return <LoadingSpinner />
  if (plotDataState.error) return <p>Error</p>

  const plotData = plotDataState.data ?? {
    bin_counts: [],
    bin_size_sec: 0,
    bins: []
  }

  const data = plotData.bins.map((item, index) => {
    return { x: item, y: plotData.bin_counts[index] }
  })

  return (
    <XYPlot
      margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
      height={height}
      width={width}
    >
      <VerticalBarSeries data={data} barWidth={1} color={plotColor} />
    </XYPlot>
  )
}
