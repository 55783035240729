export const INIT_RECORD = 'INIT_RECORD'
export const STORE_RECORD = 'STORE_RECORD'
export const RESET_RECORD = 'RESET_RECORD'
export const INIT_SPIKESORTING = 'INIT_SPIKESORTING'
export const STORE_SPIKESORTING = 'STORE_SPIKESORTING'
export const SELECT_SPIKESORTING = 'SELECT_SPIKESORTING'
export const UPDATE_RECORD = 'UPDATE_RECORD'
export const STORE_COMMENTS = 'STORE_COMMENTS'
export const DELETE_COMMENT = 'DELETE_COMMENT'
export const EDIT_COMMENT = 'EDIT_COMMENT'
export const DELETE_DEFAULT_RUN = 'DELETE_DEFAULT_RUN'
export const STORE_EVENT_FILES = 'STORE_EVENT_FILES'
export const DELETE_EVENT_FILE = 'DELETE_EVENT_FILE'

export const initRecord = data => ({
  type: INIT_RECORD,
  payload: data
})

export const storeRecord = data => ({
  type: STORE_RECORD,
  payload: data
})

export const resetRecord = () => ({
  type: RESET_RECORD
})

export const storeSpikesortings = data => ({
  type: STORE_SPIKESORTING,
  payload: data
})

export const initSpikesortings = payload => ({
  type: INIT_SPIKESORTING,
  payload
})

export const selectSpikesorting = data => ({
  type: SELECT_SPIKESORTING,
  payload: data
})

export const updateRecord = data => ({
  type: UPDATE_RECORD,
  payload: data
})

export const storeComments = data => ({
  type: STORE_COMMENTS,
  payload: data
})

export const removeComment = id => ({
  type: DELETE_COMMENT,
  payload: id
})

export const editComment = data => ({
  type: EDIT_COMMENT,
  payload: data
})

export const deleteDefaultRun = () => ({
  type: DELETE_DEFAULT_RUN
})

export const storeEventFiles = data => ({
  type: STORE_EVENT_FILES,
  payload: data
})

export const deleteEventFile = id => ({
  type: DELETE_EVENT_FILE,
  payload: id
})
