import React from 'react'

const UploadFileIcon = props => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="uploadMask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="2"
        width="10"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.33399 10.8642H6.66732C6.30065 10.8642 6.00065 10.5642 6.00065 10.1976V6.86423H4.94065C4.34732 6.86423 4.05399 6.14423 4.47399 5.72423L7.53399 2.66423C7.65854 2.53939 7.82764 2.46924 8.00399 2.46924C8.18033 2.46924 8.34943 2.53939 8.47399 2.66423L11.534 5.72423C11.954 6.14423 11.654 6.86423 11.0607 6.86423H10.0007V10.1976C10.0007 10.5642 9.70065 10.8642 9.33399 10.8642ZM12.0007 12.1975H4.00065C3.63398 12.1975 3.33398 12.4975 3.33398 12.8641C3.33398 13.2308 3.63398 13.5308 4.00065 13.5308H12.0007C12.3673 13.5308 12.6673 13.2308 12.6673 12.8641C12.6673 12.4975 12.3673 12.1975 12.0007 12.1975Z"
          fill="black"
        />
      </mask>
      <g mask="url(#uploadMask0)">
        <rect width="16" height="16" fill="white" />
      </g>
    </svg>
  )
}

export default UploadFileIcon
