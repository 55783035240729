import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ChangePassword from '../ChangePassword'
import useMenuItems from './hooks/useMenuItems'
import { ROUTES } from '../../utils/consts'
import EditProfile from '../EditProfile'
import ConfigurationLayout from '../ConfigurationLayout'

const AccountPage = () => {
  const menuItem = useMenuItems()

  return (
    <ConfigurationLayout menuItem={menuItem} title="Account">
      <Switch>
        <Route exact path={ROUTES.ACCOUNT} component={EditProfile} />
        <Route
          exact
          path={ROUTES.ACCOUNT_SECURITY}
          component={ChangePassword}
        />
      </Switch>
    </ConfigurationLayout>
  )
}

export default AccountPage
