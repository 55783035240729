import React, { useCallback, useState } from 'react'
import { MODAL_TYPE } from '../../utils/consts'
import CustomModal from '../CustomModal'
import ActionButton from '../ActionButton'
import { makeStyles } from '@material-ui/core'
import { Error } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.colors.backgrounds.main,
    height: '100%'
  }
}))

function Modals() {
  const classes = useStyles()
  const [isOpened, setOpen] = useState(false)
  const [type, setModalType] = useState(null)
  const toggleModal = useCallback(() => setOpen(o => !o), [])
  return (
    <div className={classes.container}>
      <button
        onClick={() => {
          setModalType(null)
          return toggleModal()
        }}
      >
        simple
      </button>
      <button
        onClick={() => {
          setModalType(MODAL_TYPE.ERROR)
          return toggleModal()
        }}
      >
        {MODAL_TYPE.ERROR}
      </button>
      <button
        onClick={() => {
          setModalType(MODAL_TYPE.WARNING)
          return toggleModal()
        }}
      >
        {MODAL_TYPE.WARNING}
      </button>

      <CustomModal
        open={isOpened}
        title="Displayed channel selectors"
        type={type}
        onClose={toggleModal}
        maxWidth={600}
        headerIcon={<Error />}
        renderActionButton={
          <ActionButton label="Download" onClick={() => alert('download')} />
        }
      >
        Are you sure you want to download [20 GB] this item?
      </CustomModal>
    </div>
  )
}

export default Modals
