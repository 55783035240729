import React from 'react'
import cn from 'classnames'
import { makeStyles, CircularProgress, Grid } from '@material-ui/core'

const useStyles = makeStyles({
  spinnerWrapper: {
    height: 100
  }
})

export const LoadingSpinner = ({ overrideClassName, size }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={cn(classes.spinnerWrapper, overrideClassName ?? '')}
    >
      <CircularProgress size={size} />
    </Grid>
  )
}
