import React from 'react'
import cn from 'classnames'
import { TABLE_SCROLLBAR_SIZE } from '../../../../utils/styles/CONSTANTS'

const TableShadows = ({ classes, isLeftShadow, isRightShadow }) => {
  return (
    <React.Fragment>
      <div
        className={cn(classes.shadow, classes.leftShadow, {
          [classes.visibleShadow]: isLeftShadow
        })}
        style={{ width: isLeftShadow }}
      />
      <div
        className={cn(classes.shadow, classes.rightShadow, {
          [classes.visibleShadow]: isRightShadow
        })}
        style={{ width: isRightShadow + TABLE_SCROLLBAR_SIZE }}
      />
    </React.Fragment>
  )
}

export default TableShadows
