import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.colors.white,
    background: '#21B784',
    fontWeight: 600,
    border: 'none',
    width: '100%',
    height: '100%',
    borderRadius: 4
  }
}))

const SelectedPositionWidget = ({ widget }) => {
  const classes = useStyles()

  return <div className={classes.root}>{widget}</div>
}

export default SelectedPositionWidget
