import React from 'react'

export const svg128_7 = ({ color, svgRef, preview = false }) => {
  if (preview) {
    return (
      <svg
        ref={svgRef}
        width="60"
        height="482"
        viewBox="0 0 60 482"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M60 0H0V450L23.5 481.5L46.875 450L60 0Z" fill={color} />
        <path
          d="M5 128C5 126.343 6.34315 125 8 125H20C21.6569 125 23 126.343 23 128V139.077C23 140.734 21.6569 142.077 20 142.077H8C6.34315 142.077 5 140.734 5 139.077V128Z"
          fill="white"
        />
        <path
          d="M5 149.077C5 147.42 6.34315 146.077 8 146.077H20C21.6569 146.077 23 147.42 23 149.077V160.154C23 161.811 21.6569 163.154 20 163.154H8C6.34315 163.154 5 161.811 5 160.154V149.077Z"
          fill="white"
        />
        <path
          d="M5 170.154C5 168.497 6.34315 167.154 8 167.154H20C21.6569 167.154 23 168.497 23 170.154V181.231C23 182.888 21.6569 184.231 20 184.231H8C6.34315 184.231 5 182.888 5 181.231V170.154Z"
          fill="white"
        />
        <path
          d="M5 191.231C5 189.574 6.34315 188.231 8 188.231H20C21.6569 188.231 23 189.574 23 191.231V202.308C23 203.964 21.6569 205.308 20 205.308H8C6.34315 205.308 5 203.964 5 202.308V191.231Z"
          fill="white"
        />
        <path
          d="M5 212.308C5 210.651 6.34315 209.308 8 209.308H20C21.6569 209.308 23 210.651 23 212.308V223.385C23 225.041 21.6569 226.385 20 226.385H8C6.34315 226.385 5 225.041 5 223.385V212.308Z"
          fill="white"
        />
        <path
          d="M5 233.385C5 231.728 6.34315 230.385 8 230.385H20C21.6569 230.385 23 231.728 23 233.385V244.462C23 246.118 21.6569 247.462 20 247.462H8C6.34315 247.462 5 246.118 5 244.462V233.385Z"
          fill="white"
        />
        <path
          d="M5 254.462C5 252.805 6.34315 251.462 8 251.462H20C21.6569 251.462 23 252.805 23 254.462V265.538C23 267.195 21.6569 268.538 20 268.538H8C6.34315 268.538 5 267.195 5 265.538V254.462Z"
          fill="white"
        />
        <path
          d="M5 275.538C5 273.882 6.34315 272.538 8 272.538H20C21.6569 272.538 23 273.882 23 275.538V286.615C23 288.272 21.6569 289.615 20 289.615H8C6.34315 289.615 5 288.272 5 286.615V275.538Z"
          fill="white"
        />
        <path
          d="M5 296.615C5 294.959 6.34315 293.615 8 293.615H20C21.6569 293.615 23 294.959 23 296.615V307.692C23 309.349 21.6569 310.692 20 310.692H8C6.34315 310.692 5 309.349 5 307.692V296.615Z"
          fill="white"
        />
        <path
          d="M5 317.692C5 316.035 6.34315 314.692 8 314.692H20C21.6569 314.692 23 316.035 23 317.692V328.769C23 330.426 21.6569 331.769 20 331.769H8C6.34315 331.769 5 330.426 5 328.769V317.692Z"
          fill="white"
        />
        <path
          d="M5 338.769C5 337.112 6.34315 335.769 8 335.769H20C21.6569 335.769 23 337.112 23 338.769V349.846C23 351.503 21.6569 352.846 20 352.846H8C6.34315 352.846 5 351.503 5 349.846V338.769Z"
          fill="white"
        />
        <path
          d="M5 359.846C5 358.189 6.34315 356.846 8 356.846H20C21.6569 356.846 23 358.189 23 359.846V370.923C23 372.58 21.6569 373.923 20 373.923H8C6.34315 373.923 5 372.58 5 370.923V359.846Z"
          fill="white"
        />
        <path
          d="M5 380.923C5 379.266 6.34315 377.923 8 377.923H20C21.6569 377.923 23 379.266 23 380.923V392C23 393.657 21.6569 395 20 395H8C6.34315 395 5 393.657 5 392V380.923Z"
          fill="white"
        />
        <path
          d="M5 402C5 400.343 6.34315 399 8 399H20C21.6569 399 23 400.343 23 402V413.077C23 414.734 21.6569 416.077 20 416.077H8C6.34315 416.077 5 414.734 5 413.077V402Z"
          fill="white"
        />
        <path
          d="M5 423.077C5 421.42 6.34315 420.077 8 420.077H20C21.6569 420.077 23 421.42 23 423.077V434.154C23 435.811 21.6569 437.154 20 437.154H8C6.34315 437.154 5 435.811 5 434.154V423.077Z"
          fill="white"
        />
        <path
          d="M26 112C26 110.343 27.3431 109 29 109H41C42.6569 109 44 110.343 44 112V123.077C44 124.734 42.6569 126.077 41 126.077H29C27.3431 126.077 26 124.734 26 123.077V112Z"
          fill="white"
        />
        <path
          d="M26 133.077C26 131.42 27.3431 130.077 29 130.077H41C42.6569 130.077 44 131.42 44 133.077V144.154C44 145.811 42.6569 147.154 41 147.154H29C27.3431 147.154 26 145.811 26 144.154V133.077Z"
          fill="white"
        />
        <path
          d="M26 154.154C26 152.497 27.3431 151.154 29 151.154H41C42.6569 151.154 44 152.497 44 154.154V165.231C44 166.888 42.6569 168.231 41 168.231H29C27.3431 168.231 26 166.888 26 165.231V154.154Z"
          fill="white"
        />
        <path
          d="M26 175.231C26 173.574 27.3431 172.231 29 172.231H41C42.6569 172.231 44 173.574 44 175.231V186.308C44 187.964 42.6569 189.308 41 189.308H29C27.3431 189.308 26 187.964 26 186.308V175.231Z"
          fill="white"
        />
        <path
          d="M26 196.308C26 194.651 27.3431 193.308 29 193.308H41C42.6569 193.308 44 194.651 44 196.308V207.385C44 209.041 42.6569 210.385 41 210.385H29C27.3431 210.385 26 209.041 26 207.385V196.308Z"
          fill="white"
        />
        <path
          d="M26 217.385C26 215.728 27.3431 214.385 29 214.385H41C42.6569 214.385 44 215.728 44 217.385V228.462C44 230.118 42.6569 231.462 41 231.462H29C27.3431 231.462 26 230.118 26 228.462V217.385Z"
          fill="white"
        />
        <path
          d="M26 238.462C26 236.805 27.3431 235.462 29 235.462H41C42.6569 235.462 44 236.805 44 238.462V249.538C44 251.195 42.6569 252.538 41 252.538H29C27.3431 252.538 26 251.195 26 249.538V238.462Z"
          fill="white"
        />
        <path
          d="M26 259.538C26 257.882 27.3431 256.538 29 256.538H41C42.6569 256.538 44 257.882 44 259.538V270.615C44 272.272 42.6569 273.615 41 273.615H29C27.3431 273.615 26 272.272 26 270.615V259.538Z"
          fill="white"
        />
        <path
          d="M26 280.615C26 278.959 27.3431 277.615 29 277.615H41C42.6569 277.615 44 278.959 44 280.615V291.692C44 293.349 42.6569 294.692 41 294.692H29C27.3431 294.692 26 293.349 26 291.692V280.615Z"
          fill="white"
        />
        <path
          d="M26 301.692C26 300.035 27.3431 298.692 29 298.692H41C42.6569 298.692 44 300.035 44 301.692V312.769C44 314.426 42.6569 315.769 41 315.769H29C27.3431 315.769 26 314.426 26 312.769V301.692Z"
          fill="white"
        />
        <path
          d="M26 322.769C26 321.112 27.3431 319.769 29 319.769H41C42.6569 319.769 44 321.112 44 322.769V333.846C44 335.503 42.6569 336.846 41 336.846H29C27.3431 336.846 26 335.503 26 333.846V322.769Z"
          fill="white"
        />
        <path
          d="M26 343.846C26 342.189 27.3431 340.846 29 340.846H41C42.6569 340.846 44 342.189 44 343.846V354.923C44 356.58 42.6569 357.923 41 357.923H29C27.3431 357.923 26 356.58 26 354.923V343.846Z"
          fill="white"
        />
        <path
          d="M26 364.923C26 363.266 27.3431 361.923 29 361.923H41C42.6569 361.923 44 363.266 44 364.923V376C44 377.657 42.6569 379 41 379H29C27.3431 379 26 377.657 26 376V364.923Z"
          fill="white"
        />
        <path
          d="M26 386C26 384.343 27.3431 383 29 383H41C42.6569 383 44 384.343 44 386V397.077C44 398.734 42.6569 400.077 41 400.077H29C27.3431 400.077 26 398.734 26 397.077V386Z"
          fill="white"
        />
        <path
          d="M26 407.077C26 405.42 27.3431 404.077 29 404.077H41C42.6569 404.077 44 405.42 44 407.077V418.154C44 419.811 42.6569 421.154 41 421.154H29C27.3431 421.154 26 419.811 26 418.154V407.077Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="60"
      height="482"
      viewBox="0 0 60 482"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={svgRef}
    >
      <path d="M60 0H0V450L23.5 481.5L46.875 450L60 0Z" fill={color} />
    </svg>
  )
}
