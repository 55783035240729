import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchResult } from '../../../../../hooks/useFetchResult'
import {
  storeCurations,
  updateSingleCuration
} from '../../../../../actions/curations'
import { getCurations } from '../../../../../endpoints/admin'
import useQueryParams from '../../../../../hooks/useQueryParams'
import { QPARAMS } from '../../../../../utils/consts'

function useGetCurations(fetchOnRender = true) {
  const selectedSorting = useSelector(
    state => state.recordingStore.selectedSorting
  )
  const run_id = selectedSorting?.id
  const query = useQueryParams()
  const page = query.getByName(QPARAMS.CURATIONS_PAGE) || 1

  const dispatch = useDispatch()
  const store = useCallback(
    ({ data }) => dispatch(storeCurations(data)),
    [dispatch]
  )
  const update = useCallback(
    ({ data }) => dispatch(updateSingleCuration(data)),
    [dispatch]
  )

  const { state, fetchData } = useFetchResult(getCurations)

  const fetchCurations = useCallback(
    () =>
      fetchData(store, {
        run_id,
        page
      }),
    [fetchData, page, run_id, store]
  )

  const fetchSingleCuration = useCallback(
    id => fetchData(update, { id }),
    [fetchData, update]
  )

  const { loading } = state || {}

  useEffect(() => {
    if (fetchOnRender && run_id) fetchCurations()

    // eslint-disable-next-line
  }, [fetchOnRender, page, run_id])

  return { loading, fetchCurations, fetchSingleCuration }
}

export default useGetCurations
