import { Drawer, Grid, Typography } from '@material-ui/core'
import cn from 'classnames'
import MenuList from '../MenuList/MenuList'
import OrganizationSwitch from '../OrganizationSwitch'
import React from 'react'
import useMenuDrawer from '../../hooks/useMenuDrawer'
import { makeStyles } from '@material-ui/core/styles'
import useGetCurrentUser from '../../../../hooks/useGetCurrentUser'
import { MENU_DRAWER_WIDTH } from '../../../../utils/styles/CONSTANTS'
import noPreview from '../../../../imgs/nopreview.png'

const useStyles = makeStyles(theme => ({
  root: {
    width: MENU_DRAWER_WIDTH,
    padding: '30px 0',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: theme.palette.colors.backgrounds.main
  },
  userInfo: {
    padding: '0 12px'
  },
  userIcon: {
    display: 'flex',
    flex: 1,
    maxWidth: 40,
    height: 40,
    borderRadius: 40,
    marginRight: 15,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  name: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '18px',
    color: theme.palette.primary.contrast,
    wordBreak: 'break-word'
  },
  companyName: {
    fontWeight: 400
  },
  organizationSwitch: {
    position: 'absolute',
    bottom: 0
  },
  hidden: {
    display: 'none'
  }
}))

const MenuDrawer = ({ variant = 'temporary', isPersistent = false }) => {
  const { isOpened, toggleDrawer, selectOrganization, menuItem } =
    useMenuDrawer({ restrictAccess: isPersistent })
  const classes = useStyles()
  const { user, companies } = useGetCurrentUser()
  const { name: userName = 'Name', image, company } = user || {}
  const { name: companyName = 'Company name' } = company || {}
  const profileImage = image || noPreview

  return (
    <Drawer
      anchor="left"
      variant={variant}
      open={isPersistent || isOpened}
      onClose={toggleDrawer}
    >
      <div className={classes.root}>
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          wrap="nowrap"
          className={classes.userInfo}
        >
          <div
            className={classes.userIcon}
            style={{ backgroundImage: `url(${profileImage})` }}
          />
          <Grid
            container
            justify="flex-start"
            direction="column"
            style={{ flex: 2 }}
          >
            <Typography className={classes.name}>{userName}</Typography>
            <Typography className={cn(classes.name, classes.companyName)}>
              {companyName}
            </Typography>
          </Grid>
        </Grid>
        <MenuList menuItem={menuItem} callback={toggleDrawer} />
      </div>
      <OrganizationSwitch
        className={classes.organizationSwitch}
        selected={company}
        companies={companies}
        onSelect={selectOrganization}
      />
    </Drawer>
  )
}

export default MenuDrawer
