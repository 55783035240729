import React, { memo } from 'react'
import { TableCell, Typography } from '@material-ui/core'
import cn from 'classnames'
import FileStatus from '../../FilesTable/components/FileStatus'
import { isString } from 'lodash/fp'
import ApproveComponent from '../../RecordingViewWithCuration/components/WidgetsLayout/widgets/ClusterView/components/ApproveComponent'

const BodyColumn = ({
  classes,
  renderActionButton,
  disableActionButtons,
  id,
  isSticky,
  isCheckbox,
  statusMinWidth,
  inProgressOperation,
  renderProgressBar,
  ...row
}) => {
  const { status_int, ...rest } = row

  const Value = memo(({ label, isFirst }) => {
    let hoverTitle = label
    let displayTitle = label
    const hasMultipleTextForm =
      label && typeof label === 'object' && !label?.props
    if (hasMultipleTextForm) {
      hoverTitle = label.fullText
      displayTitle = label.shortText
    }
    return (
      <Typography
        noWrap
        title={isString(hoverTitle) && hoverTitle}
        className={cn(classes.value, classes.text, {
          [classes.textShift]: isFirst
        })}
      >
        {displayTitle ?? '-'}
      </Typography>
    )
  })


  const removeUndefinedKeys = (obj) => {
    for (const key in obj) {
      if (obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  };

  removeUndefinedKeys(rest);

  return Object.entries(rest).map(([k, v], i, array) => {
    let value
    const isLast = i === array.length - 1
    const isFirst = i === 0 && !isCheckbox
    switch (k) {
      case 'author':
        value = <Value label={v?.name} />
        break
      case 'status':
        value = inProgressOperation ? (
          renderProgressBar()
        ) : (
          <FileStatus
            status={v}
            index={i}
            statusId={status_int}
            minWidth={statusMinWidth}
          />
        )
        break
      case 'tags':
        value =
          v?.length > 0 ? (
            <Typography
              className={classes.tags}
              style={i === 0 ? { marginLeft: 0 } : {}}
              noWrap
            >
              {v.map(x => x.value).join(', ')}
            </Typography>
          ) : (
            <Value />
          )
        break
      case 'curation_id_passed':
        break
      case 'name_passed':
        break
      case 'approvalStatus':
        value = <ApproveComponent isApproved={v} large />
        break
      default:
        value = <Value label={v} isFirst={isFirst} />
    }
    return (
      <TableCell
        key={i}
        align={i > 0 ? 'center' : 'left'}
        className={cn({
          [classes.sticky]: (isLast || isFirst) && isSticky
        })}
        style={isLast ? { right: 0 } : isFirst ? { left: 0 } : {}}
      >
        {value && (<div id={`row-${i}-first`}>
          {isLast &&
            renderActionButton &&
            !disableActionButtons &&
            !inProgressOperation && (
              <div className={`actions-table-row ${classes.actions}`}>
                {renderActionButton(id)}
              </div>
            )}
          {value}
        </div>)}
      </TableCell>
    )
  })
}

export default memo(BodyColumn)
