import { useDispatch, useSelector } from 'react-redux'
import useRecordingInfo from '../../../NewRecordingView/hooks/useRecordingInfo'
import useEditRecord from '../../../NewRecordingView/hooks/useEditRecord'
import { useCallback, useEffect } from 'react'
import { isEqual } from 'lodash/fp'
import useDebouncedCallback from '../../../../hooks/useDebouncedCallback'
import { editProbeFileDataActionCreator } from '../../../../containers/NewTimeSeries/redux/actions'
import { useSafeDispatch } from '../../../../hooks/useSafeDispatch'

function useSaveDisabledChannels() {
  const disabledChannels = useSelector(
    state => state.timeSeriesLeftPanel.disabledChannels
  )
  const { onEdit } = useEditRecord()
  const { recording } = useRecordingInfo()
  const { disabled_channels, id: recordId } = recording || {}
  const dispatch = useDispatch()
  const safeDispatch = useSafeDispatch(dispatch)
  const editProbeFileData = fileData =>
    safeDispatch(editProbeFileDataActionCreator(fileData))

  const saveDisabledChannels = useCallback(() => {
    const save = !isEqual(disabledChannels, disabled_channels)
    if (!save) return
    const data = { id: recordId, disabled_channels: disabledChannels }
    onEdit({
      data,
      notifications: false,
      onSuccess: (updateStore, response) => {
        updateStore({ recording: response })
        editProbeFileData(data)
      }
    })

    // eslint-disable-next-line
  }, [disabledChannels, disabled_channels, recordId])

  const debounceSave = useDebouncedCallback(saveDisabledChannels, 3000)

  useEffect(() => {
    debounceSave()
    // eslint-disable-next-line
  }, [disabledChannels])
}

export default useSaveDisabledChannels
