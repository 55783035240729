import { getOS } from '../../../utils/utils'

const stringifyKeys = keys => keys.sort((a, b) => a - b).join('-')

export const KeyMap = {
  Ctrl: getOS() === 'Mac OS' ? 224 : 17
}

export class StandardKeyEvents {
  static events = new Map()
  static keyboardKeys = new Map()

  static _register(key, event, options = {}) {
    const controller = new AbortController()
    const signal = controller.signal

    this.events.set(stringifyKeys(key), {
      event,
      abort: controller.abort.bind(controller),
      signal
    })

    this.addEventListener(key, signal, options.preventDefault)
  }

  static register(key, event, options = {}) {
    const hasKeyEvent = this.events.has(stringifyKeys(key))

    if (!hasKeyEvent) {
      this._register(key, event, options)
    } else {
      if (options.overrideEvent) {
        this.dispose(key)
        this._register(key, event, options)
      } else {
        return
        console.warn('Registered event')
      }
    }
  }

  static registerWithPreventDefault(key, event, options = {}) {
    this.register(key, event, { preventDefault: true, ...options })
  }

  static dispose(key) {
    const eventKey = this.events.get(stringifyKeys(key))
    if (eventKey) {
      eventKey.abort()
      this.events.delete(stringifyKeys(key))
    }
  }

  static addEventListener(key, signal, preventDefault) {
    const keyDownfn = e => {
      if (e.repeat) return

      this.keyboardKeys = new Map(
        Array.from(this.keyboardKeys).concat([[e.keyCode, true]])
      )

      const buildKey = stringifyKeys(
        [...this.keyboardKeys.entries()]
          .filter(en => en[1] === true)
          .map(en => en[0])
      )

      if (this.events.has(buildKey)) {
        if (this.keyboardKeys.size !== key.length) {
          return
        }

        if (buildKey === key.join('-')) {
          if (preventDefault) {
            e.preventDefault()
          }

          this.events.get(buildKey).event(e)
        }
      }
    }

    const keyUpFn = e => {
      this.keyboardKeys.delete(e.keyCode)
    }

    document.addEventListener('keydown', keyDownfn, {
      signal
    })

    document.addEventListener('keyup', keyUpFn, {
      signal
    })
  }
}
