import React, { useCallback, useState } from 'react'
import { makeStyles, Menu, MenuItem, Typography } from '@material-ui/core'
import MapData from '../../../../utils/MapData/MapData'
import { ArrowDropDownRounded } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: 50
  },
  label: {
    display: 'flex',
    fontSize: 13,
    lineHeight: '20px',
    color: theme.palette.colors.neutral.black
  },
  selector: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 12,
    '& svg': {
      bottom: -5,
      right: -24,
      fontSize: 30,
      position: 'absolute',
      color: theme.palette.colors.neutral['5']
    }
  }
}))

const FilesPerPageSelector = ({ perPage, onSelect }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleSelect = useCallback(
    value => {
      setAnchorEl(null)
      return onSelect(value)
    },
    [onSelect]
  )

  const rowsPerPage = [25, 50, 75, 100]

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography className={classes.label}>
          Rows per page:{' '}
          <span onClick={handleClick} className={classes.selector}>
            {perPage} <ArrowDropDownRounded />
          </span>
        </Typography>
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MapData data={rowsPerPage}>
          {({ item, index }) => (
            <MenuItem
              key={index}
              onClick={() => handleSelect(item)}
              selected={item === Number(perPage)}
            >
              {item}
            </MenuItem>
          )}
        </MapData>
      </Menu>
    </React.Fragment>
  )
}

export default FilesPerPageSelector
