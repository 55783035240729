import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

export function AuthRoute({ component, children, currentUser, ...rest }) {
  if (currentUser || localStorage.getItem('access_token')) {
    return <Redirect to="/" />
  }
  if (children) return <Route {...rest}>{children}</Route>
  return <Route {...rest} component={component} />
}

const mapStateToProps = state => ({
  currentUser: state.login.currentUser
})

export default connect(mapStateToProps)(AuthRoute)
