export class TransformCoords {
  constructor(canvasLayer) {
    this.canvasLayer = canvasLayer
    this._m_use_coords = false
  }

  useCoords = val => {
    this._m_use_coords = val
  }

  coordsToPixX = x => {
    const margins = this.canvasLayer.margins()
    const xr = this.canvasLayer.coordXRange()

    let W = this.canvasLayer.width() - margins[0] - margins[1]

    const xpct = (x - xr[0]) / (xr[1] - xr[0])

    return margins[0] + W * xpct
  }

  coordsToPixY = y => {
    const margins = this.canvasLayer.margins()
    const yr = this.canvasLayer.coordYRange()

    let H = this.canvasLayer.height() - margins[2] - margins[3]

    const ypct = 1 - (y - yr[0]) / (yr[1] - yr[0])

    return margins[2] + H * ypct
  }

  coordsToPix = (x, y) => {
    if (y === undefined) {
      let tmp = x
      x = tmp[0]
      y = tmp[1]
    }

    return [this.coordsToPixX(x), this.coordsToPixY(y)]
  }

  transformRect = rect => {
    return this.transformXYWH(rect[0], rect[1], rect[2], rect[3])
  }

  transformXYWH = (x, y, W, H) => {
    let pt1 = this.transformXY(x, y)
    let pt2 = this.transformXY(x + W, y + H)
    return [
      Math.min(pt1[0], pt2[0]),
      Math.min(pt1[1], pt2[1]),
      Math.abs(pt2[0] - pt1[0]),
      Math.abs(pt2[1] - pt1[1])
    ]
  }

  transformX = x => {
    const margins = this.canvasLayer.margins()

    if (this._m_use_coords) {
      return this.coordsToPixX(x)
    } else {
      return margins[0] + x
    }
  }

  transformXY = (x, y) => {
    if (y === undefined) {
      let tmp = x
      x = tmp[0]
      y = tmp[1]
    }
    const margins = this.canvasLayer.margins()
    if (this._m_use_coords) {
      return this.coordsToPix(x, y)
    } else {
      return [margins[0] + x, margins[2] + y]
    }
  }
}
