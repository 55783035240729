import { useEffect, useState } from 'react'

function useLinearProgressValue() {
  const [value, setValue] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setValue(value => {
        if (value === 90) {
          return value
        }
        const diff = Math.random() * 10
        return Math.min(value + diff, 100)
      })
    }, 50)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return value
}

export default useLinearProgressValue
