import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'
import Header from './components/Header'
import CustomTable from '../CustomTable'
import { initialColumns } from './initialColumns'
import useGetAllProbes from './hooks/useGetAllProbes'
import useProbeInventoryTable from './hooks/useProbeInventoryTable'
import ProbeUploadModal from './components/ProbeUploadModal/ProbeUploadModal'
import SelectDefaultPrbFileModal from '../ExperimentsInput/components/SelectDefaultPrbFileModal'
import ProbeDesignModal from '../RecordingViewWithCuration/components/WidgetsLayout/widgets/DataView/components/ProbeDesignModal'
import useLimitedAccess from '../../hooks/useLimitedAccess'
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    padding: '0 40px 60px',
    background: theme.palette.colors.backgrounds.files
  }
}))

const ProbeInventory = () => {
  const classes = useStyles()
  const { loading, pages } = useGetAllProbes()
  const { rows, prbFile } = useProbeInventoryTable({ columns: initialColumns })
  const { isDefaultOrganization } = useLimitedAccess()
  if (isDefaultOrganization) return <Redirect noThrow to="/files" />
  return (
    <Grid
      container
      wrap="nowrap"
      direction="column"
      className={classes.container}
    >
      <Header />
      <CustomTable
        isSticky
        columns={initialColumns}
        loading={loading}
        pages={pages}
        rows={rows}
        withPagination
      />
      <ProbeUploadModal />
      <ProbeDesignModal prbFile={prbFile} />
      <SelectDefaultPrbFileModal />
    </Grid>
  )
}

export default ProbeInventory
